import humanErrors from '~/config/humanErrors.json'

type HumanErrors = { machineError: string; idFragment?: string; humanError: string }

export const getHumanError = (error: string) => {
  const findHumanErrorByMachineError = (he: HumanErrors): boolean => he.machineError === error

  const humanError = humanErrors.find(findHumanErrorByMachineError)?.humanError

  if (humanError) {
    return humanError
  }

  const findHumanErrorByIdFragment = (he: HumanErrors): boolean | undefined =>
    !!he.idFragment && error.includes(he.idFragment)

  return humanErrors.find(findHumanErrorByIdFragment)?.humanError ?? error
}
