import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { FiatPrice, FiatPrices, MarketChart, MarketCharts } from '~/types/fiatPrice'
import { type State, stateTypes } from '~/types/state'
import { ChainToCoingeckoPlatformMap, getCoingeckoIds } from '~/utils/coingecko'
import { AddressToAssetIdMapRango, AssetIdToAddressMapRango } from '~/utils/rango'
import type { DexApiResponse } from '~/types/coingecko'

export async function fetchCoingeckoPrices(
  assetIdsWithUndefinedCoingeckoIds: { coingeckoId: string | undefined; assetId: string }[],
): Promise<FiatPrices> {
  const coingeckoIds = assetIdsWithUndefinedCoingeckoIds.filter(({ coingeckoId }) => !!coingeckoId)

  if (coingeckoIds.length === 0) {
    return {}
  }

  const queryStringCoingecko = coingeckoIds.map(({ coingeckoId }) => coingeckoId).join('%2C')
  const coingeckoUrl = `https://node.eldorado.market/prices/api/v3/simple/price?ids=${queryStringCoingecko}&vs_currencies=usd&include_24hr_change=true`
  const coingeckoPrices = await (await fetch(coingeckoUrl)).json()

  return coingeckoIds.reduce<FiatPrices>((acc, { assetId, coingeckoId }) => {
    if (coingeckoId && coingeckoPrices[coingeckoId] && coingeckoPrices[coingeckoId].usd) {
      acc[assetId.toUpperCase()] = {
        state: 'loaded',
        data: {
          usd: coingeckoPrices[coingeckoId].usd,
          usd_24h_change: coingeckoPrices[coingeckoId].usd_24h_change,
        },
      }
    } else {
      acc[assetId.toUpperCase()] = {
        state: 'error',
        errorMsg: coingeckoId ? 'Price not found' : 'Missing coingecko id',
      }
    }
    return acc
  }, {})
}

export const useFiatPriceStore = defineStore('fiatPrice', () => {
  let stopPollingFiatPrices: ReturnType<typeof setTimeout> | null = null
  const isFetchingFiatPrices = ref(false)
  const fiatPrices = ref<FiatPrices>({})
  const marketCharts = ref<MarketCharts>({})
  // type SwapkitPriceResponse = {
  //   price_usd: number
  //   provider: string
  //   timestamp: number
  //   identifier: string
  // }[]

  // async function fetchCachedSwapkitPriceAsFallback(
  //   assetIdsWithoutCoingeckoIds: string[],
  // ): Promise<FiatPrices> {
  //   const assetIds = assetIdsWithoutCoingeckoIds

  //   if (assetIds.length === 0) {
  //     return {}
  //   }

  //   const swapkitPrices: SwapkitPriceResponse = await (
  //     await fetch(`${useRuntimeConfig().public.SWAPKIT_API}/price/cached-price`, {
  //       headers: {
  //         accept: '*/*',
  //         'accept-language': 'en-US,en;q=0.9',
  //         'cache-control': 'no-cache',
  //         'content-type': 'application/json',
  //         pragma: 'no-cache',
  //         priority: 'u=1, i',
  //         'sec-fetch-dest': 'empty',
  //         'sec-fetch-mode': 'cors',
  //         'sec-fetch-site': 'cross-site',
  //       },
  //       referrerPolicy: 'strict-origin-when-cross-origin',
  //       body: JSON.stringify({
  //         tokens: assetIds.map((assetId) => ({ identifier: assetId })),
  //         metadata: true,
  //       }),
  //       method: 'POST',
  //       mode: 'cors',
  //       credentials: 'omit',
  //     })
  //   ).json()

  //   return assetIds.reduce<FiatPrices>((acc, assetId) => {
  //     const price = swapkitPrices.find(
  //       (price) => price.identifier.toUpperCase() === assetId.toUpperCase(),
  //     )
  //     if (price && price.price_usd) {
  //       return {
  //         ...acc,
  //         [assetId.toUpperCase()]: {
  //           state: 'loaded',
  //           data: {
  //             usd: price.price_usd,
  //             usd_24h_change: undefined,
  //           },
  //         },
  //       }
  //     } else {
  //       return {
  //         ...acc,
  //         [assetId.toUpperCase()]: {
  //           state: 'error',
  //           errorMsg: 'Price not found via swapkit api',
  //         },
  //       }
  //     }
  //   }, {})
  // }
  function fetchCoingeckoDexPrices(assetIds: string[]): Promise<FiatPrices> {
    if (assetIds.length === 0) {
      return Promise.resolve({})
    }
    interface AssetIdWithPlatform {
      platform: string
      address: string
      assetId: string
    }

    const assetIdsWithPlatform: AssetIdWithPlatform[] = assetIds.map((assetId) => {
      const [chain, symbolAddress] = assetId.replace('/', '.').split('.')
      const [, address] = symbolAddress.split('-')
      const platform = ChainToCoingeckoPlatformMap[chain]
      return { platform, address, assetId }
    })

    type GroupedByPlatform = Record<string, string[]>
    const groupByPlatform = (data: AssetIdWithPlatform[]): GroupedByPlatform =>
      data.reduce((acc, item) => {
        acc[item.platform] = acc[item.platform] || []
        acc[item.platform].push(item.assetId)
        return acc
      }, {} as GroupedByPlatform)

    const fetchCoingeckoDexPriceBatch = async (platform: string, assetIds: string[]) => {
      const addresses = assetIds
        .map((assetId) => AssetIdToAddressMapRango[assetId])
        .filter((assetAddress) => !!assetAddress)

      const url = `https://node.eldorado.market/prices/api/v3/onchain/simple/networks/${platform}/token_price/${addresses.join(',')}`
      const options = {
        method: 'GET',
        headers: {
          accept: 'application/json',
        },
      }

      try {
        const response = await fetch(url, options)
        const json = (await response.json()) as DexApiResponse

        // Handle bad data response
        if (!json.data || !json.data.attributes || !json.data.attributes.token_prices) {
          return addresses.reduce((acc, address) => {
            const assetId = AddressToAssetIdMapRango[address] ?? 'unknown_token'
            if (assetId === 'unknown_token')
              window.newrelic?.noticeError('Unknown token address: ' + address)

            acc[assetId] = {
              state: stateTypes.Error,
              errorMsg: `No data returned for ${address}`,
            }
            return acc
          }, {} as FiatPrices)
        }

        // Map the returned token prices to FiatPrices format
        return Object.entries(json.data.attributes.token_prices).reduce(
          (acc, [address, priceString]) => {
            const assetId = AddressToAssetIdMapRango[address] ?? 'unknown_token'
            if (assetId === 'unknown_token')
              window.newrelic?.noticeError('Unknown token address: ' + address)

            acc[assetId] = {
              state: stateTypes.Loaded,
              data: {
                usd: parseFloat(priceString),
                usd_24h_change: undefined,
              },
            }
            return acc
          },
          {} as FiatPrices,
        )
      } catch (error: any) {
        console.error(
          `Error fetching data for ${platform} with addresses ${addresses.join(',')}:`,
          error,
        )

        // Return an error state for each token in the batch
        return addresses.reduce((acc, address) => {
          const assetId = AddressToAssetIdMapRango[address] ?? 'unknown_token'
          if (assetId === 'unknown_token')
            window.newrelic?.noticeError('Unknown token address: ' + address)

          acc[assetId] = {
            state: stateTypes.Error,
            errorMsg: error.message,
          }
          return acc
        }, {} as FiatPrices)
      }
    }

    // Function to fetch prices for a platform in batches
    const fetchPricesForPlatform = (platform: string, assetIds: string[]) => {
      const batches = []

      for (let i = 0; i < assetIds.length; i += 30) {
        batches.push(assetIds.slice(i, i + 30))
      }

      return Promise.all(
        batches.map((batchedAssetIds) => fetchCoingeckoDexPriceBatch(platform, batchedAssetIds)),
      )
    }

    const fetchCoingeckoDexPrices = async (data: AssetIdWithPlatform[]) => {
      const groupedData = groupByPlatform(data)

      const allPlatformRequests = Object.entries(groupedData).map(async ([platform, assetIds]) => {
        if (platform !== 'solana') {
          return []
        }

        const results = await fetchPricesForPlatform(platform, assetIds)
        return Object.assign({}, ...results)
      })

      const allFiatPricesArray = await Promise.all(allPlatformRequests)

      return Object.assign({}, ...allFiatPricesArray)
    }

    // Execute the function and log the result
    return fetchCoingeckoDexPrices(assetIdsWithPlatform)
  }

  async function fetchFiatPrices(assetIds: string[]): Promise<FiatPrices> {
    isFetchingFiatPrices.value = true

    try {
      // Map asset Ids to coingecko ids, returns undefined for missing coingeckoIds
      const assetIdsWithUndefinedCoingeckoIds = getCoingeckoIds(assetIds)

      const assetIdsWithCoingeckoIds = assetIdsWithUndefinedCoingeckoIds.filter(
        ({ coingeckoId }) => !!coingeckoId,
      )

      const assetIdsWithoutCoingeckoIds = assetIdsWithUndefinedCoingeckoIds
        .filter(({ coingeckoId }) => !coingeckoId)
        .map(({ assetId }) => assetId)

      const allPrices = await Promise.all([
        fetchCoingeckoPrices(assetIdsWithCoingeckoIds).then((result) => {
          fiatPrices.value = { ...fiatPrices.value, ...result }
          return result
        }),
        // fetchCachedSwapkitPriceAsFallback(assetIdsWithoutCoingeckoIds).then((result) => {
        //   fiatPrices.value = { ...fiatPrices.value, ...result }
        //   return result
        // }), // TODO re-enable swapkit price api once they turn it back on
        fetchCoingeckoDexPrices(assetIdsWithoutCoingeckoIds).then((result) => {
          fiatPrices.value = { ...fiatPrices.value, ...result }
          return result
        }),
      ])
      return allPrices.reduce<FiatPrices>((pricesRecord, prices) => {
        return { ...pricesRecord, ...prices }
      }, {})
    } catch (error) {
      console.error('Failed to fetch fiat prices', error)
      const resultError = assetIds.reduce<FiatPrices>((acc, assetId) => {
        acc[assetId] = { state: 'error', errorMsg: 'Failed to fetch prices' }
        return acc
      }, {})
      fiatPrices.value = { ...fiatPrices.value, ...resultError }

      return Promise.reject(error)
    } finally {
      isFetchingFiatPrices.value = false
    }
  }

  async function fetchMarketCharts(assetIds: string[]): Promise<MarketCharts> {
    const coingeckoData = getCoingeckoIds(assetIds)

    function createCoingeckoString(coingeckoId: string): string {
      return `https://node.eldorado.market/prices/api/v3/coins/${coingeckoId}/market_chart?vs_currency=usd&days=7`
    }

    const promises = coingeckoData.map(({ coingeckoId, assetId }) => {
      if (!coingeckoId) {
        return Promise.resolve({ assetId, data: null })
      }

      return fetch(createCoingeckoString(coingeckoId))
        .then((response) => response.json() as Promise<MarketChart>)
        .then((data) => {
          return {
            assetId,
            data,
          }
        })
        .catch((error) => {
          console.error('Failed to fetch market chart', error)
          return { assetId, data: null }
        })
    })

    const results = await Promise.all(promises)

    const newMarketCharts: MarketCharts = results.reduce((acc, { assetId, data }) => {
      if (data) {
        acc[assetId] = { state: 'loaded', data }
      } else {
        acc[assetId] = { state: 'error', errorMsg: 'Failed to fetch market chart' }
      }
      return acc
    }, {} as MarketCharts)
    marketCharts.value = { ...marketCharts.value, ...newMarketCharts }
    return marketCharts.value
  }

  function refreshCachedFiatPrices() {
    stopPollingFiatPrices = setTimeout(() => {
      const existingCoingeckoIds = Object.keys(fiatPrices.value)
      fetchFiatPrices(existingCoingeckoIds).then(() => {
        refreshCachedFiatPrices()
      })
    }, 60000)
  }

  function startPollingFiatPrices() {
    refreshCachedFiatPrices()
  }

  function fetchNewFiatPricesNow(
    assetIds: Array<string>,
    includeMarketCharts = true,
  ): Promise<FiatPrices> {
    const existingAssetIds = Object.keys(fiatPrices.value)
    const newAssetIds = assetIds.filter((id) => !existingAssetIds.includes(id))
    if (includeMarketCharts) fetchMarketCharts(assetIds)

    return fetchFiatPrices(newAssetIds)
  }

  function getFiatPrice(assetId: string): State<FiatPrice> {
    const fiatPrice = fiatPrices.value[assetId]
    if (!fiatPrice) {
      return { state: stateTypes.Loading }
    }
    return fiatPrice
  }
  function getFiatPriceAmount(assetId: string): number {
    const fiatPrice = fiatPrices.value[assetId]
    if (fiatPrice.state !== stateTypes.Loaded) {
      return 0
    }
    return fiatPrice.data.usd
  }

  function getMarketChart(assetId: string): State<MarketChart> {
    const marketChart = marketCharts.value[assetId]
    if (!marketChart) {
      return { state: stateTypes.Loading }
    }
    return marketChart
  }

  return {
    fiatPrices,
    marketCharts,
    isFetchingFiatPrices,
    stopPollingFiatPrices,
    startPollingFiatPrices,
    fetchNewFiatPricesNow,
    getFiatPrice,
    getFiatPriceAmount,
    getMarketChart,
  }
})
