export enum RangoChain {
  Solana = 'SOLANA',
}

export type ConnectedChainWithAssets = {
  blockchain: string
  assets: {
    blockchain: string
    symbol: string
    address: string
  }[]
}

export type ConnectedAssetResponse = {
  data: ConnectedChainWithAssets[]
}
