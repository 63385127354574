/**
  * @type {const}
*/
export const allRoutesRango = [
  {
    "asset": "SOLANA.USDT-ES9VMFRZACERMJFRF4H2FYD4KCONKY11MCCE8BENWNYB",
    "assetId": "SOLANA.USDT-ES9VMFRZACERMJFRF4H2FYD4KCONKY11MCCE8BENWNYB",
    "assetAddress": "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
    "assetName": "USDT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.usdt-es9vmfrzacermjfrf4h2fyd4kconky11mcce8benwnyb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BNB-9GP2KCY3WA1CTVYWQK75GUQXUHFREOMQYDHLTCTCQILA",
    "assetId": "SOLANA.BNB-9GP2KCY3WA1CTVYWQK75GUQXUHFREOMQYDHLTCTCQILA",
    "assetAddress": "9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa",
    "assetName": "BNB",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bnb-9gp2kcy3wa1ctvywqk75guqxuhfreomqydhltctcqila.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.USDC-EPJFWDD5AUFQSSQEM2QN1XZYBAPC8G4WEGGKZWYTDT1V",
    "assetId": "SOLANA.USDC-EPJFWDD5AUFQSSQEM2QN1XZYBAPC8G4WEGGKZWYTDT1V",
    "assetAddress": "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    "assetName": "USDC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.usdc-epjfwdd5aufqssqem2qn1xzybapc8g4weggkzwytdt1v.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ADA-E4Q5PLAEIEJWEQHCM9GEYSQFMYGY8DJ4BPWGEYTHN24V",
    "assetId": "SOLANA.ADA-E4Q5PLAEIEJWEQHCM9GEYSQFMYGY8DJ4BPWGEYTHN24V",
    "assetAddress": "E4Q5pLaEiejwEQHcM9GeYSQfMyGy8DJ4bPWgeYthn24v",
    "assetName": "ADA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ada-e4q5plaeiejweqhcm9geysqfmygy8dj4bpwgeythn24v.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AVAX-AURMPCDYYCPUHHYNX8GEEQBMDPFUPBPHRNW3VPECFN5Z",
    "assetId": "SOLANA.AVAX-AURMPCDYYCPUHHYNX8GEEQBMDPFUPBPHRNW3VPECFN5Z",
    "assetAddress": "AUrMpCDYYcPuHhyNX8gEEqbmDPFUpBpHrNW3vPeCFn5Z",
    "assetName": "AVAX",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.avax-aurmpcdyycpuhhynx8geeqbmdpfupbphrnw3vpecfn5z.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AVAX-KGV1GVRHQMRBY8SHQQEUKWTM2R2H8T4C8QT12CW1HVE",
    "assetId": "SOLANA.AVAX-KGV1GVRHQMRBY8SHQQEUKWTM2R2H8T4C8QT12CW1HVE",
    "assetAddress": "KgV1GvrHQmRBY8sHQQeUKwTm2r2h8t4C8qt12Cw1HVE",
    "assetName": "AVAX",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.avax-kgv1gvrhqmrby8shqqeukwtm2r2h8t4c8qt12cw1hve.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DAI-EJMYN6QEC1TF1JXIG1AE7UTJHUXSWK1TCWNWQXWV4J6O",
    "assetId": "SOLANA.DAI-EJMYN6QEC1TF1JXIG1AE7UTJHUXSWK1TCWNWQXWV4J6O",
    "assetAddress": "EjmyN6qEC1Tf1JxiG1ae7UTJhUxSwk1TCWNWqxWV4J6o",
    "assetName": "DAI",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dai-ejmyn6qec1tf1jxig1ae7utjhuxswk1tcwnwqxwv4j6o.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NEAR-BYPSJXA3YUZESQZ1DKUBW1QSFCSPECSM8NCQHY5XBU1Z",
    "assetId": "SOLANA.NEAR-BYPSJXA3YUZESQZ1DKUBW1QSFCSPECSM8NCQHY5XBU1Z",
    "assetAddress": "BYPsjxa3YuZESQz1dKuBw1QSFCSpecsm8nCQhY5xbU1Z",
    "assetName": "NEAR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.near-bypsjxa3yuzesqz1dkubw1qsfcspecsm8ncqhy5xbu1z.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RENDER-RNDRIZKT3MK1IIMDXRDWABCF7ZG7AR5T4NUD4EKHBOF",
    "assetId": "SOLANA.RENDER-RNDRIZKT3MK1IIMDXRDWABCF7ZG7AR5T4NUD4EKHBOF",
    "assetAddress": "rndrizKT3MK1iimdxRdWabcF7Zg7AR5T4nud4EkHBof",
    "assetName": "RENDER",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.render-rndrizkt3mk1iimdxrdwabcf7zg7ar5t4nud4ekhbof.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HNT-HNTYVP6YFM1HG25TN9WGLQM12B8TQMCKNKRDU1OXWUX",
    "assetId": "SOLANA.HNT-HNTYVP6YFM1HG25TN9WGLQM12B8TQMCKNKRDU1OXWUX",
    "assetAddress": "hntyVP6YFm1Hg25TN9WGLqM12b8TQmcknKrdu1oxWux",
    "assetName": "HNT",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hnt-hntyvp6yfm1hg25tn9wglqm12b8tqmcknkrdu1oxwux.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FTM-ESPKHGTMF3BGOY4QM7PCV3UCCWQAMBC1UGHBTDXRJJD4",
    "assetId": "SOLANA.FTM-ESPKHGTMF3BGOY4QM7PCV3UCCWQAMBC1UGHBTDXRJJD4",
    "assetAddress": "EsPKhGTMf3bGoy4Qm7pCv3UCcWqAmbC1UGHBTDxRjjD4",
    "assetName": "FTM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ftm-espkhgtmf3bgoy4qm7pcv3uccwqambc1ughbtdxrjjd4.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PYTH-HZ1JOVNIVVGRGNIIYVEOZEVGZ58XAU3RKWX8EACQBCT3",
    "assetId": "SOLANA.PYTH-HZ1JOVNIVVGRGNIIYVEOZEVGZ58XAU3RKWX8EACQBCT3",
    "assetAddress": "HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3",
    "assetName": "PYTH",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pyth-hz1jovnivvgrgniiyveozevgz58xau3rkwx8eacqbct3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PYUSD-2B1KV6DKPANXD5IXFNXCPJXMKWQJJAYMCZFHSFU24GXO",
    "assetId": "SOLANA.PYUSD-2B1KV6DKPANXD5IXFNXCPJXMKWQJJAYMCZFHSFU24GXO",
    "assetAddress": "2b1kV6DkPAnxd5ixfnxCpjxmKwqjjaYmCZfHsFu24GXo",
    "assetName": "PYUSD",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pyusd-2b1kv6dkpanxd5ixfnxcpjxmkwqjjaymczfhsfu24gxo.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SEI-CRKWD2QEDQDI5U6W2W6JEAVIAUD1PR4AXS2AKVH7GW7M",
    "assetId": "SOLANA.SEI-CRKWD2QEDQDI5U6W2W6JEAVIAUD1PR4AXS2AKVH7GW7M",
    "assetAddress": "CRkwd2QedqDi5u6W2w6jeAViAUd1pR4AXs2aKvh7GW7M",
    "assetName": "SEI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sei-crkwd2qedqdi5u6w2w6jeaviaud1pr4axs2akvh7gw7m.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LDO-HZRCWXP2VQ9PCPPXOOAYHJ2BXTPO5XFPQRWB1SVH332P",
    "assetId": "SOLANA.LDO-HZRCWXP2VQ9PCPPXOOAYHJ2BXTPO5XFPQRWB1SVH332P",
    "assetAddress": "HZRCwxP2Vq9PCpPXooayhJ2bxTpo5xfpQrwB1svh332p",
    "assetName": "LDO",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ldo-hzrcwxp2vq9pcppxooayhj2bxtpo5xfpqrwb1svh332p.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NEO-NEO3D6MXRXF2IAFAQVZYQSMFKFUTLVNJM86XMFGWNH5",
    "assetId": "SOLANA.NEO-NEO3D6MXRXF2IAFAQVZYQSMFKFUTLVNJM86XMFGWNH5",
    "assetAddress": "NEo3D6MXRXf2iAfaqvZYqSmFkfutLvNjm86xmfGWNh5",
    "assetName": "NEO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.neo-neo3d6mxrxf2iafaqvzyqsmfkfutlvnjm86xmfgwnh5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SAND-49C7WUCZKQGC3M4QH8WUEUNXFGWUPZF1XQWKDQ7GJRGT",
    "assetId": "SOLANA.SAND-49C7WUCZKQGC3M4QH8WUEUNXFGWUPZF1XQWKDQ7GJRGT",
    "assetAddress": "49c7WuCZkQgc3M4qH8WuEUNXfgwupZf1xqWkDQ7gjRGt",
    "assetName": "SAND",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sand-49c7wuczkqgc3m4qh8wueunxfgwupzf1xqwkdq7gjrgt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DYDX-4HX6BJ56EGYW8EJRRHEM6LBQAVVYRIKYCWSALETRWYRU",
    "assetId": "SOLANA.DYDX-4HX6BJ56EGYW8EJRRHEM6LBQAVVYRIKYCWSALETRWYRU",
    "assetAddress": "4Hx6Bj56eGyw8EJrrheM6LBQAvVYRikYCWsALeTrwyRU",
    "assetName": "DYDX",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dydx-4hx6bj56egyw8ejrrhem6lbqavvyrikycwsaletrwyru.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.W-85VBFQZC9TZKFAPTBWJVUW7YBZJY52A6MJTPGJSTQAMQ",
    "assetId": "SOLANA.W-85VBFQZC9TZKFAPTBWJVUW7YBZJY52A6MJTPGJSTQAMQ",
    "assetAddress": "85VBFQZC9TZkfaptBWjvUw7YbZjy52A6mjtPGjstQAmQ",
    "assetName": "W",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.w-85vbfqzc9tzkfaptbwjvuw7ybzjy52a6mjtpgjstqamq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.POPCAT-7GCIHGDB8FE6KNJN2MYTKZZCRJQY3T9GHDC8UHYMW2HR",
    "assetId": "SOLANA.POPCAT-7GCIHGDB8FE6KNJN2MYTKZZCRJQY3T9GHDC8UHYMW2HR",
    "assetAddress": "7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr",
    "assetName": "POPCAT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.popcat-7gcihgdb8fe6knjn2mytkzzcrjqy3t9ghdc8uhymw2hr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MANA-7DGHON8WBZCC5WBNQ2C47TDNBMAXG4Q5L3KJP67Z8KNI",
    "assetId": "SOLANA.MANA-7DGHON8WBZCC5WBNQ2C47TDNBMAXG4Q5L3KJP67Z8KNI",
    "assetAddress": "7dgHoN8wBZCc5wbnQ2C47TDnBMAxG4Q5L3KjP67z8kNi",
    "assetName": "MANA",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mana-7dghon8wbzcc5wbnq2c47tdnbmaxg4q5l3kjp67z8kni.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOME-UKHH6C7MMYIWCF1B9PNWE25TSPKDDT3H5PQZGZ74J82",
    "assetId": "SOLANA.BOME-UKHH6C7MMYIWCF1B9PNWE25TSPKDDT3H5PQZGZ74J82",
    "assetAddress": "ukHH6c7mMyiWCf1b9pnWe25TSpkDDt3H5pQZgZ74J82",
    "assetName": "BOME",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bome-ukhh6c7mmyiwcf1b9pnwe25tspkddt3h5pqzgz74j82.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RAY-4K3DYJZVZP8EMZWUXBBCJEVWSKKK59S5ICNLY3QRKX6R",
    "assetId": "SOLANA.RAY-4K3DYJZVZP8EMZWUXBBCJEVWSKKK59S5ICNLY3QRKX6R",
    "assetAddress": "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
    "assetName": "RAY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ray-4k3dyjzvzp8emzwuxbbcjevwskkk59s5icnly3qrkx6r.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MEW-MEW1GQWJ3NEXG2QGERIKU7FAFJ79PHVQVREQUZSCPP5",
    "assetId": "SOLANA.MEW-MEW1GQWJ3NEXG2QGERIKU7FAFJ79PHVQVREQUZSCPP5",
    "assetAddress": "MEW1gQWJ3nEXg2qgERiKu7FAFj79PHvQVREQUzScPP5",
    "assetName": "MEW",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mew-mew1gqwj3nexg2qgeriku7fafj79phvqvrequzscpp5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TWT-HZNPQL7RT9GXF9EWOWSWZC5DFJZQ41XTQGEA7P3VZAAD",
    "assetId": "SOLANA.TWT-HZNPQL7RT9GXF9EWOWSWZC5DFJZQ41XTQGEA7P3VZAAD",
    "assetAddress": "HZNpqL7RT9gxf9eWoWsWzC5DfjzQ41XTQgEA7p3VzaaD",
    "assetName": "TWT",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.twt-hznpql7rt9gxf9ewowswzc5dfjzq41xtqgea7p3vzaad.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GMT-7I5KKSX2WEITKRY7JA4ZWSUXGHS5EJBEJY8VVXR4PFRX",
    "assetId": "SOLANA.GMT-7I5KKSX2WEITKRY7JA4ZWSUXGHS5EJBEJY8VVXR4PFRX",
    "assetAddress": "7i5KKsX2weiTkry7jA4ZwSuXGhs5eJBEjY8vVxR4pfRx",
    "assetName": "GMT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gmt-7i5kksx2weitkry7ja4zwsuxghs5ejbejy8vvxr4pfrx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JTO-JTOJTOMEPA8BEP8AUQC6EXT5FRIJWFFMWQX2V2F9MCL",
    "assetId": "SOLANA.JTO-JTOJTOMEPA8BEP8AUQC6EXT5FRIJWFFMWQX2V2F9MCL",
    "assetAddress": "jtojtomepa8beP8AuQc6eXt5FriJwfFMwQx2v2f9mCL",
    "assetName": "JTO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jto-jtojtomepa8bep8auqc6ext5frijwffmwqx2v2f9mcl.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PRIME-PRIME7GDOIG1VGR95A3CRMV9XHY7UGJD4JKVFSKMQU2",
    "assetId": "SOLANA.PRIME-PRIME7GDOIG1VGR95A3CRMV9XHY7UGJD4JKVFSKMQU2",
    "assetAddress": "PRiME7gDoiG1vGr95a3CRMv9xHY7UGjd4JKvfSkmQu2",
    "assetName": "PRIME",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.prime-prime7gdoig1vgr95a3crmv9xhy7ugjd4jkvfskmqu2.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BAT-EPEUFDGHRXS9XXEPVAL6KFGQVCON7JMAWKVUHUUX1TPZ",
    "assetId": "SOLANA.BAT-EPEUFDGHRXS9XXEPVAL6KFGQVCON7JMAWKVUHUUX1TPZ",
    "assetAddress": "EPeUFDgHRxs9xxEPVaL6kfGQvCon7jmAWKVUHuux1Tpz",
    "assetName": "BAT",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bat-epeufdghrxs9xxepval6kfgqvcon7jmawkvuhuux1tpz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LUNA-F6V4WFADJB8D8P77BMXZGYT8TDKSYXLYXH5AFHUKYX9W",
    "assetId": "SOLANA.LUNA-F6V4WFADJB8D8P77BMXZGYT8TDKSYXLYXH5AFHUKYX9W",
    "assetAddress": "F6v4wfAdJB8D8p77bMXZgYt8TDKsYxLYxH5AFhUkYx9W",
    "assetName": "LUNA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.luna-f6v4wfadjb8d8p77bmxzgyt8tdksyxlyxh5afhukyx9w.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.T-4NJVI3928U3FIGEF5TF8XVJLC5GQUN33OE4XTJNE7XXC",
    "assetId": "SOLANA.T-4NJVI3928U3FIGEF5TF8XVJLC5GQUN33OE4XTJNE7XXC",
    "assetAddress": "4Njvi3928U3figEF5tf8xvjLC5GqUN33oe4XTJNe7xXC",
    "assetName": "T",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.t-4njvi3928u3figef5tf8xvjlc5gqun33oe4xtjne7xxc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.IO-BZLBGTNCSFFOTH2GYDTWR7E4IMWZPR5JQCUUGEWR646K",
    "assetId": "SOLANA.IO-BZLBGTNCSFFOTH2GYDTWR7E4IMWZPR5JQCUUGEWR646K",
    "assetAddress": "BZLbGTNCSFfoth2GYDtwr7e4imWzpR5jqcUuGEwr646K",
    "assetName": "IO",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.io-bzlbgtncsffoth2gydtwr7e4imwzpr5jqcuugewr646k.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FRAX-FR87NWEUXVGERFGHZM8Y4AGGKGLNAXSWR1PD8WZ4KZCP",
    "assetId": "SOLANA.FRAX-FR87NWEUXVGERFGHZM8Y4AGGKGLNAXSWR1PD8WZ4KZCP",
    "assetAddress": "FR87nWEUxVgerFGhZM8Y4AggKGLnaXswr1Pd8wZ4kZcp",
    "assetName": "FRAX",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.frax-fr87nweuxvgerfghzm8y4aggkglnaxswr1pd8wz4kzcp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.USDY-A1KLOBRKBDE8TY9QTNQUTQ3C2ORTOC3U7TWGGZ7SETO6",
    "assetId": "SOLANA.USDY-A1KLOBRKBDE8TY9QTNQUTQ3C2ORTOC3U7TWGGZ7SETO6",
    "assetAddress": "A1KLoBrKBde8Ty9qtNQUtq3C2ortoC3u7twggz7sEto6",
    "assetName": "USDY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.usdy-a1klobrkbde8ty9qtnqutq3c2ortoc3u7twggz7seto6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RLB-RLBXXFKSEAZ4RGJH3SQN8JXXHMGOZ9JWXDNJMH8PL7A",
    "assetId": "SOLANA.RLB-RLBXXFKSEAZ4RGJH3SQN8JXXHMGOZ9JWXDNJMH8PL7A",
    "assetAddress": "RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a",
    "assetName": "RLB",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rlb-rlbxxfkseaz4rgjh3sqn8jxxhmgoz9jwxdnjmh8pl7a.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OSAK-GOXLANFQIQNV97P7ARGP4GHVLZ4GWJN9NUNPPOZVJZCV",
    "assetId": "SOLANA.OSAK-GOXLANFQIQNV97P7ARGP4GHVLZ4GWJN9NUNPPOZVJZCV",
    "assetAddress": "GoxLaNFQiqnV97p7aRGP4ghvLZ4GwJN9NUNPpozvJZCV",
    "assetName": "OSAK",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.osak-goxlanfqiqnv97p7argp4ghvlz4gwjn9nunppozvjzcv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HONEY-4VMSOUT2BWATFWEUDNQM1XEDRLFJGJ7HSWHCPZ4XGBTY",
    "assetId": "SOLANA.HONEY-4VMSOUT2BWATFWEUDNQM1XEDRLFJGJ7HSWHCPZ4XGBTY",
    "assetAddress": "4vMsoUT2BWatFweudnQM1xedRLfJgJ7hswhcpz4xgBTy",
    "assetName": "HONEY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.honey-4vmsout2bwatfweudnqm1xedrlfjgj7hswhcpz4xgbty.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AUDIO-9LZCMQDGTKYZ9DRZQNPGEE3SGA89UP3A247YPMJ2XRQM",
    "assetId": "SOLANA.AUDIO-9LZCMQDGTKYZ9DRZQNPGEE3SGA89UP3A247YPMJ2XRQM",
    "assetAddress": "9LzCMqDgTKYz9Drzqnpgee3SGa89up3a247ypMj2xrqM",
    "assetName": "AUDIO",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.audio-9lzcmqdgtkyz9drzqnpgee3sga89up3a247ypmj2xrqm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SUSHI-CHVZXWRMRTESGWD3UI3UUMCN8KX7VK3WAD4KGESKPYPJ",
    "assetId": "SOLANA.SUSHI-CHVZXWRMRTESGWD3UI3UUMCN8KX7VK3WAD4KGESKPYPJ",
    "assetAddress": "ChVzxWRmrTeSgwd3Ui3UumcN8KX7VK3WaD4KGeSKpypj",
    "assetName": "SUSHI",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sushi-chvzxwrmrtesgwd3ui3uumcn8kx7vk3wad4kgeskpypj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BORG-CFBDJAKONBBQTYG2GC6CMB7EXOFGDYGCDR8TP8KVGS7T",
    "assetId": "SOLANA.BORG-CFBDJAKONBBQTYG2GC6CMB7EXOFGDYGCDR8TP8KVGS7T",
    "assetAddress": "CFbdjaKonbBQTYG2GC6CmB7exofgDYGCDR8tp8KVGS7T",
    "assetName": "BORG",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.borg-cfbdjakonbbqtyg2gc6cmb7exofgdygcdr8tp8kvgs7t.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BORG-3DQTR7ROR2QPKQ3GBBCOKJUMJERGG8KTJZDNYJNFVI3Z",
    "assetId": "SOLANA.BORG-3DQTR7ROR2QPKQ3GBBCOKJUMJERGG8KTJZDNYJNFVI3Z",
    "assetAddress": "3dQTr7ror2QPKQ3GbBCokJUmjErGg8kTJzdnYjNfvi3Z",
    "assetName": "BORG",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.borg-3dqtr7ror2qpkq3gbbcokjumjergg8ktjzdnyjnfvi3z.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PONKE-5Z3EQYQO9HICES3R84RCDMU2N7ANPDMXRHDK8PSWMRRC",
    "assetId": "SOLANA.PONKE-5Z3EQYQO9HICES3R84RCDMU2N7ANPDMXRHDK8PSWMRRC",
    "assetAddress": "5z3EqYQo9HiCEs3R84RCDMu2n7anpDMxRhdK8PSWmrRC",
    "assetName": "PONKE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ponke-5z3eqyqo9hices3r84rcdmu2n7anpdmxrhdk8pswmrrc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ZIG-ANQY8H3SRSWKC29FVGJENAH7VGRABVVX7LS6MQ4BUGT",
    "assetId": "SOLANA.ZIG-ANQY8H3SRSWKC29FVGJENAH7VGRABVVX7LS6MQ4BUGT",
    "assetAddress": "ANqY8h3sRSwkC29FvGJenAh7VGRABVVx7Ls6Mq4BuGT",
    "assetName": "ZIG",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.zig-anqy8h3srswkc29fvgjenah7vgrabvvx7ls6mq4bugt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WAVES-4URN7VXRPWYP4HUAA4UNXWEPLRL8OQ71YBYMHR6YBNL4",
    "assetId": "SOLANA.WAVES-4URN7VXRPWYP4HUAA4UNXWEPLRL8OQ71YBYMHR6YBNL4",
    "assetAddress": "4uRn7vxRPWYP4HuAa4UNXwEPLRL8oQ71YByMhr6yBnL4",
    "assetName": "WAVES",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.waves-4urn7vxrpwyp4huaa4unxweplrl8oq71ybymhr6ybnl4.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DUSK-DMBB79FGXR3FKKVKWCBETMCUXNNTJNFJY3QQAE4G4WJF",
    "assetId": "SOLANA.DUSK-DMBB79FGXR3FKKVKWCBETMCUXNNTJNFJY3QQAE4G4WJF",
    "assetAddress": "DMbb79fgxR3fKKVKWcbetMCuxNNtJnfjY3qqAE4G4wJf",
    "assetName": "DUSK",
    "decimals": 3,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dusk-dmbb79fgxr3fkkvkwcbetmcuxnntjnfjy3qqae4g4wjf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DRIFT-DRIFTUPJYLTOSBWON8KOMBEYSX54AFAVLDDWSBKSJWG7",
    "assetId": "SOLANA.DRIFT-DRIFTUPJYLTOSBWON8KOMBEYSX54AFAVLDDWSBKSJWG7",
    "assetAddress": "DriFtupJYLTosbwoN8koMbEYSx54aFAVLddWsbksjwg7",
    "assetName": "DRIFT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.drift-driftupjyltosbwon8kombeysx54afavlddwsbksjwg7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MOBILE-MB1EU7TZEC71KXDPSMSKOUCSSUUOGLV1DRYS1OP2JH6",
    "assetId": "SOLANA.MOBILE-MB1EU7TZEC71KXDPSMSKOUCSSUUOGLV1DRYS1OP2JH6",
    "assetAddress": "mb1eu7TzEc71KxDpsmsKoucSSuuoGLv1drys1oP2jh6",
    "assetName": "MOBILE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mobile-mb1eu7tzec71kxdpsmskoucssuuoglv1drys1op2jh6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MPLX-METAEWGXYPBGWSSEH8T16A39CQ5VYVXZI9ZXIDPY18M",
    "assetId": "SOLANA.MPLX-METAEWGXYPBGWSSEH8T16A39CQ5VYVXZI9ZXIDPY18M",
    "assetAddress": "METAewgxyPbgwsseH8T16a39CQ5VyVxZi9zXiDPY18m",
    "assetName": "MPLX",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mplx-metaewgxypbgwsseh8t16a39cq5vyvxzi9zxidpy18m.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ORCA-ORCAEKTDK7LKZ57VAAYR9QENSVEPFIU6QEMU1KEKTZE",
    "assetId": "SOLANA.ORCA-ORCAEKTDK7LKZ57VAAYR9QENSVEPFIU6QEMU1KEKTZE",
    "assetAddress": "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
    "assetName": "ORCA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.orca-orcaektdk7lkz57vaayr9qensvepfiu6qemu1kektze.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NOS-NOSXBVOACTTYDLVKY6CSB4AC8JCDQKKAAWYTX2ZMOO7",
    "assetId": "SOLANA.NOS-NOSXBVOACTTYDLVKY6CSB4AC8JCDQKKAAWYTX2ZMOO7",
    "assetAddress": "nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7",
    "assetName": "NOS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nos-nosxbvoacttydlvky6csb4ac8jcdqkkaawytx2zmoo7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.C98-C98A4NKJXHPVZNAZDHUA95RPTF3T4WHTQUBL3YOBIUX9",
    "assetId": "SOLANA.C98-C98A4NKJXHPVZNAZDHUA95RPTF3T4WHTQUBL3YOBIUX9",
    "assetAddress": "C98A4nkJXhpVZNAZdHUA95RpTF3T4whtQubL3YobiUX9",
    "assetName": "C98",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.c98-c98a4nkjxhpvznazdhua95rptf3t4whtqubl3yobiux9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHEX-6DKCOWJPJ5MFU5GWDEFDPUUEBASBLK3WLEWHUZQPAA1E",
    "assetId": "SOLANA.CHEX-6DKCOWJPJ5MFU5GWDEFDPUUEBASBLK3WLEWHUZQPAA1E",
    "assetAddress": "6dKCoWjpj5MFU5gWDEFdpUUeBasBLK3wLEwhUzQPAa1e",
    "assetName": "CHEX",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chex-6dkcowjpj5mfu5gwdefdpuuebasblk3wlewhuzqpaa1e.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SLERF-7BGBVYJRZX1YKZ4OH9MJB8ZSCATKKWB8DZFX7LOIVKM3",
    "assetId": "SOLANA.SLERF-7BGBVYJRZX1YKZ4OH9MJB8ZSCATKKWB8DZFX7LOIVKM3",
    "assetAddress": "7BgBvyjrZX1YKz4oh9mjb8ZScatkkwb8DzFx7LoiVkM3",
    "assetName": "SLERF",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.slerf-7bgbvyjrzx1ykz4oh9mjb8zscatkkwb8dzfx7loivkm3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EURC-HZWQBKZW8HXMN6BF2YFZNRHT3C2IXXZPKCFU7UBEDKTR",
    "assetId": "SOLANA.EURC-HZWQBKZW8HXMN6BF2YFZNRHT3C2IXXZPKCFU7UBEDKTR",
    "assetAddress": "HzwqbKZw8HxMN6bF2yFZNrht3c2iXXzpKcFu7uBEDKtr",
    "assetName": "EURC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.eurc-hzwqbkzw8hxmn6bf2yfznrht3c2ixxzpkcfu7ubedktr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ACS-5MAYDFQ5YXTUDAHTFYUMBUHZJGABAS9TBEYEQYAHDS5Y",
    "assetId": "SOLANA.ACS-5MAYDFQ5YXTUDAHTFYUMBUHZJGABAS9TBEYEQYAHDS5Y",
    "assetAddress": "5MAYDfq5yxtudAhtfyuMBuHZjgAbaS9tbEyEQYAhDS5y",
    "assetName": "ACS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.acs-5maydfq5yxtudahtfyumbuhzjgabas9tbeyeqyahds5y.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RAD-B6AJ3TGFME3SMNLSOUHXQWXJVFQYYQJ7CZZHZR8WJFAI",
    "assetId": "SOLANA.RAD-B6AJ3TGFME3SMNLSOUHXQWXJVFQYYQJ7CZZHZR8WJFAI",
    "assetAddress": "B6aJ3TGfme3SMnLSouHXqWXjVFqYyqj7czzhzr8WJFAi",
    "assetName": "RAD",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rad-b6aj3tgfme3smnlsouhxqwxjvfqyyqj7czzhzr8wjfai.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KIN-KINXDECPDQEHPEUQNQMUGTYYKQKGVFQ6CEVX5IAHJQ6",
    "assetId": "SOLANA.KIN-KINXDECPDQEHPEUQNQMUGTYYKQKGVFQ6CEVX5IAHJQ6",
    "assetAddress": "kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6",
    "assetName": "KIN",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.kin-kinxdecpdqehpeuqnqmugtyykqkgvfq6cevx5iahjq6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TNSR-TNSRXCUXOT9XBG3DE7PIJYTDYU7KSKLQCPDDXNEJAS6",
    "assetId": "SOLANA.TNSR-TNSRXCUXOT9XBG3DE7PIJYTDYU7KSKLQCPDDXNEJAS6",
    "assetAddress": "TNSRxcUxoT9xBG3de7PiJyTDYu7kskLqcpddxnEJAS6",
    "assetName": "TNSR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tnsr-tnsrxcuxot9xbg3de7pijytdyu7ksklqcpddxnejas6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MANEKI-25HAYBQFODHFWX9AY6RARBGVWGWDDNQCHSXS3JQ3MTDJ",
    "assetId": "SOLANA.MANEKI-25HAYBQFODHFWX9AY6RARBGVWGWDDNQCHSXS3JQ3MTDJ",
    "assetAddress": "25hAyBQfoDhfWx9ay6rarbgvWGwDdNqcHsXS3jQ3mTDJ",
    "assetName": "MANEKI",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.maneki-25haybqfodhfwx9ay6rarbgvwgwddnqchsxs3jq3mtdj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BWB-6FVYLVHQSSHWVUSCQ2FJRR1MRECGSHC3QXBWWTGIVFWK",
    "assetId": "SOLANA.BWB-6FVYLVHQSSHWVUSCQ2FJRR1MRECGSHC3QXBWWTGIVFWK",
    "assetAddress": "6FVyLVhQsShWVUsCq2FJRr1MrECGShc3QxBwWtgiVFwK",
    "assetName": "BWB",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bwb-6fvylvhqsshwvuscq2fjrr1mrecgshc3qxbwwtgivfwk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ATR-ATRLUHPH8DXNPNY4WSNW7FXKHBEIVBRTWBY6BFB4XPLJ",
    "assetId": "SOLANA.ATR-ATRLUHPH8DXNPNY4WSNW7FXKHBEIVBRTWBY6BFB4XPLJ",
    "assetAddress": "ATRLuHph8dxnPny4WSNW7fxkhbeivBrtWbY6BfB4xpLj",
    "assetName": "ATR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.atr-atrluhph8dxnpny4wsnw7fxkhbeivbrtwby6bfb4xplj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PERP-EBQ6GWBQNXA2ZB4TWR5GWP6CKEAHQZZZEDGEP7BTTDM3",
    "assetId": "SOLANA.PERP-EBQ6GWBQNXA2ZB4TWR5GWP6CKEAHQZZZEDGEP7BTTDM3",
    "assetAddress": "EBQ6gWBQNxA2zB4twR5GWP6CkeAhqZZZeDgeP7BTtdM3",
    "assetName": "PERP",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.perp-ebq6gwbqnxa2zb4twr5gwp6ckeahqzzzedgep7bttdm3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MOTHER-3S8QX1MSMQRBIWKG2CQYX7NIS1OHMGACUC9C4VFVVDPN",
    "assetId": "SOLANA.MOTHER-3S8QX1MSMQRBIWKG2CQYX7NIS1OHMGACUC9C4VFVVDPN",
    "assetAddress": "3S8qX1MsMqRbiwKg2cQyx7nis1oHMgaCuc9c4VfvVdPN",
    "assetName": "MOTHER",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mother-3s8qx1msmqrbiwkg2cqyx7nis1ohmgacuc9c4vfvvdpn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RETARDIO-6OGZHHZDRQR9PGV6HZ2MNZE7URZBMAFYBBWUYP1FHITX",
    "assetId": "SOLANA.RETARDIO-6OGZHHZDRQR9PGV6HZ2MNZE7URZBMAFYBBWUYP1FHITX",
    "assetAddress": "6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx",
    "assetName": "RETARDIO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.retardio-6ogzhhzdrqr9pgv6hz2mnze7urzbmafybbwuyp1fhitx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ALEPH-3UCMISNKCNKPE1PGQ5GGPCBV6DXGVUY16TMMUE1WPG9X",
    "assetId": "SOLANA.ALEPH-3UCMISNKCNKPE1PGQ5GGPCBV6DXGVUY16TMMUE1WPG9X",
    "assetAddress": "3UCMiSnkcnkPE1pgQ5ggPCBv6dXgVUy16TmMUe1WpG9x",
    "assetName": "ALEPH",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.aleph-3ucmisnkcnkpe1pgq5ggpcbv6dxgvuy16tmmue1wpg9x.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HUNT-CTYIHF58UGSHFHTPKTWX7VJPDA779DD6IVAED281FEVX",
    "assetId": "SOLANA.HUNT-CTYIHF58UGSHFHTPKTWX7VJPDA779DD6IVAED281FEVX",
    "assetAddress": "CTYiHf58UGShfHtpkTwx7vjPDA779dd6iVaeD281fEVx",
    "assetName": "HUNT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hunt-ctyihf58ugshfhtpktwx7vjpda779dd6ivaed281fevx.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FIDA-ECHESYFXEPKDLTOIZSL8PBE8MYAGYY8ZRQSACNCFGNVP",
    "assetId": "SOLANA.FIDA-ECHESYFXEPKDLTOIZSL8PBE8MYAGYY8ZRQSACNCFGNVP",
    "assetAddress": "EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp",
    "assetName": "FIDA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fida-echesyfxepkdltoizsl8pbe8myagyy8zrqsacncfgnvp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.IOT-IOTEVVZLEYWOTN1QDWNPDDXPWSZN3ZFHEOT3MFL9FNS",
    "assetId": "SOLANA.IOT-IOTEVVZLEYWOTN1QDWNPDDXPWSZN3ZFHEOT3MFL9FNS",
    "assetAddress": "iotEVVZLEywoTn1QdwNPddxPWszn3zFhEot3MfL9fns",
    "assetName": "IOT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.iot-iotevvzleywotn1qdwnpddxpwszn3zfheot3mfl9fns.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PSG-DM8YLBUJRA6ARVKUZ23D4UKQLDZBIGUHYWM4DUZNTNFA",
    "assetId": "SOLANA.PSG-DM8YLBUJRA6ARVKUZ23D4UKQLDZBIGUHYWM4DUZNTNFA",
    "assetAddress": "DM8YLBujra6arvKuZ23d4ukqLdzbiGuhyWM4dUZnTnFA",
    "assetName": "PSG",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.psg-dm8ylbujra6arvkuz23d4ukqldzbiguhywm4duzntnfa.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SAUCE-427XVZVKBFJ7ZYFFOYS9IFPNUNSRIJM6T9VP8ZNFKO9J",
    "assetId": "SOLANA.SAUCE-427XVZVKBFJ7ZYFFOYS9IFPNUNSRIJM6T9VP8ZNFKO9J",
    "assetAddress": "427xvZVKbFj7ZyfFoYS9iFpNuNsrijm6T9VP8znfko9j",
    "assetName": "SAUCE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sauce-427xvzvkbfj7zyffoys9ifpnunsrijm6t9vp8znfko9j.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.USDK-43M2EWFV5NDEPIEFJT9EMAQNC1HRTAF247RBPLGFEM5F",
    "assetId": "SOLANA.USDK-43M2EWFV5NDEPIEFJT9EMAQNC1HRTAF247RBPLGFEM5F",
    "assetAddress": "43m2ewFV5nDepieFjT9EmAQnc1HRtAF247RBpLGFem5F",
    "assetName": "USDK",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.usdk-43m2ewfv5ndepiefjt9emaqnc1hrtaf247rbplgfem5f.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KOKO-FSA54YL49WKS7RWOGV9SUCBSGWCWV756JTD349E6H2YW",
    "assetId": "SOLANA.KOKO-FSA54YL49WKS7RWOGV9SUCBSGWCWV756JTD349E6H2YW",
    "assetAddress": "FsA54yL49WKs7rWoGv9sUcbSGWCWV756jTD349e6H2yW",
    "assetName": "KOKO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.koko-fsa54yl49wks7rwogv9sucbsgwcwv756jtd349e6h2yw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SAMO-7XKXTG2CW87D97TXJSDPBD5JBKHETQA83TZRUJOSGASU",
    "assetId": "SOLANA.SAMO-7XKXTG2CW87D97TXJSDPBD5JBKHETQA83TZRUJOSGASU",
    "assetAddress": "7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU",
    "assetName": "SAMO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.samo-7xkxtg2cw87d97txjsdpbd5jbkhetqa83tzrujosgasu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SLND-SLNDPMOWTVADGEDNDYVWZRONL7ZSI1DF9PC3XHGTPWP",
    "assetId": "SOLANA.SLND-SLNDPMOWTVADGEDNDYVWZRONL7ZSI1DF9PC3XHGTPWP",
    "assetAddress": "SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp",
    "assetName": "SLND",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.slnd-slndpmowtvadgedndyvwzronl7zsi1df9pc3xhgtpwp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SD-8REEAVQ6KUJW2UKJYHFUTH5ETOAWSK4AAJ8P1APOMS9G",
    "assetId": "SOLANA.SD-8REEAVQ6KUJW2UKJYHFUTH5ETOAWSK4AAJ8P1APOMS9G",
    "assetAddress": "8rEEAvq6kujW2ukjYHfutH5eToAwsK4aaJ8p1ApoMs9g",
    "assetName": "SD",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sd-8reeavq6kujw2ukjyhfuth5etoawsk4aaj8p1apoms9g.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NEON-NEONTJSJSUO3REXG9O6VHUMXW62F9V7ZVMU8M8ZUT44",
    "assetId": "SOLANA.NEON-NEONTJSJSUO3REXG9O6VHUMXW62F9V7ZVMU8M8ZUT44",
    "assetAddress": "NeonTjSjsuo3rexg9o6vHuMXw62f9V7zvmu8M8Zut44",
    "assetName": "NEON",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.neon-neontjsjsuo3rexg9o6vhumxw62f9v7zvmu8m8zut44.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PRCL-4LLBSB5REP3YETYZMXEWYGJCIR5UXTKFURTAEUVC2AHS",
    "assetId": "SOLANA.PRCL-4LLBSB5REP3YETYZMXEWYGJCIR5UXTKFURTAEUVC2AHS",
    "assetAddress": "4LLbsb5ReP3yEtYzmXewyGjcir5uXtKFURtaEUVC2AHs",
    "assetName": "PRCL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.prcl-4llbsb5rep3yetyzmxewygjcir5uxtkfurtaeuvc2ahs.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MATH-CAGA7PDDFXS65GZNQWP42KBHKJQDCEOFVT7AQYO8JR8Q",
    "assetId": "SOLANA.MATH-CAGA7PDDFXS65GZNQWP42KBHKJQDCEOFVT7AQYO8JR8Q",
    "assetAddress": "CaGa7pddFXS65Gznqwp42kBhkJQdceoFVT7AQYo8Jr8Q",
    "assetName": "MATH",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.math-caga7pddfxs65gznqwp42kbhkjqdceofvt7aqyo8jr8q.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SIX-HWSQJDWEMJI7TNIKQPUDUJ86LXYF3VGATWM5EPK5KZGD",
    "assetId": "SOLANA.SIX-HWSQJDWEMJI7TNIKQPUDUJ86LXYF3VGATWM5EPK5KZGD",
    "assetAddress": "HWSqJdwemji7TNiKQPudUj86LXyF3vGAtWm5ePk5KzgD",
    "assetName": "SIX",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.six-hwsqjdwemji7tnikqpuduj86lxyf3vgatwm5epk5kzgd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NATIX-FRYSI8LPKUBYB7VPSCCGGXPEWFUEEJIWEGRKKUHWPKCX",
    "assetId": "SOLANA.NATIX-FRYSI8LPKUBYB7VPSCCGGXPEWFUEEJIWEGRKKUHWPKCX",
    "assetAddress": "FRySi8LPkuByB7VPSCCggxpewFUeeJiwEGRKKuhwpKcX",
    "assetName": "NATIX",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.natix-frysi8lpkubyb7vpsccggxpewfueejiwegrkkuhwpkcx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SMOG-FS66V5XYTJAFO14LIPZ5HT93EUMAHMYIPCFQHLPU4SS8",
    "assetId": "SOLANA.SMOG-FS66V5XYTJAFO14LIPZ5HT93EUMAHMYIPCFQHLPU4SS8",
    "assetAddress": "FS66v5XYtJAFo14LiPz5HT93EUMAHmYipCfQhLpU4ss8",
    "assetName": "SMOG",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.smog-fs66v5xytjafo14lipz5ht93eumahmyipcfqhlpu4ss8.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MNGO-MANGOCZJ36AJZYKWVJ3VNYU4GTONJFVENJMVVWAXLAC",
    "assetId": "SOLANA.MNGO-MANGOCZJ36AJZYKWVJ3VNYU4GTONJFVENJMVVWAXLAC",
    "assetAddress": "MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac",
    "assetName": "MNGO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mngo-mangoczj36ajzykwvj3vnyu4gtonjfvenjmvvwaxlac.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AURY-AURYYDFXJIB1ZKTIR1JN1J9ECYUTJB6RKQVMTYAIXWPP",
    "assetId": "SOLANA.AURY-AURYYDFXJIB1ZKTIR1JN1J9ECYUTJB6RKQVMTYAIXWPP",
    "assetAddress": "AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP",
    "assetName": "AURY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.aury-auryydfxjib1zktir1jn1j9ecyutjb6rkqvmtyaixwpp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LIME-H3QMCAMH5LXTS9OGDWAMARXPSPSIXVQNY4YSFRQMRJQD",
    "assetId": "SOLANA.LIME-H3QMCAMH5LXTS9OGDWAMARXPSPSIXVQNY4YSFRQMRJQD",
    "assetAddress": "H3QMCaMh5LxtS9oGDwaMaRXPSPSiXVqnY4YsfrQMRjqD",
    "assetName": "LIME",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lime-h3qmcamh5lxts9ogdwamarxpspsixvqny4ysfrqmrjqd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HEGE-ULWSJMMPXMNRFPU6BJNK6RPRKXQD5JXUMPPS1FXHXFY",
    "assetId": "SOLANA.HEGE-ULWSJMMPXMNRFPU6BJNK6RPRKXQD5JXUMPPS1FXHXFY",
    "assetAddress": "ULwSJmmpxmnRfpu6BjnK6rprKXqD5jXUmPpS1FxHXFy",
    "assetName": "HEGE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hege-ulwsjmmpxmnrfpu6bjnk6rprkxqd5jxumpps1fxhxfy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DUKO-HLPTM5E6RTGH4EKGDPYFRNRHBJPKMYVDEEREEA2G7RF9",
    "assetId": "SOLANA.DUKO-HLPTM5E6RTGH4EKGDPYFRNRHBJPKMYVDEEREEA2G7RF9",
    "assetAddress": "HLptm5e6rTgh4EKgDpYFrnRHbjpkMyVdEeREEa2G7rf9",
    "assetName": "DUKO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.duko-hlptm5e6rtgh4ekgdpyfrnrhbjpkmyvdeereea2g7rf9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TOKE-AMGUMQEQW8H74TRC8UKKJZZWTXBDPS496WH4GLY2MCPO",
    "assetId": "SOLANA.TOKE-AMGUMQEQW8H74TRC8UKKJZZWTXBDPS496WH4GLY2MCPO",
    "assetAddress": "AmgUMQeqW8H74trc8UkKjzZWtxBdpS496wh4GLy2mCpo",
    "assetName": "TOKE",
    "decimals": 3,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.toke-amgumqeqw8h74trc8ukkjzzwtxbdps496wh4gly2mcpo.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SC-6D7NAB2XSLD7CAUWU1WKK6KBSJOHJMP2QZH9GEFVI5UI",
    "assetId": "SOLANA.SC-6D7NAB2XSLD7CAUWU1WKK6KBSJOHJMP2QZH9GEFVI5UI",
    "assetAddress": "6D7NaB2xsLd7cauWu1wKk6KBsJohJmP2qZH9GEfVi5Ui",
    "assetName": "SC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sc-6d7nab2xsld7cauwu1wkk6kbsjohjmp2qzh9gefvi5ui.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SC-A2GHNFPZVYEZX5PR63JDMMO9UYBPAAKQHHUPD5XD2N6V",
    "assetId": "SOLANA.SC-A2GHNFPZVYEZX5PR63JDMMO9UYBPAAKQHHUPD5XD2N6V",
    "assetAddress": "A2GHnfpZvyeZX5Pr63jdMmo9uYbpaaKqHHuPD5xD2n6v",
    "assetName": "SC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sc-a2ghnfpzvyezx5pr63jdmmo9uybpaakqhhupd5xd2n6v.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SLIM-XXXXA1SKNGWFTW2KFN8XAUW9XQ8HBZ5KVTCSESTT9FW",
    "assetId": "SOLANA.SLIM-XXXXA1SKNGWFTW2KFN8XAUW9XQ8HBZ5KVTCSESTT9FW",
    "assetAddress": "xxxxa1sKNGwFtw2kFn8XauW9xq8hBZ5kVtcSesTT9fW",
    "assetName": "SLIM",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.slim-xxxxa1skngwftw2kfn8xauw9xq8hbz5kvtcsestt9fw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HELLO-4H49HPGPHLNJNDRYIBWZVKOASR3RW1WJCEV19PHUBSS4",
    "assetId": "SOLANA.HELLO-4H49HPGPHLNJNDRYIBWZVKOASR3RW1WJCEV19PHUBSS4",
    "assetAddress": "4h49hPGphLNJNDRyiBwzvKoasR3rw1WJCEv19PhUbSS4",
    "assetName": "HELLO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hello-4h49hpgphlnjndryibwzvkoasr3rw1wjcev19phubss4.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$WAFFLES-8DOS8NZMGVZEAACXALKBK5FZTW4UUORP4YT8NEAXFDMB",
    "assetId": "SOLANA.$WAFFLES-8DOS8NZMGVZEAACXALKBK5FZTW4UUORP4YT8NEAXFDMB",
    "assetAddress": "8doS8nzmgVZEaACxALkbK5fZtw4UuoRp4Yt8NEaXfDMb",
    "assetName": "$WAFFLES",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.waffles-8dos8nzmgvzeaacxalkbk5fztw4uuorp4yt8neaxfdmb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SCP-5SMYPTYRCREVT27DW3XHGVVHA3ZCXLV4CAVT88PXJBGV",
    "assetId": "SOLANA.SCP-5SMYPTYRCREVT27DW3XHGVVHA3ZCXLV4CAVT88PXJBGV",
    "assetAddress": "5sMyPtYRcrEVt27DW3xhGVVha3zCXLv4caVt88PXjBgV",
    "assetName": "SCP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.scp-5smyptyrcrevt27dw3xhgvvha3zcxlv4cavt88pxjbgv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OTK-OCTO82DRBEDM8CSDAEKBYMVN86TBTGMPNDDME64PTQJ",
    "assetId": "SOLANA.OTK-OCTO82DRBEDM8CSDAEKBYMVN86TBTGMPNDDME64PTQJ",
    "assetAddress": "octo82drBEdm8CSDaEKBymVn86TBtgmPnDdmE64PTqJ",
    "assetName": "OTK",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.otk-octo82drbedm8csdaekbymvn86tbtgmpnddme64ptqj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.VCHF-AHHDRU5YZDJVKKR3WBNUDAYMVQL2UCJMQ63SZ3LFHSCH",
    "assetId": "SOLANA.VCHF-AHHDRU5YZDJVKKR3WBNUDAYMVQL2UCJMQ63SZ3LFHSCH",
    "assetAddress": "AhhdRu5YZdjVkKR3wbnUDaymVQL2ucjMQ63sZ3LFHsch",
    "assetName": "VCHF",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.vchf-ahhdru5yzdjvkkr3wbnudaymvql2ucjmq63sz3lfhsch.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SPX-J3NKXXXZCNNIMJKW9HYB2K4LUXGWB6T1FTPTQVSV3KFR",
    "assetId": "SOLANA.SPX-J3NKXXXZCNNIMJKW9HYB2K4LUXGWB6T1FTPTQVSV3KFR",
    "assetAddress": "J3NKxxXZcnNiMjKw9hYb2K4LUxgwB6t1FtPtQVsv3KFr",
    "assetName": "SPX",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.spx-j3nkxxxzcnnimjkw9hyb2k4luxgwb6t1ftptqvsv3kfr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOO-BOOOCKXQN9YTK2AQN5PWFTQEB9TH7CJ7IUKUVCSHWQX",
    "assetId": "SOLANA.BOO-BOOOCKXQN9YTK2AQN5PWFTQEB9TH7CJ7IUKUVCSHWQX",
    "assetAddress": "boooCKXQn9YTK2aqN5pWftQeb9TH7cj7iUKuVCShWQx",
    "assetName": "BOO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.boo-booockxqn9ytk2aqn5pwftqeb9th7cj7iukuvcshwqx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HAMMY-26KMQVGDUOB6REFNJ51YAABWWJND8UMTPNQGSHQ64UDR",
    "assetId": "SOLANA.HAMMY-26KMQVGDUOB6REFNJ51YAABWWJND8UMTPNQGSHQ64UDR",
    "assetAddress": "26KMQVgDUoB6rEfnJ51yAABWWJND8uMtpnQgsHQ64Udr",
    "assetName": "HAMMY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hammy-26kmqvgduob6refnj51yaabwwjnd8umtpnqgshq64udr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DC-FAUKSGCUQAD9SCAFZ3IF7IA1DASAXVONPTXWFS2GSCWN",
    "assetId": "SOLANA.DC-FAUKSGCUQAD9SCAFZ3IF7IA1DASAXVONPTXWFS2GSCWN",
    "assetAddress": "FaUKsgcuqAd9sCaFz3if7ia1DasaxVoNPTXWFs2GScWN",
    "assetName": "DC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dc-fauksgcuqad9scafz3if7ia1dasaxvonptxwfs2gscwn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLAMA-AVLHAHDCDQ4M4VHM4UG63OH7XC8JTK49DM5HOE9SAZQR",
    "assetId": "SOLANA.SOLAMA-AVLHAHDCDQ4M4VHM4UG63OH7XC8JTK49DM5HOE9SAZQR",
    "assetAddress": "AVLhahDcDQ4m4vHM4ug63oh7xc8Jtk49Dm5hoe9Sazqr",
    "assetName": "SOLAMA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solama-avlhahdcdq4m4vhm4ug63oh7xc8jtk49dm5hoe9sazqr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PIP-HHJOYWUP5AU6PNRVN4S2PWEERWXNZKHXKGYJRJMOBJLW",
    "assetId": "SOLANA.PIP-HHJOYWUP5AU6PNRVN4S2PWEERWXNZKHXKGYJRJMOBJLW",
    "assetAddress": "HHjoYwUp5aU6pnrvN4s2pwEErwXNZKhxKGYjRJMoBjLw",
    "assetName": "PIP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pip-hhjoywup5au6pnrvn4s2pweerwxnzkhxkgyjrjmobjlw.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GENE-GENETH5AMGSI8KHATQOEZP1XEXWZJ8VCUEPYNXDKRMYZ",
    "assetId": "SOLANA.GENE-GENETH5AMGSI8KHATQOEZP1XEXWZJ8VCUEPYNXDKRMYZ",
    "assetAddress": "GENEtH5amGSi8kHAtQoezp1XEXwZJ8vcuePYnXdKrMYz",
    "assetName": "GENE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gene-geneth5amgsi8khatqoezp1xexwzj8vcuepynxdkrmyz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DIO-BIDB55P4G3N1FGHWKFPXSOKBMQGCTL4QNZPDH1BVQXMD",
    "assetId": "SOLANA.DIO-BIDB55P4G3N1FGHWKFPXSOKBMQGCTL4QNZPDH1BVQXMD",
    "assetAddress": "BiDB55p4G3n1fGhwKFpxsokBMqgctL4qnZpDH1bVQxMD",
    "assetName": "DIO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dio-bidb55p4g3n1fghwkfpxsokbmqgctl4qnzpdh1bvqxmd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ELIX-5CBQ1HRIESW4ZHPFEK9GC8UT4CCMFHCBTDCA2XCBDUTO",
    "assetId": "SOLANA.ELIX-5CBQ1HRIESW4ZHPFEK9GC8UT4CCMFHCBTDCA2XCBDUTO",
    "assetAddress": "5cbq1HriesW4zHpFEk9Gc8UT4ccmfHcBTDCa2XcBduTo",
    "assetName": "ELIX",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.elix-5cbq1hriesw4zhpfek9gc8ut4ccmfhcbtdca2xcbduto.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DFL-DFL1ZNKAGPWM1BQAVQRJCZVHMWTFREAJTBZJWGSEONJH",
    "assetId": "SOLANA.DFL-DFL1ZNKAGPWM1BQAVQRJCZVHMWTFREAJTBZJWGSEONJH",
    "assetAddress": "DFL1zNkaGPWm1BqAVqRjCZvHmwTFrEaJtbzJWgseoNJh",
    "assetName": "DFL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dfl-dfl1znkagpwm1bqavqrjczvhmwtfreajtbzjwgseonjh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PAJAMAS-FVER7SSVY5GQAMAWF7QFB5MNUUMDDBPNPG4NCA4ZHOLW",
    "assetId": "SOLANA.PAJAMAS-FVER7SSVY5GQAMAWF7QFB5MNUUMDDBPNPG4NCA4ZHOLW",
    "assetAddress": "FvER7SsvY5GqAMawf7Qfb5MnUUmDdbPNPg4nCa4zHoLw",
    "assetName": "PAJAMAS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pajamas-fver7ssvy5gqamawf7qfb5mnuumddbpnpg4nca4zholw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MSI-2E7YNWRMTGXP9ABUMCPXVFJTSREVLJ4YMYRB4GUM4PDD",
    "assetId": "SOLANA.MSI-2E7YNWRMTGXP9ABUMCPXVFJTSREVLJ4YMYRB4GUM4PDD",
    "assetAddress": "2e7yNwrmTgXp9ABUmcPXvFJTSrEVLj4YMyrb4GUM4Pdd",
    "assetName": "MSI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.msi-2e7ynwrmtgxp9abumcpxvfjtsrevlj4ymyrb4gum4pdd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SMILEK-7X4FGIFFEQZS1TIUNVJZPT47GTLXAJ8JFN8G1HYYU6JH",
    "assetId": "SOLANA.SMILEK-7X4FGIFFEQZS1TIUNVJZPT47GTLXAJ8JFN8G1HYYU6JH",
    "assetAddress": "7x4FgiFfeqzs1tiUNvJzpt47GtLXAJ8Jfn8G1Hyyu6JH",
    "assetName": "SMILEK",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.smilek-7x4fgiffeqzs1tiunvjzpt47gtlxaj8jfn8g1hyyu6jh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FRA-F9MV7XXBRXZB1SP2JUOSWBCB3WHQM4QGMFDTVFNRZMNP",
    "assetId": "SOLANA.FRA-F9MV7XXBRXZB1SP2JUOSWBCB3WHQM4QGMFDTVFNRZMNP",
    "assetAddress": "F9mv7XXbrXZb1sP2JUoswbCB3WHQM4QGMFDTVfnRZMnP",
    "assetName": "FRA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fra-f9mv7xxbrxzb1sp2juoswbcb3whqm4qgmfdtvfnrzmnp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.YOM-YOMFPUQZ1WJWYSFD5TZJUTS3BNB8XS8MX9XZBV8RL39",
    "assetId": "SOLANA.YOM-YOMFPUQZ1WJWYSFD5TZJUTS3BNB8XS8MX9XZBV8RL39",
    "assetAddress": "yomFPUqz1wJwYSfD5tZJUtS3bNb8xs8mx9XzBv8RL39",
    "assetName": "YOM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.yom-yomfpuqz1wjwysfd5tzjuts3bnb8xs8mx9xzbv8rl39.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GARI-CKAKTYVZ6DKPYMVYQ9RH3UBRNNQYZAYD7IF4HJTJUVKS",
    "assetId": "SOLANA.GARI-CKAKTYVZ6DKPYMVYQ9RH3UBRNNQYZAYD7IF4HJTJUVKS",
    "assetAddress": "CKaKtYvz6dKPyMvYq9Rh3UBrnNqYZAyd7iF4hJtjUvks",
    "assetName": "GARI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gari-ckaktyvz6dkpymvyq9rh3ubrnnqyzayd7if4hjtjuvks.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ELON-7ZCM8WBN9ALA3O47SOYCTU6ILDJ7WKGG5SV2HE5CGTD5",
    "assetId": "SOLANA.ELON-7ZCM8WBN9ALA3O47SOYCTU6ILDJ7WKGG5SV2HE5CGTD5",
    "assetAddress": "7ZCm8WBN9aLa3o47SoYctU6iLdj7wkGG5SV2hE5CgtD5",
    "assetName": "ELON",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.elon-7zcm8wbn9ala3o47soyctu6ildj7wkgg5sv2he5cgtd5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SIN-SIN1URE1CMCWR7VPLDZRGRVKS8UVKMSGZHVPJLG4LD9",
    "assetId": "SOLANA.SIN-SIN1URE1CMCWR7VPLDZRGRVKS8UVKMSGZHVPJLG4LD9",
    "assetAddress": "sin1uRe1cMCWR7VPLdZrGrvKs8UvKMsGzhvpJLg4Ld9",
    "assetName": "SIN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sin-sin1ure1cmcwr7vpldzrgrvks8uvkmsgzhvpjlg4ld9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLCEX-AMJZRN1TBQWQFNAJHFEBB7UGBBQBJB7FZXANGGDFPK6K",
    "assetId": "SOLANA.SOLCEX-AMJZRN1TBQWQFNAJHFEBB7UGBBQBJB7FZXANGGDFPK6K",
    "assetAddress": "AMjzRn1TBQwQfNAjHFeBb7uGbbqbJB7FzXAnGgdFPk6K",
    "assetName": "SOLCEX",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solcex-amjzrn1tbqwqfnajhfebb7ugbbqbjb7fzxanggdfpk6k.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHEEMS-3FOUASGDBVTD6YZ4WVKJGTB76ONJUKZ7GPEBNIR5B8WC",
    "assetId": "SOLANA.CHEEMS-3FOUASGDBVTD6YZ4WVKJGTB76ONJUKZ7GPEBNIR5B8WC",
    "assetAddress": "3FoUAsGDbvTD6YZ4wVKJgTB76onJUKz7GPEBNiR5b8wc",
    "assetName": "CHEEMS",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cheems-3fouasgdbvtd6yz4wvkjgtb76onjukz7gpebnir5b8wc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.VEUR-C4KKR9NZU3VBYEDCGUTU6LKMI6MKZ81SX6GRMK5PX519",
    "assetId": "SOLANA.VEUR-C4KKR9NZU3VBYEDCGUTU6LKMI6MKZ81SX6GRMK5PX519",
    "assetAddress": "C4Kkr9NZU3VbyedcgutU6LKmi6MKz81sx6gRmk5pX519",
    "assetName": "VEUR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.veur-c4kkr9nzu3vbyedcgutu6lkmi6mkz81sx6grmk5px519.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MVP-55QMV1HTV8FQRJNFWDB9YDI9TBCEV8XWFGRPKGIJK5DN",
    "assetId": "SOLANA.MVP-55QMV1HTV8FQRJNFWDB9YDI9TBCEV8XWFGRPKGIJK5DN",
    "assetAddress": "55qMv1HtV8fqRjnFwDb9yDi9tBCeV8xwfgrPKgiJk5DN",
    "assetName": "MVP",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mvp-55qmv1htv8fqrjnfwdb9ydi9tbcev8xwfgrpkgijk5dn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHI-ARG9WFELN4QZTXGYTYEUGTGFMMYDK5ZFHBUSNTFXXUVB",
    "assetId": "SOLANA.CHI-ARG9WFELN4QZTXGYTYEUGTGFMMYDK5ZFHBUSNTFXXUVB",
    "assetAddress": "ARg9wfeLN4qZTxgYTYeuGtGFMmYdk5zFhBuSnTfXXUvb",
    "assetName": "CHI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chi-arg9wfeln4qztxgytyeugtgfmmydk5zfhbusntfxxuvb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HUND-2XPQOKFJITK8YCMDGBKY7CMZRRYF2X9PNIZECYKDUZEV",
    "assetId": "SOLANA.HUND-2XPQOKFJITK8YCMDGBKY7CMZRRYF2X9PNIZECYKDUZEV",
    "assetAddress": "2XPqoKfJitk8YcMDGBKy7CMzRRyF2X9PniZeCykDUZev",
    "assetName": "HUND",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hund-2xpqokfjitk8ycmdgbky7cmzrryf2x9pnizecykduzev.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FCON-HOVGJRBGTFNA4DVG6EXKMXXUEXB3TUFEZKCUT8AWOWXJ",
    "assetId": "SOLANA.FCON-HOVGJRBGTFNA4DVG6EXKMXXUEXB3TUFEZKCUT8AWOWXJ",
    "assetAddress": "HovGjrBGTfna4dvg6exkMxXuexB3tUfEZKcut8AWowXj",
    "assetName": "FCON",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fcon-hovgjrbgtfna4dvg6exkmxxuexb3tufezkcut8awowxj.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CDT-AK3OVNWQNAXPSFOSNCONYJLNJTQDCKRBH4HWHWKB6HFM",
    "assetId": "SOLANA.CDT-AK3OVNWQNAXPSFOSNCONYJLNJTQDCKRBH4HWHWKB6HFM",
    "assetAddress": "Ak3ovnWQnAxPSFoSNCoNYJLnJtQDCKRBH4HwhWkb6hFm",
    "assetName": "CDT",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cdt-ak3ovnwqnaxpsfosnconyjlnjtqdckrbh4hwhwkb6hfm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.POWSCHE-8CKISHHJDHJV4LUOIRMLUHQG58CUKBYJRTCP4Z3MCXNF",
    "assetId": "SOLANA.POWSCHE-8CKISHHJDHJV4LUOIRMLUHQG58CUKBYJRTCP4Z3MCXNF",
    "assetAddress": "8CkiSHHJDHJV4LUoiRMLUhqG58cUkbyJRtcP4Z3mCXNf",
    "assetName": "POWSCHE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.powsche-8ckishhjdhjv4luoirmluhqg58cukbyjrtcp4z3mcxnf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.COK-DNB9DLSXXAARXVEXEHZEH8W8NFMLMNJSUGOADDZSWTOG",
    "assetId": "SOLANA.COK-DNB9DLSXXAARXVEXEHZEH8W8NFMLMNJSUGOADDZSWTOG",
    "assetAddress": "Dnb9dLSXxAarXVexehzeH8W8nFmLMNJSuGoaddZSwtog",
    "assetName": "COK",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cok-dnb9dlsxxaarxvexehzeh8w8nfmlmnjsugoaddzswtog.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WAM-7HDEO5QCIUF8S2VFSX6URJKDNVADBU3DDCXW4ZJDCMIN",
    "assetId": "SOLANA.WAM-7HDEO5QCIUF8S2VFSX6URJKDNVADBU3DDCXW4ZJDCMIN",
    "assetAddress": "7hdeo5QciUF8S2vfsx6uRJkdNVADBU3DDcXW4zjDcMin",
    "assetName": "WAM",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wam-7hdeo5qciuf8s2vfsx6urjkdnvadbu3ddcxw4zjdcmin.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GST-AFBX8OGJGPMVFYWBVOUVHQSRMIW2AR1MOHFAHI4Y2ADB",
    "assetId": "SOLANA.GST-AFBX8OGJGPMVFYWBVOUVHQSRMIW2AR1MOHFAHI4Y2ADB",
    "assetAddress": "AFbX8oGjGpmVFywbVouvhQSRmiW2aR1mohfahi4Y2AdB",
    "assetName": "GST",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gst-afbx8ogjgpmvfywbvouvhqsrmiw2ar1mohfahi4y2adb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CWAR-HFYFJMKNZYGFMC8LSQ8LTPPSPXEJOXJX4M6TQI75HAJO",
    "assetId": "SOLANA.CWAR-HFYFJMKNZYGFMC8LSQ8LTPPSPXEJOXJX4M6TQI75HAJO",
    "assetAddress": "HfYFjMKNZygfMC8LsQ8LtpPsPxEJoXJx4M6tqi75Hajo",
    "assetName": "CWAR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cwar-hfyfjmknzygfmc8lsq8ltppspxejoxjx4m6tqi75hajo.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CRWNY-CRWNYKQDGVHGGAE9CKFNKA58J6QQKAD5BLHKXVUYQNC1",
    "assetId": "SOLANA.CRWNY-CRWNYKQDGVHGGAE9CKFNKA58J6QQKAD5BLHKXVUYQNC1",
    "assetAddress": "CRWNYkqdgvhGGae9CKfNka58j6QQkaD5bLhKXvUYqnc1",
    "assetName": "CRWNY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.crwny-crwnykqdgvhggae9ckfnka58j6qqkad5blhkxvuyqnc1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.XTAG-5GS8NF4WOJB5EXGDUWNLWXPKNZGV2YWDHVEAEBZPVLBP",
    "assetId": "SOLANA.XTAG-5GS8NF4WOJB5EXGDUWNLWXPKNZGV2YWDHVEAEBZPVLBP",
    "assetAddress": "5gs8nf4wojB5EXgDUWNLwXpknzgV2YWDhveAeBZpVLbp",
    "assetName": "XTAG",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.xtag-5gs8nf4wojb5exgduwnlwxpknzgv2ywdhveaebzpvlbp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.VNXAU-9TPL8DROGJ7JTHSQ4MOMAOZ6UHTCVX2SEMQIPOPMNA8R",
    "assetId": "SOLANA.VNXAU-9TPL8DROGJ7JTHSQ4MOMAOZ6UHTCVX2SEMQIPOPMNA8R",
    "assetAddress": "9TPL8droGJ7jThsq4momaoz6uhTcvX2SeMqipoPmNa8R",
    "assetName": "VNXAU",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.vnxau-9tpl8drogj7jthsq4momaoz6uhtcvx2semqipopmna8r.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BIRD-7Y2KPMZSXMJCN8OGA3PSFWZVMYUGMUWO7NWY4CT5JRHP",
    "assetId": "SOLANA.BIRD-7Y2KPMZSXMJCN8OGA3PSFWZVMYUGMUWO7NWY4CT5JRHP",
    "assetAddress": "7y2KpMzsxmjCN8ogA3PSfwZvmYUgMuWo7NWY4Ct5jRHp",
    "assetName": "BIRD",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bird-7y2kpmzsxmjcn8oga3psfwzvmyugmuwo7nwy4ct5jrhp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WLKN-ECQCUYV57C4V6ROPXKVUIDWTX1SP8Y8FP5AETOYL8AZ",
    "assetId": "SOLANA.WLKN-ECQCUYV57C4V6ROPXKVUIDWTX1SP8Y8FP5AETOYL8AZ",
    "assetAddress": "EcQCUYv57C4V6RoPxkVUiDwtX1SP8y8FP5AEToYL8Az",
    "assetName": "WLKN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wlkn-ecqcuyv57c4v6ropxkvuidwtx1sp8y8fp5aetoyl8az.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TROG-2CPCDYVCPXZWYWFHFQGMJQXNMGEMDGOHHUQUF4PPWUAH",
    "assetId": "SOLANA.TROG-2CPCDYVCPXZWYWFHFQGMJQXNMGEMDGOHHUQUF4PPWUAH",
    "assetAddress": "2cpCdyVCpxzwYWFhFqgMJqxnMgEMDGohHUQuf4ppWuAH",
    "assetName": "TROG",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.trog-2cpcdyvcpxzwywfhfqgmjqxnmgemdgohhuquf4ppwuah.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MAPS-MAPS41MDAHZ9QDKXHVA4DWB9RUYFV4XQHYAZ8XCYEPB",
    "assetId": "SOLANA.MAPS-MAPS41MDAHZ9QDKXHVA4DWB9RUYFV4XQHYAZ8XCYEPB",
    "assetAddress": "MAPS41MDahZ9QdKXhVa4dWB9RuyfV4XqhyAZ8XcYepb",
    "assetName": "MAPS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.maps-maps41mdahz9qdkxhva4dwb9ruyfv4xqhyaz8xcyepb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DEFI-FEFECFI5DHAHY51XQQTP2QJFGHNUOSJRVIVL6K9ERCW6",
    "assetId": "SOLANA.DEFI-FEFECFI5DHAHY51XQQTP2QJFGHNUOSJRVIVL6K9ERCW6",
    "assetAddress": "Fefecfi5DhahY51XqQTP2qjFGhnuoSjrVivL6k9Ercw6",
    "assetName": "DEFI",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.defi-fefecfi5dhahy51xqqtp2qjfghnuosjrvivl6k9ercw6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GRLC-88YQDBWXYHHWPBEXF966EDACYBKP51XVM1OGBCBWZCF2",
    "assetId": "SOLANA.GRLC-88YQDBWXYHHWPBEXF966EDACYBKP51XVM1OGBCBWZCF2",
    "assetAddress": "88YqDBWxYhhwPbExF966EdaCYBKP51xVm1oGBcbWzcf2",
    "assetName": "GRLC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.grlc-88yqdbwxyhhwpbexf966edacybkp51xvm1ogbcbwzcf2.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KEKE-GGKDDEJ9N2NCHHONE5QSXVGPKGQNSBEXEKR1SHDLAPPV",
    "assetId": "SOLANA.KEKE-GGKDDEJ9N2NCHHONE5QSXVGPKGQNSBEXEKR1SHDLAPPV",
    "assetAddress": "GgKDdEJ9n2NCHHonE5qSxVgPKGQNsbeXEkr1SHDLapPv",
    "assetName": "KEKE",
    "decimals": 7,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.keke-ggkddej9n2nchhone5qsxvgpkgqnsbexekr1shdlappv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EGO-2ZJMVX4KDWTNWQYJHBNXGPMMSAOXWFTB7SGV4QVV18XA",
    "assetId": "SOLANA.EGO-2ZJMVX4KDWTNWQYJHBNXGPMMSAOXWFTB7SGV4QVV18XA",
    "assetAddress": "2zjmVX4KDWtNWQyJhbNXgpMmsAoXwftB7sGV4qvV18xa",
    "assetName": "EGO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ego-2zjmvx4kdwtnwqyjhbnxgpmmsaoxwftb7sgv4qvv18xa.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.UPDOG-HJ39RRZ6YS22KDB3USXDGNSL7RKIQMSC3YL8AS3SUUKU",
    "assetId": "SOLANA.UPDOG-HJ39RRZ6YS22KDB3USXDGNSL7RKIQMSC3YL8AS3SUUKU",
    "assetAddress": "HJ39rRZ6ys22KdB3USxDgNsL7RKiQmsC3yL8AS3Suuku",
    "assetName": "UPDOG",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.updog-hj39rrz6ys22kdb3usxdgnsl7rkiqmsc3yl8as3suuku.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EPIKO-6EP1SNB9UWNI3NBZUX84USSRBHKRZMAXEMCIA479CWTH",
    "assetId": "SOLANA.EPIKO-6EP1SNB9UWNI3NBZUX84USSRBHKRZMAXEMCIA479CWTH",
    "assetAddress": "6ep1sNB9UWni3nBZUX84USsRBhKRzMAxEmcia479cWTh",
    "assetName": "EPIKO",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.epiko-6ep1snb9uwni3nbzux84ussrbhkrzmaxemcia479cwth.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SHILL-6CVGJUQO4NMVQPBGRDZWYFD6RWWW5BFNCAMS3M9N1FD",
    "assetId": "SOLANA.SHILL-6CVGJUQO4NMVQPBGRDZWYFD6RWWW5BFNCAMS3M9N1FD",
    "assetAddress": "6cVgJUqo4nmvQpbgrDZwyfd6RwWw5bfnCamS3M9N1fd",
    "assetName": "SHILL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.shill-6cvgjuqo4nmvqpbgrdzwyfd6rwww5bfncams3m9n1fd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SPORE-6CW88QKMXS7LZAUCXBYDCUEXPQ1TVSOJYPV9XWWJPT7R",
    "assetId": "SOLANA.SPORE-6CW88QKMXS7LZAUCXBYDCUEXPQ1TVSOJYPV9XWWJPT7R",
    "assetAddress": "6Cw88QKMxS7LzAucXbYDcUEXPq1TvsojYPV9xWwJPt7r",
    "assetName": "SPORE",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.spore-6cw88qkmxs7lzaucxbydcuexpq1tvsojypv9xwwjpt7r.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AART-F3NEFJBCEJYBTDREJUI1T9DPH5DBGPKKQ7U2GAAMXS5B",
    "assetId": "SOLANA.AART-F3NEFJBCEJYBTDREJUI1T9DPH5DBGPKKQ7U2GAAMXS5B",
    "assetAddress": "F3nefJBcejYbtdREjui1T9DPh5dBgpkKq7u2GAAMXs5B",
    "assetName": "AART",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.aart-f3nefjbcejybtdrejui1t9dph5dbgpkkq7u2gaamxs5b.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MMA-MMAX26JTJGSWV6YH48NEHCGZCVVRBF9LT9ALA7JSIPE",
    "assetId": "SOLANA.MMA-MMAX26JTJGSWV6YH48NEHCGZCVVRBF9LT9ALA7JSIPE",
    "assetAddress": "MMAx26JtJgSWv6yH48nEHCGZcVvRbf9Lt9ALa7jSipe",
    "assetName": "MMA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mma-mmax26jtjgswv6yh48nehcgzcvvrbf9lt9ala7jsipe.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CSM-EZFNJRUKTC5VWEE1GCLDHV4MKDQ3EBSPQXLOBSKGQ9RB",
    "assetId": "SOLANA.CSM-EZFNJRUKTC5VWEE1GCLDHV4MKDQ3EBSPQXLOBSKGQ9RB",
    "assetAddress": "EzfnjRUKtc5vweE1GCLdHV4MkDQ3ebSpQXLobSKgQ9RB",
    "assetName": "CSM",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.csm-ezfnjruktc5vwee1gcldhv4mkdq3ebspqxlobskgq9rb.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PRISM-PRSMNSEPQHGVCH1TTWIJQPJJYH2CKRLOSTPZTNY1O5X",
    "assetId": "SOLANA.PRISM-PRSMNSEPQHGVCH1TTWIJQPJJYH2CKRLOSTPZTNY1O5X",
    "assetAddress": "PRSMNsEPqhGVCH1TtWiJqPjJyh2cKrLostPZTNy1o5x",
    "assetName": "PRISM",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.prism-prsmnsepqhgvch1ttwijqpjjyh2ckrlostpztny1o5x.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ZAP-HXPOEHMT1VKEQJKCEPCQTJ6YYGN6XQQ1FKTY3PJX4YRX",
    "assetId": "SOLANA.ZAP-HXPOEHMT1VKEQJKCEPCQTJ6YYGN6XQQ1FKTY3PJX4YRX",
    "assetAddress": "HxPoEHMt1vKeqjKCePcqTj6yYgn6Xqq1fKTY3Pjx4YrX",
    "assetName": "ZAP",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.zap-hxpoehmt1vkeqjkcepcqtj6yygn6xqq1fkty3pjx4yrx.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RODAI-GDBYLSNKHKLXTZVEO8QRGKVMREXEEZUYVHPSFUZ9TDKC",
    "assetId": "SOLANA.RODAI-GDBYLSNKHKLXTZVEO8QRGKVMREXEEZUYVHPSFUZ9TDKC",
    "assetAddress": "GdbyLsNKHKLXTZVEo8QrGKVmrexEeZUYvhpSfuZ9TdkC",
    "assetName": "RODAI",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rodai-gdbylsnkhklxtzveo8qrgkvmrexeezuyvhpsfuz9tdkc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MRX-8YQUJ5V4S72UQZI3SYZL5RAD4NPV4UEUWBKZCHBDWMVF",
    "assetId": "SOLANA.MRX-8YQUJ5V4S72UQZI3SYZL5RAD4NPV4UEUWBKZCHBDWMVF",
    "assetAddress": "8yQuj5v4s72UqZi3sYZL5rAD4NPV4ueUwBKzChBDWMVf",
    "assetName": "MRX",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mrx-8yquj5v4s72uqzi3syzl5rad4npv4ueuwbkzchbdwmvf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DXL-GSNZXJFFN6ZQDJGEYSUPJWZUAM57BA7335MFHWVFIE9Z",
    "assetId": "SOLANA.DXL-GSNZXJFFN6ZQDJGEYSUPJWZUAM57BA7335MFHWVFIE9Z",
    "assetAddress": "GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z",
    "assetName": "DXL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dxl-gsnzxjffn6zqdjgeysupjwzuam57ba7335mfhwvfie9z.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PAINT-8X9C5QA4NVAKKO5WHPBPA5XVTVMKMS26W4DRPCQLCLK3",
    "assetId": "SOLANA.PAINT-8X9C5QA4NVAKKO5WHPBPA5XVTVMKMS26W4DRPCQLCLK3",
    "assetAddress": "8x9c5qa4nvakKo5wHPbPa5xvTVMKmS26w4DRpCQLCLk3",
    "assetName": "PAINT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.paint-8x9c5qa4nvakko5whpbpa5xvtvmkms26w4drpcqlclk3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OXY-Z3DN17YLAGMKFFVOGEFHQ9ZWVCXGQGF3PQNDSNS2G6M",
    "assetId": "SOLANA.OXY-Z3DN17YLAGMKFFVOGEFHQ9ZWVCXGQGF3PQNDSNS2G6M",
    "assetAddress": "z3dn17yLaGMKffVogeFHQ9zWVcXgqgf3PQnDsNs2g6M",
    "assetName": "OXY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.oxy-z3dn17ylagmkffvogefhq9zwvcxgqgf3pqndsns2g6m.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.STEP-STEPASCQOEIOFXXWGNH2SLBDFP9D8RVKZ2YP39IDPYT",
    "assetId": "SOLANA.STEP-STEPASCQOEIOFXXWGNH2SLBDFP9D8RVKZ2YP39IDPYT",
    "assetAddress": "StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT",
    "assetName": "STEP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.step-stepascqoeiofxxwgnh2slbdfp9d8rvkz2yp39idpyt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KART-GDZFEMOYR5GKBK4YUPYPYQ3E8DU9FSFKXXKDPKDRQGJS",
    "assetId": "SOLANA.KART-GDZFEMOYR5GKBK4YUPYPYQ3E8DU9FSFKXXKDPKDRQGJS",
    "assetAddress": "GDzfemoYR5GkbK4YupYpyq3E8Du9fSfKXxKDpkdrqGjs",
    "assetName": "KART",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.kart-gdzfemoyr5gkbk4yupypyq3e8du9fsfkxxkdpkdrqgjs.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLZILLA-31IQSAHFA4CMIIRU7REYGBZUAWG4R4AH7Y4ADU9ZFXJP",
    "assetId": "SOLANA.SOLZILLA-31IQSAHFA4CMIIRU7REYGBZUAWG4R4AH7Y4ADU9ZFXJP",
    "assetAddress": "31iQsahfa4CMiirU7REygBzuAWg4R4ah7Y4aDu9ZfXJP",
    "assetName": "SOLZILLA",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solzilla-31iqsahfa4cmiiru7reygbzuawg4r4ah7y4adu9zfxjp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SAITAMA-CVRWDBKWSUBE1IFENLFDDZSTMVENLCYDUKXTDAZX1UA5",
    "assetId": "SOLANA.SAITAMA-CVRWDBKWSUBE1IFENLFDDZSTMVENLCYDUKXTDAZX1UA5",
    "assetAddress": "CVRwdbkwSube1ifeNLFDdZSTmVEnLcyDuKXTdaZX1ua5",
    "assetName": "SAITAMA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.saitama-cvrwdbkwsube1ifenlfddzstmvenlcydukxtdazx1ua5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NSO-HGMFSGNDLQ6VGLXCW4J33NJRWV2ZTH81IEJNVWK9KCHD",
    "assetId": "SOLANA.NSO-HGMFSGNDLQ6VGLXCW4J33NJRWV2ZTH81IEJNVWK9KCHD",
    "assetAddress": "HgMfSGndLq6vgLxCw4J33nJrwV2zTh81iEJNVwK9kcHD",
    "assetName": "NSO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nso-hgmfsgndlq6vglxcw4j33njrwv2zth81iejnvwk9kchd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.POS-B8VV6AN7XFF3BARB1CMU7TMFKNJJES2WVY7JWQIRC6K6",
    "assetId": "SOLANA.POS-B8VV6AN7XFF3BARB1CMU7TMFKNJJES2WVY7JWQIRC6K6",
    "assetAddress": "B8vV6An7xFF3bARB1cmU7TMfKNjjes2WvY7jWqiRc6K6",
    "assetName": "POS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pos-b8vv6an7xff3barb1cmu7tmfknjjes2wvy7jwqirc6k6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SBABE-D9MFKGNZHNQGRTZKVNJ44YVOLTJMFBZRAHXIUKCAZRE4",
    "assetId": "SOLANA.SBABE-D9MFKGNZHNQGRTZKVNJ44YVOLTJMFBZRAHXIUKCAZRE4",
    "assetAddress": "D9mFkgnZHnQGRtZKvnJ44yvoLtJmfBZRahXiUKcAzRE4",
    "assetName": "SBABE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sbabe-d9mfkgnzhnqgrtzkvnj44yvoltjmfbzrahxiukcazre4.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ALL-BAOAWH9P2J8YUK9R5YXQS3HQWMUJGSCACJMTKH8RMWYL",
    "assetId": "SOLANA.ALL-BAOAWH9P2J8YUK9R5YXQS3HQWMUJGSCACJMTKH8RMWYL",
    "assetAddress": "BaoawH9p2J8yUK9r5YXQs3hQwmUJgscACjmTkh8rMwYL",
    "assetName": "ALL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.all-baoawh9p2j8yuk9r5yxqs3hqwmujgscacjmtkh8rmwyl.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TULIP-TULIPCQTGVXP9XR62WM8WWCM6A9VHLS7T1UOWBK6FDS",
    "assetId": "SOLANA.TULIP-TULIPCQTGVXP9XR62WM8WWCM6A9VHLS7T1UOWBK6FDS",
    "assetAddress": "TuLipcqtGVXP9XR62wM8WWCm6a9vhLs7T1uoWBk6FDs",
    "assetName": "TULIP",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tulip-tulipcqtgvxp9xr62wm8wwcm6a9vhls7t1uowbk6fds.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CRP-DUBWWZNWINGMMEEQHPNMATNJ77YZPZSAZ2WVR5WJLJQZ",
    "assetId": "SOLANA.CRP-DUBWWZNWINGMMEEQHPNMATNJ77YZPZSAZ2WVR5WJLJQZ",
    "assetAddress": "DubwWZNWiNGMMeeQHPnMATNj77YZPZSAz2WVR5WjLJqz",
    "assetName": "CRP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.crp-dubwwznwingmmeeqhpnmatnj77yzpzsaz2wvr5wjljqz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SLRS-SLRSSPSLUTP7OKBCUBYSTWCO1VUGYT775FAPQZ8HUMR",
    "assetId": "SOLANA.SLRS-SLRSSPSLUTP7OKBCUBYSTWCO1VUGYT775FAPQZ8HUMR",
    "assetAddress": "SLRSSpSLUTP7okbCUBYStWCo1vUgyt775faPqz8HUMr",
    "assetName": "SLRS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.slrs-slrsspslutp7okbcubystwco1vugyt775fapqz8humr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.APYS-5JNZ667P3VCJDINKJFYSWH2K2KTVIY63FZ3OL5YGHEHW",
    "assetId": "SOLANA.APYS-5JNZ667P3VCJDINKJFYSWH2K2KTVIY63FZ3OL5YGHEHW",
    "assetAddress": "5JnZ667P3VcjDinkJFysWh2K2KtViy63FZ3oL5YghEhW",
    "assetName": "APYS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.apys-5jnz667p3vcjdinkjfyswh2k2ktviy63fz3ol5yghehw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SYP-FNKE9N6AGJQONWRBZXY4RW6LZVAO7QWBONUBID7EDUMZ",
    "assetId": "SOLANA.SYP-FNKE9N6AGJQONWRBZXY4RW6LZVAO7QWBONUBID7EDUMZ",
    "assetAddress": "FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ",
    "assetName": "SYP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.syp-fnke9n6agjqonwrbzxy4rw6lzvao7qwbonubid7edumz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CATMAN-EAVJDLH8CYTANT3QDITPKGMSPL2HQ1MY5G9R2P6AT6LC",
    "assetId": "SOLANA.CATMAN-EAVJDLH8CYTANT3QDITPKGMSPL2HQ1MY5G9R2P6AT6LC",
    "assetAddress": "EavJDLh8cYTAnt3QDitpKGMsPL2hq1My5g9R2P6at6Lc",
    "assetName": "CATMAN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.catman-eavjdlh8cytant3qditpkgmspl2hq1my5g9r2p6at6lc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.POLIS-POLISWXNNRWC6OBU1VHIUKQZFJGL4XDSU4G9QJZ9QVK",
    "assetId": "SOLANA.POLIS-POLISWXNNRWC6OBU1VHIUKQZFJGL4XDSU4G9QJZ9QVK",
    "assetAddress": "poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk",
    "assetName": "POLIS",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.polis-poliswxnnrwc6obu1vhiukqzfjgl4xdsu4g9qjz9qvk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HAWK-BKIPKEARSQAUDNKA1WDSTVCMJOPSSKBUNYVKDQDDU9WE",
    "assetId": "SOLANA.HAWK-BKIPKEARSQAUDNKA1WDSTVCMJOPSSKBUNYVKDQDDU9WE",
    "assetAddress": "BKipkearSqAUdNKa1WDstvcMjoPsSKBuNyvKDQDDu9WE",
    "assetName": "HAWK",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hawk-bkipkearsqaudnka1wdstvcmjopsskbunyvkdqddu9we.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SAO-2HEYKDKJZHKGM2LKHW8PDYWJKPIFEOXAZ74DIRHUGQVQ",
    "assetId": "SOLANA.SAO-2HEYKDKJZHKGM2LKHW8PDYWJKPIFEOXAZ74DIRHUGQVQ",
    "assetAddress": "2HeykdKjzHKGm2LKHw8pDYwjKPiFEoXAz74dirhUgQvq",
    "assetName": "SAO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sao-2heykdkjzhkgm2lkhw8pdywjkpifeoxaz74dirhugqvq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SNY-4DMKKXNHDGYSXQBHCUMIKNQWWVOMZURHYVKKX5C4PQ7Y",
    "assetId": "SOLANA.SNY-4DMKKXNHDGYSXQBHCUMIKNQWWVOMZURHYVKKX5C4PQ7Y",
    "assetAddress": "4dmKkXNHdgYsXqBHCuMikNQWwVomZURhYvkkX5c4pQ7y",
    "assetName": "SNY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sny-4dmkkxnhdgysxqbhcumiknqwwvomzurhyvkkx5c4pq7y.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SHOE-8XVXZMSMMW7UFA8RC21FHCDP6TGTI5Y3ZIDQINNYURQR",
    "assetId": "SOLANA.SHOE-8XVXZMSMMW7UFA8RC21FHCDP6TGTI5Y3ZIDQINNYURQR",
    "assetAddress": "8XVXzmsMMw7ufa8RC21fHcDP6TGti5y3ZidQinnYurqr",
    "assetName": "SHOE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.shoe-8xvxzmsmmw7ufa8rc21fhcdp6tgti5y3zidqinnyurqr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NNI-BUMNHMD5XSYXBSSTQO15JOUU8VHUEZJCFBTBUZGRCUW",
    "assetId": "SOLANA.NNI-BUMNHMD5XSYXBSSTQO15JOUU8VHUEZJCFBTBUZGRCUW",
    "assetAddress": "buMnhMd5xSyXBssTQo15jouu8VhuEZJCfbtBUZgRcuW",
    "assetName": "NNI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nni-bumnhmd5xsyxbsstqo15jouu8vhuezjcfbtbuzgrcuw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.UNI-8FU95XFJHUUKYYCLU13HSZDLS7OC4QZDXQHL6SCEAB36",
    "assetId": "SOLANA.UNI-8FU95XFJHUUKYYCLU13HSZDLS7OC4QZDXQHL6SCEAB36",
    "assetAddress": "8FU95xFJhUUkyyCLU13HSzDLs7oC4QZdXQHL6SCeab36",
    "assetName": "UNI",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.uni-8fu95xfjhuukyyclu13hszdls7oc4qzdxqhl6sceab36.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PHNX-JAZESW3TU2VLDX99PWGKDMCA2DTNSEHSWG8SJ21B1NIZ",
    "assetId": "SOLANA.PHNX-JAZESW3TU2VLDX99PWGKDMCA2DTNSEHSWG8SJ21B1NIZ",
    "assetAddress": "JAzesW3tU2VLDx99pwgkDMca2DTNsEhSWG8sj21B1niz",
    "assetName": "PHNX",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.phnx-jazesw3tu2vldx99pwgkdmca2dtnsehswg8sj21b1niz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SPWN-5U9QQCPHQXAJCEV9UYZFJD5ZHN93VUPK1ANNKXNUFPNT",
    "assetId": "SOLANA.SPWN-5U9QQCPHQXAJCEV9UYZFJD5ZHN93VUPK1ANNKXNUFPNT",
    "assetAddress": "5U9QqCPhqXAJcEv9uyzFJd5zhN93vuPk1aNNkXnUfPnt",
    "assetName": "SPWN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.spwn-5u9qqcphqxajcev9uyzfjd5zhn93vupk1annkxnufpnt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JLP-27G8MTK7VTTCCHKPASJSDDKWWYFOQT6GGEUKIDVJIDD4",
    "assetId": "SOLANA.JLP-27G8MTK7VTTCCHKPASJSDDKWWYFOQT6GGEUKIDVJIDD4",
    "assetAddress": "27G8MtK7VtTcCHkpASjSDdkWWYfoqT6ggEuKidVJidD4",
    "assetName": "JLP",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jlp-27g8mtk7vttcchkpasjsddkwwyfoqt6ggeukidvjidd4.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GUMMY-H7BTHGB5CVO5FGE5JBDNDPUV8KYKQNZYZA3QZ8SH7YXW",
    "assetId": "SOLANA.GUMMY-H7BTHGB5CVO5FGE5JBDNDPUV8KYKQNZYZA3QZ8SH7YXW",
    "assetAddress": "H7bTHGb5Cvo5fGe5jBDNDPUv8KykQnzyZA3qZ8sH7yxw",
    "assetName": "GUMMY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gummy-h7bthgb5cvo5fge5jbdndpuv8kykqnzyza3qz8sh7yxw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JUP-JUPYIWRYJFSKUPIHA7HKER8VUTAEFOSYBKEDZNSDVCN",
    "assetId": "SOLANA.JUP-JUPYIWRYJFSKUPIHA7HKER8VUTAEFOSYBKEDZNSDVCN",
    "assetAddress": "JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN",
    "assetName": "JUP",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jup-jupyiwryjfskupiha7hker8vutaefosybkedznsdvcn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KMNO-KMNO3NJSBXFCPJTVHZCXLW7RMTWTT4GVFE7SUUBO9SS",
    "assetId": "SOLANA.KMNO-KMNO3NJSBXFCPJTVHZCXLW7RMTWTT4GVFE7SUUBO9SS",
    "assetAddress": "KMNo3nJsBXfcpJTVhZcXLW7RmTwTt4GVFE7suUBo9sS",
    "assetName": "KMNO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.kmno-kmno3njsbxfcpjtvhzcxlw7rmtwtt4gvfe7suubo9ss.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SAROS-SAROSY6VSCAO718M4A778Z4CGTVCWCGEF5M9MEH1LGL",
    "assetId": "SOLANA.SAROS-SAROSY6VSCAO718M4A778Z4CGTVCWCGEF5M9MEH1LGL",
    "assetAddress": "SarosY6Vscao718M4A778z4CGtvcwcGef5M9MEH1LGL",
    "assetName": "SAROS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.saros-sarosy6vscao718m4a778z4cgtvcwcgef5m9meh1lgl.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KEYCAT-9PPE1Q9EW1BMQWBHMFFRZUCFRR7S82UOXNUFFA6MAZC6",
    "assetId": "SOLANA.KEYCAT-9PPE1Q9EW1BMQWBHMFFRZUCFRR7S82UOXNUFFA6MAZC6",
    "assetAddress": "9pPE1q9EW1bMQWbHmffrzUCfRr7S82UoxNUFfA6mAZC6",
    "assetName": "KEYCAT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.keycat-9ppe1q9ew1bmqwbhmffrzucfrr7s82uoxnuffa6mazc6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PEW-5ZGTYTDK836G2FC4ZLQP4RSYI78PABUDR4QAQUE1PUMP",
    "assetId": "SOLANA.PEW-5ZGTYTDK836G2FC4ZLQP4RSYI78PABUDR4QAQUE1PUMP",
    "assetAddress": "5zgTYTDK836G2Fc4ZLQp4rsyi78pAbuDr4qaQUE1pump",
    "assetName": "PEW",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pew-5zgtytdk836g2fc4zlqp4rsyi78pabudr4qaque1pump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LMF-LMFZMYL6Y1FX8HSEMZ6YNKNZERCBMTMPG2ZOLWUUBOU",
    "assetId": "SOLANA.LMF-LMFZMYL6Y1FX8HSEMZ6YNKNZERCBMTMPG2ZOLWUUBOU",
    "assetAddress": "LMFzmYL6y1FX8HsEmZ6yNKNzercBmtmpg2ZoLwuUboU",
    "assetName": "LMF",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lmf-lmfzmyl6y1fx8hsemz6ynknzercbmtmpg2zolwuubou.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ZERO-93RC484OMK5T9H89RZT5QIAXKHGP9JSCXFFFRIHNBE57",
    "assetId": "SOLANA.ZERO-93RC484OMK5T9H89RZT5QIAXKHGP9JSCXFFFRIHNBE57",
    "assetAddress": "93RC484oMK5T9H89rzT5qiAXKHGP9jscXfFfrihNbe57",
    "assetName": "ZERO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.zero-93rc484omk5t9h89rzt5qiaxkhgp9jscxfffrihnbe57.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DMAGA-7D7BRCBYEPFI77VXYSAPMEQRNN1WSBBXNFPJGBH5PUMP",
    "assetId": "SOLANA.DMAGA-7D7BRCBYEPFI77VXYSAPMEQRNN1WSBBXNFPJGBH5PUMP",
    "assetAddress": "7D7BRcBYepfi77vxySapmeqRNN1wsBBxnFPJGbH5pump",
    "assetName": "DMAGA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dmaga-7d7brcbyepfi77vxysapmeqrnn1wsbbxnfpjgbh5pump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$NAP-4G86CMXGSMDLETRYNAVMFKPHQZKTVDBYGMRADVTR72NU",
    "assetId": "SOLANA.$NAP-4G86CMXGSMDLETRYNAVMFKPHQZKTVDBYGMRADVTR72NU",
    "assetAddress": "4G86CMxGsMdLETrYnavMFKPhQzKTvDBYGMRAdVtr72nu",
    "assetName": "$NAP",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nap-4g86cmxgsmdletrynavmfkphqzktvdbygmradvtr72nu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SELFIE-9WPTUKH8FKUCNEPRWOPYLH3AK9GSJPHFDENBQ2X1CZDP",
    "assetId": "SOLANA.SELFIE-9WPTUKH8FKUCNEPRWOPYLH3AK9GSJPHFDENBQ2X1CZDP",
    "assetAddress": "9WPTUkh8fKuCnepRWoPYLH3aK9gSjPHFDenBq2X1Czdp",
    "assetName": "SELFIE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.selfie-9wptukh8fkucneprwopylh3ak9gsjphfdenbq2x1czdp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WHALES-DSXWF79VQ3RZFBB67WENFCZFZAQQ5X6M97ZI85BQ1TRX",
    "assetId": "SOLANA.WHALES-DSXWF79VQ3RZFBB67WENFCZFZAQQ5X6M97ZI85BQ1TRX",
    "assetAddress": "DSXWF79VQ3RzFBB67WeNfCzfzAQq5X6m97zi85bq1TRx",
    "assetName": "WHALES",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.whales-dsxwf79vq3rzfbb67wenfczfzaqq5x6m97zi85bq1trx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WHALES-GTH3WG3NERJWCF7VGCOXEXKGXSHVYHX5GTATEEM5JAS1",
    "assetId": "SOLANA.WHALES-GTH3WG3NERJWCF7VGCOXEXKGXSHVYHX5GTATEEM5JAS1",
    "assetAddress": "GTH3wG3NErjwcf7VGCoXEXkgXSHvYhx5gtATeeM5JAS1",
    "assetName": "WHALES",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.whales-gth3wg3nerjwcf7vgcoxexkgxshvyhx5gtateem5jas1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JUNGLE-AOGV6J1WWIBAZCQRNN1Y89EOZDA2KE6RKC4CYY7C4ICI",
    "assetId": "SOLANA.JUNGLE-AOGV6J1WWIBAZCQRNN1Y89EOZDA2KE6RKC4CYY7C4ICI",
    "assetAddress": "Aogv6j1wWiBAZcqRNN1Y89eozda2ke6rkc4CYy7c4iCi",
    "assetName": "JUNGLE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jungle-aogv6j1wwibazcqrnn1y89eozda2ke6rkc4cyy7c4ici.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BABY-UUC6HIKT9Y6ASOQS2PHONGGW2LATECFJU9YEOHPPZWH",
    "assetId": "SOLANA.BABY-UUC6HIKT9Y6ASOQS2PHONGGW2LATECFJU9YEOHPPZWH",
    "assetAddress": "Uuc6hiKT9Y6ASoqs2phonGGw2LAtecfJu9yEohppzWH",
    "assetName": "BABY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.baby-uuc6hikt9y6asoqs2phonggw2latecfju9yeohppzwh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BABY-5HMF8JT9PUWOQIFQTB3VR22732ZTKYRLRW9VO7TN3RCZ",
    "assetId": "SOLANA.BABY-5HMF8JT9PUWOQIFQTB3VR22732ZTKYRLRW9VO7TN3RCZ",
    "assetAddress": "5hmf8Jt9puwoqiFQTb3vr22732ZTKYRLRw9Vo7tN3rcz",
    "assetName": "BABY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.baby-5hmf8jt9puwoqifqtb3vr22732ztkyrlrw9vo7tn3rcz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ELU-4TJZHSDGEPUMEFZQ3H5LAHJTPSW1IWTRFTOJNZCWSAU6",
    "assetId": "SOLANA.ELU-4TJZHSDGEPUMEFZQ3H5LAHJTPSW1IWTRFTOJNZCWSAU6",
    "assetAddress": "4tJZhSdGePuMEfZQ3h5LaHjTPsw1iWTRFTojnZcwsAU6",
    "assetName": "ELU",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.elu-4tjzhsdgepumefzq3h5lahjtpsw1iwtrftojnzcwsau6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PENG-A3EME5CETYZPBOWBRUWY3TSE25S6TB18BA9ZPBWK9EFJ",
    "assetId": "SOLANA.PENG-A3EME5CETYZPBOWBRUWY3TSE25S6TB18BA9ZPBWK9EFJ",
    "assetAddress": "A3eME5CetyZPBoWbRUwY3tSe25S6tb18ba9ZPbWk9eFJ",
    "assetName": "PENG",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.peng-a3eme5cetyzpbowbruwy3tse25s6tb18ba9zpbwk9efj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$WATER-B6H248NJKACBAKACNJI889A26TCIGXGN8CXHEJ4DX391",
    "assetId": "SOLANA.$WATER-B6H248NJKACBAKACNJI889A26TCIGXGN8CXHEJ4DX391",
    "assetAddress": "B6h248NJkAcBAkaCnji889a26tCiGXGN8cxhEJ4dX391",
    "assetName": "$WATER",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.water-b6h248njkacbakacnji889a26tcigxgn8cxhej4dx391.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ZEX-ZEXY1PQTERU3N13KDYH4LWPQKNKFK3GZMMYMUNADWPO",
    "assetId": "SOLANA.ZEX-ZEXY1PQTERU3N13KDYH4LWPQKNKFK3GZMMYMUNADWPO",
    "assetAddress": "ZEXy1pqteRu3n13kdyh4LwPQknkFk3GzmMYMuNadWPo",
    "assetName": "ZEX",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.zex-zexy1pqteru3n13kdyh4lwpqknkfk3gzmmymunadwpo.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JASON-6SURYVEUDZ5HQAXAB6QRGFBZWVJN8DC7M29EZSVDPUMP",
    "assetId": "SOLANA.JASON-6SURYVEUDZ5HQAXAB6QRGFBZWVJN8DC7M29EZSVDPUMP",
    "assetAddress": "6SUryVEuDz5hqAxab6QrGfbzWvjN8dC7m29ezSvDpump",
    "assetName": "JASON",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jason-6suryveudz5hqaxab6qrgfbzwvjn8dc7m29ezsvdpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOOMER-7JMATFBOOHKASRBJDFTU3LCK85KPTQWTCAFZCDXQV7ZW",
    "assetId": "SOLANA.BOOMER-7JMATFBOOHKASRBJDFTU3LCK85KPTQWTCAFZCDXQV7ZW",
    "assetAddress": "7jmaTFBooHkaSrBJDftu3LcK85KPtqWTCaFZCDxQV7ZW",
    "assetName": "BOOMER",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.boomer-7jmatfboohkasrbjdftu3lck85kptqwtcafzcdxqv7zw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ZACK-8VCAUBXEJDTAXN6JNX5UAQTYTZLMXALG9U1BVFCAJTX7",
    "assetId": "SOLANA.ZACK-8VCAUBXEJDTAXN6JNX5UAQTYTZLMXALG9U1BVFCAJTX7",
    "assetAddress": "8vCAUbxejdtaxn6jnX5uaQTyTZLmXALg9u1bvFCAjtx7",
    "assetName": "ZACK",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.zack-8vcaubxejdtaxn6jnx5uaqtytzlmxalg9u1bvfcajtx7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHEEKS-6E6RVIDZAVLRV56NVZYE5UOFRKDG36MF6DTQRMCOPTW9",
    "assetId": "SOLANA.CHEEKS-6E6RVIDZAVLRV56NVZYE5UOFRKDG36MF6DTQRMCOPTW9",
    "assetAddress": "6e6rViDzavLRv56nvZye5UofrKDg36mf6dTQrMCoPTW9",
    "assetName": "CHEEKS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cheeks-6e6rvidzavlrv56nvzye5uofrkdg36mf6dtqrmcoptw9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CAW-9NIFQK8MSPARJSXHYQ3YS2A6ZHMSEUKSB1M7WWDVZ7BJ",
    "assetId": "SOLANA.CAW-9NIFQK8MSPARJSXHYQ3YS2A6ZHMSEUKSB1M7WWDVZ7BJ",
    "assetAddress": "9niFQK8MsParjSxhYQ3Ys2a6zHmsEuKSB1M7wwdvZ7bj",
    "assetName": "CAW",
    "decimals": 0,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.caw-9nifqk8msparjsxhyq3ys2a6zhmseuksb1m7wwdvz7bj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KHAI-3TWGDVYBL2YPET2LXNWAWSMEOA8AL4DUTNUWAT2PKCJC",
    "assetId": "SOLANA.KHAI-3TWGDVYBL2YPET2LXNWAWSMEOA8AL4DUTNUWAT2PKCJC",
    "assetAddress": "3TWgDvYBL2YPET2LxnWAwsMeoA8aL4DutNuwat2pKCjC",
    "assetName": "KHAI",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.khai-3twgdvybl2ypet2lxnwawsmeoa8al4dutnuwat2pkcjc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JUM-43EEGP8K9AZKBSYD9IAHHTHNTE3VCZVBWMAHND2AKM5S",
    "assetId": "SOLANA.JUM-43EEGP8K9AZKBSYD9IAHHTHNTE3VCZVBWMAHND2AKM5S",
    "assetAddress": "43eeGP8k9AZKBSyd9iahhThNtE3vcZvBWMaHNd2akM5s",
    "assetName": "JUM",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jum-43eegp8k9azkbsyd9iahhthnte3vczvbwmahnd2akm5s.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MNDE-MNDEFZGVMT87UEUHVVU9VCTQSAP5B3FTGPSHUUPA5EY",
    "assetId": "SOLANA.MNDE-MNDEFZGVMT87UEUHVVU9VCTQSAP5B3FTGPSHUUPA5EY",
    "assetAddress": "MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey",
    "assetName": "MNDE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mnde-mndefzgvmt87ueuhvvu9vctqsap5b3ftgpshuupa5ey.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TRUNK-9MV4WUUKVSVA5WYCYW4VEO34CNDIF44SH3JI65JNDVH5",
    "assetId": "SOLANA.TRUNK-9MV4WUUKVSVA5WYCYW4VEO34CNDIF44SH3JI65JNDVH5",
    "assetAddress": "9mV4WUukVsva5wYcYW4veo34CNDiF44sh3Ji65JNdvh5",
    "assetName": "TRUNK",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.trunk-9mv4wuukvsva5wycyw4veo34cndif44sh3ji65jndvh5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BORK-4JZXKSNGTQKCDB36ECZ6A2ANZCUNIGCDEXGTDTM2HXAX",
    "assetId": "SOLANA.BORK-4JZXKSNGTQKCDB36ECZ6A2ANZCUNIGCDEXGTDTM2HXAX",
    "assetAddress": "4jZXkSNgTQKCDb36ECZ6a2aNzcUniGcDeXgTdtM2HxAX",
    "assetName": "BORK",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bork-4jzxksngtqkcdb36ecz6a2anzcunigcdexgtdtm2hxax.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SBAE-BWWWURBODJGBOVFETC3FC6OSBQKDOE62E1XQZ7X4PUMP",
    "assetId": "SOLANA.SBAE-BWWWURBODJGBOVFETC3FC6OSBQKDOE62E1XQZ7X4PUMP",
    "assetAddress": "BWWWurbodjGbovFetc3FC6oSbqkdoE62E1XqZ7X4pump",
    "assetName": "SBAE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sbae-bwwwurbodjgbovfetc3fc6osbqkdoe62e1xqz7x4pump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOBAOPPA-BOBAM3U8QMQZHY1HWATNVZE9DLXVKGKYK3TD3T8MLVA",
    "assetId": "SOLANA.BOBAOPPA-BOBAM3U8QMQZHY1HWATNVZE9DLXVKGKYK3TD3T8MLVA",
    "assetAddress": "bobaM3u8QmqZhY1HwAtnvze9DLXvkgKYk3td3t8MLva",
    "assetName": "BOBAOPPA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bobaoppa-bobam3u8qmqzhy1hwatnvze9dlxvkgkyk3td3t8mlva.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EPIK-3BGWJ8B7B9HHX4SGFZ2KJHV9496COVFSMK2YEPEVSBRW",
    "assetId": "SOLANA.EPIK-3BGWJ8B7B9HHX4SGFZ2KJHV9496COVFSMK2YEPEVSBRW",
    "assetAddress": "3BgwJ8b7b9hHX4sgfZ2KJhv9496CoVfsMK2YePevsBRw",
    "assetName": "EPIK",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.epik-3bgwj8b7b9hhx4sgfz2kjhv9496covfsmk2yepevsbrw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CROWN-GDFNESIA2WLAW5T8YX2X5J2MKFA74I5KWGDDUZHT7XMG",
    "assetId": "SOLANA.CROWN-GDFNESIA2WLAW5T8YX2X5J2MKFA74I5KWGDDUZHT7XMG",
    "assetAddress": "GDfnEsia2WLAW5t8yx2X5j2mkfA74i5kwGdDuZHt7XmG",
    "assetName": "CROWN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.crown-gdfnesia2wlaw5t8yx2x5j2mkfa74i5kwgdduzht7xmg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHILL-BZPQOPC44OAHU9SB5HEK1GRNJZC4UWFCL4OWUSWOZM3N",
    "assetId": "SOLANA.CHILL-BZPQOPC44OAHU9SB5HEK1GRNJZC4UWFCL4OWUSWOZM3N",
    "assetAddress": "BzpqoPc44oaHu9SB5hek1GRNjzc4UwFcL4oWusWozm3N",
    "assetName": "CHILL",
    "decimals": 3,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chill-bzpqopc44oahu9sb5hek1grnjzc4uwfcl4owuswozm3n.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HBB-HBB111SCO9JKCEJSZFZ8EC8NH7T6THF8KEKSNVWT6XK6",
    "assetId": "SOLANA.HBB-HBB111SCO9JKCEJSZFZ8EC8NH7T6THF8KEKSNVWT6XK6",
    "assetAddress": "HBB111SCo9jkCejsZfz8Ec8nH7T6THF8KEKSnvwT6XK6",
    "assetName": "HBB",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hbb-hbb111sco9jkcejszfz8ec8nh7t6thf8keksnvwt6xk6.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FLUXB-FLUXBMPHT3FD1EDVFDG46YREQHBENYPN1H4EBNTZWERX",
    "assetId": "SOLANA.FLUXB-FLUXBMPHT3FD1EDVFDG46YREQHBENYPN1H4EBNTZWERX",
    "assetAddress": "FLUXBmPhT3Fd1EDVFdg46YREqHBeNypn1h4EbnTzWERX",
    "assetName": "FLUXB",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fluxb-fluxbmpht3fd1edvfdg46yreqhbenypn1h4ebntzwerx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TAKI-TAKI7FI3ZICV7DU1XNAWLAF6MRK7IKDN77HEGZGWVO4",
    "assetId": "SOLANA.TAKI-TAKI7FI3ZICV7DU1XNAWLAF6MRK7IKDN77HEGZGWVO4",
    "assetAddress": "Taki7fi3Zicv7Du1xNAWLaf6mRK7ikdn77HeGzgwvo4",
    "assetName": "TAKI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.taki-taki7fi3zicv7du1xnawlaf6mrk7ikdn77hegzgwvo4.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PEOPLE-COBCSURT3P91FWVULYKORQEJGSM5HOQDV5T8RUZ6PNLA",
    "assetId": "SOLANA.PEOPLE-COBCSURT3P91FWVULYKORQEJGSM5HOQDV5T8RUZ6PNLA",
    "assetAddress": "CobcsUrt3p91FwvULYKorQejgsm5HoQdv5T8RUZ6PnLA",
    "assetName": "PEOPLE",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.people-cobcsurt3p91fwvulykorqejgsm5hoqdv5t8ruz6pnla.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.COST-AV6QVIGKB7USQYPXJKUVAEM4F599WTRVD75PUWBA9ENM",
    "assetId": "SOLANA.COST-AV6QVIGKB7USQYPXJKUVAEM4F599WTRVD75PUWBA9ENM",
    "assetAddress": "Av6qVigkb7USQyPXJkUvAEm4f599WTRvd75PUWBA9eNm",
    "assetName": "COST",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cost-av6qvigkb7usqypxjkuvaem4f599wtrvd75puwba9enm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHINU-FLRGWXXAX8Q8ECF18WEDF3PLAYORXST5ORPY34D8JFBM",
    "assetId": "SOLANA.CHINU-FLRGWXXAX8Q8ECF18WEDF3PLAYORXST5ORPY34D8JFBM",
    "assetAddress": "FLrgwxXaX8q8ECF18weDf3PLAYorXST5orpY34d8jfbm",
    "assetName": "CHINU",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chinu-flrgwxxax8q8ecf18wedf3playorxst5orpy34d8jfbm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ZYN-PZUAVAUH2TFXGZCBBR6KMXEJSBNGNSPLFOTGJNCTCSD",
    "assetId": "SOLANA.ZYN-PZUAVAUH2TFXGZCBBR6KMXEJSBNGNSPLFOTGJNCTCSD",
    "assetAddress": "PzuaVAUH2tfxGZcbBR6kMxeJsBngnsPLFotGJNCtcsd",
    "assetName": "ZYN",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.zyn-pzuavauh2tfxgzcbbr6kmxejsbngnsplfotgjnctcsd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LAB-2PP6EBUVEL9YRTAUUTMGTWYZKRFYQXGM9JE4S8WPDTEY",
    "assetId": "SOLANA.LAB-2PP6EBUVEL9YRTAUUTMGTWYZKRFYQXGM9JE4S8WPDTEY",
    "assetAddress": "2Pp6ebUvEL9YRTauUTmGTwYZKRfyQXGM9jE4S8WPDtEy",
    "assetName": "LAB",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lab-2pp6ebuvel9yrtauutmgtwyzkrfyqxgm9je4s8wpdtey.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WINR-CSXCTA8USVWKDRHE7KHLU5GGWZYALKOHSZ1MKBVZ4W3M",
    "assetId": "SOLANA.WINR-CSXCTA8USVWKDRHE7KHLU5GGWZYALKOHSZ1MKBVZ4W3M",
    "assetAddress": "CsxCtA8usvWKdRhe7KhLU5GgwzYaLkoHsz1MKBVZ4W3M",
    "assetName": "WINR",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.winr-csxcta8usvwkdrhe7khlu5ggwzyalkohsz1mkbvz4w3m.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ITA-GUVOE2QAS3DHAAGSEUZFBKBLJFXFP46DFBOGBRVJNHFN",
    "assetId": "SOLANA.ITA-GUVOE2QAS3DHAAGSEUZFBKBLJFXFP46DFBOGBRVJNHFN",
    "assetAddress": "GuVoE2qAS3DHaAGSeuZfBkbLjFXfP46DFbogbrVJNHfN",
    "assetName": "ITA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ita-guvoe2qas3dhaagseuzfbkbljfxfp46dfbogbrvjnhfn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GUAC-AZSHEMXD36BJ1EMNXHOWJAJPUXZRKCK57WW4ZGXVA7YR",
    "assetId": "SOLANA.GUAC-AZSHEMXD36BJ1EMNXHOWJAJPUXZRKCK57WW4ZGXVA7YR",
    "assetAddress": "AZsHEMXd36Bj1EMNXhowJajpUXzrKcK57wW4ZGXVa7yR",
    "assetName": "GUAC",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.guac-azshemxd36bj1emnxhowjajpuxzrkck57ww4zgxva7yr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DITH-E1KVZJNXSHVVWTRUDOKPZUC789VRIDXFXG3DUCUY6OOE",
    "assetId": "SOLANA.DITH-E1KVZJNXSHVVWTRUDOKPZUC789VRIDXFXG3DUCUY6OOE",
    "assetAddress": "E1kvzJNxShvvWTrudokpzuc789vRiDXfXG3duCuY6ooE",
    "assetName": "DITH",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dith-e1kvzjnxshvvwtrudokpzuc789vridxfxg3ducuy6ooe.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WALTER-FV56CMR7FHEYPKYMKFMVIKV48UPO51TI9KAXSSQQTDLU",
    "assetId": "SOLANA.WALTER-FV56CMR7FHEYPKYMKFMVIKV48UPO51TI9KAXSSQQTDLU",
    "assetAddress": "FV56CmR7fhEyPkymKfmviKV48uPo51ti9kAxssQqTDLu",
    "assetName": "WALTER",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.walter-fv56cmr7fheypkymkfmvikv48upo51ti9kaxssqqtdlu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DOUG-BAVUJ8BNTC79A8AHTXQI1EUHCCNQVEU8KSBE4SVCAAHC",
    "assetId": "SOLANA.DOUG-BAVUJ8BNTC79A8AHTXQI1EUHCCNQVEU8KSBE4SVCAAHC",
    "assetAddress": "BavuJ8bntC79A8aHTxQi1EUhcCnqvEU8KSBE4sVCAaHc",
    "assetName": "DOUG",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.doug-bavuj8bntc79a8ahtxqi1euhccnqveu8ksbe4svcaahc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WUF-73XSLCBNLNC9BH81CQVKQJ8UEYIARXNG5ZWJUTBNVEBG",
    "assetId": "SOLANA.WUF-73XSLCBNLNC9BH81CQVKQJ8UEYIARXNG5ZWJUTBNVEBG",
    "assetAddress": "73xsLcBnLnc9bh81cqVKqj8uEyiarXng5ZwJuTbnVebG",
    "assetName": "WUF",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wuf-73xslcbnlnc9bh81cqvkqj8ueyiarxng5zwjutbnvebg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.STASH-EWMFSJGDCE7CXDAYZ3HBCAA7NSFHTNDDYSXX3SHCO2HS",
    "assetId": "SOLANA.STASH-EWMFSJGDCE7CXDAYZ3HBCAA7NSFHTNDDYSXX3SHCO2HS",
    "assetAddress": "EWMfSJgDCE7CXDAYz3hbCaA7NsFHTnddySXx3shco2Hs",
    "assetName": "STASH",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.stash-ewmfsjgdce7cxdayz3hbcaa7nsfhtnddysxx3shco2hs.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$BULL-7ZYECVDSXRFFH7TC5JRFBAZXQ6UHF5NNNDNTRZRDSDYF",
    "assetId": "SOLANA.$BULL-7ZYECVDSXRFFH7TC5JRFBAZXQ6UHF5NNNDNTRZRDSDYF",
    "assetAddress": "7ZYeCVdsXRFfh7TC5JRfBaZxQ6UhF5nNNdNtRzrdsDYF",
    "assetName": "$BULL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bull-7zyecvdsxrffh7tc5jrfbazxq6uhf5nnndntrzrdsdyf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LFGO-2U98MM7DMTVMNG4IAKRNMTYNJMKZGD6FXAZB3WVFHQVG",
    "assetId": "SOLANA.LFGO-2U98MM7DMTVMNG4IAKRNMTYNJMKZGD6FXAZB3WVFHQVG",
    "assetAddress": "2u98MM7DMtVmNG4iAKRNMtynjmkzgD6fXAzB3wVfhQvg",
    "assetName": "LFGO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lfgo-2u98mm7dmtvmng4iakrnmtynjmkzgd6fxazb3wvfhqvg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FALX-AFO4NUMBNHDXC7M7P6QJZ1PF3LBQYFG5K1CNRGVE8RVU",
    "assetId": "SOLANA.FALX-AFO4NUMBNHDXC7M7P6QJZ1PF3LBQYFG5K1CNRGVE8RVU",
    "assetAddress": "Afo4NumBNHDXc7m7p6qjZ1pF3LbqYfG5k1CNrGve8rVu",
    "assetName": "FALX",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.falx-afo4numbnhdxc7m7p6qjz1pf3lbqyfg5k1cnrgve8rvu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MEAN-MEANED3XDDUMNMSRGJASKSWDC8PRLYSORJ61PPEHCTD",
    "assetId": "SOLANA.MEAN-MEANED3XDDUMNMSRGJASKSWDC8PRLYSORJ61PPEHCTD",
    "assetAddress": "MEANeD3XDdUmNMsRGjASkSWdC8prLYsoRJ61pPeHctD",
    "assetName": "MEAN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mean-meaned3xddumnmsrgjaskswdc8prlysorj61ppehctd.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WOOF-9NEQAUCB16SQ3TN1PSBKWQYHPDLMFHWJKGYMREJSAGTE",
    "assetId": "SOLANA.WOOF-9NEQAUCB16SQ3TN1PSBKWQYHPDLMFHWJKGYMREJSAGTE",
    "assetAddress": "9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE",
    "assetName": "WOOF",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.woof-9neqaucb16sq3tn1psbkwqyhpdlmfhwjkgymrejsagte.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BDROP-DCJLACAUR25RUJGXBVHZTCWPCTJZSW6YDM8E7OIPIT3K",
    "assetId": "SOLANA.BDROP-DCJLACAUR25RUJGXBVHZTCWPCTJZSW6YDM8E7OIPIT3K",
    "assetAddress": "DcJLACAUR25RujgxBVhZtcWPCTjzSw6YDM8E7oipiT3k",
    "assetName": "BDROP",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bdrop-dcjlacaur25rujgxbvhztcwpctjzsw6ydm8e7oipit3k.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHONKY-H7ED7UGCLP3AX4X1CQ5WUWDN6D1PPRFMMYIV5EJWLWWU",
    "assetId": "SOLANA.CHONKY-H7ED7UGCLP3AX4X1CQ5WUWDN6D1PPRFMMYIV5EJWLWWU",
    "assetAddress": "H7ed7UgcLp3ax4X1CQ5WuWDn6d1pprfMMYiv5ejwLWWU",
    "assetName": "CHONKY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chonky-h7ed7ugclp3ax4x1cq5wuwdn6d1pprfmmyiv5ejwlwwu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MBS-FM9RHUTF5V3HWMLBSTJZXQNBBOZYGRIQAFM6STFZ3K8A",
    "assetId": "SOLANA.MBS-FM9RHUTF5V3HWMLBSTJZXQNBBOZYGRIQAFM6STFZ3K8A",
    "assetAddress": "Fm9rHUTF5v3hwMLbStjZXqNBBoZyGriQaFM6sTFz3K8A",
    "assetName": "MBS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mbs-fm9rhutf5v3hwmlbstjzxqnbbozygriqafm6stfz3k8a.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PORT-PORTJZMPXB9T7DYU7TPLEZRQJ7E6SSFAE62J2OQUC6Y",
    "assetId": "SOLANA.PORT-PORTJZMPXB9T7DYU7TPLEZRQJ7E6SSFAE62J2OQUC6Y",
    "assetAddress": "PoRTjZMPXb9T7dyU7tpLEZRQj7e6ssfAE62j2oQuc6y",
    "assetName": "PORT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.port-portjzmpxb9t7dyu7tplezrqj7e6ssfae62j2oquc6y.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TORI-D8F1FVRUHWG8WBJMQABOPFYO13WWYMGNVHSL3D7DREXP",
    "assetId": "SOLANA.TORI-D8F1FVRUHWG8WBJMQABOPFYO13WWYMGNVHSL3D7DREXP",
    "assetAddress": "D8F1FvrUhwg8WBjMqABopFYo13WwymGnVhsL3d7dRexP",
    "assetName": "TORI",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tori-d8f1fvruhwg8wbjmqabopfyo13wwymgnvhsl3d7drexp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BRZ-FTGGSFADXBTROXQ8VCAUSXRR2OF47QBF5AS1NTZCU4GD",
    "assetId": "SOLANA.BRZ-FTGGSFADXBTROXQ8VCAUSXRR2OF47QBF5AS1NTZCU4GD",
    "assetAddress": "FtgGSFADXBtroxq8VCausXRr2of47QBf5AS1NtZCu4GD",
    "assetName": "BRZ",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.brz-ftggsfadxbtroxq8vcausxrr2of47qbf5as1ntzcu4gd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GIV-3XI3EHKJNKAK2KTCHZYBUSWCLW6EAGTHYOTHH1U6SJE1",
    "assetId": "SOLANA.GIV-3XI3EHKJNKAK2KTCHZYBUSWCLW6EAGTHYOTHH1U6SJE1",
    "assetAddress": "3Xi3EhKjnKAk2KTChzybUSWcLW6eAgTHyotHH1U6sJE1",
    "assetName": "GIV",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.giv-3xi3ehkjnkak2ktchzybuswclw6eagthyothh1u6sje1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PPT-FB2DEFBDJKFPFMTNQ4XNUZ9XDTVYXGSEAETOFMGLZQCC",
    "assetId": "SOLANA.PPT-FB2DEFBDJKFPFMTNQ4XNUZ9XDTVYXGSEAETOFMGLZQCC",
    "assetAddress": "Fb2DefbdjKFPfmTnq4xnuZ9xdtVyXGsEAetoFmGLZQcc",
    "assetName": "PPT",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ppt-fb2defbdjkfpfmtnq4xnuz9xdtvyxgseaetofmglzqcc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.YOURAI-FJK6RQU6QZUENTMK6QQ78CCUS5AHAHCM4HGJKDSVXAEP",
    "assetId": "SOLANA.YOURAI-FJK6RQU6QZUENTMK6QQ78CCUS5AHAHCM4HGJKDSVXAEP",
    "assetAddress": "FjK6rqU6QzUeNtmK6QQ78cCuS5AHAhcm4HgJkdsvXaep",
    "assetName": "YOURAI",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.yourai-fjk6rqu6qzuentmk6qq78ccus5ahahcm4hgjkdsvxaep.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.IMARO-3GJCKK5JXNJFFBRUUS2EEYHPIDEN6Z5TPXLKFVHKSKKG",
    "assetId": "SOLANA.IMARO-3GJCKK5JXNJFFBRUUS2EEYHPIDEN6Z5TPXLKFVHKSKKG",
    "assetAddress": "3Gjckk5jXnJffBruUS2EEYhpiDEN6z5TPXLkFVHkSkkg",
    "assetName": "IMARO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.imaro-3gjckk5jxnjffbruus2eeyhpiden6z5tpxlkfvhkskkg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MDT-8WQBST4QAN2FQBCCH5GDXQ2WJ7VTNWEY4ONLRPUG7TYA",
    "assetId": "SOLANA.MDT-8WQBST4QAN2FQBCCH5GDXQ2WJ7VTNWEY4ONLRPUG7TYA",
    "assetAddress": "8Wqbst4qAN2FqBCCh5gdXq2WJ7vTNWEY4oNLrpUg7Tya",
    "assetName": "MDT",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mdt-8wqbst4qan2fqbcch5gdxq2wj7vtnwey4onlrpug7tya.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MXM-H53UGEYBRB9EASO9EGO8YYK7O4ZQ1G5CCTKXD3E3HZAV",
    "assetId": "SOLANA.MXM-H53UGEYBRB9EASO9EGO8YYK7O4ZQ1G5CCTKXD3E3HZAV",
    "assetAddress": "H53UGEyBrB9easo9ego8yYk7o4Zq1G5cCtkxD3E3hZav",
    "assetName": "MXM",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mxm-h53ugeybrb9easo9ego8yyk7o4zq1g5cctkxd3e3hzav.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PICA-966VSQWOS3ZBRHESTYAVE7ESFV2KAHADFLLXS4ASPDLJ",
    "assetId": "SOLANA.PICA-966VSQWOS3ZBRHESTYAVE7ESFV2KAHADFLLXS4ASPDLJ",
    "assetAddress": "966vsqwoS3ZBrHesTyAvE7esFV2kaHaDFLLXs4asPdLJ",
    "assetName": "PICA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pica-966vsqwos3zbrhestyave7esfv2kahadfllxs4aspdlj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KURO-2KC38RFQ49DFAKHQAWBIJKE7FCYMUMLY5GUUIUSDMFFN",
    "assetId": "SOLANA.KURO-2KC38RFQ49DFAKHQAWBIJKE7FCYMUMLY5GUUIUSDMFFN",
    "assetAddress": "2Kc38rfQ49DFaKHQaWbijkE7fcymUMLY5guUiUsDmFfn",
    "assetName": "KURO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.kuro-2kc38rfq49dfakhqawbijke7fcymumly5guuiusdmffn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.STYLE-3FHPKMTQ3QYAJOLOXVDBPH4TFHIEHNL2KXMV9UXBPYUF",
    "assetId": "SOLANA.STYLE-3FHPKMTQ3QYAJOLOXVDBPH4TFHIEHNL2KXMV9UXBPYUF",
    "assetAddress": "3FHpkMTQ3QyAJoLoXVdBpH4TfHiehnL2kXmv9UXBpYuF",
    "assetName": "STYLE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.style-3fhpkmtq3qyajoloxvdbph4tfhiehnl2kxmv9uxbpyuf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$PELF-BGJW7U1U2RY5XJK9UYB5AQFRZJMTQE7PW3KAF9IW9NTZ",
    "assetId": "SOLANA.$PELF-BGJW7U1U2RY5XJK9UYB5AQFRZJMTQE7PW3KAF9IW9NTZ",
    "assetAddress": "BgJW7U1u2RY5XJk9uYb5AqFRzjMtqE7pw3kaf9iw9Ntz",
    "assetName": "$PELF",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pelf-bgjw7u1u2ry5xjk9uyb5aqfrzjmtqe7pw3kaf9iw9ntz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DORKL-BGQJQDNNJRTCELAGKYPFKAXWRQSREWERCDDRTH6ULOER",
    "assetId": "SOLANA.DORKL-BGQJQDNNJRTCELAGKYPFKAXWRQSREWERCDDRTH6ULOER",
    "assetAddress": "BgqjQdnnjRtcELAgkYpfKAxWRqSReWercDdRTH6uLoer",
    "assetName": "DORKL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dorkl-bgqjqdnnjrtcelagkypfkaxwrqsrewercddrth6uloer.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JIZZLORD-69SEPKGCZFZJLOSAWB3XPFCQNZDIVM21XU28AWZB5PZK",
    "assetId": "SOLANA.JIZZLORD-69SEPKGCZFZJLOSAWB3XPFCQNZDIVM21XU28AWZB5PZK",
    "assetAddress": "69SEPKGCzFzJLoSawb3xPfcqnZdiVm21Xu28AWZB5pzk",
    "assetName": "JIZZLORD",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jizzlord-69sepkgczfzjlosawb3xpfcqnzdivm21xu28awzb5pzk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NYXC-HRLX8MLKEGPBMBMWEPZIUVF3ABJNX1CJYJBWUHWICEGW",
    "assetId": "SOLANA.NYXC-HRLX8MLKEGPBMBMWEPZIUVF3ABJNX1CJYJBWUHWICEGW",
    "assetAddress": "HrLx8MLKegpbmbmWePZiuvf3AbJNx1CJyjBwUHwicEgW",
    "assetName": "NYXC",
    "decimals": 7,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nyxc-hrlx8mlkegpbmbmwepziuvf3abjnx1cjyjbwuhwicegw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TAP-CEJQBKDRPN8JYOD2JVUYTFHWU9HPHVM3RD9PRVQIFFDU",
    "assetId": "SOLANA.TAP-CEJQBKDRPN8JYOD2JVUYTFHWU9HPHVM3RD9PRVQIFFDU",
    "assetAddress": "CejQBkdRPN8Jyod2jVUYTfHwU9hPHvM3rD9prvQiffDU",
    "assetName": "TAP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tap-cejqbkdrpn8jyod2jvuytfhwu9hphvm3rd9prvqiffdu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLC-DLUNTKRQT7CRPQSX1NAHUYOBZNJ9PVMP65UCEWQGYNRK",
    "assetId": "SOLANA.SOLC-DLUNTKRQT7CRPQSX1NAHUYOBZNJ9PVMP65UCEWQGYNRK",
    "assetAddress": "DLUNTKRQt7CrpqSX1naHUYoBznJ9pvMP65uCeWQgYnRK",
    "assetName": "SOLC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solc-dluntkrqt7crpqsx1nahuyobznj9pvmp65ucewqgynrk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLC-BX1FDTVTN6NVE4KJDPHQXTMGSG582BZX9FGY4DQNMMAT",
    "assetId": "SOLANA.SOLC-BX1FDTVTN6NVE4KJDPHQXTMGSG582BZX9FGY4DQNMMAT",
    "assetAddress": "Bx1fDtvTN6NvE4kjdPHQXtmGSg582bZx9fGy4DQNMmAT",
    "assetName": "SOLC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solc-bx1fdtvtn6nve4kjdphqxtmgsg582bzx9fgy4dqnmmat.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DARC-CPFE715P5DNDOJJ9FBCRCUYHHETXNDRNVZNKHVQ1O23U",
    "assetId": "SOLANA.DARC-CPFE715P5DNDOJJ9FBCRCUYHHETXNDRNVZNKHVQ1O23U",
    "assetAddress": "CpFE715P5DnDoJj9FbCRcuyHHeTXNdRnvzNkHvq1o23U",
    "assetName": "DARC",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.darc-cpfe715p5dndojj9fbcrcuyhhetxndrnvznkhvq1o23u.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TAONU-6ZT7HOEMNFYUA5OJ67EQJBFJHUBVLUFBBCKDURUK1RXR",
    "assetId": "SOLANA.TAONU-6ZT7HOEMNFYUA5OJ67EQJBFJHUBVLUFBBCKDURUK1RXR",
    "assetAddress": "6ZT7hoeMNfYua5oJ67EQJbFJHUBVLuFBbCKduRuk1rXr",
    "assetName": "TAONU",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.taonu-6zt7hoemnfyua5oj67eqjbfjhubvlufbbckduruk1rxr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SHARK-8JF4EKDGW7VTDRRDCABAKAXLC55MGU9PTASNIAMIH4A9",
    "assetId": "SOLANA.SHARK-8JF4EKDGW7VTDRRDCABAKAXLC55MGU9PTASNIAMIH4A9",
    "assetAddress": "8JF4ekdgw7VTdrrdCABAKAxLc55mGu9ptasNiamih4a9",
    "assetName": "SHARK",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.shark-8jf4ekdgw7vtdrrdcabakaxlc55mgu9ptasniamih4a9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SHARK-SHARKSYJJQANYXVFRPNBN9PJGKHWDHATNMYICWPNR1S",
    "assetId": "SOLANA.SHARK-SHARKSYJJQANYXVFRPNBN9PJGKHWDHATNMYICWPNR1S",
    "assetAddress": "SHARKSYJjqaNyxVfrpnBN9pjgkhwDhatnMyicWPnr1s",
    "assetName": "SHARK",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.shark-sharksyjjqanyxvfrpnbn9pjgkhwdhatnmyicwpnr1s.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.21X-6R4PCVAX4RYN9WDBXWVWAQL4DJFOUAEBMSQ8CXC6NAPZ",
    "assetId": "SOLANA.21X-6R4PCVAX4RYN9WDBXWVWAQL4DJFOUAEBMSQ8CXC6NAPZ",
    "assetAddress": "6r4PCVaX4rYN9WdbXwVWAQL4djFoUaeBMsq8Cxc6NApZ",
    "assetName": "21X",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.21x-6r4pcvax4ryn9wdbxwvwaql4djfouaebmsq8cxc6napz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHIPPY-BZ7NX1F3MTI1BVS7ZAVDLSKGEAEJUFXVX2DPDJPF8PQT",
    "assetId": "SOLANA.CHIPPY-BZ7NX1F3MTI1BVS7ZAVDLSKGEAEJUFXVX2DPDJPF8PQT",
    "assetAddress": "Bz7Nx1F3Mti1BVS7ZAVDLSKGEaejufxvX2DPdjpf8PqT",
    "assetName": "CHIPPY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chippy-bz7nx1f3mti1bvs7zavdlskgeaejufxvx2dpdjpf8pqt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.USEDCAR-9GWTEGFJJERDPWJKJPFLR2G2ZRE3NL1V5ZPWBTSK3C6P",
    "assetId": "SOLANA.USEDCAR-9GWTEGFJJERDPWJKJPFLR2G2ZRE3NL1V5ZPWBTSK3C6P",
    "assetAddress": "9gwTegFJJErDpWJKjPfLr2g2zrE3nL1v5zpwbtsk3c6P",
    "assetName": "USEDCAR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.usedcar-9gwtegfjjerdpwjkjpflr2g2zre3nl1v5zpwbtsk3c6p.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KOI-6FDCC8XFRXNY6PMNAVCXDEY5XNCTAHA2V54ZYYNMBCEY",
    "assetId": "SOLANA.KOI-6FDCC8XFRXNY6PMNAVCXDEY5XNCTAHA2V54ZYYNMBCEY",
    "assetAddress": "6fdCC8xfrXNy6PmNaVcxdEY5XNCTAha2V54zYYnmBCey",
    "assetName": "KOI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.koi-6fdcc8xfrxny6pmnavcxdey5xnctaha2v54zyynmbcey.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MILKBAG-2UBUHGFS4VJVXSEPVV3KDWZ6JIUXDAAOGMWRWYC87TP8",
    "assetId": "SOLANA.MILKBAG-2UBUHGFS4VJVXSEPVV3KDWZ6JIUXDAAOGMWRWYC87TP8",
    "assetAddress": "2ubuHGFS4VJVxSEpvV3kDwz6JiuXdaAoGMwrwYC87tp8",
    "assetName": "MILKBAG",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.milkbag-2ubuhgfs4vjvxsepvv3kdwz6jiuxdaaogmwrwyc87tp8.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HXD-3DGCCB15HMQSA4PN3TFII5VRK7ARQTH95LJJXZSG2MUG",
    "assetId": "SOLANA.HXD-3DGCCB15HMQSA4PN3TFII5VRK7ARQTH95LJJXZSG2MUG",
    "assetAddress": "3dgCCb15HMQSA4Pn3Tfii5vRk7aRqTH95LJjxzsG2Mug",
    "assetName": "HXD",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hxd-3dgccb15hmqsa4pn3tfii5vrk7arqth95ljjxzsg2mug.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GIKO-3WPEP4UFATOK1AS5S8BL9INZEURT4DYAQCIIC6ZKKC1U",
    "assetId": "SOLANA.GIKO-3WPEP4UFATOK1AS5S8BL9INZEURT4DYAQCIIC6ZKKC1U",
    "assetAddress": "3WPep4ufaToK1aS5s8BL9inzeUrt4DYaQCiic6ZkkC1U",
    "assetName": "GIKO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.giko-3wpep4ufatok1as5s8bl9inzeurt4dyaqciic6zkkc1u.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.USDH-USDH1SM1OJWWUGA67PGRGFWUHIBBJQMVUMADKRJTGKX",
    "assetId": "SOLANA.USDH-USDH1SM1OJWWUGA67PGRGFWUHIBBJQMVUMADKRJTGKX",
    "assetAddress": "USDH1SM1ojwWUga67PGrgFWUHibbjqMvuMaDkRJTgkX",
    "assetName": "USDH",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.usdh-usdh1sm1ojwwuga67pgrgfwuhibbjqmvumadkrjtgkx.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MOOCAT-FULH3F9TPXILNOJPBZXFAT5RB5VFFIA8HMZLO9E9C6CG",
    "assetId": "SOLANA.MOOCAT-FULH3F9TPXILNOJPBZXFAT5RB5VFFIA8HMZLO9E9C6CG",
    "assetAddress": "FuLH3f9TPxiLNojPBZxfaT5Rb5VFfia8hMzLo9e9c6CG",
    "assetName": "MOOCAT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.moocat-fulh3f9tpxilnojpbzxfat5rb5vffia8hmzlo9e9c6cg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MONKEY-921MOB1U7VPRQFWW5D37A38LCBGB3NARET7RNFFK66BG",
    "assetId": "SOLANA.MONKEY-921MOB1U7VPRQFWW5D37A38LCBGB3NARET7RNFFK66BG",
    "assetAddress": "921MoB1U7VprQfWw5D37a38LCBgB3nareT7rNffk66BG",
    "assetName": "MONKEY",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.monkey-921mob1u7vprqfww5d37a38lcbgb3naret7rnffk66bg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.COOK-G8VY25NZJRMUQTNN35XF7J3X2Z1TRV39XIJZU8MG4W8N",
    "assetId": "SOLANA.COOK-G8VY25NZJRMUQTNN35XF7J3X2Z1TRV39XIJZU8MG4W8N",
    "assetAddress": "G8Vy25NzjRmuQtnN35xF7j3X2Z1TrV39XijZu8Mg4w8n",
    "assetName": "COOK",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cook-g8vy25nzjrmuqtnn35xf7j3x2z1trv39xijzu8mg4w8n.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ANUS-9HJZ8UTNRNWT3YUTHVPVZDQJNBP64NBKSDSBLQKR6BZC",
    "assetId": "SOLANA.ANUS-9HJZ8UTNRNWT3YUTHVPVZDQJNBP64NBKSDSBLQKR6BZC",
    "assetAddress": "9hjZ8UTNrNWt3YUTHVpvzdQjNbp64NbKSDsbLqKR6BZc",
    "assetName": "ANUS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.anus-9hjz8utnrnwt3yuthvpvzdqjnbp64nbksdsblqkr6bzc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.UXD-7KBNVUGBXXJ8AG9QP8SCN56MUWGARAFQXG1FSRP3PAFT",
    "assetId": "SOLANA.UXD-7KBNVUGBXXJ8AG9QP8SCN56MUWGARAFQXG1FSRP3PAFT",
    "assetAddress": "7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT",
    "assetName": "UXD",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.uxd-7kbnvugbxxj8ag9qp8scn56muwgarafqxg1fsrp3paft.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SAIL-6KWTQMDQKJD8QRR9RJSNUX9XJ24RMJRSRU1RSRAGP97Y",
    "assetId": "SOLANA.SAIL-6KWTQMDQKJD8QRR9RJSNUX9XJ24RMJRSRU1RSRAGP97Y",
    "assetAddress": "6kwTqmdQkJd8qRr9RjSnUX9XJ24RmJRSrU1rsragP97Y",
    "assetName": "SAIL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sail-6kwtqmdqkjd8qrr9rjsnux9xj24rmjrsru1rsragp97y.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HABIBI-864YJRB3JAVARC4FNUDTPKFXDESYRBB39NWXKZUDXY46",
    "assetId": "SOLANA.HABIBI-864YJRB3JAVARC4FNUDTPKFXDESYRBB39NWXKZUDXY46",
    "assetAddress": "864YJRb3JAVARC4FNuDtPKFxdEsYRbB39Nwxkzudxy46",
    "assetName": "HABIBI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.habibi-864yjrb3javarc4fnudtpkfxdesyrbb39nwxkzudxy46.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WYNN-4VQYQTJMKJXRWGTBL2TVKBAU1EVAZ9JWCYTD2VE3PBVU",
    "assetId": "SOLANA.WYNN-4VQYQTJMKJXRWGTBL2TVKBAU1EVAZ9JWCYTD2VE3PBVU",
    "assetAddress": "4vqYQTjmKjxrWGtbL2tVkbAU1EVAz9JwcYtd2VE3PbVU",
    "assetName": "WYNN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wynn-4vqyqtjmkjxrwgtbl2tvkbau1evaz9jwcytd2ve3pbvu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GUUFY-INLBKBYUVP9WGSLFMY1W9QX5PVAAB5MKVLCZ48DBKMF",
    "assetId": "SOLANA.GUUFY-INLBKBYUVP9WGSLFMY1W9QX5PVAAB5MKVLCZ48DBKMF",
    "assetAddress": "inLbkByUvP9WGsLfMy1w9QX5pvaab5mkvLcz48DbkmF",
    "assetName": "GUUFY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.guufy-inlbkbyuvp9wgslfmy1w9qx5pvaab5mkvlcz48dbkmf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AMC-9JAZHJM6NMHTO4HY9DGABQ1HNUUWHJTM7JS1FMKMVPKN",
    "assetId": "SOLANA.AMC-9JAZHJM6NMHTO4HY9DGABQ1HNUUWHJTM7JS1FMKMVPKN",
    "assetAddress": "9jaZhJM6nMHTo4hY9DGabQ1HNuUWhJtm7js1fmKMVpkN",
    "assetName": "AMC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.amc-9jazhjm6nmhto4hy9dgabq1hnuuwhjtm7js1fmkmvpkn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WEWE-G3MU6GYIQEEH5VZ3QCHGB8CV4SW4OUXAQRM67NUVXH1H",
    "assetId": "SOLANA.WEWE-G3MU6GYIQEEH5VZ3QCHGB8CV4SW4OUXAQRM67NUVXH1H",
    "assetAddress": "G3Mu6gYiqeEH5vz3qChGB8CV4sW4oUxAqRM67nUVXH1H",
    "assetName": "WEWE",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wewe-g3mu6gyiqeeh5vz3qchgb8cv4sw4ouxaqrm67nuvxh1h.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GOME-8ULCKCTUA3XXRNXADVZPCJA2TDJTRDXRR8T4EZJVKQK",
    "assetId": "SOLANA.GOME-8ULCKCTUA3XXRNXADVZPCJA2TDJTRDXRR8T4EZJVKQK",
    "assetAddress": "8ULCkCTUa3XXrNXaDVzPcja2tdJtRdxRr8T4eZjVKqk",
    "assetName": "GOME",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gome-8ulckctua3xxrnxadvzpcja2tdjtrdxrr8t4ezjvkqk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BANX-BANXBTPN8U2CU41FJPXE2TI37PIT5CCXLUKDQZUJEMMR",
    "assetId": "SOLANA.BANX-BANXBTPN8U2CU41FJPXE2TI37PIT5CCXLUKDQZUJEMMR",
    "assetAddress": "BANXbTpN8U2cU41FjPxe2Ti37PiT5cCxLUKDQZuJeMMR",
    "assetName": "BANX",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.banx-banxbtpn8u2cu41fjpxe2ti37pit5ccxlukdqzujemmr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.1SOL-4THREWABAVZJNVGS5UI9PK3CZ5TYAD9U6Y89FP6EFZOF",
    "assetId": "SOLANA.1SOL-4THREWABAVZJNVGS5UI9PK3CZ5TYAD9U6Y89FP6EFZOF",
    "assetAddress": "4ThReWAbAVZjNVgs5Ui9Pk3cZ5TYaD9u6Y89fp6EFzoF",
    "assetName": "1SOL",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.1sol-4threwabavzjnvgs5ui9pk3cz5tyad9u6y89fp6efzof.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BUBBA-BMT3PQ4G8GGWWBND6DJ1JHVYTKHFWJAFJWWW6SRCBQJV",
    "assetId": "SOLANA.BUBBA-BMT3PQ4G8GGWWBND6DJ1JHVYTKHFWJAFJWWW6SRCBQJV",
    "assetAddress": "BMt3pq4g8ggWWBnd6DJ1jhVyTkHfWjAfJwWW6sRCbQJv",
    "assetName": "BUBBA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bubba-bmt3pq4g8ggwwbnd6dj1jhvytkhfwjafjwww6srcbqjv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RING-7SESXCSINIYQCPYG16WX4C9U2YGLZPHNEFTAU9ENAIZD",
    "assetId": "SOLANA.RING-7SESXCSINIYQCPYG16WX4C9U2YGLZPHNEFTAU9ENAIZD",
    "assetAddress": "7SEsxCsiNiYqCpYG16wx4c9u2YGLZphnEFTAU9ENAizD",
    "assetName": "RING",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ring-7sesxcsiniyqcpyg16wx4c9u2yglzphneftau9enaizd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SBR-SABER2GLAUYIM4MVFTNRASOMSV6NVAUNCVMEZWCLPD1",
    "assetId": "SOLANA.SBR-SABER2GLAUYIM4MVFTNRASOMSV6NVAUNCVMEZWCLPD1",
    "assetAddress": "Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1",
    "assetName": "SBR",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sbr-saber2glauyim4mvftnrasomsv6nvauncvmezwclpd1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHP-DHVUOXNQV3D7EGKTBXUSXFF2WX83HVDMD2WBBPUAW3JN",
    "assetId": "SOLANA.CHP-DHVUOXNQV3D7EGKTBXUSXFF2WX83HVDMD2WBBPUAW3JN",
    "assetAddress": "DHVUoxNqv3D7EgktBxUsxFF2Wx83hVDmD2wBBpUaw3jn",
    "assetName": "CHP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chp-dhvuoxnqv3d7egktbxusxff2wx83hvdmd2wbbpuaw3jn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LFNTY-LFNTYRAETVIOAPNGJHT4YNG2AUZFXR776CMEN9VMJXP",
    "assetId": "SOLANA.LFNTY-LFNTYRAETVIOAPNGJHT4YNG2AUZFXR776CMEN9VMJXP",
    "assetAddress": "LFNTYraetVioAPnGJht4yNg2aUZFXR776cMeN9VMjXp",
    "assetName": "LFNTY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lfnty-lfntyraetvioapngjht4yng2auzfxr776cmen9vmjxp.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AABL-ENOD8J2J6WNHKCJKVVBKWQ5JMIR1ONBFBZRKOHCQOGYT",
    "assetId": "SOLANA.AABL-ENOD8J2J6WNHKCJKVVBKWQ5JMIR1ONBFBZRKOHCQOGYT",
    "assetAddress": "ENoD8J2J6wNHkcJkvVBkwq5JMiR1oNBfBZRkoHCQogyT",
    "assetName": "AABL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.aabl-enod8j2j6wnhkcjkvvbkwq5jmir1onbfbzrkohcqogyt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GP-31K88G5MQ7PTBRDF3AM13HAQ6WRQHXHIKR8HIK7WPYGK",
    "assetId": "SOLANA.GP-31K88G5MQ7PTBRDF3AM13HAQ6WRQHXHIKR8HIK7WPYGK",
    "assetAddress": "31k88G5Mq7ptbRDf3AM13HAq6wRQHXHikR8hik7wPygk",
    "assetName": "GP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gp-31k88g5mq7ptbrdf3am13haq6wrqhxhikr8hik7wpygk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KPOP-DCUOGUENTLHHZYRCZ49LE7Z3MEFWCA2N9USW1XBVI1GM",
    "assetId": "SOLANA.KPOP-DCUOGUENTLHHZYRCZ49LE7Z3MEFWCA2N9USW1XBVI1GM",
    "assetAddress": "DcUoGUeNTLhhzyrcz49LE7z3MEFwca2N9uSw1xbVi1gm",
    "assetName": "KPOP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.kpop-dcuoguentlhhzyrcz49le7z3mefwca2n9usw1xbvi1gm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PESHI-5LWSEQRO8FSZ4S3Y7JBQQE5C7TZTZ5PWHXNCHJ13JLBI",
    "assetId": "SOLANA.PESHI-5LWSEQRO8FSZ4S3Y7JBQQE5C7TZTZ5PWHXNCHJ13JLBI",
    "assetAddress": "5LwseQRo8fsz4S3y7jbqqe5C7tZTz5PwhXNCHj13jLBi",
    "assetName": "PESHI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.peshi-5lwseqro8fsz4s3y7jbqqe5c7tztz5pwhxnchj13jlbi.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JOWNES-8M9FJYYCXAFVA1KSCTTQGSESVZT7YELHJZASQFHBUMA5",
    "assetId": "SOLANA.JOWNES-8M9FJYYCXAFVA1KSCTTQGSESVZT7YELHJZASQFHBUMA5",
    "assetAddress": "8m9fjYycXAFva1kScttQgsESVZT7yELhjZASqfHBuMa5",
    "assetName": "JOWNES",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jownes-8m9fjyycxafva1kscttqgsesvzt7yelhjzasqfhbuma5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MYRA-H1AN3VCVB68EAFPBMKOASS3VNFI4AHP5C2DPNRZZDBC7",
    "assetId": "SOLANA.MYRA-H1AN3VCVB68EAFPBMKOASS3VNFI4AHP5C2DPNRZZDBC7",
    "assetAddress": "H1aN3vcvB68eaFPbMkoAss3vnfi4AhP5C2dpnrZzdBc7",
    "assetName": "MYRA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.myra-h1an3vcvb68eafpbmkoass3vnfi4ahp5c2dpnrzzdbc7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HADES-BWXRRYFHT7BMHMNBFOQFWDSSGA3YXOANMHDK6FN1ESEN",
    "assetId": "SOLANA.HADES-BWXRRYFHT7BMHMNBFOQFWDSSGA3YXOANMHDK6FN1ESEN",
    "assetAddress": "BWXrrYFhT7bMHmNBFoQFWdsSgA3yXoAnMhDK6Fn1eSEn",
    "assetName": "HADES",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hades-bwxrryfht7bmhmnbfoqfwdssga3yxoanmhdk6fn1esen.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DIP-3XXVMED354933DWSPJUZB7SE9UIWPD1ERYDDUHMBFRMK",
    "assetId": "SOLANA.DIP-3XXVMED354933DWSPJUZB7SE9UIWPD1ERYDDUHMBFRMK",
    "assetAddress": "3XxvmED354933DwSPJuzB7SE9uiWpD1ErydDuhmbFRMk",
    "assetName": "DIP",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dip-3xxvmed354933dwspjuzb7se9uiwpd1erydduhmbfrmk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WIT-ADQ3WNAVTAXBNFY63XGV1YNKDIPKADDT469XF9UZPRQE",
    "assetId": "SOLANA.WIT-ADQ3WNAVTAXBNFY63XGV1YNKDIPKADDT469XF9UZPRQE",
    "assetAddress": "Adq3wnAvtaXBNfy63xGV1YNkDiPKadDT469xF9uZPrqE",
    "assetName": "WIT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wit-adq3wnavtaxbnfy63xgv1ynkdipkaddt469xf9uzprqe.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SONAR-SONARX4VTVKQEMRIJELM6CKEW3GDMYIBNNAEMW1MRAE",
    "assetId": "SOLANA.SONAR-SONARX4VTVKQEMRIJELM6CKEW3GDMYIBNNAEMW1MRAE",
    "assetAddress": "sonarX4VtVkQemriJeLm6CKeW3GDMyiBnnAEMw1MRAE",
    "assetName": "SONAR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sonar-sonarx4vtvkqemrijelm6ckew3gdmyibnnaemw1mrae.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DVINCI-5JFJCVNGG8YTGWBYGOQUAUC6BMZYR7C5JMGHECBZRXR5",
    "assetId": "SOLANA.DVINCI-5JFJCVNGG8YTGWBYGOQUAUC6BMZYR7C5JMGHECBZRXR5",
    "assetAddress": "5jFJCvNgg8ytGWBygoquaUC6bMZyr7C5jmGHECBzrxR5",
    "assetName": "DVINCI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dvinci-5jfjcvngg8ytgwbygoquauc6bmzyr7c5jmghecbzrxr5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CRODIE-GVCNXDSEHFNSNYHDVDJ27KC459LZFQWOZT9CSJYWMY6R",
    "assetId": "SOLANA.CRODIE-GVCNXDSEHFNSNYHDVDJ27KC459LZFQWOZT9CSJYWMY6R",
    "assetAddress": "GvcNXdSehfNSNyhDVDj27kc459LzFqWozt9CSJywMy6r",
    "assetName": "CRODIE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.crodie-gvcnxdsehfnsnyhdvdj27kc459lzfqwozt9csjywmy6r.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LARIX-LRXQNH6ZHKBGY3DCRCED43NSOLKM1LTZU2JRFWE8QUC",
    "assetId": "SOLANA.LARIX-LRXQNH6ZHKBGY3DCRCED43NSOLKM1LTZU2JRFWE8QUC",
    "assetAddress": "Lrxqnh6ZHKbGy3dcrCED43nsoLkM1LTzU2jRfWe8qUC",
    "assetName": "LARIX",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.larix-lrxqnh6zhkbgy3dcrced43nsolkm1ltzu2jrfwe8quc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WIK-WIKKR42C9DC4KUKAQXCZESNYCRCRQXPEYULK1QPCGEK",
    "assetId": "SOLANA.WIK-WIKKR42C9DC4KUKAQXCZESNYCRCRQXPEYULK1QPCGEK",
    "assetAddress": "wikkR42C9DC4KukAQXcZESnYcRcrQXPEYULk1qpcgeK",
    "assetName": "WIK",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wik-wikkr42c9dc4kukaqxczesnycrcrqxpeyulk1qpcgek.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LIGMA-LIGMAX75TZTD9NYXF3VWYQUESF4UWPXOHGXKEIA2TPU",
    "assetId": "SOLANA.LIGMA-LIGMAX75TZTD9NYXF3VWYQUESF4UWPXOHGXKEIA2TPU",
    "assetAddress": "LigMAx75tZtD9NyxF3VwYQuEsf4uWPxoHgXkeiA2TPu",
    "assetName": "LIGMA",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ligma-ligmax75tztd9nyxf3vwyquesf4uwpxohgxkeia2tpu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SPDR-AT79REYU9XTHUTF5VM6Q4OA9K8W7918FP5SU7G1MDMQY",
    "assetId": "SOLANA.SPDR-AT79REYU9XTHUTF5VM6Q4OA9K8W7918FP5SU7G1MDMQY",
    "assetAddress": "AT79ReYU9XtHUTF5vM6Q4oa9K8w7918Fp5SU7G1MDMQY",
    "assetName": "SPDR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.spdr-at79reyu9xthutf5vm6q4oa9k8w7918fp5su7g1mdmqy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.VCAT-VP9UABXLM4KYRVYJISU65RZ8BU5XNABEWU7LVMYU2X4",
    "assetId": "SOLANA.VCAT-VP9UABXLM4KYRVYJISU65RZ8BU5XNABEWU7LVMYU2X4",
    "assetAddress": "VP9UaBXLM4KYRvyjisu65rz8BU5xNAbewU7LVmyU2x4",
    "assetName": "VCAT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.vcat-vp9uabxlm4kyrvyjisu65rz8bu5xnabewu7lvmyu2x4.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MUZKI-C1KZNKFFGDTP8VF1PFYA4S32RLPQK5KPAURCAQJWXFWB",
    "assetId": "SOLANA.MUZKI-C1KZNKFFGDTP8VF1PFYA4S32RLPQK5KPAURCAQJWXFWB",
    "assetAddress": "C1kzNkFfgdtP8VF1pFYA4S32RLPqk5KPaurCaQJwxfWb",
    "assetName": "MUZKI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.muzki-c1kznkffgdtp8vf1pfya4s32rlpqk5kpaurcaqjwxfwb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TOPG-8NH3AFWKIZHMBVD83SSXC2YBSFMFL4M2BEEPVL6UPUMP",
    "assetId": "SOLANA.TOPG-8NH3AFWKIZHMBVD83SSXC2YBSFMFL4M2BEEPVL6UPUMP",
    "assetAddress": "8NH3AfwkizHmbVd83SSxc2YbsFmFL4m2BeepvL6upump",
    "assetName": "TOPG",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.topg-8nh3afwkizhmbvd83ssxc2ybsfmfl4m2beepvl6upump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LOON-GDJFYK4UMETVFVU9R5PKDYAXZD2KTMHMBIRWNJOKA7WW",
    "assetId": "SOLANA.LOON-GDJFYK4UMETVFVU9R5PKDYAXZD2KTMHMBIRWNJOKA7WW",
    "assetAddress": "GDjFyK4umeTvfVu9r5pkdyaxzD2KtMhmBirwnJoKA7WW",
    "assetName": "LOON",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.loon-gdjfyk4umetvfvu9r5pkdyaxzd2ktmhmbirwnjoka7ww.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KATCHU-CKIW2P4ZNGHA6FIPHMJGGHGDZM638DWOTXKZGI8PSDXF",
    "assetId": "SOLANA.KATCHU-CKIW2P4ZNGHA6FIPHMJGGHGDZM638DWOTXKZGI8PSDXF",
    "assetAddress": "CKiW2P4zngHa6fiPhMJGGhgDZm638dWotxkzgi8PsDxf",
    "assetName": "KATCHU",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.katchu-ckiw2p4zngha6fiphmjgghgdzm638dwotxkzgi8psdxf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FRONK-5YXNBU8DGYJZNI3MPD9RS4XLH9CKXRHPJJ5VCUJUWG5H",
    "assetId": "SOLANA.FRONK-5YXNBU8DGYJZNI3MPD9RS4XLH9CKXRHPJJ5VCUJUWG5H",
    "assetAddress": "5yxNbU8DgYJZNi3mPD9rs4XLh9ckXrhPjJ5VCujUWg5H",
    "assetName": "FRONK",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fronk-5yxnbu8dgyjzni3mpd9rs4xlh9ckxrhpjj5vcujuwg5h.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SMOL-A9JLULRDSYZKBTKFVG44W8NWZVCN6RGLPOA3HY7TQ85K",
    "assetId": "SOLANA.SMOL-A9JLULRDSYZKBTKFVG44W8NWZVCN6RGLPOA3HY7TQ85K",
    "assetAddress": "A9jLULrDSYZkBtKfvG44w8NWzvCN6rGLpoA3hY7TQ85k",
    "assetName": "SMOL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.smol-a9jlulrdsyzkbtkfvg44w8nwzvcn6rglpoa3hy7tq85k.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LTX-LTXH7NCGXZ5TBZ57H8OZU7YWMDSVFSQWVIW4B28YG8X",
    "assetId": "SOLANA.LTX-LTXH7NCGXZ5TBZ57H8OZU7YWMDSVFSQWVIW4B28YG8X",
    "assetAddress": "LTXH7nCGXz5TBZ57H8oZu7YwmDSVfSqWViW4B28yg8X",
    "assetName": "LTX",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ltx-ltxh7ncgxz5tbz57h8ozu7ywmdsvfsqwviw4b28yg8x.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SENK-FEBG9UTF5WFA2PST6KNJ6UITVWTFC87R38WMRNXRNMIW",
    "assetId": "SOLANA.SENK-FEBG9UTF5WFA2PST6KNJ6UITVWTFC87R38WMRNXRNMIW",
    "assetAddress": "FeBg9Utf5wFa2PsT6KnJ6uitvWtfc87R38wmRnxrNMiW",
    "assetName": "SENK",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.senk-febg9utf5wfa2pst6knj6uitvwtfc87r38wmrnxrnmiw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WORK-9TNKUSLJAYCWPKZOJAK5JMXKDKXBHRKFNVSSA7TPUGLB",
    "assetId": "SOLANA.WORK-9TNKUSLJAYCWPKZOJAK5JMXKDKXBHRKFNVSSA7TPUGLB",
    "assetAddress": "9tnkusLJaycWpkzojAk5jmxkdkxBHRkFNVSsa7tPUgLb",
    "assetName": "WORK",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.work-9tnkusljaycwpkzojak5jmxkdkxbhrkfnvssa7tpuglb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.XTV-GTTS8JKHMFWBDYYKNSGBDQEYQVKPALKP98YJRGBRBVUA",
    "assetId": "SOLANA.XTV-GTTS8JKHMFWBDYYKNSGBDQEYQVKPALKP98YJRGBRBVUA",
    "assetAddress": "GTTS8jkhmfWBdYyKNSgbDQeYqVkpALkP98YJRGbRbvUA",
    "assetName": "XTV",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.xtv-gtts8jkhmfwbdyyknsgbdqeyqvkpalkp98yjrgbrbvua.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SRM-SRMUAPVNDXXOKK5GT7XD5CUUGXMBCOAZ2LHEUAOKWRT",
    "assetId": "SOLANA.SRM-SRMUAPVNDXXOKK5GT7XD5CUUGXMBCOAZ2LHEUAOKWRT",
    "assetAddress": "SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt",
    "assetName": "SRM",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.srm-srmuapvndxxokk5gt7xd5cuugxmbcoaz2lheuaokwrt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PUFF-G9TT98AYSZNRK7JWSFUZ9FNTDOKXS6BROHDO9HSMJTRB",
    "assetId": "SOLANA.PUFF-G9TT98AYSZNRK7JWSFUZ9FNTDOKXS6BROHDO9HSMJTRB",
    "assetAddress": "G9tt98aYSznRk7jWsfuz9FnTdokxS6Brohdo9hSmjTRB",
    "assetName": "PUFF",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.puff-g9tt98aysznrk7jwsfuz9fntdokxs6brohdo9hsmjtrb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JJ-8EAURUGF8TOBMKG4CPJJTY9ACPX1UBMDEXW2JU84MCG4",
    "assetId": "SOLANA.JJ-8EAURUGF8TOBMKG4CPJJTY9ACPX1UBMDEXW2JU84MCG4",
    "assetAddress": "8eAUrugF8ToBmkg4CpJjTY9AcPx1UBMdExw2Ju84MCG4",
    "assetName": "JJ",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jj-8eaurugf8tobmkg4cpjjty9acpx1ubmdexw2ju84mcg4.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ABR-A11BDAAUV8IB2FU7X6AXAVDTO1QZ8FXB3KK5EECDASP",
    "assetId": "SOLANA.ABR-A11BDAAUV8IB2FU7X6AXAVDTO1QZ8FXB3KK5EECDASP",
    "assetAddress": "a11bdAAuV8iB2fu7X6AxAvDTo1QZ8FXB3kk5eecdasp",
    "assetName": "ABR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.abr-a11bdaauv8ib2fu7x6axavdto1qz8fxb3kk5eecdasp.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HARAMBE-FCH1OIXTPRI8ZXBNMDCEADOJW2TOYFHXQDZACQKWDVSP",
    "assetId": "SOLANA.HARAMBE-FCH1OIXTPRI8ZXBNMDCEADOJW2TOYFHXQDZACQKWDVSP",
    "assetAddress": "Fch1oixTPri8zxBnmdCEADoJW2toyFHxqDZacQkwdvSP",
    "assetName": "HARAMBE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.harambe-fch1oixtpri8zxbnmdceadojw2toyfhxqdzacqkwdvsp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HARAMBE-CNL2OPDQAQDY7YUDM5V4MAATWUUY32LTKQNP93JZM8X6",
    "assetId": "SOLANA.HARAMBE-CNL2OPDQAQDY7YUDM5V4MAATWUUY32LTKQNP93JZM8X6",
    "assetAddress": "CNL2opdqaQDY7yudm5V4MAaTwuuY32LTKqNP93jZM8X6",
    "assetName": "HARAMBE",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.harambe-cnl2opdqaqdy7yudm5v4maatwuuy32ltkqnp93jzm8x6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ZEBU-7UNYEPWUHCPB28CNS65TPQT2QQMZAFTRZ9QABKDR8YN7",
    "assetId": "SOLANA.ZEBU-7UNYEPWUHCPB28CNS65TPQT2QQMZAFTRZ9QABKDR8YN7",
    "assetAddress": "7unYePWUHcpB28cnS65TpqT2qqmZaftRz9QABkdR8yN7",
    "assetName": "ZEBU",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.zebu-7unyepwuhcpb28cns65tpqt2qqmzaftrz9qabkdr8yn7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EPEP-RUPBMGF6P42AAEN1QVHFREZEJQRY1CLKE1PUYFVVPNL",
    "assetId": "SOLANA.EPEP-RUPBMGF6P42AAEN1QVHFREZEJQRY1CLKE1PUYFVVPNL",
    "assetAddress": "RUpbmGF6p42AAeN1QvhFReZejQry1cLkE1PUYFVVpnL",
    "assetName": "EPEP",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.epep-rupbmgf6p42aaen1qvhfrezejqry1clke1puyfvvpnl.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FOXY-FOXYMU5XWXRE7ZEOSVZVIRK3NGAWHUP9KUH97Y2NDHCQ",
    "assetId": "SOLANA.FOXY-FOXYMU5XWXRE7ZEOSVZVIRK3NGAWHUP9KUH97Y2NDHCQ",
    "assetAddress": "FoXyMu5xwXre7zEoSvzViRk3nGawHUp9kUh97y2NDhcq",
    "assetName": "FOXY",
    "decimals": 0,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.foxy-foxymu5xwxre7zeosvzvirk3ngawhup9kuh97y2ndhcq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EDSE-2ZVO6BNWJTYXHSPGBCFAJTRETB7NWRJHK8MMMTEXVHHC",
    "assetId": "SOLANA.EDSE-2ZVO6BNWJTYXHSPGBCFAJTRETB7NWRJHK8MMMTEXVHHC",
    "assetAddress": "2Zvo6bnwJtyXhsPgbcfajTrEtB7NwrJhK8mMmtEXvHHc",
    "assetName": "EDSE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.edse-2zvo6bnwjtyxhspgbcfajtretb7nwrjhk8mmmtexvhhc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FREE-FCVN3F5BTCHSH53IXTURMMF5VQJ9YGSSYRRJFKF3WCX2",
    "assetId": "SOLANA.FREE-FCVN3F5BTCHSH53IXTURMMF5VQJ9YGSSYRRJFKF3WCX2",
    "assetAddress": "Fcvn3f5BTChSH53ixtURMmF5Vqj9yGsSYrRjFkf3wCX2",
    "assetName": "FREE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.free-fcvn3f5btchsh53ixturmmf5vqj9ygssyrrjfkf3wcx2.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SHARBI-8D1NUMJQAM54O34KJ2KNFHSTAWOEHER4MBC7LFIDDCQQ",
    "assetId": "SOLANA.SHARBI-8D1NUMJQAM54O34KJ2KNFHSTAWOEHER4MBC7LFIDDCQQ",
    "assetAddress": "8D1nUMJQam54o34Kj2knFhSTaWoehEr4mBc7LfiDdCqq",
    "assetName": "SHARBI",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sharbi-8d1numjqam54o34kj2knfhstawoeher4mbc7lfiddcqq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ACHI-HVQKZBZP1YHXDQDURUB8FQRFBJ6RU9CYTZSCMYG7EFAE",
    "assetId": "SOLANA.ACHI-HVQKZBZP1YHXDQDURUB8FQRFBJ6RU9CYTZSCMYG7EFAE",
    "assetAddress": "HvQKzBzp1YhXdqDUrUB8fqrfbJ6rU9CytzscMyG7EFAe",
    "assetName": "ACHI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.achi-hvqkzbzp1yhxdqdurub8fqrfbj6ru9cytzscmyg7efae.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ACHI-4RUFHWTRPJD1ECGJW1UREVHA8G63CRATUOFLRVRKKQHS",
    "assetId": "SOLANA.ACHI-4RUFHWTRPJD1ECGJW1UREVHA8G63CRATUOFLRVRKKQHS",
    "assetAddress": "4rUfhWTRpjD1ECGjw1UReVhA8G63CrATuoFLRVRkkqhs",
    "assetName": "ACHI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.achi-4rufhwtrpjd1ecgjw1urevha8g63cratuoflrvrkkqhs.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FAM-7NJSG9BA1XVXX9DNPE5FERHK4ZB7MBCHKZ6ZSX5K3ADR",
    "assetId": "SOLANA.FAM-7NJSG9BA1XVXX9DNPE5FERHK4ZB7MBCHKZ6ZSX5K3ADR",
    "assetAddress": "7njsg9BA1xvXX9DNpe5fERHK4zb7MbCHKZ6zsx5k3adr",
    "assetName": "FAM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fam-7njsg9ba1xvxx9dnpe5ferhk4zb7mbchkz6zsx5k3adr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FLOCKA-9N8B1EXLCA8Z22MF7PJLKVNZUQGGBYPFLRMFQVECHESU",
    "assetId": "SOLANA.FLOCKA-9N8B1EXLCA8Z22MF7PJLKVNZUQGGBYPFLRMFQVECHESU",
    "assetAddress": "9n8b1EXLCA8Z22mf7pjLKVNzuQgGbyPfLrmFQvEcHeSU",
    "assetName": "FLOCKA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.flocka-9n8b1exlca8z22mf7pjlkvnzuqggbypflrmfqvechesu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.POOWEL-BHCPVARUJEV3RCAMBLGRM7QPMZOTSCCHCKWWZVCSAHJI",
    "assetId": "SOLANA.POOWEL-BHCPVARUJEV3RCAMBLGRM7QPMZOTSCCHCKWWZVCSAHJI",
    "assetAddress": "BHcPVARUJEV3rCAmbLgRm7QPmZotsCcHcKWwzvCSAHJi",
    "assetName": "POOWEL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.poowel-bhcpvarujev3rcamblgrm7qpmzotscchckwwzvcsahji.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MIM-G33S1LIUADEBLZN5JL6OCSXQRT2WSUQ9W6NZ8O4K1B4L",
    "assetId": "SOLANA.MIM-G33S1LIUADEBLZN5JL6OCSXQRT2WSUQ9W6NZ8O4K1B4L",
    "assetAddress": "G33s1LiUADEBLzN5jL6ocSXqrT2wsUq9W6nZ8o4k1b4L",
    "assetName": "MIM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mim-g33s1liuadeblzn5jl6ocsxqrt2wsuq9w6nz8o4k1b4l.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SI-FXGDFSY1Z5MVH53O69S2EV6TGXTAJ1RQ5RJ5MOCPKMQZ",
    "assetId": "SOLANA.SI-FXGDFSY1Z5MVH53O69S2EV6TGXTAJ1RQ5RJ5MOCPKMQZ",
    "assetAddress": "Fxgdfsy1Z5Mvh53o69s2Ev6TGxtAJ1RQ5RJ5moCpKmQZ",
    "assetName": "SI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.si-fxgdfsy1z5mvh53o69s2ev6tgxtaj1rq5rj5mocpkmqz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DADDY-4CNK9EPNW5IXFLZATCPJJDB1PUTCRPVVGTQUKM9EPUMP",
    "assetId": "SOLANA.DADDY-4CNK9EPNW5IXFLZATCPJJDB1PUTCRPVVGTQUKM9EPUMP",
    "assetAddress": "4Cnk9EPnW5ixfLZatCPJjDB1PUtcRpVVgTQukm9epump",
    "assetName": "DADDY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.daddy-4cnk9epnw5ixflzatcpjjdb1putcrpvvgtqukm9epump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DADDY-2J5USGQGARWOH7QDBMHSDA3D7UBFBKDZSDY1YPTSPUMP",
    "assetId": "SOLANA.DADDY-2J5USGQGARWOH7QDBMHSDA3D7UBFBKDZSDY1YPTSPUMP",
    "assetAddress": "2J5uSgqgarWoh7QDBmHSDA3d7UbfBKDZsdy1ypTSpump",
    "assetName": "DADDY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.daddy-2j5usgqgarwoh7qdbmhsda3d7ubfbkdzsdy1yptspump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KAMA-HNKKZR1YTFBUUXM6G3IVRS2RY68KHHGV7BNDFF1GCSJB",
    "assetId": "SOLANA.KAMA-HNKKZR1YTFBUUXM6G3IVRS2RY68KHHGV7BNDFF1GCSJB",
    "assetAddress": "HnKkzR1YtFbUUxM6g3iVRS2RY68KHhGV7bNdfF1GCsJB",
    "assetName": "KAMA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.kama-hnkkzr1ytfbuuxm6g3ivrs2ry68khhgv7bndff1gcsjb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NOTE-CGBCLNUZP7JWMFEXTAKMABRFXDHXEJKEUH94JLU3LCVK",
    "assetId": "SOLANA.NOTE-CGBCLNUZP7JWMFEXTAKMABRFXDHXEJKEUH94JLU3LCVK",
    "assetAddress": "CgbcLNUZP7jWmFeXtaKmABRFXDhxejkeUH94JLu3Lcvk",
    "assetName": "NOTE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.note-cgbclnuzp7jwmfextakmabrfxdhxejkeuh94jlu3lcvk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MUMU-5LAFQURVCO6O7KMZ42EQVEJ9LW31STPYGJEEU5SKOMTA",
    "assetId": "SOLANA.MUMU-5LAFQURVCO6O7KMZ42EQVEJ9LW31STPYGJEEU5SKOMTA",
    "assetAddress": "5LafQUrVco6o7KMz42eqVEJ9LW31StPyGjeeu5sKoMtA",
    "assetName": "MUMU",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mumu-5lafqurvco6o7kmz42eqvej9lw31stpygjeeu5skomta.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.VONSPEED-DVZRCERBZYDH92BBZSJX1DKWVVB4OMWHRVNZ8CWRYXXV",
    "assetId": "SOLANA.VONSPEED-DVZRCERBZYDH92BBZSJX1DKWVVB4OMWHRVNZ8CWRYXXV",
    "assetAddress": "DVzrCErBzydh92bBzSJX1dKwVvb4omwhrvNz8CwRyxxV",
    "assetName": "VONSPEED",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.vonspeed-dvzrcerbzydh92bbzsjx1dkwvvb4omwhrvnz8cwryxxv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GECKO-6CNHDCZD5RKVBWXXYOKQQNQPJFWGOHF94D7BMC73X6ZK",
    "assetId": "SOLANA.GECKO-6CNHDCZD5RKVBWXXYOKQQNQPJFWGOHF94D7BMC73X6ZK",
    "assetAddress": "6CNHDCzD5RkvBWxxyokQQNQPjFWgoHF94D7BmC73X6ZK",
    "assetName": "GECKO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gecko-6cnhdczd5rkvbwxxyokqqnqpjfwgohf94d7bmc73x6zk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLNIC-DEAKMZAEZJA3MH5OKZE6WUVYGLP3LFUVM6RG78HQXTZ9",
    "assetId": "SOLANA.SOLNIC-DEAKMZAEZJA3MH5OKZE6WUVYGLP3LFUVM6RG78HQXTZ9",
    "assetAddress": "DeaKMzAeZja3Mh5okZE6WUvygLP3Lfuvm6Rg78HqXTz9",
    "assetName": "SOLNIC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solnic-deakmzaezja3mh5okze6wuvyglp3lfuvm6rg78hqxtz9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.YUGE-FPC2TNMME78KJSTTYUSJFUFGB14VK15A3P13P9ZZYVOV",
    "assetId": "SOLANA.YUGE-FPC2TNMME78KJSTTYUSJFUFGB14VK15A3P13P9ZZYVOV",
    "assetAddress": "Fpc2tnmme78kjsttyuSjfUfgB14vk15a3P13P9zZYvov",
    "assetName": "YUGE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.yuge-fpc2tnmme78kjsttyusjfufgb14vk15a3p13p9zzyvov.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NFD-CY2E69DSG9VBSMOAXDVYMMDSMEP4SZTRY1RQVQ9TKNDU",
    "assetId": "SOLANA.NFD-CY2E69DSG9VBSMOAXDVYMMDSMEP4SZTRY1RQVQ9TKNDU",
    "assetAddress": "CY2E69dSG9vBsMoaXDvYmMDSMEP4SZtRY1rqVQ9tkNDu",
    "assetName": "NFD",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nfd-cy2e69dsg9vbsmoaxdvymmdsmep4sztry1rqvq9tkndu.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SEAL-3B3ZFS7EB46RE9GHWV6CCYRSBGY5EVQF2I2VXMD6TGE6",
    "assetId": "SOLANA.SEAL-3B3ZFS7EB46RE9GHWV6CCYRSBGY5EVQF2I2VXMD6TGE6",
    "assetAddress": "3B3Zfs7eb46Re9GHWv6ccYRSBGy5EvQF2i2VXMD6tge6",
    "assetName": "SEAL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.seal-3b3zfs7eb46re9ghwv6ccyrsbgy5evqf2i2vxmd6tge6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CIRCLE-EKHR62PC6Y1AXRLS7CR8YC4BZEW19MTHXQLCLMRF9VNQ",
    "assetId": "SOLANA.CIRCLE-EKHR62PC6Y1AXRLS7CR8YC4BZEW19MTHXQLCLMRF9VNQ",
    "assetAddress": "EkHr62PC6Y1axrLS7cR8YC4BZeW19mtHxQLCLMrf9vnq",
    "assetName": "CIRCLE",
    "decimals": 3,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.circle-ekhr62pc6y1axrls7cr8yc4bzew19mthxqlclmrf9vnq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NANA-HXRELUQFVVJTOVBACJR9YECDFQMUQGGPYB68JVDYXKBR",
    "assetId": "SOLANA.NANA-HXRELUQFVVJTOVBACJR9YECDFQMUQGGPYB68JVDYXKBR",
    "assetAddress": "HxRELUQfvvjToVbacjr9YECdfQMUqGgPYB68jVDYxkbr",
    "assetName": "NANA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nana-hxreluqfvvjtovbacjr9yecdfqmuqggpyb68jvdyxkbr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JIZZUS-7VQDGLTR2QO6MBOTKKVMCKFW83FGZKFQLR3CJ6X3ZRF7",
    "assetId": "SOLANA.JIZZUS-7VQDGLTR2QO6MBOTKKVMCKFW83FGZKFQLR3CJ6X3ZRF7",
    "assetAddress": "7vQdgLtR2Qo6MBotkKVmCKfw83fGZkFQLR3Cj6X3ZRF7",
    "assetName": "JIZZUS",
    "decimals": 7,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jizzus-7vqdgltr2qo6mbotkkvmckfw83fgzkfqlr3cj6x3zrf7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LDZ-E5ZVEBMAZQAYQ4UEISNRLXFMERDS9SKL31YPAN7J5GJK",
    "assetId": "SOLANA.LDZ-E5ZVEBMAZQAYQ4UEISNRLXFMERDS9SKL31YPAN7J5GJK",
    "assetAddress": "E5ZVeBMazQAYq4UEiSNRLxfMeRds9SKL31yPan7j5GJK",
    "assetName": "LDZ",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ldz-e5zvebmazqayq4ueisnrlxfmerds9skl31ypan7j5gjk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOOK-4NFWUKIEVW5WCPCXTUDDFXWLYXSEDVGKPENZC9M9Y5ME",
    "assetId": "SOLANA.BOOK-4NFWUKIEVW5WCPCXTUDDFXWLYXSEDVGKPENZC9M9Y5ME",
    "assetAddress": "4nFwuKievw5wcpcXtUDdfxWLyXsEdvgkpENzC9M9Y5me",
    "assetName": "BOOK",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.book-4nfwukievw5wcpcxtuddfxwlyxsedvgkpenzc9m9y5me.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RNT-2FUFHZYD47MAPV9WCFXH5GNQWFXTQCYU9XAN4THBPUMP",
    "assetId": "SOLANA.RNT-2FUFHZYD47MAPV9WCFXH5GNQWFXTQCYU9XAN4THBPUMP",
    "assetAddress": "2fUFhZyd47Mapv9wcfXh5gnQwFXtqcYu9xAN4THBpump",
    "assetName": "RNT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rnt-2fufhzyd47mapv9wcfxh5gnqwfxtqcyu9xan4thbpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MILEI-43N5UGR3MNFHJFZPBPNM7ZDNOBGHIYBTHTAQFZQV65MH",
    "assetId": "SOLANA.MILEI-43N5UGR3MNFHJFZPBPNM7ZDNOBGHIYBTHTAQFZQV65MH",
    "assetAddress": "43N5UGr3mnfhJFzpBPNM7ZdnobghiYBtHTaQfZQv65mh",
    "assetName": "MILEI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.milei-43n5ugr3mnfhjfzpbpnm7zdnobghiybthtaqfzqv65mh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ALMC-7F94ZK1EGFOEG57VJ5FTDDJMMPNHM4DYS7KRIYD2T4BA",
    "assetId": "SOLANA.ALMC-7F94ZK1EGFOEG57VJ5FTDDJMMPNHM4DYS7KRIYD2T4BA",
    "assetAddress": "7f94zk1EgfoeG57Vj5FtDDjMmPNHM4DYs7KRiyd2T4bA",
    "assetName": "ALMC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.almc-7f94zk1egfoeg57vj5ftddjmmpnhm4dys7kriyd2t4ba.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MC-FYUKUYBYWQUUYRUWIAEZBVHTP2DUGX1EG8TQNIKKXQJ9",
    "assetId": "SOLANA.MC-FYUKUYBYWQUUYRUWIAEZBVHTP2DUGX1EG8TQNIKKXQJ9",
    "assetAddress": "FYUkUybywqUUyrUwiAezbvhTp2DUgx1eg8tQNiKkXqJ9",
    "assetName": "MC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mc-fyukuybywquuyruwiaezbvhtp2dugx1eg8tqnikkxqj9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NINJA-DFRJXDOLMYT6BNYENE8WRJZJ2UPUSLZLEMMYBLUTKCTK",
    "assetId": "SOLANA.NINJA-DFRJXDOLMYT6BNYENE8WRJZJ2UPUSLZLEMMYBLUTKCTK",
    "assetAddress": "DFrJxDoLMYt6bNYeNe8Wrjzj2UPUSLZLEMMYBLuTKcTk",
    "assetName": "NINJA",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ninja-dfrjxdolmyt6bnyene8wrjzj2upuslzlemmyblutkctk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NINJA-FGX1WD9WZMU3YLWXAFSARPFKGZJLB2DZCQMKX9EXPUVJ",
    "assetId": "SOLANA.NINJA-FGX1WD9WZMU3YLWXAFSARPFKGZJLB2DZCQMKX9EXPUVJ",
    "assetAddress": "FgX1WD9WzMU3yLwXaFSarPfkgzjLb2DZCqmkx9ExpuvJ",
    "assetName": "NINJA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ninja-fgx1wd9wzmu3ylwxafsarpfkgzjlb2dzcqmkx9expuvj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NINJA-2XP43MAWHFU7PWPUMVKC6AUWG4GX8XPQLTGMKSZFCEJT",
    "assetId": "SOLANA.NINJA-2XP43MAWHFU7PWPUMVKC6AUWG4GX8XPQLTGMKSZFCEJT",
    "assetAddress": "2xP43MawHfU7pwPUmvkc6AUWg4GX8xPQLTGMkSZfCEJT",
    "assetName": "NINJA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ninja-2xp43mawhfu7pwpumvkc6auwg4gx8xpqltgmkszfcejt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NUTS-DEEZGP19ZPOVNEWRJZW7KUNBKLH6XJNJZ4HSUJNMZV7J",
    "assetId": "SOLANA.NUTS-DEEZGP19ZPOVNEWRJZW7KUNBKLH6XJNJZ4HSUJNMZV7J",
    "assetAddress": "DEEZgP19ZPovNeWRJZw7KuNbkLH6xjNjZ4HsUJnmZv7J",
    "assetName": "NUTS",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nuts-deezgp19zpovnewrjzw7kunbklh6xjnjz4hsujnmzv7j.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.STBOT-2X8O3HA5S5FBXCSE9HZVTF3ROHCMWHQKDNKNEPUZPRD5",
    "assetId": "SOLANA.STBOT-2X8O3HA5S5FBXCSE9HZVTF3ROHCMWHQKDNKNEPUZPRD5",
    "assetAddress": "2x8o3hA5S5fBxCSE9hzVTf3RohcMWHqkDNKNEPuzprD5",
    "assetName": "STBOT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.stbot-2x8o3ha5s5fbxcse9hzvtf3rohcmwhqkdnknepuzprd5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WHY-WHYOABUMCMXCQW38Y2MJS4CVKCBGWIDIZBMVVCEJMGT",
    "assetId": "SOLANA.WHY-WHYOABUMCMXCQW38Y2MJS4CVKCBGWIDIZBMVVCEJMGT",
    "assetAddress": "WHYoaBumcmxCqw38y2mjs4cVkCBgwiDizbMVvcejmGT",
    "assetName": "WHY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.why-whyoabumcmxcqw38y2mjs4cvkcbgwidizbmvvcejmgt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DOGGO-DOGGOYB1UHFJGFDHHJF8FKEBUMV58QO98CISWGED7FTK",
    "assetId": "SOLANA.DOGGO-DOGGOYB1UHFJGFDHHJF8FKEBUMV58QO98CISWGED7FTK",
    "assetAddress": "Doggoyb1uHFJGFdHhJf8FKEBUMv58qo98CisWgeD7Ftk",
    "assetName": "DOGGO",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.doggo-doggoyb1uhfjgfdhhjf8fkebumv58qo98ciswged7ftk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLR-7J7H7SGSNNDECNGAPJPACN4AAARU4HS7NAFYSEUYZJ3K",
    "assetId": "SOLANA.SOLR-7J7H7SGSNNDECNGAPJPACN4AAARU4HS7NAFYSEUYZJ3K",
    "assetAddress": "7j7H7sgsnNDeCngAPjpaCN4aaaru4HS7NAFYSEUyzJ3k",
    "assetName": "SOLR",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solr-7j7h7sgsnndecngapjpacn4aaaru4hs7nafyseuyzj3k.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PRNT-4TUNZCGP2FPD48FCW4SERJYQYDZMRPJ4ZUBNXFESKEYK",
    "assetId": "SOLANA.PRNT-4TUNZCGP2FPD48FCW4SERJYQYDZMRPJ4ZUBNXFESKEYK",
    "assetAddress": "4TUNzcgp2fPD48fcW4seRjyqyDZMrPj4ZubnXFEsKeYk",
    "assetName": "PRNT",
    "decimals": 7,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.prnt-4tunzcgp2fpd48fcw4serjyqydzmrpj4zubnxfeskeyk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DGLN-E6UU5M1Z4CVSAAF99D9WROXSASWMEXSVHRZ3JQRXTM2X",
    "assetId": "SOLANA.DGLN-E6UU5M1Z4CVSAAF99D9WROXSASWMEXSVHRZ3JQRXTM2X",
    "assetAddress": "E6UU5M1z4CvSAAF99d9wRoXsasWMEXsvHrz3JQRXtm2X",
    "assetName": "DGLN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dgln-e6uu5m1z4cvsaaf99d9wroxsaswmexsvhrz3jqrxtm2x.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ZOOMER-9MBZPYMRKJ2R5NTQZMMNXNCM5J1MAAFSYUTBSKJAF3WU",
    "assetId": "SOLANA.ZOOMER-9MBZPYMRKJ2R5NTQZMMNXNCM5J1MAAFSYUTBSKJAF3WU",
    "assetAddress": "9MBzpyMRkj2r5nTQZMMnxnCm5j1MAAFSYUtbSKjAF3WU",
    "assetName": "ZOOMER",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.zoomer-9mbzpymrkj2r5ntqzmmnxncm5j1maafsyutbskjaf3wu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ZOOMER-NBZECHSG771MRBI4Y2SSGKJFDUH8JSM2EO5FNCASLEU",
    "assetId": "SOLANA.ZOOMER-NBZECHSG771MRBI4Y2SSGKJFDUH8JSM2EO5FNCASLEU",
    "assetAddress": "nBZEcHSG771mRbi4y2sSgKjfDUH8jsM2Eo5fNcASLeU",
    "assetName": "ZOOMER",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.zoomer-nbzechsg771mrbi4y2ssgkjfduh8jsm2eo5fncasleu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ATH-F9BQFOWRML4RED6YPIL3XJBP7U9G8F61AKJMEYDB3WBR",
    "assetId": "SOLANA.ATH-F9BQFOWRML4RED6YPIL3XJBP7U9G8F61AKJMEYDB3WBR",
    "assetAddress": "F9BqFoWRML4Red6YPiL3xJBP7u9g8f61AKJMEYDB3wBR",
    "assetName": "ATH",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ath-f9bqfowrml4red6ypil3xjbp7u9g8f61akjmeydb3wbr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CEICAT-8PMJCZFS9W8TDKINBD85AUQXE8TWACCDEUWXPUEADL3J",
    "assetId": "SOLANA.CEICAT-8PMJCZFS9W8TDKINBD85AUQXE8TWACCDEUWXPUEADL3J",
    "assetAddress": "8PMJczfs9W8TDKiNBD85AuqxE8tWACCDeUwxpUeadL3j",
    "assetName": "CEICAT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ceicat-8pmjczfs9w8tdkinbd85auqxe8twaccdeuwxpueadl3j.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NVX-GTMTXOJIQSF8GFP83CUUNNDTIJTETMV7CNIVTJ6UAMWH",
    "assetId": "SOLANA.NVX-GTMTXOJIQSF8GFP83CUUNNDTIJTETMV7CNIVTJ6UAMWH",
    "assetAddress": "GtMtXoJiqSf8Gfp83cuunnDTiJTeTmv7cniVtJ6UAMWH",
    "assetName": "NVX",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nvx-gtmtxojiqsf8gfp83cuunndtijtetmv7cnivtj6uamwh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DPLN-J2LWSSXX4R3PYBJ1FWUX5NQO7PPXJCGPPUB2ZHNADWKA",
    "assetId": "SOLANA.DPLN-J2LWSSXX4R3PYBJ1FWUX5NQO7PPXJCGPPUB2ZHNADWKA",
    "assetAddress": "J2LWsSXx4r3pYbJ1fwuX5Nqo7PPxjcGPpUb2zHNadWKa",
    "assetName": "DPLN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dpln-j2lwssxx4r3pybj1fwux5nqo7ppxjcgppub2zhnadwka.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SWH-ASSNSWFHGVRENUN6YMZQKIETJPRV731BBETDWQSREAWJ",
    "assetId": "SOLANA.SWH-ASSNSWFHGVRENUN6YMZQKIETJPRV731BBETDWQSREAWJ",
    "assetAddress": "ASsnSwFhGVREnuN6YmZQKietjprv731BbETDWQsreAwj",
    "assetName": "SWH",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.swh-assnswfhgvrenun6ymzqkietjprv731bbetdwqsreawj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.POPDOG-EATGZHJVIJSK7NEKKRDJICWNBFPKJFATMREMRJXR8NBJ",
    "assetId": "SOLANA.POPDOG-EATGZHJVIJSK7NEKKRDJICWNBFPKJFATMREMRJXR8NBJ",
    "assetAddress": "EATGZHJViJsk7nEKkrdJicwNbfpkJfAtmrEmrjXR8NBj",
    "assetName": "POPDOG",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.popdog-eatgzhjvijsk7nekkrdjicwnbfpkjfatmremrjxr8nbj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FORA-FORAXIBD8K3A7C1PWXV1XQDHS5AV8Y8NWRMHEBAFDKES",
    "assetId": "SOLANA.FORA-FORAXIBD8K3A7C1PWXV1XQDHS5AV8Y8NWRMHEBAFDKES",
    "assetAddress": "ForaXiBD8K3a7C1PwxV1xqDHs5aV8y8nWRmHebafdkes",
    "assetName": "FORA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fora-foraxibd8k3a7c1pwxv1xqdhs5av8y8nwrmhebafdkes.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PIZZA-DZVUATQMDWZDHMGDPTKRMW2QBXSTJCNTCOBMDMPVURDA",
    "assetId": "SOLANA.PIZZA-DZVUATQMDWZDHMGDPTKRMW2QBXSTJCNTCOBMDMPVURDA",
    "assetAddress": "DZvuAtqMdWzDHMGDpTkRmW2QBxstjCNTcobMDMpVuRDa",
    "assetName": "PIZZA",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pizza-dzvuatqmdwzdhmgdptkrmw2qbxstjcntcobmdmpvurda.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BUD-BUD1144GGYWMMRFS4WHJFKOM5UHQC9A8DZHPVVR2VFPX",
    "assetId": "SOLANA.BUD-BUD1144GGYWMMRFS4WHJFKOM5UHQC9A8DZHPVVR2VFPX",
    "assetAddress": "BUD1144GGYwmMRFs4Whjfkom5UHqC9a8dZHPVvR2vfPx",
    "assetName": "BUD",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bud-bud1144ggywmmrfs4whjfkom5uhqc9a8dzhpvvr2vfpx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FSM-DMQV9NGBEVAKQOZ13NCKH5XJRFG2BQ5YTWD1XRGEKKAZ",
    "assetId": "SOLANA.FSM-DMQV9NGBEVAKQOZ13NCKH5XJRFG2BQ5YTWD1XRGEKKAZ",
    "assetAddress": "DMqv9nGbEVAkQoz13ncKh5XJrFg2BQ5YTwd1XrGEKkaz",
    "assetName": "FSM",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fsm-dmqv9ngbevakqoz13nckh5xjrfg2bq5ytwd1xrgekkaz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LUIS-5WU4TUCABDS7D5CMNGK2OTHA9GBAYYSD2MHZ1RER6C91",
    "assetId": "SOLANA.LUIS-5WU4TUCABDS7D5CMNGK2OTHA9GBAYYSD2MHZ1RER6C91",
    "assetAddress": "5wU4tUcAbds7d5cmnGK2otHa9gbayYsD2mhz1reR6c91",
    "assetName": "LUIS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.luis-5wu4tucabds7d5cmngk2otha9gbayysd2mhz1rer6c91.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BCOQ-COQRKAAKEUYGDPHUS3MRMRJ6DIHJEQJC2RFBT2YFXWN",
    "assetId": "SOLANA.BCOQ-COQRKAAKEUYGDPHUS3MRMRJ6DIHJEQJC2RFBT2YFXWN",
    "assetAddress": "coqRkaaKeUygDPhuS3mrmrj6DiHjeQJc2rFbT2YfxWn",
    "assetName": "BCOQ",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bcoq-coqrkaakeuygdphus3mrmrj6dihjeqjc2rfbt2yfxwn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MIMO-9TE7EBZ1DSFO1UQ2T4OYAKSM39Y6FWUHRD6UK6XAID16",
    "assetId": "SOLANA.MIMO-9TE7EBZ1DSFO1UQ2T4OYAKSM39Y6FWUHRD6UK6XAID16",
    "assetAddress": "9TE7ebz1dsFo1uQ2T4oYAKSm39Y6fWuHrd6Uk6XaiD16",
    "assetName": "MIMO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mimo-9te7ebz1dsfo1uq2t4oyaksm39y6fwuhrd6uk6xaid16.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLPAD-GFJ3VQ2ESTYF1HJP6KKLE9RT6U7JF9GNSZJHZWO5VPZP",
    "assetId": "SOLANA.SOLPAD-GFJ3VQ2ESTYF1HJP6KKLE9RT6U7JF9GNSZJHZWO5VPZP",
    "assetAddress": "GfJ3Vq2eSTYf1hJP6kKLE9RT6u7jF9gNszJhZwo5VPZp",
    "assetName": "SOLPAD",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solpad-gfj3vq2estyf1hjp6kkle9rt6u7jf9gnszjhzwo5vpzp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CUFF-2VYVWRWSNM8WXBFDPU4KQPZUB9FWCENFFODQVPHQ7RZE",
    "assetId": "SOLANA.CUFF-2VYVWRWSNM8WXBFDPU4KQPZUB9FWCENFFODQVPHQ7RZE",
    "assetAddress": "2VYVwrwSNM8WxbFdPU4KQpZUB9FWCenFFoDqvpHQ7rZE",
    "assetName": "CUFF",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cuff-2vyvwrwsnm8wxbfdpu4kqpzub9fwcenffodqvphq7rze.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$DTJR-7G7SMGV9NSG316YKK6IOBJMZWA8GZB15WD25KGAZGTAZ",
    "assetId": "SOLANA.$DTJR-7G7SMGV9NSG316YKK6IOBJMZWA8GZB15WD25KGAZGTAZ",
    "assetAddress": "7G7SMGV9nSG316ykk6iobjMZWa8GZb15Wd25kgaZGTaZ",
    "assetName": "$DTJR",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dtjr-7g7smgv9nsg316ykk6iobjmzwa8gzb15wd25kgazgtaz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GBOY-GKZBA2GDZW3MOXBFRSNRJTNI5UBDRNRZ9BQ1PNNX6KV",
    "assetId": "SOLANA.GBOY-GKZBA2GDZW3MOXBFRSNRJTNI5UBDRNRZ9BQ1PNNX6KV",
    "assetAddress": "GKZbA2gDzw3MoxbfRsnrJTNi5uBDrnrz9bq1pNnx6kv",
    "assetName": "GBOY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gboy-gkzba2gdzw3moxbfrsnrjtni5ubdrnrz9bq1pnnx6kv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHEEPEPE-FWBIXTDCMXAWRFZBNEUMZHQZAFUVV6CZS5WCQULGWWSG",
    "assetId": "SOLANA.CHEEPEPE-FWBIXTDCMXAWRFZBNEUMZHQZAFUVV6CZS5WCQULGWWSG",
    "assetAddress": "FwBixtdcmxawRFzBNeUmzhQzaFuvv6czs5wCQuLgWWsg",
    "assetName": "CHEEPEPE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cheepepe-fwbixtdcmxawrfzbneumzhqzafuvv6czs5wcqulgwwsg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KNOB-5RITAPTFPQQTEFHCHVQNJR5OFNUJQCMGKTZYPD2AYLLY",
    "assetId": "SOLANA.KNOB-5RITAPTFPQQTEFHCHVQNJR5OFNUJQCMGKTZYPD2AYLLY",
    "assetAddress": "5ritAPtFPqQtEFHcHVqNjR5oFNUJqcmgKtZyPd2AyLLy",
    "assetName": "KNOB",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.knob-5ritaptfpqqtefhchvqnjr5ofnujqcmgktzypd2aylly.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MOGGO-FBTRB4ZF2U52FSNJLB79YRG73HBMVGSW9RC897SHQZB4",
    "assetId": "SOLANA.MOGGO-FBTRB4ZF2U52FSNJLB79YRG73HBMVGSW9RC897SHQZB4",
    "assetAddress": "FbtRb4zF2u52FSnjLB79yRg73hbMVgSw9rC897shqzb4",
    "assetName": "MOGGO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.moggo-fbtrb4zf2u52fsnjlb79yrg73hbmvgsw9rc897shqzb4.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SBONK-H1G6SZ1WDOMMMCFQBKABG9GKQPCO1SKQTAJWZ9DHMQZR",
    "assetId": "SOLANA.SBONK-H1G6SZ1WDOMMMCFQBKABG9GKQPCO1SKQTAJWZ9DHMQZR",
    "assetAddress": "H1G6sZ1WDoMmMCFqBKAbg9gkQPCo1sKQtaJWz9dHmqZr",
    "assetName": "SBONK",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sbonk-h1g6sz1wdommmcfqbkabg9gkqpco1skqtajwz9dhmqzr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SEAT-29AD8JW1YCVT3MXCVXJPE3EZWQXCGD7KASRWRC3TEMWG",
    "assetId": "SOLANA.SEAT-29AD8JW1YCVT3MXCVXJPE3EZWQXCGD7KASRWRC3TEMWG",
    "assetAddress": "29ad8JW1YcVT3mxcvxJpe3EzWqXCGD7KaSRWrc3TEMWG",
    "assetName": "SEAT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.seat-29ad8jw1ycvt3mxcvxjpe3ezwqxcgd7kasrwrc3temwg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PC-F5WPG7XDZCZNG5PYNWJPK8TZLT52WMAIT1ZUKRBALNEM",
    "assetId": "SOLANA.PC-F5WPG7XDZCZNG5PYNWJPK8TZLT52WMAIT1ZUKRBALNEM",
    "assetAddress": "F5WPg7xdZczNg5pynWjPK8TZLT52WmAiT1ZuKRbaLnEM",
    "assetName": "PC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pc-f5wpg7xdzczng5pynwjpk8tzlt52wmait1zukrbalnem.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLY-CUWIF1FIX5B3BWWB2N5BM35AIXVNR8LJJGUVMEWNZNGR",
    "assetId": "SOLANA.SOLY-CUWIF1FIX5B3BWWB2N5BM35AIXVNR8LJJGUVMEWNZNGR",
    "assetAddress": "CUwif1FiX5b3bwwb2n5Bm35AixvnR8LJjGUVmEwNZNgR",
    "assetName": "SOLY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.soly-cuwif1fix5b3bwwb2n5bm35aixvnr8ljjguvmewnzngr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LOVE-4QQV4LQUUXAN1EN1XQGRFY65TFLE5STJCFSCQOZQYB8T",
    "assetId": "SOLANA.LOVE-4QQV4LQUUXAN1EN1XQGRFY65TFLE5STJCFSCQOZQYB8T",
    "assetAddress": "4QQV4LQUUXAn1eN1XQGrfY65TfLe5STJcfsCQozqyb8T",
    "assetName": "LOVE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.love-4qqv4lquuxan1en1xqgrfy65tfle5stjcfscqozqyb8t.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NIGI-3BORKAXWR6WEV6KUFR9YKDLCM9CL5Q2P469TCQECANHY",
    "assetId": "SOLANA.NIGI-3BORKAXWR6WEV6KUFR9YKDLCM9CL5Q2P469TCQECANHY",
    "assetAddress": "3boRKAxWR6weV6kufr9ykdLcm9cL5q2p469tCqeCAnHy",
    "assetName": "NIGI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nigi-3borkaxwr6wev6kufr9ykdlcm9cl5q2p469tcqecanhy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NUT-4PB6MYMM9HYQN6OG9UF24EYZ2QWXCWCWGVCR1DKCGEER",
    "assetId": "SOLANA.NUT-4PB6MYMM9HYQN6OG9UF24EYZ2QWXCWCWGVCR1DKCGEER",
    "assetAddress": "4pb6mymm9hYQN6og9uF24eyZ2qwXCWCwGvcR1DkCgeEr",
    "assetName": "NUT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nut-4pb6mymm9hyqn6og9uf24eyz2qwxcwcwgvcr1dkcgeer.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WIFE-4Y3OURSJFSP431R3WJRWIALXRPSNYTPKVJMOV2BYPBIY",
    "assetId": "SOLANA.WIFE-4Y3OURSJFSP431R3WJRWIALXRPSNYTPKVJMOV2BYPBIY",
    "assetAddress": "4y3oUrsJfSp431R3wJrWiaLxRPsnYtpkVJmoV2bYpBiy",
    "assetName": "WIFE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wife-4y3oursjfsp431r3wjrwialxrpsnytpkvjmov2bypbiy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NCAT-82RC22MNYHRMBGWJ15RHYHFZVRU3BGFKJNTV3IHJPUMP",
    "assetId": "SOLANA.NCAT-82RC22MNYHRMBGWJ15RHYHFZVRU3BGFKJNTV3IHJPUMP",
    "assetAddress": "82Rc22mnyHrmBGwj15rhYhFzVrU3bgFkjNtV3iHjpump",
    "assetName": "NCAT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ncat-82rc22mnyhrmbgwj15rhyhfzvru3bgfkjntv3ihjpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHURRO-A3T817AK6XKHQQA148BJXKWJMHBMP9T2D9CAWVQNAHQR",
    "assetId": "SOLANA.CHURRO-A3T817AK6XKHQQA148BJXKWJMHBMP9T2D9CAWVQNAHQR",
    "assetAddress": "A3t817aK6XkhqQA148bjXKWJMhBmP9t2d9caWvQNaHQR",
    "assetName": "CHURRO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.churro-a3t817ak6xkhqqa148bjxkwjmhbmp9t2d9cawvqnahqr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.STARS-HCGYBXQ5UPY8MCCIHRP7ESMWWFQYZTRHRSMSKWTGXLGW",
    "assetId": "SOLANA.STARS-HCGYBXQ5UPY8MCCIHRP7ESMWWFQYZTRHRSMSKWTGXLGW",
    "assetAddress": "HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW",
    "assetName": "STARS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.stars-hcgybxq5upy8mccihrp7esmwwfqyztrhrsmskwtgxlgw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RATIO-RATIOMVG27RSZBSVBOPUVSDRGUZEALUFFMA61MPXC8J",
    "assetId": "SOLANA.RATIO-RATIOMVG27RSZBSVBOPUVSDRGUZEALUFFMA61MPXC8J",
    "assetAddress": "ratioMVg27rSZbSvBopUvsdrGUzeALUfFma61mpxc8J",
    "assetName": "RATIO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ratio-ratiomvg27rszbsvbopuvsdrguzealuffma61mpxc8j.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BIRDDOG-3XTP12PMKMHXB6YKEJAGPUJMGBLKRGGZHWGJUVTSBCOP",
    "assetId": "SOLANA.BIRDDOG-3XTP12PMKMHXB6YKEJAGPUJMGBLKRGGZHWGJUVTSBCOP",
    "assetAddress": "3XTp12PmKMHxB6YkejaGPUjMGBLKRGgzHWgJuVTsBCoP",
    "assetName": "BIRDDOG",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.birddog-3xtp12pmkmhxb6ykejagpujmgblkrggzhwgjuvtsbcop.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BAMBIT-XN9QD63MUYG7NPANMDKSMCQP3NQJTCGFQPTYQ2F1TQC",
    "assetId": "SOLANA.BAMBIT-XN9QD63MUYG7NPANMDKSMCQP3NQJTCGFQPTYQ2F1TQC",
    "assetAddress": "xN9Qd63mUYg7npanmdksmcqp3NQjTcGFQPTyq2F1TQC",
    "assetName": "BAMBIT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bambit-xn9qd63muyg7npanmdksmcqp3nqjtcgfqptyq2f1tqc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AEVUM-ESIRN3ORP85UYVZYDRZNBE9CYO7N1114YNLFDWMPCQCE",
    "assetId": "SOLANA.AEVUM-ESIRN3ORP85UYVZYDRZNBE9CYO7N1114YNLFDWMPCQCE",
    "assetAddress": "EsirN3orp85uyvZyDrZnbe9cyo7N1114ynLFdwMPCQce",
    "assetName": "AEVUM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.aevum-esirn3orp85uyvzydrznbe9cyo7n1114ynlfdwmpcqce.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SONIC-7EW2NTUQFYKVXF3WTA1L1V62PXB7RFYMVC7VEGXNDFIS",
    "assetId": "SOLANA.SONIC-7EW2NTUQFYKVXF3WTA1L1V62PXB7RFYMVC7VEGXNDFIS",
    "assetAddress": "7EW2NTuQFYKVxF3WTA1L1v62pxB7RFYmVC7veGxNDFis",
    "assetName": "SONIC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sonic-7ew2ntuqfykvxf3wta1l1v62pxb7rfymvc7vegxndfis.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.STREAM-54JVZGHYWURX5EVBTZQUSJJWOKZCZJBVOKDU93AUZF2H",
    "assetId": "SOLANA.STREAM-54JVZGHYWURX5EVBTZQUSJJWOKZCZJBVOKDU93AUZF2H",
    "assetAddress": "54jVZGHyWURX5evBtZqUsJjwoKzcZJbVokDU93AUZf2h",
    "assetName": "STREAM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.stream-54jvzghywurx5evbtzqusjjwokzczjbvokdu93auzf2h.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TTT-FNFKRV3V8DTA3GVJN6USHMILGYA8IZXFWKNWMJBFJMRJ",
    "assetId": "SOLANA.TTT-FNFKRV3V8DTA3GVJN6USHMILGYA8IZXFWKNWMJBFJMRJ",
    "assetAddress": "FNFKRV3V8DtA3gVJN6UshMiLGYA8izxFwkNWmJbFjmRj",
    "assetName": "TTT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ttt-fnfkrv3v8dta3gvjn6ushmilgya8izxfwknwmjbfjmrj.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ROCKY-4ICEZCREYNOP2ZAMMCKRHANZKT6XG9BPIJMCQV7MPW6Z",
    "assetId": "SOLANA.ROCKY-4ICEZCREYNOP2ZAMMCKRHANZKT6XG9BPIJMCQV7MPW6Z",
    "assetAddress": "4icEZCrEYNop2ZaMMCkRHaNzkt6xG9BpijMCQV7mpw6Z",
    "assetName": "ROCKY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rocky-4icezcreynop2zammckrhanzkt6xg9bpijmcqv7mpw6z.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ROCKY-DHTNFBP4NJAXWQF6LWG57GRJQZFXRSO1QK9FCMZT9SV7",
    "assetId": "SOLANA.ROCKY-DHTNFBP4NJAXWQF6LWG57GRJQZFXRSO1QK9FCMZT9SV7",
    "assetAddress": "DhTNFBp4NjaxWqf6LwG57GrJQZfXrso1qK9FcMZt9sv7",
    "assetName": "ROCKY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rocky-dhtnfbp4njaxwqf6lwg57grjqzfxrso1qk9fcmzt9sv7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MOLA-AMDNW9H5DFTQWZOWVFR4KUGSXJZLOKKSINVGGIUOLSPS",
    "assetId": "SOLANA.MOLA-AMDNW9H5DFTQWZOWVFR4KUGSXJZLOKKSINVGGIUOLSPS",
    "assetAddress": "AMdnw9H5DFtQwZowVFr4kUgSXJzLokKSinvgGiUoLSps",
    "assetName": "MOLA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mola-amdnw9h5dftqwzowvfr4kugsxjzlokksinvggiuolsps.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FECES-5J2SHKKZASCW33QPW6RRQFRKACLKN7QWWV1E7JX36NYN",
    "assetId": "SOLANA.FECES-5J2SHKKZASCW33QPW6RRQFRKACLKN7QWWV1E7JX36NYN",
    "assetAddress": "5j2shkkzascw33QpW6RRqfRkacLkn7QwwV1e7jX36nyn",
    "assetName": "FECES",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.feces-5j2shkkzascw33qpw6rrqfrkaclkn7qwwv1e7jx36nyn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CRAMER-E3THHRA9Y1TWAA1NPZO8FAYVFGBDNXRS8U8NMMSHL3M3",
    "assetId": "SOLANA.CRAMER-E3THHRA9Y1TWAA1NPZO8FAYVFGBDNXRS8U8NMMSHL3M3",
    "assetAddress": "E3tHHRa9Y1TWAA1nPZo8FAyVFgBDnXrS8u8nmMShL3M3",
    "assetName": "CRAMER",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cramer-e3thhra9y1twaa1npzo8fayvfgbdnxrs8u8nmmshl3m3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WBS-GJGHSC1HU4IBMZW6OWQR8L2RRT95ATC1BONULKP94AWU",
    "assetId": "SOLANA.WBS-GJGHSC1HU4IBMZW6OWQR8L2RRT95ATC1BONULKP94AWU",
    "assetAddress": "GJgHsc1HU4ibmzW6oWQr8L2RRT95ATc1BoNuLkp94AwU",
    "assetName": "WBS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wbs-gjghsc1hu4ibmzw6owqr8l2rrt95atc1bonulkp94awu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ZEUS-ZEUS1AR7AX8DFFJF5QJWJ2FTDDDNTROMNGO8YOQM3GQ",
    "assetId": "SOLANA.ZEUS-ZEUS1AR7AX8DFFJF5QJWJ2FTDDDNTROMNGO8YOQM3GQ",
    "assetAddress": "ZEUS1aR7aX8DFFJf5QjWj2ftDDdNTroMNGo8YoQm3Gq",
    "assetName": "ZEUS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.zeus-zeus1ar7ax8dffjf5qjwj2ftdddntromngo8yoqm3gq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PAI-EA5SJE2Y6YVCEW5DYTN7PYMUW5IKXKVBGDCMSNXEALJS",
    "assetId": "SOLANA.PAI-EA5SJE2Y6YVCEW5DYTN7PYMUW5IKXKVBGDCMSNXEALJS",
    "assetAddress": "Ea5SjE2Y6yvCeW5dYTn7PYMuW5ikXkvbGdcmSnXeaLjS",
    "assetName": "PAI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pai-ea5sje2y6yvcew5dytn7pymuw5ikxkvbgdcmsnxealjs.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PAI-2TYBZWN2X1G7Y6GRBB4CRX4KPK7BQDK9TYCOZBXXITFR",
    "assetId": "SOLANA.PAI-2TYBZWN2X1G7Y6GRBB4CRX4KPK7BQDK9TYCOZBXXITFR",
    "assetAddress": "2TybzwN2X1g7Y6GRbB4cRx4KPK7bqdK9tYCozBXxiTfr",
    "assetName": "PAI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pai-2tybzwn2x1g7y6grbb4crx4kpk7bqdk9tycozbxxitfr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CIF-G3VWVAAXPHCNNCNYABQ6YBRXQFRTEV3H7VEXZASZET6G",
    "assetId": "SOLANA.CIF-G3VWVAAXPHCNNCNYABQ6YBRXQFRTEV3H7VEXZASZET6G",
    "assetAddress": "G3vWvAaXPHCnncnyAbq6yBRXqfRtEV3h7vExzasZeT6g",
    "assetName": "CIF",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cif-g3vwvaaxphcnncnyabq6ybrxqfrtev3h7vexzaszet6g.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PEDRO-9SIKU8VNRIBYQSBFF84K5ZWG7HABZWYVZN7KRTGCZNFG",
    "assetId": "SOLANA.PEDRO-9SIKU8VNRIBYQSBFF84K5ZWG7HABZWYVZN7KRTGCZNFG",
    "assetAddress": "9SiKU8vnRiBYQSBff84K5zwG7habzwYVzn7KrtgCzNfg",
    "assetName": "PEDRO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pedro-9siku8vnribyqsbff84k5zwg7habzwyvzn7krtgcznfg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HMM-BWHSVKYRUJQVVRAKJGYLPNTUUCG4SPEH6XVKCJNYCI27",
    "assetId": "SOLANA.HMM-BWHSVKYRUJQVVRAKJGYLPNTUUCG4SPEH6XVKCJNYCI27",
    "assetAddress": "BWhsvkyrUJqVvrAKjGYLpnTuUCG4SPEh6xVKcjnYCi27",
    "assetName": "HMM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hmm-bwhsvkyrujqvvrakjgylpntuucg4speh6xvkcjnyci27.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CYS-BRLSMCZKUAR5W9VSUBF4J8HWEGGPRVAYYVGS4EX7DKEG",
    "assetId": "SOLANA.CYS-BRLSMCZKUAR5W9VSUBF4J8HWEGGPRVAYYVGS4EX7DKEG",
    "assetAddress": "BRLsMczKuaR5w9vSubF4j8HwEGGprVAyyVgS4EX7DKEg",
    "assetName": "CYS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cys-brlsmczkuar5w9vsubf4j8hweggprvayyvgs4ex7dkeg.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LMDA-LMDAMLNDUIDMSIMXGAE1GW7UBARFEGDAFTPKOHQE5GN",
    "assetId": "SOLANA.LMDA-LMDAMLNDUIDMSIMXGAE1GW7UBARFEGDAFTPKOHQE5GN",
    "assetAddress": "LMDAmLNduiDmSiMxgae1gW7ubArfEGdAfTpKohqE5gn",
    "assetName": "LMDA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lmda-lmdamlnduidmsimxgae1gw7ubarfegdaftpkohqe5gn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BENJI-CPJDHHBPWESLYGNUCZKCNWBPGMENI1XLOMD48X51MYYU",
    "assetId": "SOLANA.BENJI-CPJDHHBPWESLYGNUCZKCNWBPGMENI1XLOMD48X51MYYU",
    "assetAddress": "CPjDhhBpwEsLygNuczKcNwBPgMeni1xLomD48x51MyYU",
    "assetName": "BENJI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.benji-cpjdhhbpweslygnuczkcnwbpgmeni1xlomd48x51myyu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHIPI-CHIPIQTVKQ7OPTAD7YLQAEEHMPQXCA2WCRQDWFNNETE",
    "assetId": "SOLANA.CHIPI-CHIPIQTVKQ7OPTAD7YLQAEEHMPQXCA2WCRQDWFNNETE",
    "assetAddress": "chiPiQTvkQ7oPtAD7YLQaEeHmPqXCa2wcRQdwFNneTe",
    "assetName": "CHIPI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chipi-chipiqtvkq7optad7ylqaeehmpqxca2wcrqdwfnnete.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CMFI-5WSD311HY8NXQHKT9CWHWTNQAFK7BGEBLU8PY3DSNPAR",
    "assetId": "SOLANA.CMFI-5WSD311HY8NXQHKT9CWHWTNQAFK7BGEBLU8PY3DSNPAR",
    "assetAddress": "5Wsd311hY8NXQhkt9cWHwTnqafk7BGEbLu8Py3DSnPAr",
    "assetName": "CMFI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cmfi-5wsd311hy8nxqhkt9cwhwtnqafk7bgeblu8py3dsnpar.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MEMES-8W4QPYLX74VWBREWA3RVEPPVMNJ8VWMKCTWCTSYPQTDU",
    "assetId": "SOLANA.MEMES-8W4QPYLX74VWBREWA3RVEPPVMNJ8VWMKCTWCTSYPQTDU",
    "assetAddress": "8W4qpyLx74vwBRewa3rVEPPVMnJ8VWMkCTWCTSYPQTDu",
    "assetName": "MEMES",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.memes-8w4qpylx74vwbrewa3rveppvmnj8vwmkctwctsypqtdu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LOAF-3DE2YRHTD4VBJBB8EQAQFFYMPLU4ENSHT1EVEBWIL3TN",
    "assetId": "SOLANA.LOAF-3DE2YRHTD4VBJBB8EQAQFFYMPLU4ENSHT1EVEBWIL3TN",
    "assetAddress": "3de2yRhtD4VbJBb8EQAQffYMPLU4EnSHT1eveBwiL3tn",
    "assetName": "LOAF",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.loaf-3de2yrhtd4vbjbb8eqaqffymplu4ensht1evebwil3tn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GLXY-CJ5U6WPMJXFUYTJPUTS7RT1UQHTMSVRMVMJ8WD4NNDXW",
    "assetId": "SOLANA.GLXY-CJ5U6WPMJXFUYTJPUTS7RT1UQHTMSVRMVMJ8WD4NNDXW",
    "assetAddress": "CJ5U6wPmjxFUyTJpUTS7Rt1UqhTmSVRMvmJ8WD4nndXW",
    "assetName": "GLXY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.glxy-cj5u6wpmjxfuytjputs7rt1uqhtmsvrmvmj8wd4nndxw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SUNNY-SUNNYWGPQMFXE9WTZZNK7IPNJ3VYDRKGNXJRJM1S3AG",
    "assetId": "SOLANA.SUNNY-SUNNYWGPQMFXE9WTZZNK7IPNJ3VYDRKGNXJRJM1S3AG",
    "assetAddress": "SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag",
    "assetName": "SUNNY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sunny-sunnywgpqmfxe9wtzznk7ipnj3vydrkgnxjrjm1s3ag.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOGGY-GMEHF4SFXD9PRR9KJO7HYPJEHDCDG5YXTNP22KKNYWVZ",
    "assetId": "SOLANA.BOGGY-GMEHF4SFXD9PRR9KJO7HYPJEHDCDG5YXTNP22KKNYWVZ",
    "assetAddress": "GMEhF4sFXd9PRR9KJo7hyPjeHdcdg5yxTNP22KKNyWvZ",
    "assetName": "BOGGY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.boggy-gmehf4sfxd9prr9kjo7hypjehdcdg5yxtnp22kknywvz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SKULL-SKU11EYPAFU3GVR8VSABI13ZEC2CPVQBZ9S83N3TWHM",
    "assetId": "SOLANA.SKULL-SKU11EYPAFU3GVR8VSABI13ZEC2CPVQBZ9S83N3TWHM",
    "assetAddress": "SKu11EypaFU3gvr8VSAbi13zEC2CPvqbz9s83N3tWHM",
    "assetName": "SKULL",
    "decimals": 0,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.skull-sku11eypafu3gvr8vsabi13zec2cpvqbz9s83n3twhm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SKULL-3X36YHQ35MJNT2JJWODEFDFV2MFPB99RC53YUYNRPUMP",
    "assetId": "SOLANA.SKULL-3X36YHQ35MJNT2JJWODEFDFV2MFPB99RC53YUYNRPUMP",
    "assetAddress": "3X36yhq35MJnt2JjwodeFDfv2MFPb99RC53yUyNrpump",
    "assetName": "SKULL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.skull-3x36yhq35mjnt2jjwodefdfv2mfpb99rc53yuynrpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SIZE-HQLRJRU6PD6GFGNQ7TWSSGQRUPHF8UZNEY9T4YCSZZUQ",
    "assetId": "SOLANA.SIZE-HQLRJRU6PD6GFGNQ7TWSSGQRUPHF8UZNEY9T4YCSZZUQ",
    "assetAddress": "HqLRjru6pD6GFGnQ7TwSSGQRuPhF8UZNey9T4yCsZzuq",
    "assetName": "SIZE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.size-hqlrjru6pd6gfgnq7twssgqruphf8uzney9t4ycszzuq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SWIFT-7ACRQSHYNAULRWQZD7TQWPVUQV4PNPERALOAFXFSZVQA",
    "assetId": "SOLANA.SWIFT-7ACRQSHYNAULRWQZD7TQWPVUQV4PNPERALOAFXFSZVQA",
    "assetAddress": "7ACrQshyNAULRWqZD7tQWpvUqV4pnpeRALoafXfsZVQa",
    "assetName": "SWIFT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.swift-7acrqshynaulrwqzd7tqwpvuqv4pnperaloafxfszvqa.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DEO-DEOP2SWMNA9D4SGCQKR82J4RYYENHDJCTCWYZEHKWFAF",
    "assetId": "SOLANA.DEO-DEOP2SWMNA9D4SGCQKR82J4RYYENHDJCTCWYZEHKWFAF",
    "assetAddress": "DeoP2swMNa9d4SGcQkR82j4RYYeNhDjcTCwyzEhKwfAf",
    "assetName": "DEO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.deo-deop2swmna9d4sgcqkr82j4ryyenhdjctcwyzehkwfaf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KIRA-KIRAZUMSNZGFVFHRDVNJ6HXHFAPFTTUK8IOY98CBH6G",
    "assetId": "SOLANA.KIRA-KIRAZUMSNZGFVFHRDVNJ6HXHFAPFTTUK8IOY98CBH6G",
    "assetAddress": "kiraZUmSnzgfVfhrdvNj6hxHFaPFTTUk8ioY98cbh6G",
    "assetName": "KIRA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.kira-kirazumsnzgfvfhrdvnj6hxhfapfttuk8ioy98cbh6g.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ANON-A53BZB7297SXDF6MGUQQ8KZQJVYT8PUEHW5M1I8PD6HF",
    "assetId": "SOLANA.ANON-A53BZB7297SXDF6MGUQQ8KZQJVYT8PUEHW5M1I8PD6HF",
    "assetAddress": "A53BzB7297SXdF6mguQQ8kzqjVYt8pUeHW5m1i8pD6hf",
    "assetName": "ANON",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.anon-a53bzb7297sxdf6mguqq8kzqjvyt8puehw5m1i8pd6hf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ANON-8VJ51BDE3XORQ1ZB7FEA8CSHDM4KW77XCFICGBNL2QBT",
    "assetId": "SOLANA.ANON-8VJ51BDE3XORQ1ZB7FEA8CSHDM4KW77XCFICGBNL2QBT",
    "assetAddress": "8VJ51bdE3xorQ1zB7FEa8CsHdM4kw77xCFiCgbnL2qbT",
    "assetName": "ANON",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.anon-8vj51bde3xorq1zb7fea8cshdm4kw77xcficgbnl2qbt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HXRO-HXHWKVPK5NS4LTG5NIJ2G671CKXFRKPK8VY271UB4UEK",
    "assetId": "SOLANA.HXRO-HXHWKVPK5NS4LTG5NIJ2G671CKXFRKPK8VY271UB4UEK",
    "assetAddress": "HxhWkVpk5NS4Ltg5nij2G671CKXFRKPK8vy271Ub4uEK",
    "assetName": "HXRO",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hxro-hxhwkvpk5ns4ltg5nij2g671ckxfrkpk8vy271ub4uek.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BTL-AYZPYGOHJK6LYM8O1V6PNR3ZINHURZSHD6TRDVDUBKK",
    "assetId": "SOLANA.BTL-AYZPYGOHJK6LYM8O1V6PNR3ZINHURZSHD6TRDVDUBKK",
    "assetAddress": "aYZPYgohjK6LYM8o1v6pnr3ZinhuRzSHd6TRDVDUBkK",
    "assetName": "BTL",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.btl-ayzpygohjk6lym8o1v6pnr3zinhurzshd6trdvdubkk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SCOT-EOJEYPPNNPYCP1ZOPNWQ6CXWA7MSYPR9T1WXPFYJEZEY",
    "assetId": "SOLANA.SCOT-EOJEYPPNNPYCP1ZOPNWQ6CXWA7MSYPR9T1WXPFYJEZEY",
    "assetAddress": "EoJEyppNNpycP1ZoPnWq6cxwA7mSYpr9T1WXPFyjEzEy",
    "assetName": "SCOT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.scot-eojeyppnnpycp1zopnwq6cxwa7msypr9t1wxpfyjezey.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CLOUD-CLOUDKC4ANE7HEQCPPE3YHNZNRXHMIMJ4MYAUQYHFZAU",
    "assetId": "SOLANA.CLOUD-CLOUDKC4ANE7HEQCPPE3YHNZNRXHMIMJ4MYAUQYHFZAU",
    "assetAddress": "CLoUDKc4Ane7HeQcPpE3YHnznRxhMimJ4MyaUqyHFzAu",
    "assetName": "CLOUD",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cloud-cloudkc4ane7heqcppe3yhnznrxhmimj4myauqyhfzau.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WAG-5TN42N9VMI6UBP67UY4NNMM5DMZYN8AS8GEB3BEDHR6E",
    "assetId": "SOLANA.WAG-5TN42N9VMI6UBP67UY4NNMM5DMZYN8AS8GEB3BEDHR6E",
    "assetAddress": "5tN42n9vMi6ubp67Uy4NnmM5DMZYN8aS8GeB3bEDHr6E",
    "assetName": "WAG",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wag-5tn42n9vmi6ubp67uy4nnmm5dmzyn8as8geb3bedhr6e.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.COZY-COZYLXNAOJVQ3KB5DCJDU7MOZIBPWBWGDVC4DKMXNQA",
    "assetId": "SOLANA.COZY-COZYLXNAOJVQ3KB5DCJDU7MOZIBPWBWGDVC4DKMXNQA",
    "assetAddress": "cozyLxNaoJvQ3KB5dCJdu7MoZiBpwBWGdvc4dkMXnqA",
    "assetName": "COZY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cozy-cozylxnaojvq3kb5dcjdu7mozibpwbwgdvc4dkmxnqa.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MEDIA-BNT4UHSSTQ1BEFADV3CQ4WQAVFWB392PJAAXTBPNEWXU",
    "assetId": "SOLANA.MEDIA-BNT4UHSSTQ1BEFADV3CQ4WQAVFWB392PJAAXTBPNEWXU",
    "assetAddress": "BNT4uhSStq1beFADv3cq4wQAVfWB392PjAaxTBpNeWxu",
    "assetName": "MEDIA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.media-bnt4uhsstq1befadv3cq4wqavfwb392pjaaxtbpnewxu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MEDIA-ETATLMCMSOIEEKFNRHKJ2KYY3MOABHU6NQVPSFIJ5TDS",
    "assetId": "SOLANA.MEDIA-ETATLMCMSOIEEKFNRHKJ2KYY3MOABHU6NQVPSFIJ5TDS",
    "assetAddress": "ETAtLmCmsoiEEKfNrHKJ2kYy3MoABhU6NQvpSfij5tDs",
    "assetName": "MEDIA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.media-etatlmcmsoieekfnrhkj2kyy3moabhu6nqvpsfij5tds.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WSB-7ZBBQAPGGOKVQCK74YUA8QGWEKEJAZXUPB5M3KKVVHYF",
    "assetId": "SOLANA.WSB-7ZBBQAPGGOKVQCK74YUA8QGWEKEJAZXUPB5M3KKVVHYF",
    "assetAddress": "7zBbQAPGgoKvqcK74Yua8qGwEkEjAZxUPb5m3kKvvHyF",
    "assetName": "WSB",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wsb-7zbbqapggokvqck74yua8qgwekejazxupb5m3kkvvhyf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WSB-AKVT31H8VGJI5WF4NVBQ1QMBV5WBOE8JDSODTKDHQWEW",
    "assetId": "SOLANA.WSB-AKVT31H8VGJI5WF4NVBQ1QMBV5WBOE8JDSODTKDHQWEW",
    "assetAddress": "AkVt31h8vgji5wF4nVbq1QmBV5wBoe8JdSoDTkDhQwEw",
    "assetName": "WSB",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wsb-akvt31h8vgji5wf4nvbq1qmbv5wboe8jdsodtkdhqwew.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHILI-GPYZPHUFFGVN4YWWIXT6TYUTDG49GFMDFFI2INITMCKH",
    "assetId": "SOLANA.CHILI-GPYZPHUFFGVN4YWWIXT6TYUTDG49GFMDFFI2INITMCKH",
    "assetAddress": "GPyzPHuFFGvN4yWWixt6TYUtDG49gfMdFFi2iniTmCkh",
    "assetName": "CHILI",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chili-gpyzphuffgvn4ywwixt6tyutdg49gfmdffi2initmckh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SLB-2URFEWRBQLEKPLMF8MOHFZGDCFQMRKQEEZMHQVMUBVY7",
    "assetId": "SOLANA.SLB-2URFEWRBQLEKPLMF8MOHFZGDCFQMRKQEEZMHQVMUBVY7",
    "assetAddress": "2uRFEWRBQLEKpLmF8mohFZGDcFQmrkQEEZmHQvMUBvY7",
    "assetName": "SLB",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.slb-2urfewrbqlekplmf8mohfzgdcfqmrkqeezmhqvmubvy7.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WSBS-5RDHUIWLCHKKFUSXEU9KEDUTRFXSDWV8ETOOPNZMWVM7",
    "assetId": "SOLANA.WSBS-5RDHUIWLCHKKFUSXEU9KEDUTRFXSDWV8ETOOPNZMWVM7",
    "assetAddress": "5RDHUiwLChkKfusxeu9kEDUtRfxsDWV8etoopnZMwVM7",
    "assetName": "WSBS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wsbs-5rdhuiwlchkkfusxeu9kedutrfxsdwv8etoopnzmwvm7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MEREDITH-7Y3MLY8VAI6UMT4IVFGM1GN5GRPQ4AMQTMJGM6OLUOEM",
    "assetId": "SOLANA.MEREDITH-7Y3MLY8VAI6UMT4IVFGM1GN5GRPQ4AMQTMJGM6OLUOEM",
    "assetAddress": "7y3mLy8vai6uMt4iVfgm1gN5GRpQ4aMqtMJgM6oLuoeM",
    "assetName": "MEREDITH",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.meredith-7y3mly8vai6umt4ivfgm1gn5grpq4amqtmjgm6oluoem.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SLC-METAMTMXWDB8GYZYCPFXXFMZZW4RUSXX58PNSDG7ZJL",
    "assetId": "SOLANA.SLC-METAMTMXWDB8GYZYCPFXXFMZZW4RUSXX58PNSDG7ZJL",
    "assetAddress": "METAmTMXwdb8gYzyCPfXXFmZZw4rUsXX58PNsDg7zjL",
    "assetName": "SLC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.slc-metamtmxwdb8gyzycpfxxfmzzw4rusxx58pnsdg7zjl.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GOFX-GFX1ZJR2P15TMRSWOW6FJYDYCEKOFB4P4GJCPLBJAXHD",
    "assetId": "SOLANA.GOFX-GFX1ZJR2P15TMRSWOW6FJYDYCEKOFB4P4GJCPLBJAXHD",
    "assetAddress": "GFX1ZjR2P15tmrSwow6FjyDYcEkoFb4p4gJCpLBjaxHD",
    "assetName": "GOFX",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gofx-gfx1zjr2p15tmrswow6fjydycekofb4p4gjcplbjaxhd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$TOAD-FVIMP5PHQH2BX81S7YYN1YXJJ3BRDDFBNCMCBTH8FCZE",
    "assetId": "SOLANA.$TOAD-FVIMP5PHQH2BX81S7YYN1YXJJ3BRDDFBNCMCBTH8FCZE",
    "assetAddress": "FViMp5phQH2bX81S7Yyn1yXjj3BRddFBNcMCbTH8FCze",
    "assetName": "$TOAD",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.toad-fvimp5phqh2bx81s7yyn1yxjj3brddfbncmcbth8fcze.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CANDLE-6IFUKWGDKSVVMNSYJUGYNSU168XSTNI8XJKTF7QRPPAU",
    "assetId": "SOLANA.CANDLE-6IFUKWGDKSVVMNSYJUGYNSU168XSTNI8XJKTF7QRPPAU",
    "assetAddress": "6iFUKWGDksVvmnSYJUGYnsu168xstni8xJkTF7QrpPAu",
    "assetName": "CANDLE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.candle-6ifukwgdksvvmnsyjugynsu168xstni8xjktf7qrppau.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LIQ-4WJPQJ6PRKC4DHHYGHWJZGBVP78DKBZA2U3KHOFNBUHJ",
    "assetId": "SOLANA.LIQ-4WJPQJ6PRKC4DHHYGHWJZGBVP78DKBZA2U3KHOFNBUHJ",
    "assetAddress": "4wjPQJ6PrkC4dHhYghwJzGBVP78DkBzA2U3kHoFNBuhj",
    "assetName": "LIQ",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.liq-4wjpqj6prkc4dhhyghwjzgbvp78dkbza2u3khofnbuhj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SHROOM-XYZR4S6H724BUQ6Q7MTQWXUNHI8LM5FIKKUQ38H8M1P",
    "assetId": "SOLANA.SHROOM-XYZR4S6H724BUQ6Q7MTQWXUNHI8LM5FIKKUQ38H8M1P",
    "assetAddress": "xyzR4s6H724bUq6q7MTqWxUnhi8LM5fiKKUq38h8M1P",
    "assetName": "SHROOM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.shroom-xyzr4s6h724buq6q7mtqwxunhi8lm5fikkuq38h8m1p.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WATT-GJDREVE7IUG4HYESSWEGYFZGEKWUFHEWGJQAAA1HR7PF",
    "assetId": "SOLANA.WATT-GJDREVE7IUG4HYESSWEGYFZGEKWUFHEWGJQAAA1HR7PF",
    "assetAddress": "GjdreVe7iUG4hyESSweGyFzgekWufhEwGJqAaa1hr7pf",
    "assetName": "WATT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.watt-gjdreve7iug4hyesswegyfzgekwufhewgjqaaa1hr7pf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AOC-GFKFESC5A4EKXCGWSYMNY4ZFKCDDVTB7AJCYBLFYL1QQ",
    "assetId": "SOLANA.AOC-GFKFESC5A4EKXCGWSYMNY4ZFKCDDVTB7AJCYBLFYL1QQ",
    "assetAddress": "GfkfESc5A4EkxCGWsymNY4ZfkCDdvtB7ajCybLfYL1Qq",
    "assetName": "AOC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.aoc-gfkfesc5a4ekxcgwsymny4zfkcddvtb7ajcyblfyl1qq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ELGATO-F47VVWFYULIOQSQEVAJQDY6YIHC8WVRIUCFHGCBR9XUS",
    "assetId": "SOLANA.ELGATO-F47VVWFYULIOQSQEVAJQDY6YIHC8WVRIUCFHGCBR9XUS",
    "assetAddress": "F47vvwFYuLioQsqEVAjqdY6Yihc8wVRiUcfHGcBR9XUs",
    "assetName": "ELGATO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.elgato-f47vvwfyulioqsqevajqdy6yihc8wvriucfhgcbr9xus.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CLUB-GTGMJFKTBF9JVBOGMNAIAVNHMVNF2WYGAV3ZVTK5CBHQ",
    "assetId": "SOLANA.CLUB-GTGMJFKTBF9JVBOGMNAIAVNHMVNF2WYGAV3ZVTK5CBHQ",
    "assetAddress": "GTgMjfKTBF9jVBogMnaiAVnHmvnF2wyGAv3zvtk5CBHq",
    "assetName": "CLUB",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.club-gtgmjfktbf9jvbogmnaiavnhmvnf2wygav3zvtk5cbhq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DAW-7CB67EV3JVBKAAH1TNVM8FL8WFQ96SA2TYBYEXAJQX8N",
    "assetId": "SOLANA.DAW-7CB67EV3JVBKAAH1TNVM8FL8WFQ96SA2TYBYEXAJQX8N",
    "assetAddress": "7cb67ev3jvBKaAH1tnVM8FL8WfQ96sa2TYByEXajqx8N",
    "assetName": "DAW",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.daw-7cb67ev3jvbkaah1tnvm8fl8wfq96sa2tybyexajqx8n.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DJT-HRW8MQK8N3ASKFKJGMJPY4FODWR3GKVCFKPDQNQUNUEP",
    "assetId": "SOLANA.DJT-HRW8MQK8N3ASKFKJGMJPY4FODWR3GKVCFKPDQNQUNUEP",
    "assetAddress": "HRw8mqK8N3ASKFKJGMJpy4FodwR3GKvCFKPDQNqUNuEP",
    "assetName": "DJT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.djt-hrw8mqk8n3askfkjgmjpy4fodwr3gkvcfkpdqnqunuep.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOK-88TMABF1S3A6JBLD2YLR1TGBGJZ68FZ3EDRBESB1JPPC",
    "assetId": "SOLANA.BOK-88TMABF1S3A6JBLD2YLR1TGBGJZ68FZ3EDRBESB1JPPC",
    "assetAddress": "88tmABf1s3A6jbLD2yLR1tgbGjz68fz3EDrbeSB1JPPc",
    "assetName": "BOK",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bok-88tmabf1s3a6jbld2ylr1tgbgjz68fz3edrbesb1jppc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.UNQ-UNQTEECZ5ZB4GSSVHCAWUQEONNSVEBWIKCI1V9KDUJJ",
    "assetId": "SOLANA.UNQ-UNQTEECZ5ZB4GSSVHCAWUQEONNSVEBWIKCI1V9KDUJJ",
    "assetAddress": "UNQtEecZ5Zb4gSSVHCAWUQEoNnSVEbWiKCi1v9kdUJJ",
    "assetName": "UNQ",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.unq-unqteecz5zb4gssvhcawuqeonnsvebwikci1v9kdujj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BONKFA-DOXSC4PPVHIUXCKYEKSKPXVVVSJYZIDZZJXW4XCFF2T",
    "assetId": "SOLANA.BONKFA-DOXSC4PPVHIUXCKYEKSKPXVVVSJYZIDZZJXW4XCFF2T",
    "assetAddress": "DoxsC4PpVHiUxCKYeKSkPXVVVSJYzidZZJxW4XCFF2t",
    "assetName": "BONKFA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bonkfa-doxsc4ppvhiuxckyekskpxvvvsjyzidzzjxw4xcff2t.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WALLY-FO6TFAKXJ74X6J8HATI8SXTWZHHCDGEQXVUPLP9ABVQU",
    "assetId": "SOLANA.WALLY-FO6TFAKXJ74X6J8HATI8SXTWZHHCDGEQXVUPLP9ABVQU",
    "assetAddress": "Fo6tfAkXj74X6j8hati8SxtwZHHcdGeqXVUPLP9Abvqu",
    "assetName": "WALLY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wally-fo6tfakxj74x6j8hati8sxtwzhhcdgeqxvuplp9abvqu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOC-47PSVAP1QAXYYEPHFN7PZUVR8M9GQAX8WDODZUY3FWL7",
    "assetId": "SOLANA.BOC-47PSVAP1QAXYYEPHFN7PZUVR8M9GQAX8WDODZUY3FWL7",
    "assetAddress": "47psVap1QaXYYEPhFn7pZuvR8m9GQaX8wDodZuY3fWL7",
    "assetName": "BOC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.boc-47psvap1qaxyyephfn7pzuvr8m9gqax8wdodzuy3fwl7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GPT-EYHI3QZCW1HNGSLTV1GEAEDGZJMRFCPETBJABWFGCGX5",
    "assetId": "SOLANA.GPT-EYHI3QZCW1HNGSLTV1GEAEDGZJMRFCPETBJABWFGCGX5",
    "assetAddress": "Eyhi3qZCW1hNgsLtv1geaeDgZJmRFCpEtbjABWfGcGx5",
    "assetName": "GPT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gpt-eyhi3qzcw1hngsltv1geaedgzjmrfcpetbjabwfgcgx5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DONALD-4YZ5ZBYTWNVE46AXD6HSRCCBQ4TKLYIH2XRQPYLBDT1P",
    "assetId": "SOLANA.DONALD-4YZ5ZBYTWNVE46AXD6HSRCCBQ4TKLYIH2XRQPYLBDT1P",
    "assetAddress": "4Yz5zByTwnVe46AXD6hsrccbq4TKLyih2xRqPyLBDT1P",
    "assetName": "DONALD",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.donald-4yz5zbytwnve46axd6hsrccbq4tklyih2xrqpylbdt1p.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BURRRD-F8QTCT3QNWQ24CHKSURRSELTM5K9OB8J64XAZJ3JJSMS",
    "assetId": "SOLANA.BURRRD-F8QTCT3QNWQ24CHKSURRSELTM5K9OB8J64XAZJ3JJSMS",
    "assetAddress": "F8qtcT3qnwQ24CHksuRrSELtm5k9ob8j64xAzj3JjsMs",
    "assetName": "BURRRD",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.burrrd-f8qtct3qnwq24chksurrseltm5k9ob8j64xazj3jjsms.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LSP-BAY5FMGZFWCVCZQ1YXADVF1MEACHF3MPTBLRUMBSNLN9",
    "assetId": "SOLANA.LSP-BAY5FMGZFWCVCZQ1YXADVF1MEACHF3MPTBLRUMBSNLN9",
    "assetAddress": "BAy5FmGzFwcVcZq1yXaDvF1mEAChF3MPtBLrUMBsnLN9",
    "assetName": "LSP",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lsp-bay5fmgzfwcvczq1yxadvf1meachf3mptblrumbsnln9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PSY-PSYFIQQJIV41G7O5SMRZDJCU4PSPTTHNR2GTFEGHFSQ",
    "assetId": "SOLANA.PSY-PSYFIQQJIV41G7O5SMRZDJCU4PSPTTHNR2GTFEGHFSQ",
    "assetAddress": "PsyFiqqjiv41G7o5SMRzDJCu4psptThNR2GtfeGHfSq",
    "assetName": "PSY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.psy-psyfiqqjiv41g7o5smrzdjcu4psptthnr2gtfeghfsq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.REAL-AD27OV5FVU2XZWSBVNFVB1JPCBACB5DRXRCZV9CQSVGB",
    "assetId": "SOLANA.REAL-AD27OV5FVU2XZWSBVNFVB1JPCBACB5DRXRCZV9CQSVGB",
    "assetAddress": "AD27ov5fVU2XzwsbvnFvb1JpCBaCB5dRXrczV9CqSVGb",
    "assetName": "REAL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.real-ad27ov5fvu2xzwsbvnfvb1jpcbacb5drxrczv9cqsvgb.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.YES-32H846XXTSWGUAAKHMC5B2E39N1NWJD6UTDBPPX5P4E9",
    "assetId": "SOLANA.YES-32H846XXTSWGUAAKHMC5B2E39N1NWJD6UTDBPPX5P4E9",
    "assetAddress": "32h846XXTSWGUaaKHMC5b2e39n1nwJD6UtDBppX5p4E9",
    "assetName": "YES",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.yes-32h846xxtswguaakhmc5b2e39n1nwjd6utdbppx5p4e9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.POTATO-GEYROTDKRITGUK5UMV3AMTTEHVAZLHRJMCG82ZKYSAWB",
    "assetId": "SOLANA.POTATO-GEYROTDKRITGUK5UMV3AMTTEHVAZLHRJMCG82ZKYSAWB",
    "assetAddress": "GEYrotdkRitGUK5UMv3aMttEhVAZLhRJMcG82zKYsaWB",
    "assetName": "POTATO",
    "decimals": 3,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.potato-geyrotdkritguk5umv3amttehvazlhrjmcg82zkysawb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BYAT-BYATMZ7RY2PEWXW3213SCZJYB7ZJZPR921UVCRCJYYZQ",
    "assetId": "SOLANA.BYAT-BYATMZ7RY2PEWXW3213SCZJYB7ZJZPR921UVCRCJYYZQ",
    "assetAddress": "BYATmZ7ry2pewxW3213sczJYB7ZJzPr921uvcRcJYYZQ",
    "assetName": "BYAT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.byat-byatmz7ry2pewxw3213sczjyb7zjzpr921uvcrcjyyzq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OGCINU-5DOZSGPSKVJK9U58HSMDSQ8N6ONTELVSYCOFJ42P327P",
    "assetId": "SOLANA.OGCINU-5DOZSGPSKVJK9U58HSMDSQ8N6ONTELVSYCOFJ42P327P",
    "assetAddress": "5doZSgpsKVJk9u58hsmDsq8N6oNtELvsycoFJ42P327p",
    "assetName": "OGCINU",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ogcinu-5dozsgpskvjk9u58hsmdsq8n6ontelvsycofj42p327p.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SCRAT-3GEZNP41VAAGV9YRDBIUYDA8ZKFFNC8B7DZNJGFBH4KH",
    "assetId": "SOLANA.SCRAT-3GEZNP41VAAGV9YRDBIUYDA8ZKFFNC8B7DZNJGFBH4KH",
    "assetAddress": "3GEznP41VaAGv9yRdBiuYdA8zkffNc8b7DzNJgFbH4Kh",
    "assetName": "SCRAT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.scrat-3geznp41vaagv9yrdbiuyda8zkffnc8b7dznjgfbh4kh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LIKE-3BRTIVRVSITBMCTGTQWP7HXXPSYBKJN4XLNTPSHQA3ZR",
    "assetId": "SOLANA.LIKE-3BRTIVRVSITBMCTGTQWP7HXXPSYBKJN4XLNTPSHQA3ZR",
    "assetAddress": "3bRTivrVsitbmCTGtqwp7hxXPsybkjn4XLNtPsHqa3zR",
    "assetName": "LIKE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.like-3brtivrvsitbmctgtqwp7hxxpsybkjn4xlntpshqa3zr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MAI-9MWRABUZ2X6KOTPCWICPM49WUBCRNQGTHBV9T9K7Y1O7",
    "assetId": "SOLANA.MAI-9MWRABUZ2X6KOTPCWICPM49WUBCRNQGTHBV9T9K7Y1O7",
    "assetAddress": "9mWRABuz2x6koTPCWiCPM49WUbcrNqGTHBV9T9k7y1o7",
    "assetName": "MAI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mai-9mwrabuz2x6kotpcwicpm49wubcrnqgthbv9t9k7y1o7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PRT-PRT88RKA4KG5Z7PKNEZENH4MAFTVTQDFFGPQTGRJZ44",
    "assetId": "SOLANA.PRT-PRT88RKA4KG5Z7PKNEZENH4MAFTVTQDFFGPQTGRJZ44",
    "assetAddress": "PRT88RkA4Kg5z7pKnezeNH4mafTvtQdfFgpQTGRjz44",
    "assetName": "PRT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.prt-prt88rka4kg5z7pknezenh4maftvtqdffgpqtgrjz44.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MOCHICAT-EVDQN4P1YTTD1Y3ELBDBOALWNR4AMBC9Y2XUYCRBGBUY",
    "assetId": "SOLANA.MOCHICAT-EVDQN4P1YTTD1Y3ELBDBOALWNR4AMBC9Y2XUYCRBGBUY",
    "assetAddress": "EVDQN4P1YTTD1Y3eLBDBoALWnR4aMBC9y2xUYCrbGbuy",
    "assetName": "MOCHICAT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mochicat-evdqn4p1yttd1y3elbdboalwnr4ambc9y2xuycrbgbuy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MAGAIBA-A6RSPI9JMJGVKW6BATSA6MJFYLSEIZPM2FNT92COFJF4",
    "assetId": "SOLANA.MAGAIBA-A6RSPI9JMJGVKW6BATSA6MJFYLSEIZPM2FNT92COFJF4",
    "assetAddress": "A6rSPi9JmJgVkW6BatsA6MjFYLseizPM2Fnt92coFjf4",
    "assetName": "MAGAIBA",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.magaiba-a6rspi9jmjgvkw6batsa6mjfylseizpm2fnt92cofjf4.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RPEPE-8JNGEPQBBQ1KQFPVQA7ZAZYSIFTYY8TV2ZA8U6NHHSDO",
    "assetId": "SOLANA.RPEPE-8JNGEPQBBQ1KQFPVQA7ZAZYSIFTYY8TV2ZA8U6NHHSDO",
    "assetAddress": "8jnGePqBBq1KQfPvqA7zAZySiFTyy8tv2zA8u6nHhsdo",
    "assetName": "RPEPE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rpepe-8jngepqbbq1kqfpvqa7zazysiftyy8tv2za8u6nhhsdo.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DPAY-GNCA3UKJR4A1FFNZUGFEELMBAHKL6GTESC2SWPDWRMF7",
    "assetId": "SOLANA.DPAY-GNCA3UKJR4A1FFNZUGFEELMBAHKL6GTESC2SWPDWRMF7",
    "assetAddress": "Gnca3UkjR4a1FFNZuGfEELmbaHkL6GteSC2swpdWRmf7",
    "assetName": "DPAY",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dpay-gnca3ukjr4a1ffnzugfeelmbahkl6gtesc2swpdwrmf7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KNK-KNKT1RDNEXWQYP3EYGYWV5ZTAZB8CFGGAFJTV9AQ3KZ",
    "assetId": "SOLANA.KNK-KNKT1RDNEXWQYP3EYGYWV5ZTAZB8CFGGAFJTV9AQ3KZ",
    "assetAddress": "kNkT1RDnexWqYP3EYGyWv5ZtazB8CfgGAfJtv9AQ3kz",
    "assetName": "KNK",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.knk-knkt1rdnexwqyp3eygywv5ztazb8cfggafjtv9aq3kz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GOL-9SJYR4GROZEV8A9XM3YKKPGPXJYASY9AUUFZEFCYACNP",
    "assetId": "SOLANA.GOL-9SJYR4GROZEV8A9XM3YKKPGPXJYASY9AUUFZEFCYACNP",
    "assetAddress": "9sjyR4GrozeV8a9xM3ykKPGPXJYASy9AuufzefCyaCnP",
    "assetName": "GOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gol-9sjyr4grozev8a9xm3ykkpgpxjyasy9auufzefcyacnp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TIM-DWJVPQEX4FPFQ47XB7EEGPGHGWZRVIYFPOEWPNXZ2CRD",
    "assetId": "SOLANA.TIM-DWJVPQEX4FPFQ47XB7EEGPGHGWZRVIYFPOEWPNXZ2CRD",
    "assetAddress": "DWjVPqEX4fPFQ47Xb7EegpGhgWzRviYfPoEWPnxz2CRd",
    "assetName": "TIM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tim-dwjvpqex4fpfq47xb7eegpghgwzrviyfpoewpnxz2crd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SHARE-E6EG7ESJ5TFSWKBDGDRZHROTQPTV7GHJNARLZ9RBHDSG",
    "assetId": "SOLANA.SHARE-E6EG7ESJ5TFSWKBDGDRZHROTQPTV7GHJNARLZ9RBHDSG",
    "assetAddress": "E6Eg7Esj5tfSwkbDGdrzhrotqptv7ghJNarLZ9rbHDSG",
    "assetName": "SHARE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.share-e6eg7esj5tfswkbdgdrzhrotqptv7ghjnarlz9rbhdsg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.THOL-EKCW975DWDT1ROK1NVQDF4QGFAGTCQPU5TFD1DMCME9Q",
    "assetId": "SOLANA.THOL-EKCW975DWDT1ROK1NVQDF4QGFAGTCQPU5TFD1DMCME9Q",
    "assetAddress": "EKCW975DWdt1roK1NVQDf4QGfaGTcQPU5tFD1DMcMe9Q",
    "assetName": "THOL",
    "decimals": 7,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.thol-ekcw975dwdt1rok1nvqdf4qgfagtcqpu5tfd1dmcme9q.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CLAY-AYTG18TFRQS1MCQ29ZWY3ASWXWTCW1UTDZ65DL3WIBQU",
    "assetId": "SOLANA.CLAY-AYTG18TFRQS1MCQ29ZWY3ASWXWTCW1UTDZ65DL3WIBQU",
    "assetAddress": "AYtg18TFRQs1mCQ29zWY3aSwxwTcW1uTdZ65dL3WiBQu",
    "assetName": "CLAY",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.clay-aytg18tfrqs1mcq29zwy3aswxwtcw1utdz65dl3wibqu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CKC-8S9FCZ99WCR3DHPIAUFRI6BLXZSHXFCGTFGQE7UEOPVX",
    "assetId": "SOLANA.CKC-8S9FCZ99WCR3DHPIAUFRI6BLXZSHXFCGTFGQE7UEOPVX",
    "assetAddress": "8s9FCz99Wcr3dHpiauFRi6bLXzshXfcGTfgQE7UEopVx",
    "assetName": "CKC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ckc-8s9fcz99wcr3dhpiaufri6blxzshxfcgtfgqe7ueopvx.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.COL-B4CYZYVYEHGLC3W1PCDUCYKOS75G6ROPAPDPOBCFWENJ",
    "assetId": "SOLANA.COL-B4CYZYVYEHGLC3W1PCDUCYKOS75G6ROPAPDPOBCFWENJ",
    "assetAddress": "B4cYZYVYeHgLc3W1pCduCYkoS75G6roPaPdPoBCFweNJ",
    "assetName": "COL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.col-b4cyzyvyehglc3w1pcducykos75g6ropapdpobcfwenj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.USA-69KDRLYP5DTRKPHRAASZAQBWMAWZF9GUKJZFZMXZCBAS",
    "assetId": "SOLANA.USA-69KDRLYP5DTRKPHRAASZAQBWMAWZF9GUKJZFZMXZCBAS",
    "assetAddress": "69kdRLyP5DTRkpHraaSZAQbWmAwzF9guKjZfzMXzcbAs",
    "assetName": "USA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.usa-69kdrlyp5dtrkphraaszaqbwmawzf9gukjzfzmxzcbas.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ANTC-4WRST6Q2YYDP1P7BQSZG8PAAOJ8J69CJ7QY4QLN5AQ3O",
    "assetId": "SOLANA.ANTC-4WRST6Q2YYDP1P7BQSZG8PAAOJ8J69CJ7QY4QLN5AQ3O",
    "assetAddress": "4WRST6Q2yyDP1p7Bqszg8PAAoj8j69cj7QY4QLn5Aq3o",
    "assetName": "ANTC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.antc-4wrst6q2yydp1p7bqszg8paaoj8j69cj7qy4qln5aq3o.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GM-CMSRYDA4MNDYUICQ7QSESSTKADGBP26JSYCG8ZAVVOJD",
    "assetId": "SOLANA.GM-CMSRYDA4MNDYUICQ7QSESSTKADGBP26JSYCG8ZAVVOJD",
    "assetAddress": "CmSryDa4mnDYUicq7qSESsTKAdgBP26jSYcg8zavVoJd",
    "assetName": "GM",
    "decimals": 7,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gm-cmsryda4mndyuicq7qsesstkadgbp26jsycg8zavvojd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GM-3ACXNNMFDKKZJ9I35P4VDBFM74UFDT8OJKWCEVGYNWC5",
    "assetId": "SOLANA.GM-3ACXNNMFDKKZJ9I35P4VDBFM74UFDT8OJKWCEVGYNWC5",
    "assetAddress": "3acxNNmfdKKZj9i35P4VDBFm74Ufdt8ojKWceVGynwC5",
    "assetName": "GM",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gm-3acxnnmfdkkzj9i35p4vdbfm74ufdt8ojkwcevgynwc5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MDF-ALQ9KMWJFMXVBEW3VMKJJ3YPBAKUORSGGST6SVCHEE2Z",
    "assetId": "SOLANA.MDF-ALQ9KMWJFMXVBEW3VMKJJ3YPBAKUORSGGST6SVCHEE2Z",
    "assetAddress": "ALQ9KMWjFmxVbew3vMkJj3ypbAKuorSgGst6svCHEe2z",
    "assetName": "MDF",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mdf-alq9kmwjfmxvbew3vmkjj3ypbakuorsggst6svchee2z.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DOGO-5LSFPVLDKCDV2A3KIYZMG5YMJSJ2XDLYSAXVNFP1CGLT",
    "assetId": "SOLANA.DOGO-5LSFPVLDKCDV2A3KIYZMG5YMJSJ2XDLYSAXVNFP1CGLT",
    "assetAddress": "5LSFpvLDkcdV2a3Kiyzmg5YmJsj2XDLySaXvnfP1cgLT",
    "assetName": "DOGO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dogo-5lsfpvldkcdv2a3kiyzmg5ymjsj2xdlysaxvnfp1cglt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.STACKS-8NPBXTU4OTNW3XRMGY1ATM4CTB7CMPVURUCH3WQJ3FSW",
    "assetId": "SOLANA.STACKS-8NPBXTU4OTNW3XRMGY1ATM4CTB7CMPVURUCH3WQJ3FSW",
    "assetAddress": "8npbXTu4oTNw3xrMGY1aTm4CtB7cMPVuRUCh3Wqj3fSW",
    "assetName": "STACKS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.stacks-8npbxtu4otnw3xrmgy1atm4ctb7cmpvuruch3wqj3fsw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BMBO-5SM9XXCBTM9RWZA6NEGQ2CSHA87JJTBX1CU82LJGMAEG",
    "assetId": "SOLANA.BMBO-5SM9XXCBTM9RWZA6NEGQ2CSHA87JJTBX1CU82LJGMAEG",
    "assetAddress": "5sM9xxcBTM9rWza6nEgq2cShA87JjTBx1Cu82LjgmaEg",
    "assetName": "BMBO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bmbo-5sm9xxcbtm9rwza6negq2csha87jjtbx1cu82ljgmaeg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.POU-POUWAAP6BHZV1OT7T7RZRGQHTBD4NSWQRHGBB5HFDA6",
    "assetId": "SOLANA.POU-POUWAAP6BHZV1OT7T7RZRGQHTBD4NSWQRHGBB5HFDA6",
    "assetAddress": "PouWaap6bHZv1oT7T7rzRgQHTBD4nsWQRHGbB5hfDA6",
    "assetName": "POU",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pou-pouwaap6bhzv1ot7t7rzrgqhtbd4nswqrhgbb5hfda6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FUEL-FUEL3HBZJLLLJHIFH9CQZOOZTG3XQZ53DIWFPWBZNIM",
    "assetId": "SOLANA.FUEL-FUEL3HBZJLLLJHIFH9CQZOOZTG3XQZ53DIWFPWBZNIM",
    "assetAddress": "fueL3hBZjLLLJHiFH9cqZoozTG3XQZ53diwFPwbzNim",
    "assetName": "FUEL",
    "decimals": 0,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fuel-fuel3hbzjllljhifh9cqzooztg3xqz53diwfpwbznim.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.APT-APTTJYARX5YGTSJU522N4VYWG3VCVSB65EAM5GRPT5RT",
    "assetId": "SOLANA.APT-APTTJYARX5YGTSJU522N4VYWG3VCVSB65EAM5GRPT5RT",
    "assetAddress": "APTtJyaRX5yGTsJU522N4VYWg3vCvSb65eam5GrPT5Rt",
    "assetName": "APT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.apt-apttjyarx5ygtsju522n4vywg3vcvsb65eam5grpt5rt.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BANANA-GABRT1GE7Y5NBHHE95BXE2TARYSM8HMA6SYJIUQ7Q1ER",
    "assetId": "SOLANA.BANANA-GABRT1GE7Y5NBHHE95BXE2TARYSM8HMA6SYJIUQ7Q1ER",
    "assetAddress": "GAbRt1gE7Y5nBhhe95bxE2TaRySm8hMA6syjiUQ7q1Er",
    "assetName": "BANANA",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.banana-gabrt1ge7y5nbhhe95bxe2tarysm8hma6syjiuq7q1er.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GOLDY-GOLDYYYIVEXNVF9QGOK712N5ESM1CCBHEK9ANJFX47SX",
    "assetId": "SOLANA.GOLDY-GOLDYYYIVEXNVF9QGOK712N5ESM1CCBHEK9ANJFX47SX",
    "assetAddress": "GoLDYyyiVeXnVf9qgoK712N5esm1cCbHEK9aNJFx47Sx",
    "assetName": "GOLDY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.goldy-goldyyyivexnvf9qgok712n5esm1ccbhek9anjfx47sx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BAREBEARS-AENG6DACAJNPK7CVKSC6C9J5G8YFSP78ATQXEJANRNCZ",
    "assetId": "SOLANA.BAREBEARS-AENG6DACAJNPK7CVKSC6C9J5G8YFSP78ATQXEJANRNCZ",
    "assetAddress": "AeNg6DaCAjNpK7CvkSC6c9j5g8YFSp78aTQxejaNRNcz",
    "assetName": "BAREBEARS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.barebears-aeng6dacajnpk7cvksc6c9j5g8yfsp78atqxejanrncz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BAKED-CQBXK942C6GPCRWTZ2WMFP5JCQ9NQBXTB8JUEWBI7GOT",
    "assetId": "SOLANA.BAKED-CQBXK942C6GPCRWTZ2WMFP5JCQ9NQBXTB8JUEWBI7GOT",
    "assetAddress": "CQbXk942c6GPcRwtZ2WMFP5JcQ9NqbXtb8jUewBi7GoT",
    "assetName": "BAKED",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.baked-cqbxk942c6gpcrwtz2wmfp5jcq9nqbxtb8juewbi7got.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ICE-ICEX2FY2KTXJFIAAUEHLPHU7XKDLVWIYVUPP9PNPSKF",
    "assetId": "SOLANA.ICE-ICEX2FY2KTXJFIAAUEHLPHU7XKDLVWIYVUPP9PNPSKF",
    "assetAddress": "icex2Fy2KtXjfiAAUEHLPHu7XKDLvwiyVUPP9PNpSkF",
    "assetName": "ICE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ice-icex2fy2ktxjfiaauehlphu7xkdlvwiyvupp9pnpskf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CAN-FF4DN8QY8NNF88HRGMA3TKBRVZ8PTXWXZCZJB59X3SBG",
    "assetId": "SOLANA.CAN-FF4DN8QY8NNF88HRGMA3TKBRVZ8PTXWXZCZJB59X3SBG",
    "assetAddress": "FF4dN8Qy8NNF88HRgMA3TkbRVZ8PTXWXZCZJb59X3Sbg",
    "assetName": "CAN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.can-ff4dn8qy8nnf88hrgma3tkbrvz8ptxwxzczjb59x3sbg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HTO-HTOHLBJV1ERR8XP5OXYQDV2PLQHTVJXLXPKB7FSGJQD",
    "assetId": "SOLANA.HTO-HTOHLBJV1ERR8XP5OXYQDV2PLQHTVJXLXPKB7FSGJQD",
    "assetAddress": "htoHLBJV1err8xP5oxyQdV2PLQhtVjxLXpKB7FsgJQD",
    "assetName": "HTO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hto-htohlbjv1err8xp5oxyqdv2plqhtvjxlxpkb7fsgjqd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FOFAR-6HFWP6FHT1PETUSLSDQKUOVBISPBFVOA7JZQYEK1AMMG",
    "assetId": "SOLANA.FOFAR-6HFWP6FHT1PETUSLSDQKUOVBISPBFVOA7JZQYEK1AMMG",
    "assetAddress": "6Hfwp6Fht1pEtusLsDqKuovBisPbFVoA7jZQYeK1aMmG",
    "assetName": "FOFAR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fofar-6hfwp6fht1petuslsdqkuovbispbfvoa7jzqyek1ammg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BSKT-6GNCPHXTLNUD76HJQUSYPENLSZDG8RVDB1PTLM5ALSJA",
    "assetId": "SOLANA.BSKT-6GNCPHXTLNUD76HJQUSYPENLSZDG8RVDB1PTLM5ALSJA",
    "assetAddress": "6gnCPhXtLnUD76HjQuSYPENLSZdG8RvDB1pTLM5aLSJA",
    "assetName": "BSKT",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bskt-6gncphxtlnud76hjqusypenlszdg8rvdb1ptlm5alsja.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JET-JET6ZMJWKCN9TPRT2V2JFAMM5VNQFDPUBCYAKOJMGTZ",
    "assetId": "SOLANA.JET-JET6ZMJWKCN9TPRT2V2JFAMM5VNQFDPUBCYAKOJMGTZ",
    "assetAddress": "JET6zMJWkCN9tpRT2v2jfAmm5VnQFDpUBCyaKojmGtz",
    "assetName": "JET",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jet-jet6zmjwkcn9tprt2v2jfamm5vnqfdpubcyakojmgtz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WGC-4EB7REET936HX25KMBFYTYP1RWDDD9IIXEWGJROACELC",
    "assetId": "SOLANA.WGC-4EB7REET936HX25KMBFYTYP1RWDDD9IIXEWGJROACELC",
    "assetAddress": "4Eb7ReeT936hX25KMBfYtyP1RWDdd9iixEwGJroACeLC",
    "assetName": "WGC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wgc-4eb7reet936hx25kmbfytyp1rwddd9iixewgjroacelc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WGC-AFPEB1BDUOTBENOLV82XRDCNQCDAA1MQIS3YC5SMTE7A",
    "assetId": "SOLANA.WGC-AFPEB1BDUOTBENOLV82XRDCNQCDAA1MQIS3YC5SMTE7A",
    "assetAddress": "AfPeB1BDUotBeNoLv82XRDCNQcdAA1mqis3YC5SMTe7a",
    "assetName": "WGC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wgc-afpeb1bduotbenolv82xrdcnqcdaa1mqis3yc5smte7a.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PLD-2CJGFTNQJAOIU9FKVX3FNY4Z4PRZUAQFJ3PBTMK2D9UR",
    "assetId": "SOLANA.PLD-2CJGFTNQJAOIU9FKVX3FNY4Z4PRZUAQFJ3PBTMK2D9UR",
    "assetAddress": "2cJgFtnqjaoiu9fKVX3fny4Z4pRzuaqfJ3PBTMk2D9ur",
    "assetName": "PLD",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pld-2cjgftnqjaoiu9fkvx3fny4z4przuaqfj3pbtmk2d9ur.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FUTURE-FUTURETNHZFAPQ2TIZINBWLQDXMX4NWNPFTMVTF11PMY",
    "assetId": "SOLANA.FUTURE-FUTURETNHZFAPQ2TIZINBWLQDXMX4NWNPFTMVTF11PMY",
    "assetAddress": "FUTURETnhzFApq2TiZiNbWLQDXMx4nWNpFtmvTf11pMy",
    "assetName": "FUTURE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.future-futuretnhzfapq2tizinbwlqdxmx4nwnpftmvtf11pmy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DINO-6Y7LBYB3TFGBG6CSKYSSOXDTHB77AEMTRVXE8JUJRWZ7",
    "assetId": "SOLANA.DINO-6Y7LBYB3TFGBG6CSKYSSOXDTHB77AEMTRVXE8JUJRWZ7",
    "assetAddress": "6Y7LbYB3tfGBG6CSkyssoxdtHb77AEMTRVXe8JUJRwZ7",
    "assetName": "DINO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dino-6y7lbyb3tfgbg6cskyssoxdthb77aemtrvxe8jujrwz7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OPN-J1YNYKZMXBWFKPFTVZEXNHTM4AM7JNMWYDHXTXDWEMMV",
    "assetId": "SOLANA.OPN-J1YNYKZMXBWFKPFTVZEXNHTM4AM7JNMWYDHXTXDWEMMV",
    "assetAddress": "J1YnyKzmxBwFkPftvZexnHTm4Am7JnmWydhxtXdwEmMv",
    "assetName": "OPN",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.opn-j1ynykzmxbwfkpftvzexnhtm4am7jnmwydhxtxdwemmv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RIN-E5NDSKAB17DM7CSD22DVCJFRYSDLCXFCMD6Z8DDCK5WP",
    "assetId": "SOLANA.RIN-E5NDSKAB17DM7CSD22DVCJFRYSDLCXFCMD6Z8DDCK5WP",
    "assetAddress": "E5ndSkaB17Dm7CsD22dvcjfrYSDLCxFcMd6z8ddCk5wp",
    "assetName": "RIN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rin-e5ndskab17dm7csd22dvcjfrysdlcxfcmd6z8ddck5wp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.COS-3OBNTKNSF4HQ5PNH3GDPEHYCB9WTKJJZM2PCQFKGLMBU",
    "assetId": "SOLANA.COS-3OBNTKNSF4HQ5PNH3GDPEHYCB9WTKJJZM2PCQFKGLMBU",
    "assetAddress": "3obNTknsf4hq5pNh3GDPEhyCB9WTKJJzM2PcqfKgLmBu",
    "assetName": "COS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cos-3obntknsf4hq5pnh3gdpehycb9wtkjjzm2pcqfkglmbu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SB-8TWUNZMSZQWEFBDERWTF4GW13E6MUS4HSDX5MI3AQXAM",
    "assetId": "SOLANA.SB-8TWUNZMSZQWEFBDERWTF4GW13E6MUS4HSDX5MI3AQXAM",
    "assetAddress": "8twuNzMszqWeFbDErwtf4gw13E6MUS4Hsdx5mi3aqXAM",
    "assetName": "SB",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sb-8twunzmszqwefbderwtf4gw13e6mus4hsdx5mi3aqxam.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GOO-GRFKAABC518SQXMVBPAVYUZTVT3NJ4MYK7E7XU4GA5RG",
    "assetId": "SOLANA.GOO-GRFKAABC518SQXMVBPAVYUZTVT3NJ4MYK7E7XU4GA5RG",
    "assetAddress": "GRFKaABC518SqXMvBpAVYUZtVT3Nj4mYk7E7xU4gA5Rg",
    "assetName": "GOO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.goo-grfkaabc518sqxmvbpavyuztvt3nj4myk7e7xu4ga5rg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PRINT-HKYX2JVWKDJBKBSDIRAIQHQTCPQA3JD2DVRKAFHGFXXT",
    "assetId": "SOLANA.PRINT-HKYX2JVWKDJBKBSDIRAIQHQTCPQA3JD2DVRKAFHGFXXT",
    "assetAddress": "HKYX2jvwkdjbkbSdirAiQHqTCPQa3jD2DVRkAFHgFXXT",
    "assetName": "PRINT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.print-hkyx2jvwkdjbkbsdiraiqhqtcpqa3jd2dvrkafhgfxxt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHINGON-BPFMET9EFZ3EKB7G35DUWIYWX3O9NFSRANX76D1G6FNK",
    "assetId": "SOLANA.CHINGON-BPFMET9EFZ3EKB7G35DUWIYWX3O9NFSRANX76D1G6FNK",
    "assetAddress": "BpFmEt9efz3ekb7g35DuWiywX3o9nfsRANX76D1g6fNk",
    "assetName": "CHINGON",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chingon-bpfmet9efz3ekb7g35duwiywx3o9nfsranx76d1g6fnk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.POT-9IZ45N44TQUPYORYMDZXEUNQVZUKSZYHZS6ZQ7SLMADJ",
    "assetId": "SOLANA.POT-9IZ45N44TQUPYORYMDZXEUNQVZUKSZYHZS6ZQ7SLMADJ",
    "assetAddress": "9iz45n44TQUPyoRymdZXEunqvZUksZyhzS6zQ7sLMadj",
    "assetName": "POT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pot-9iz45n44tqupyorymdzxeunqvzukszyhzs6zq7slmadj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ICC-EQGG5MUKHVIWMWJWY4FI9TEENPJUX7RPHAWKQMNTAYAJ",
    "assetId": "SOLANA.ICC-EQGG5MUKHVIWMWJWY4FI9TEENPJUX7RPHAWKQMNTAYAJ",
    "assetAddress": "EQGG5muKhviWmWJwy4Fi9TeeNpJUX7RpHAwkQMnTAyAj",
    "assetName": "ICC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.icc-eqgg5mukhviwmwjwy4fi9teenpjux7rphawkqmntayaj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOBER-2ZE6HSL36E44WP168YMNXRBI1CSCFUD2BJM7NONHFSMN",
    "assetId": "SOLANA.SOBER-2ZE6HSL36E44WP168YMNXRBI1CSCFUD2BJM7NONHFSMN",
    "assetAddress": "2ZE6hSL36e44wP168YMnxrbi1CSCFuD2BJm7NoNHfsmN",
    "assetName": "SOBER",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sober-2ze6hsl36e44wp168ymnxrbi1cscfud2bjm7nonhfsmn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LOST-4RIQPY1YXQ2D1FFZGLY9EJY6YOUDZUQKMJ7JSVKR4BGU",
    "assetId": "SOLANA.LOST-4RIQPY1YXQ2D1FFZGLY9EJY6YOUDZUQKMJ7JSVKR4BGU",
    "assetAddress": "4riQPy1yXQ2d1FfzgLy9eJy6yoUdZuQkMj7JsVKr4bGu",
    "assetName": "LOST",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lost-4riqpy1yxq2d1ffzgly9ejy6youdzuqkmj7jsvkr4bgu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CSTR-G7UYEDVQFY97MZJYGEBNMMAMUVXWHFHNZOTY6ZZSPRVF",
    "assetId": "SOLANA.CSTR-G7UYEDVQFY97MZJYGEBNMMAMUVXWHFHNZOTY6ZZSPRVF",
    "assetAddress": "G7uYedVqFy97mzjygebnmmaMUVxWHFhNZotY6Zzsprvf",
    "assetName": "CSTR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cstr-g7uyedvqfy97mzjygebnmmamuvxwhfhnzoty6zzsprvf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CATO-5P2ZJQCD1WJZAVGCENJHB9ZWDU7B9XVHFHX4USIYN7JB",
    "assetId": "SOLANA.CATO-5P2ZJQCD1WJZAVGCENJHB9ZWDU7B9XVHFHX4USIYN7JB",
    "assetAddress": "5p2zjqCd1WJzAVgcEnjhb9zWDU7b9XVhFhx4usiyN7jB",
    "assetName": "CATO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cato-5p2zjqcd1wjzavgcenjhb9zwdu7b9xvhfhx4usiyn7jb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$BAYSE-9DGMYGTYENZGCHOSJWLZSUEYTYA3V9BSPKZZFRHYJKDO",
    "assetId": "SOLANA.$BAYSE-9DGMYGTYENZGCHOSJWLZSUEYTYA3V9BSPKZZFRHYJKDO",
    "assetAddress": "9DgMYGtyeNzGchoSJWLZsueyTYa3v9bSpkzzfRhYJKDo",
    "assetName": "$BAYSE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bayse-9dgmygtyenzgchosjwlzsueytya3v9bspkzzfrhyjkdo.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLCAT-E99FN4TCRB1TQPHXK1DU7PRXJI6HMZXETYPNJRO19FWZ",
    "assetId": "SOLANA.SOLCAT-E99FN4TCRB1TQPHXK1DU7PRXJI6HMZXETYPNJRO19FWZ",
    "assetAddress": "E99fN4tCRb1tQphXK1DU7prXji6hMzxETyPNJro19Fwz",
    "assetName": "SOLCAT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solcat-e99fn4tcrb1tqphxk1du7prxji6hmzxetypnjro19fwz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BABYTRUMP-6NBNHQKD2DH4JSWTLMMCP7LNSH4NH6Y2CNGDQG2NY9ZW",
    "assetId": "SOLANA.BABYTRUMP-6NBNHQKD2DH4JSWTLMMCP7LNSH4NH6Y2CNGDQG2NY9ZW",
    "assetAddress": "6NbnHQKD2dh4jswTLmMCP7LnSh4Nh6y2cNgdQg2ny9zW",
    "assetName": "BABYTRUMP",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.babytrump-6nbnhqkd2dh4jswtlmmcp7lnsh4nh6y2cngdqg2ny9zw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DRAGY-3JOKPQE4KOWVTR3PO3GR3SXZLF6VKCVJGX48G8DRX9ON",
    "assetId": "SOLANA.DRAGY-3JOKPQE4KOWVTR3PO3GR3SXZLF6VKCVJGX48G8DRX9ON",
    "assetAddress": "3JoKpqE4kowVTR3Po3gr3sxzLF6vKCvjGx48g8DRx9oN",
    "assetName": "DRAGY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dragy-3jokpqe4kowvtr3po3gr3sxzlf6vkcvjgx48g8drx9on.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MOON-CCPYXGVBSMP9VGNRLDT7KKZNZJQ7DYFJ2ZVSPWGDN62E",
    "assetId": "SOLANA.MOON-CCPYXGVBSMP9VGNRLDT7KKZNZJQ7DYFJ2ZVSPWGDN62E",
    "assetAddress": "CcPYxgVbSmP9VgNRLdT7KkznZJQ7DyFJ2ZvSPwgdN62e",
    "assetName": "MOON",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.moon-ccpyxgvbsmp9vgnrldt7kkznzjq7dyfj2zvspwgdn62e.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MOON-2KMPEJCZL8VEDZE7YPLMCS9Y3WKSAMEDXBN7XHPVSWVI",
    "assetId": "SOLANA.MOON-2KMPEJCZL8VEDZE7YPLMCS9Y3WKSAMEDXBN7XHPVSWVI",
    "assetAddress": "2kMpEJCZL8vEDZe7YPLMCS9Y3WKSAMedXBn7xHPvsWvi",
    "assetName": "MOON",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.moon-2kmpejczl8vedze7yplmcs9y3wksamedxbn7xhpvswvi.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.VINU-CGBJXXYAHEU8VSQUBPYSUFXA94B6LWXXIOZ28WRR8FS9",
    "assetId": "SOLANA.VINU-CGBJXXYAHEU8VSQUBPYSUFXA94B6LWXXIOZ28WRR8FS9",
    "assetAddress": "CgbJxXyaHeU8VsquBpySuFXA94b6LWXxioZ28wRr8fs9",
    "assetName": "VINU",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.vinu-cgbjxxyaheu8vsqubpysufxa94b6lwxxioz28wrr8fs9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WOOP-A3HYGZQE451CBESNQIENPFJ4A9MU332UI8NI6DOBVSLB",
    "assetId": "SOLANA.WOOP-A3HYGZQE451CBESNQIENPFJ4A9MU332UI8NI6DOBVSLB",
    "assetAddress": "A3HyGZqe451CBesNqieNPfJ4A9Mu332ui8ni6dobVSLB",
    "assetName": "WOOP",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.woop-a3hygzqe451cbesnqienpfj4a9mu332ui8ni6dobvslb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WEC-6Y8W5YWAUZOSTQRS4YDJUFBVKSOSFSI47PD8U4A5VRBC",
    "assetId": "SOLANA.WEC-6Y8W5YWAUZOSTQRS4YDJUFBVKSOSFSI47PD8U4A5VRBC",
    "assetAddress": "6y8W5YwAuzostqrS4YDJufBvksosfSi47Pd8U4A5vrBC",
    "assetName": "WEC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wec-6y8w5ywauzostqrs4ydjufbvksosfsi47pd8u4a5vrbc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PHAUNTEM-5VEVHPNDSMSXDW4ABPB368WLHPO32LP3FQHFJHNKSSWO",
    "assetId": "SOLANA.PHAUNTEM-5VEVHPNDSMSXDW4ABPB368WLHPO32LP3FQHFJHNKSSWO",
    "assetAddress": "5veVHPNDsmSxDW4Abpb368wLHpo32LP3fqhfJHnkSSwo",
    "assetName": "PHAUNTEM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.phauntem-5vevhpndsmsxdw4abpb368wlhpo32lp3fqhfjhnksswo.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SIUUU-SIUWWORRVNKJU3AE6ZJ6UIEFGC9JSXIGJ7YWXYSCGXT",
    "assetId": "SOLANA.SIUUU-SIUWWORRVNKJU3AE6ZJ6UIEFGC9JSXIGJ7YWXYSCGXT",
    "assetAddress": "siuwworrVnkjU3AE6Zj6uieFGC9JSXiGJ7YWxyScGxT",
    "assetName": "SIUUU",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.siuuu-siuwworrvnkju3ae6zj6uiefgc9jsxigj7ywxyscgxt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SIUUU-BQ74ODDOJCJKZ9W4QADZLFH1JVLBMXRCVDAC65P7ANEX",
    "assetId": "SOLANA.SIUUU-BQ74ODDOJCJKZ9W4QADZLFH1JVLBMXRCVDAC65P7ANEX",
    "assetAddress": "BQ74oddoJCJKz9W4QaDzLFh1JvLbmXRCvdaC65P7anex",
    "assetName": "SIUUU",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.siuuu-bq74oddojcjkz9w4qadzlfh1jvlbmxrcvdac65p7anex.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SDOGE-8YMI88Q5DTMDNTN2SPRNFKVMKSZMHULJ1E3RVDWJPA3S",
    "assetId": "SOLANA.SDOGE-8YMI88Q5DTMDNTN2SPRNFKVMKSZMHULJ1E3RVDWJPA3S",
    "assetAddress": "8ymi88q5DtmdNTn2sPRNFkvMkszMHuLJ1e3RVdWjPa3s",
    "assetName": "SDOGE",
    "decimals": 0,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sdoge-8ymi88q5dtmdntn2sprnfkvmkszmhulj1e3rvdwjpa3s.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ALIEN-4MJ6N65RD9W6SFPQ17UDWOT2H64UTZR31BIVLAKPZT6J",
    "assetId": "SOLANA.ALIEN-4MJ6N65RD9W6SFPQ17UDWOT2H64UTZR31BIVLAKPZT6J",
    "assetAddress": "4mJ6N65rD9w6sFPQ17UDWot2H64UtzR31biVLaKpZT6J",
    "assetName": "ALIEN",
    "decimals": 0,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.alien-4mj6n65rd9w6sfpq17udwot2h64utzr31bivlakpzt6j.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SBF-FKBWN4DCFQYM2PGCELFTHGHQQLUA2E2JTHMJYHZJFG4M",
    "assetId": "SOLANA.SBF-FKBWN4DCFQYM2PGCELFTHGHQQLUA2E2JTHMJYHZJFG4M",
    "assetAddress": "FkbWN4dcFQym2PgCELfThghQqLuA2e2jThMJyhZjfG4M",
    "assetName": "SBF",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sbf-fkbwn4dcfqym2pgcelfthghqqlua2e2jthmjyhzjfg4m.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KITTI-B5FVZD2RL5CTRMFSVDAFXINGBBQBXAPIRYJO8JFOSECA",
    "assetId": "SOLANA.KITTI-B5FVZD2RL5CTRMFSVDAFXINGBBQBXAPIRYJO8JFOSECA",
    "assetAddress": "B5Fvzd2RL5ctrmFsvDafXiNGbBqbxapiryJo8JfoSEcA",
    "assetName": "KITTI",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.kitti-b5fvzd2rl5ctrmfsvdafxingbbqbxapiryjo8jfoseca.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HOLY-HEZGWSXSVMQEZY7HJF7TTXZQRLIDRUYSHEYWQOUVNWQO",
    "assetId": "SOLANA.HOLY-HEZGWSXSVMQEZY7HJF7TTXZQRLIDRUYSHEYWQOUVNWQO",
    "assetAddress": "HezGWsxSVMqEZy7HJf7TtXzQRLiDruYsheYWqoUVnWQo",
    "assetName": "HOLY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.holy-hezgwsxsvmqezy7hjf7ttxzqrlidruysheywqouvnwqo.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SSU-AGKFKKGXUEP7ZXAZZA5A25BSKBZ5DDPGAFPHQYWUQNPF",
    "assetId": "SOLANA.SSU-AGKFKKGXUEP7ZXAZZA5A25BSKBZ5DDPGAFPHQYWUQNPF",
    "assetAddress": "AGkFkKgXUEP7ZXazza5a25bSKbz5dDpgafPhqywuQnpf",
    "assetName": "SSU",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ssu-agkfkkgxuep7zxazza5a25bskbz5ddpgafphqywuqnpf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.UM-DMCUFM2ZANSU7UGSDVQ23GROGMU3MEBJPGQF1GK53REN",
    "assetId": "SOLANA.UM-DMCUFM2ZANSU7UGSDVQ23GROGMU3MEBJPGQF1GK53REN",
    "assetAddress": "DMCUFm2ZAnSU7UgsdVq23gRogMU3MEBjPgQF1gK53rEn",
    "assetName": "UM",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.um-dmcufm2zansu7ugsdvq23grogmu3mebjpgqf1gk53ren.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TIME-AG9YIH1WKUNF17YUCONYUACW4LWQUGR2VWBPPHA6XZ6Q",
    "assetId": "SOLANA.TIME-AG9YIH1WKUNF17YUCONYUACW4LWQUGR2VWBPPHA6XZ6Q",
    "assetAddress": "AG9yih1Wkunf17yucoNYUacw4LwQugr2vWBPpHA6xz6q",
    "assetName": "TIME",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.time-ag9yih1wkunf17yuconyuacw4lwqugr2vwbppha6xz6q.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.USN-PUHUATMHSKAVMTWZSLADEKY2JB7CIETHJP7RHBKLJGY",
    "assetId": "SOLANA.USN-PUHUATMHSKAVMTWZSLADEKY2JB7CIETHJP7RHBKLJGY",
    "assetAddress": "PUhuAtMHsKavMTwZsLaDeKy2jb7ciETHJP7rhbKLJGY",
    "assetName": "USN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.usn-puhuatmhskavmtwzsladeky2jb7ciethjp7rhbkljgy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GRAPE-8UPJSPVJCDPUZHFR1ZRIWG5NXKWDRUEJQNE9WNBPRTYA",
    "assetId": "SOLANA.GRAPE-8UPJSPVJCDPUZHFR1ZRIWG5NXKWDRUEJQNE9WNBPRTYA",
    "assetAddress": "8upjSpvjcdpuzhfR1zriwg5NXkwDruejqNE9WNbPRtyA",
    "assetName": "GRAPE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.grape-8upjspvjcdpuzhfr1zriwg5nxkwdruejqne9wnbprtya.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ATS-HJBNXX2YMRXGFUJ6K4QEWTJATMK5KYQT1QNSCDDWYWNV",
    "assetId": "SOLANA.ATS-HJBNXX2YMRXGFUJ6K4QEWTJATMK5KYQT1QNSCDDWYWNV",
    "assetAddress": "HJbNXx2YMRxgfUJ6K4qeWtjatMK5KYQT1QnsCdDWywNv",
    "assetName": "ATS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ats-hjbnxx2ymrxgfuj6k4qewtjatmk5kyqt1qnscddwywnv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SCY-SCYFRGCW8ADIQDGCPDGJV6JP4UVVQLUPHXTDLNWU36F",
    "assetId": "SOLANA.SCY-SCYFRGCW8ADIQDGCPDGJV6JP4UVVQLUPHXTDLNWU36F",
    "assetAddress": "SCYfrGCw8aDiqdgcpdGjV6jp4UVVQLuphxTDLNWu36f",
    "assetName": "SCY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.scy-scyfrgcw8adiqdgcpdgjv6jp4uvvqluphxtdlnwu36f.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PANDA-AW8QLRHGHMCKQ7RXS5XBNCD9OE3BVOAHPNMVZ7ADGMTY",
    "assetId": "SOLANA.PANDA-AW8QLRHGHMCKQ7RXS5XBNCD9OE3BVOAHPNMVZ7ADGMTY",
    "assetAddress": "Aw8qLRHGhMcKq7rxs5XBNCd9oe3BvoAhpNMVz7AdGmty",
    "assetName": "PANDA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.panda-aw8qlrhghmckq7rxs5xbncd9oe3bvoahpnmvz7adgmty.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ZAZA-3QJZPI68A3CUVPGVUJYLWZIGKCAVBNXMC5VFNY1YPUMP",
    "assetId": "SOLANA.ZAZA-3QJZPI68A3CUVPGVUJYLWZIGKCAVBNXMC5VFNY1YPUMP",
    "assetAddress": "3QJzpi68a3CUVPGVUjYLWziGKCAvbNXmC5VFNy1ypump",
    "assetName": "ZAZA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.zaza-3qjzpi68a3cuvpgvujylwzigkcavbnxmc5vfny1ypump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AI-ACEWC77UEW2DBZME7YBSUXOXLVK4DHMNPZNEAPAU1AU6",
    "assetId": "SOLANA.AI-ACEWC77UEW2DBZME7YBSUXOXLVK4DHMNPZNEAPAU1AU6",
    "assetAddress": "ACeWC77UeW2DBZMe7YBsuXoxLvk4dHMnPzneApau1Au6",
    "assetName": "AI",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ai-acewc77uew2dbzme7ybsuxoxlvk4dhmnpzneapau1au6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AI-99OUK5YUK3JPGCPX9JONTHSMU7NPPU7W91JN4KDQ97PO",
    "assetId": "SOLANA.AI-99OUK5YUK3JPGCPX9JONTHSMU7NPPU7W91JN4KDQ97PO",
    "assetAddress": "99ouK5YUK3JPGCPX9joNtHsMU7NPpU7w91JN4kdQ97po",
    "assetName": "AI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ai-99ouk5yuk3jpgcpx9jonthsmu7nppu7w91jn4kdq97po.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EYE-G7EETAAUZMSBPKHOKZYFBAT4TD9IGDZSMFQGEYWEM8SW",
    "assetId": "SOLANA.EYE-G7EETAAUZMSBPKHOKZYFBAT4TD9IGDZSMFQGEYWEM8SW",
    "assetAddress": "G7eETAaUzmsBPKhokZyfbaT4tD9igdZSmfQGEYWem8Sw",
    "assetName": "EYE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.eye-g7eetaauzmsbpkhokzyfbat4td9igdzsmfqgeywem8sw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EYE-74EYOS32V2B6INEYGACRMZSIDPZ65Z7SXHQ7D5MSMYGF",
    "assetId": "SOLANA.EYE-74EYOS32V2B6INEYGACRMZSIDPZ65Z7SXHQ7D5MSMYGF",
    "assetAddress": "74Eyos32V2B6ineYgAcRMZsiDpz65z7sXHq7D5MSMYgF",
    "assetName": "EYE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.eye-74eyos32v2b6ineygacrmzsidpz65z7sxhq7d5msmygf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JFI-GEPFQAZKHCWE5VPXHFVIQTH5JGXOKSS51Y5Q4ZGBIMDS",
    "assetId": "SOLANA.JFI-GEPFQAZKHCWE5VPXHFVIQTH5JGXOKSS51Y5Q4ZGBIMDS",
    "assetAddress": "GePFQaZKHcWE5vpxHfviQtH5jgxokSs51Y5Q4zgBiMDs",
    "assetName": "JFI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jfi-gepfqazkhcwe5vpxhfviqth5jgxokss51y5q4zgbimds.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KIT-AUGDT7WJBIFF9VZPDE7BJU6HLROCYH4SUHYC7YHHEECW",
    "assetId": "SOLANA.KIT-AUGDT7WJBIFF9VZPDE7BJU6HLROCYH4SUHYC7YHHEECW",
    "assetAddress": "AUgdt7wjBifF9vZpde7BjU6HLroCYh4SUHYc7yhheECW",
    "assetName": "KIT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.kit-augdt7wjbiff9vzpde7bju6hlrocyh4suhyc7yhheecw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SSE-CAA3J9OD6NDN5AERMWZ6FCR78TAVXV9KUMOIHWVSBXSB",
    "assetId": "SOLANA.SSE-CAA3J9OD6NDN5AERMWZ6FCR78TAVXV9KUMOIHWVSBXSB",
    "assetAddress": "CAa3j9oD6nDn5AeRmwZ6fcR78TAVXv9kumoihWvSbXsB",
    "assetName": "SSE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sse-caa3j9od6ndn5aermwz6fcr78tavxv9kumoihwvsbxsb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BAG-D8R8XTUCRUHLHEWEGXSWC3G92RHASFICV3YA7B2XWCLV",
    "assetId": "SOLANA.BAG-D8R8XTUCRUHLHEWEGXSWC3G92RHASFICV3YA7B2XWCLV",
    "assetAddress": "D8r8XTuCrUhLheWeGXSwC3G92RhASficV3YA7B2XWcLv",
    "assetName": "BAG",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bag-d8r8xtucruhlhewegxswc3g92rhasficv3ya7b2xwclv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BAG-DNPY7ZKE1LVHYTC1HMTB8OPJ6G9RGT2ORVN8Z7Y35WRJ",
    "assetId": "SOLANA.BAG-DNPY7ZKE1LVHYTC1HMTB8OPJ6G9RGT2ORVN8Z7Y35WRJ",
    "assetAddress": "Dnpy7ZkE1LvhyTC1hmTb8opJ6g9rgt2oRvN8z7y35WRJ",
    "assetName": "BAG",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bag-dnpy7zke1lvhytc1hmtb8opj6g9rgt2orvn8z7y35wrj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LOWQ-7UAZN8R4MBFG1FOQYL1169LZD6CSWXYSLQFXYHZSINQG",
    "assetId": "SOLANA.LOWQ-7UAZN8R4MBFG1FOQYL1169LZD6CSWXYSLQFXYHZSINQG",
    "assetAddress": "7UAzn8R4mBfG1foqyL1169Lzd6cSWXYSLQFXYHzsiNQG",
    "assetName": "LOWQ",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lowq-7uazn8r4mbfg1foqyl1169lzd6cswxyslqfxyhzsinqg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.USH-9ILH8T7ZOWHY7SBMJ1WK9ENBWDS1NL8N9WAXAERITTA6",
    "assetId": "SOLANA.USH-9ILH8T7ZOWHY7SBMJ1WK9ENBWDS1NL8N9WAXAERITTA6",
    "assetAddress": "9iLH8T7zoWhY7sBmj1WK9ENbWdS1nL8n9wAxaeRitTa6",
    "assetName": "USH",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ush-9ilh8t7zowhy7sbmj1wk9enbwds1nl8n9waxaeritta6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CORN-6DSQVXG9WLTWGZ6LACQXN757QDHE1SCQKUFOJWMXWTOK",
    "assetId": "SOLANA.CORN-6DSQVXG9WLTWGZ6LACQXN757QDHE1SCQKUFOJWMXWTOK",
    "assetAddress": "6DSqVXg9WLTWgz6LACqxN757QdHe1sCqkUfojWmxWtok",
    "assetName": "CORN",
    "decimals": 7,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.corn-6dsqvxg9wltwgz6lacqxn757qdhe1scqkufojwmxwtok.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SNTR-SENBBKVCM7HOMNF5RX9ZQPF1GFE935HNBU4UVZY1Y6M",
    "assetId": "SOLANA.SNTR-SENBBKVCM7HOMNF5RX9ZQPF1GFE935HNBU4UVZY1Y6M",
    "assetAddress": "SENBBKVCM7homnf5RX9zqpf1GFe935hnbU4uVzY1Y6M",
    "assetName": "SNTR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sntr-senbbkvcm7homnf5rx9zqpf1gfe935hnbu4uvzy1y6m.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DYOR-DIDJVEEFLK31YEJTKXF6CFNI6RCSUJPS6QHNTVZHAPNU",
    "assetId": "SOLANA.DYOR-DIDJVEEFLK31YEJTKXF6CFNI6RCSUJPS6QHNTVZHAPNU",
    "assetAddress": "DidjvEEFLk31yEjTkxf6CfNi6RcsUjPS6qHNTVzhApNU",
    "assetName": "DYOR",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dyor-didjveeflk31yejtkxf6cfni6rcsujps6qhntvzhapnu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SMURF-EARKN8UVF8YLFPF2ECDKCVDAPYPQUJZKXXACNYXXC2P7",
    "assetId": "SOLANA.SMURF-EARKN8UVF8YLFPF2ECDKCVDAPYPQUJZKXXACNYXXC2P7",
    "assetAddress": "EArkn8uVf8YLfpF2eCdkCvDaPYpQuJzKXxaCnyxXc2P7",
    "assetName": "SMURF",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.smurf-earkn8uvf8ylfpf2ecdkcvdapypqujzkxxacnyxxc2p7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.UXP-UXPHBOR3QG4UCIGNJFV7MQHHYFQKN68G45GOYVAEL2M",
    "assetId": "SOLANA.UXP-UXPHBOR3QG4UCIGNJFV7MQHHYFQKN68G45GOYVAEL2M",
    "assetAddress": "UXPhBoR3qG4UCiGNJfV7MqhHyFqKN68g45GoYvAeL2M",
    "assetName": "UXP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.uxp-uxphbor3qg4ucignjfv7mqhhyfqkn68g45goyvael2m.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FLOOF-3JZDRXXKXWKBK82U2ECWASZLCKOZS1LQTG87HBEAMBJW",
    "assetId": "SOLANA.FLOOF-3JZDRXXKXWKBK82U2ECWASZLCKOZS1LQTG87HBEAMBJW",
    "assetAddress": "3jzdrXXKxwkBk82u2eCWASZLCKoZs1LQTg87HBEAmBJw",
    "assetName": "FLOOF",
    "decimals": 1,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.floof-3jzdrxxkxwkbk82u2ecwaszlckozs1lqtg87hbeambjw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CZOL-AUWUGNCH1TFC5SCRHLNQNHJFCRBHRSQ7YRKFUE7YSMGS",
    "assetId": "SOLANA.CZOL-AUWUGNCH1TFC5SCRHLNQNHJFCRBHRSQ7YRKFUE7YSMGS",
    "assetAddress": "AUwugnCh1tFc5scRHLNqnHjfcRbHRsq7yrKFUe7Ysmgs",
    "assetName": "CZOL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.czol-auwugnch1tfc5scrhlnqnhjfcrbhrsq7yrkfue7ysmgs.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CARL-CARL1SLWHAK4EAF633JGEGYGJ7UDJMTA9XGHFMGPUD5E",
    "assetId": "SOLANA.CARL-CARL1SLWHAK4EAF633JGEGYGJ7UDJMTA9XGHFMGPUD5E",
    "assetAddress": "CARL1SLwhaK4eaF633jgEgYgJ7UDJmTa9XGHfMgPud5e",
    "assetName": "CARL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.carl-carl1slwhak4eaf633jgegygj7udjmta9xghfmgpud5e.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WIFS-6VUQSEPJHPH67DB6P7KOJ1WQSQP1UTOVBKWXSRC1DKAA",
    "assetId": "SOLANA.WIFS-6VUQSEPJHPH67DB6P7KOJ1WQSQP1UTOVBKWXSRC1DKAA",
    "assetAddress": "6vUQsePjhpH67Db6p7Koj1wQsQP1UtovBkWXSrC1DkaA",
    "assetName": "WIFS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wifs-6vuqsepjhph67db6p7koj1wqsqp1utovbkwxsrc1dkaa.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOUL-J4YWFDM8H7HJWKZCAEQUJHKDRFCNRVIVNHMVFNDAOLNQ",
    "assetId": "SOLANA.SOUL-J4YWFDM8H7HJWKZCAEQUJHKDRFCNRVIVNHMVFNDAOLNQ",
    "assetAddress": "J4ywFdm8H7hjwKzCaEQujhkDRfCnRviVnHMvFNDAoLNQ",
    "assetName": "SOUL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.soul-j4ywfdm8h7hjwkzcaequjhkdrfcnrvivnhmvfndaolnq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOUL-F6WEWMUC1VWDL4U38RO9JKXHEMJP9BONDWMF5O5TVTJF",
    "assetId": "SOLANA.SOUL-F6WEWMUC1VWDL4U38RO9JKXHEMJP9BONDWMF5O5TVTJF",
    "assetAddress": "F6weWmuc1vwdL4u38Ro9jKXHEMjP9BoNdWMF5o5TvtJf",
    "assetName": "SOUL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.soul-f6wewmuc1vwdl4u38ro9jkxhemjp9bondwmf5o5tvtjf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KORRA-T5CSTUSZZUAQXQKZQVHIEFG4HZ4XC23Z9DU1CHP8GES",
    "assetId": "SOLANA.KORRA-T5CSTUSZZUAQXQKZQVHIEFG4HZ4XC23Z9DU1CHP8GES",
    "assetAddress": "t5cSTUSZzUAQXQKzQvhieFG4Hz4xC23z9du1Chp8gES",
    "assetName": "KORRA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.korra-t5cstuszzuaqxqkzqvhiefg4hz4xc23z9du1chp8ges.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ATLAS-ATLASXMBPQXBUYBXPSV97USA3FPQYEQZQBUHGIFCUSXX",
    "assetId": "SOLANA.ATLAS-ATLASXMBPQXBUYBXPSV97USA3FPQYEQZQBUHGIFCUSXX",
    "assetAddress": "ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx",
    "assetName": "ATLAS",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.atlas-atlasxmbpqxbuybxpsv97usa3fpqyeqzqbuhgifcusxx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GEN-1F1SRPIDPKBQJMCNHAU8VJPMKTLVK3ZJKYXVWEY8XPC",
    "assetId": "SOLANA.GEN-1F1SRPIDPKBQJMCNHAU8VJPMKTLVK3ZJKYXVWEY8XPC",
    "assetAddress": "1F1sRpidpKBQjmCnHAu8vJpmktLVK3ZjKyxVwEy8XpC",
    "assetName": "GEN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gen-1f1srpidpkbqjmcnhau8vjpmktlvk3zjkyxvwey8xpc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LFG-LFG1EZANTSY2LPX8JRZ2QA31PPEHPWN9MSFDZZW4T9Q",
    "assetId": "SOLANA.LFG-LFG1EZANTSY2LPX8JRZ2QA31PPEHPWN9MSFDZZW4T9Q",
    "assetAddress": "LFG1ezantSY2LPX8jRz2qa31pPEhpwN9msFDzZw4T9Q",
    "assetName": "LFG",
    "decimals": 7,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lfg-lfg1ezantsy2lpx8jrz2qa31ppehpwn9msfdzzw4t9q.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHUMP-HJ8WWPSHETMKWUOFKVEUHAZDQQUTGQDS7JVR37RXGNFS",
    "assetId": "SOLANA.CHUMP-HJ8WWPSHETMKWUOFKVEUHAZDQQUTGQDS7JVR37RXGNFS",
    "assetAddress": "HJ8WWpsheTMKwuoFkvEuhAzdqqUTgqdS7JVR37rxgnFS",
    "assetName": "CHUMP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chump-hj8wwpshetmkwuofkveuhazdqqutgqds7jvr37rxgnfs.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOJI-2OMYG3APHJFJXG1PRYLWVDRQAYEXUS8N4CJLXJ64GKLQ",
    "assetId": "SOLANA.BOJI-2OMYG3APHJFJXG1PRYLWVDRQAYEXUS8N4CJLXJ64GKLQ",
    "assetAddress": "2oMYg3aPHjFjxg1PRYLwvdRQayexUS8N4CjLXJ64GkLq",
    "assetName": "BOJI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.boji-2omyg3aphjfjxg1prylwvdrqayexus8n4cjlxj64gklq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MBC-EE1PKGTQMP5XJYQS76HMRM2C2YKQEDC9TK5MQBIGFIGT",
    "assetId": "SOLANA.MBC-EE1PKGTQMP5XJYQS76HMRM2C2YKQEDC9TK5MQBIGFIGT",
    "assetAddress": "Ee1pKgTQmP5xjYQs76HmRM2c2YkqEdc9tk5mQbiGFigT",
    "assetName": "MBC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mbc-ee1pkgtqmp5xjyqs76hmrm2c2ykqedc9tk5mqbigfigt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NIRV-NRVWHJBQIUPYTFDT5ZRBVJAJZFQHABUNTC7SNVVQRFA",
    "assetId": "SOLANA.NIRV-NRVWHJBQIUPYTFDT5ZRBVJAJZFQHABUNTC7SNVVQRFA",
    "assetAddress": "NRVwhjBQiUPYtfDT5zRBVJajzFQHaBUNtC7SNVvqRFa",
    "assetName": "NIRV",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nirv-nrvwhjbqiupytfdt5zrbvjajzfqhabuntc7snvvqrfa.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MDS-9TVJNZPF3X8DHSFVQYWOCGPHJXTGYH1PDCFN5QMSHW5T",
    "assetId": "SOLANA.MDS-9TVJNZPF3X8DHSFVQYWOCGPHJXTGYH1PDCFN5QMSHW5T",
    "assetAddress": "9TVjnzpF3X8DHsfVqYWoCGphJxtGYh1PDCFN5QmsHW5t",
    "assetName": "MDS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mds-9tvjnzpf3x8dhsfvqywocgphjxtgyh1pdcfn5qmshw5t.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.YIKES-CE3DRAEPI2PRCSHB45I8QCAECPHACVJXBBZO2DTPFX8Z",
    "assetId": "SOLANA.YIKES-CE3DRAEPI2PRCSHB45I8QCAECPHACVJXBBZO2DTPFX8Z",
    "assetAddress": "Ce3dRaePi2PrcsHb45i8qcaeCpHacvjXbbzo2DTPfX8z",
    "assetName": "YIKES",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.yikes-ce3draepi2prcshb45i8qcaecphacvjxbbzo2dtpfx8z.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLAPE-GHVFFSZ9BCTWSEC5NUJR1MTMMJWY7TGQZ2AXE6WVFTGN",
    "assetId": "SOLANA.SOLAPE-GHVFFSZ9BCTWSEC5NUJR1MTMMJWY7TGQZ2AXE6WVFTGN",
    "assetAddress": "GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN",
    "assetName": "SOLAPE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solape-ghvffsz9bctwsec5nujr1mtmmjwy7tgqz2axe6wvftgn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TALK-3CE4PDWFDGJP2F5GN2IYXZ7CDMG7TPWUNBKHKF67ITQF",
    "assetId": "SOLANA.TALK-3CE4PDWFDGJP2F5GN2IYXZ7CDMG7TPWUNBKHKF67ITQF",
    "assetAddress": "3Ce4PdWfdGjp2F5gn2iyxz7CDMG7TpwunbKHkF67itqf",
    "assetName": "TALK",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.talk-3ce4pdwfdgjp2f5gn2iyxz7cdmg7tpwunbkhkf67itqf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LC-5HJ3FCRCXUEERALJUBLVHBNWSYS2RNZJUUS33FLH7UJP",
    "assetId": "SOLANA.LC-5HJ3FCRCXUEERALJUBLVHBNWSYS2RNZJUUS33FLH7UJP",
    "assetAddress": "5HJ3fCrCXUEEraLJUBLvhbNwSYS2RNzjuuS33FLH7UjP",
    "assetName": "LC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lc-5hj3fcrcxueeraljublvhbnwsys2rnzjuus33flh7ujp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DAWG-3DHPQXDMXOGNNNPQBMF8N4ZS4DN1WR31H7UJWQ6FEXWG",
    "assetId": "SOLANA.DAWG-3DHPQXDMXOGNNNPQBMF8N4ZS4DN1WR31H7UJWQ6FEXWG",
    "assetAddress": "3DHPqxdMXogNNnpqBMF8N4Zs4dn1WR31H7UjWq6FExwG",
    "assetName": "DAWG",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dawg-3dhpqxdmxognnnpqbmf8n4zs4dn1wr31h7ujwq6fexwg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NEKO-6WDBFQAXDVWUDJRZENNZGPZSZ1NRUVLHF9QCVMSD5DLX",
    "assetId": "SOLANA.NEKO-6WDBFQAXDVWUDJRZENNZGPZSZ1NRUVLHF9QCVMSD5DLX",
    "assetAddress": "6wdbFQAxDVwUdJrZEnnzgPzsZ1NruvLhf9qCvmSD5DLX",
    "assetName": "NEKO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.neko-6wdbfqaxdvwudjrzennzgpzsz1nruvlhf9qcvmsd5dlx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SNS-SNSNKV9ZFG5ZKWQS6X4HXVBRV6S8SQMFSGCTECDVDMD",
    "assetId": "SOLANA.SNS-SNSNKV9ZFG5ZKWQS6X4HXVBRV6S8SQMFSGCTECDVDMD",
    "assetAddress": "SNSNkV9zfG5ZKWQs6x4hxvBRV6s8SqMfSGCtECDvdMd",
    "assetName": "SNS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sns-snsnkv9zfg5zkwqs6x4hxvbrv6s8sqmfsgctecdvdmd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.QUACK-6FRKVZF72WIZ3UQRWHBQLFTNU4PS6XXYCONRW9P4CFDK",
    "assetId": "SOLANA.QUACK-6FRKVZF72WIZ3UQRWHBQLFTNU4PS6XXYCONRW9P4CFDK",
    "assetAddress": "6frkvZf72wiz3uqRWhBqLftNU4PS6XXYCoNrW9P4CFdK",
    "assetName": "QUACK",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.quack-6frkvzf72wiz3uqrwhbqlftnu4ps6xxyconrw9p4cfdk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HAPPY-ETBNEBQ97QDUYGSEBDNPIUF6E832GHV8FZSJCVBUGN1B",
    "assetId": "SOLANA.HAPPY-ETBNEBQ97QDUYGSEBDNPIUF6E832GHV8FZSJCVBUGN1B",
    "assetAddress": "ETBneBQ97qDUygsEBDnpiUF6e832GHV8FzsJCvbUgN1B",
    "assetName": "HAPPY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.happy-etbnebq97qduygsebdnpiuf6e832ghv8fzsjcvbugn1b.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.META-METADDFL6WWMWEOKTFJWCTHTBUMTARRJZJRPZUVKXHR",
    "assetId": "SOLANA.META-METADDFL6WWMWEOKTFJWCTHTBUMTARRJZJRPZUVKXHR",
    "assetAddress": "METADDFL6wWMWEoKTFJwcThTbUmtarRJZjRpzUvkxhr",
    "assetName": "META",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.meta-metaddfl6wwmweoktfjwcthtbumtarrjzjrpzuvkxhr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DOGGS-HZF4L5A6Y4Y3JPWVVSZXW51UUKI851CTRYJ2GYK4DP7G",
    "assetId": "SOLANA.DOGGS-HZF4L5A6Y4Y3JPWVVSZXW51UUKI851CTRYJ2GYK4DP7G",
    "assetAddress": "HzF4L5A6Y4y3jpwvvsZXW51uUki851ctRYJ2GYK4dp7g",
    "assetName": "DOGGS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.doggs-hzf4l5a6y4y3jpwvvszxw51uuki851ctryj2gyk4dp7g.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JEFF-FKCAFSPRX7GYSAGFOQPHNBE9MRKJRVH21COKJO6C1E2T",
    "assetId": "SOLANA.JEFF-FKCAFSPRX7GYSAGFOQPHNBE9MRKJRVH21COKJO6C1E2T",
    "assetAddress": "FkCaFsprX7gySagFoQPHNbe9MRkjrvh21cokJo6C1e2T",
    "assetName": "JEFF",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jeff-fkcafsprx7gysagfoqphnbe9mrkjrvh21cokjo6c1e2t.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MILK-MLKMUCAJ1DPBY881AFSRBWR9RUMOKIC8SWT3U1Q5NKJ",
    "assetId": "SOLANA.MILK-MLKMUCAJ1DPBY881AFSRBWR9RUMOKIC8SWT3U1Q5NKJ",
    "assetAddress": "MLKmUCaj1dpBY881aFsrBwR9RUMoKic8SWT3u1q5Nkj",
    "assetName": "MILK",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.milk-mlkmucaj1dpby881afsrbwr9rumokic8swt3u1q5nkj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MILK-CCKDRAD4XWJOOVTF2S1DUU3D4TPTMFRYH1HFRB3ZUGR2",
    "assetId": "SOLANA.MILK-CCKDRAD4XWJOOVTF2S1DUU3D4TPTMFRYH1HFRB3ZUGR2",
    "assetAddress": "CCKDRAd4Xwjoovtf2s1duu3d4TPTmFRyh1hfrb3ZUGR2",
    "assetName": "MILK",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.milk-cckdrad4xwjoovtf2s1duu3d4tptmfryh1hfrb3zugr2.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.VC-AEBRVZPFSH7KPAXPWNUQTZB9QNEPDKTK7HSSY4SNJ7BM",
    "assetId": "SOLANA.VC-AEBRVZPFSH7KPAXPWNUQTZB9QNEPDKTK7HSSY4SNJ7BM",
    "assetAddress": "AebrVZPfSH7KPAxPwnuqTZB9QNepdktk7HSSY4SNj7BM",
    "assetName": "VC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.vc-aebrvzpfsh7kpaxpwnuqtzb9qnepdktk7hssy4snj7bm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SVNN-DBM7MCJM9ZITHANZKMFF7NH4SAEZZDCF5TPEGZWTMUH4",
    "assetId": "SOLANA.SVNN-DBM7MCJM9ZITHANZKMFF7NH4SAEZZDCF5TPEGZWTMUH4",
    "assetAddress": "DbM7mcJM9zitHanzKmFf7NH4SaEZZDCf5TPEgzwTmuh4",
    "assetName": "SVNN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.svnn-dbm7mcjm9zithanzkmff7nh4saezzdcf5tpegzwtmuh4.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PUGAI-PUGAIDUAQ5HZICBHAW9QRQQ8QI4B6SH3N7PKNKHYJEX",
    "assetId": "SOLANA.PUGAI-PUGAIDUAQ5HZICBHAW9QRQQ8QI4B6SH3N7PKNKHYJEX",
    "assetAddress": "PugAiDuaQ5hzicBHAW9qrQQ8qi4B6sh3n7PknKhyjeX",
    "assetName": "PUGAI",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pugai-pugaiduaq5hzicbhaw9qrqq8qi4b6sh3n7pknkhyjex.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BILLY-3B5WUURMEI5YATD7ON46HKFEJ3PFMD7T1RKGRSN3PUMP",
    "assetId": "SOLANA.BILLY-3B5WUURMEI5YATD7ON46HKFEJ3PFMD7T1RKGRSN3PUMP",
    "assetAddress": "3B5wuUrMEi5yATD7on46hKfej3pfmd7t1RKgrsN3pump",
    "assetName": "BILLY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.billy-3b5wuurmei5yatd7on46hkfej3pfmd7t1rkgrsn3pump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KING-9NOXZPXNKYECKF3AEXQUHTDR59V5UVRRBUZ9BWFQWXEQ",
    "assetId": "SOLANA.KING-9NOXZPXNKYECKF3AEXQUHTDR59V5UVRRBUZ9BWFQWXEQ",
    "assetAddress": "9noXzpXnkyEcKF3AeXqUHTdR59V5uvrRBUZ9bwfQwxeq",
    "assetName": "KING",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.king-9noxzpxnkyeckf3aexquhtdr59v5uvrrbuz9bwfqwxeq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RBT-65NTNUJGHME4PQVKQYJYKKP1BJAKK4A8Q66SD2YBWUGF",
    "assetId": "SOLANA.RBT-65NTNUJGHME4PQVKQYJYKKP1BJAKK4A8Q66SD2YBWUGF",
    "assetAddress": "65nTNuJGHme4PQvKQyJykKp1bJAkK4A8Q66sd2yBWugf",
    "assetName": "RBT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rbt-65ntnujghme4pqvkqyjykkp1bjakk4a8q66sd2ybwugf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BLOCK-NFTUKR4U7WKXY9QLAX2TGVD9OZSWOMO4JQSJQDMB7NK",
    "assetId": "SOLANA.BLOCK-NFTUKR4U7WKXY9QLAX2TGVD9OZSWOMO4JQSJQDMB7NK",
    "assetAddress": "NFTUkR4u7wKxy9QLaX2TGvd9oZSWoMo4jqSJqdMb7Nk",
    "assetName": "BLOCK",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.block-nftukr4u7wkxy9qlax2tgvd9ozswomo4jqsjqdmb7nk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ARB-9TZZZEHSKNWFL1A3DYFJWJ36KNZJ3GZ7G4SRWP9YTEOH",
    "assetId": "SOLANA.ARB-9TZZZEHSKNWFL1A3DYFJWJ36KNZJ3GZ7G4SRWP9YTEOH",
    "assetAddress": "9tzZzEHsKnwFL1A3DyFJwj36KnZj3gZ7g4srWp9YTEoh",
    "assetName": "ARB",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.arb-9tzzzehsknwfl1a3dyfjwj36knzj3gz7g4srwp9yteoh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FRN-J5TZD1WW1V1QRGDUQHVCGQPMPBNENJZGS9LAQJXWKNDE",
    "assetId": "SOLANA.FRN-J5TZD1WW1V1QRGDUQHVCGQPMPBNENJZGS9LAQJXWKNDE",
    "assetAddress": "J5tzd1ww1V1qrgDUQHVCGqpmpbnEnjzGs9LAqJxwkNde",
    "assetName": "FRN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.frn-j5tzd1ww1v1qrgduqhvcgqpmpbnenjzgs9laqjxwknde.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BERN-CKFATSPMUF8SKIURSDXS7EK6GWB4JSD6UDBS7TWMCWXO",
    "assetId": "SOLANA.BERN-CKFATSPMUF8SKIURSDXS7EK6GWB4JSD6UDBS7TWMCWXO",
    "assetAddress": "CKfatsPMUf8SkiURsDXs7eK6GWb4Jsd6UDbs7twMCWxo",
    "assetName": "BERN",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bern-ckfatspmuf8skiursdxs7ek6gwb4jsd6udbs7twmcwxo.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SCS-SCSUPPNUSYPLBSV4DARSRYNG4ANPGAGHKHSA3GMMYJZ",
    "assetId": "SOLANA.SCS-SCSUPPNUSYPLBSV4DARSRYNG4ANPGAGHKHSA3GMMYJZ",
    "assetAddress": "SCSuPPNUSypLBsV4darsrYNg4ANPgaGhKhsA3GmMyjz",
    "assetName": "SCS",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.scs-scsuppnusyplbsv4darsryng4anpgaghkhsa3gmmyjz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CRM-CRMADAZKCWYBHUFSKYA8448VAA1QUZCETD7GNBDZQ1KS",
    "assetId": "SOLANA.CRM-CRMADAZKCWYBHUFSKYA8448VAA1QUZCETD7GNBDZQ1KS",
    "assetAddress": "CRMaDAzKCWYbhUfsKYA8448vaA1qUzCETd7gNBDzQ1ks",
    "assetName": "CRM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.crm-crmadazkcwybhufskya8448vaa1quzcetd7gnbdzq1ks.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SHDW-SHDWYBXIHQICJ6YEKG2GUR7WQKLELAMK1GHZCK9PL6Y",
    "assetId": "SOLANA.SHDW-SHDWYBXIHQICJ6YEKG2GUR7WQKLELAMK1GHZCK9PL6Y",
    "assetAddress": "SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y",
    "assetName": "SHDW",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.shdw-shdwybxihqicj6yekg2gur7wqklelamk1ghzck9pl6y.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SEND-SENDDRQTYMWAQRBROBRJ2Q53FGVUQ95CV9UPGEVPCXA",
    "assetId": "SOLANA.SEND-SENDDRQTYMWAQRBROBRJ2Q53FGVUQ95CV9UPGEVPCXA",
    "assetAddress": "SENDdRQtYMWaQrBroBrJ2Q53fgVuq95CV9UPGEvpCxa",
    "assetName": "SEND",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.send-senddrqtymwaqrbrobrj2q53fgvuq95cv9upgevpcxa.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BON-6BPFBXGPSFZG6WDRUJ7VRODQ4GY7K7TMQUNAM1BYU3PW",
    "assetId": "SOLANA.BON-6BPFBXGPSFZG6WDRUJ7VRODQ4GY7K7TMQUNAM1BYU3PW",
    "assetAddress": "6bPFbxgpsFZg6WDruj7vrodq4GY7K7TmqUnAm1byu3PW",
    "assetName": "BON",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bon-6bpfbxgpsfzg6wdruj7vrodq4gy7k7tmqunam1byu3pw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHAT-947TEOG318GUMYJVYHRANRVWPMX7FPBTDQFBOJVSKSG3",
    "assetId": "SOLANA.CHAT-947TEOG318GUMYJVYHRANRVWPMX7FPBTDQFBOJVSKSG3",
    "assetAddress": "947tEoG318GUmyjVYhraNRvWpMX7fpBTDQFBoJvSkSG3",
    "assetName": "CHAT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chat-947teog318gumyjvyhranrvwpmx7fpbtdqfbojvsksg3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.YUKI-53YANRIBNP1WZRSCIY6UPAN2VPY85WAZETADTEJHTQGN",
    "assetId": "SOLANA.YUKI-53YANRIBNP1WZRSCIY6UPAN2VPY85WAZETADTEJHTQGN",
    "assetAddress": "53yANribNp1WzRsciY6upAN2VPY85waZEtADTeJhtQGN",
    "assetName": "YUKI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.yuki-53yanribnp1wzrsciy6upan2vpy85wazetadtejhtqgn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ENG-4XQVDIPJBDRB5HUGURBZPPFMP6BCAV41N55DC7KDYW3M",
    "assetId": "SOLANA.ENG-4XQVDIPJBDRB5HUGURBZPPFMP6BCAV41N55DC7KDYW3M",
    "assetAddress": "4XQvdipJBdrb5hUgUrbZPPFmp6BCav41n55dc7KDYW3m",
    "assetName": "ENG",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.eng-4xqvdipjbdrb5hugurbzppfmp6bcav41n55dc7kdyw3m.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ENG-GTLBSMS6Z4FC9GMNCFS2ZZCKMB9VYL3KELNJVMXHURNH",
    "assetId": "SOLANA.ENG-GTLBSMS6Z4FC9GMNCFS2ZZCKMB9VYL3KELNJVMXHURNH",
    "assetAddress": "GtLBsmS6Z4FC9gmnCFS2ZzCKmb9vYL3kELnjVmxHurnh",
    "assetName": "ENG",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.eng-gtlbsms6z4fc9gmncfs2zzckmb9vyl3kelnjvmxhurnh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DUST-DUSTAWUCRTSGU8HCQRDHDCBUYHCPADMLM2VCCB8VNFNQ",
    "assetId": "SOLANA.DUST-DUSTAWUCRTSGU8HCQRDHDCBUYHCPADMLM2VCCB8VNFNQ",
    "assetAddress": "DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ",
    "assetName": "DUST",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dust-dustawucrtsgu8hcqrdhdcbuyhcpadmlm2vccb8vnfnq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CORE-2MADVG9NXGVSTJDNSCZOSSNTJODA1SSZTLRHBVRGLR5F",
    "assetId": "SOLANA.CORE-2MADVG9NXGVSTJDNSCZOSSNTJODA1SSZTLRHBVRGLR5F",
    "assetAddress": "2maDvG9nXGVstjdnsCZoSsNtjoda1SsZTLrHBVRgLR5F",
    "assetName": "CORE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.core-2madvg9nxgvstjdnsczossntjoda1ssztlrhbvrglr5f.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LST-LSTXXXNJZKDFSLR4DUKPCMCF5VYRYEQZPLZ5J4BPXFP",
    "assetId": "SOLANA.LST-LSTXXXNJZKDFSLR4DUKPCMCF5VYRYEQZPLZ5J4BPXFP",
    "assetAddress": "LSTxxxnJzKDFSLr4dUkPcmCf5VyryEqzPLz5j4bpxFp",
    "assetName": "LST",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lst-lstxxxnjzkdfslr4dukpcmcf5vyryeqzplz5j4bpxfp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RARE-HMLSPVJPQTQENARUYJOBSFGS38GNJWBUXAEQSV9SZ66K",
    "assetId": "SOLANA.RARE-HMLSPVJPQTQENARUYJOBSFGS38GNJWBUXAEQSV9SZ66K",
    "assetAddress": "HmLspvjpQtQEnArUyJoBSFGS38gNJwBuxAeqSV9SZ66K",
    "assetName": "RARE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rare-hmlspvjpqtqenaruyjobsfgs38gnjwbuxaeqsv9sz66k.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PIPI-HMTZ1SFRHGP63KGOT64IAWKXZEZ9WF3DF8R3MFYWN8MP",
    "assetId": "SOLANA.PIPI-HMTZ1SFRHGP63KGOT64IAWKXZEZ9WF3DF8R3MFYWN8MP",
    "assetAddress": "HmTZ1SFRhgp63kgoT64iAwKXZez9Wf3df8r3MFywN8mp",
    "assetName": "PIPI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pipi-hmtz1sfrhgp63kgot64iawkxzez9wf3df8r3mfywn8mp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MER-MERT85FC5BOKW3BW1EYDXONEUJNVXBIMBS6HVHEAU5K",
    "assetId": "SOLANA.MER-MERT85FC5BOKW3BW1EYDXONEUJNVXBIMBS6HVHEAU5K",
    "assetAddress": "MERt85fc5boKw3BW1eYdxonEuJNvXbiMbs6hvheau5K",
    "assetName": "MER",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mer-mert85fc5bokw3bw1eydxoneujnvxbimbs6hvheau5k.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FEED-3LDAW7ENNUZ4DJE1JCI1CDPXVXLRJ1RPIECPBCHPMGG2",
    "assetId": "SOLANA.FEED-3LDAW7ENNUZ4DJE1JCI1CDPXVXLRJ1RPIECPBCHPMGG2",
    "assetAddress": "3LDAW7enNUZ4DjE1jCi1cDpXvXLrJ1rPiECPbcHpMgG2",
    "assetName": "FEED",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.feed-3ldaw7ennuz4dje1jci1cdpxvxlrj1rpiecpbchpmgg2.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BIRB-2EBJQPYGLUEXDWWJJRLQTGPAWZB2AMJE1WTPUYKHY2UQ",
    "assetId": "SOLANA.BIRB-2EBJQPYGLUEXDWWJJRLQTGPAWZB2AMJE1WTPUYKHY2UQ",
    "assetAddress": "2EBjqPYGLUExdWwJJRLqtGPawzb2aMjE1wTpUYKhy2UQ",
    "assetName": "BIRB",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.birb-2ebjqpygluexdwwjjrlqtgpawzb2amje1wtpuykhy2uq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GEM-E7BGDTPNXUTQPNBZXKHILOWGLDDIAEUKCBYD7TSNFYWD",
    "assetId": "SOLANA.GEM-E7BGDTPNXUTQPNBZXKHILOWGLDDIAEUKCBYD7TSNFYWD",
    "assetAddress": "E7BGDtpNXUTqPNbZxKHiLowgLddiAeuKcByD7tSnfYWD",
    "assetName": "GEM",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gem-e7bgdtpnxutqpnbzxkhilowglddiaeukcbyd7tsnfywd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.IVN-IVNCRNE9BRZBC9AQF753IZIZFBSZEAVUOIKGT9YVR2A",
    "assetId": "SOLANA.IVN-IVNCRNE9BRZBC9AQF753IZIZFBSZEAVUOIKGT9YVR2A",
    "assetAddress": "iVNcrNE9BRZBC9Aqf753iZiZfbszeAVUoikgT9yvr2a",
    "assetName": "IVN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ivn-ivncrne9brzbc9aqf753izizfbszeavuoikgt9yvr2a.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.STR-9ZOQDWEBKWEI9G5ZE8BSKDMPPXGGVV1KW4LUIGDINR9M",
    "assetId": "SOLANA.STR-9ZOQDWEBKWEI9G5ZE8BSKDMPPXGGVV1KW4LUIGDINR9M",
    "assetAddress": "9zoqdwEBKWEi9G5Ze8BSkdmppxGgVv1Kw4LuigDiNr9m",
    "assetName": "STR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.str-9zoqdwebkwei9g5ze8bskdmppxggvv1kw4luigdinr9m.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.XSB-4UUGQGKD3RSEOXATXRWWRFRD21G87D5LICFKVZNNV1TT",
    "assetId": "SOLANA.XSB-4UUGQGKD3RSEOXATXRWWRFRD21G87D5LICFKVZNNV1TT",
    "assetAddress": "4UuGQgkD3rSeoXatXRWwRfRd21G87d5LiCfkVzNNv1Tt",
    "assetName": "XSB",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.xsb-4uugqgkd3rseoxatxrwwrfrd21g87d5licfkvznnv1tt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HBIT-HD8CRL1E3KNYEWVHBX7B2TSSADKQUFR52CWXXXZJYCV1",
    "assetId": "SOLANA.HBIT-HD8CRL1E3KNYEWVHBX7B2TSSADKQUFR52CWXXXZJYCV1",
    "assetAddress": "Hd8crL1e3KnYEWvHBx7B2TSsadkQuFr52CwXXxZJyCv1",
    "assetName": "HBIT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hbit-hd8crl1e3knyewvhbx7b2tssadkqufr52cwxxxzjycv1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.COC-COCVP5K8DSDYBJKRGASSG69XWFENRVVDBLLKJJKPJSO",
    "assetId": "SOLANA.COC-COCVP5K8DSDYBJKRGASSG69XWFENRVVDBLLKJJKPJSO",
    "assetAddress": "cocvP5K8DsDYbJkRGasSg69xWFenrVVdBLLKjJKPJSo",
    "assetName": "COC",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.coc-cocvp5k8dsdybjkrgassg69xwfenrvvdbllkjjkpjso.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OOGI-H7QC9APCWWGDVXGD5FJHMLTMDEGT9GFATAKFNG6SHH8A",
    "assetId": "SOLANA.OOGI-H7QC9APCWWGDVXGD5FJHMLTMDEGT9GFATAKFNG6SHH8A",
    "assetAddress": "H7Qc9APCWWGDVxGD5fJHmLTmdEgT9GFatAKFNg6sHh8A",
    "assetName": "OOGI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.oogi-h7qc9apcwwgdvxgd5fjhmltmdegt9gfatakfng6shh8a.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SWOLE-4BZXVOBQZWKOQM1DQC78R42YBY3EZAEZMMIYFDCJEU5Z",
    "assetId": "SOLANA.SWOLE-4BZXVOBQZWKOQM1DQC78R42YBY3EZAEZMMIYFDCJEU5Z",
    "assetAddress": "4BzxVoBQzwKoqm1dQc78r42Yby3EzAeZmMiYFdCjeu5Z",
    "assetName": "SWOLE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.swole-4bzxvobqzwkoqm1dqc78r42yby3ezaezmmiyfdcjeu5z.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SWOLE-SIO28IENC3IABUKJFZKIKHKNBR3XYHJZHJE34TIPYDP",
    "assetId": "SOLANA.SWOLE-SIO28IENC3IABUKJFZKIKHKNBR3XYHJZHJE34TIPYDP",
    "assetAddress": "sio28ienC3iABUKJFzkikHknbR3xyhjzhJE34tipyDP",
    "assetName": "SWOLE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.swole-sio28ienc3iabukjfzkikhknbr3xyhjzhje34tipydp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CAVE-4SZJJNABOQHBD4HNAPBVOEPEQT8MNNKFBEOAWALF1V8T",
    "assetId": "SOLANA.CAVE-4SZJJNABOQHBD4HNAPBVOEPEQT8MNNKFBEOAWALF1V8T",
    "assetAddress": "4SZjjNABoqhbd4hnapbvoEPEqT8mnNkfbEoAwALf1V8t",
    "assetName": "CAVE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cave-4szjjnaboqhbd4hnapbvoepeqt8mnnkfbeoawalf1v8t.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RUN-6F9XRIABHFWHIT6ZMMUYAQBSY6XK5VF1CHXUW5LDPRTC",
    "assetId": "SOLANA.RUN-6F9XRIABHFWHIT6ZMMUYAQBSY6XK5VF1CHXUW5LDPRTC",
    "assetAddress": "6F9XriABHfWhit6zmMUYAQBSy6XK5VF1cHXuW5LDpRtC",
    "assetName": "RUN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.run-6f9xriabhfwhit6zmmuyaqbsy6xk5vf1chxuw5ldprtc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DARK-FMQ7V2QUQXVVTAXKNGBH3MWX7S3MKT55NQ5Z673DURYS",
    "assetId": "SOLANA.DARK-FMQ7V2QUQXVVTAXKNGBH3MWX7S3MKT55NQ5Z673DURYS",
    "assetAddress": "FmQ7v2QUqXVVtAXkngBh3Mwx7s3mKT55nQ5Z673dURYS",
    "assetName": "DARK",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dark-fmq7v2quqxvvtaxkngbh3mwx7s3mkt55nq5z673durys.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SVT-SVTMPL5EQZDMB3UQK9NXAQKQ8PRGZOKQFNVJGHDWCKV",
    "assetId": "SOLANA.SVT-SVTMPL5EQZDMB3UQK9NXAQKQ8PRGZOKQFNVJGHDWCKV",
    "assetAddress": "svtMpL5eQzdmB3uqK9NXaQkq8prGZoKQFNVJghdWCkV",
    "assetName": "SVT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.svt-svtmpl5eqzdmb3uqk9nxaqkq8prgzokqfnvjghdwckv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DBC-98OUGZBMV2DNHFN3UC7OAHWVDP4CF7LS77JSBIARDJVN",
    "assetId": "SOLANA.DBC-98OUGZBMV2DNHFN3UC7OAHWVDP4CF7LS77JSBIARDJVN",
    "assetAddress": "98ouGzbMV2DnHfn3Uc7oahwvDP4Cf7Ls77JsBiARdjvn",
    "assetName": "DBC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dbc-98ougzbmv2dnhfn3uc7oahwvdp4cf7ls77jsbiardjvn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FOOD-ECK2EVV2CDECVSMVY2FXU51EU3FP4W48ZRZXUA92AAAN",
    "assetId": "SOLANA.FOOD-ECK2EVV2CDECVSMVY2FXU51EU3FP4W48ZRZXUA92AAAN",
    "assetAddress": "EcK2evV2cDECVsmvY2FxU51eu3fp4w48zrZxuA92AAAN",
    "assetName": "FOOD",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.food-eck2evv2cdecvsmvy2fxu51eu3fp4w48zrzxua92aaan.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FOOD-FOODQJAZTMZX1DKPLAIOUNNE2BDMDS5RNUPC6JSNRDG",
    "assetId": "SOLANA.FOOD-FOODQJAZTMZX1DKPLAIOUNNE2BDMDS5RNUPC6JSNRDG",
    "assetAddress": "foodQJAztMzX1DKpLaiounNe2BDMds5RNuPC6jsNrDG",
    "assetName": "FOOD",
    "decimals": 0,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.food-foodqjaztmzx1dkplaiounne2bdmds5rnupc6jsnrdg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GOD-9KT93AW5QMJFL6ZXOMNSQ3FBWIU5IBNETSGBZ9UDFSB6",
    "assetId": "SOLANA.GOD-9KT93AW5QMJFL6ZXOMNSQ3FBWIU5IBNETSGBZ9UDFSB6",
    "assetAddress": "9kt93AW5QMjFL6ZxomnSq3FbWiU5ibNeTSgBz9UDFSB6",
    "assetName": "GOD",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.god-9kt93aw5qmjfl6zxomnsq3fbwiu5ibnetsgbz9udfsb6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SHARDS-8J3HXRK5RDOZ2VSPGLRMXTWMW6IYARUW5XVK4KZMC9HP",
    "assetId": "SOLANA.SHARDS-8J3HXRK5RDOZ2VSPGLRMXTWMW6IYARUW5XVK4KZMC9HP",
    "assetAddress": "8j3hXRK5rdoZ2vSpGLRmXtWmW6iYaRUw5xVk4Kzmc9Hp",
    "assetName": "SHARDS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.shards-8j3hxrk5rdoz2vspglrmxtwmw6iyaruw5xvk4kzmc9hp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HAT-HRQGFZIPMFHXVN5NKVTUACWUA3TP2UGQCQZARRGAYQ22",
    "assetId": "SOLANA.HAT-HRQGFZIPMFHXVN5NKVTUACWUA3TP2UGQCQZARRGAYQ22",
    "assetAddress": "HrqgFZipMFHXvN5nKvTUaCwuA3Tp2UGqcQzArRGAyQ22",
    "assetName": "HAT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hat-hrqgfzipmfhxvn5nkvtuacwua3tp2ugqcqzarrgayq22.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SUPER-5NRAYNQDSEYBC3GRC7HVM5QNDWJQVEPNG9KN6BCVI4G3",
    "assetId": "SOLANA.SUPER-5NRAYNQDSEYBC3GRC7HVM5QNDWJQVEPNG9KN6BCVI4G3",
    "assetAddress": "5nrAynqdsEyBc3GRc7hvM5QnDwjQVePNg9kn6bCvi4G3",
    "assetName": "SUPER",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.super-5nraynqdseybc3grc7hvm5qndwjqvepng9kn6bcvi4g3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RPC-EAEFYXW6E8SNY1CX3LTH6RSVTZH6E5EFY1XSE2AIH1F3",
    "assetId": "SOLANA.RPC-EAEFYXW6E8SNY1CX3LTH6RSVTZH6E5EFY1XSE2AIH1F3",
    "assetAddress": "EAefyXw6E8sny1cX3LTH6RSvtzH6E5EFy1XsE2AiH1f3",
    "assetName": "RPC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rpc-eaefyxw6e8sny1cx3lth6rsvtzh6e5efy1xse2aih1f3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GEMS-2YJH1Y5NBDWJGEUAMY6HOTYCKWRRCP6KLKS62XISKWHM",
    "assetId": "SOLANA.GEMS-2YJH1Y5NBDWJGEUAMY6HOTYCKWRRCP6KLKS62XISKWHM",
    "assetAddress": "2YJH1Y5NbdwJGEUAMY6hoTycKWrRCP6kLKs62xiSKWHM",
    "assetName": "GEMS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gems-2yjh1y5nbdwjgeuamy6hotyckwrrcp6klks62xiskwhm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.INU-5JFNSFX36DYGK8UVGRBXNVUMTSBKPXGPX6E69BIGFZKO",
    "assetId": "SOLANA.INU-5JFNSFX36DYGK8UVGRBXNVUMTSBKPXGPX6E69BIGFZKO",
    "assetAddress": "5jFnsfx36DyGk8uVGrbXnVUMTsBkPXGpx6e69BiGFzko",
    "assetName": "INU",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.inu-5jfnsfx36dygk8uvgrbxnvumtsbkpxgpx6e69bigfzko.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOOP-CCRJOHP9BFQDHZCVCE7MZUQUXMJKKBI4XCJUQ3A2YQN8",
    "assetId": "SOLANA.BOOP-CCRJOHP9BFQDHZCVCE7MZUQUXMJKKBI4XCJUQ3A2YQN8",
    "assetAddress": "CCRJohp9bfQdhZCvCe7MzUQuXMJkKBi4XCjUq3A2YqN8",
    "assetName": "BOOP",
    "decimals": 10,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.boop-ccrjohp9bfqdhzcvce7mzuquxmjkkbi4xcjuq3a2yqn8.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HAMI-4SP2EUDRQF46RZUN6SYAWZJRXWUPX2T3NJUOKMV766RJ",
    "assetId": "SOLANA.HAMI-4SP2EUDRQF46RZUN6SYAWZJRXWUPX2T3NJUOKMV766RJ",
    "assetAddress": "4sp2EUDrQf46rZun6sYAWzjrXwUpx2T3njuoKmV766RJ",
    "assetName": "HAMI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hami-4sp2eudrqf46rzun6syawzjrxwupx2t3njuokmv766rj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LSD-DDTI34VNKRCEHR8FIH6DTGPPUC3W8TL4XQ4QLQHC3XPA",
    "assetId": "SOLANA.LSD-DDTI34VNKRCEHR8FIH6DTGPPUC3W8TL4XQ4QLQHC3XPA",
    "assetAddress": "DDti34vnkrCehR8fih6dTGpPuc3w8tL4XQ4QLQhc3xPa",
    "assetName": "LSD",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lsd-ddti34vnkrcehr8fih6dtgppuc3w8tl4xq4qlqhc3xpa.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHAD-G7RWEGK8KGQ4RUTNMY2W2I7DRDQ4HXHD4CSP9PSMSBRW",
    "assetId": "SOLANA.CHAD-G7RWEGK8KGQ4RUTNMY2W2I7DRDQ4HXHD4CSP9PSMSBRW",
    "assetAddress": "G7rwEgk8KgQ4RUTnMy2W2i7dRDq4hXHD4CSp9PSmSbRW",
    "assetName": "CHAD",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chad-g7rwegk8kgq4rutnmy2w2i7drdq4hxhd4csp9psmsbrw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NYAN-NYANPAP9CR7YARBNRBY7XX4XU6NO6JKTBUOHNA3YSCP",
    "assetId": "SOLANA.NYAN-NYANPAP9CR7YARBNRBY7XX4XU6NO6JKTBUOHNA3YSCP",
    "assetAddress": "NYANpAp9Cr7YarBNrby7Xx4xU6No6JKTBuohNA3yscP",
    "assetName": "NYAN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nyan-nyanpap9cr7yarbnrby7xx4xu6no6jktbuohna3yscp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NYAN-49ZTDWXK7QEFZ8Y1T8OWMHLHEWIA3PTVNPPRGAJRD8WH",
    "assetId": "SOLANA.NYAN-49ZTDWXK7QEFZ8Y1T8OWMHLHEWIA3PTVNPPRGAJRD8WH",
    "assetAddress": "49ztDWXk7qEfz8Y1t8owmhLhewiA3ptVNPpRGAjRd8Wh",
    "assetName": "NYAN",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nyan-49ztdwxk7qefz8y1t8owmhlhewia3ptvnpprgajrd8wh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WEN-WENWENVQQNYA429UBCDR81ZMD69BRWQAABYY6P3LCPK",
    "assetId": "SOLANA.WEN-WENWENVQQNYA429UBCDR81ZMD69BRWQAABYY6P3LCPK",
    "assetAddress": "WENWENvqqNya429ubCdR81ZmD69brwQaaBYY6p3LCpk",
    "assetName": "WEN",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wen-wenwenvqqnya429ubcdr81zmd69brwqaabyy6p3lcpk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.VROOM-KARTDF5K68Q2NGPPIZG3DECZP7AHHY6YXF2UTQJBSQX",
    "assetId": "SOLANA.VROOM-KARTDF5K68Q2NGPPIZG3DECZP7AHHY6YXF2UTQJBSQX",
    "assetAddress": "KARTdF5K68Q2nGppizG3DeCzp7AhHy6YXf2uTQjBSQx",
    "assetName": "VROOM",
    "decimals": 0,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.vroom-kartdf5k68q2ngppizg3deczp7ahhy6yxf2utqjbsqx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FRENS-HNM1VGNYHAMZZF71RJNFNIYLN76ZYZTDCBZPJYVEWFXX",
    "assetId": "SOLANA.FRENS-HNM1VGNYHAMZZF71RJNFNIYLN76ZYZTDCBZPJYVEWFXX",
    "assetAddress": "HNm1VgnyhaMZZF71RjNFNiYLN76zyZTDcBZPjYveWFXX",
    "assetName": "FRENS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.frens-hnm1vgnyhamzzf71rjnfniyln76zyztdcbzpjyvewfxx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OMNI-7MMXL6ET4SBPDS2IXOZQ3OPEEXEAIYETXH1QJDNI5QNV",
    "assetId": "SOLANA.OMNI-7MMXL6ET4SBPDS2IXOZQ3OPEEXEAIYETXH1QJDNI5QNV",
    "assetAddress": "7mmXL6Et4SbpDs2iXoZQ3oPEeXeAiyETxh1QjDNi5qnV",
    "assetName": "OMNI",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.omni-7mmxl6et4sbpds2ixozq3opeexeaiyetxh1qjdni5qnv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PAW-PAWSXHWSONRTEY4SX7TZ1FM9KSULPE13Y54K57YM4RG",
    "assetId": "SOLANA.PAW-PAWSXHWSONRTEY4SX7TZ1FM9KSULPE13Y54K57YM4RG",
    "assetAddress": "pawSXHWsonrTey4SX7tz1fM9ksuLpE13Y54K57ym4Rg",
    "assetName": "PAW",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.paw-pawsxhwsonrtey4sx7tz1fm9ksulpe13y54k57ym4rg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PAW-3WV4FTWGVTWNVQB8OVU4T99BY8KZTDLTEXQHNKPFHAA9",
    "assetId": "SOLANA.PAW-3WV4FTWGVTWNVQB8OVU4T99BY8KZTDLTEXQHNKPFHAA9",
    "assetAddress": "3WV4fTWGvtWNvQb8oVU4t99By8KztDLtExqHnkPfHAA9",
    "assetName": "PAW",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.paw-3wv4ftwgvtwnvqb8ovu4t99by8kztdltexqhnkpfhaa9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.APU-ECUTGG12PNHQHKVNH1S1FCUXGCDZKDNHSF5ALTANIOR7",
    "assetId": "SOLANA.APU-ECUTGG12PNHQHKVNH1S1FCUXGCDZKDNHSF5ALTANIOR7",
    "assetAddress": "ECutGg12PNhqhkvnH1s1FcuXgCDzKDNhSf5aLtANioR7",
    "assetName": "APU",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.apu-ecutgg12pnhqhkvnh1s1fcuxgcdzkdnhsf5altanior7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GME-8WXTPEU6557ETKP9WHFY1N1ECU6NXDVBAGGHGSMYIHSB",
    "assetId": "SOLANA.GME-8WXTPEU6557ETKP9WHFY1N1ECU6NXDVBAGGHGSMYIHSB",
    "assetAddress": "8wXtPeU6557ETkp9WHFY1n1EcU6NxDvbAggHGsMYiHsB",
    "assetName": "GME",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gme-8wxtpeu6557etkp9whfy1n1ecu6nxdvbagghgsmyihsb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GROK-BQ3F72YT9FVRGYRQCVCG3YOHYBESDZ9BTUHGDMQ7GNEF",
    "assetId": "SOLANA.GROK-BQ3F72YT9FVRGYRQCVCG3YOHYBESDZ9BTUHGDMQ7GNEF",
    "assetAddress": "BQ3F72yt9FVRgYrqCVCG3YohyBesDZ9bTuhGdmQ7GNEF",
    "assetName": "GROK",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.grok-bq3f72yt9fvrgyrqcvcg3yohybesdz9btuhgdmq7gnef.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NOVA-BDRL8HUIS6S5TPMOZAAAT5ZHE5A7ZBAB2JMMVPKEEF8A",
    "assetId": "SOLANA.NOVA-BDRL8HUIS6S5TPMOZAAAT5ZHE5A7ZBAB2JMMVPKEEF8A",
    "assetAddress": "BDrL8huis6S5tpmozaAaT5zhE5A7ZBAB2jMMvpKEeF8A",
    "assetName": "NOVA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nova-bdrl8huis6s5tpmozaaat5zhe5a7zbab2jmmvpkeef8a.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SANTA-ECTMRN2JMADTDVQDG7MXADYITVHGZIGYNRT9PWE6ZIOG",
    "assetId": "SOLANA.SANTA-ECTMRN2JMADTDVQDG7MXADYITVHGZIGYNRT9PWE6ZIOG",
    "assetAddress": "EctmRn2jMAdTDvQdG7mxadyiTvhGZiGYNrt9PWe6zioG",
    "assetName": "SANTA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.santa-ectmrn2jmadtdvqdg7mxadyitvhgzigynrt9pwe6ziog.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LONG-AYABIQKUTH9VA5AQC6AUJFEVHWDGMECGQIFMKW5G3K4Z",
    "assetId": "SOLANA.LONG-AYABIQKUTH9VA5AQC6AUJFEVHWDGMECGQIFMKW5G3K4Z",
    "assetAddress": "AYABiqKuTh9Va5Aqc6AujFevHwDGmECGQiFmKW5g3K4Z",
    "assetName": "LONG",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.long-ayabiqkuth9va5aqc6aujfevhwdgmecgqifmkw5g3k4z.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SILLY-7EYNHQOR9YM3N7UOAKROA44UY8JEAZV3QYOUOV87AWMS",
    "assetId": "SOLANA.SILLY-7EYNHQOR9YM3N7UOAKROA44UY8JEAZV3QYOUOV87AWMS",
    "assetAddress": "7EYnhQoR9YM3N7UoaKRoA44Uy8JeaZV3qyouov87awMs",
    "assetName": "SILLY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.silly-7eynhqor9ym3n7uoakroa44uy8jeazv3qyouov87awms.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HONK-3AG1MJ9AKZ9FAKCQ6GAEHPLSX8B2PUBPDKB9IBSDLZNB",
    "assetId": "SOLANA.HONK-3AG1MJ9AKZ9FAKCQ6GAEHPLSX8B2PUBPDKB9IBSDLZNB",
    "assetAddress": "3ag1Mj9AKz9FAkCQ6gAEhpLSX8B2pUbPdkb9iBsDLZNB",
    "assetName": "HONK",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.honk-3ag1mj9akz9fakcq6gaehplsx8b2pubpdkb9ibsdlznb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GIGA-63LFDMNB3MQ8MW9MTZ2TO9BEA2M71KZUUGQ5TIJXCQJ9",
    "assetId": "SOLANA.GIGA-63LFDMNB3MQ8MW9MTZ2TO9BEA2M71KZUUGQ5TIJXCQJ9",
    "assetAddress": "63LfDmNb3MQ8mw9MtZ2To9bEA2M71kZUUGq5tiJxcqj9",
    "assetName": "GIGA",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.giga-63lfdmnb3mq8mw9mtz2to9bea2m71kzuugq5tijxcqj9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EGG-EXA537HSBVPSFIJENBT6MUUY9AADUN8DUMYKD4OKBJJE",
    "assetId": "SOLANA.EGG-EXA537HSBVPSFIJENBT6MUUY9AADUN8DUMYKD4OKBJJE",
    "assetAddress": "EXA537HSBVpsFijENbt6Muuy9AADUN8dUmYKD4oKbjJE",
    "assetName": "EGG",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.egg-exa537hsbvpsfijenbt6muuy9aadun8dumykd4okbjje.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EGG-WO1ZGT8RFRYPVDVI4NIDOJ1SYFCR4PQX69BMNV2JLHQ",
    "assetId": "SOLANA.EGG-WO1ZGT8RFRYPVDVI4NIDOJ1SYFCR4PQX69BMNV2JLHQ",
    "assetAddress": "wo1zgt8rfrYpvdVi4nidoj1SYfcR4pQx69bmNv2JLhQ",
    "assetName": "EGG",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.egg-wo1zgt8rfrypvdvi4nidoj1syfcr4pqx69bmnv2jlhq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EGG-4YNYX6BZY2XGFGJJUN9CRUJ1BSRO8FLSAQNNPSW6JDSU",
    "assetId": "SOLANA.EGG-4YNYX6BZY2XGFGJJUN9CRUJ1BSRO8FLSAQNNPSW6JDSU",
    "assetAddress": "4ynyx6BzY2XGFgjjun9Cruj1bSRo8FLsAqNnPsW6jDsu",
    "assetName": "EGG",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.egg-4ynyx6bzy2xgfgjjun9cruj1bsro8flsaqnnpsw6jdsu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PIF-8VRNNKNWERUNJEEDTEFOXZAXBXGNEY61FPBX8UTVKSUD",
    "assetId": "SOLANA.PIF-8VRNNKNWERUNJEEDTEFOXZAXBXGNEY61FPBX8UTVKSUD",
    "assetAddress": "8vRnnknwERunJEEDtEFoxzaxbxGnEY61FPbx8uTVKsUD",
    "assetName": "PIF",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pif-8vrnnknwerunjeedtefoxzaxbxgney61fpbx8utvksud.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLANA-FESBUKJUMY6JZDH9VP8CY4P3PU2Q5W2RK2XGHVFNSEP",
    "assetId": "SOLANA.SOLANA-FESBUKJUMY6JZDH9VP8CY4P3PU2Q5W2RK2XGHVFNSEP",
    "assetAddress": "fESbUKjuMY6jzDH9VP8cy4p3pu2q5W2rK2XghVfNseP",
    "assetName": "SOLANA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solana-fesbukjumy6jzdh9vp8cy4p3pu2q5w2rk2xghvfnsep.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SANDY-6HBRY7W55A3NXDAXXJKQRX3OXFAATNKAJUSGT66ZRSZK",
    "assetId": "SOLANA.SANDY-6HBRY7W55A3NXDAXXJKQRX3OXFAATNKAJUSGT66ZRSZK",
    "assetAddress": "6hBry7w55A3NXDAXxjKqrx3oXfAatNkAJUSGt66ZRsZk",
    "assetName": "SANDY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sandy-6hbry7w55a3nxdaxxjkqrx3oxfaatnkajusgt66zrszk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OBEMA-CZKM8BZWFDXSJTZQNZ2PITXJKPTJ5MFTL8S6SNZG7N7S",
    "assetId": "SOLANA.OBEMA-CZKM8BZWFDXSJTZQNZ2PITXJKPTJ5MFTL8S6SNZG7N7S",
    "assetAddress": "CzkM8bzWFdXsjtZQnz2piTxJKPtJ5mfTL8S6sNZg7n7S",
    "assetName": "OBEMA",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.obema-czkm8bzwfdxsjtzqnz2pitxjkptj5mftl8s6snzg7n7s.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ETH-7VFCXTUXX5WJV5JADK17DUJ4KSGAU7UTNKJ4B963VOXS",
    "assetId": "SOLANA.ETH-7VFCXTUXX5WJV5JADK17DUJ4KSGAU7UTNKJ4B963VOXS",
    "assetAddress": "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs",
    "assetName": "ETH",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.eth-7vfcxtuxx5wjv5jadk17duj4ksgau7utnkj4b963voxs.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLPAKA-BDHQX9YFJE3M6CAOX3OBUX5YPWHZ2CJNGFIZJTRGHDCO",
    "assetId": "SOLANA.SOLPAKA-BDHQX9YFJE3M6CAOX3OBUX5YPWHZ2CJNGFIZJTRGHDCO",
    "assetAddress": "BDHqX9YfJE3M6caox3obUX5YpWHz2cjnGFiZJtRghdCo",
    "assetName": "SOLPAKA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solpaka-bdhqx9yfje3m6caox3obux5ypwhz2cjngfizjtrghdco.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BULL-7ZYECVDSXRFFH7TC5JRFBAZXQ6UHF5NNNDNTRZRDSDYF",
    "assetId": "SOLANA.BULL-7ZYECVDSXRFFH7TC5JRFBAZXQ6UHF5NNNDNTRZRDSDYF",
    "assetAddress": "7ZYeCVdsXRFfh7TC5JRfBaZxQ6UhF5nNNdNtRzrdsDYF",
    "assetName": "BULL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bull-7zyecvdsxrffh7tc5jrfbazxq6uhf5nnndntrzrdsdyf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BULL-BULLA6G9E5UCUTXC5Z3CF7S7CGVJHNJFY71DWIPSMF8W",
    "assetId": "SOLANA.BULL-BULLA6G9E5UCUTXC5Z3CF7S7CGVJHNJFY71DWIPSMF8W",
    "assetAddress": "BULLa6g9e5UCuTXC5Z3Cf7s7CgvJhnJfY71DwipSmF8w",
    "assetName": "BULL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bull-bulla6g9e5ucutxc5z3cf7s7cgvjhnjfy71dwipsmf8w.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LORGY-C3VG7PS6DNPUGYCPA52KETPDCYTE6KI3OE8CCTSAY63I",
    "assetId": "SOLANA.LORGY-C3VG7PS6DNPUGYCPA52KETPDCYTE6KI3OE8CCTSAY63I",
    "assetAddress": "C3Vg7ps6dNPugYCpA52KETpdCytE6ki3oe8ccTsaY63i",
    "assetName": "LORGY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lorgy-c3vg7ps6dnpugycpa52ketpdcyte6ki3oe8cctsay63i.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CAPY-CAPYD6LRM7BTZ6C7T7JVSXVPECFKQ9YNB7KUJH6P6XBN",
    "assetId": "SOLANA.CAPY-CAPYD6LRM7BTZ6C7T7JVSXVPECFKQ9YNB7KUJH6P6XBN",
    "assetAddress": "CAPYD6Lrm7bTZ6C7t7JvSxvpEcfKQ9YNB7kUjh6p6XBN",
    "assetName": "CAPY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.capy-capyd6lrm7btz6c7t7jvsxvpecfkq9ynb7kujh6p6xbn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.COPE-8HGYAAB1YOM1TTS7PXJHMA3DUKTFGQGGNFFH3HJZGZQH",
    "assetId": "SOLANA.COPE-8HGYAAB1YOM1TTS7PXJHMA3DUKTFGQGGNFFH3HJZGZQH",
    "assetAddress": "8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh",
    "assetName": "COPE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cope-8hgyaab1yom1tts7pxjhma3duktfgqggnffh3hjzgzqh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.COME-GMW12MAZYTJ897Y3PGXDQZPNNID7Q58E8T7V56RMAUDD",
    "assetId": "SOLANA.COME-GMW12MAZYTJ897Y3PGXDQZPNNID7Q58E8T7V56RMAUDD",
    "assetAddress": "GmW12mAzyTj897Y3pgxDQzpnNid7q58E8T7V56rmaUdD",
    "assetName": "COME",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.come-gmw12mazytj897y3pgxdqzpnnid7q58e8t7v56rmaudd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KITTY-6XWFKYG5MZGTKNFTSDGYJYOPYUSLRF2RAFJ95XSFSFRR",
    "assetId": "SOLANA.KITTY-6XWFKYG5MZGTKNFTSDGYJYOPYUSLRF2RAFJ95XSFSFRR",
    "assetAddress": "6XWfkyg5mzGtKNftSDgYjyoPyUsLRf2rafj95XSFSFrr",
    "assetName": "KITTY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.kitty-6xwfkyg5mzgtknftsdgyjyopyuslrf2rafj95xsfsfrr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KITTY-8YJ15EE2AUQMWBWPXXLTTEBTZYMGN4MTSRKMQVHW1J1G",
    "assetId": "SOLANA.KITTY-8YJ15EE2AUQMWBWPXXLTTEBTZYMGN4MTSRKMQVHW1J1G",
    "assetAddress": "8yJ15ee2AUQmwbWPxXLTTeBTzyMGn4MtSRKMqVHw1J1G",
    "assetName": "KITTY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.kitty-8yj15ee2auqmwbwpxxlttebtzymgn4mtsrkmqvhw1j1g.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CCC-E9BJYSAQABYAD2ZAEV4QAMVNAIX7Z2DP4SN1JGSTN2B6",
    "assetId": "SOLANA.CCC-E9BJYSAQABYAD2ZAEV4QAMVNAIX7Z2DP4SN1JGSTN2B6",
    "assetAddress": "E9bjYSAqabYAd2Zaev4qAMVNAiX7Z2Dp4Sn1JgsTn2b6",
    "assetName": "CCC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ccc-e9bjysaqabyad2zaev4qamvnaix7z2dp4sn1jgstn2b6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SPEND-3MP1MN5V7ZDGXTVVCC9ZUMOSZMRH9PNDACDKAQKC7FEC",
    "assetId": "SOLANA.SPEND-3MP1MN5V7ZDGXTVVCC9ZUMOSZMRH9PNDACDKAQKC7FEC",
    "assetAddress": "3mp1MN5v7zdGXTvvcC9zUMoszMrh9pNdaCDkAQKc7Fec",
    "assetName": "SPEND",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.spend-3mp1mn5v7zdgxtvvcc9zumoszmrh9pndacdkaqkc7fec.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PUN-FASJ3PKDJAHATJMGPC92CMHJCJXNC8SBTKPAPUTF3HW1",
    "assetId": "SOLANA.PUN-FASJ3PKDJAHATJMGPC92CMHJCJXNC8SBTKPAPUTF3HW1",
    "assetAddress": "FaSJ3PKDjaHatJMgpC92cmhjcJxnc8sbTkpaPuTF3hW1",
    "assetName": "PUN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pun-fasj3pkdjahatjmgpc92cmhjcjxnc8sbtkpaputf3hw1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FOMO-CX9OLYNYGC3RRGXZIN7U417HNY9D6YB1EMGW4ZMBWJGW",
    "assetId": "SOLANA.FOMO-CX9OLYNYGC3RRGXZIN7U417HNY9D6YB1EMGW4ZMBWJGW",
    "assetAddress": "Cx9oLynYgC3RrgXzin7U417hNY9D6YB1eMGw4ZMbWJgw",
    "assetName": "FOMO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fomo-cx9olynygc3rrgxzin7u417hny9d6yb1emgw4zmbwjgw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOP-BLWTNYKQF7U4QJGZRRSKENS2EZWKMLQVCU6J8IHYRNA3",
    "assetId": "SOLANA.BOP-BLWTNYKQF7U4QJGZRRSKENS2EZWKMLQVCU6J8IHYRNA3",
    "assetAddress": "BLwTnYKqf7u4qjgZrrsKeNs2EzWkMLqVCu6j8iHyrNA3",
    "assetName": "BOP",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bop-blwtnykqf7u4qjgzrrskens2ezwkmlqvcu6j8ihyrna3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WOLF-FAF89929NI9FBG4GMVZTCA7EW6NFG877JQN6MIZT3GVW",
    "assetId": "SOLANA.WOLF-FAF89929NI9FBG4GMVZTCA7EW6NFG877JQN6MIZT3GVW",
    "assetAddress": "Faf89929Ni9fbg4gmVZTca7eW6NFg877Jqn6MizT3Gvw",
    "assetName": "WOLF",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wolf-faf89929ni9fbg4gmvztca7ew6nfg877jqn6mizt3gvw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WOLF-HMKQCHBKZEVQFNH8SXJA694N77ZIYMBWAUCZRKFJDRR2",
    "assetId": "SOLANA.WOLF-HMKQCHBKZEVQFNH8SXJA694N77ZIYMBWAUCZRKFJDRR2",
    "assetAddress": "HmKqChBkZEvqFnH8sxja694n77ziYMBWaucZRKfJDRr2",
    "assetName": "WOLF",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wolf-hmkqchbkzevqfnh8sxja694n77ziymbwauczrkfjdrr2.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NAZA-FJTWIPH9GYFFNX7MDE2ZS4S8HZRFZB2VRZYAKMNPAAKS",
    "assetId": "SOLANA.NAZA-FJTWIPH9GYFFNX7MDE2ZS4S8HZRFZB2VRZYAKMNPAAKS",
    "assetAddress": "FjtwiPH9gyffNX7mdE2ZS4s8hZRfZB2VRzyaKMNpaakS",
    "assetName": "NAZA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.naza-fjtwiph9gyffnx7mde2zs4s8hzrfzb2vrzyakmnpaaks.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.COOL-3WNMRXYZPZDWVAYDSF3VUNKCNK89A8USO7EZTNUBDJCR",
    "assetId": "SOLANA.COOL-3WNMRXYZPZDWVAYDSF3VUNKCNK89A8USO7EZTNUBDJCR",
    "assetAddress": "3wNmRxyZPzDWVAydsF3VuNKCNk89A8uSo7EzTnubDJcR",
    "assetName": "COOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cool-3wnmrxyzpzdwvaydsf3vunkcnk89a8uso7eztnubdjcr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PEPE-CYUXNHURE8CF3RQC1687DZNMSWX9ATNG3MZPFMRYU22S",
    "assetId": "SOLANA.PEPE-CYUXNHURE8CF3RQC1687DZNMSWX9ATNG3MZPFMRYU22S",
    "assetAddress": "CYuXNHURE8cF3rQc1687DZnmsWx9ATNG3mZPFmryu22S",
    "assetName": "PEPE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pepe-cyuxnhure8cf3rqc1687dznmswx9atng3mzpfmryu22s.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PEPE-F9CPWOYEBJFORB8F2PBE2ZNPBPSEE76MWZWME3STSVHK",
    "assetId": "SOLANA.PEPE-F9CPWOYEBJFORB8F2PBE2ZNPBPSEE76MWZWME3STSVHK",
    "assetAddress": "F9CpWoyeBJfoRB8f2pBe2ZNPbPsEE76mWZWme3StsvHK",
    "assetName": "PEPE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pepe-f9cpwoyebjforb8f2pbe2znpbpsee76mwzwme3stsvhk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PEPE-OX698TZSZAN2MINQJ1FQB48A58FZ546C4SWQQ9Y9VMU",
    "assetId": "SOLANA.PEPE-OX698TZSZAN2MINQJ1FQB48A58FZ546C4SWQQ9Y9VMU",
    "assetAddress": "ox698TZsZAN2miNQj1FQb48A58FZ546c4sWqq9Y9vmU",
    "assetName": "PEPE",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pepe-ox698tzszan2minqj1fqb48a58fz546c4swqq9y9vmu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HIM-3SK6T5GZPVPCVGDQXC4ERFVRB9YEJRRJNIXLAH6CPUMP",
    "assetId": "SOLANA.HIM-3SK6T5GZPVPCVGDQXC4ERFVRB9YEJRRJNIXLAH6CPUMP",
    "assetAddress": "3sk6T5gzpvPcvGDqXC4erFvrB9yeJrRJniXLaH6Cpump",
    "assetName": "HIM",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.him-3sk6t5gzpvpcvgdqxc4erfvrb9yejrrjnixlah6cpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLMEME-5MRMQVLZYRQHRMN2A8VSL3KV9VFJNHJRKRPHTTBZ1VEB",
    "assetId": "SOLANA.SOLMEME-5MRMQVLZYRQHRMN2A8VSL3KV9VFJNHJRKRPHTTBZ1VEB",
    "assetAddress": "5MRMqvLZyRQhrMn2a8vSL3Kv9vfjNhjRKRPHtTBz1VEB",
    "assetName": "SOLMEME",
    "decimals": 7,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solmeme-5mrmqvlzyrqhrmn2a8vsl3kv9vfjnhjrkrphttbz1veb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CAT-7HWCHOHZWTLDDDUG81H2PKWQ6KEKMTSDNKYXSSO18FY3",
    "assetId": "SOLANA.CAT-7HWCHOHZWTLDDDUG81H2PKWQ6KEKMTSDNKYXSSO18FY3",
    "assetAddress": "7hWcHohzwtLddDUG81H2PkWq6KEkMtSDNkYXsso18Fy3",
    "assetName": "CAT",
    "decimals": 3,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cat-7hwchohzwtldddug81h2pkwq6kekmtsdnkyxsso18fy3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SHIB-F6QOEFQQ4ICBLONZ34RJEQHJHKD8VTMORSDW9ND55J1K",
    "assetId": "SOLANA.SHIB-F6QOEFQQ4ICBLONZ34RJEQHJHKD8VTMORSDW9ND55J1K",
    "assetAddress": "F6qoefQq4iCBLoNZ34RjEqHjHkD8vtmoRSdw9Nd55J1k",
    "assetName": "SHIB",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.shib-f6qoefqq4icblonz34rjeqhjhkd8vtmorsdw9nd55j1k.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SHIB-5MBBSOCVDDAUF8XIXVCCXNBHAW6WFPZ8WYTKMMCZXXRN",
    "assetId": "SOLANA.SHIB-5MBBSOCVDDAUF8XIXVCCXNBHAW6WFPZ8WYTKMMCZXXRN",
    "assetAddress": "5MBBsoCVddAuF8XixvCcXNbHAw6WfpZ8WyTKMmczxxRN",
    "assetName": "SHIB",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.shib-5mbbsocvddauf8xixvccxnbhaw6wfpz8wytkmmczxxrn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SHIB-CIKU4EHSVRC1EUEVQEHN7QHXTCVU95GSQMBPX4UTJL9Z",
    "assetId": "SOLANA.SHIB-CIKU4EHSVRC1EUEVQEHN7QHXTCVU95GSQMBPX4UTJL9Z",
    "assetAddress": "CiKu4eHsVrc1eueVQeHn7qhXTcVu95gSQmBpX4utjL9z",
    "assetName": "SHIB",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.shib-ciku4ehsvrc1euevqehn7qhxtcvu95gsqmbpx4utjl9z.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.YEEHAW-AHWJJMYCQB6JWNVFR5HDTURSAZIRKOOFG6VFZHG8N71O",
    "assetId": "SOLANA.YEEHAW-AHWJJMYCQB6JWNVFR5HDTURSAZIRKOOFG6VFZHG8N71O",
    "assetAddress": "AhwJjMyCqb6jWnvFr5hDTurSAZirKoofg6vFzhG8N71o",
    "assetName": "YEEHAW",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.yeehaw-ahwjjmycqb6jwnvfr5hdtursazirkoofg6vfzhg8n71o.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MAGA-SFYDFZJGUYF4YLZJJE7QWWH41NRYMFFZ3QXZBVM7EYG",
    "assetId": "SOLANA.MAGA-SFYDFZJGUYF4YLZJJE7QWWH41NRYMFFZ3QXZBVM7EYG",
    "assetAddress": "sfYDFZJguyF4YLZjje7qwwh41NRymFfZ3QXZbVm7Eyg",
    "assetName": "MAGA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.maga-sfydfzjguyf4ylzjje7qwwh41nrymffz3qxzbvm7eyg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MAGA-AWDUJQUJOSCZM5BUDHF99IZKUTUGHU34TPFT8W5KNBW1",
    "assetId": "SOLANA.MAGA-AWDUJQUJOSCZM5BUDHF99IZKUTUGHU34TPFT8W5KNBW1",
    "assetAddress": "AwduJQUJoSCZm5BUdhF99iZkUTUGHU34TPft8w5kNbw1",
    "assetName": "MAGA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.maga-awdujqujosczm5budhf99izkutughu34tpft8w5knbw1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BCAT-7ABHUFZLKT7ACJ9RFRZJUBVCQYFCFHLAKTVZM75KAVPS",
    "assetId": "SOLANA.BCAT-7ABHUFZLKT7ACJ9RFRZJUBVCQYFCFHLAKTVZM75KAVPS",
    "assetAddress": "7ABhUfzLKT7acJ9rFRZJUBvcqYfCfHLakTvZM75kAVPS",
    "assetName": "BCAT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bcat-7abhufzlkt7acj9rfrzjubvcqyfcfhlaktvzm75kavps.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BCAT-7BQSJ9DCIGXS6CTKHB3D1WBCEJUMPMD7AMQRWJEVBPU",
    "assetId": "SOLANA.BCAT-7BQSJ9DCIGXS6CTKHB3D1WBCEJUMPMD7AMQRWJEVBPU",
    "assetAddress": "7bQsj9DciGXs6cTkhB3D1WbcEjuMpmD7amQRWjEVBpu",
    "assetName": "BCAT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bcat-7bqsj9dcigxs6ctkhb3d1wbcejumpmd7amqrwjevbpu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOBBY-48YNDQABAVGNFNKHADSV1MAVTP44FFDDHBRBDFHVPUMP",
    "assetId": "SOLANA.BOBBY-48YNDQABAVGNFNKHADSV1MAVTP44FFDDHBRBDFHVPUMP",
    "assetAddress": "48yNDqabAvGNfnkhadsV1MAvtp44fFDdHBRBdFhvpump",
    "assetName": "BOBBY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bobby-48yndqabavgnfnkhadsv1mavtp44ffddhbrbdfhvpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.APEX-51TMB3ZBKDIQHNWGQPGWBAVAGH54MK8FXFZXTC1XNASG",
    "assetId": "SOLANA.APEX-51TMB3ZBKDIQHNWGQPGWBAVAGH54MK8FXFZXTC1XNASG",
    "assetAddress": "51tMb3zBKDiQhNwGqpgwbavaGH54mk8fXFzxTc1xnasg",
    "assetName": "APEX",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.apex-51tmb3zbkdiqhnwgqpgwbavagh54mk8fxfzxtc1xnasg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TRUMP-HAP8R3KSG76PHQLTQR8FYBENIQPEJCFBQMIHBG787UT1",
    "assetId": "SOLANA.TRUMP-HAP8R3KSG76PHQLTQR8FYBENIQPEJCFBQMIHBG787UT1",
    "assetAddress": "HaP8r3ksG76PhQLTqR8FYBeNiQpejcFbQmiHbg787Ut1",
    "assetName": "TRUMP",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.trump-hap8r3ksg76phqltqr8fybeniqpejcfbqmihbg787ut1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TRUMP-AWRERBEFGTNOHZFLERSBH9HDDQEY2OERXNWLRBVFFH95",
    "assetId": "SOLANA.TRUMP-AWRERBEFGTNOHZFLERSBH9HDDQEY2OERXNWLRBVFFH95",
    "assetAddress": "AwRErBEFGTnohzfLeRSBH9HddQEy2oeRxnWLrbvFFh95",
    "assetName": "TRUMP",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.trump-awrerbefgtnohzflersbh9hddqey2oerxnwlrbvffh95.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TRUMP-BQHNDGTS1NQTZI2MVZ7G8NN1VRUHZ12UPHGJKE71E1JT",
    "assetId": "SOLANA.TRUMP-BQHNDGTS1NQTZI2MVZ7G8NN1VRUHZ12UPHGJKE71E1JT",
    "assetAddress": "BqhNdGtS1Nqtzi2MvZ7G8NN1vRuHZ12UpHGJKe71e1JT",
    "assetName": "TRUMP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.trump-bqhndgts1nqtzi2mvz7g8nn1vruhz12uphgjke71e1jt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TRUMP-4H8LJZWUFUQVGBEZ29UZTUGXNW6RWRJIS78ZU66EKKPV",
    "assetId": "SOLANA.TRUMP-4H8LJZWUFUQVGBEZ29UZTUGXNW6RWRJIS78ZU66EKKPV",
    "assetAddress": "4h8LjZWUfUQVgbEZ29UzTuGXNW6rwrJis78ZU66ekkPV",
    "assetName": "TRUMP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.trump-4h8ljzwufuqvgbez29uztugxnw6rwrjis78zu66ekkpv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AMADEUS-EQ9XBLGNBC2B6WKDOZW6V1ACC4EVTSANPKSFKADNQNFR",
    "assetId": "SOLANA.AMADEUS-EQ9XBLGNBC2B6WKDOZW6V1ACC4EVTSANPKSFKADNQNFR",
    "assetAddress": "Eq9xBLGnBc2B6wkdoZW6v1aCC4evtSaNPkSFKaDNQNFr",
    "assetName": "AMADEUS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.amadeus-eq9xblgnbc2b6wkdozw6v1acc4evtsanpksfkadnqnfr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.G-FMQVMWXBESYU4G6FT1UZ1GABKDJ4J6WBUULFWPJTQPMU",
    "assetId": "SOLANA.G-FMQVMWXBESYU4G6FT1UZ1GABKDJ4J6WBUULFWPJTQPMU",
    "assetAddress": "FmqVMWXBESyu4g6FT1uz1GABKdJ4j6wbuuLFwPJtqpmu",
    "assetName": "G",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.g-fmqvmwxbesyu4g6ft1uz1gabkdj4j6wbuulfwpjtqpmu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BONES-BONEGFPGRPZ4BFVN3KQK1AMBGYDDWTFMAYWNT5LSUVE",
    "assetId": "SOLANA.BONES-BONEGFPGRPZ4BFVN3KQK1AMBGYDDWTFMAYWNT5LSUVE",
    "assetAddress": "bonegFPgrpZ4bfVn3kQK1aMbGYddWtfMAywNt5LsuVE",
    "assetName": "BONES",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bones-bonegfpgrpz4bfvn3kqk1ambgyddwtfmaywnt5lsuve.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.APE-DF5YCVTFHVWVS1VRFHETNZEEH1N6DJAQEBS3KJ9FRDAR",
    "assetId": "SOLANA.APE-DF5YCVTFHVWVS1VRFHETNZEEH1N6DJAQEBS3KJ9FRDAR",
    "assetAddress": "DF5yCVTfhVwvS1VRfHETNzEeh1n6DjAqEBs3kj9frdAr",
    "assetName": "APE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ape-df5ycvtfhvwvs1vrfhetnzeeh1n6djaqebs3kj9frdar.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BRETT-DXTSSVDYYE4WWE5F5ZEGX2NQTDFBVL3ABGY62WCYCHWG",
    "assetId": "SOLANA.BRETT-DXTSSVDYYE4WWE5F5ZEGX2NQTDFBVL3ABGY62WCYCHWG",
    "assetAddress": "DxtssVdyYe4wWE5f5zEgx2NqtDFbVL3ABGY62WCycHWg",
    "assetName": "BRETT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.brett-dxtssvdyye4wwe5f5zegx2nqtdfbvl3abgy62wcychwg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SPIKE-BX9YEGW8WKOWV8SVQTMMCYNKQWRERTJ9ZS81DRXYNNR9",
    "assetId": "SOLANA.SPIKE-BX9YEGW8WKOWV8SVQTMMCYNKQWRERTJ9ZS81DRXYNNR9",
    "assetAddress": "BX9yEgW8WkoWV8SvqTMMCynkQWreRTJ9ZS81dRXYnnR9",
    "assetName": "SPIKE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.spike-bx9yegw8wkowv8svqtmmcynkqwrertj9zs81drxynnr9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SPIKE-SPIKEYAQOAGYYBANPXRO8NLSYLU93SR56N352JJRLN5",
    "assetId": "SOLANA.SPIKE-SPIKEYAQOAGYYBANPXRO8NLSYLU93SR56N352JJRLN5",
    "assetAddress": "sPiKEYAqoaGYYBAnPxro8NLSYLu93sr56n352jJRLN5",
    "assetName": "SPIKE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.spike-spikeyaqoagyybanpxro8nlsylu93sr56n352jjrln5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ARG-9XRPJZJHJPEWTUYMIEWN3FW7UANMEQCA14UCTWWWYP2G",
    "assetId": "SOLANA.ARG-9XRPJZJHJPEWTUYMIEWN3FW7UANMEQCA14UCTWWWYP2G",
    "assetAddress": "9XRpjZjhJPeWtUymiEWn3FW7uAnMeQca14ucTWWWyP2g",
    "assetName": "ARG",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.arg-9xrpjzjhjpewtuymiewn3fw7uanmeqca14uctwwwyp2g.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WBTC-3NZ9JMVBMGAQOCYBIC2C7LQCJSCMGSAZ6VQQTDZCQMJH",
    "assetId": "SOLANA.WBTC-3NZ9JMVBMGAQOCYBIC2C7LQCJSCMGSAZ6VQQTDZCQMJH",
    "assetAddress": "3NZ9JMVBmGAqocybic2c7LQCJScmgsAZ6vQqTDzcqmJh",
    "assetName": "WBTC",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wbtc-3nz9jmvbmgaqocybic2c7lqcjscmgsaz6vqqtdzcqmjh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOL",
    "assetId": "SOLANA.SOL",
    "assetAddress": null,
    "assetName": "SOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sol.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.INF-5OVNBEEEQVYI1CX3IR8DX5N1P7PDXYDBGF2X4TXVUSJM",
    "assetId": "SOLANA.INF-5OVNBEEEQVYI1CX3IR8DX5N1P7PDXYDBGF2X4TXVUSJM",
    "assetAddress": "5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm",
    "assetName": "INF",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.inf-5ovnbeeeqvyi1cx3ir8dx5n1p7pdxydbgf2x4txvusjm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SSOL-8EDAOEBQPCVACWVKYXH1VACU29HIBINHQOF4PRSUUSZS",
    "assetId": "SOLANA.SSOL-8EDAOEBQPCVACWVKYXH1VACU29HIBINHQOF4PRSUUSZS",
    "assetAddress": "8EDaoeBqpcVACwvkYXh1vAcU29HiBiNhqoF4pRsuUsZS",
    "assetName": "sSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ssol-8edaoebqpcvacwvkyxh1vacu29hibinhqof4prsuuszs.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JSOL-7Q2AFV64IN6N6SEZSAAB81TJZWDOD6ZPQMHKZI9DCAVN",
    "assetId": "SOLANA.JSOL-7Q2AFV64IN6N6SEZSAAB81TJZWDOD6ZPQMHKZI9DCAVN",
    "assetAddress": "7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn",
    "assetName": "JSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jsol-7q2afv64in6n6sezsaab81tjzwdod6zpqmhkzi9dcavn.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.STSOL-7DHBWXMCI3DT8UFYWYZWEBLXGYCU7Y3IL6TRKN1Y7ARJ",
    "assetId": "SOLANA.STSOL-7DHBWXMCI3DT8UFYWYZWEBLXGYCU7Y3IL6TRKN1Y7ARJ",
    "assetAddress": "7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj",
    "assetName": "stSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.stsol-7dhbwxmci3dt8ufywyzweblxgycu7y3il6trkn1y7arj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TBTC-6DNSN2BJSAPFDFFC1ZP37KKENE4USC1SQKZR9C9VPWCU",
    "assetId": "SOLANA.TBTC-6DNSN2BJSAPFDFFC1ZP37KKENE4USC1SQKZR9C9VPWCU",
    "assetAddress": "6DNSN2BJsaPFdFFc1zP37kkeNe4Usc1Sqkzr9C9vPWcU",
    "assetName": "TBTC",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tbtc-6dnsn2bjsapfdffc1zp37kkene4usc1sqkzr9c9vpwcu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PBTC-DYDWU4HE4MN3AH897XQ3SRTS5EAJJDMQSKLNHBPUIKUN",
    "assetId": "SOLANA.PBTC-DYDWU4HE4MN3AH897XQ3SRTS5EAJJDMQSKLNHBPUIKUN",
    "assetAddress": "DYDWu4hE4MN3aH897xQ3sRTs5EAjJDmQsKLNhbpUiKun",
    "assetName": "pBTC",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pbtc-dydwu4he4mn3ah897xq3srts5eajjdmqsklnhbpuikun.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FUSDC-EZ2ZVJW85TZAN1YCNJ5PYWNNXR6GM4JBXQTZKYQNU3LV",
    "assetId": "SOLANA.FUSDC-EZ2ZVJW85TZAN1YCNJ5PYWNNXR6GM4JBXQTZKYQNU3LV",
    "assetAddress": "Ez2zVjw85tZan1ycnJ5PywNNxR6Gm4jbXQtZKyQNu3Lv",
    "assetName": "fUSDC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fusdc-ez2zvjw85tzan1ycnj5pywnnxr6gm4jbxqtzkyqnu3lv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.21BTC-21BTCO9HWHJGYYUQQLQJLGDBXJCN8VDT4ZIC7TB3UBNE",
    "assetId": "SOLANA.21BTC-21BTCO9HWHJGYYUQQLQJLGDBXJCN8VDT4ZIC7TB3UBNE",
    "assetAddress": "21BTCo9hWHjGYYUQQLqjLgDBxjcn8vDt4Zic7TB3UbNE",
    "assetName": "21BTC",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.21btc-21btco9hwhjgyyuqqlqjlgdbxjcn8vdt4zic7tb3ubne.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BUNK-2NHJJQSKA8FYCUDJVQHYJBTZDPJZBNO8VTNKTKJ3HNCB",
    "assetId": "SOLANA.BUNK-2NHJJQSKA8FYCUDJVQHYJBTZDPJZBNO8VTNKTKJ3HNCB",
    "assetAddress": "2nhjjqSkA8FYCUdJvQhYjbtZdPjZbNo8VtNKTkJ3hncb",
    "assetName": "bunk",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bunk-2nhjjqska8fycudjvqhyjbtzdpjzbno8vtnktkj3hncb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FURBY-34PXEUD2AJWJAQ7XZRYDVUUGHKJP2DFRAG6K8ZVSSIGT",
    "assetId": "SOLANA.FURBY-34PXEUD2AJWJAQ7XZRYDVUUGHKJP2DFRAG6K8ZVSSIGT",
    "assetAddress": "34pxeud2ajwJaq7XZrYdvUUGHKJP2dFrAg6k8zvSsigt",
    "assetName": "FURBY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.furby-34pxeud2ajwjaq7xzrydvuughkjp2dfrag6k8zvssigt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WSOL-SO11111111111111111111111111111111111111112",
    "assetId": "SOLANA.WSOL-SO11111111111111111111111111111111111111112",
    "assetAddress": "So11111111111111111111111111111111111111112",
    "assetName": "WSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wsol-so11111111111111111111111111111111111111112.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$WIF-EKPQGSJTJMFQKZ9KQANSQYXRCF8FBOPZLHYXDM65ZCJM",
    "assetId": "SOLANA.$WIF-EKPQGSJTJMFQKZ9KQANSQYXRCF8FBOPZLHYXDM65ZCJM",
    "assetAddress": "EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm",
    "assetName": "$WIF",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wif-ekpqgsjtjmfqkz9kqansqyxrcf8fbopzlhyxdm65zcjm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JITOSOL-J1TOSO1UCK3RLMJORHTTRVWY9HJ7X8V9YYAC6Y7KGCPN",
    "assetId": "SOLANA.JITOSOL-J1TOSO1UCK3RLMJORHTTRVWY9HJ7X8V9YYAC6Y7KGCPN",
    "assetAddress": "J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn",
    "assetName": "JitoSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jitosol-j1toso1uck3rlmjorhttrvwy9hj7x8v9yyac6y7kgcpn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BONK-DEZXAZ8Z7PNRNRJJZ3WXBORGIXCA6XJNB7YAB1PPB263",
    "assetId": "SOLANA.BONK-DEZXAZ8Z7PNRNRJJZ3WXBORGIXCA6XJNB7YAB1PPB263",
    "assetAddress": "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263",
    "assetName": "Bonk",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bonk-dezxaz8z7pnrnrjjz3wxborgixca6xjnb7yab1ppb263.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JUPSOL-JUPSOLAHXQIZZTSFEWMTRRGPNYFM8F6SZDOSWBJX93V",
    "assetId": "SOLANA.JUPSOL-JUPSOLAHXQIZZTSFEWMTRRGPNYFM8F6SZDOSWBJX93V",
    "assetAddress": "jupSoLaHXQiZZTSfEWMTRRgpnyFm8f6sZdosWBjx93v",
    "assetName": "JupSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jupsol-jupsolahxqizztsfewmtrrgpnyfm8f6szdoswbjx93v.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MSOL-MSOLZYCXHDYGDZU16G5QSH3I5K3Z3KZK7YTFQCJM7SO",
    "assetId": "SOLANA.MSOL-MSOLZYCXHDYGDZU16G5QSH3I5K3Z3KZK7YTFQCJM7SO",
    "assetAddress": "mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So",
    "assetName": "mSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.msol-msolzycxhdygdzu16g5qsh3i5k3z3kzk7ytfqcjm7so.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$MICHI-5MBK36SZ7J19AN8JFOCHHQS4OF8G6BWUJBECSXBSOWDP",
    "assetId": "SOLANA.$MICHI-5MBK36SZ7J19AN8JFOCHHQS4OF8G6BWUJBECSXBSOWDP",
    "assetAddress": "5mbK36SZ7J19An8jFochhQS4of8g6BwUjbeCSxBSoWdp",
    "assetName": "$michi",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.michi-5mbk36sz7j19an8jfochhqs4of8g6bwujbecsxbsowdp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AURA-DTR4D9FTVOTX2569GAL837ZGRB6WNJJ6TKMNX9RDK9B2",
    "assetId": "SOLANA.AURA-DTR4D9FTVOTX2569GAL837ZGRB6WNJJ6TKMNX9RDK9B2",
    "assetAddress": "DtR4D9FtVoTX2569gaL837ZgrB6wNjj6tkmnX9Rdk9B2",
    "assetName": "aura",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.aura-dtr4d9ftvotx2569gal837zgrb6wnjj6tkmnx9rdk9b2.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BSOL-BSO13R4TKIE4KUML71LSHTPPL2EUBYLFX6H9HP3PIY1",
    "assetId": "SOLANA.BSOL-BSO13R4TKIE4KUML71LSHTPPL2EUBYLFX6H9HP3PIY1",
    "assetAddress": "bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1",
    "assetName": "bSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bsol-bso13r4tkie4kuml71lshtppl2eubylfx6h9hp3piy1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BICHO-GKJXELGJXPQRM7DFC2YS18VBDRXP5SJVJGBRMTGGPUMP",
    "assetId": "SOLANA.BICHO-GKJXELGJXPQRM7DFC2YS18VBDRXP5SJVJGBRMTGGPUMP",
    "assetAddress": "GkJxELgJXpQRm7dfc2yS18vBDRxP5SjVJgbrmTGgpump",
    "assetName": "bicho",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bicho-gkjxelgjxpqrm7dfc2ys18vbdrxp5sjvjgbrmtggpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HSOL-HE1IUSMFKPADWVXLNGV8Y1ISBJ4RUY6YMHEA3FOTN9A",
    "assetId": "SOLANA.HSOL-HE1IUSMFKPADWVXLNGV8Y1ISBJ4RUY6YMHEA3FOTN9A",
    "assetAddress": "he1iusmfkpAdwvxLNGV8Y1iSbj4rUy6yMhEA3fotn9A",
    "assetName": "hSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hsol-he1iusmfkpadwvxlngv8y1isbj4ruy6ymhea3fotn9a.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MINI-2JCXACFWT9MVAWBQ5NZKYWCYXQKRCDSYRDXN6HJ22SBP",
    "assetId": "SOLANA.MINI-2JCXACFWT9MVAWBQ5NZKYWCYXQKRCDSYRDXN6HJ22SBP",
    "assetAddress": "2JcXacFwt9mVAwBQ5nZkYwCyXQkRcdsYrDXn6hj22SbP",
    "assetName": "mini",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mini-2jcxacfwt9mvawbq5nzkywcyxqkrcdsyrdxn6hj22sbp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ISC-J9BCRQFX4P9D1BVLZRNCBMDV8F44A9LFDEQNE4YK2WMD",
    "assetId": "SOLANA.ISC-J9BCRQFX4P9D1BVLZRNCBMDV8F44A9LFDEQNE4YK2WMD",
    "assetAddress": "J9BcrQfX4p9D1bvLzRNCbMDv8f44a9LFdeqNE4Yk2WMD",
    "assetName": "ISC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.isc-j9bcrqfx4p9d1bvlzrncbmdv8f44a9lfdeqne4yk2wmd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LOCKIN-8KI8DPUWNXU9VSS3KQBARSCWMCFGWKZZA8PUPTO9ZBD5",
    "assetId": "SOLANA.LOCKIN-8KI8DPUWNXU9VSS3KQBARSCWMCFGWKZZA8PUPTO9ZBD5",
    "assetAddress": "8Ki8DpuWNxu9VsS3kQbarsCWMcFGWkzzA8pUPto9zBd5",
    "assetName": "LOCKIN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lockin-8ki8dpuwnxu9vss3kqbarscwmcfgwkzza8pupto9zbd5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TREMP-FU1Q8VJPZNURMQSCISJP8BAKKIDGSLMOUB8CBDF8TKQV",
    "assetId": "SOLANA.TREMP-FU1Q8VJPZNURMQSCISJP8BAKKIDGSLMOUB8CBDF8TKQV",
    "assetAddress": "FU1q8vJpZNUrmqsciSjp8bAKKidGsLmouB8CBdf8TKQv",
    "assetName": "tremp",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tremp-fu1q8vjpznurmqscisjp8bakkidgslmoub8cbdf8tkqv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NUB-GTDZKAQVMZMNTI46ZEWMIXCA4OXF4BZXWQPOKZXPFXZN",
    "assetId": "SOLANA.NUB-GTDZKAQVMZMNTI46ZEWMIXCA4OXF4BZXWQPOKZXPFXZN",
    "assetAddress": "GtDZKAqvMZMnti46ZewMiXCa4oXF4bZxwQPoKzXPFxZn",
    "assetName": "nub",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nub-gtdzkaqvmzmnti46zewmixca4oxf4bzxwqpokzxpfxzn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$MYRO-HHJPBHRRN4G56VSYLUT8DL5BV31HKXQSRAHTTUCZEZG4",
    "assetId": "SOLANA.$MYRO-HHJPBHRRN4G56VSYLUT8DL5BV31HKXQSRAHTTUCZEZG4",
    "assetAddress": "HhJpBhRRn4g56VsyLuT8DL5Bv31HkXqsrahTTUCZeZg4",
    "assetName": "$MYRO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.myro-hhjpbhrrn4g56vsylut8dl5bv31hkxqsrahttuczezg4.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SRMET-XNORPHAZWXUCZCP3KJU5YDXMKKZI5CSBXYTQ1LGE3KG",
    "assetId": "SOLANA.SRMET-XNORPHAZWXUCZCP3KJU5YDXMKKZI5CSBXYTQ1LGE3KG",
    "assetAddress": "xnorPhAzWXUczCP3KjU5yDxmKKZi5cSbxytQ1LgE3kG",
    "assetName": "SRMet",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.srmet-xnorphazwxuczcp3kju5ydxmkkzi5csbxytq1lge3kg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.YARD-8RYSC3RRS4X4BVBCTSJNHCPPPMAAJKXNVKZPZANXQHGZ",
    "assetId": "SOLANA.YARD-8RYSC3RRS4X4BVBCTSJNHCPPPMAAJKXNVKZPZANXQHGZ",
    "assetAddress": "8RYSc3rrS4X4bvBCtSJnhcpPpMaAJkXnVKZPzANxQHgz",
    "assetName": "YARD",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.yard-8rysc3rrs4x4bvbctsjnhcpppmaajkxnvkzpzanxqhgz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JOHN-BUXH23OSRYFFLBWG3CZRTSFBQYBXZVZ8F7QV4CJTHN5X",
    "assetId": "SOLANA.JOHN-BUXH23OSRYFFLBWG3CZRTSFBQYBXZVZ8F7QV4CJTHN5X",
    "assetAddress": "BuxH23osRyFFLbWG3czrTsfBQYbxzVZ8f7QV4cjTHN5x",
    "assetName": "JOHN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.john-buxh23osryfflbwg3czrtsfbqybxzvz8f7qv4cjthn5x.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KET-2SSU91ZE2DNI5AD8I7BFTR4BVF6UCGFBBLSVDUCWUDW3",
    "assetId": "SOLANA.KET-2SSU91ZE2DNI5AD8I7BFTR4BVF6UCGFBBLSVDUCWUDW3",
    "assetAddress": "2SsU91ZE2dni5aD8i7bfTR4Bvf6UcGFBbLsvDucWUdw3",
    "assetName": "ket",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ket-2ssu91ze2dni5ad8i7bftr4bvf6ucgfbblsvducwudw3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WHOREN-EF23AVQ2CTPNMVTFHACZ3SG5Z8MISHMFA2GBT2RKQIYH",
    "assetId": "SOLANA.WHOREN-EF23AVQ2CTPNMVTFHACZ3SG5Z8MISHMFA2GBT2RKQIYH",
    "assetAddress": "EF23Avq2cTPnMVTfHacZ3SG5Z8misHmFA2gbt2rKqiYH",
    "assetName": "whoren",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.whoren-ef23avq2ctpnmvtfhacz3sg5z8mishmfa2gbt2rkqiyh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PULP-5YTGSYQ7YXFGDC9OJ4SPZGTJOS5P5FUBKG8SMAUQQXOS",
    "assetId": "SOLANA.PULP-5YTGSYQ7YXFGDC9OJ4SPZGTJOS5P5FUBKG8SMAUQQXOS",
    "assetAddress": "5YtGSyQ7YxFgDc9oj4SpZGTjoS5p5Fubkg8sMauQqXoS",
    "assetName": "PULP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pulp-5ytgsyq7yxfgdc9oj4spzgtjos5p5fubkg8smauqqxos.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LABZ-4VC7UYQBO9SIW8ZNKPXFW9D3DZYCIVRPDZS9XRTYRJMH",
    "assetId": "SOLANA.LABZ-4VC7UYQBO9SIW8ZNKPXFW9D3DZYCIVRPDZS9XRTYRJMH",
    "assetAddress": "4VC7UYqBo9Siw8ZnkPXfw9D3dzYCiVrPDzs9XRtyRJMH",
    "assetName": "Labz",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.labz-4vc7uyqbo9siw8znkpxfw9d3dzycivrpdzs9xrtyrjmh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SEYLER-5G6B3ECLHC4WVMUJRITVE5MNCUMIAHBKXQYWKUJDMDI",
    "assetId": "SOLANA.SEYLER-5G6B3ECLHC4WVMUJRITVE5MNCUMIAHBKXQYWKUJDMDI",
    "assetAddress": "5g6b3eCLHC4WvmUJRiTvE5mNCUMiaHbKxQyWKuJdmDi",
    "assetName": "SEYLER",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.seyler-5g6b3eclhc4wvmujritve5mncumiahbkxqywkujdmdi.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ALBÄRT-EUROSPBDDNVGHRGWJ56S864XWG6WGJ42YLSHSNYAWBJ",
    "assetId": "SOLANA.ALBÄRT-EUROSPBDDNVGHRGWJ56S864XWG6WGJ42YLSHSNYAWBJ",
    "assetAddress": "EUroSPBddnvGhRGWj56S864XWG6wgJ42yLsHSNyAWBj",
    "assetName": "Albärt",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.alb%C3%A4rt-eurospbddnvghrgwj56s864xwg6wgj42ylshsnyawbj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DFTU-9HD434AAPFBIDD6HRET16HHGMQKH9Z3WRKICL5CVCSZ8",
    "assetId": "SOLANA.DFTU-9HD434AAPFBIDD6HRET16HHGMQKH9Z3WRKICL5CVCSZ8",
    "assetAddress": "9hD434AapfbidD6hret16hHGMqKh9z3WrkicL5Cvcsz8",
    "assetName": "DFTU",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dftu-9hd434aapfbidd6hret16hhgmqkh9z3wrkicl5cvcsz8.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AEUSDT-BN113WT6RBDGWRM12UJTNMNQGQZJY4IT2WOUQUQOPFVN",
    "assetId": "SOLANA.AEUSDT-BN113WT6RBDGWRM12UJTNMNQGQZJY4IT2WOUQUQOPFVN",
    "assetAddress": "Bn113WT6rbdgwrm12UJtnmNqGqZjY4it2WoUQuQopFVn",
    "assetName": "aeUSDT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.aeusdt-bn113wt6rbdgwrm12ujtnmnqgqzjy4it2wouquqopfvn.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SPOOKY-FDGOS1DOK5CLNS8FVSMJ5A92UY1YHZDTC2ZXULJDKWGS",
    "assetId": "SOLANA.SPOOKY-FDGOS1DOK5CLNS8FVSMJ5A92UY1YHZDTC2ZXULJDKWGS",
    "assetAddress": "FdGoS1Dok5CLnS8fVSmj5A92uY1yhzdTC2ZxuLJdkwgs",
    "assetName": "SPOOKY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.spooky-fdgos1dok5clns8fvsmj5a92uy1yhzdtc2zxuljdkwgs.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GRUMP-CH5JJQZSPIJ9MCDURZAA8NNVVBIWD1TNPCFMKCCVPIDB",
    "assetId": "SOLANA.GRUMP-CH5JJQZSPIJ9MCDURZAA8NNVVBIWD1TNPCFMKCCVPIDB",
    "assetAddress": "Ch5JJQZspiJ9MCDURZAA8nnVvbiwD1tnPcfmkCcVPiDb",
    "assetName": "GRUMP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.grump-ch5jjqzspij9mcdurzaa8nnvvbiwd1tnpcfmkccvpidb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHEXBACCA-8J5E2FPMBJ1SUBEUEVKELPEBZV9AYURMMFHYS7SREEXR",
    "assetId": "SOLANA.CHEXBACCA-8J5E2FPMBJ1SUBEUEVKELPEBZV9AYURMMFHYS7SREEXR",
    "assetAddress": "8J5e2FPmBJ1subEUeVkELpeBZv9aYUrMmfHys7sREeXr",
    "assetName": "CHEXBACCA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chexbacca-8j5e2fpmbj1subeuevkelpebzv9ayurmmfhys7sreexr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SHEPI-2MMJXYVSJUY4C3SSJ9EAWVROILWNUXG7DQIEB5PCMJKM",
    "assetId": "SOLANA.SHEPI-2MMJXYVSJUY4C3SSJ9EAWVROILWNUXG7DQIEB5PCMJKM",
    "assetAddress": "2MMJXYvSJuY4C3sSj9eAWVroiLWnuxG7DQieB5PCMjKm",
    "assetName": "SHEPI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.shepi-2mmjxyvsjuy4c3ssj9eawvroilwnuxg7dqieb5pcmjkm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RFRAKT-7V5AAQHTWIYSEGAAMNPLEKQFTAOK4WVEVGFI2R8V44TB",
    "assetId": "SOLANA.RFRAKT-7V5AAQHTWIYSEGAAMNPLEKQFTAOK4WVEVGFI2R8V44TB",
    "assetAddress": "7V5AaqHTwiySegaAmNPLekQfTAoK4WvEVgfi2R8V44tB",
    "assetName": "rFRAKT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rfrakt-7v5aaqhtwiysegaamnplekqftaok4wvevgfi2r8v44tb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHED-12MCPYL84OMI8HIINYJUV2ZQ3F47TLLXJW1THLCADKT2",
    "assetId": "SOLANA.CHED-12MCPYL84OMI8HIINYJUV2ZQ3F47TLLXJW1THLCADKT2",
    "assetAddress": "12mcpYL84oMi8Hiinyjuv2Zq3F47tLLxjw1THLcAdKT2",
    "assetName": "ched",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ched-12mcpyl84omi8hiinyjuv2zq3f47tllxjw1thlcadkt2.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ASV-AXATJDRUUC3626FTPWDQCMCWPH6YZGXXKWBFCZN3TMGY",
    "assetId": "SOLANA.ASV-AXATJDRUUC3626FTPWDQCMCWPH6YZGXXKWBFCZN3TMGY",
    "assetAddress": "AxaTJdRuuc3626FtPWdQCMcWPH6yzgxXKWbFCZN3TMgy",
    "assetName": "ASV",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.asv-axatjdruuc3626ftpwdqcmcwph6yzgxxkwbfczn3tmgy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PRE--EZGVDFQRMSAXTT3UD2BCGUASAPKWGEXIDZHVLQT3STZM",
    "assetId": "SOLANA.PRE--EZGVDFQRMSAXTT3UD2BCGUASAPKWGEXIDZHVLQT3STZM",
    "assetAddress": "",
    "assetName": "PRE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pre--ezgvdfqrmsaxtt3ud2bcguasapkwgexidzhvlqt3stzm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLNTN-AXNX9XD9EUAD1XCI3AUX1FJYNTAXAYRX29GSL7YTBJQ",
    "assetId": "SOLANA.SOLNTN-AXNX9XD9EUAD1XCI3AUX1FJYNTAXAYRX29GSL7YTBJQ",
    "assetAddress": "aXNx9xd9EUAd1xci3aUX1FjyNTAxaYrX29GsL7YtBJq",
    "assetName": "SOLNTN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solntn-axnx9xd9euad1xci3aux1fjyntaxayrx29gsl7ytbjq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PFIRE-PFIREKHT5WG7AXMSLBMMRPVYH7CGHX9CRWHU8F8HNBR",
    "assetId": "SOLANA.PFIRE-PFIREKHT5WG7AXMSLBMMRPVYH7CGHX9CRWHU8F8HNBR",
    "assetAddress": "PFireKhT5WG7axMSLBmMRpvYH7cgHx9CRWHU8F8HNbr",
    "assetName": "PFIRE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pfire-pfirekht5wg7axmslbmmrpvyh7cghx9crwhu8f8hnbr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GLEEK-4ACUWNJZJE1Q51589MBMMYFD82RZ4LNFVEUPDSRFPC3L",
    "assetId": "SOLANA.GLEEK-4ACUWNJZJE1Q51589MBMMYFD82RZ4LNFVEUPDSRFPC3L",
    "assetAddress": "4ACuWnJZjE1Q51589mBmmyfD82RZ4LNFVeuPdSRFPc3L",
    "assetName": "GLEEK",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gleek-4acuwnjzje1q51589mbmmyfd82rz4lnfveupdsrfpc3l.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SECROT-7VQZGUK2XBWTEJ2A5YZGKUDMCQLQGASVD9MJMW8VUTTG",
    "assetId": "SOLANA.SECROT-7VQZGUK2XBWTEJ2A5YZGKUDMCQLQGASVD9MJMW8VUTTG",
    "assetAddress": "7VQzGuk2xbWteJ2A5yzGkuDmCQLQGAsvD9MjmW8VutTG",
    "assetName": "SECROT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.secrot-7vqzguk2xbwtej2a5yzgkudmcqlqgasvd9mjmw8vuttg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SDO-7SZUNH7H9KPTYJKUHJ5L4KEE5FFABQVGCHVT7B6WG4XC",
    "assetId": "SOLANA.SDO-7SZUNH7H9KPTYJKUHJ5L4KEE5FFABQVGCHVT7B6WG4XC",
    "assetAddress": "7SZUnH7H9KptyJkUhJ5L4Kee5fFAbqVgCHvt7B6wg4Xc",
    "assetName": "SDO",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sdo-7szunh7h9kptyjkuhj5l4kee5ffabqvgchvt7b6wg4xc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DLP8-9AGM8DWXIB4PWVH1V2R31CHSTE2F2TDYSVDBFJMFAMBQ",
    "assetId": "SOLANA.DLP8-9AGM8DWXIB4PWVH1V2R31CHSTE2F2TDYSVDBFJMFAMBQ",
    "assetAddress": "9AGm8DWXiB4PwvH1V2r31ChsTE2f2TDySvDbfJmfaMBq",
    "assetName": "DLP8",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dlp8-9agm8dwxib4pwvh1v2r31chste2f2tdysvdbfjmfambq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MALL-5EBPXHW7T8YPBF3Q1X7ODFAHJUH7XJFCOHXR3VYAW32I",
    "assetId": "SOLANA.MALL-5EBPXHW7T8YPBF3Q1X7ODFAHJUH7XJFCOHXR3VYAW32I",
    "assetAddress": "5EbpXhW7t8ypBF3Q1X7odFaHjuh7XJfCohXR3VYAW32i",
    "assetName": "MALL",
    "decimals": 3,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mall-5ebpxhw7t8ypbf3q1x7odfahjuh7xjfcohxr3vyaw32i.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHAN-CHANGGUDHBOPSWPTMKDFSTVGQL96VHHMVPWRE4UJWSSD",
    "assetId": "SOLANA.CHAN-CHANGGUDHBOPSWPTMKDFSTVGQL96VHHMVPWRE4UJWSSD",
    "assetAddress": "ChanGGuDHboPswpTmKDfsTVGQL96VHhmvpwrE4UjWssd",
    "assetName": "CHAN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chan-changgudhbopswptmkdfstvgql96vhhmvpwre4ujwssd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$BIAO-3W52UCB8NW8RUMF9MMJX3OKIYAJDPAI4633SRSZFQCS6",
    "assetId": "SOLANA.$BIAO-3W52UCB8NW8RUMF9MMJX3OKIYAJDPAI4633SRSZFQCS6",
    "assetAddress": "3W52uCb8NW8ruMF9mmJX3oKiYAjdPai4633srsZFQCS6",
    "assetName": "$BIAO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.biao-3w52ucb8nw8rumf9mmjx3okiyajdpai4633srszfqcs6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.YOLO-4JE4VUQFWZFMSXJTDD5KUON2QKVU96NPTPJ4MHS5PA1W",
    "assetId": "SOLANA.YOLO-4JE4VUQFWZFMSXJTDD5KUON2QKVU96NPTPJ4MHS5PA1W",
    "assetAddress": "4jE4VuqFWZfmSXjTDD5KUoN2qkVu96nPTPj4mhs5PA1W",
    "assetName": "YOLO",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.yolo-4je4vuqfwzfmsxjtdd5kuon2qkvu96nptpj4mhs5pa1w.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MELL-MELLD8PYFOENW3D5VAUE7L96EZEIHTRZGLWRBKZ5DR2",
    "assetId": "SOLANA.MELL-MELLD8PYFOENW3D5VAUE7L96EZEIHTRZGLWRBKZ5DR2",
    "assetAddress": "MELLd8PyFoeNW3D5VaUe7L96eZeihtrzgLWrbKz5DR2",
    "assetName": "MELL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mell-melld8pyfoenw3d5vaue7l96ezeihtrzglwrbkz5dr2.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$BLEK-EYAJJKB2VDZUYKEWF5TJNGFRC43CMGTTPURTCJWQT35Z",
    "assetId": "SOLANA.$BLEK-EYAJJKB2VDZUYKEWF5TJNGFRC43CMGTTPURTCJWQT35Z",
    "assetAddress": "EYaJJKb2VDZuYKEWf5TjngFRc43cMgttPuRtcJwQt35z",
    "assetName": "$BLEK",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.blek-eyajjkb2vdzuykewf5tjngfrc43cmgttpurtcjwqt35z.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.2080-DWRI1IUY5PDFF2U2GWWSH2MXJR6DATYDV9EN9JK8FKOF",
    "assetId": "SOLANA.2080-DWRI1IUY5PDFF2U2GWWSH2MXJR6DATYDV9EN9JK8FKOF",
    "assetAddress": "Dwri1iuy5pDFf2u2GwwsH2MxjR6dATyDv9En9Jk8Fkof",
    "assetName": "2080",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.2080-dwri1iuy5pdff2u2gwwsh2mxjr6datydv9en9jk8fkof.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$CLOWN-V7NTWK4D9FRWAEC2CUJMRMMSJG28CD31HXDKNDP1QJM",
    "assetId": "SOLANA.$CLOWN-V7NTWK4D9FRWAEC2CUJMRMMSJG28CD31HXDKNDP1QJM",
    "assetAddress": "V7ntWk4D9Frwaec2CUjMRMMsJG28Cd31HxDKNdP1qjM",
    "assetName": "$Clown",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.clown-v7ntwk4d9frwaec2cujmrmmsjg28cd31hxdkndp1qjm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.UBERJEETS-G2SHFTKHAPGY1YUQZZB611COEFPBHYGIRZGGMYW5FEHV",
    "assetId": "SOLANA.UBERJEETS-G2SHFTKHAPGY1YUQZZB611COEFPBHYGIRZGGMYW5FEHV",
    "assetAddress": "G2ShfTkHaPgY1YUqZzB611coeFpbhyGirzgGmyW5fEhV",
    "assetName": "UberJeets",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.uberjeets-g2shftkhapgy1yuqzzb611coefpbhygirzggmyw5fehv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOL100-GKDG1ZFOFKROLAWLQTJNXHXCDG8GMKXHAGXSUZAGYFFE",
    "assetId": "SOLANA.SOL100-GKDG1ZFOFKROLAWLQTJNXHXCDG8GMKXHAGXSUZAGYFFE",
    "assetAddress": "GkDg1ZfoFkroLAwLqtJNXhxCDg8gmKxHAGxSUZagYFfE",
    "assetName": "SOL100",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sol100-gkdg1zfofkrolawlqtjnxhxcdg8gmkxhagxsuzagyffe.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.USDCAV-FHFBA3OV5P3RJAILVGH8FTV4OIRXQDOVXUOUUDVHUXAX",
    "assetId": "SOLANA.USDCAV-FHFBA3OV5P3RJAILVGH8FTV4OIRXQDOVXUOUUDVHUXAX",
    "assetAddress": "FHfba3ov5P3RjaiLVgh8FTv4oirxQDoVXuoUUDvHuXax",
    "assetName": "USDCav",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.usdcav-fhfba3ov5p3rjailvgh8ftv4oirxqdovxuouudvhuxax.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.VSOL-VSOLXYDX6AKXYMD9XECPVGYNGQ6NN66OQVB3UKGKEI7",
    "assetId": "SOLANA.VSOL-VSOLXYDX6AKXYMD9XECPVGYNGQ6NN66OQVB3UKGKEI7",
    "assetAddress": "vSoLxydx6akxyMD9XEcPvGYNGq6Nn66oqVb3UkGkei7",
    "assetName": "vSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.vsol-vsolxydx6akxymd9xecpvgyngq6nn66oqvb3ukgkei7.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EVOL-EVOLSBMQXT6R3B11WJPPGEXYCJW1ZMMIR271XQDBKRSG",
    "assetId": "SOLANA.EVOL-EVOLSBMQXT6R3B11WJPPGEXYCJW1ZMMIR271XQDBKRSG",
    "assetAddress": "EVoLsbmQXT6R3b11WjPpGEXyCjw1zmmir271XqDbKRsg",
    "assetName": "EVOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.evol-evolsbmqxt6r3b11wjppgexycjw1zmmir271xqdbkrsg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GODZ-BB33FYOEBVA2UV119BE9TKVMXEUWTCX1W25N9KFND2CA",
    "assetId": "SOLANA.GODZ-BB33FYOEBVA2UV119BE9TKVMXEUWTCX1W25N9KFND2CA",
    "assetAddress": "BB33fYoeBVA2uv119be9tKvmXeuwtcx1W25N9KFNd2ca",
    "assetName": "GODZ",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.godz-bb33fyoebva2uv119be9tkvmxeuwtcx1w25n9kfnd2ca.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DSOL-DSO1BDEDJCQXTRWHQUUI63OBVV7MDM6WAOBLBQ7GNPQ",
    "assetId": "SOLANA.DSOL-DSO1BDEDJCQXTRWHQUUI63OBVV7MDM6WAOBLBQ7GNPQ",
    "assetAddress": "Dso1bDeDjCQxTrWHqUUi63oBvV7Mdm6WaobLbQ7gnPQ",
    "assetName": "dSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dsol-dso1bdedjcqxtrwhquui63obvv7mdm6waoblbq7gnpq.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DOOGLE-F6TSRCJTLBZKDTZYQJTPVQ9WTNWHMMC1WCQGUEGCPUMP",
    "assetId": "SOLANA.DOOGLE-F6TSRCJTLBZKDTZYQJTPVQ9WTNWHMMC1WCQGUEGCPUMP",
    "assetAddress": "F6TsRcjtLBzkdtZYqjTPVq9WtnwHMMc1WcQguEgCpump",
    "assetName": "Doogle",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.doogle-f6tsrcjtlbzkdtzyqjtpvq9wtnwhmmc1wcqguegcpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SUSDC-9-JEFFSQ3S8T3WKSVP4TNRASUBW7CQGNF8UKBZC4C8XBM1",
    "assetId": "SOLANA.SUSDC-9-JEFFSQ3S8T3WKSVP4TNRASUBW7CQGNF8UKBZC4C8XBM1",
    "assetAddress": "9",
    "assetName": "sUSDC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.susdc-9-jeffsq3s8t3wksvp4tnrasubw7cqgnf8ukbzc4c8xbm1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WTRBR-AYMDF5FP2URJNCSHPEF2NQEQFHIQVVHRDVYQTYZFW7ZN",
    "assetId": "SOLANA.WTRBR-AYMDF5FP2URJNCSHPEF2NQEQFHIQVVHRDVYQTYZFW7ZN",
    "assetAddress": "Aymdf5Fp2URJNcsHpEF2NqEqFHiqvvhrDvYQtyZFw7zn",
    "assetName": "WTRBR",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wtrbr-aymdf5fp2urjncshpef2nqeqfhiqvvhrdvyqtyzfw7zn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NARD-F89DOZEEUE9AJCU6GNR1F5RLAQWCJMDPDNSULUVJUST9",
    "assetId": "SOLANA.NARD-F89DOZEEUE9AJCU6GNR1F5RLAQWCJMDPDNSULUVJUST9",
    "assetAddress": "F89doZeeUe9ajcU6gNR1F5RLaQWcJMdPDNSuLuvJUst9",
    "assetName": "NARD",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nard-f89dozeeue9ajcu6gnr1f5rlaqwcjmdpdnsuluvjust9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JENSEN-HFQUALJMGUH7VYZQQRWUVQPSJQD7TAPRZBWKQA3PDTH3",
    "assetId": "SOLANA.JENSEN-HFQUALJMGUH7VYZQQRWUVQPSJQD7TAPRZBWKQA3PDTH3",
    "assetAddress": "HfQuaLjMguh7vyZqqrWuVqpsjQd7tAPrzBWKqA3pDTH3",
    "assetName": "JENSEN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jensen-hfqualjmguh7vyzqqrwuvqpsjqd7taprzbwkqa3pdth3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DABLNS-DAB15VG2K8ZGJPY4XM2DH2G2BY3KHRQDUXAPZYAV3Y8",
    "assetId": "SOLANA.DABLNS-DAB15VG2K8ZGJPY4XM2DH2G2BY3KHRQDUXAPZYAV3Y8",
    "assetAddress": "dab15vg2k8zGJPy4xM2DH2G2BY3khrqduXapzYAV3y8",
    "assetName": "DABLNS",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dablns-dab15vg2k8zgjpy4xm2dh2g2by3khrqduxapzyav3y8.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MELON-7DGJNYFJRYIP5CKBX6WPBU8F5YA1SWDFOESUCRAKCZZC",
    "assetId": "SOLANA.MELON-7DGJNYFJRYIP5CKBX6WPBU8F5YA1SWDFOESUCRAKCZZC",
    "assetAddress": "7DGJnYfJrYiP5CKBx6wpbu8F5Ya1swdFoesuCrAKCzZc",
    "assetName": "MELON",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.melon-7dgjnyfjryip5ckbx6wpbu8f5ya1swdfoesucrakczzc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DILDO-CDW5FC3FP69KM6KG4XTF5SIFQP3B24WT2SVK9GWG6LUS",
    "assetId": "SOLANA.DILDO-CDW5FC3FP69KM6KG4XTF5SIFQP3B24WT2SVK9GWG6LUS",
    "assetAddress": "CDW5fC3Fp69Km6Kg4xTf5SiFqP3B24wt2SVK9GwG6LUs",
    "assetName": "DILDO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dildo-cdw5fc3fp69km6kg4xtf5sifqp3b24wt2svk9gwg6lus.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLINK-CWE8JPTUYHDCTZYWPTE1O5DFQFDJZWKC9WKZ6RSJQUDG",
    "assetId": "SOLANA.SOLINK-CWE8JPTUYHDCTZYWPTE1O5DFQFDJZWKC9WKZ6RSJQUDG",
    "assetAddress": "CWE8jPTUYhdCTZYWPTe1o5DFqfdjzWKc9WKz6rSjQUdG",
    "assetName": "soLINK",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solink-cwe8jptuyhdctzywpte1o5dfqfdjzwkc9wkz6rsjqudg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DED-7RAHQURZXAQTXFJ2WCMTPH7SQYLEN9447VD4KHZM7TCP",
    "assetId": "SOLANA.DED-7RAHQURZXAQTXFJ2WCMTPH7SQYLEN9447VD4KHZM7TCP",
    "assetAddress": "7raHqUrZXAqtxFJ2wcmtpH7SQYLeN9447vD4KhZM7tcP",
    "assetName": "DED",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ded-7rahqurzxaqtxfj2wcmtph7sqylen9447vd4khzm7tcp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KEKW-CEYNKWUEXU1KD3MN47NAMVHZNPPIMR15SJFV6Y2R1SVW",
    "assetId": "SOLANA.KEKW-CEYNKWUEXU1KD3MN47NAMVHZNPPIMR15SJFV6Y2R1SVW",
    "assetAddress": "CEYNkwuEXU1KD3MN47NaMvHznPPimR15Sjfv6Y2r1SVw",
    "assetName": "KEKW",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.kekw-ceynkwuexu1kd3mn47namvhznppimr15sjfv6y2r1svw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EMMY-8QRC2PF9P24NYJVG1FAGNQJXWKW6H5L5MCXNMFJOUXEV",
    "assetId": "SOLANA.EMMY-8QRC2PF9P24NYJVG1FAGNQJXWKW6H5L5MCXNMFJOUXEV",
    "assetAddress": "8Qrc2pf9p24NyJVG1FagnqJXwKw6h5L5McxnMfJoUxev",
    "assetName": "EMMY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.emmy-8qrc2pf9p24nyjvg1fagnqjxwkw6h5l5mcxnmfjouxev.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BULB-A6JVLMAJR1AECFZ3X2Z4VVQ9GAFYWVT75TC5V5QEFJA2",
    "assetId": "SOLANA.BULB-A6JVLMAJR1AECFZ3X2Z4VVQ9GAFYWVT75TC5V5QEFJA2",
    "assetAddress": "A6JVLMAjR1aeCfz3X2z4vVQ9GafYWVT75tC5V5qefja2",
    "assetName": "BULB",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bulb-a6jvlmajr1aecfz3x2z4vvq9gafywvt75tc5v5qefja2.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PEPECAT-FFHARVGV8WB7EZ6QWENMOUJRZCVPQVDODTTUHRCK4CXI",
    "assetId": "SOLANA.PEPECAT-FFHARVGV8WB7EZ6QWENMOUJRZCVPQVDODTTUHRCK4CXI",
    "assetAddress": "FfhArvgv8WB7eZ6qwENMouJRzcVpQVDoDtTuHrCk4Cxi",
    "assetName": "PEPECAT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pepecat-ffharvgv8wb7ez6qwenmoujrzcvpqvdodttuhrck4cxi.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ORCAT-3IG5NRC36XAVNWKDFKW9HWDJG2BF58YITCJMGUM7HRFJ",
    "assetId": "SOLANA.ORCAT-3IG5NRC36XAVNWKDFKW9HWDJG2BF58YITCJMGUM7HRFJ",
    "assetAddress": "3iG5NRc36xaVnWKdFKw9HwDjG2Bf58YitCJmGUm7hrFJ",
    "assetName": "Orcat",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.orcat-3ig5nrc36xavnwkdfkw9hwdjg2bf58yitcjmgum7hrfj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MMOSH-FWFRWNNVLGYS8UCVJWVYORDFDPTY8W6ACMAXJ4RQGUSS",
    "assetId": "SOLANA.MMOSH-FWFRWNNVLGYS8UCVJWVYORDFDPTY8W6ACMAXJ4RQGUSS",
    "assetAddress": "FwfrwnNVLGyS8ucVjWvyoRdFDpTY8w6ACMAxJ4rqGUSS",
    "assetName": "MMOSH",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mmosh-fwfrwnnvlgys8ucvjwvyordfdpty8w6acmaxj4rqguss.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AEWETH-AAAEW2VCW1XZGVKB8RJ2DYK2ZVAU9FBT2BE8HZFWSMYE",
    "assetId": "SOLANA.AEWETH-AAAEW2VCW1XZGVKB8RJ2DYK2ZVAU9FBT2BE8HZFWSMYE",
    "assetAddress": "AaAEw2VCw1XzgvKB8Rj2DyK2ZVau9fbt2bE8hZFWsMyE",
    "assetName": "aeWETH",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.aeweth-aaaew2vcw1xzgvkb8rj2dyk2zvau9fbt2be8hzfwsmye.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOT-AKHDZGVBJXPUQZ53U2LRIMCJKRP6ZYXG1SOM85T98EE1",
    "assetId": "SOLANA.BOT-AKHDZGVBJXPUQZ53U2LRIMCJKRP6ZYXG1SOM85T98EE1",
    "assetAddress": "AkhdZGVbJXPuQZ53u2LrimCjkRP6ZyxG1SoM85T98eE1",
    "assetName": "BOT",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bot-akhdzgvbjxpuqz53u2lrimcjkrp6zyxg1som85t98ee1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HCOIN-4B619RBCXBXRKTZNVGDSRIUN9WFXWGA1W1OFLVEGACNY",
    "assetId": "SOLANA.HCOIN-4B619RBCXBXRKTZNVGDSRIUN9WFXWGA1W1OFLVEGACNY",
    "assetAddress": "4B619RbcXbXrKTzNVgDSRiUn9wfxWgA1w1oFLveGacNy",
    "assetName": "HCOIN",
    "decimals": 3,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hcoin-4b619rbcxbxrktznvgdsriun9wfxwga1w1oflvegacny.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WSTETH-ZSCHUTTQZUKURTZS43TETKGS2VQKKL8K4QCOUR2N6UO",
    "assetId": "SOLANA.WSTETH-ZSCHUTTQZUKURTZS43TETKGS2VQKKL8K4QCOUR2N6UO",
    "assetAddress": "ZScHuTtqZukUrtZS43teTKGs2VqkKL8k4QCouR2n6Uo",
    "assetName": "wstETH",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wsteth-zschuttqzukurtzs43tetkgs2vqkkl8k4qcour2n6uo.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RACEFI-AAMGOPDFLG6BE82BGZWJVI8K95TJ9TF3VUN7WVTUM2BU",
    "assetId": "SOLANA.RACEFI-AAMGOPDFLG6BE82BGZWJVI8K95TJ9TF3VUN7WVTUM2BU",
    "assetAddress": "AAmGoPDFLG6bE82BgZWjVi8k95tj9Tf3vUN7WvtUm2BU",
    "assetName": "RACEFI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.racefi-aamgopdflg6be82bgzwjvi8k95tj9tf3vun7wvtum2bu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ASTRALIS-ASTRALVKJGK2XK2PAMJMBU5DAV5CEQA6ZPKCP6FZ7BAJ",
    "assetId": "SOLANA.ASTRALIS-ASTRALVKJGK2XK2PAMJMBU5DAV5CEQA6ZPKCP6FZ7BAJ",
    "assetAddress": "ASTRALvKjGK2xk2pamjMBU5dav5cEQa6zpKCP6FZ7BAJ",
    "assetName": "ASTRALIS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.astralis-astralvkjgk2xk2pamjmbu5dav5ceqa6zpkcp6fz7baj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CULTURE-BUAL6QT1CJBFA6WNHNYNQJ572NQPKGR7C5XE4JN3ICBP",
    "assetId": "SOLANA.CULTURE-BUAL6QT1CJBFA6WNHNYNQJ572NQPKGR7C5XE4JN3ICBP",
    "assetAddress": "BuAL6Qt1CJbfa6wnHnYNqj572nQpKGR7C5xe4jn3icBp",
    "assetName": "Culture",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.culture-bual6qt1cjbfa6wnhnynqj572nqpkgr7c5xe4jn3icbp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ILU-GM6SZIBJFB1ZZUXNYF85NXWFCHUGQTQNYE5FDWWFBC7K",
    "assetId": "SOLANA.ILU-GM6SZIBJFB1ZZUXNYF85NXWFCHUGQTQNYE5FDWWFBC7K",
    "assetAddress": "Gm6szibJfB1ZzUxNYf85nXwFchugqTqNyE5fDwWfBc7K",
    "assetName": "ILU",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ilu-gm6szibjfb1zzuxnyf85nxwfchugqtqnye5fdwwfbc7k.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PISS-7QULVSB7XDOKBDDA7WNUUVOTX5YE4VRJ94IPCWIBYQ1F",
    "assetId": "SOLANA.PISS-7QULVSB7XDOKBDDA7WNUUVOTX5YE4VRJ94IPCWIBYQ1F",
    "assetAddress": "7qULVSb7XdoKBDDa7WnuUvoTx5ye4Vrj94iPcwibyQ1F",
    "assetName": "PISS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.piss-7qulvsb7xdokbdda7wnuuvotx5ye4vrj94ipcwibyq1f.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.XALGO-XALGOH1ZUFRMPCRIY94QBFOMXHTK6NDNMKZT4XDVGMS",
    "assetId": "SOLANA.XALGO-XALGOH1ZUFRMPCRIY94QBFOMXHTK6NDNMKZT4XDVGMS",
    "assetAddress": "xALGoH1zUfRmpCriy94qbfoMXHtK6NDnMKzT4Xdvgms",
    "assetName": "xALGO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.xalgo-xalgoh1zufrmpcriy94qbfomxhtk6ndnmkzt4xdvgms.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.STAKESOL-ST8QUJHLPSX3D6HG9UQG9KJ91JFXUGRUWSB1HYYXSND",
    "assetId": "SOLANA.STAKESOL-ST8QUJHLPSX3D6HG9UQG9KJ91JFXUGRUWSB1HYYXSND",
    "assetAddress": "st8QujHLPsX3d6HG9uQg9kJ91jFxUgruwsb1hyYXSNd",
    "assetName": "stakeSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.stakesol-st8qujhlpsx3d6hg9uqg9kj91jfxugruwsb1hyyxsnd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DEADCO-R8EXVDNCDEIW1XXBUSU7MNBLFBG1TMWTVIGJVWNCIQH",
    "assetId": "SOLANA.DEADCO-R8EXVDNCDEIW1XXBUSU7MNBLFBG1TMWTVIGJVWNCIQH",
    "assetAddress": "r8EXVDnCDeiw1xxbUSU7MNbLfbG1tmWTvigjvWNCiqh",
    "assetName": "DEADCO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.deadco-r8exvdncdeiw1xxbusu7mnblfbg1tmwtvigjvwnciqh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TERT-4BBXHREZDJDU3UUH1KM3BWETYIAZSAUTEUGIXN9RMIX9",
    "assetId": "SOLANA.TERT-4BBXHREZDJDU3UUH1KM3BWETYIAZSAUTEUGIXN9RMIX9",
    "assetAddress": "4bBxhRezDJDu3uuh1KM3bWetYiAZSauTeUGixn9rmiX9",
    "assetName": "tert",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tert-4bbxhrezdjdu3uuh1km3bwetyiazsauteugixn9rmix9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.XBM-XBMUUVZKHD6U8GYX6JAKHJGPCA6H7FG28BXAWX2S51P",
    "assetId": "SOLANA.XBM-XBMUUVZKHD6U8GYX6JAKHJGPCA6H7FG28BXAWX2S51P",
    "assetAddress": "XBMuuVZKHd6u8GyX6JakhjgpCA6h7FG28bXaWX2s51P",
    "assetName": "XBM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.xbm-xbmuuvzkhd6u8gyx6jakhjgpca6h7fg28bxawx2s51p.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SATORI-HKSIK3RRAF4JHXN7CF1J7GSLOFX9JO8962XABT1QIFF1",
    "assetId": "SOLANA.SATORI-HKSIK3RRAF4JHXN7CF1J7GSLOFX9JO8962XABT1QIFF1",
    "assetAddress": "HkSiK3rrAf4JHXN7Cf1j7GsLofX9jo8962xabT1qiff1",
    "assetName": "SATORI",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.satori-hksik3rraf4jhxn7cf1j7gslofx9jo8962xabt1qiff1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.UNKN-UNKNXBA1BDG39NUBQVGMNZ5QSZA8PW5HDITGKPE5EFA",
    "assetId": "SOLANA.UNKN-UNKNXBA1BDG39NUBQVGMNZ5QSZA8PW5HDITGKPE5EFA",
    "assetAddress": "unknXbA1bDg39nuBqVgMNZ5qSZa8pw5HditgkPe5eFA",
    "assetName": "UNKN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.unkn-unknxba1bdg39nubqvgmnz5qsza8pw5hditgkpe5efa.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.L-2EMYMETYSWE7XH6QRG1AWAQ3TDVRLKUSPSZ5AJCWCC7B",
    "assetId": "SOLANA.L-2EMYMETYSWE7XH6QRG1AWAQ3TDVRLKUSPSZ5AJCWCC7B",
    "assetAddress": "2emYMetySwE7Xh6qrG1AwAQ3TDvrLkUSpSZ5AjcWCc7B",
    "assetName": "L",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.l-2emymetyswe7xh6qrg1awaq3tdvrlkuspsz5ajcwcc7b.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BASC-DM3Y4R7N1HGHP9AKNT6EX4W1QQTPGQ1TYUJRMDX11FMT",
    "assetId": "SOLANA.BASC-DM3Y4R7N1HGHP9AKNT6EX4W1QQTPGQ1TYUJRMDX11FMT",
    "assetAddress": "DM3Y4R7n1HGhP9AkNT6Ex4w1qQTpgq1TyujrMDX11FMT",
    "assetName": "BASC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.basc-dm3y4r7n1hghp9aknt6ex4w1qqtpgq1tyujrmdx11fmt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FOXES-3RCVCYWXSS8PDRU1HW8FO7XTUCPNUX86W8CKTM6FGWBY",
    "assetId": "SOLANA.FOXES-3RCVCYWXSS8PDRU1HW8FO7XTUCPNUX86W8CKTM6FGWBY",
    "assetAddress": "3RCVCywxSs8pDRU1hw8fo7xTUCpNuX86w8cKTM6fgWBY",
    "assetName": "FOXES",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.foxes-3rcvcywxss8pdru1hw8fo7xtucpnux86w8cktm6fgwby.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHICKS-CXXSHYRVCEPDUDXHE7U62QHVW8UBJOKFIFMZGGGKVC2",
    "assetId": "SOLANA.CHICKS-CXXSHYRVCEPDUDXHE7U62QHVW8UBJOKFIFMZGGGKVC2",
    "assetAddress": "cxxShYRVcepDudXhe7U62QHvw8uBJoKFifmzggGKVC2",
    "assetName": "CHICKS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chicks-cxxshyrvcepdudxhe7u62qhvw8ubjokfifmzgggkvc2.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TRADEBOT-GARPH5BCLZZ4SRH6EXKS6V7DICVANHCWA5IWEE6ABEYR",
    "assetId": "SOLANA.TRADEBOT-GARPH5BCLZZ4SRH6EXKS6V7DICVANHCWA5IWEE6ABEYR",
    "assetAddress": "GaRph5BcLZZ4sRh6EXKS6V7dicVanhcwa5iWEE6AbEYR",
    "assetName": "tradebot",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tradebot-garph5bclzz4srh6exks6v7dicvanhcwa5iwee6abeyr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GIANT-A6YRAK4TOME2QZY7KLNCUGXBRSJCG4FAJAR8UZUBEUEK",
    "assetId": "SOLANA.GIANT-A6YRAK4TOME2QZY7KLNCUGXBRSJCG4FAJAR8UZUBEUEK",
    "assetAddress": "A6YRaK4toMe2qZY7KLNCugXBrsjCG4fAjAR8uZUBeUek",
    "assetName": "GIANT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.giant-a6yrak4tome2qzy7klncugxbrsjcg4fajar8uzubeuek.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WFRAX_V1-8L8PDF3JUTDPDR4M3NP68CL9ZROLACTRQWXI6S9AH5XU",
    "assetId": "SOLANA.WFRAX_V1-8L8PDF3JUTDPDR4M3NP68CL9ZROLACTRQWXI6S9AH5XU",
    "assetAddress": "8L8pDf3jutdpdr4m3np68CL9ZroLActrqwxi6s9Ah5xU",
    "assetName": "wFRAX_v1",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wfrax_v1-8l8pdf3jutdpdr4m3np68cl9zrolactrqwxi6s9ah5xu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JOEVER-5YCBARVYYAU1CW8S1R6TY439RBVVGM7X5DRKXGIGKMZ",
    "assetId": "SOLANA.JOEVER-5YCBARVYYAU1CW8S1R6TY439RBVVGM7X5DRKXGIGKMZ",
    "assetAddress": "5ycBARVYYau1CW8s1r6Ty439rbVvGm7x5DRkxGiGKmZ",
    "assetName": "JOEVER",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.joever-5ycbarvyyau1cw8s1r6ty439rbvvgm7x5drkxgigkmz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ISL-75XRACGNQJPEUEXHKWQU3BBCXMZG6XLDF867TKB1T9E6",
    "assetId": "SOLANA.ISL-75XRACGNQJPEUEXHKWQU3BBCXMZG6XLDF867TKB1T9E6",
    "assetAddress": "75XracgnqjPeuexHKWQU3bBcXMZG6XLDF867tKB1T9e6",
    "assetName": "ISL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.isl-75xracgnqjpeuexhkwqu3bbcxmzg6xldf867tkb1t9e6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GMCAT-83HDSXUNFNHANLGKTDIJ3DT7TP5FH3BB1TV1RBTT7ATZ",
    "assetId": "SOLANA.GMCAT-83HDSXUNFNHANLGKTDIJ3DT7TP5FH3BB1TV1RBTT7ATZ",
    "assetAddress": "83HDsxuNFnhanLgkTdij3dT7tP5FH3bb1TV1rbTT7atz",
    "assetName": "GMCAT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gmcat-83hdsxunfnhanlgktdij3dt7tp5fh3bb1tv1rbtt7atz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FRIES-FRICEBW1V99GWRJRXPNSQ6SU2TABHABNXIZ3VNSVFPPN",
    "assetId": "SOLANA.FRIES-FRICEBW1V99GWRJRXPNSQ6SU2TABHABNXIZ3VNSVFPPN",
    "assetAddress": "FriCEbw1V99GwrJRXPnSQ6su2TabHabNxiZ3VNsVFPPN",
    "assetName": "FRIES",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fries-fricebw1v99gwrjrxpnsq6su2tabhabnxiz3vnsvfppn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ANSOM-BFHKVKMEYJWPXNL36UIM8RNAOMFY8AQNYTJXYU3ZNZTZ",
    "assetId": "SOLANA.ANSOM-BFHKVKMEYJWPXNL36UIM8RNAOMFY8AQNYTJXYU3ZNZTZ",
    "assetAddress": "BfHkvKMEYjwPXnL36uiM8RnAoMFy8aqNyTJXYU3ZnZtz",
    "assetName": "ANSOM",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ansom-bfhkvkmeyjwpxnl36uim8rnaomfy8aqnytjxyu3znztz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WBUSD_V1-AJ1W9A9N9DEMDVYODIAM2RV44GNBM2CSRPDP7XQCAPGX",
    "assetId": "SOLANA.WBUSD_V1-AJ1W9A9N9DEMDVYODIAM2RV44GNBM2CSRPDP7XQCAPGX",
    "assetAddress": "AJ1W9A9N9dEMdVyoDiam2rV44gnBm2csrPDP7xqcapgX",
    "assetName": "wBUSD_v1",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wbusd_v1-aj1w9a9n9demdvyodiam2rv44gnbm2csrpdp7xqcapgx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PROTUGAL-BT2APS5UMYBETHWY6XP5PFGNDW3YKMYXIRY5XXZ7H654",
    "assetId": "SOLANA.PROTUGAL-BT2APS5UMYBETHWY6XP5PFGNDW3YKMYXIRY5XXZ7H654",
    "assetAddress": "BT2apS5umybEthwy6xP5PfgNDw3ykMyxirY5XxZ7H654",
    "assetName": "PROTUGAL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.protugal-bt2aps5umybethwy6xp5pfgndw3ykmyxiry5xxz7h654.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TSUKI-463SK47VKB7UE7XENTHKIVCMTXRSFNE2X4Q9WBYAURVA",
    "assetId": "SOLANA.TSUKI-463SK47VKB7UE7XENTHKIVCMTXRSFNE2X4Q9WBYAURVA",
    "assetAddress": "463SK47VkB7uE7XenTHKiVcMtxRsfNE2X4Q9wByaURVA",
    "assetName": "TSUKI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tsuki-463sk47vkb7ue7xenthkivcmtxrsfne2x4q9wbyaurva.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$RETIRE-HXKBUADFOCGYZ2WRZJPJEFRY8QYNDM5KWIIQ3MZ3TTI1",
    "assetId": "SOLANA.$RETIRE-HXKBUADFOCGYZ2WRZJPJEFRY8QYNDM5KWIIQ3MZ3TTI1",
    "assetAddress": "HXkbUADfocGyz2WrzJpjEfry8qyNDm5Kwiiq3Mz3tTi1",
    "assetName": "$RETIRE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.retire-hxkbuadfocgyz2wrzjpjefry8qyndm5kwiiq3mz3tti1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$MICHA-43MVZHT7GGYEUCG3BGIAUH3N7ZVE73YYGRSC1AUBXLHT",
    "assetId": "SOLANA.$MICHA-43MVZHT7GGYEUCG3BGIAUH3N7ZVE73YYGRSC1AUBXLHT",
    "assetAddress": "43MvZht7GGYEuCg3bgiauh3N7zVe73yyGRSC1aUBXLHT",
    "assetName": "$micha",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.micha-43mvzht7ggyeucg3bgiauh3n7zve73yygrsc1aubxlht.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOARD-4XBEOJFNXRY7ZYUPEFMWWHRGZYN5UQZSAH94DTVBV3B1",
    "assetId": "SOLANA.BOARD-4XBEOJFNXRY7ZYUPEFMWWHRGZYN5UQZSAH94DTVBV3B1",
    "assetAddress": "4xBEoJFNxRY7ZyUPEFmWwHrGzYN5uqzsAH94DTvBv3b1",
    "assetName": "Board",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.board-4xbeojfnxry7zyupefmwwhrgzyn5uqzsah94dtvbv3b1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ESOL-HG35VD8K3BS2PLB3XWC2WQQV8PMPCM3ONRGYP1PEPMCM",
    "assetId": "SOLANA.ESOL-HG35VD8K3BS2PLB3XWC2WQQV8PMPCM3ONRGYP1PEPMCM",
    "assetAddress": "Hg35Vd8K3BS2pLB3xwC2WqQV8pmpCm3oNRGYP1PEpmCM",
    "assetName": "eSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.esol-hg35vd8k3bs2plb3xwc2wqqv8pmpcm3onrgyp1pepmcm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NELSOL-EBPPUYEGSMVGG291MXOXMWUCQW6NR9DEUEFK5LJDEUVE",
    "assetId": "SOLANA.NELSOL-EBPPUYEGSMVGG291MXOXMWUCQW6NR9DEUEFK5LJDEUVE",
    "assetAddress": "EBPpUYEGsmVGG291MXoXmwucqw6nR9dEUEfK5LJdeuve",
    "assetName": "NELSOL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nelsol-ebppuyegsmvgg291mxoxmwucqw6nr9deuefk5ljdeuve.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MIMANY-3RCC6TMYS7ZEA29DXV4G3J5STORS9J1DN98GD42PZTK1",
    "assetId": "SOLANA.MIMANY-3RCC6TMYS7ZEA29DXV4G3J5STORS9J1DN98GD42PZTK1",
    "assetAddress": "3Rcc6tMyS7ZEa29dxV4g3J5StorS9J1dn98gd42pZTk1",
    "assetName": "MIMANY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mimany-3rcc6tmys7zea29dxv4g3j5stors9j1dn98gd42pztk1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.UCIT-HH8BCHOGQD71IULGHP4CUVSU7VSGJOMJDBXVWTFU7MPA",
    "assetId": "SOLANA.UCIT-HH8BCHOGQD71IULGHP4CUVSU7VSGJOMJDBXVWTFU7MPA",
    "assetAddress": "HH8bchogQD71iuLghP4cuvSU7vsGJoMJDBxvWTFu7MpA",
    "assetName": "UCIT",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ucit-hh8bchogqd71iulghp4cuvsu7vsgjomjdbxvwtfu7mpa.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOOSH-6WKTCKUJAFRKV9SZ455NHUNZ34DEWHT1GQKZR5KQ9LJB",
    "assetId": "SOLANA.SOOSH-6WKTCKUJAFRKV9SZ455NHUNZ34DEWHT1GQKZR5KQ9LJB",
    "assetAddress": "6wktcKujaFRKV9Sz455nHUNz34dEWht1gqKzR5KQ9Ljb",
    "assetName": "SOOSH",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.soosh-6wktckujafrkv9sz455nhunz34dewht1gqkzr5kq9ljb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$WNZ-WNZZXM1WQWFH8DPDZSQR6EOHKWXEMX9NLLD2R5RZGPA",
    "assetId": "SOLANA.$WNZ-WNZZXM1WQWFH8DPDZSQR6EOHKWXEMX9NLLD2R5RZGPA",
    "assetAddress": "WNZzxM1WqWFH8DpDZSqr6EoHKWXeMx9NLLd2R5RzGPA",
    "assetName": "$WNZ",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wnz-wnzzxm1wqwfh8dpdzsqr6eohkwxemx9nlld2r5rzgpa.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RENBTC-CDJWUQTCYTVAKXAVXOQZFES5JUFC7OWSEQ7EMQCDSBO5",
    "assetId": "SOLANA.RENBTC-CDJWUQTCYTVAKXAVXOQZFES5JUFC7OWSEQ7EMQCDSBO5",
    "assetAddress": "CDJWUqTcYTVAKXAVXoQZFes5JUFc7owSeq7eMQcDSbo5",
    "assetName": "renBTC",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.renbtc-cdjwuqtcytvakxavxoqzfes5jufc7owseq7emqcdsbo5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BRO-BRO4MUM7ZSWGGGHIOXDMNE8TWIZJKLJYK9ENQQBGEN9X",
    "assetId": "SOLANA.BRO-BRO4MUM7ZSWGGGHIOXDMNE8TWIZJKLJYK9ENQQBGEN9X",
    "assetAddress": "Bro4MuM7ZSWgGGhioxdMne8TwiZjKLJYK9eNQQbGEN9X",
    "assetName": "BRO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bro-bro4mum7zswggghioxdmne8twizjkljyk9enqqbgen9x.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$MARVIN-ELUV4BTJE7NZNLEMSW7FOPTFSIRZEXPNXYUET8CM27SN",
    "assetId": "SOLANA.$MARVIN-ELUV4BTJE7NZNLEMSW7FOPTFSIRZEXPNXYUET8CM27SN",
    "assetAddress": "ELuv4btje7nZNLeMSw7foptfSiRzEXpNxYuet8cM27SN",
    "assetName": "$MARVIN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.marvin-eluv4btje7nznlemsw7foptfsirzexpnxyuet8cm27sn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SBABYDOGE-BABYSOCP6CB95XVBDXNJXKX96VBNC37DMNWUTAV9JK6V",
    "assetId": "SOLANA.SBABYDOGE-BABYSOCP6CB95XVBDXNJXKX96VBNC37DMNWUTAV9JK6V",
    "assetAddress": "BABYsocP6cB95xvBDXnjXKX96VBNC37dmNWUtaV9Jk6v",
    "assetName": "SBABYDOGE",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sbabydoge-babysocp6cb95xvbdxnjxkx96vbnc37dmnwutav9jk6v.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.USDCPO-E2VMBOOTBVCBKMNNXKQGCLMS1X3NOGMAYASUFAASF7M",
    "assetId": "SOLANA.USDCPO-E2VMBOOTBVCBKMNNXKQGCLMS1X3NOGMAYASUFAASF7M",
    "assetAddress": "E2VmbootbVCBkMNNxKQgCLMS1X3NoGMaYAsufaAsf7M",
    "assetName": "USDCpo",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.usdcpo-e2vmbootbvcbkmnnxkqgclms1x3nogmayasufaasf7m.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.YORK-5K1JTWPDSKSVKAL6R2DULPCDAJZXK6SQ2CPXAXDWHVLG",
    "assetId": "SOLANA.YORK-5K1JTWPDSKSVKAL6R2DULPCDAJZXK6SQ2CPXAXDWHVLG",
    "assetAddress": "5K1JtWpdSksVKaL6R2DuLpCDAjzxK6sq2CpXaXDWHVLg",
    "assetName": "YORK",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.york-5k1jtwpdsksvkal6r2dulpcdajzxk6sq2cpxaxdwhvlg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLPAY-ZWQE1ND4EIWYCCQDO4FGCQ7LYZHDSEGKKUDV6RWIAEN",
    "assetId": "SOLANA.SOLPAY-ZWQE1ND4EIWYCCQDO4FGCQ7LYZHDSEGKKUDV6RWIAEN",
    "assetAddress": "zwqe1Nd4eiWyCcqdo4FgCq7LYZHdSeGKKudv6RwiAEn",
    "assetName": "SOLPAY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solpay-zwqe1nd4eiwyccqdo4fgcq7lyzhdsegkkudv6rwiaen.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JUCYSOL-JUCY5XJ76PHVVTPZB5TKRCGQEXKWIT2P5S4VY8UZMPC",
    "assetId": "SOLANA.JUCYSOL-JUCY5XJ76PHVVTPZB5TKRCGQEXKWIT2P5S4VY8UZMPC",
    "assetAddress": "jucy5XJ76pHVvtPZb5TKRcGQExkwit2P5s4vY8UzmpC",
    "assetName": "jucySOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jucysol-jucy5xj76phvvtpzb5tkrcgqexkwit2p5s4vy8uzmpc.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.REMILIO-REMIG7SGAHWGRY7O6SXJW5CYI5A7KMKUTYJZ6X6HUSP",
    "assetId": "SOLANA.REMILIO-REMIG7SGAHWGRY7O6SXJW5CYI5A7KMKUTYJZ6X6HUSP",
    "assetAddress": "remiG7sGaHWgrY7o6SXJW5CYi5A7kmKutyJz6x6hUsp",
    "assetName": "REMILIO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.remilio-remig7sgahwgry7o6sxjw5cyi5a7kmkutyjz6x6husp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BRASIL-HHACS9JJCVEM45FG2ZI3JCWAOMRA3QEZZ22MVVR7ZPSE",
    "assetId": "SOLANA.BRASIL-HHACS9JJCVEM45FG2ZI3JCWAOMRA3QEZZ22MVVR7ZPSE",
    "assetAddress": "HhAcs9JjcveM45FG2Zi3JcWAomra3QezZ22mVvr7zPSE",
    "assetName": "BRASIL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.brasil-hhacs9jjcvem45fg2zi3jcwaomra3qezz22mvvr7zpse.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLI-8JNNWJ46YFDQ8SKGT1LK4G7VWKAA8RHH7LHQGJ6WY41G",
    "assetId": "SOLANA.SOLI-8JNNWJ46YFDQ8SKGT1LK4G7VWKAA8RHH7LHQGJ6WY41G",
    "assetAddress": "8JnNWJ46yfdq8sKgT1Lk4G7VWkAA8Rhh7LhqgJ6WY41G",
    "assetName": "SOLI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.soli-8jnnwj46yfdq8skgt1lk4g7vwkaa8rhh7lhqgj6wy41g.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SLITE-7EJCLYW5KKVZDZKVXS1UKA1WFFJCCOCXJVIT64TYCOWN",
    "assetId": "SOLANA.SLITE-7EJCLYW5KKVZDZKVXS1UKA1WFFJCCOCXJVIT64TYCOWN",
    "assetAddress": "7eJCLyW5KkvzdzkVXs1ukA1WfFjCcocXjVit64tYcown",
    "assetName": "SLITE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.slite-7ejclyw5kkvzdzkvxs1uka1wffjccocxjvit64tycown.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EZB-9ZF6CPFT8MJ5TBWJUR3X5HRKDR7YFNG9QHEX2JKNNYFC",
    "assetId": "SOLANA.EZB-9ZF6CPFT8MJ5TBWJUR3X5HRKDR7YFNG9QHEX2JKNNYFC",
    "assetAddress": "9Zf6CPFt8mJ5TBWJur3x5HrKdr7yfNg9QhEx2jkNNyFc",
    "assetName": "EZB",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ezb-9zf6cpft8mj5tbwjur3x5hrkdr7yfng9qhex2jknnyfc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ROPE-8PMHT4SWUMTBZGHNH5U564N5SJPSIUZ2CJEQZFNNP1FO",
    "assetId": "SOLANA.ROPE-8PMHT4SWUMTBZGHNH5U564N5SJPSIUZ2CJEQZFNNP1FO",
    "assetAddress": "8PMHT4swUMtBzgHnh5U564N5sjPSiUz2cjEQzFnnP1Fo",
    "assetName": "ROPE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rope-8pmht4swumtbzghnh5u564n5sjpsiuz2cjeqzfnnp1fo.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TMOON-7CX1FMABGWGM86XVV73WZSACWTLPPKF39YWE8E6QQ4T6",
    "assetId": "SOLANA.TMOON-7CX1FMABGWGM86XVV73WZSACWTLPPKF39YWE8E6QQ4T6",
    "assetAddress": "7Cx1fMabGWgm86xvv73WzSAcwtLppKf39Ywe8e6Qq4T6",
    "assetName": "TMOON",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tmoon-7cx1fmabgwgm86xvv73wzsacwtlppkf39ywe8e6qq4t6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AAUSDC-8YV9JZ4Z7BUHP68DZ8E8M3TME6NKGPMUKN8KVQRPA6FR",
    "assetId": "SOLANA.AAUSDC-8YV9JZ4Z7BUHP68DZ8E8M3TME6NKGPMUKN8KVQRPA6FR",
    "assetAddress": "8Yv9Jz4z7BUHP68dz8E8m3tMe6NKgpMUKn8KVqrPA6Fr",
    "assetName": "aaUSDC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.aausdc-8yv9jz4z7buhp68dz8e8m3tme6nkgpmukn8kvqrpa6fr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOFTT-AGFEAD2ET2ZJIF9JAGPDMIXQQVW5I81ABDVKE7PHNFZ3",
    "assetId": "SOLANA.SOFTT-AGFEAD2ET2ZJIF9JAGPDMIXQQVW5I81ABDVKE7PHNFZ3",
    "assetAddress": "AGFEad2et2ZJif9jaGpdMixQqvW5i81aBdvKe7PHNfz3",
    "assetName": "soFTT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.softt-agfead2et2zjif9jagpdmixqqvw5i81abdvke7phnfz3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GIL-CYUGNNKPQLQFCHEYGV8WMYPNJQOJA7NZSDJJTS4NUT2J",
    "assetId": "SOLANA.GIL-CYUGNNKPQLQFCHEYGV8WMYPNJQOJA7NZSDJJTS4NUT2J",
    "assetAddress": "CyUgNnKPQLqFcheyGV8wmypnJqojA7NzsdJjTS4nUT2j",
    "assetName": "gil",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gil-cyugnnkpqlqfcheygv8wmypnjqoja7nzsdjjts4nut2j.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$KSH-6J14WYX1AG2PLWVN99EUK4XP2VCZD62VEJV2IWCRYMT8",
    "assetId": "SOLANA.$KSH-6J14WYX1AG2PLWVN99EUK4XP2VCZD62VEJV2IWCRYMT8",
    "assetAddress": "6j14WyX1Ag2pLWvn99euK4xp2VcZD62VeJv2iwCrYmT8",
    "assetName": "$KSH",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ksh-6j14wyx1ag2plwvn99euk4xp2vczd62vejv2iwcrymt8.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PRGC-66EDZNAPEJSXNAK4SCKUUPSSXPBU5DOV57FUCGHAQPSY",
    "assetId": "SOLANA.PRGC-66EDZNAPEJSXNAK4SCKUUPSSXPBU5DOV57FUCGHAQPSY",
    "assetAddress": "66edZnAPEJSxnAK4SckuupssXpbu5doV57FUcghaqPsY",
    "assetName": "PRGC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.prgc-66edznapejsxnak4sckuupssxpbu5dov57fucghaqpsy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$INA-2YD2SUUS3YY4SA7LHHN1PSHKJXJ3XKRARS4CCOG2TGU8",
    "assetId": "SOLANA.$INA-2YD2SUUS3YY4SA7LHHN1PSHKJXJ3XKRARS4CCOG2TGU8",
    "assetAddress": "2yd2Suus3YY4Sa7LHhn1PSHkjXj3XKrars4cCog2tGU8",
    "assetName": "$INA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ina-2yd2suus3yy4sa7lhhn1pshkjxj3xkrars4ccog2tgu8.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.REWD-2EU1K3WVFPC7GVJ1CK8OHV4GGUSDN6QXYXPJHYTCKJZT",
    "assetId": "SOLANA.REWD-2EU1K3WVFPC7GVJ1CK8OHV4GGUSDN6QXYXPJHYTCKJZT",
    "assetAddress": "2eu1K3wvfPC7gVj1CK8ohv4ggusdN6qxyxpjHyTCkjZT",
    "assetName": "REWD",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rewd-2eu1k3wvfpc7gvj1ck8ohv4ggusdn6qxyxpjhytckjzt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EPCT-CVB1ZTJVPYQPVDPBEPTRZJL4AQIDJYDTUZ61NWGCGQTP",
    "assetId": "SOLANA.EPCT-CVB1ZTJVPYQPVDPBEPTRZJL4AQIDJYDTUZ61NWGCGQTP",
    "assetAddress": "CvB1ztJvpYQPvdPBePtRzjL4aQidjydtUz61NWgcgQtP",
    "assetName": "EPCT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.epct-cvb1ztjvpyqpvdpbeptrzjl4aqidjydtuz61nwgcgqtp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SUPERSOL-SUPER8CPWXOJPQ7ZKSGMWFVJBQHJAHWUMMPV4FVATBW",
    "assetId": "SOLANA.SUPERSOL-SUPER8CPWXOJPQ7ZKSGMWFVJBQHJAHWUMMPV4FVATBW",
    "assetAddress": "suPer8CPwxoJPQ7zksGMwFvjBQhjAHwUMmPV4FVatBw",
    "assetName": "superSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.supersol-super8cpwxojpq7zksgmwfvjbqhjahwummpv4fvatbw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ENRX-5S4BYUXLUVS9ZCVDTXKTPKHTHWFSPAU8GG55Q2IYSE2N",
    "assetId": "SOLANA.ENRX-5S4BYUXLUVS9ZCVDTXKTPKHTHWFSPAU8GG55Q2IYSE2N",
    "assetAddress": "5s4BYUXLuvs9ZcVDTxkTpKhThWFSpaU8GG55q2iySe2N",
    "assetName": "ENRX",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.enrx-5s4byuxluvs9zcvdtxktpkhthwfspau8gg55q2iyse2n.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OPOS-BQVHWPWUDGMIK5GBTCIFFOZADPE2OZTH5BXCDRGBDT52",
    "assetId": "SOLANA.OPOS-BQVHWPWUDGMIK5GBTCIFFOZADPE2OZTH5BXCDRGBDT52",
    "assetAddress": "BqVHWpwUDgMik5gbTciFfozadpE2oZth5bxCDrgbDt52",
    "assetName": "OPOS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.opos-bqvhwpwudgmik5gbtciffozadpe2ozth5bxcdrgbdt52.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOXXY-EZYYFVUE2AEPSXBTDDKKQ5RWPN7BXHYZQPHZ2GF4Y24R",
    "assetId": "SOLANA.BOXXY-EZYYFVUE2AEPSXBTDDKKQ5RWPN7BXHYZQPHZ2GF4Y24R",
    "assetAddress": "EzYyFvUE2AepSxbtdDkkq5rWpN7bXhYzQphZ2gF4Y24R",
    "assetName": "BOXXY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.boxxy-ezyyfvue2aepsxbtddkkq5rwpn7bxhyzqphz2gf4y24r.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MUTANT-MMONECHKLNDUXDBVP9YNTWO459QFUMQDDZV95DNVNR6",
    "assetId": "SOLANA.MUTANT-MMONECHKLNDUXDBVP9YNTWO459QFUMQDDZV95DNVNR6",
    "assetAddress": "mmonechkLNdUxDbvP9Yntwo459QfUmqDdzV95DNvnr6",
    "assetName": "MUTANT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mutant-mmonechklnduxdbvp9yntwo459qfumqddzv95dnvnr6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BARRTREMP-2CMXMFB2NSNDW351TFZOAWPN1DNDUVRH4WN6FXH2EJT6",
    "assetId": "SOLANA.BARRTREMP-2CMXMFB2NSNDW351TFZOAWPN1DNDUVRH4WN6FXH2EJT6",
    "assetAddress": "2CMxmFb2nsNdw351TfZoawpN1DnDuVrH4Wn6fxH2EJT6",
    "assetName": "BarrTremp",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.barrtremp-2cmxmfb2nsndw351tfzoawpn1dnduvrh4wn6fxh2ejt6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$VIDEOGAME-CD35ZSEE8ATGY14Q47MGN4N7XA2VPJG57YGTAXL7PUMP",
    "assetId": "SOLANA.$VIDEOGAME-CD35ZSEE8ATGY14Q47MGN4N7XA2VPJG57YGTAXL7PUMP",
    "assetAddress": "Cd35Zsee8atgY14q47mgN4N7xa2VpJG57ygtAXL7pump",
    "assetName": "$VIDEOGAME",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.videogame-cd35zsee8atgy14q47mgn4n7xa2vpjg57ygtaxl7pump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.COMFY-FBJPD8YHRGGKWVL1UJF7QFVTW4UD4675K8CYK82LEKVZ",
    "assetId": "SOLANA.COMFY-FBJPD8YHRGGKWVL1UJF7QFVTW4UD4675K8CYK82LEKVZ",
    "assetAddress": "FbJpd8yhrGGkWVL1Ujf7qFvTw4uD4675k8CYk82LEKvZ",
    "assetName": "COMFY",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.comfy-fbjpd8yhrggkwvl1ujf7qfvtw4ud4675k8cyk82lekvz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MUGI-DC7MZCE1AG8RNJUGD3ZDIRPCGJUYKSYBXBA1OXWQJLZ4",
    "assetId": "SOLANA.MUGI-DC7MZCE1AG8RNJUGD3ZDIRPCGJUYKSYBXBA1OXWQJLZ4",
    "assetAddress": "Dc7mzCE1aG8rNJUgD3zDiRPcgJUYKSybxBA1oXWqjLz4",
    "assetName": "MUGI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mugi-dc7mzce1ag8rnjugd3zdirpcgjuyksybxba1oxwqjlz4.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NONCE-3WDQAHLUAAOTFAJEJXPJPNMIO3LBHSTSCHA8XQ5UWLO2",
    "assetId": "SOLANA.NONCE-3WDQAHLUAAOTFAJEJXPJPNMIO3LBHSTSCHA8XQ5UWLO2",
    "assetAddress": "3wDQAHLuaaotFajeJXpJpNMio3LBhSTscHa8Xq5UwLo2",
    "assetName": "NONCE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nonce-3wdqahluaaotfajejxpjpnmio3lbhstscha8xq5uwlo2.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WXM-WXMJYE17A2OGJZJ1WDE6ZYRKUKMRLJ2PJSAVEDTVHPP",
    "assetId": "SOLANA.WXM-WXMJYE17A2OGJZJ1WDE6ZYRKUKMRLJ2PJSAVEDTVHPP",
    "assetAddress": "wxmJYe17a2oGJZJ1wDe6ZyRKUKmrLj2pJsavEdTVhPP",
    "assetName": "WXM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wxm-wxmjye17a2ogjzj1wde6zyrkukmrlj2pjsavedtvhpp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOZOHYBRID-EJPTJEDOGXZDBVM8QVASQYBLMPJ5N1VQEQOAZJ9YFV3Q",
    "assetId": "SOLANA.BOZOHYBRID-EJPTJEDOGXZDBVM8QVASQYBLMPJ5N1VQEQOAZJ9YFV3Q",
    "assetAddress": "EJPtJEDogxzDbvM8qvAsqYbLmPj5n1vQeqoAzj9Yfv3q",
    "assetName": "bozoHYBRID",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bozohybrid-ejptjedogxzdbvm8qvasqyblmpj5n1vqeqoazj9yfv3q.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EGIRL-ART4N4WY4PEDYUUBG7QENWUYSSIQUQP1RXFIAHHWFZH9",
    "assetId": "SOLANA.EGIRL-ART4N4WY4PEDYUUBG7QENWUYSSIQUQP1RXFIAHHWFZH9",
    "assetAddress": "ARt4N4WY4PEdYUuBG7qENwuYSSiQUqP1RXFiahhwfzH9",
    "assetName": "EGIRL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.egirl-art4n4wy4pedyuubg7qenwuyssiquqp1rxfiahhwfzh9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PORTNOY-8WZYFQEQKJBWYBHMACBVEN8TSUJQXIDTSCGMJNUJDSKM",
    "assetId": "SOLANA.PORTNOY-8WZYFQEQKJBWYBHMACBVEN8TSUJQXIDTSCGMJNUJDSKM",
    "assetAddress": "8wzYfqeqkjBwYBHMacBVen8tSuJqXiDtsCgmjnUJDSKM",
    "assetName": "PORTNOY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.portnoy-8wzyfqeqkjbwybhmacbven8tsujqxidtscgmjnujdskm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HELIA-FZNSMD1HPU5MVGTTMTFDZUPN5GCUEXVRH3CB67UUQSHB",
    "assetId": "SOLANA.HELIA-FZNSMD1HPU5MVGTTMTFDZUPN5GCUEXVRH3CB67UUQSHB",
    "assetAddress": "FZnSMd1hPu5MVgttmtfDZUPN5gcuexvrh3CB67UuQshb",
    "assetName": "HELIA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.helia-fznsmd1hpu5mvgttmtfdzupn5gcuexvrh3cb67uuqshb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DEDS-BVEJ2MNMPSURD4VSK7NHS4TTRCCCJD75WX5HVVBY4RBK",
    "assetId": "SOLANA.DEDS-BVEJ2MNMPSURD4VSK7NHS4TTRCCCJD75WX5HVVBY4RBK",
    "assetAddress": "BvEj2MNMPsUrD4vSk7NHs4TtRcCcJd75Wx5HvVbY4rbK",
    "assetName": "DEDS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.deds-bvej2mnmpsurd4vsk7nhs4ttrcccjd75wx5hvvby4rbk.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PIPI-BZKUDQZD4RB2PUUV2CBSND2KZZJ5JZKIQKWBZCK8HRLU",
    "assetId": "SOLANA.PIPI-BZKUDQZD4RB2PUUV2CBSND2KZZJ5JZKIQKWBZCK8HRLU",
    "assetAddress": "BZKuDqzD4rb2puUV2cbSNd2kZzJ5JzKiQKwBzCk8hrLu",
    "assetName": "pipi",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pipi-bzkudqzd4rb2puuv2cbsnd2kzzj5jzkiqkwbzck8hrlu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GCR-7DGBPGUXKPB5QWILRKCTQSC3OM1FPZUPGGAFFWEJ9HXX",
    "assetId": "SOLANA.GCR-7DGBPGUXKPB5QWILRKCTQSC3OM1FPZUPGGAFFWEJ9HXX",
    "assetAddress": "7dGbPgUxKpB5qWiLRKcTQSC3om1fPzUpgGAFfwej9hXx",
    "assetName": "GCR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gcr-7dgbpguxkpb5qwilrkctqsc3om1fpzupggaffwej9hxx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BEKELE-4PPMX85G5T6M8HOKVDSBRDGBMKXXRG1VEFCQ7MVJJMKM",
    "assetId": "SOLANA.BEKELE-4PPMX85G5T6M8HOKVDSBRDGBMKXXRG1VEFCQ7MVJJMKM",
    "assetAddress": "4pPMX85G5t6M8hoKvdsbRDGbMkXxRg1vefcQ7MVjjMKM",
    "assetName": "Bekele",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bekele-4ppmx85g5t6m8hokvdsbrdgbmkxxrg1vefcq7mvjjmkm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.USDTBS-8QJSYQPRMC57TWKAYEMETUR3UUITP2M3HXDCVFHKZDMV",
    "assetId": "SOLANA.USDTBS-8QJSYQPRMC57TWKAYEMETUR3UUITP2M3HXDCVFHKZDMV",
    "assetAddress": "8qJSyQprMC57TWKaYEmetUR3UUiTP2M3hXdcvFhkZdmv",
    "assetName": "USDTbs",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.usdtbs-8qjsyqprmc57twkayemetur3uuitp2m3hxdcvfhkzdmv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DRIPSPLIT-UL2QHMCKUAROJPT2MLHWEEPPJNYE3WBAGFMCS3ANWXN",
    "assetId": "SOLANA.DRIPSPLIT-UL2QHMCKUAROJPT2MLHWEEPPJNYE3WBAGFMCS3ANWXN",
    "assetAddress": "uL2qhMckUAroJPt2MLHwEeppJNYE3wBAGFMCs3anwXn",
    "assetName": "DRIPSPLIT",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dripsplit-ul2qhmckuarojpt2mlhweeppjnye3wbagfmcs3anwxn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MACHO-8ZFOVNZXZK9JDIFTGAW7WIRXARRRTVM9LZ12VJ8CZ5ZA",
    "assetId": "SOLANA.MACHO-8ZFOVNZXZK9JDIFTGAW7WIRXARRRTVM9LZ12VJ8CZ5ZA",
    "assetAddress": "8zFovnzXzK9JDiftGaw7wiRxARrRtvm9Lz12vJ8CZ5ZA",
    "assetName": "macho",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.macho-8zfovnzxzk9jdiftgaw7wirxarrrtvm9lz12vj8cz5za.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SCUM-5CVJ5REEOCG5WVH3OJUY6MOYJ7GVZD8AOXSLZJDY6W4W",
    "assetId": "SOLANA.SCUM-5CVJ5REEOCG5WVH3OJUY6MOYJ7GVZD8AOXSLZJDY6W4W",
    "assetAddress": "5cvj5rEEocG5Wvh3oJuY6MoYj7gVZd8aoXSLZjDY6W4W",
    "assetName": "SCUM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.scum-5cvj5reeocg5wvh3ojuy6moyj7gvzd8aoxslzjdy6w4w.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DONK-XABFKIG2KCHI6KETELYCW1IK7B52WJMCHSWXU3YRSDP",
    "assetId": "SOLANA.DONK-XABFKIG2KCHI6KETELYCW1IK7B52WJMCHSWXU3YRSDP",
    "assetAddress": "xABfKiG2KCHi6keTeLycW1iK7B52wJmchSWXu3YrsDp",
    "assetName": "DONK",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.donk-xabfkig2kchi6ketelycw1ik7b52wjmchswxu3yrsdp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GRAY-6XFB1VVAXJTFKNF1KOTGXLUQ4P87XTDP3CCYC6Q2VHNG",
    "assetId": "SOLANA.GRAY-6XFB1VVAXJTFKNF1KOTGXLUQ4P87XTDP3CCYC6Q2VHNG",
    "assetAddress": "6XfB1VVAxjtfKnF1kotGxLUq4p87xTDp3cCyC6Q2VHNG",
    "assetName": "GRAY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gray-6xfb1vvaxjtfknf1kotgxluq4p87xtdp3ccyc6q2vhng.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DOBI-GZQZKT2B4JR6WHWVBF7XQKZWVOUY1JED5Z9TCZZGG1RH",
    "assetId": "SOLANA.DOBI-GZQZKT2B4JR6WHWVBF7XQKZWVOUY1JED5Z9TCZZGG1RH",
    "assetAddress": "GzQzkt2B4Jr6whWVBF7XqkzWvoUy1jEd5z9tczzGg1rH",
    "assetName": "DOBI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dobi-gzqzkt2b4jr6whwvbf7xqkzwvouy1jed5z9tczzgg1rh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BRETTGOLD-F6EXBZKDLRCJKCAKNQGFBHRXX78EHQONXPNEGJWPPUMP",
    "assetId": "SOLANA.BRETTGOLD-F6EXBZKDLRCJKCAKNQGFBHRXX78EHQONXPNEGJWPPUMP",
    "assetAddress": "F6ExBzKdLRcJkCAknQgfbhRXX78EhqoNxPnegJWPpump",
    "assetName": "BRETTGOLD",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.brettgold-f6exbzkdlrcjkcaknqgfbhrxx78ehqonxpnegjwppump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FCAT-CCFFGMU9JODK6S5FSZM1VYGF3UNNG7FZVTMPGZVU1SCF",
    "assetId": "SOLANA.FCAT-CCFFGMU9JODK6S5FSZM1VYGF3UNNG7FZVTMPGZVU1SCF",
    "assetAddress": "CcFfGMU9Jodk6s5fSZM1vYGF3UNng7fZvTmPgzvU1ScF",
    "assetName": "FCAT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fcat-ccffgmu9jodk6s5fszm1vygf3unng7fzvtmpgzvu1scf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SMOLCAT-AJD5BI9WK4GUPVF9XEFTFRZQRAQVTHERLXETDHV5QEHU",
    "assetId": "SOLANA.SMOLCAT-AJD5BI9WK4GUPVF9XEFTFRZQRAQVTHERLXETDHV5QEHU",
    "assetAddress": "AJd5bi9wK4GupVf9XeftfrzQraQVthErLxEtdHv5qEHU",
    "assetName": "SMOLCAT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.smolcat-ajd5bi9wk4gupvf9xeftfrzqraqvtherlxetdhv5qehu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.INBRED-EJZZYCSILQJFDPRPZJ8E1ZJXMCTG5HPGFRSEOWCJWHH9",
    "assetId": "SOLANA.INBRED-EJZZYCSILQJFDPRPZJ8E1ZJXMCTG5HPGFRSEOWCJWHH9",
    "assetAddress": "EjzzyCSiLqjFDprpZj8e1zjXmcTG5HPGFRSEoWcJWHh9",
    "assetName": "INBRED",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.inbred-ejzzycsilqjfdprpzj8e1zjxmctg5hpgfrseowcjwhh9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BSLSK-EMAB1CEXVWRMEVIYYIK8941V7SGWIXSYHMREBCSWGGPW",
    "assetId": "SOLANA.BSLSK-EMAB1CEXVWRMEVIYYIK8941V7SGWIXSYHMREBCSWGGPW",
    "assetAddress": "EMAb1cexvwrMeViyYiK8941V7SGWiXsyhMREBcswggPw",
    "assetName": "BSLSK",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bslsk-emab1cexvwrmeviyyik8941v7sgwixsyhmrebcswggpw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OPPIE-GWYXEDNWBRHGT2K6IPUSBTADERA7TBGQSJJYZAODY2MV",
    "assetId": "SOLANA.OPPIE-GWYXEDNWBRHGT2K6IPUSBTADERA7TBGQSJJYZAODY2MV",
    "assetAddress": "GwyxednWbrhgT2K6iPUsbtadErA7TBGqsJjyzAody2mv",
    "assetName": "OPPIE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.oppie-gwyxednwbrhgt2k6ipusbtadera7tbgqsjjyzaody2mv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ASOL-ASOLXBFE7CD6IGH5YIESU8M7FW64QRXPKKXK7SJAFOND",
    "assetId": "SOLANA.ASOL-ASOLXBFE7CD6IGH5YIESU8M7FW64QRXPKKXK7SJAFOND",
    "assetAddress": "ASoLXbfe7cd6igh5yiEsU8M7FW64QRxPKkxk7sjAfond",
    "assetName": "aSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.asol-asolxbfe7cd6igh5yiesu8m7fw64qrxpkkxk7sjafond.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SHORK-BJB5THWAHBOMAWTI5AHBCNX17F3JL8EHKH4ZUTUX99ZN",
    "assetId": "SOLANA.SHORK-BJB5THWAHBOMAWTI5AHBCNX17F3JL8EHKH4ZUTUX99ZN",
    "assetAddress": "BJB5tHWAHboMAwti5AHbCNX17F3jL8ehkh4zuTUx99Zn",
    "assetName": "shork",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.shork-bjb5thwahbomawti5ahbcnx17f3jl8ehkh4zutux99zn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CROWS-6GSMBXSFGJRQEGBYWUKGUSBSHHBDLJCCLWQZIVVPEG3I",
    "assetId": "SOLANA.CROWS-6GSMBXSFGJRQEGBYWUKGUSBSHHBDLJCCLWQZIVVPEG3I",
    "assetAddress": "6gSmbxSfgJrqeGBYwuKGuSbShhbDLjcCLWqzivvPeG3i",
    "assetName": "CROWS",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.crows-6gsmbxsfgjrqegbywukgusbshhbdljcclwqzivvpeg3i.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OGT-DHBCBJVWGTHPIV4WNZ9AAJVNKEWKRDU2MMJXYJQJ847T",
    "assetId": "SOLANA.OGT-DHBCBJVWGTHPIV4WNZ9AAJVNKEWKRDU2MMJXYJQJ847T",
    "assetAddress": "DHbCBJVWGTHpiV4WNZ9AajvNkEWKRdu2mmJXYjQj847t",
    "assetName": "OGT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ogt-dhbcbjvwgthpiv4wnz9aajvnkewkrdu2mmjxyjqj847t.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TTRIAD-T3DOHMSWHKK94PPBPYWA6ZKACYY3Y5KBCQEQERAJJMV",
    "assetId": "SOLANA.TTRIAD-T3DOHMSWHKK94PPBPYWA6ZKACYY3Y5KBCQEQERAJJMV",
    "assetAddress": "t3DohmswhKk94PPbPYwA6ZKACyY3y5kbcqeQerAJjmV",
    "assetName": "tTRIAD",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ttriad-t3dohmswhkk94ppbpywa6zkacyy3y5kbcqeqerajjmv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PEPE-3TS6FBLH2P8TZXXUQCIHZPZHSXJPMRR3XB9PSMYPNP69",
    "assetId": "SOLANA.PEPE-3TS6FBLH2P8TZXXUQCIHZPZHSXJPMRR3XB9PSMYPNP69",
    "assetAddress": "3tS6fbLh2P8tzxXuqCiHZpZhsxJpmrR3Xb9psmypnp69",
    "assetName": "pepe",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pepe-3ts6fblh2p8tzxxuqcihzpzhsxjpmrr3xb9psmypnp69.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NESTA-CZT7FC4DZ6BPLH2VKISYYOTNK2UPPDHVBWRRLED9QXHV",
    "assetId": "SOLANA.NESTA-CZT7FC4DZ6BPLH2VKISYYOTNK2UPPDHVBWRRLED9QXHV",
    "assetAddress": "Czt7Fc4dz6BpLh2vKiSYyotNK2uPPDhvbWrrLeD9QxhV",
    "assetName": "NESTA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nesta-czt7fc4dz6bplh2vkisyyotnk2uppdhvbwrrled9qxhv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BARRON-HMAGIWJJP9CXQK5WQNSHKTJAT2CH3KV8Q7XH5KGL2NQZ",
    "assetId": "SOLANA.BARRON-HMAGIWJJP9CXQK5WQNSHKTJAT2CH3KV8Q7XH5KGL2NQZ",
    "assetAddress": "HmAgiwjjP9CXqK5wQNsHKtjAt2CH3Kv8Q7xH5kGL2nqZ",
    "assetName": "Barron",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.barron-hmagiwjjp9cxqk5wqnshktjat2ch3kv8q7xh5kgl2nqz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.XETH-8BQJZ8DESUIM1SEASQATJJN4ZSEYXSPDHHQCUUHL8PCK",
    "assetId": "SOLANA.XETH-8BQJZ8DESUIM1SEASQATJJN4ZSEYXSPDHHQCUUHL8PCK",
    "assetAddress": "8bqjz8DeSuim1sEAsQatjJN4zseyxSPdhHQcuuhL8PCK",
    "assetName": "xETH",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.xeth-8bqjz8desuim1seasqatjjn4zseyxspdhhqcuuhl8pck.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HUNTBODEN-22513U2QWIY6XAJN7NVFWGKY3ABDW6WFZSRPW2RRTCKJ",
    "assetId": "SOLANA.HUNTBODEN-22513U2QWIY6XAJN7NVFWGKY3ABDW6WFZSRPW2RRTCKJ",
    "assetAddress": "22513u2QwiY6xaJn7nVFWGKy3aBdw6WfZsRPW2RRtCKj",
    "assetName": "HuntBoden",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.huntboden-22513u2qwiy6xajn7nvfwgky3abdw6wfzsrpw2rrtckj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CICADA-HDNUZYXVD64AFCDTI3ASMTWNSSP9TDRRS16UAQOJP9XS",
    "assetId": "SOLANA.CICADA-HDNUZYXVD64AFCDTI3ASMTWNSSP9TDRRS16UAQOJP9XS",
    "assetAddress": "HdnUzyXVD64afcdti3asmtWnsSP9TDrRs16UAqoJp9xS",
    "assetName": "CICADA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cicada-hdnuzyxvd64afcdti3asmtwnssp9tdrrs16uaqojp9xs.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SRLY-SRLY3MIGNRKC1HLGQOTPVI66QGKDNEDQPZ9TJPAQHYH",
    "assetId": "SOLANA.SRLY-SRLY3MIGNRKC1HLGQOTPVI66QGKDNEDQPZ9TJPAQHYH",
    "assetAddress": "sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh",
    "assetName": "sRLY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.srly-srly3mignrkc1hlgqotpvi66qgkdnedqpz9tjpaqhyh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SENDER-9YOTBA6U4G2ZZOUAJKYS8FNTAFUWVRFYLN2Q4PSRFNIK",
    "assetId": "SOLANA.SENDER-9YOTBA6U4G2ZZOUAJKYS8FNTAFUWVRFYLN2Q4PSRFNIK",
    "assetAddress": "9yotbA6u4g2ZZoUajkyS8FntaFUwVRFyLn2Q4PSrfNik",
    "assetName": "SENDER",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sender-9yotba6u4g2zzouajkys8fntafuwvrfyln2q4psrfnik.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CTKOL-FYR3BF5PATZLCQ6ODQ7IZFYJOM1UOS96WFTVWDWSJYWQ",
    "assetId": "SOLANA.CTKOL-FYR3BF5PATZLCQ6ODQ7IZFYJOM1UOS96WFTVWDWSJYWQ",
    "assetAddress": "Fyr3bF5PatZLcq6odQ7izFyJom1uoS96WftVWdWsJYwq",
    "assetName": "CTKOL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ctkol-fyr3bf5patzlcq6odq7izfyjom1uos96wftvwdwsjywq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RIBBET-32GAR4RN9JYZODVWMZZ5J3NGCHC5RQHMSJBY55FFKNQ3",
    "assetId": "SOLANA.RIBBET-32GAR4RN9JYZODVWMZZ5J3NGCHC5RQHMSJBY55FFKNQ3",
    "assetAddress": "32gaR4rn9JyzoDVwMzZ5j3NgcHc5RQhMSJby55FFKnq3",
    "assetName": "RIBBET",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ribbet-32gar4rn9jyzodvwmzz5j3ngchc5rqhmsjby55ffknq3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$GARY-8C71AVJQEKKEWRE8JTTGG1BJ2WIYXQDBJQFBUFHHGSVK",
    "assetId": "SOLANA.$GARY-8C71AVJQEKKEWRE8JTTGG1BJ2WIYXQDBJQFBUFHHGSVK",
    "assetAddress": "8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk",
    "assetName": "$GARY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gary-8c71avjqekkewre8jttgg1bj2wiyxqdbjqfbufhhgsvk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CLSUI-JZWFZVJGDSQBRKZQUVZJPWHBHCZUIX7CYCCAOINPJXG",
    "assetId": "SOLANA.CLSUI-JZWFZVJGDSQBRKZQUVZJPWHBHCZUIX7CYCCAOINPJXG",
    "assetAddress": "JzwfZvJGdsqbrKZQUvzJpWhbHcZUix7CYcCaoiNpjxg",
    "assetName": "clSUI",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.clsui-jzwfzvjgdsqbrkzquvzjpwhbhczuix7cyccaoinpjxg.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PINGU-FFJRFW9PHXYYPQC9FYYQ5URV3K6943WZO3T9A1L4VFOW",
    "assetId": "SOLANA.PINGU-FFJRFW9PHXYYPQC9FYYQ5URV3K6943WZO3T9A1L4VFOW",
    "assetAddress": "Ffjrfw9phxYYpQc9fyYq5uRV3K6943Wzo3t9a1L4vfoW",
    "assetName": "PINGU",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pingu-ffjrfw9phxyypqc9fyyq5urv3k6943wzo3t9a1l4vfow.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PUMPKINSOL-PUMPKINSEQ8XENVZE6QGTS93EN4R9IKVNXNALS1OOYP",
    "assetId": "SOLANA.PUMPKINSOL-PUMPKINSEQ8XENVZE6QGTS93EN4R9IKVNXNALS1OOYP",
    "assetAddress": "pumpkinsEq8xENVZE6QgTS93EN4r9iKvNxNALS1ooyp",
    "assetName": "pumpkinSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pumpkinsol-pumpkinseq8xenvze6qgts93en4r9ikvnxnals1ooyp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CIGGS-7P6RJGNZ7HLHPFTO6NH21XYW4CZGXXLQPZKXG72PND2Y",
    "assetId": "SOLANA.CIGGS-7P6RJGNZ7HLHPFTO6NH21XYW4CZGXXLQPZKXG72PND2Y",
    "assetAddress": "7p6RjGNZ7HLHpfTo6nh21XYw4CZgxXLQPzKXG72pNd2y",
    "assetName": "CIGGS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ciggs-7p6rjgnz7hlhpfto6nh21xyw4czgxxlqpzkxg72pnd2y.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JORDAN-HKMESDTMRM9WXUEHGJBU3OHYIJ4EWB3YTEUURM5QEJFZ",
    "assetId": "SOLANA.JORDAN-HKMESDTMRM9WXUEHGJBU3OHYIJ4EWB3YTEUURM5QEJFZ",
    "assetAddress": "HKmEsdtmRm9WXuEHgJbU3oHyij4EwB3YtEuUrm5qEJFZ",
    "assetName": "JORDAN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jordan-hkmesdtmrm9wxuehgjbu3ohyij4ewb3yteuurm5qejfz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DAPE-6AARZPV8KWMPBXBEZDRMD3G1Q2TUBASGTNQ5E621QCZQ",
    "assetId": "SOLANA.DAPE-6AARZPV8KWMPBXBEZDRMD3G1Q2TUBASGTNQ5E621QCZQ",
    "assetAddress": "6AarZpv8KwmPBxBEZdRmd3g1q2tUBaSgTNQ5e621qcZQ",
    "assetName": "DAPE",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dape-6aarzpv8kwmpbxbezdrmd3g1q2tubasgtnq5e621qczq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FLTH-FLTHUDK5B5ZAG7JMGXQRYRFFEY6OTEVLQA6JM1UHHLEE",
    "assetId": "SOLANA.FLTH-FLTHUDK5B5ZAG7JMGXQRYRFFEY6OTEVLQA6JM1UHHLEE",
    "assetAddress": "FLTHudk5B5zag7JmGXqrYrFfey6otevLQA6jm1UHHLEE",
    "assetName": "FLTH",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.flth-flthudk5b5zag7jmgxqryrffey6otevlqa6jm1uhhlee.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AADAI-EGQ3YNTVHDHZ7G1ZHJFGBXHFKMPPAFKZ8QHXM5RBZBGI",
    "assetId": "SOLANA.AADAI-EGQ3YNTVHDHZ7G1ZHJFGBXHFKMPPAFKZ8QHXM5RBZBGI",
    "assetAddress": "EgQ3yNtVhdHz7g1ZhjfGbxhFKMPPaFkz8QHXM5RBZBgi",
    "assetName": "aaDAI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.aadai-egq3yntvhdhz7g1zhjfgbxhfkmppafkz8qhxm5rbzbgi.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$BLEP-BKD4K5NLA6AQ7PHLJUFNWGSYWRIYT2FCKBADSCABGWJY",
    "assetId": "SOLANA.$BLEP-BKD4K5NLA6AQ7PHLJUFNWGSYWRIYT2FCKBADSCABGWJY",
    "assetAddress": "Bkd4k5nLA6Aq7PHLJUFnWGSyWriyT2fcKbADScaBGwJy",
    "assetName": "$blep",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.blep-bkd4k5nla6aq7phljufnwgsywriyt2fckbadscabgwjy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GMECAT-DCGDYP2ABHZCSSDHXTDS887UK98WTTTA9FD2DLZNI6UZ",
    "assetId": "SOLANA.GMECAT-DCGDYP2ABHZCSSDHXTDS887UK98WTTTA9FD2DLZNI6UZ",
    "assetAddress": "DcgDYp2AbHzCSsdhxTds887uk98wtTTA9fd2DLzNi6UZ",
    "assetName": "GMECAT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gmecat-dcgdyp2abhzcssdhxtds887uk98wttta9fd2dlzni6uz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SWHETH-9-KNVFDSJYQ1PRQK9AKKV1G5UYGUK6WPM4WG16BJUWDMA",
    "assetId": "SOLANA.SWHETH-9-KNVFDSJYQ1PRQK9AKKV1G5UYGUK6WPM4WG16BJUWDMA",
    "assetAddress": "9",
    "assetName": "swhETH",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.swheth-9-knvfdsjyq1prqk9akkv1g5uyguk6wpm4wg16bjuwdma.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TOBI-6H4ZKWQB9DDW8JAB4D76LNFP8PORGISVQLBHNKUIH1TY",
    "assetId": "SOLANA.TOBI-6H4ZKWQB9DDW8JAB4D76LNFP8PORGISVQLBHNKUIH1TY",
    "assetAddress": "6h4ZKWqb9dDW8jaB4d76LNfP8PoRgiSvQLBHnkuih1Ty",
    "assetName": "TOBI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tobi-6h4zkwqb9ddw8jab4d76lnfp8porgisvqlbhnkuih1ty.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CWH-2BIAFVHHGR13P2WBKERJND1HUSBGFBVBHTMYOOVZII7E",
    "assetId": "SOLANA.CWH-2BIAFVHHGR13P2WBKERJND1HUSBGFBVBHTMYOOVZII7E",
    "assetAddress": "2biAFVhHGr13P2wBKeRjnD1HUsBGFBvbHtMyooVZii7E",
    "assetName": "CWH",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cwh-2biafvhhgr13p2wbkerjnd1husbgfbvbhtmyoovzii7e.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KEYDOG-3FGONJPOHYRVPK9IJUWEW5WUURU5GMADSRGNGEAANEXQ",
    "assetId": "SOLANA.KEYDOG-3FGONJPOHYRVPK9IJUWEW5WUURU5GMADSRGNGEAANEXQ",
    "assetAddress": "3fGoNjPohYRVPk9iJuwEW5WuURU5gmAdSRgNgeaanexQ",
    "assetName": "KEYDOG",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.keydog-3fgonjpohyrvpk9ijuwew5wuuru5gmadsrgngeaanexq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RKSOL-EPCZ5LK372VMVCKZH3HGSUGNKACJJWWXSOFW6FYPCPZL",
    "assetId": "SOLANA.RKSOL-EPCZ5LK372VMVCKZH3HGSUGNKACJJWWXSOFW6FYPCPZL",
    "assetAddress": "EPCz5LK372vmvCkZH3HgSuGNKACJJwwxsofW6fypCPZL",
    "assetName": "rkSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rksol-epcz5lk372vmvckzh3hgsugnkacjjwwxsofw6fypcpzl.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KI-KIGENOPASCF8VF31ZBTX2HG8QA5ARGQVNVTXB83SOTC",
    "assetId": "SOLANA.KI-KIGENOPASCF8VF31ZBTX2HG8QA5ARGQVNVTXB83SOTC",
    "assetAddress": "kiGenopAScF8VF31Zbtx2Hg8qA5ArGqvnVtXb83sotc",
    "assetName": "KI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ki-kigenopascf8vf31zbtx2hg8qa5argqvnvtxb83sotc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$GRETCH-7AY4X2ZCHQRSSZD8NTZNWXQZLTWXOCCRNH2DAWN3PUMP",
    "assetId": "SOLANA.$GRETCH-7AY4X2ZCHQRSSZD8NTZNWXQZLTWXOCCRNH2DAWN3PUMP",
    "assetAddress": "7ay4X2zcHQRSSZd8NtzNwxqzLtwxoCCRnH2daWn3pump",
    "assetName": "$Gretch",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gretch-7ay4x2zchqrsszd8ntznwxqzltwxoccrnh2dawn3pump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FRKT-ERGB9XA24SZXBK1M28U2TX8RKPQZL6BRONKKZK5RG4ZJ",
    "assetId": "SOLANA.FRKT-ERGB9XA24SZXBK1M28U2TX8RKPQZL6BRONKKZK5RG4ZJ",
    "assetAddress": "ErGB9xa24Szxbk1M28u2Tx8rKPqzL6BroNkkzk5rG4zj",
    "assetName": "FRKT",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.frkt-ergb9xa24szxbk1m28u2tx8rkpqzl6bronkkzk5rg4zj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GMCOCK-8CSTYS264XFRGYM2WLY6QGGQ6ISXU8G4UND9Q1GVBBBB",
    "assetId": "SOLANA.GMCOCK-8CSTYS264XFRGYM2WLY6QGGQ6ISXU8G4UND9Q1GVBBBB",
    "assetAddress": "8CSTYs264XFrGym2WLy6QGgq6isxU8G4Und9q1gVbbbB",
    "assetName": "GMCOCK",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gmcock-8cstys264xfrgym2wly6qggq6isxu8g4und9q1gvbbbb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PEANIE-DEKNON3D8MXA4JHLWTBVXZ8APAYJUKK443UJCSPJKI4",
    "assetId": "SOLANA.PEANIE-DEKNON3D8MXA4JHLWTBVXZ8APAYJUKK443UJCSPJKI4",
    "assetAddress": "dekNoN3D8mXa4JHLwTbVXz8aPAyJUkk443UjcSpJKi4",
    "assetName": "peanie",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.peanie-deknon3d8mxa4jhlwtbvxz8apayjukk443ujcspjki4.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FRNT-3VHSSV6MGVPA1JVUUDZVB72VYBEUNZW4MBXIBFTWZHEA",
    "assetId": "SOLANA.FRNT-3VHSSV6MGVPA1JVUUDZVB72VYBEUNZW4MBXIBFTWZHEA",
    "assetAddress": "3vHSsV6mgvpa1JVuuDZVB72vYbeUNzW4mBxiBftwzHEA",
    "assetName": "FRNT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.frnt-3vhssv6mgvpa1jvuudzvb72vybeunzw4mbxibftwzhea.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LEGEND-EEAY5QJPS5F3JQQD51MYFHO8ENAKTGFZXNNFJBEVPUMP",
    "assetId": "SOLANA.LEGEND-EEAY5QJPS5F3JQQD51MYFHO8ENAKTGFZXNNFJBEVPUMP",
    "assetAddress": "EEAy5QjPS5F3Jqqd51MYfHo8enAKtgFZxNnFjbeVpump",
    "assetName": "LEGEND",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.legend-eeay5qjps5f3jqqd51myfho8enaktgfzxnnfjbevpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FUJI-FUJICECEP9AFDVCV27P5JRCKLOH7WFS2C9XMDECS24M",
    "assetId": "SOLANA.FUJI-FUJICECEP9AFDVCV27P5JRCKLOH7WFS2C9XMDECS24M",
    "assetAddress": "fujiCeCeP9AFDVCv27P5JRcKLoH7wfs2C9xmDECs24m",
    "assetName": "FUJI",
    "decimals": 3,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fuji-fujicecep9afdvcv27p5jrckloh7wfs2c9xmdecs24m.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JENNER-4GJ3TCT5MTGQT5BRKB14AKJDDPFQQKVFPHXZS3T4FOZ9",
    "assetId": "SOLANA.JENNER-4GJ3TCT5MTGQT5BRKB14AKJDDPFQQKVFPHXZS3T4FOZ9",
    "assetAddress": "4GJ3TCt5mTgQT5BRKb14AkjddpFQqKVfphxzS3t4foZ9",
    "assetName": "jenner",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jenner-4gj3tct5mtgqt5brkb14akjddpfqqkvfphxzs3t4foz9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.UST-9VMJFXUKXXBOEA7RM12MYLMWTACLMLDJQHOZW96WQL8I",
    "assetId": "SOLANA.UST-9VMJFXUKXXBOEA7RM12MYLMWTACLMLDJQHOZW96WQL8I",
    "assetAddress": "9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i",
    "assetName": "UST",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ust-9vmjfxukxxboea7rm12mylmwtaclmldjqhozw96wql8i.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FUSESOL-FUSEYVHNJBSZDDBYYTCRLCOGSONWAVIB1WEEWHBQGFC",
    "assetId": "SOLANA.FUSESOL-FUSEYVHNJBSZDDBYYTCRLCOGSONWAVIB1WEEWHBQGFC",
    "assetAddress": "fuseYvhNJbSzdDByyTCrLcogsoNwAviB1WeewhbqgFc",
    "assetName": "fuseSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fusesol-fuseyvhnjbszddbyytcrlcogsonwavib1weewhbqgfc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EUROE-2VHJJ9WXAGC3EZFWJG9BDUS9KXKCAJQY4VGD1QXGYWVG",
    "assetId": "SOLANA.EUROE-2VHJJ9WXAGC3EZFWJG9BDUS9KXKCAJQY4VGD1QXGYWVG",
    "assetAddress": "2VhjJ9WxaGC3EZFwJG9BDUs9KxKCAjQY4vgd1qxgYWVg",
    "assetName": "EUROe",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.euroe-2vhjj9wxagc3ezfwjg9bdus9kxkcajqy4vgd1qxgywvg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CROX-DTEQTXXGFN3SZ4C8TNP35X8IEGCCGCBRX974WFSUYVZH",
    "assetId": "SOLANA.CROX-DTEQTXXGFN3SZ4C8TNP35X8IEGCCGCBRX974WFSUYVZH",
    "assetAddress": "DTEqTxxGFn3SZ4C8tNP35X8iegCCgCBrX974WFSuYVZh",
    "assetName": "CROX",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.crox-dteqtxxgfn3sz4c8tnp35x8iegccgcbrx974wfsuyvzh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SUST-8-UST8SCN7JRQSQ51ODVLQCMVNC658HKQRKRPL3W2HHQ7",
    "assetId": "SOLANA.SUST-8-UST8SCN7JRQSQ51ODVLQCMVNC658HKQRKRPL3W2HHQ7",
    "assetAddress": "8",
    "assetName": "sUST",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sust-8-ust8scn7jrqsq51odvlqcmvnc658hkqrkrpl3w2hhq7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOKU-BKW2V5UV6SKTW5C5GYJBCTKBY9NUYYHS3GRY1DCO5HRA",
    "assetId": "SOLANA.BOKU-BKW2V5UV6SKTW5C5GYJBCTKBY9NUYYHS3GRY1DCO5HRA",
    "assetAddress": "BkW2v5uv6skTW5c5GYjBctkbY9nuyyHs3gry1dCo5Hra",
    "assetName": "BOKU",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.boku-bkw2v5uv6sktw5c5gyjbctkby9nuyyhs3gry1dco5hra.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GER-52DFSNKNORXOGKJQECCTT3VK2PUWZ3EMNSYKVM4Z3YWY",
    "assetId": "SOLANA.GER-52DFSNKNORXOGKJQECCTT3VK2PUWZ3EMNSYKVM4Z3YWY",
    "assetAddress": "52DfsNknorxogkjqecCTT3Vk2pUwZ3eMnsYKVm4z3yWy",
    "assetName": "GER",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ger-52dfsnknorxogkjqecctt3vk2puwz3emnsykvm4z3ywy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PATHSOL-PATHDXW4HE1XK3EX84PDDDZNGKEME3GIVBAMGCVPZ5A",
    "assetId": "SOLANA.PATHSOL-PATHDXW4HE1XK3EX84PDDDZNGKEME3GIVBAMGCVPZ5A",
    "assetAddress": "pathdXw4He1Xk3eX84pDdDZnGKEme3GivBamGCVPZ5a",
    "assetName": "pathSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pathsol-pathdxw4he1xk3ex84pdddzngkeme3givbamgcvpz5a.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BASIS-BASIS9OJW9J8CW53OMV7IQSGO6IHI9ALW4QR31RCJUJA",
    "assetId": "SOLANA.BASIS-BASIS9OJW9J8CW53OMV7IQSGO6IHI9ALW4QR31RCJUJA",
    "assetAddress": "Basis9oJw9j8cw53oMV7iqsgo6ihi9ALw4QR31rcjUJa",
    "assetName": "BASIS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.basis-basis9ojw9j8cw53omv7iqsgo6ihi9alw4qr31rcjuja.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SPURDO-8HJ81SHVSMJMZM6XBFTGYWWQXWQTMVDMXAQHM7HTGPH2",
    "assetId": "SOLANA.SPURDO-8HJ81SHVSMJMZM6XBFTGYWWQXWQTMVDMXAQHM7HTGPH2",
    "assetAddress": "8HJ81sHVsmJMzm6XBfTgywWQXwQTmVdmXaQHm7htGPH2",
    "assetName": "spurdo",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.spurdo-8hj81shvsmjmzm6xbftgywwqxwqtmvdmxaqhm7htgph2.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BMT-FANJWA4YEVUJJ1R83TR7XYBXMDGF6BNH8M81AG9AEUBF",
    "assetId": "SOLANA.BMT-FANJWA4YEVUJJ1R83TR7XYBXMDGF6BNH8M81AG9AEUBF",
    "assetAddress": "FanJWA4yEVUJj1r83tR7XybxmDGF6bNH8M81ag9aeUbF",
    "assetName": "BMT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bmt-fanjwa4yevujj1r83tr7xybxmdgf6bnh8m81ag9aeubf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DUAL-DUALA4FC2YREWZ59PHEU1UN4WIS36VHRV5HWVBMZYKCJ",
    "assetId": "SOLANA.DUAL-DUALA4FC2YREWZ59PHEU1UN4WIS36VHRV5HWVBMZYKCJ",
    "assetAddress": "DUALa4FC2yREwZ59PHeu1un4wis36vHRv5hWVBmzykCJ",
    "assetName": "DUAL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dual-duala4fc2yrewz59pheu1un4wis36vhrv5hwvbmzykcj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SPACEGOLD-9MXZ54YNJJRMUN2MAMETWCFFYONCP4ZHKZ7U9DZ4JY2X",
    "assetId": "SOLANA.SPACEGOLD-9MXZ54YNJJRMUN2MAMETWCFFYONCP4ZHKZ7U9DZ4JY2X",
    "assetAddress": "9mXZ54YnJJRmUN2MaMEtWCfFyoncP4ZhKz7U9DZ4JY2X",
    "assetName": "SPACEGOLD",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.spacegold-9mxz54ynjjrmun2mametwcffyoncp4zhkz7u9dz4jy2x.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CFA-F1N2TN7EB9JTBSQIQY2Z7G4VTBKREHGQQCRKKMWZV726",
    "assetId": "SOLANA.CFA-F1N2TN7EB9JTBSQIQY2Z7G4VTBKREHGQQCRKKMWZV726",
    "assetAddress": "F1n2Tn7Eb9jTbSQiqy2Z7G4VTbkreHGQqcRKKmwZv726",
    "assetName": "CFA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cfa-f1n2tn7eb9jtbsqiqy2z7g4vtbkrehgqqcrkkmwzv726.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BATCAT-ETBC6GKCVSB9C6F5WSBWG8WPJRQXMB5EUPTK6BQG1R4X",
    "assetId": "SOLANA.BATCAT-ETBC6GKCVSB9C6F5WSBWG8WPJRQXMB5EUPTK6BQG1R4X",
    "assetAddress": "EtBc6gkCvsB9c6f5wSbwG8wPjRqXMB5euptK6bqG1R4X",
    "assetName": "batcat",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.batcat-etbc6gkcvsb9c6f5wsbwg8wpjrqxmb5euptk6bqg1r4x.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SPEED-5WD2ALXQFNPGQKCYH4WL9GIBIIUULUJS84CJXFQCCVMN",
    "assetId": "SOLANA.SPEED-5WD2ALXQFNPGQKCYH4WL9GIBIIUULUJS84CJXFQCCVMN",
    "assetAddress": "5Wd2ALxQfnpgQKCyH4WL9giBiiuuLuJs84CJxfQccvmN",
    "assetName": "speed",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.speed-5wd2alxqfnpgqkcyh4wl9gibiiuulujs84cjxfqccvmn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ICEDOUT-26BEDY893CHQI5BCUUFHMGD6UTLW9V9ILWKAQJJDJPEA",
    "assetId": "SOLANA.ICEDOUT-26BEDY893CHQI5BCUUFHMGD6UTLW9V9ILWKAQJJDJPEA",
    "assetAddress": "26bedy893CHqi5bcuUFhMgD6uTLw9V9iLWKAQjjDjpEA",
    "assetName": "ICEDOUT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.icedout-26bedy893chqi5bcuufhmgd6utlw9v9ilwkaqjjdjpea.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DAOSOL-GEJPT3WJMR628FQXXTGXMCE1PLNTCPV4UFI8KSXMYPQH",
    "assetId": "SOLANA.DAOSOL-GEJPT3WJMR628FQXXTGXMCE1PLNTCPV4UFI8KSXMYPQH",
    "assetAddress": "GEJpt3Wjmr628FqXxTgxMce1pLntcPV4uFi8ksxMyPQh",
    "assetName": "daoSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.daosol-gejpt3wjmr628fqxxtgxmce1plntcpv4ufi8ksxmypqh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GOFURS-4XNXNJLKEVOJEAUFJJ5XTVKDTLGYHTRDYYXTHGDFHWMR",
    "assetId": "SOLANA.GOFURS-4XNXNJLKEVOJEAUFJJ5XTVKDTLGYHTRDYYXTHGDFHWMR",
    "assetAddress": "4xnxNjLkeVoJEAUFjj5xTvkdTLGYHtrdyyXThGDFhwmr",
    "assetName": "GOFURS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gofurs-4xnxnjlkevojeaufjj5xtvkdtlgyhtrdyyxthgdfhwmr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EDGESOL-EDGE86G9CVZ87XCPKPY3J77VBP4WYD9IDEV562CCNTT",
    "assetId": "SOLANA.EDGESOL-EDGE86G9CVZ87XCPKPY3J77VBP4WYD9IDEV562CCNTT",
    "assetAddress": "edge86g9cVz87xcpKpy3J77vbp4wYd9idEV562CCntt",
    "assetName": "edgeSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.edgesol-edge86g9cvz87xcpkpy3j77vbp4wyd9idev562ccntt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.STRONGSOL-STRNG7MQQC1MBJJV6VMZYBEQNWVGVKKGKEDECVTKTWA",
    "assetId": "SOLANA.STRONGSOL-STRNG7MQQC1MBJJV6VMZYBEQNWVGVKKGKEDECVTKTWA",
    "assetAddress": "strng7mqqc1MBJJV6vMzYbEqnwVGvKKGKedeCvtktWA",
    "assetName": "strongSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.strongsol-strng7mqqc1mbjjv6vmzybeqnwvgvkkgkedecvtktwa.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SLO-E43QU77TNWDWN11O7TTAGMNPXCAQZ8RZEZ7PCTCUXSIM",
    "assetId": "SOLANA.SLO-E43QU77TNWDWN11O7TTAGMNPXCAQZ8RZEZ7PCTCUXSIM",
    "assetAddress": "E43qU77tnWDwN11o7TtaGMNpxCAqz8RZEZ7PcTCUXSim",
    "assetName": "SLO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.slo-e43qu77tnwdwn11o7ttagmnpxcaqz8rzez7pctcuxsim.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.QUEST-6YBXMQPMGQHTSTLHVHZQK8UQAO7KVOEXY6E8JMCTQAB1",
    "assetId": "SOLANA.QUEST-6YBXMQPMGQHTSTLHVHZQK8UQAO7KVOEXY6E8JMCTQAB1",
    "assetAddress": "6ybxMQpMgQhtsTLhvHZqk8uqao7kvoexY6e8JmCTqAB1",
    "assetName": "QUEST",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.quest-6ybxmqpmgqhtstlhvhzqk8uqao7kvoexy6e8jmctqab1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AAUSDT-FWEHS3KJEDMA2QZHV7SGZCIFXUQPEYCEXKSFBKWMS8GJ",
    "assetId": "SOLANA.AAUSDT-FWEHS3KJEDMA2QZHV7SGZCIFXUQPEYCEXKSFBKWMS8GJ",
    "assetAddress": "FwEHs3kJEdMa2qZHv7SgzCiFXUQPEycEXksfBkwmS8gj",
    "assetName": "aaUSDT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.aausdt-fwehs3kjedma2qzhv7sgzcifxuqpeycexksfbkwms8gj.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FUDSI-91FV8PZTVLSF4S49RANPGW92VEJ2OL6BJWSP9I666WXU",
    "assetId": "SOLANA.FUDSI-91FV8PZTVLSF4S49RANPGW92VEJ2OL6BJWSP9I666WXU",
    "assetAddress": "91Fv8PztVLsf4S49RANPGW92veJ2oL6BjwsP9i666Wxu",
    "assetName": "FUDSI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fudsi-91fv8pztvlsf4s49ranpgw92vej2ol6bjwsp9i666wxu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BANA-BHPXDQIO8XTNC6K5BG5FNUVL9KGN8UVRDNWW8MZBU8DL",
    "assetId": "SOLANA.BANA-BHPXDQIO8XTNC6K5BG5FNUVL9KGN8UVRDNWW8MZBU8DL",
    "assetAddress": "BhPXDQio8xtNC6k5Bg5fnUVL9kGN8uvRDNwW8MZBu8DL",
    "assetName": "BANA",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bana-bhpxdqio8xtnc6k5bg5fnuvl9kgn8uvrdnww8mzbu8dl.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RZOOM-VJQ9T5XMQRZLXQRYVIGZYZZPHCGCSBYAJ7AFLVUF8J9",
    "assetId": "SOLANA.RZOOM-VJQ9T5XMQRZLXQRYVIGZYZZPHCGCSBYAJ7AFLVUF8J9",
    "assetAddress": "Vjq9T5xmqRzLXQRyvigzyZzpHCGCsbYAJ7afLVuF8j9",
    "assetName": "rZOOM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rzoom-vjq9t5xmqrzlxqryvigzyzzphcgcsbyaj7aflvuf8j9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.METAS-3ZTT53VWGHQGOEP3N1RJSU4CFNGRFQZWO6L8KN8GMXFD",
    "assetId": "SOLANA.METAS-3ZTT53VWGHQGOEP3N1RJSU4CFNGRFQZWO6L8KN8GMXFD",
    "assetAddress": "3Ztt53vwGhQGoEp3n1RjSu4CFnGRfqzwo6L8KN8gmXfd",
    "assetName": "METAS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.metas-3ztt53vwghqgoep3n1rjsu4cfngrfqzwo6l8kn8gmxfd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WEYU-EHAEBHYHWA7HSPHORXXOSYSJEM6QF4AGCCOQDQQKCUGE",
    "assetId": "SOLANA.WEYU-EHAEBHYHWA7HSPHORXXOSYSJEM6QF4AGCCOQDQQKCUGE",
    "assetAddress": "EHaEBhYHWA7HSphorXXosysJem6qF4agccoqDqQKCUge",
    "assetName": "WEYU",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.weyu-ehaebhyhwa7hsphorxxosysjem6qf4agccoqdqqkcuge.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EURO2024-D5OCX51J1HGWGAAAWGN4AKQU8TVBATDX3NTNUDKKRGGQ",
    "assetId": "SOLANA.EURO2024-D5OCX51J1HGWGAAAWGN4AKQU8TVBATDX3NTNUDKKRGGQ",
    "assetAddress": "D5oCx51J1hgwGAaaWgn4aKqU8TvbaTDx3NtNUdkKrGgq",
    "assetName": "EURO2024",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.euro2024-d5ocx51j1hgwgaaawgn4akqu8tvbatdx3ntnudkkrggq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NORM-9M26M7CXKJDAEWDIH8V5KSIIXKBWBBIRTE1QMXHMDVVG",
    "assetId": "SOLANA.NORM-9M26M7CXKJDAEWDIH8V5KSIIXKBWBBIRTE1QMXHMDVVG",
    "assetAddress": "9M26M7CxkJdaewdiH8v5kSiiXKbWBBirTE1QmXHmDvVg",
    "assetName": "Norm",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.norm-9m26m7cxkjdaewdih8v5ksiixkbwbbirte1qmxhmdvvg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PEEP-N54ZWXECLNC3O7ZK48NHRLV4KTU5WWD4IQ7GVDT5TIK",
    "assetId": "SOLANA.PEEP-N54ZWXECLNC3O7ZK48NHRLV4KTU5WWD4IQ7GVDT5TIK",
    "assetAddress": "n54ZwXEcLnc3o7zK48nhrLV4KTU5wWD4iq7Gvdt5tik",
    "assetName": "PEEP",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.peep-n54zwxeclnc3o7zk48nhrlv4ktu5wwd4iq7gvdt5tik.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$SSHIB-6VHL2VMKGRF1YQFSV29RS1PJ9VCRK29BD11NTDQERQHA",
    "assetId": "SOLANA.$SSHIB-6VHL2VMKGRF1YQFSV29RS1PJ9VCRK29BD11NTDQERQHA",
    "assetAddress": "6VHL2vMKgrF1YQFSv29Rs1pj9VCRK29bD11NtDqerqHA",
    "assetName": "$SSHIB",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sshib-6vhl2vmkgrf1yqfsv29rs1pj9vcrk29bd11ntdqerqha.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$BEAR-24A1K6WSDQUSWORXJYVKR25KF5DO8F6DDFF6A2FTC1PM",
    "assetId": "SOLANA.$BEAR-24A1K6WSDQUSWORXJYVKR25KF5DO8F6DDFF6A2FTC1PM",
    "assetAddress": "24a1k6wSDQUSwoRXJyvkr25kF5do8f6Ddff6A2fTc1pM",
    "assetName": "$BEAR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bear-24a1k6wsdqusworxjyvkr25kf5do8f6ddff6a2ftc1pm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CALUSD-CALUSHEMBJF3TQ69BXFBLRUSPGRWKZENLKWUPHQO5DFK",
    "assetId": "SOLANA.CALUSD-CALUSHEMBJF3TQ69BXFBLRUSPGRWKZENLKWUPHQO5DFK",
    "assetAddress": "CALusHembJf3tQ69BxFbLRUSpGRwKzEnLKWUPhQo5dFk",
    "assetName": "calUSD",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.calusd-calushembjf3tq69bxfblruspgrwkzenlkwuphqo5dfk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.APUSDC-EQKJTF1DO4MDPYKISMYQVAUFPKEFAS3RIGF3CEDH2CA",
    "assetId": "SOLANA.APUSDC-EQKJTF1DO4MDPYKISMYQVAUFPKEFAS3RIGF3CEDH2CA",
    "assetAddress": "eqKJTf1Do4MDPyKisMYqVaUFpkEFAs3riGF3ceDH2Ca",
    "assetName": "apUSDC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.apusdc-eqkjtf1do4mdpykismyqvaufpkefas3rigf3cedh2ca.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$JPMT-7ERXZRN1HPYMZC8GPS7ANZFTGGEDG7CFMVZCMFE6OGRD",
    "assetId": "SOLANA.$JPMT-7ERXZRN1HPYMZC8GPS7ANZFTGGEDG7CFMVZCMFE6OGRD",
    "assetAddress": "7ErxzRN1hpyMZC8gps7ANZFTGgeDG7cFmVZcMfE6oGrd",
    "assetName": "$JPMT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jpmt-7erxzrn1hpymzc8gps7anzftggedg7cfmvzcmfe6ogrd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MBAPEPE-5CJUT4HZOVPRFWAQENEDFBFJLVNXSPV9RZGPYYY3XLJX",
    "assetId": "SOLANA.MBAPEPE-5CJUT4HZOVPRFWAQENEDFBFJLVNXSPV9RZGPYYY3XLJX",
    "assetAddress": "5cJUt4HzoVpRfwAqeNEDfbFJLVNxSpV9rzgPyyY3XLJx",
    "assetName": "Mbapepe",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mbapepe-5cjut4hzovprfwaqenedfbfjlvnxspv9rzgpyyy3xljx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GMFC-42Y3CGJQLNHJDSCYMU8VS4TBEZMUNVDBMKYBF7HZ7AUM",
    "assetId": "SOLANA.GMFC-42Y3CGJQLNHJDSCYMU8VS4TBEZMUNVDBMKYBF7HZ7AUM",
    "assetAddress": "42Y3CgJQLnHjdScYMu8VS4TbeZMUNVdBMKYbf7hz7aum",
    "assetName": "GMFC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gmfc-42y3cgjqlnhjdscymu8vs4tbezmunvdbmkybf7hz7aum.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JORLPS-EA4SYW5UDNNTXWUYYOGN9UGPLU4Q71HWGJHJBFUHTQLY",
    "assetId": "SOLANA.JORLPS-EA4SYW5UDNNTXWUYYOGN9UGPLU4Q71HWGJHJBFUHTQLY",
    "assetAddress": "EA4SyW5UDnntXwUyyogN9UgpLU4q71HWgjhJBFUHTqLY",
    "assetName": "Jorlps",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jorlps-ea4syw5udnntxwuyyogn9ugplu4q71hwgjhjbfuhtqly.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SHYCAT-6WERZ2AMZYLUYZBP9N5GM4FWHJBV9LN8YIMSQRWZPUMP",
    "assetId": "SOLANA.SHYCAT-6WERZ2AMZYLUYZBP9N5GM4FWHJBV9LN8YIMSQRWZPUMP",
    "assetAddress": "6WErZ2aMZYLUyzbP9n5gm4fwHJbv9Ln8yimSQRwZpump",
    "assetName": "SHYCAT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.shycat-6werz2amzyluyzbp9n5gm4fwhjbv9ln8yimsqrwzpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$SOLO-J8CKU4PD2NTSOVVV5XGHWHQIJY5TTEZGSYOZORXZ6AX8",
    "assetId": "SOLANA.$SOLO-J8CKU4PD2NTSOVVV5XGHWHQIJY5TTEZGSYOZORXZ6AX8",
    "assetAddress": "J8cKU4pD2NTSovvV5XghWHQiJy5TTEzgSyozorxz6ax8",
    "assetName": "$SOLO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solo-j8cku4pd2ntsovvv5xghwhqijy5ttezgsyozorxz6ax8.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BLOB-85VB4PD3GYVMXQ211ZBXXWQJWJ4XZPBC5AQXVNMB1HTI",
    "assetId": "SOLANA.BLOB-85VB4PD3GYVMXQ211ZBXXWQJWJ4XZPBC5AQXVNMB1HTI",
    "assetAddress": "85vb4pd3gyVMxQ211ZBXXWQJwJ4xZPBC5AQXvnmb1HTi",
    "assetName": "Blob",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.blob-85vb4pd3gyvmxq211zbxxwqjwj4xzpbc5aqxvnmb1hti.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DUBAI-93NHRYHQDN5EVZ2N4QOOF7ELWNTCGHBW44TMGCLZXKXZ",
    "assetId": "SOLANA.DUBAI-93NHRYHQDN5EVZ2N4QOOF7ELWNTCGHBW44TMGCLZXKXZ",
    "assetAddress": "93NhryHqdN5eVz2n4Qoof7ELwnTcgHBw44tmGcLzxKXz",
    "assetName": "DUBAI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dubai-93nhryhqdn5evz2n4qoof7elwntcghbw44tmgclzxkxz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PHY-ESWGBJ2HZKDGOVX2IHWSUDNUBG9VNBGMSGOH5YJNSPRA",
    "assetId": "SOLANA.PHY-ESWGBJ2HZKDGOVX2IHWSUDNUBG9VNBGMSGOH5YJNSPRA",
    "assetAddress": "EswgBj2hZKdgovX2ihWSUDnuBg9VNbGmSGoH5yjNsPRa",
    "assetName": "PHY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.phy-eswgbj2hzkdgovx2ihwsudnubg9vnbgmsgoh5yjnspra.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ROT-APOM2SXUZDRHTKUJXSSDUHEX1WPPDP4HFLOTMTRNMU8P",
    "assetId": "SOLANA.ROT-APOM2SXUZDRHTKUJXSSDUHEX1WPPDP4HFLOTMTRNMU8P",
    "assetAddress": "APoM2sXUzdRHTkUjXSsdUheX1wPPdP4HFLotmtRNMU8P",
    "assetName": "rot",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rot-apom2sxuzdrhtkujxssduhex1wppdp4hflotmtrnmu8p.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.POOT-CERSPNNEHUNSNW3AZJHYVEKWHMPR9H3W2S71UA3PZJUS",
    "assetId": "SOLANA.POOT-CERSPNNEHUNSNW3AZJHYVEKWHMPR9H3W2S71UA3PZJUS",
    "assetAddress": "CErSpNnEHUNsNw3AZJhyvekwhMpr9H3W2S71uA3pzJus",
    "assetName": "POOT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.poot-cerspnnehunsnw3azjhyvekwhmpr9h3w2s71ua3pzjus.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BABYBONK-DX1LQ5FJANGW5IFRMEOGAIAKM24LYB5AOHMQIFEPVNJV",
    "assetId": "SOLANA.BABYBONK-DX1LQ5FJANGW5IFRMEOGAIAKM24LYB5AOHMQIFEPVNJV",
    "assetAddress": "Dx1Lq5FjangW5ifRMEogAiakm24LyB5AoHmQifepvNjV",
    "assetName": "BabyBonk",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.babybonk-dx1lq5fjangw5ifrmeogaiakm24lyb5aohmqifepvnjv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$CWIF-7ATGF8KQO4WJRD5ATGX7T1V2ZVVYKPJBFFNEVF1ICFV1",
    "assetId": "SOLANA.$CWIF-7ATGF8KQO4WJRD5ATGX7T1V2ZVVYKPJBFFNEVF1ICFV1",
    "assetAddress": "7atgF8KQo4wJrD5ATGX7t1V2zVvykPJbFfNeVf1icFv1",
    "assetName": "$CWIF",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cwif-7atgf8kqo4wjrd5atgx7t1v2zvvykpjbffnevf1icfv1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FEEL-FEELEKVXHTSHHVU1ARYSJETXNJMKBLC6WHCAMD68IPIR",
    "assetId": "SOLANA.FEEL-FEELEKVXHTSHHVU1ARYSJETXNJMKBLC6WHCAMD68IPIR",
    "assetAddress": "FEELeKVxHtsHHvu1ARySjETxnJMKbLC6wHCaMD68ipiR",
    "assetName": "FEEL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.feel-feelekvxhtshhvu1arysjetxnjmkblc6whcamd68ipir.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOBTC-9N4NBM75F5UI33ZBPYXN59EWSGE8CGSHTAETH5YFEJ9E",
    "assetId": "SOLANA.SOBTC-9N4NBM75F5UI33ZBPYXN59EWSGE8CGSHTAETH5YFEJ9E",
    "assetAddress": "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E",
    "assetName": "soBTC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sobtc-9n4nbm75f5ui33zbpyxn59ewsge8cgshtaeth5yfej9e.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PURPE-HBONJ5V8G71S2BORIVRHNFSB5MVPLDHHYVJRUPFHGKVL",
    "assetId": "SOLANA.PURPE-HBONJ5V8G71S2BORIVRHNFSB5MVPLDHHYVJRUPFHGKVL",
    "assetAddress": "HBoNJ5v8g71s2boRivrHnfSB5MVPLDHHyVjruPfhGkvL",
    "assetName": "PURPE",
    "decimals": 1,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.purpe-hbonj5v8g71s2borivrhnfsb5mvpldhhyvjrupfhgkvl.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ROLL-76AYNHBDFHEMXSS7VMH6EJGFJODK8M7SRCXIYCRKXZY1",
    "assetId": "SOLANA.ROLL-76AYNHBDFHEMXSS7VMH6EJGFJODK8M7SRCXIYCRKXZY1",
    "assetAddress": "76aYNHbDfHemxSS7vmh6eJGfjodK8m7srCxiYCrKxzY1",
    "assetName": "ROLL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.roll-76aynhbdfhemxss7vmh6ejgfjodk8m7srcxiycrkxzy1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$BEN-2RY7AGS1W488LDSXHZ2XVSRU1EVUZUGJO27DBP57COUH",
    "assetId": "SOLANA.$BEN-2RY7AGS1W488LDSXHZ2XVSRU1EVUZUGJO27DBP57COUH",
    "assetAddress": "2Ry7AGS1w488LdSxhz2XvSrU1EVUzUGJo27DBP57couh",
    "assetName": "$BEN",
    "decimals": 7,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ben-2ry7ags1w488ldsxhz2xvsru1evuzugjo27dbp57couh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLBLOCK-3GXEC9N9SGGOARIEY3AKBYNBHCHSDMGRKDQF31ZSIJVM",
    "assetId": "SOLANA.SOLBLOCK-3GXEC9N9SGGOARIEY3AKBYNBHCHSDMGRKDQF31ZSIJVM",
    "assetAddress": "3gxEC9N9SGgoaRiey3AkbynbHcHsdmgrKDQf31zsiJvm",
    "assetName": "SolBlock",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solblock-3gxec9n9sggoariey3akbynbhchsdmgrkdqf31zsijvm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JIMMY-94XDUSFSNYAS7JAEFSJSXICI1XQ4MENWCII1ACVJVUQU",
    "assetId": "SOLANA.JIMMY-94XDUSFSNYAS7JAEFSJSXICI1XQ4MENWCII1ACVJVUQU",
    "assetAddress": "94XduSfSnyas7jAEFSJSXiCi1xQ4mENWcii1aCvjVuqu",
    "assetName": "JIMMY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jimmy-94xdusfsnyas7jaefsjsxici1xq4menwcii1acvjvuqu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$STICKY-8TGTGXHYRGKTAF4CPEVZSQPM3CUXBZ8P4ZW4HEHYLWZZ",
    "assetId": "SOLANA.$STICKY-8TGTGXHYRGKTAF4CPEVZSQPM3CUXBZ8P4ZW4HEHYLWZZ",
    "assetAddress": "8tgtGXhYRGKtaF4CPeVzSQpM3cUXBZ8P4ZW4hEhyLWZZ",
    "assetName": "$STICKY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sticky-8tgtgxhyrgktaf4cpevzsqpm3cuxbz8p4zw4hehylwzz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLA-FYFQ9UARAYVRIAEGUMCT45F9WKAM3BYXARTROTNTNFXF",
    "assetId": "SOLANA.SOLA-FYFQ9UARAYVRIAEGUMCT45F9WKAM3BYXARTROTNTNFXF",
    "assetAddress": "FYfQ9uaRaYvRiaEGUmct45F9WKam3BYXArTrotnTNFXF",
    "assetName": "SOLA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sola-fyfq9uarayvriaegumct45f9wkam3byxartrotntnfxf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CLARB-9CMWA1WUWCIO3VGEPIFG7PQKBCOAFUUW5SLYFKXJ2J8M",
    "assetId": "SOLANA.CLARB-9CMWA1WUWCIO3VGEPIFG7PQKBCOAFUUW5SLYFKXJ2J8M",
    "assetAddress": "9cMWa1wuWcio3vgEpiFg7PqKbcoafuUw5sLYFkXJ2J8M",
    "assetName": "clARB",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.clarb-9cmwa1wuwcio3vgepifg7pqkbcoafuuw5slyfkxj2j8m.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$MOONDELA-BSXVX9DMVEDCXUVWUQ5PZYJB9G8BBXKTDDQNS4OAXGEO",
    "assetId": "SOLANA.$MOONDELA-BSXVX9DMVEDCXUVWUQ5PZYJB9G8BBXKTDDQNS4OAXGEO",
    "assetAddress": "BSXvX9dMvedcXUvwUQ5pzYJb9G8bBXKtddQns4oAxgEo",
    "assetName": "$MOONDELA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.moondela-bsxvx9dmvedcxuvwuq5pzyjb9g8bbxktddqns4oaxgeo.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BUSDBS-5RPUWQ8WTDPCZHHU6MERP2RGRPOBSBZ6MH5DDHKUJS2",
    "assetId": "SOLANA.BUSDBS-5RPUWQ8WTDPCZHHU6MERP2RGRPOBSBZ6MH5DDHKUJS2",
    "assetAddress": "5RpUwQ8wtdPCZHhu6MERp2RGrpobsbZ6MH5dDHkUjs2",
    "assetName": "BUSDbs",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.busdbs-5rpuwq8wtdpczhhu6merp2rgrpobsbz6mh5ddhkujs2.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SRLY-RLYV2UBRMDLCGG2UYVPMNPMKFUQTSMBG4JTYGC7DMNQ",
    "assetId": "SOLANA.SRLY-RLYV2UBRMDLCGG2UYVPMNPMKFUQTSMBG4JTYGC7DMNQ",
    "assetAddress": "RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq",
    "assetName": "sRLY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.srly-rlyv2ubrmdlcgg2uyvpmnpmkfuqtsmbg4jtygc7dmnq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NEKI-ALKIRVRFLGZEAV2MCT7CJHKG3ZOPVSCRSV7VCRWNE8ZQ",
    "assetId": "SOLANA.NEKI-ALKIRVRFLGZEAV2MCT7CJHKG3ZOPVSCRSV7VCRWNE8ZQ",
    "assetAddress": "ALKiRVrfLgzeAV2mCT7cJHKg3ZoPvsCRSV7VCRWnE8zQ",
    "assetName": "NEKI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.neki-alkirvrflgzeav2mct7cjhkg3zopvscrsv7vcrwne8zq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RUSTY-AYY1QVG5VR6NJ9FDIJWWTRVNMJVFEHGGOQRX9NHZ6DG3",
    "assetId": "SOLANA.RUSTY-AYY1QVG5VR6NJ9FDIJWWTRVNMJVFEHGGOQRX9NHZ6DG3",
    "assetAddress": "Ayy1QvG5vR6nJ9fdijWWTrvNmjVfEhGGoQrX9nhZ6Dg3",
    "assetName": "RUSTY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rusty-ayy1qvg5vr6nj9fdijwwtrvnmjvfehggoqrx9nhz6dg3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CROCHET-AQJJU8GCV3UC2XSMF5X92ZARDO3HCNP7EGUEDKV1I7JK",
    "assetId": "SOLANA.CROCHET-AQJJU8GCV3UC2XSMF5X92ZARDO3HCNP7EGUEDKV1I7JK",
    "assetAddress": "Aqjju8gCv3Uc2XsmF5x92ZarDo3hCnP7EgUeDkv1i7jK",
    "assetName": "CROCHET",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.crochet-aqjju8gcv3uc2xsmf5x92zardo3hcnp7eguedkv1i7jk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.COPE-O1MW5Y3N68O8TAKZFUGKLZMGJM72QV4JEOZVGICLEVK",
    "assetId": "SOLANA.COPE-O1MW5Y3N68O8TAKZFUGKLZMGJM72QV4JEOZVGICLEVK",
    "assetAddress": "o1Mw5Y3n68o8TakZFuGKLZMGjm72qv4JeoZvGiCLEvK",
    "assetName": "Cope",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cope-o1mw5y3n68o8takzfugklzmgjm72qv4jeozvgiclevk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WIFSOL-FI5GAYACZZURFACRCJTBZ2VSYKGF56XJGCCEZX5SBXWQ",
    "assetId": "SOLANA.WIFSOL-FI5GAYACZZURFACRCJTBZ2VSYKGF56XJGCCEZX5SBXWQ",
    "assetAddress": "Fi5GayacZzUrfaCRCJtBz2vSYkGF56xjgCceZx5SbXwq",
    "assetName": "wifSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wifsol-fi5gayaczzurfacrcjtbz2vsykgf56xjgccezx5sbxwq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ANSEM-3LEC18Q7NPM62LQWQXG2DDIBTDRFCINW1NEA1EHBZPGB",
    "assetId": "SOLANA.ANSEM-3LEC18Q7NPM62LQWQXG2DDIBTDRFCINW1NEA1EHBZPGB",
    "assetAddress": "3Lec18q7nPM62LQwqXG2ddiBTDrFCiNw1NEA1ehBZPgB",
    "assetName": "ANSEM",
    "decimals": 1,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ansem-3lec18q7npm62lqwqxg2ddibtdrfcinw1nea1ehbzpgb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DOKY-5RS53FY3Q4T4MLK9ZBQ45CVNGF1RH7NQRNXIP6PA5RYH",
    "assetId": "SOLANA.DOKY-5RS53FY3Q4T4MLK9ZBQ45CVNGF1RH7NQRNXIP6PA5RYH",
    "assetAddress": "5Rs53fY3q4t4mLk9zBQ45cVNgF1RH7NqRNXiP6Pa5rYH",
    "assetName": "doky",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.doky-5rs53fy3q4t4mlk9zbq45cvngf1rh7nqrnxip6pa5ryh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ETX-9DZ58I5VAFK3JAFVYEZYZHRVHT7J8MCZSUBUTCDISBRP",
    "assetId": "SOLANA.ETX-9DZ58I5VAFK3JAFVYEZYZHRVHT7J8MCZSUBUTCDISBRP",
    "assetAddress": "9DZ58i5vAfk3JaFVYezYzhrVht7j8McZsUbuTcDiSbrP",
    "assetName": "ETX",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.etx-9dz58i5vafk3jafvyezyzhrvht7j8mczsubutcdisbrp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.VICE-AWEL8NCXXJM3GZKEVRQXEET5KWT2KWDA1SHGQFEBOGKC",
    "assetId": "SOLANA.VICE-AWEL8NCXXJM3GZKEVRQXEET5KWT2KWDA1SHGQFEBOGKC",
    "assetAddress": "AWeL8nCXxJm3GZkEVRqxeeT5KWT2KwDa1shgQFeBogkC",
    "assetName": "VICE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.vice-awel8ncxxjm3gzkevrqxeet5kwt2kwda1shgqfebogkc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MRC-5HSZR8EG7QPQCN8MNP8OFDENRKJMP9ZKCKHPSCKTJSWH",
    "assetId": "SOLANA.MRC-5HSZR8EG7QPQCN8MNP8OFDENRKJMP9ZKCKHPSCKTJSWH",
    "assetAddress": "5HsZR8eG7QpQcN8Mnp8oFdENRkJMP9ZkcKhPSCKTJSWh",
    "assetName": "MRC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mrc-5hszr8eg7qpqcn8mnp8ofdenrkjmp9zkckhpscktjswh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FELON-F1NIVDDCEAXCKG5A1PDSVWN18AQHBJQQCGATWOB3M8R",
    "assetId": "SOLANA.FELON-F1NIVDDCEAXCKG5A1PDSVWN18AQHBJQQCGATWOB3M8R",
    "assetAddress": "f1niVdDCEAxcKg5a1pdsvwN18AQHBJQQcgATWob3M8r",
    "assetName": "FELON",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.felon-f1nivddceaxckg5a1pdsvwn18aqhbjqqcgatwob3m8r.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$CRYPT-CRYPTI2V87TU6ALC9GSWXM1WSLC6RJZH3TGC4GDRCECQ",
    "assetId": "SOLANA.$CRYPT-CRYPTI2V87TU6ALC9GSWXM1WSLC6RJZH3TGC4GDRCECQ",
    "assetAddress": "CRYPTi2V87Tu6aLc9gSwXM1wSLc6rjZh3TGC4GDRCecq",
    "assetName": "$CRYPT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.crypt-crypti2v87tu6alc9gswxm1wslc6rjzh3tgc4gdrcecq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$GGEM-GGEMXCSQM74URIXDY46VCASW73A4YFHFJKRJRUMDVPEF",
    "assetId": "SOLANA.$GGEM-GGEMXCSQM74URIXDY46VCASW73A4YFHFJKRJRUMDVPEF",
    "assetAddress": "GGEMxCsqM74URiXdY46VcaSW73a4yfHfJKrJrUmDVpEF",
    "assetName": "$GGEM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ggem-ggemxcsqm74urixdy46vcasw73a4yfhfjkrjrumdvpef.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DOCS-GR1QPTO3TPMAXT59BFTQO2USFRHRUUZJAWLHR8ADTWZ",
    "assetId": "SOLANA.DOCS-GR1QPTO3TPMAXT59BFTQO2USFRHRUUZJAWLHR8ADTWZ",
    "assetAddress": "gr1qPTo3tpMAxt59BftQo2uSfRHRuUZJaWLhR8ADtwz",
    "assetName": "DOCS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.docs-gr1qpto3tpmaxt59bftqo2usfrhruuzjawlhr8adtwz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$DRUNK-EARMZBWWRWVSGUS8RFDZGFDZX3ZKRKOCL7U36G7XFVCB",
    "assetId": "SOLANA.$DRUNK-EARMZBWWRWVSGUS8RFDZGFDZX3ZKRKOCL7U36G7XFVCB",
    "assetAddress": "EaRMzBwWRwvSgus8rfdZGfdzX3ZKRKoCL7U36G7xfvcb",
    "assetName": "$DRUNK",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.drunk-earmzbwwrwvsgus8rfdzgfdzx3zkrkocl7u36g7xfvcb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MOGDOG-BZU1NWVKRFEN7FRUMTNRTC4QQXTBAMCMBNY1Z7EJPUMP",
    "assetId": "SOLANA.MOGDOG-BZU1NWVKRFEN7FRUMTNRTC4QQXTBAMCMBNY1Z7EJPUMP",
    "assetAddress": "Bzu1nWVKRFEn7FRumTNrTC4qqxtBaMCMBNY1z7ejpump",
    "assetName": "MOGDOG",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mogdog-bzu1nwvkrfen7frumtnrtc4qqxtbamcmbny1z7ejpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOULO-GZ3U6EJAKEVIYPPC5AWUZIZ891KNX76PNDSMJRNANNY4",
    "assetId": "SOLANA.SOULO-GZ3U6EJAKEVIYPPC5AWUZIZ891KNX76PNDSMJRNANNY4",
    "assetAddress": "Gz3u6eJaKEviYpPC5AwUziz891kNX76PNdsmJrnaNNY4",
    "assetName": "SOULO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.soulo-gz3u6ejakeviyppc5awuziz891knx76pndsmjrnanny4.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FINDER-DN7MSHRUG4LEQ1RFMLZ27VIL2P16HN8P12BGBVZSIYAK",
    "assetId": "SOLANA.FINDER-DN7MSHRUG4LEQ1RFMLZ27VIL2P16HN8P12BGBVZSIYAK",
    "assetAddress": "Dn7mshRUg4LEq1RfMLz27ViL2P16hn8p12bGBVZsiyak",
    "assetName": "FINDER",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.finder-dn7mshrug4leq1rfmlz27vil2p16hn8p12bgbvzsiyak.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MILLY-6YNCXRS1FD6UT3C3BTUJKQLG9XZPBMZCBQTAHVPEAXJK",
    "assetId": "SOLANA.MILLY-6YNCXRS1FD6UT3C3BTUJKQLG9XZPBMZCBQTAHVPEAXJK",
    "assetAddress": "6yNcxrS1Fd6Ut3c3bTuJKQLG9xzpbMZCBqTahvpeAXjk",
    "assetName": "milly",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.milly-6yncxrs1fd6ut3c3btujkqlg9xzpbmzcbqtahvpeaxjk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.THOG-5CQFXEX1KNFRIOZWDTGFFNAIGR9TSMSUCWDNUTUGZQRU",
    "assetId": "SOLANA.THOG-5CQFXEX1KNFRIOZWDTGFFNAIGR9TSMSUCWDNUTUGZQRU",
    "assetAddress": "5CqfXex1knfRiozwDtgFFNaiGR9TsmSUcWDNUTUGZQru",
    "assetName": "THOG",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.thog-5cqfxex1knfriozwdtgffnaigr9tsmsucwdnutugzqru.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PEPE-B5WTLARWAUQPKK7IR1WNINB6M5O8GGMRIMHKMYAN2R6B",
    "assetId": "SOLANA.PEPE-B5WTLARWAUQPKK7IR1WNINB6M5O8GGMRIMHKMYAN2R6B",
    "assetAddress": "B5WTLaRwaUQpKk7ir1wniNB6m5o8GgMrimhKMYan2R6B",
    "assetName": "Pepe",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pepe-b5wtlarwauqpkk7ir1wninb6m5o8ggmrimhkmyan2r6b.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ABULL-J3HXVJTN3KGTR1THKJSWXVMR2D4M8IBZXZB75ZYWXVZF",
    "assetId": "SOLANA.ABULL-J3HXVJTN3KGTR1THKJSWXVMR2D4M8IBZXZB75ZYWXVZF",
    "assetAddress": "J3hxVJtn3kGtr1tHkJSWXvMr2D4M8iBzxzB75zywxVzF",
    "assetName": "ABULL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.abull-j3hxvjtn3kgtr1thkjswxvmr2d4m8ibzxzb75zywxvzf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.IRS-A3TCD8Q1YJDY3GRDJ4LFZPECNAFW3SHNA3DJKHESG9XF",
    "assetId": "SOLANA.IRS-A3TCD8Q1YJDY3GRDJ4LFZPECNAFW3SHNA3DJKHESG9XF",
    "assetAddress": "A3tCD8Q1yjdy3gRDJ4LFzpECnafW3sHNa3dJKHesG9Xf",
    "assetName": "IRS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.irs-a3tcd8q1yjdy3grdj4lfzpecnafw3shna3djkhesg9xf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$RKT-RKT69NZHN5UOVCAN3Q5HRBZZFJUXIGEUPMGANOBJLLZ",
    "assetId": "SOLANA.$RKT-RKT69NZHN5UOVCAN3Q5HRBZZFJUXIGEUPMGANOBJLLZ",
    "assetAddress": "RKT69NZHN5uovcan3q5hRbZzfJuXiGEuPmGANoBJLLz",
    "assetName": "$RKT",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rkt-rkt69nzhn5uovcan3q5hrbzzfjuxigeupmganobjllz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PIKA-42O42KH1DZEDJQIJWPWHCNTPMW42HXZG7YBMS1H6A5R1",
    "assetId": "SOLANA.PIKA-42O42KH1DZEDJQIJWPWHCNTPMW42HXZG7YBMS1H6A5R1",
    "assetAddress": "42o42KH1dzEDjqijWpWHcNtpmW42Hxzg7YbMs1h6A5r1",
    "assetName": "Pika",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pika-42o42kh1dzedjqijwpwhcntpmw42hxzg7ybms1h6a5r1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FM-EH1FXBAIPE4K7CYR9UMB2BBWMBCPU3HCYX3LWURVFBLZ",
    "assetId": "SOLANA.FM-EH1FXBAIPE4K7CYR9UMB2BBWMBCPU3HCYX3LWURVFBLZ",
    "assetAddress": "Eh1fXbAipe4k7CYR9UMb2bbWmBcpU3HcyX3LWuRVFBLz",
    "assetName": "FM",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fm-eh1fxbaipe4k7cyr9umb2bbwmbcpu3hcyx3lwurvfblz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FUSD-B7MXKKZGN7ABWZ1A3HNKKB18Y6Y18WCBESKH1DULMKEE",
    "assetId": "SOLANA.FUSD-B7MXKKZGN7ABWZ1A3HNKKB18Y6Y18WCBESKH1DULMKEE",
    "assetAddress": "B7mXkkZgn7abwz1A3HnKkb18Y6y18WcbeSkh1DuLMkee",
    "assetName": "fUSD",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fusd-b7mxkkzgn7abwz1a3hnkkb18y6y18wcbeskh1dulmkee.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.D/ACC-FABJHJC1DRUUQOHVTUDPNICPNF73RTLZMKRM1B5NSBB6",
    "assetId": "SOLANA.D/ACC-FABJHJC1DRUUQOHVTUDPNICPNF73RTLZMKRM1B5NSBB6",
    "assetAddress": "FabjHjc1druUQoHVtudpNiCpnf73rtLzMkRM1b5NSbb6",
    "assetName": "D/ACC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.d%2Facc-fabjhjc1druuqohvtudpnicpnf73rtlzmkrm1b5nsbb6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MYKE-DV2TCTBENV9XJ14KHC89QD1W2KZB6EFVZKHNRPOO5CUD",
    "assetId": "SOLANA.MYKE-DV2TCTBENV9XJ14KHC89QD1W2KZB6EFVZKHNRPOO5CUD",
    "assetAddress": "DV2TCTbENV9xJ14kHC89qd1w2KZB6efvZKHNRpoo5cuD",
    "assetName": "MYKE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.myke-dv2tctbenv9xj14khc89qd1w2kzb6efvzkhnrpoo5cud.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CRYY-56TNQ29XBRBOVM5K5STHUQATJCY92W2WKUAUEQ8WCD9G",
    "assetId": "SOLANA.CRYY-56TNQ29XBRBOVM5K5STHUQATJCY92W2WKUAUEQ8WCD9G",
    "assetAddress": "56tNQ29XBrbovm5K5SThuQatjCy92w2wKUaUeQ8WCD9g",
    "assetName": "CRYY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cryy-56tnq29xbrbovm5k5sthuqatjcy92w2wkuaueq8wcd9g.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$NEON-DUSYBCGUHPVYGU6CSVBZONVQVH8JLYGVXJN1TMKJH6ZN",
    "assetId": "SOLANA.$NEON-DUSYBCGUHPVYGU6CSVBZONVQVH8JLYGVXJN1TMKJH6ZN",
    "assetAddress": "DuSyBCGuhPvyGu6cSvbZonvQvh8JLyGvXJn1TmkJh6Zn",
    "assetName": "$NEON",
    "decimals": 0,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.neon-dusybcguhpvygu6csvbzonvqvh8jlygvxjn1tmkjh6zn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHIBI-HFXLMUSCHVSUYHRLEKYW88OIZ1LJVRERKH4QH1Y5F8XK",
    "assetId": "SOLANA.CHIBI-HFXLMUSCHVSUYHRLEKYW88OIZ1LJVRERKH4QH1Y5F8XK",
    "assetAddress": "HfXLMuSCHvsuYHRLekyW88oiz1LJvRerKh4QH1y5f8xk",
    "assetName": "chibi",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chibi-hfxlmuschvsuyhrlekyw88oiz1ljvrerkh4qh1y5f8xk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MOUTAI-45EGCWCPXYAGBC7KQBIN4NCFGEZWN7F3Y6NACWXQMCWX",
    "assetId": "SOLANA.MOUTAI-45EGCWCPXYAGBC7KQBIN4NCFGEZWN7F3Y6NACWXQMCWX",
    "assetAddress": "45EgCwcPXYagBC7KqBin4nCFgEZWN7f3Y6nACwxqMCWX",
    "assetName": "Moutai",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.moutai-45egcwcpxyagbc7kqbin4ncfgezwn7f3y6nacwxqmcwx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PHOX-9PUG19UEJW5WIFG5BLWYPJTZ6S9K8ZGXMRVZWYFX7SNY",
    "assetId": "SOLANA.PHOX-9PUG19UEJW5WIFG5BLWYPJTZ6S9K8ZGXMRVZWYFX7SNY",
    "assetAddress": "9Pug19UEjW5wiFG5bLWYpjtz6s9K8ZGXmRvZwyfX7SNy",
    "assetName": "PHOX",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.phox-9pug19uejw5wifg5blwypjtz6s9k8zgxmrvzwyfx7sny.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SHARKI-6U48JTR53ZK3E1MOZLRPWJDTRTJ74UUFHMGNZGY18YPU",
    "assetId": "SOLANA.SHARKI-6U48JTR53ZK3E1MOZLRPWJDTRTJ74UUFHMGNZGY18YPU",
    "assetAddress": "6U48jtR53ZK3E1MozLrpwJDTrtj74uuFhMGNzGY18YPu",
    "assetName": "SHARKI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sharki-6u48jtr53zk3e1mozlrpwjdtrtj74uufhmgnzgy18ypu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$BOZO-BOZOQQRAMYKR5IJHQO7DCHAS7DPDWEZ5CV1VKYC9YZJG",
    "assetId": "SOLANA.$BOZO-BOZOQQRAMYKR5IJHQO7DCHAS7DPDWEZ5CV1VKYC9YZJG",
    "assetAddress": "BoZoQQRAmYkr5iJhqo7DChAs7DPDwEZ5cv1vkYC9yzJG",
    "assetName": "$BOZO",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bozo-bozoqqramykr5ijhqo7dchas7dpdwez5cv1vkyc9yzjg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WAVE-GS6E87SLTIORDG3USVRWQMUKKMXDMWU7FKTCTJ5XKEM8",
    "assetId": "SOLANA.WAVE-GS6E87SLTIORDG3USVRWQMUKKMXDMWU7FKTCTJ5XKEM8",
    "assetAddress": "GS6E87SLTioRDG3uSVRwQmuKKMxDmWU7fktCTJ5xkEM8",
    "assetName": "WAVE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wave-gs6e87sltiordg3usvrwqmukkmxdmwu7fktctj5xkem8.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GREEN-4IEKSPPXXJSD6FRY9UMCGX73NJCH5YAKPZYT6MCLPUMP",
    "assetId": "SOLANA.GREEN-4IEKSPPXXJSD6FRY9UMCGX73NJCH5YAKPZYT6MCLPUMP",
    "assetAddress": "4iEkSPpXxjsD6fRY9UmCGX73NJch5yakPzYt6McLpump",
    "assetName": "Green",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.green-4ieksppxxjsd6fry9umcgx73njch5yakpzyt6mclpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TEIT-GZWP5TSAM9VBDPX2BA4US2E3SX5PP65JMZWCYYFY9ZBF",
    "assetId": "SOLANA.TEIT-GZWP5TSAM9VBDPX2BA4US2E3SX5PP65JMZWCYYFY9ZBF",
    "assetAddress": "GzWP5TSam9VBdPx2bA4US2E3sX5pP65JMZWCyYFY9ZbF",
    "assetName": "TEIT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.teit-gzwp5tsam9vbdpx2ba4us2e3sx5pp65jmzwcyyfy9zbf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.T1NY-C5XTJBKM24WTT3JIXRVGUV7VHCE7CKNDB7PNABP4EYX6",
    "assetId": "SOLANA.T1NY-C5XTJBKM24WTT3JIXRVGUV7VHCE7CKNDB7PNABP4EYX6",
    "assetAddress": "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    "assetName": "T1NY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.t1ny-c5xtjbkm24wtt3jixrvguv7vhce7ckndb7pnabp4eyx6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BITARD-2ZZAE2GNQSPZLEFINZBEWGQHJ3WGPX4ODZUAAWH4DHBB",
    "assetId": "SOLANA.BITARD-2ZZAE2GNQSPZLEFINZBEWGQHJ3WGPX4ODZUAAWH4DHBB",
    "assetAddress": "2ZZaE2gNQSPZLEFiNzBewgQhj3wGpx4oDzuAawh4DHBB",
    "assetName": "BITARD",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bitard-2zzae2gnqspzlefinzbewgqhj3wgpx4odzuaawh4dhbb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$TURBO-42KMNHEUHT3UAQFOAKNIPLGBJ1ZM427HEJDQCHGPTNJC",
    "assetId": "SOLANA.$TURBO-42KMNHEUHT3UAQFOAKNIPLGBJ1ZM427HEJDQCHGPTNJC",
    "assetAddress": "42KmnheUHt3UaQFoAKnipLgBJ1Zm427heJdQcHGPtnjc",
    "assetName": "$TURBO",
    "decimals": 7,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.turbo-42kmnheuht3uaqfoakniplgbj1zm427hejdqchgptnjc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PU238-7P6ZGHDMWHVCH4LSIK2MOMBXQPGHFBSPSCESBXD8KNEC",
    "assetId": "SOLANA.PU238-7P6ZGHDMWHVCH4LSIK2MOMBXQPGHFBSPSCESBXD8KNEC",
    "assetAddress": "7p6zGHdmWHvCH4Lsik2MoMBXqPGhFbSPSceSBXd8KNEC",
    "assetName": "PU238",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pu238-7p6zghdmwhvch4lsik2mombxqpghfbspscesbxd8knec.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SUSDC-8-88881HU2JGMFCS9TMU5RR7AH7WBNBUXQDE4NR5ZMKYYY",
    "assetId": "SOLANA.SUSDC-8-88881HU2JGMFCS9TMU5RR7AH7WBNBUXQDE4NR5ZMKYYY",
    "assetAddress": "8",
    "assetName": "sUSDC",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.susdc-8-88881hu2jgmfcs9tmu5rr7ah7wbnbuxqde4nr5zmkyyy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.POVT-PHILR4JDZB9Z92RYT5XBXKCXMQ4PGB1LYJTYBII7AIS",
    "assetId": "SOLANA.POVT-PHILR4JDZB9Z92RYT5XBXKCXMQ4PGB1LYJTYBII7AIS",
    "assetAddress": "PhiLR4JDZB9z92rYT5xBXKCxmq4pGB1LYjtybii7aiS",
    "assetName": "POVT",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.povt-philr4jdzb9z92ryt5xbxkcxmq4pgb1lyjtybii7ais.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$PUPPA-8SHTEDXBWVM2C9ELXNHSH8PMKOWOVZJ3XGAZQFWFAPMX",
    "assetId": "SOLANA.$PUPPA-8SHTEDXBWVM2C9ELXNHSH8PMKOWOVZJ3XGAZQFWFAPMX",
    "assetAddress": "8shTEDxbwvM2C9ELXNHsh8pmkoWovzJ3xgazQFWFapMx",
    "assetName": "$PUPPA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.puppa-8shtedxbwvm2c9elxnhsh8pmkowovzj3xgazqfwfapmx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MURGAN-63KAW6KKS7FSQJ4RADJQDYFCB8QVEZZ2BJA9M81SEWPN",
    "assetId": "SOLANA.MURGAN-63KAW6KKS7FSQJ4RADJQDYFCB8QVEZZ2BJA9M81SEWPN",
    "assetAddress": "63kAw6KkS7fSqJ4RADJqdYFcB8QVEZz2BjA9m81sEwpN",
    "assetName": "Murgan",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.murgan-63kaw6kks7fsqj4radjqdyfcb8qvezz2bja9m81sewpn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$FORCE-9MJAMGHXBU5DRKNA9XPZFOZGSM5DCQ6BSNKZZDNRWSCC",
    "assetId": "SOLANA.$FORCE-9MJAMGHXBU5DRKNA9XPZFOZGSM5DCQ6BSNKZZDNRWSCC",
    "assetAddress": "9MjAmgHXbu5drkNa9XpzfozgsM5Dcq6bSnKZzdNrwscC",
    "assetName": "$FORCE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.force-9mjamghxbu5drkna9xpzfozgsm5dcq6bsnkzzdnrwscc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BONKSOL-BONK1YHKXEGLZZWTCVRTIP3GAL9NCEQD7PPZBLXHTTS",
    "assetId": "SOLANA.BONKSOL-BONK1YHKXEGLZZWTCVRTIP3GAL9NCEQD7PPZBLXHTTS",
    "assetAddress": "BonK1YhkXEGLZzwtcvRTip3gAL9nCeQD7ppZBLXhtTs",
    "assetName": "bonkSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bonksol-bonk1yhkxeglzzwtcvrtip3gal9nceqd7ppzblxhtts.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WVE-33MJJPS6H7P2NWD648UITIUU8V5FJ1ZKYJNVQEWZ344F",
    "assetId": "SOLANA.WVE-33MJJPS6H7P2NWD648UITIUU8V5FJ1ZKYJNVQEWZ344F",
    "assetAddress": "33MJjPS6H7P2nwD648uiTiUu8v5fJ1zKyjnVqeWZ344F",
    "assetName": "WVE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wve-33mjjps6h7p2nwd648uitiuu8v5fj1zkyjnvqewz344f.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FBZ-BKYDRTNDAMJ8B4ZPVA3YHWUQCPVASYZAGJNKA6F44FX7",
    "assetId": "SOLANA.FBZ-BKYDRTNDAMJ8B4ZPVA3YHWUQCPVASYZAGJNKA6F44FX7",
    "assetAddress": "BKydRTNdaMJ8B4zPva3YhwUQcpvAsyZaGJnKA6F44fX7",
    "assetName": "FBZ",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fbz-bkydrtndamj8b4zpva3yhwuqcpvasyzagjnka6f44fx7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CLOCKSOL-GRJQTWWDJMP5LLPY8JWJPGN5FNLYQSJGNHN5ZNCTFUWM",
    "assetId": "SOLANA.CLOCKSOL-GRJQTWWDJMP5LLPY8JWJPGN5FNLYQSJGNHN5ZNCTFUWM",
    "assetAddress": "GRJQtWwdJmp5LLpy8JWjPgn5FnLyqSJGNhn5ZnCTFUwM",
    "assetName": "clockSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.clocksol-grjqtwwdjmp5llpy8jwjpgn5fnlyqsjgnhn5znctfuwm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$GERTA-DLVWONT1D5ISX2T1AUUBZRDGBSEF2XW3MWJBKTEWVW6H",
    "assetId": "SOLANA.$GERTA-DLVWONT1D5ISX2T1AUUBZRDGBSEF2XW3MWJBKTEWVW6H",
    "assetAddress": "DLvWoNT1d5iSX2T1aUUBzRdGbSef2xW3MwJBKtewVW6h",
    "assetName": "$gerta",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gerta-dlvwont1d5isx2t1auubzrdgbsef2xw3mwjbktewvw6h.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KIWI-66QQ2QS67K4L5XQ3XUTINCYXZDPEZQG1R1IPK8JRY7GC",
    "assetId": "SOLANA.KIWI-66QQ2QS67K4L5XQ3XUTINCYXZDPEZQG1R1IPK8JRY7GC",
    "assetAddress": "66Qq2qS67K4L5xQ3xUTinCyxzdPeZQG1R1ipK8jrY7gc",
    "assetName": "KIWI",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.kiwi-66qq2qs67k4l5xq3xutincyxzdpezqg1r1ipk8jry7gc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TPC-5WWRMYPCHXGH3VMYGPQOQ2KFZCTBLXXB9VFH2TEFER9M",
    "assetId": "SOLANA.TPC-5WWRMYPCHXGH3VMYGPQOQ2KFZCTBLXXB9VFH2TEFER9M",
    "assetAddress": "5WWRMYPchxgh3VmYGPqoq2kfzCtBLxXB9vFH2TeFeR9m",
    "assetName": "TPC",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tpc-5wwrmypchxgh3vmygpqoq2kfzctblxxb9vfh2tefer9m.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.POW-WA1MARTTML1YYTWEBJYHZZDTEHQRD5FGWSUKX7IIQKU",
    "assetId": "SOLANA.POW-WA1MARTTML1YYTWEBJYHZZDTEHQRD5FGWSUKX7IIQKU",
    "assetAddress": "wA1MArtTmL1yYtweBjyHZZdteHQRd5fgwsUKx7iiqKU",
    "assetName": "POW",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pow-wa1marttml1yytwebjyhzzdtehqrd5fgwsukx7iiqku.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LEIA-9EPYANQZJLSPSFH9AKSXN2EAJOXHS1OQT7PVBR3YIVT",
    "assetId": "SOLANA.LEIA-9EPYANQZJLSPSFH9AKSXN2EAJOXHS1OQT7PVBR3YIVT",
    "assetAddress": "9EPYaNqzJLSPSFH9aKSxn2EaJoxHS1oqt7pVBR3yivt",
    "assetName": "Leia",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.leia-9epyanqzjlspsfh9aksxn2eajoxhs1oqt7pvbr3yivt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CGNTSOL-CGNTSOL3DGY9SFHXCLJ6CGCGKKOTBR6TP4CPAEWY25DE",
    "assetId": "SOLANA.CGNTSOL-CGNTSOL3DGY9SFHXCLJ6CGCGKKOTBR6TP4CPAEWY25DE",
    "assetAddress": "CgnTSoL3DgY9SFHxcLj6CgCgKKoTBr6tp4CPAEWy25DE",
    "assetName": "cgntSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cgntsol-cgntsol3dgy9sfhxclj6cgcgkkotbr6tp4cpaewy25de.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PUMPMAN-BU7MDYQDEYMWB7GPYAHF7MRXSMT9DNMZN7O3AZAVYL1R",
    "assetId": "SOLANA.PUMPMAN-BU7MDYQDEYMWB7GPYAHF7MRXSMT9DNMZN7O3AZAVYL1R",
    "assetAddress": "BU7mDyqDEYMwB7gPyAHf7MRXSmT9DNMZn7o3AZavYL1R",
    "assetName": "PUMPMAN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pumpman-bu7mdyqdeymwb7gpyahf7mrxsmt9dnmzn7o3azavyl1r.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KREECHURE-3XWZTYP5G2KU5CNZLXYCBYYGVTOXKDJW73GSRQ73QYLQ",
    "assetId": "SOLANA.KREECHURE-3XWZTYP5G2KU5CNZLXYCBYYGVTOXKDJW73GSRQ73QYLQ",
    "assetAddress": "3XwZtyP5g2ku5cNzLXYcBYygvToXKdjw73GSRq73QYLQ",
    "assetName": "KREECHURE",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.kreechure-3xwztyp5g2ku5cnzlxycbyygvtoxkdjw73gsrq73qylq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SUSDT-9-AEUT5UFM1D575FVCOQD5YQ891FJEQKNCZUBBFOFCAHTV",
    "assetId": "SOLANA.SUSDT-9-AEUT5UFM1D575FVCOQD5YQ891FJEQKNCZUBBFOFCAHTV",
    "assetAddress": "9",
    "assetName": "sUSDT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.susdt-9-aeut5ufm1d575fvcoqd5yq891fjeqknczubbfofcahtv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DOODOO-JDWZFSXCUVLUBUB9XAUUZNVH4BBCEJCUM9TEZPMRHVWF",
    "assetId": "SOLANA.DOODOO-JDWZFSXCUVLUBUB9XAUUZNVH4BBCEJCUM9TEZPMRHVWF",
    "assetAddress": "JDwzFSxcUvLubUb9xAuuZNvh4bbcEJcuM9TezpmRHVWF",
    "assetName": "doodoo",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.doodoo-jdwzfsxcuvlubub9xauuznvh4bbcejcum9tezpmrhvwf.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GCR-791HZNICJY1QGSGZAVQUU8X6GEJIBJ2MBV8JJYOVNZBR",
    "assetId": "SOLANA.GCR-791HZNICJY1QGSGZAVQUU8X6GEJIBJ2MBV8JJYOVNZBR",
    "assetAddress": "791hZNiCJy1qGSGzAvqUU8X6gejiBJ2mBV8JjYoVnzBR",
    "assetName": "GCR",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gcr-791hznicjy1qgsgzavquu8x6gejibj2mbv8jjyovnzbr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LILPUMP-9VRGUHWSC8LYLJQOH3ZJB9S53X7A88U49LA63QPB6F5T",
    "assetId": "SOLANA.LILPUMP-9VRGUHWSC8LYLJQOH3ZJB9S53X7A88U49LA63QPB6F5T",
    "assetAddress": "9vrGUHwsC8LyLjQoh3zJb9S53x7A88u49La63qPB6F5t",
    "assetName": "lilpump",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lilpump-9vrguhwsc8lyljqoh3zjb9s53x7a88u49la63qpb6f5t.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GREEN-7VUUICQKYFVMEWG1U4W4E9K4FOEYDTCYYXYCPHRNF5QY",
    "assetId": "SOLANA.GREEN-7VUUICQKYFVMEWG1U4W4E9K4FOEYDTCYYXYCPHRNF5QY",
    "assetAddress": "7VUUicqKYFVmewg1U4w4e9K4FoEYdTcYyxYCPhRNf5Qy",
    "assetName": "green",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.green-7vuuicqkyfvmewg1u4w4e9k4foeydtcyyxycphrnf5qy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AXSET-HYSWCBHIYY9888PHBAQHWLYZQEZRCQMXKQWRQS7ZCPK5",
    "assetId": "SOLANA.AXSET-HYSWCBHIYY9888PHBAQHWLYZQEZRCQMXKQWRQS7ZCPK5",
    "assetAddress": "HysWcbHiYY9888pHbaqhwLYZQeZrcQMXKQWRqS7zcPK5",
    "assetName": "AXSet",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.axset-hyswcbhiyy9888phbaqhwlyzqezrcqmxkqwrqs7zcpk5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$KEIF-9QGXH6RJULX5IZVGRU1OVZACKRSBZQOE415MXHUZJKKH",
    "assetId": "SOLANA.$KEIF-9QGXH6RJULX5IZVGRU1OVZACKRSBZQOE415MXHUZJKKH",
    "assetAddress": "9QgXH6RjuLx5izvgRU1ovzackRsbzQoe415mxHUZJkkH",
    "assetName": "$KEIF",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.keif-9qgxh6rjulx5izvgru1ovzackrsbzqoe415mxhuzjkkh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DFTZ-71WSPPM44SUTWDFCCDLKSDEZW4ZBXDRPJHZ2REA7LO97",
    "assetId": "SOLANA.DFTZ-71WSPPM44SUTWDFCCDLKSDEZW4ZBXDRPJHZ2REA7LO97",
    "assetAddress": "71WsPPm44SUTWDfcCdLKsDezw4ZBXDrPjHz2rea7Lo97",
    "assetName": "DFTZ",
    "decimals": 7,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dftz-71wsppm44sutwdfccdlksdezw4zbxdrpjhz2rea7lo97.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SWAG-FAXYQ3LVXP51RDP2YWGLWVRFAAHESDFF8SGZXWJ2DVOR",
    "assetId": "SOLANA.SWAG-FAXYQ3LVXP51RDP2YWGLWVRFAAHESDFF8SGZXWJ2DVOR",
    "assetAddress": "FaxYQ3LVXP51rDP2yWGLWVrFAAHeSdFF8SGZxwj2dvor",
    "assetName": "SWAG",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.swag-faxyq3lvxp51rdp2ywglwvrfaahesdff8sgzxwj2dvor.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JURDEN-HYWB4HKYFDSA1I4T4CQAK9CVLHG4NQBRZGX9TZMEMGDR",
    "assetId": "SOLANA.JURDEN-HYWB4HKYFDSA1I4T4CQAK9CVLHG4NQBRZGX9TZMEMGDR",
    "assetAddress": "HYWB4HkyfDSA1i4t4cQaK9cvLHG4nQBRzGX9tzmEmgDr",
    "assetName": "JURDEN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jurden-hywb4hkyfdsa1i4t4cqak9cvlhg4nqbrzgx9tzmemgdr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NLTK-3UNAEVHAMUZKKQDTDLZMHNVQD8R14TXUUXX5PN48UBYC",
    "assetId": "SOLANA.NLTK-3UNAEVHAMUZKKQDTDLZMHNVQD8R14TXUUXX5PN48UBYC",
    "assetAddress": "3uNAevHamuZKKQdtdLzmHNvqD8r14tXUUXx5PN48UbYC",
    "assetName": "NLTK",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nltk-3unaevhamuzkkqdtdlzmhnvqd8r14txuuxx5pn48ubyc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HOPE-9ZHVJCCE3KHBYQGOJYBM9GBTE76RVOMJM36EGCCMPS2S",
    "assetId": "SOLANA.HOPE-9ZHVJCCE3KHBYQGOJYBM9GBTE76RVOMJM36EGCCMPS2S",
    "assetAddress": "9Zhvjcce3kHbYQGojybM9GbtE76rvoMJM36egCcmPs2s",
    "assetName": "HOPE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hope-9zhvjcce3khbyqgojybm9gbte76rvomjm36egccmps2s.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.POOTI-2NDPIBBOQGHCBURYNWCQZC5FCPXBZKQ1T37PR5ANFCZN",
    "assetId": "SOLANA.POOTI-2NDPIBBOQGHCBURYNWCQZC5FCPXBZKQ1T37PR5ANFCZN",
    "assetAddress": "2nDpiBboQgHcBuRyNwCQzc5fcpxBZKQ1t37pr5aNFczn",
    "assetName": "POOTI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pooti-2ndpibboqghcburynwcqzc5fcpxbzkq1t37pr5anfczn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SMEAN-SMEANEBFMND9UTYPYNTGZBMTMZEUKRFWCJECNXT2E8Z",
    "assetId": "SOLANA.SMEAN-SMEANEBFMND9UTYPYNTGZBMTMZEUKRFWCJECNXT2E8Z",
    "assetAddress": "sMEANebFMnd9uTYpyntGzBmTmzEukRFwCjEcnXT2E8z",
    "assetName": "sMEAN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.smean-smeanebfmnd9utypyntgzbmtmzeukrfwcjecnxt2e8z.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DINOEGG-2TXM6S3ZOZRBHZGHEPH9CTM74A9SVXBR7NQ7UXKRVQIJ",
    "assetId": "SOLANA.DINOEGG-2TXM6S3ZOZRBHZGHEPH9CTM74A9SVXBR7NQ7UXKRVQIJ",
    "assetAddress": "2TxM6S3ZozrBHZGHEPh9CtM74a9SVXbr7NQ7UxkRvQij",
    "assetName": "DINOEGG",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dinoegg-2txm6s3zozrbhzgheph9ctm74a9svxbr7nq7uxkrvqij.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GYC-GYCVDMDTHKF3JSZ5NS6FKZCMHUB7FSZXJVCFBFGQKH7P",
    "assetId": "SOLANA.GYC-GYCVDMDTHKF3JSZ5NS6FKZCMHUB7FSZXJVCFBFGQKH7P",
    "assetAddress": "GYCVdmDthkf3jSz5ns6fkzCmHub7FSZxjVCfbfGqkH7P",
    "assetName": "GYC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gyc-gycvdmdthkf3jsz5ns6fkzcmhub7fszxjvcfbfgqkh7p.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FANT-FANTAFPFBAT93BNJVPDU25PGPMCA3RFWDSDSRRT3LX1R",
    "assetId": "SOLANA.FANT-FANTAFPFBAT93BNJVPDU25PGPMCA3RFWDSDSRRT3LX1R",
    "assetAddress": "FANTafPFBAt93BNJVpdu25pGPmca3RfwdsDsRrT3LX1r",
    "assetName": "FANT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fant-fantafpfbat93bnjvpdu25pgpmca3rfwdsdsrrt3lx1r.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.USDTPO-5GOWRAO6A3YNC4D6UJMDQXONKCMVKBWDPUBU3QHFCDF1",
    "assetId": "SOLANA.USDTPO-5GOWRAO6A3YNC4D6UJMDQXONKCMVKBWDPUBU3QHFCDF1",
    "assetAddress": "5goWRao6a3yNC4d6UjMdQxonkCMvKBwdpubU3qhfcdf1",
    "assetName": "USDTpo",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.usdtpo-5gowrao6a3ync4d6ujmdqxonkcmvkbwdpubu3qhfcdf1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.XCOPE-3K6RFTDAAQYMPUNRTNRHGNK2UATJM2JWYT2OCITDOUYE",
    "assetId": "SOLANA.XCOPE-3K6RFTDAAQYMPUNRTNRHGNK2UATJM2JWYT2OCITDOUYE",
    "assetAddress": "3K6rftdAaQYMPunrtNRHgnK2UAtjm2JwyT2oCiTDouYE",
    "assetName": "XCOPE",
    "decimals": 0,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.xcope-3k6rftdaaqympunrtnrhgnk2uatjm2jwyt2ocitdouye.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PURI-CZLWMVJTJ5BP9YEWKZZBNUWOVVTOGEUCHFU5GEFH68HB",
    "assetId": "SOLANA.PURI-CZLWMVJTJ5BP9YEWKZZBNUWOVVTOGEUCHFU5GEFH68HB",
    "assetAddress": "CzLWmvjtj5bp9YEWkzzBnuWovVtogeuchFu5GeFh68hB",
    "assetName": "Puri",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.puri-czlwmvjtj5bp9yewkzzbnuwovvtogeuchfu5gefh68hb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OLYMP24-4E1SQNAJN9JEAP7KYJWRQP3V4ED1DSKPYGYBFFQM11UA",
    "assetId": "SOLANA.OLYMP24-4E1SQNAJN9JEAP7KYJWRQP3V4ED1DSKPYGYBFFQM11UA",
    "assetAddress": "4e1SQnaJn9JEAp7KYJwrQp3V4ED1dSkPygYbfFQM11uA",
    "assetName": "OLYMP24",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.olymp24-4e1sqnajn9jeap7kyjwrqp3v4ed1dskpygybffqm11ua.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FROGGO-A12XGGFFK3B5GCD6ZYXUQ55CQBXARHL4H7JXS3GQCDC3",
    "assetId": "SOLANA.FROGGO-A12XGGFFK3B5GCD6ZYXUQ55CQBXARHL4H7JXS3GQCDC3",
    "assetAddress": "A12XggFFk3b5GCd6ZYxuQ55cQbxarHL4h7Jxs3GQcdC3",
    "assetName": "Froggo",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.froggo-a12xggffk3b5gcd6zyxuq55cqbxarhl4h7jxs3gqcdc3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MEWING-9BXAVJMUWSC71J8Z2PVUL3UAR1S5FCNWUPVYHQV9JTMW",
    "assetId": "SOLANA.MEWING-9BXAVJMUWSC71J8Z2PVUL3UAR1S5FCNWUPVYHQV9JTMW",
    "assetAddress": "9bxaVJmUwSc71j8Z2pvUL3UAr1s5fCnwUpvYhqV9jtmw",
    "assetName": "MEWING",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mewing-9bxavjmuwsc71j8z2pvul3uar1s5fcnwupvyhqv9jtmw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MUSHU-CF97PGA5ANHXYKERSYLBAZ1WYK6CSFRFKHD1ANSRD3SO",
    "assetId": "SOLANA.MUSHU-CF97PGA5ANHXYKERSYLBAZ1WYK6CSFRFKHD1ANSRD3SO",
    "assetAddress": "CF97pGa5aNhXYkERsYLbAZ1wyk6cSFrfKhd1ansRD3So",
    "assetName": "MuShu",
    "decimals": 10,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mushu-cf97pga5anhxykersylbaz1wyk6csfrfkhd1ansrd3so.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TYRESE-EKIWUG6K1AAYFF1UUGHXEYPQKSVTKCEHORKR8XWSONAA",
    "assetId": "SOLANA.TYRESE-EKIWUG6K1AAYFF1UUGHXEYPQKSVTKCEHORKR8XWSONAA",
    "assetAddress": "EKiwUg6K1aAyfF1uugHxEYPQksVTkCeHorkr8XwsoNAa",
    "assetName": "Tyrese",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tyrese-ekiwug6k1aayff1uughxeypqksvtkcehorkr8xwsonaa.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ZPET-4ANUSHLSYC4J6YJNEKRYMJKNL4ZJHCVRLXFUXGPMCLEQ",
    "assetId": "SOLANA.ZPET-4ANUSHLSYC4J6YJNEKRYMJKNL4ZJHCVRLXFUXGPMCLEQ",
    "assetAddress": "4AnuSHLSYc4J6yjNekrYMjknL4zJHCvRLXfUxgpmCLeQ",
    "assetName": "ZPET",
    "decimals": 3,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.zpet-4anushlsyc4j6yjnekrymjknl4zjhcvrlxfuxgpmcleq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BARK-APKM2BQZPWQAIV5BY2EA7VR7DEB1EX5MMF6JNCBCHYRC",
    "assetId": "SOLANA.BARK-APKM2BQZPWQAIV5BY2EA7VR7DEB1EX5MMF6JNCBCHYRC",
    "assetAddress": "APkM2bqzpwQaiv5BY2eA7vR7DEB1EX5MMf6JNcBCHYrC",
    "assetName": "BARK",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bark-apkm2bqzpwqaiv5by2ea7vr7deb1ex5mmf6jncbchyrc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.VOTEDOGE-98ZMJTNLJTR1YIH6D7FDFM8VIU1JEYDCBU1WJC2K9IMV",
    "assetId": "SOLANA.VOTEDOGE-98ZMJTNLJTR1YIH6D7FDFM8VIU1JEYDCBU1WJC2K9IMV",
    "assetAddress": "98ZmjtNLjTr1yih6D7fdFm8ViU1jEYDcBU1wjc2k9imV",
    "assetName": "VOTEDOGE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.votedoge-98zmjtnljtr1yih6d7fdfm8viu1jeydcbu1wjc2k9imv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SLCL-SLCLWW7NC1PD2GQPQDGAYHVIVVCPMTHNQUZ2IWKHNQV",
    "assetId": "SOLANA.SLCL-SLCLWW7NC1PD2GQPQDGAYHVIVVCPMTHNQUZ2IWKHNQV",
    "assetAddress": "SLCLww7nc1PD2gQPQdGayHviVVcpMthnqUz2iWKhNQV",
    "assetName": "SLCL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.slcl-slclww7nc1pd2gqpqdgayhvivvcpmthnquz2iwkhnqv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SEEDED-SEEDEDBQU63TJ7PFQVCBWVTHRYUKQEQT6NLF81KLIBS",
    "assetId": "SOLANA.SEEDED-SEEDEDBQU63TJ7PFQVCBWVTHRYUKQEQT6NLF81KLIBS",
    "assetAddress": "seedEDBqu63tJ7PFqvcbwvThrYUkQeqT6NLf81kLibs",
    "assetName": "SEEDED",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.seeded-seededbqu63tj7pfqvcbwvthryukqeqt6nlf81klibs.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CREDITS-4PTCYKUYPE7SDH82OXAEYKAAJUNUB4YEDJEILJWS2NQC",
    "assetId": "SOLANA.CREDITS-4PTCYKUYPE7SDH82OXAEYKAAJUNUB4YEDJEILJWS2NQC",
    "assetAddress": "4ptcYkUypE7sDH82oXaeykaAJunuB4yeDJeiLJwS2nQc",
    "assetName": "CREDITS",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.credits-4ptcykuype7sdh82oxaeykaajunub4yedjeiljws2nqc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BUSDET-33FSBLA8DJQM82RPHME3SUVRPGTZBWNYEXSEUEKX1HXX",
    "assetId": "SOLANA.BUSDET-33FSBLA8DJQM82RPHME3SUVRPGTZBWNYEXSEUEKX1HXX",
    "assetAddress": "33fsBLA8djQm82RpHmE3SuVrPGtZBWNYExsEUeKX1HXX",
    "assetName": "BUSDet",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.busdet-33fsbla8djqm82rphme3suvrpgtzbwnyexseuekx1hxx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ARTE-6DUJEWCXN1QCD6RCJ448SXQL9YYQTCQZCNQDCN3XJAKS",
    "assetId": "SOLANA.ARTE-6DUJEWCXN1QCD6RCJ448SXQL9YYQTCQZCNQDCN3XJAKS",
    "assetAddress": "6Dujewcxn1qCd6rcj448SXQL9YYqTcqZCNQdCn3xJAKS",
    "assetName": "ARTE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.arte-6dujewcxn1qcd6rcj448sxql9yyqtcqzcnqdcn3xjaks.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TENKAI-TKDRCM3N4MFXFFPKZOLP5SORSDFQSMYWLDOMDKL3KTU",
    "assetId": "SOLANA.TENKAI-TKDRCM3N4MFXFFPKZOLP5SORSDFQSMYWLDOMDKL3KTU",
    "assetAddress": "TKDrcm3n4mfXFfPKZoLp5soRSdFQSmyWLdomdKL3ktU",
    "assetName": "TENKAI",
    "decimals": 0,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tenkai-tkdrcm3n4mfxffpkzolp5sorsdfqsmywldomdkl3ktu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CLDOGE-DD6PDE7K4J7YB691GW8K177TWD1HUY6RKFTWRBKZ8FRE",
    "assetId": "SOLANA.CLDOGE-DD6PDE7K4J7YB691GW8K177TWD1HUY6RKFTWRBKZ8FRE",
    "assetAddress": "Dd6Pde7K4J7yB691gW8K177TWd1Huy6RkfTwrbkz8Fre",
    "assetName": "clDOGE",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cldoge-dd6pde7k4j7yb691gw8k177twd1huy6rkftwrbkz8fre.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HEIST-FJBEDZF7WRWMO4YD3TC2U6INDEEQXTDEWZ514HYXH5X9",
    "assetId": "SOLANA.HEIST-FJBEDZF7WRWMO4YD3TC2U6INDEEQXTDEWZ514HYXH5X9",
    "assetAddress": "FjBedzf7WrWmo4yd3Tc2U6indeEQXtdewZ514hYxH5X9",
    "assetName": "HEIST",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.heist-fjbedzf7wrwmo4yd3tc2u6indeeqxtdewz514hyxh5x9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TWTR-FCSCANDN3TRPMWCGQHJ1E5GUEH1RNESBGVN5WTZUAD1Z",
    "assetId": "SOLANA.TWTR-FCSCANDN3TRPMWCGQHJ1E5GUEH1RNESBGVN5WTZUAD1Z",
    "assetAddress": "FcScaNdN3TRPMwcgqHj1E5GuEh1rNesBGVN5WtZuad1z",
    "assetName": "TWTR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.twtr-fcscandn3trpmwcgqhj1e5gueh1rnesbgvn5wtzuad1z.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MAGA-TRUMPTPNNBEGVJDC8BNEMRTNYZKHDSST9UJNANTSHQP",
    "assetId": "SOLANA.MAGA-TRUMPTPNNBEGVJDC8BNEMRTNYZKHDSST9UJNANTSHQP",
    "assetAddress": "TrumptpNNBEgVjDc8bnemRTNYZKhdsst9ujNAnTSHqp",
    "assetName": "maga",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.maga-trumptpnnbegvjdc8bnemrtnyzkhdsst9ujnantshqp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BIJU-4ONZDS1X6UBKTIRORHB8IYBVE3K4BBTKGPYEHQZGM9SO",
    "assetId": "SOLANA.BIJU-4ONZDS1X6UBKTIRORHB8IYBVE3K4BBTKGPYEHQZGM9SO",
    "assetAddress": "4onzDs1X6ubktirorHB8iYbve3K4bBtkGpYehqzGm9So",
    "assetName": "BIJU",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.biju-4onzds1x6ubktirorhb8iybve3k4bbtkgpyehqzgm9so.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SVIZ-GV6N9UOW3XZMWSS8VWTCML8SVMA6OZBIDAEFDPOSORAQ",
    "assetId": "SOLANA.SVIZ-GV6N9UOW3XZMWSS8VWTCML8SVMA6OZBIDAEFDPOSORAQ",
    "assetAddress": "GV6n9Uow3XzMWSs8vwTCML8SvMA6ozbidaEfdPoSoraQ",
    "assetName": "SVIZ",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sviz-gv6n9uow3xzmwss8vwtcml8svma6ozbidaefdposoraq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOSUSHI-AR1MTGH7ZATXUXGD2XPOVXPVJCSDY3I4RQYISNADJFKY",
    "assetId": "SOLANA.SOSUSHI-AR1MTGH7ZATXUXGD2XPOVXPVJCSDY3I4RQYISNADJFKY",
    "assetAddress": "AR1Mtgh7zAtxuxGd2XPovXPVjcSdY3i4rQYisNadjfKy",
    "assetName": "soSUSHI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sosushi-ar1mtgh7zatxuxgd2xpovxpvjcsdy3i4rqyisnadjfky.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KRILL-EP2AYBDD4WVDHNWWLUMYQU69G1EPTEJGYK6QYEAFCHTX",
    "assetId": "SOLANA.KRILL-EP2AYBDD4WVDHNWWLUMYQU69G1EPTEJGYK6QYEAFCHTX",
    "assetAddress": "EP2aYBDD4WvdhnwWLUMyqU69g1ePtEjgYK6qyEAFCHTx",
    "assetName": "KRILL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.krill-ep2aybdd4wvdhnwwlumyqu69g1eptejgyk6qyeafchtx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KENG-BKYKA4H1S4717FHBGFHDXKKF4UZVVSWB4UXQHBWX8FUU",
    "assetId": "SOLANA.KENG-BKYKA4H1S4717FHBGFHDXKKF4UZVVSWB4UXQHBWX8FUU",
    "assetAddress": "BKykA4H1s4717FhBGFhDXkKF4UzvvsWB4uxqHBWX8FUU",
    "assetName": "KENG",
    "decimals": 3,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.keng-bkyka4h1s4717fhbgfhdxkkf4uzvvswb4uxqhbwx8fuu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ELE-8A9HYFJ9WAMGJXARWVCJHAEQ9I8VDN9CERBMQUAMDJ7U",
    "assetId": "SOLANA.ELE-8A9HYFJ9WAMGJXARWVCJHAEQ9I8VDN9CERBMQUAMDJ7U",
    "assetAddress": "8A9HYfj9WAMgjxARWVCJHAeq9i8vdN9cerBmqUamDj7U",
    "assetName": "ELE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ele-8a9hyfj9wamgjxarwvcjhaeq9i8vdn9cerbmquamdj7u.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MEND-CH9NFVK5SQEPQHTW2GJVGNHFTM7FW1JSPYWC7SXLI6Q3",
    "assetId": "SOLANA.MEND-CH9NFVK5SQEPQHTW2GJVGNHFTM7FW1JSPYWC7SXLI6Q3",
    "assetAddress": "Ch9NFVk5sqEPQHtw2gJVgnHfTm7FW1JspYwc7SxLi6q3",
    "assetName": "MEND",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mend-ch9nfvk5sqepqhtw2gjvgnhftm7fw1jspywc7sxli6q3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GRAF-9EL3CHVQS3NWUFHYVT7AGBTTRSBJ5UE8QJNW5ZATKUHR",
    "assetId": "SOLANA.GRAF-9EL3CHVQS3NWUFHYVT7AGBTTRSBJ5UE8QJNW5ZATKUHR",
    "assetAddress": "9EL3CHVQS3nwUFhyVT7AGbttRsbJ5UE8Qjnw5ZAtkUhr",
    "assetName": "GRAF",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.graf-9el3chvqs3nwufhyvt7agbttrsbj5ue8qjnw5zatkuhr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HUBSOL-HUBSVENPJO5PWQNKH57QZXJQASDTVXCSK7BVKTSZTCSX",
    "assetId": "SOLANA.HUBSOL-HUBSVENPJO5PWQNKH57QZXJQASDTVXCSK7BVKTSZTCSX",
    "assetAddress": "HUBsveNpjo5pWqNkH57QzxjQASdTVXcSK7bVKTSZtcSX",
    "assetName": "hubSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hubsol-hubsvenpjo5pwqnkh57qzxjqasdtvxcsk7bvktsztcsx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.YPRT-YPRTUPLDFTNEJ7P6QOFNYGRARRXSM6MVKZOHJ4BH4WM",
    "assetId": "SOLANA.YPRT-YPRTUPLDFTNEJ7P6QOFNYGRARRXSM6MVKZOHJ4BH4WM",
    "assetAddress": "yPRTUpLDftNej7p6QofNYgRArRXsm6Mvkzohj4bh4WM",
    "assetName": "yPRT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.yprt-yprtupldftnej7p6qofnygrarrxsm6mvkzohj4bh4wm.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ABUSDC-8XSSNVAKU9FDHYWAV7YC7QSNWUJSZVRXBNEK7AFIWF69",
    "assetId": "SOLANA.ABUSDC-8XSSNVAKU9FDHYWAV7YC7QSNWUJSZVRXBNEK7AFIWF69",
    "assetAddress": "8XSsNvaKU9FDhYWAv7Yc7qSNwuJSzVrXBNEk7AFiWF69",
    "assetName": "abUSDC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.abusdc-8xssnvaku9fdhywav7yc7qsnwujszvrxbnek7afiwf69.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SCALES-8FA3TPF84H9GEZAXDHILKMN4VVZCDEUZXMGADBO12AUH",
    "assetId": "SOLANA.SCALES-8FA3TPF84H9GEZAXDHILKMN4VVZCDEUZXMGADBO12AUH",
    "assetAddress": "8FA3TPf84h9gEZAxDhiLkMN4vvZcdEUZxmGadbo12auh",
    "assetName": "SCALES",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.scales-8fa3tpf84h9gezaxdhilkmn4vvzcdeuzxmgadbo12auh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PWRSOL-PWRSOLAHUE6JUXUKBWGMEY5RD9VJZKFMVFTDV5KGNUU",
    "assetId": "SOLANA.PWRSOL-PWRSOLAHUE6JUXUKBWGMEY5RD9VJZKFMVFTDV5KGNUU",
    "assetAddress": "pWrSoLAhue6jUxUkbWgmEy5rD9VJzkFmvfTDV5KgNuu",
    "assetName": "pwrSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pwrsol-pwrsolahue6juxukbwgmey5rd9vjzkfmvftdv5kgnuu.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SRENBTC-9-FACTQHZBFRZC7A76ANTNPAOZTIWYMUFDAN8WZ7E8RXC5",
    "assetId": "SOLANA.SRENBTC-9-FACTQHZBFRZC7A76ANTNPAOZTIWYMUFDAN8WZ7E8RXC5",
    "assetAddress": "9",
    "assetName": "srenBTC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.srenbtc-9-factqhzbfrzc7a76antnpaoztiwymufdan8wz7e8rxc5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LEGEND-LGNDEXXXADDERERWWHFUTPBNZ5S6VRN1NMST9HDACWX",
    "assetId": "SOLANA.LEGEND-LGNDEXXXADDERERWWHFUTPBNZ5S6VRN1NMST9HDACWX",
    "assetAddress": "LGNDeXXXaDDeRerwwHfUtPBNz5s6vrn1NMSt9hdaCwx",
    "assetName": "LEGEND",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.legend-lgndexxxaddererwwhfutpbnz5s6vrn1nmst9hdacwx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WIZE-7RRJLRAR2WJZWROF3IJKHKNA7D7D9YJT1X9HAJNWUH3Z",
    "assetId": "SOLANA.WIZE-7RRJLRAR2WJZWROF3IJKHKNA7D7D9YJT1X9HAJNWUH3Z",
    "assetAddress": "7rrJLRar2WjZwRoF3iJKHKnA7d7d9YJT1X9HAJnwUH3Z",
    "assetName": "WIZE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wize-7rrjlrar2wjzwrof3ijkhkna7d7d9yjt1x9hajnwuh3z.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HEEHEE-9DLUVBJMD4ZPTPFGMAFHAGSSFWVJTCNZFWALAA1EXPHG",
    "assetId": "SOLANA.HEEHEE-9DLUVBJMD4ZPTPFGMAFHAGSSFWVJTCNZFWALAA1EXPHG",
    "assetAddress": "9dLuVbJMd4ZpTpFgmaFHAGSsFwVjtcnzFWaLAA1expHg",
    "assetName": "HEEHEE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.heehee-9dluvbjmd4zptpfgmafhagssfwvjtcnzfwalaa1exphg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHADS-DJZG1GPN2COTDV9CVKHO4KXD3POY2VJJEGHCIVV8U8WJ",
    "assetId": "SOLANA.CHADS-DJZG1GPN2COTDV9CVKHO4KXD3POY2VJJEGHCIVV8U8WJ",
    "assetAddress": "DjzG1gPn2cotdV9cvkho4KxD3Poy2VjJeghCivv8U8Wj",
    "assetName": "CHADS",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chads-djzg1gpn2cotdv9cvkho4kxd3poy2vjjeghcivv8u8wj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SBTC-9-9999J2A8SXUTHTDOQDK528OVZHAKBSXYRGZ67FKGOI7H",
    "assetId": "SOLANA.SBTC-9-9999J2A8SXUTHTDOQDK528OVZHAKBSXYRGZ67FKGOI7H",
    "assetAddress": "9",
    "assetName": "sBTC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sbtc-9-9999j2a8sxuthtdoqdk528ovzhakbsxyrgz67fkgoi7h.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DUK-A2KHRBHRJNRAEHJ95HTIVC4CR4VBJWFSSDH5FPPBP4M9",
    "assetId": "SOLANA.DUK-A2KHRBHRJNRAEHJ95HTIVC4CR4VBJWFSSDH5FPPBP4M9",
    "assetAddress": "A2khRbhRJNrAEHj95htivC4cR4VbJwfssDH5FPPbP4m9",
    "assetName": "duk",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.duk-a2khrbhrjnraehj95htivc4cr4vbjwfssdh5fppbp4m9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FPHX-6GX6PH2EK73KF6EWDRG4GQ54PCLJB6CYPATURYXKXUMO",
    "assetId": "SOLANA.FPHX-6GX6PH2EK73KF6EWDRG4GQ54PCLJB6CYPATURYXKXUMO",
    "assetAddress": "6gx6Ph2ek73kF6EWDrG4GQ54pcLJB6CYpATuRyxKXumo",
    "assetName": "fPHX",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fphx-6gx6ph2ek73kf6ewdrg4gq54pcljb6cypaturyxkxumo.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KINI-GV4PBBDAP1APVF6PPFR4VVL6BTNG4E7VY91GMKHQPUMP",
    "assetId": "SOLANA.KINI-GV4PBBDAP1APVF6PPFR4VVL6BTNG4E7VY91GMKHQPUMP",
    "assetAddress": "GV4pBbdAp1Apvf6PPFR4VvL6BtNg4E7VY91GmkHqpump",
    "assetName": "Kini",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.kini-gv4pbbdap1apvf6ppfr4vvl6btng4e7vy91gmkhqpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OPPLE-BG5DP9GU5WBKHEAZ6Y95APB5NVPW3JC17M4RO27SGSXP",
    "assetId": "SOLANA.OPPLE-BG5DP9GU5WBKHEAZ6Y95APB5NVPW3JC17M4RO27SGSXP",
    "assetAddress": "BG5Dp9gU5WbkHEaz6y95apb5NVPw3jC17M4ro27sgSXP",
    "assetName": "opple",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.opple-bg5dp9gu5wbkheaz6y95apb5nvpw3jc17m4ro27sgsxp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NVDA-9DWPISTDBWJJQC3QZMNJPJP7XOHZBMVMHELFX3UY3KRQ",
    "assetId": "SOLANA.NVDA-9DWPISTDBWJJQC3QZMNJPJP7XOHZBMVMHELFX3UY3KRQ",
    "assetAddress": "9dwPiStDBwJJqC3QzMnjpJP7xohZbMVmHELFx3uy3KRq",
    "assetName": "NVDA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nvda-9dwpistdbwjjqc3qzmnjpjp7xohzbmvmhelfx3uy3krq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MROW-GTEZ6QKRGHS4MZQ9NSPVGTFWSNLN1HFQ8RX5BSVKTGUB",
    "assetId": "SOLANA.MROW-GTEZ6QKRGHS4MZQ9NSPVGTFWSNLN1HFQ8RX5BSVKTGUB",
    "assetAddress": "GTeZ6qkRgHS4MZq9nSPvgtFWSNLn1HFq8rx5bSVktGub",
    "assetName": "MROW",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mrow-gtez6qkrghs4mzq9nspvgtfwsnln1hfq8rx5bsvktgub.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FJB-PRZDVHSIHALAYQAWCAQYCSVTBKXFT9FME28P19NP9VD",
    "assetId": "SOLANA.FJB-PRZDVHSIHALAYQAWCAQYCSVTBKXFT9FME28P19NP9VD",
    "assetAddress": "PRZdvhsihALAYQAwCaQYCSVTbkxfT9Fme28p19Np9VD",
    "assetName": "FJB",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fjb-przdvhsihalayqawcaqycsvtbkxft9fme28p19np9vd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HOWDY-PPVT3VQB323UBEW3QUTVDNCPQM1SPEZM8BT1TV9WALW",
    "assetId": "SOLANA.HOWDY-PPVT3VQB323UBEW3QUTVDNCPQM1SPEZM8BT1TV9WALW",
    "assetAddress": "ppVT3Vqb323UBEW3QuTvdNCpQm1spEZM8Bt1tv9WALW",
    "assetName": "HOWDY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.howdy-ppvt3vqb323ubew3qutvdncpqm1spezm8bt1tv9walw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.UGM-2EY5SA3XTTJAR6KGAVMX48MUTUAMRBT14JEWJQWJCC4H",
    "assetId": "SOLANA.UGM-2EY5SA3XTTJAR6KGAVMX48MUTUAMRBT14JEWJQWJCC4H",
    "assetAddress": "2Ey5sa3XTtjaR6KGaVmX48MUtUAmrBT14JeWjQWJcc4h",
    "assetName": "UGM",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ugm-2ey5sa3xttjar6kgavmx48mutuamrbt14jewjqwjcc4h.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DEV-H5EUUUZXAUFAK2NVTMU53FCKDKHFWUJZXXB3TFKTRLWK",
    "assetId": "SOLANA.DEV-H5EUUUZXAUFAK2NVTMU53FCKDKHFWUJZXXB3TFKTRLWK",
    "assetAddress": "H5euuuZXAuFak2NVTMu53fckdkHFWuJzXXb3TfKTrLWK",
    "assetName": "DEV",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dev-h5euuuzxaufak2nvtmu53fckdkhfwujzxxb3tfktrlwk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MAIL-C8CNX2D1Y3JQKPMFKQHP1GGBFVTEDECKZXLBKSN5Z5KF",
    "assetId": "SOLANA.MAIL-C8CNX2D1Y3JQKPMFKQHP1GGBFVTEDECKZXLBKSN5Z5KF",
    "assetAddress": "C8cNX2D1y3jqKpMFkQhP1gGbfvTEdeckZXLBKSN5z5KF",
    "assetName": "Mail",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mail-c8cnx2d1y3jqkpmfkqhp1ggbfvtedeckzxlbksn5z5kf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$DAUMEN-T1OYBAEJEESRZLTSAJUMAXHZFQZGNXQ4KVN9VPUOXMV",
    "assetId": "SOLANA.$DAUMEN-T1OYBAEJEESRZLTSAJUMAXHZFQZGNXQ4KVN9VPUOXMV",
    "assetAddress": "T1oYbAejEESrZLtSAjumAXhzFqZGNxQ4kVN9vPUoxMv",
    "assetName": "$daumen",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.daumen-t1oybaejeesrzltsajumaxhzfqzgnxq4kvn9vpuoxmv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BULLY-7QNTXCM7ZVMLDQNWZHFSM3RLKK8MRX3DYAVB4HQJV5F9",
    "assetId": "SOLANA.BULLY-7QNTXCM7ZVMLDQNWZHFSM3RLKK8MRX3DYAVB4HQJV5F9",
    "assetAddress": "7qnTXCm7ZVMLdqNWzhfSm3RLKk8MrX3dYavB4hqJv5F9",
    "assetName": "BULLY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bully-7qntxcm7zvmldqnwzhfsm3rlkk8mrx3dyavb4hqjv5f9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MIGO-8ODNQWCQNVQ4URYMYGKWPUEDBR3YEMWY2ZBHTNW7JRCU",
    "assetId": "SOLANA.MIGO-8ODNQWCQNVQ4URYMYGKWPUEDBR3YEMWY2ZBHTNW7JRCU",
    "assetAddress": "8odnqwCqNVQ4UrYmYgkWPuedBr3yEMWY2ZBhtNW7jrCu",
    "assetName": "MIGO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.migo-8odnqwcqnvq4urymygkwpuedbr3yemwy2zbhtnw7jrcu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PATS-35FN6LMYT6CKSEMGBR28NFOOIJTCNVAKPCERXYUMKFOF",
    "assetId": "SOLANA.PATS-35FN6LMYT6CKSEMGBR28NFOOIJTCNVAKPCERXYUMKFOF",
    "assetAddress": "35fN6LMYt6cKsemgbR28nFooiJtcnvaKPCeRXyuMKfoF",
    "assetName": "PATS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pats-35fn6lmyt6cksemgbr28nfooijtcnvakpcerxyumkfof.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DRAW-48AEWAUAHSJIBYT3WQJQ6EOHNFBCNYHASFO7VB2ECXPS",
    "assetId": "SOLANA.DRAW-48AEWAUAHSJIBYT3WQJQ6EOHNFBCNYHASFO7VB2ECXPS",
    "assetAddress": "48AEwauAHsJibyt3WqjQ6EoHnFBcnyHASfo7vB2eCXPS",
    "assetName": "DRAW",
    "decimals": 0,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.draw-48aewauahsjibyt3wqjq6eohnfbcnyhasfo7vb2ecxps.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MOTION-UIUPT55OWCQBJDUNYQXYYF48KRMU4F53XQAYF31DPZX",
    "assetId": "SOLANA.MOTION-UIUPT55OWCQBJDUNYQXYYF48KRMU4F53XQAYF31DPZX",
    "assetAddress": "Uiupt55owCqBJdunYQxyYf48KRMU4F53xQAyf31dpzx",
    "assetName": "MOTION",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.motion-uiupt55owcqbjdunyqxyyf48krmu4f53xqayf31dpzx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SLX-AASDD9RAEFJ4PP7IM89MYUSQEYCQWVBOFHCEZUGDH5HZ",
    "assetId": "SOLANA.SLX-AASDD9RAEFJ4PP7IM89MYUSQEYCQWVBOFHCEZUGDH5HZ",
    "assetAddress": "AASdD9rAefJ4PP7iM89MYUsQEyCQwvBofhceZUGDh5HZ",
    "assetName": "SLX",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.slx-aasdd9raefj4pp7im89myusqeycqwvbofhcezugdh5hz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RHO-TRHOR7NPQLCA4DFIUWR9VJCAW1JE2ZGHSBWH37NW81I",
    "assetId": "SOLANA.RHO-TRHOR7NPQLCA4DFIUWR9VJCAW1JE2ZGHSBWH37NW81I",
    "assetAddress": "Trhor7npQLca4DFiUWR9vJCAw1je2zghSbwh37nW81i",
    "assetName": "RHO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rho-trhor7npqlca4dfiuwr9vjcaw1je2zghsbwh37nw81i.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ACUSDC-DHPOYEJUDQZBYB6HADALWF7KZVWUV2VWYDY9CTENNZUI",
    "assetId": "SOLANA.ACUSDC-DHPOYEJUDQZBYB6HADALWF7KZVWUV2VWYDY9CTENNZUI",
    "assetAddress": "DHpoYejUDqzByb6HAdaLWF7KZvwUv2vWYDY9cTENNZui",
    "assetName": "acUSDC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.acusdc-dhpoyejudqzbyb6hadalwf7kzvwuv2vwydy9ctennzui.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JJJJC-6FLSSKF4AQQEDUQEMGPYBZVVEBC4WWWNTNZP2QZYBZG8",
    "assetId": "SOLANA.JJJJC-6FLSSKF4AQQEDUQEMGPYBZVVEBC4WWWNTNZP2QZYBZG8",
    "assetAddress": "6FLsSkF4AqQeDuqEmGPyBZvVebc4WWWntnZP2QZyBzG8",
    "assetName": "JJJJC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jjjjc-6flsskf4aqqeduqemgpybzvvebc4wwwntnzp2qzybzg8.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NCTR-AGNHZGSPNU7F3NFM4IZUPT5G7M1URJVATAFNGVQSXCJC",
    "assetId": "SOLANA.NCTR-AGNHZGSPNU7F3NFM4IZUPT5G7M1URJVATAFNGVQSXCJC",
    "assetAddress": "AgnHzGspNu7F3nFM4izuPt5g7m1URjVaTaFNgvqSXcjC",
    "assetName": "NCTR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nctr-agnhzgspnu7f3nfm4izupt5g7m1urjvatafngvqsxcjc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.USDCBS-FCQFQSUJUPXY6V42UVAFBHSYSWTEQ1VHJFMN1PUBGAXA",
    "assetId": "SOLANA.USDCBS-FCQFQSUJUPXY6V42UVAFBHSYSWTEQ1VHJFMN1PUBGAXA",
    "assetAddress": "FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA",
    "assetName": "USDCbs",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.usdcbs-fcqfqsujupxy6v42uvafbhsyswteq1vhjfmn1pubgaxa.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.N2H4-4Q5UBXJXE91BZKX548QHU8I5QBWVZDXZS3RZWFTGLQDA",
    "assetId": "SOLANA.N2H4-4Q5UBXJXE91BZKX548QHU8I5QBWVZDXZS3RZWFTGLQDA",
    "assetAddress": "4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda",
    "assetName": "N2H4",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.n2h4-4q5ubxjxe91bzkx548qhu8i5qbwvzdxzs3rzwftglqda.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ZBC-ZEBECZGI5FSETBPFQKVZKCJ3WGYXXJKMUKNNX7FLKAF",
    "assetId": "SOLANA.ZBC-ZEBECZGI5FSETBPFQKVZKCJ3WGYXXJKMUKNNX7FLKAF",
    "assetAddress": "zebeczgi5fSEtbpfQKVZKCJ3WgYXxjkMUkNNx7fLKAF",
    "assetName": "ZBC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.zbc-zebeczgi5fsetbpfqkvzkcj3wgyxxjkmuknnx7flkaf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.XSOL-B5GGNAZQDN8VPRQ15JPRXMJXVTMANHLQHOGJ9B9I4ZSS",
    "assetId": "SOLANA.XSOL-B5GGNAZQDN8VPRQ15JPRXMJXVTMANHLQHOGJ9B9I4ZSS",
    "assetAddress": "B5GgNAZQDN8vPrQ15jPrXmJxVtManHLqHogj9B9i4zSs",
    "assetName": "xSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.xsol-b5ggnazqdn8vprq15jprxmjxvtmanhlqhogj9b9i4zss.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$SCOT-EOJEYPPNNPYCP1ZOPNWQ6CXWA7MSYPR9T1WXPFYJEZEY",
    "assetId": "SOLANA.$SCOT-EOJEYPPNNPYCP1ZOPNWQ6CXWA7MSYPR9T1WXPFYJEZEY",
    "assetAddress": "EoJEyppNNpycP1ZoPnWq6cxwA7mSYpr9T1WXPFyjEzEy",
    "assetName": "$SCOT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.scot-eojeyppnnpycp1zopnwq6cxwa7msypr9t1wxpfyjezey.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BEACH-HO2FQGG65OM1ZPYUENC8FULPBNWTQRCTRXRP56AEYCCI",
    "assetId": "SOLANA.BEACH-HO2FQGG65OM1ZPYUENC8FULPBNWTQRCTRXRP56AEYCCI",
    "assetAddress": "Ho2FQgg65oM1zpYuEnC8fULpBnWtqRCTrXRP56AeyCci",
    "assetName": "BEACH",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.beach-ho2fqgg65om1zpyuenc8fulpbnwtqrctrxrp56aeycci.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ARAB-BFPCHRNVHYTRZMNAG9QKIZFRN2VQRBWCYOTX8QGKBDVM",
    "assetId": "SOLANA.ARAB-BFPCHRNVHYTRZMNAG9QKIZFRN2VQRBWCYOTX8QGKBDVM",
    "assetAddress": "BFpchrNVhyTRzMNAg9QkiZfRN2vqRBwcYoTX8qgkbDvm",
    "assetName": "arab",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.arab-bfpchrnvhytrzmnag9qkizfrn2vqrbwcyotx8qgkbdvm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WISDM-53CTV3WWFXQBXRUKWSBQCCE7SEFOWYU96PXK6FRLTJFV",
    "assetId": "SOLANA.WISDM-53CTV3WWFXQBXRUKWSBQCCE7SEFOWYU96PXK6FRLTJFV",
    "assetAddress": "53ctv3wwFXQbXruKWsbQcCe7sefowyu96pXK6FRLTjfv",
    "assetName": "WISDM",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wisdm-53ctv3wwfxqbxrukwsbqcce7sefowyu96pxk6frltjfv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHENG-BESNQVKFEAQ3G8QBEN5FMUNSQUQXZXW5I83HV3SYOTTC",
    "assetId": "SOLANA.CHENG-BESNQVKFEAQ3G8QBEN5FMUNSQUQXZXW5I83HV3SYOTTC",
    "assetAddress": "BEsnQvkfeaQ3G8qbeN5FmUnsQUqXzXW5i83Hv3syoTTc",
    "assetName": "Cheng",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cheng-besnqvkfeaq3g8qben5fmunsquqxzxw5i83hv3syottc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BODEN-3PSH1MJ1F7YUFAD5GH6ZJ7EPE8HHRMKMETGV5TSHQA4O",
    "assetId": "SOLANA.BODEN-3PSH1MJ1F7YUFAD5GH6ZJ7EPE8HHRMKMETGV5TSHQA4O",
    "assetAddress": "3psH1Mj1f7yUfaD5gh6Zj7epE8hhrMkMETgv5TshQA4o",
    "assetName": "boden",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.boden-3psh1mj1f7yufad5gh6zj7epe8hhrmkmetgv5tshqa4o.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOALEPH-CSZ5LZKDS7H9TDKJRBL7VAWQZ9NSRU8VJLHRYFMGAN8K",
    "assetId": "SOLANA.SOALEPH-CSZ5LZKDS7H9TDKJRBL7VAWQZ9NSRU8VJLHRYFMGAN8K",
    "assetAddress": "CsZ5LZkDS7h9TDKjrbL7VAwQZ9nsRu8vJLhRYfmGaN8K",
    "assetName": "soALEPH",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.soaleph-csz5lzkds7h9tdkjrbl7vawqz9nsru8vjlhryfmgan8k.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RAI-RAIUUHKRPHE2JENYANZ37MCTQUWMWBQDNAIR881AEBZ",
    "assetId": "SOLANA.RAI-RAIUUHKRPHE2JENYANZ37MCTQUWMWBQDNAIR881AEBZ",
    "assetAddress": "RaiuuHKrphE2jENyANz37mcTquwmwBqdnAiR881aEBZ",
    "assetName": "RAi",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rai-raiuuhkrphe2jenyanz37mctquwmwbqdnair881aebz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MOONAK-A8IQGLJ9BMQB2JM8UMKHJ7B7AGRID1EKLZHVRGA2VCNP",
    "assetId": "SOLANA.MOONAK-A8IQGLJ9BMQB2JM8UMKHJ7B7AGRID1EKLZHVRGA2VCNP",
    "assetAddress": "A8iqgLj9bmqb2JM8umkHj7B7AGriD1EKLZHVRGA2VcNP",
    "assetName": "MOONAK",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.moonak-a8iqglj9bmqb2jm8umkhj7b7agrid1eklzhvrga2vcnp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MODUL-A98UDY7Z8MFMWNTQT6CKJJE7UFQV3PTLF4YEBUWL2HRH",
    "assetId": "SOLANA.MODUL-A98UDY7Z8MFMWNTQT6CKJJE7UFQV3PTLF4YEBUWL2HRH",
    "assetAddress": "A98UDy7z8MfmWnTQt6cKjje7UfqV3pTLf4yEbuwL2HrH",
    "assetName": "MODUL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.modul-a98udy7z8mfmwntqt6ckjje7ufqv3ptlf4yebuwl2hrh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MOAR-76GDMBYOHG6YHY6XJ2RN9KXC22Q6V6UYPPXXKIWR5OTA",
    "assetId": "SOLANA.MOAR-76GDMBYOHG6YHY6XJ2RN9KXC22Q6V6UYPPXXKIWR5OTA",
    "assetAddress": "76gDMbyohg6yHy6XJ2Rn9kxC22Q6v6uYpPXxkiwR5ota",
    "assetName": "MOAR",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.moar-76gdmbyohg6yhy6xj2rn9kxc22q6v6uyppxxkiwr5ota.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DWH-DEVWHJ57QMPPARD2CYJBOMBDWVJEMJXRIGYPAUNDTD7O",
    "assetId": "SOLANA.DWH-DEVWHJ57QMPPARD2CYJBOMBDWVJEMJXRIGYPAUNDTD7O",
    "assetAddress": "DEVwHJ57QMPPArD2CyjboMbdWvjEMjXRigYpaUNDTD7o",
    "assetName": "DWH",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dwh-devwhj57qmppard2cyjbombdwvjemjxrigypaundtd7o.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOTS-HDEQEPFGTRBAWZDGTG1EYH8GO9PX84LCEC8QJT8T4JFN",
    "assetId": "SOLANA.BOTS-HDEQEPFGTRBAWZDGTG1EYH8GO9PX84LCEC8QJT8T4JFN",
    "assetAddress": "HDEqEpFgTrBawzDgTG1eyH8Go9PX84LCEC8Qjt8T4jFN",
    "assetName": "BOTS",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bots-hdeqepfgtrbawzdgtg1eyh8go9px84lcec8qjt8t4jfn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CONUR-7YQC6J7QXURZWG1XHHBMCTLG6X1K7Y5DBYB49KS8NGKF",
    "assetId": "SOLANA.CONUR-7YQC6J7QXURZWG1XHHBMCTLG6X1K7Y5DBYB49KS8NGKF",
    "assetAddress": "7yqC6J7qxuRzWg1xHHBmcTLG6X1K7Y5DBYB49Ks8nGKf",
    "assetName": "CONUR",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.conur-7yqc6j7qxurzwg1xhhbmctlg6x1k7y5dbyb49ks8ngkf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EDUST-21KBEZACXZ9DG2NTXP7PW1HAMTKE6YKIJKET1EIGXDYY",
    "assetId": "SOLANA.EDUST-21KBEZACXZ9DG2NTXP7PW1HAMTKE6YKIJKET1EIGXDYY",
    "assetAddress": "21kbezaCxz9dG2NtXp7pW1hAmtKe6ykiJkeT1EiGXDyY",
    "assetName": "EDUST",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.edust-21kbezacxz9dg2ntxp7pw1hamtke6ykijket1eigxdyy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$POINTS-9CX8HMXZ2VW7PXYEPF2G5UHRCMMX83ITGGCXWWRKDARQ",
    "assetId": "SOLANA.$POINTS-9CX8HMXZ2VW7PXYEPF2G5UHRCMMX83ITGGCXWWRKDARQ",
    "assetAddress": "9cX8hMxZ2vW7pxYEPf2G5UHrcmMx83iTgGcxwwRKdarq",
    "assetName": "$POINTS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.points-9cx8hmxz2vw7pxyepf2g5uhrcmmx83itggcxwwrkdarq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DGNA-8ISAGWHZNJ4HX4CMEOZWLLMVBWT4MUT6QK42TXIHKRTN",
    "assetId": "SOLANA.DGNA-8ISAGWHZNJ4HX4CMEOZWLLMVBWT4MUT6QK42TXIHKRTN",
    "assetAddress": "8iSagwHZNj4Hx4CMeoZwLLMVbWt4mUT6qk42TxiHkRtn",
    "assetName": "DGNA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dgna-8isagwhznj4hx4cmeozwllmvbwt4mut6qk42txihkrtn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TOPG-2KARUPCJQKJDSTGZQKRZABLKBJVI2HNADYWHQ7Z52GGQ",
    "assetId": "SOLANA.TOPG-2KARUPCJQKJDSTGZQKRZABLKBJVI2HNADYWHQ7Z52GGQ",
    "assetAddress": "2kARUpcjqKJdSTgZQKrzABLKbjvi2hNADYwHq7z52gGq",
    "assetName": "TopG",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.topg-2karupcjqkjdstgzqkrzablkbjvi2hnadywhq7z52ggq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CAPRI-JD3S1OQNJG5TRRTZXPLMKNS7LSKSPPYFA51RPBBMWOGV",
    "assetId": "SOLANA.CAPRI-JD3S1OQNJG5TRRTZXPLMKNS7LSKSPPYFA51RPBBMWOGV",
    "assetAddress": "JD3S1oqnjG5trRTZXpLmKnS7LsKsppyFa51rPBBMWogv",
    "assetName": "capri",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.capri-jd3s1oqnjg5trrtzxplmkns7lsksppyfa51rpbbmwogv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.XUSD-83LGLCM7QKPYZBX8Q4W2KYWBTT8NJBWBVWEEPZKVNJ9Y",
    "assetId": "SOLANA.XUSD-83LGLCM7QKPYZBX8Q4W2KYWBTT8NJBWBVWEEPZKVNJ9Y",
    "assetAddress": "83LGLCm7QKpYZbX8q4W2kYWbtt8NJBwbVwEepzkVnJ9y",
    "assetName": "xUSD",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.xusd-83lglcm7qkpyzbx8q4w2kywbtt8njbwbvweepzkvnj9y.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ELIXIR-ELXRYRF8WD4DCYXDU9QPNMDD8JN2TWG7O7QETF5Z2GBW",
    "assetId": "SOLANA.ELIXIR-ELXRYRF8WD4DCYXDU9QPNMDD8JN2TWG7O7QETF5Z2GBW",
    "assetAddress": "ELXRYrf8wd4DcyXDU9QPnMdD8jn2twg7o7qEtf5z2GBW",
    "assetName": "ELIXIR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.elixir-elxryrf8wd4dcyxdu9qpnmdd8jn2twg7o7qetf5z2gbw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NICK-G3Q2ZUKUXDCXMNHDBPUJJPHPW9UTMDBXQZCC2UHM3JIY",
    "assetId": "SOLANA.NICK-G3Q2ZUKUXDCXMNHDBPUJJPHPW9UTMDBXQZCC2UHM3JIY",
    "assetAddress": "G3q2zUkuxDCXMnhdBPujjPHPw9UTMDbXqzcc2UHM3jiy",
    "assetName": "NICK",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nick-g3q2zukuxdcxmnhdbpujjphpw9utmdbxqzcc2uhm3jiy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WUPHF-8UFSHIHMIQYECJTYUTDDUP8ZVYNFGOVJ7QGPTLSAP9GH",
    "assetId": "SOLANA.WUPHF-8UFSHIHMIQYECJTYUTDDUP8ZVYNFGOVJ7QGPTLSAP9GH",
    "assetAddress": "8UFSHiHmiqyecJtYUtDDuP8ZVyNfGovj7qgPtLsaP9gh",
    "assetName": "WUPHF",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wuphf-8ufshihmiqyecjtyutddup8zvynfgovj7qgptlsap9gh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WOOOO-JCQSYVAJFHBGJNWNMLT6CFX1VN9F3MRIBTNSDUSVRWS5",
    "assetId": "SOLANA.WOOOO-JCQSYVAJFHBGJNWNMLT6CFX1VN9F3MRIBTNSDUSVRWS5",
    "assetAddress": "JCqsyVajfHBGjNWNmLT6Cfx1Vn9f3mRibtNSdUsVRWS5",
    "assetName": "WOOOO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.woooo-jcqsyvajfhbgjnwnmlt6cfx1vn9f3mribtnsdusvrws5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PRANA-PRAXFBOUROJ9YZQHYEJEAH6RVJJ86Y82VFIZTBSM3XG",
    "assetId": "SOLANA.PRANA-PRAXFBOUROJ9YZQHYEJEAH6RVJJ86Y82VFIZTBSM3XG",
    "assetAddress": "PRAxfbouRoJ9yZqhyejEAH6RvjJ86Y82vfiZTBSM3xG",
    "assetName": "PRANA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.prana-praxfbouroj9yzqhyejeah6rvjj86y82vfiztbsm3xg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OXS-4TGXGCSJQX2GQK9OHZ8DC5M3JNXTYZHJXUMKAW3VLNNX",
    "assetId": "SOLANA.OXS-4TGXGCSJQX2GQK9OHZ8DC5M3JNXTYZHJXUMKAW3VLNNX",
    "assetAddress": "4TGxgCSJQx2GQk9oHZ8dC5m3JNXTYZHjXumKAW3vLnNx",
    "assetName": "OXS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.oxs-4tgxgcsjqx2gqk9ohz8dc5m3jnxtyzhjxumkaw3vlnnx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LUX-LUX88ZHPNTE7TTHA4F2NNXDCZWE3G61TQLBVFW7I8SM",
    "assetId": "SOLANA.LUX-LUX88ZHPNTE7TTHA4F2NNXDCZWE3G61TQLBVFW7I8SM",
    "assetAddress": "LUX88ZHPnte7tThA4F2nnXDCZWE3G61TqLBvFw7i8SM",
    "assetName": "LUX",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lux-lux88zhpnte7ttha4f2nnxdczwe3g61tqlbvfw7i8sm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HOBBES-6N7JANARY9FQZXKAJVRHL9TG2F61VBATWUMU1YZSCAQS",
    "assetId": "SOLANA.HOBBES-6N7JANARY9FQZXKAJVRHL9TG2F61VBATWUMU1YZSCAQS",
    "assetAddress": "6n7Janary9fqzxKaJVrhL9TG2F61VbAtwUMu1YZscaQS",
    "assetName": "Hobbes",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hobbes-6n7janary9fqzxkajvrhl9tg2f61vbatwumu1yzscaqs.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FORGE-FORGERIW7ODCCBGU1BZTZI16OSPBHJXHARVDATHL5EDS",
    "assetId": "SOLANA.FORGE-FORGERIW7ODCCBGU1BZTZI16OSPBHJXHARVDATHL5EDS",
    "assetAddress": "FoRGERiW7odcCBGU1bztZi16osPBHjxharvDathL5eds",
    "assetName": "FORGE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.forge-forgeriw7odccbgu1bztzi16ospbhjxharvdathl5eds.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HMTR-7JHMUCZRRFHYT5NTSU3AFSRUQ2L9992A7AHWDSDXDOL2",
    "assetId": "SOLANA.HMTR-7JHMUCZRRFHYT5NTSU3AFSRUQ2L9992A7AHWDSDXDOL2",
    "assetAddress": "7JhmUcZrrfhyt5nTSu3AfsrUq2L9992a7AhwdSDxdoL2",
    "assetName": "HMTR",
    "decimals": 0,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hmtr-7jhmuczrrfhyt5ntsu3afsruq2l9992a7ahwdsdxdol2.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TINY-HKFS24UEDQPHS5HUYKYKHD9Q7GY5UQ679Q2BOKEL2WHU",
    "assetId": "SOLANA.TINY-HKFS24UEDQPHS5HUYKYKHD9Q7GY5UQ679Q2BOKEL2WHU",
    "assetAddress": "HKfs24UEDQpHS5hUyKYkHd9q7GY5UQ679q2bokeL2whu",
    "assetName": "TINY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tiny-hkfs24uedqphs5huykykhd9q7gy5uq679q2bokel2whu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ICESOL-ICESDWQZTAQFUH6EN49HWWMXWTHKMNGZLFQCMN3BQHJ",
    "assetId": "SOLANA.ICESOL-ICESDWQZTAQFUH6EN49HWWMXWTHKMNGZLFQCMN3BQHJ",
    "assetAddress": "iceSdwqztAQFuH6En49HWwMxwthKMnGzLFQcMN3Bqhj",
    "assetName": "iceSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.icesol-icesdwqztaqfuh6en49hwwmxwthkmngzlfqcmn3bqhj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PHTEVE-DHRQWSIP53GVXNG8KQWQ63UO1FVOURPVNL8536TSVD5H",
    "assetId": "SOLANA.PHTEVE-DHRQWSIP53GVXNG8KQWQ63UO1FVOURPVNL8536TSVD5H",
    "assetAddress": "DhRQWsiP53gVXnG8KQwq63Uo1FvouRpVNL8536tsVD5H",
    "assetName": "PHTEVE",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.phteve-dhrqwsip53gvxng8kqwq63uo1fvourpvnl8536tsvd5h.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.QUIL-BZW7GNM32KTN82A1YCIYFOOBLZI37MVEXFF8SOQVPUMP",
    "assetId": "SOLANA.QUIL-BZW7GNM32KTN82A1YCIYFOOBLZI37MVEXFF8SOQVPUMP",
    "assetAddress": "BzW7Gnm32KTn82a1yCiYFooBLzi37MvEXFF8soQVpump",
    "assetName": "QUIL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.quil-bzw7gnm32ktn82a1yciyfooblzi37mvexff8soqvpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OPOS-3BYG6HEKHG5GUZ5PZU9ZKKFAEHYPVEGETVS68LYR8R5N",
    "assetId": "SOLANA.OPOS-3BYG6HEKHG5GUZ5PZU9ZKKFAEHYPVEGETVS68LYR8R5N",
    "assetAddress": "3byG6HEKhG5gUZ5PZU9zKkFaEHYPveGEtvs68LYR8R5N",
    "assetName": "OPOS",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.opos-3byg6hekhg5guz5pzu9zkkfaehypvegetvs68lyr8r5n.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SUBO-HTPMFWQELCFCCEG6NHQDPFXLPRR2BNKZ2YVA9HKQPUMP",
    "assetId": "SOLANA.SUBO-HTPMFWQELCFCCEG6NHQDPFXLPRR2BNKZ2YVA9HKQPUMP",
    "assetAddress": "HtpMfWQELCfCCeG6nHQDPfxLPrr2BNkZ2YVa9hKqpump",
    "assetName": "SUBO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.subo-htpmfwqelcfcceg6nhqdpfxlprr2bnkz2yva9hkqpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FAP-D1YAKKQRAQQG6SPUTDIWYSKPPZFGMV51OVCAGVU436TX",
    "assetId": "SOLANA.FAP-D1YAKKQRAQQG6SPUTDIWYSKPPZFGMV51OVCAGVU436TX",
    "assetAddress": "D1YaKkQRaQQg6sPUtDiWyskppzfgMV51ovcagVU436TX",
    "assetName": "FAP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fap-d1yakkqraqqg6sputdiwyskppzfgmv51ovcagvu436tx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOOTY-BOOTYAFCH1ESQEKHFADJN9PU6ZWPMAOQPOJWVUPASJJ",
    "assetId": "SOLANA.BOOTY-BOOTYAFCH1ESQEKHFADJN9PU6ZWPMAOQPOJWVUPASJJ",
    "assetAddress": "bootyAfCh1eSQeKhFaDjN9Pu6zwPmAoQPoJWVuPasjJ",
    "assetName": "BOOTY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.booty-bootyafch1esqekhfadjn9pu6zwpmaoqpojwvupasjj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AEUSDC-DDFPRNCCQQLD4ZCHRBQDY95D6HVW6PLWP9DEXJ1FLCL9",
    "assetId": "SOLANA.AEUSDC-DDFPRNCCQQLD4ZCHRBQDY95D6HVW6PLWP9DEXJ1FLCL9",
    "assetAddress": "DdFPRnccQqLD4zCHrBqdY95D6hvw6PLWp9DEXj1fLCL9",
    "assetName": "aeUSDC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.aeusdc-ddfprnccqqld4zchrbqdy95d6hvw6plwp9dexj1flcl9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WMP-BYGDD5LUROQZTD3XETC99WCXLUBTI6WYSHT9XIBGZ4HW",
    "assetId": "SOLANA.WMP-BYGDD5LUROQZTD3XETC99WCXLUBTI6WYSHT9XIBGZ4HW",
    "assetAddress": "BygDd5LURoqztD3xETc99WCxLUbTi6WYSht9XiBgZ4HW",
    "assetName": "WMP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wmp-bygdd5luroqztd3xetc99wcxlubti6wysht9xibgz4hw.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TRUMPWIF-DDGCYJKMMD1IILRFPQLZEPXLJCLDHIIOQ83FRMDAJD3H",
    "assetId": "SOLANA.TRUMPWIF-DDGCYJKMMD1IILRFPQLZEPXLJCLDHIIOQ83FRMDAJD3H",
    "assetAddress": "DDGcYJkMMD1iiLRfPQLZePxLJCLDhiioQ83frmdAJd3h",
    "assetName": "TRUMPWIF",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.trumpwif-ddgcyjkmmd1iilrfpqlzepxljcldhiioq83frmdajd3h.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TAOLIE-2ZE5RJ2CTXMZ9HVBK1AVJA78X7MH3KUR728SNZGXTEEU",
    "assetId": "SOLANA.TAOLIE-2ZE5RJ2CTXMZ9HVBK1AVJA78X7MH3KUR728SNZGXTEEU",
    "assetAddress": "2zE5rJ2ctXMz9hVbk1AvJa78X7mh3kuR728SNzGXTEeu",
    "assetName": "TAOLIE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.taolie-2ze5rj2ctxmz9hvbk1avja78x7mh3kur728snzgxteeu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TRANSEM-E4R9CN6ZOODPWCRWIRQKDP15KD9G6WQ5HZKPK7OGTPFK",
    "assetId": "SOLANA.TRANSEM-E4R9CN6ZOODPWCRWIRQKDP15KD9G6WQ5HZKPK7OGTPFK",
    "assetAddress": "E4r9cN6ZooDPwCrWiRQkdP15KD9G6wq5Hzkpk7ogTpfk",
    "assetName": "TRANSEM",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.transem-e4r9cn6zoodpwcrwirqkdp15kd9g6wq5hzkpk7ogtpfk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NOCT-8HFNU2EFWFQZ2SRSNDVPSJAWV3G65ZWP3PDC67MCNV6J",
    "assetId": "SOLANA.NOCT-8HFNU2EFWFQZ2SRSNDVPSJAWV3G65ZWP3PDC67MCNV6J",
    "assetAddress": "8HfNu2EfwFQz2srSNDvPsjaWV3G65ZWP3pDC67mcNv6J",
    "assetName": "NOCT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.noct-8hfnu2efwfqz2srsndvpsjawv3g65zwp3pdc67mcnv6j.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NRC-GH1JKZMXF95CT5PQABNBFJSKKQU8KQ5UUGFPBHSNPQ9Z",
    "assetId": "SOLANA.NRC-GH1JKZMXF95CT5PQABNBFJSKKQU8KQ5UUGFPBHSNPQ9Z",
    "assetAddress": "Gh1jKzmxf95cT5PQabNbfJskkQU8kQ5UugfpbHSnPq9z",
    "assetName": "NRC",
    "decimals": 3,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nrc-gh1jkzmxf95ct5pqabnbfjskkqu8kq5uugfpbhsnpq9z.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$RE-REDAOGK6ECBVGXW7VHS9FNZWMKR3BA6EHRBBGETLNWO",
    "assetId": "SOLANA.$RE-REDAOGK6ECBVGXW7VHS9FNZWMKR3BA6EHRBBGETLNWO",
    "assetAddress": "REdaoGk6EcBVgXW7vHs9FnzWmkr3ba6eHRBBgEtLNWo",
    "assetName": "$RE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.re-redaogk6ecbvgxw7vhs9fnzwmkr3ba6ehrbbgetlnwo.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SPOODY-8ND3TZJFXT9YYKIPIPMYP6S5DHLFTG3BWSQDW3KJWARB",
    "assetId": "SOLANA.SPOODY-8ND3TZJFXT9YYKIPIPMYP6S5DHLFTG3BWSQDW3KJWARB",
    "assetAddress": "8Nd3TZJfxt9yYKiPiPmYp6S5DhLftG3bwSqdW3KJwArb",
    "assetName": "SPOODY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.spoody-8nd3tzjfxt9yykipipmyp6s5dhlftg3bwsqdw3kjwarb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.YAW-YAWTS7VWCSRPCKX1AGB6SKIDVXIXIDUFEHXDEUSRGKE",
    "assetId": "SOLANA.YAW-YAWTS7VWCSRPCKX1AGB6SKIDVXIXIDUFEHXDEUSRGKE",
    "assetAddress": "YAWtS7vWCSRPckx1agB6sKidVXiXiDUfehXdEUSRGKE",
    "assetName": "YAW",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.yaw-yawts7vwcsrpckx1agb6skidvxixidufehxdeusrgke.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.XBTC-HWXPSV3QAGZLQZGATVHSYAER7STQUGQYGNNI1GNUGH1D",
    "assetId": "SOLANA.XBTC-HWXPSV3QAGZLQZGATVHSYAER7STQUGQYGNNI1GNUGH1D",
    "assetAddress": "HWxpSV3QAGzLQzGAtvhSYAEr7sTQugQygnni1gnUGh1D",
    "assetName": "xBTC",
    "decimals": 10,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.xbtc-hwxpsv3qagzlqzgatvhsyaer7stqugqygnni1gnugh1d.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CATY-8MY8BIF63V5VAHYAHPHPOB71K4UJSDKXVX7H9H1XMJ6N",
    "assetId": "SOLANA.CATY-8MY8BIF63V5VAHYAHPHPOB71K4UJSDKXVX7H9H1XMJ6N",
    "assetAddress": "8mY8bif63v5vAHYaHPhpob71K4uJsDKXVx7h9h1XmJ6N",
    "assetName": "CATY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.caty-8my8bif63v5vahyahphpob71k4ujsdkxvx7h9h1xmj6n.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.XIAO-EDAVHEZSUNNHDOAKPEXWAMTNUHQ6FVQOBYNYFEJLLBQC",
    "assetId": "SOLANA.XIAO-EDAVHEZSUNNHDOAKPEXWAMTNUHQ6FVQOBYNYFEJLLBQC",
    "assetAddress": "EDavhezsuNnhdoAKPExWaMtnuhq6FVqoBYnyFEJLLBqC",
    "assetName": "XIAO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.xiao-edavhezsunnhdoakpexwamtnuhq6fvqobynyfejllbqc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.VCC-FZGL5MOTNWEDEA24XGFSDBDFXKB9RU9KXFESEY9S58BB",
    "assetId": "SOLANA.VCC-FZGL5MOTNWEDEA24XGFSDBDFXKB9RU9KXFESEY9S58BB",
    "assetAddress": "FZgL5motNWEDEa24xgfSdBDfXkB9Ru9KxfEsey9S58bb",
    "assetName": "VCC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.vcc-fzgl5motnwedea24xgfsdbdfxkb9ru9kxfesey9s58bb.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HAGGORD-AYYYGH3I43S1QSPVG4VWHJ6S3GEWFN7UTEFWYRSWGMGW",
    "assetId": "SOLANA.HAGGORD-AYYYGH3I43S1QSPVG4VWHJ6S3GEWFN7UTEFWYRSWGMGW",
    "assetAddress": "AYyYgh3i43s1QSpvG4vwhJ6s3gewfN7uteFwYrswgMGw",
    "assetName": "HAGGORD",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.haggord-ayyygh3i43s1qspvg4vwhj6s3gewfn7utefwyrswgmgw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DPUNKZ-MCPGFN2CXFYFQ6JLIBXEC6VINFEBLSFSF9SV5WCWKVL",
    "assetId": "SOLANA.DPUNKZ-MCPGFN2CXFYFQ6JLIBXEC6VINFEBLSFSF9SV5WCWKVL",
    "assetAddress": "McpgFn2CxFYFq6JLiBxeC6viNfebLsfsf9Sv5wcwKvL",
    "assetName": "DPUNKZ",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dpunkz-mcpgfn2cxfyfq6jlibxec6vinfeblsfsf9sv5wcwkvl.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SMRAI-RVXO8T7TKESMAGPDQK1CY9DDZI3NYOWRCH1M2D7KRUC",
    "assetId": "SOLANA.SMRAI-RVXO8T7TKESMAGPDQK1CY9DDZI3NYOWRCH1M2D7KRUC",
    "assetAddress": "rvxo8t7TKeSmAgpdqK1CY9ddZi3NyowRCh1m2d7KrUc",
    "assetName": "SMRAI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.smrai-rvxo8t7tkesmagpdqk1cy9ddzi3nyowrch1m2d7kruc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RLGND-9M8E1YLHAG1B2TFSNEWAHSITQH5YQRNRBYW756HFACEA",
    "assetId": "SOLANA.RLGND-9M8E1YLHAG1B2TFSNEWAHSITQH5YQRNRBYW756HFACEA",
    "assetAddress": "9m8E1yLHaG1B2TFSNeWahsitQh5yQRnrbyw756HFAcEa",
    "assetName": "rLGND",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rlgnd-9m8e1ylhag1b2tfsnewahsitqh5yqrnrbyw756hfacea.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SNIFF-4OAV94MCVVEROSSGDZTN3JKMPR1GE7SQZJWBZ7XG6OA7",
    "assetId": "SOLANA.SNIFF-4OAV94MCVVEROSSGDZTN3JKMPR1GE7SQZJWBZ7XG6OA7",
    "assetAddress": "4oaV94McVveRosSgdZTn3jkMpr1ge7SQZjwBZ7xG6oA7",
    "assetName": "SNIFF",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sniff-4oav94mcvverossgdztn3jkmpr1ge7sqzjwbz7xg6oa7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PIXI-FTHCI9CXJSSIZRZMZSPJAFTFJI32V1CGRDM5SKQN4QBF",
    "assetId": "SOLANA.PIXI-FTHCI9CXJSSIZRZMZSPJAFTFJI32V1CGRDM5SKQN4QBF",
    "assetAddress": "FtHCi9cxJSSizrzMzsPjAfTfJi32V1CGRDM5Skqn4QBF",
    "assetName": "PIXI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pixi-fthci9cxjssizrzmzspjaftfji32v1cgrdm5skqn4qbf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TIMMI-BXXMDHM8STF3QG4FOAVM2V1EUVG9DLSVUSDRTJR8TMYS",
    "assetId": "SOLANA.TIMMI-BXXMDHM8STF3QG4FOAVM2V1EUVG9DLSVUSDRTJR8TMYS",
    "assetAddress": "BxXmDhM8sTF3QG4foaVM2v1EUvg9DLSVUsDRTjR8tMyS",
    "assetName": "TIMMI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.timmi-bxxmdhm8stf3qg4foavm2v1euvg9dlsvusdrtjr8tmys.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WUT-DG5BH1BNFJB5YL7VT3GJYKKUKF6MADUW3JYVDNA9EEVA",
    "assetId": "SOLANA.WUT-DG5BH1BNFJB5YL7VT3GJYKKUKF6MADUW3JYVDNA9EEVA",
    "assetAddress": "DG5bH1BnfjB5YL7Vt3GjykkUKf6maDUW3jYvdNa9eEVa",
    "assetName": "WUT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wut-dg5bh1bnfjb5yl7vt3gjykkukf6maduw3jyvdna9eeva.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOYFI-3JSF5TPEUSCJGTACP5GIEIDHV51GQ4V3ZWG8DGGYLFAB",
    "assetId": "SOLANA.SOYFI-3JSF5TPEUSCJGTACP5GIEIDHV51GQ4V3ZWG8DGGYLFAB",
    "assetAddress": "3JSf5tPeuscJGtaCp5giEiDhv51gQ4v3zWg8DGgyLfAB",
    "assetName": "soYFI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.soyfi-3jsf5tpeuscjgtacp5gieidhv51gq4v3zwg8dggylfab.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.POWME-6R25FEIBEDLFH4K9PWJCVGMT4G8YXMQZ2RAA5JWDJZVT",
    "assetId": "SOLANA.POWME-6R25FEIBEDLFH4K9PWJCVGMT4G8YXMQZ2RAA5JWDJZVT",
    "assetAddress": "6r25fEibeDLfh4K9pwJcVGmt4g8yxMqZ2rAA5jwdJzvt",
    "assetName": "POWME",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.powme-6r25feibedlfh4k9pwjcvgmt4g8yxmqz2raa5jwdjzvt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TICKET-AYMKZSDZNOLT7VHSB4WSRNCJ1GJCG3ZKGYFY8FXSHHER",
    "assetId": "SOLANA.TICKET-AYMKZSDZNOLT7VHSB4WSRNCJ1GJCG3ZKGYFY8FXSHHER",
    "assetAddress": "AymKzSDznoLT7Vhsb4wSRnCj1gjcG3zkgYFY8fxsHHer",
    "assetName": "TICKET",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ticket-aymkzsdznolt7vhsb4wsrncj1gjcg3zkgyfy8fxshher.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BURR-XWTZRAIF1DVH69CZ2SPQYJDLMEI2UVPS5CYHD9VQK6D",
    "assetId": "SOLANA.BURR-XWTZRAIF1DVH69CZ2SPQYJDLMEI2UVPS5CYHD9VQK6D",
    "assetAddress": "XwTZraiF1dVh69cZ2SpqyjDLmei2uVps5CYHD9vqK6d",
    "assetName": "BURR",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.burr-xwtzraif1dvh69cz2spqyjdlmei2uvps5cyhd9vqk6d.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.USDTET-DN4NOZ5JGGFKNTZCQSUZ8CZKREIZ1FORXYOV2H8DM7S1",
    "assetId": "SOLANA.USDTET-DN4NOZ5JGGFKNTZCQSUZ8CZKREIZ1FORXYOV2H8DM7S1",
    "assetAddress": "Dn4noZ5jgGfkntzcQSUZ8czkreiZ1ForXYoV2H8Dm7S1",
    "assetName": "USDTet",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.usdtet-dn4noz5jggfkntzcqsuz8czkreiz1forxyov2h8dm7s1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.99CENTS-3VTXWW93L1WRFQ7QDPJVFBQGZFRVQDGHPNC8VWQMEPUI",
    "assetId": "SOLANA.99CENTS-3VTXWW93L1WRFQ7QDPJVFBQGZFRVQDGHPNC8VWQMEPUI",
    "assetAddress": "3VTXWW93L1WRfQ7QDPjvfBQgzFRVqDgHPnc8vwQmEpUi",
    "assetName": "99CENTS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.99cents-3vtxww93l1wrfq7qdpjvfbqgzfrvqdghpnc8vwqmepui.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FLUXT-D1WUHNZTDSCCDRDXDWR4H82XKESXGQR4Q2ZLHSUYJA5M",
    "assetId": "SOLANA.FLUXT-D1WUHNZTDSCCDRDXDWR4H82XKESXGQR4Q2ZLHSUYJA5M",
    "assetAddress": "D1wUhnzTDscCDRdxDwR4h82XkesXgQR4Q2zLhSuYJA5m",
    "assetName": "FLUXT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fluxt-d1wuhnztdsccdrdxdwr4h82xkesxgqr4q2zlhsuyja5m.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TOLY-TO1YVXINRMVVGS8I54YJJ3XB51MTORFRCMZ7N8CIRBK",
    "assetId": "SOLANA.TOLY-TO1YVXINRMVVGS8I54YJJ3XB51MTORFRCMZ7N8CIRBK",
    "assetAddress": "to1yVXiNRMVVgS8i54Yjj3xB51MTorFrCMz7N8cirbK",
    "assetName": "TOLY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.toly-to1yvxinrmvvgs8i54yjj3xb51mtorfrcmz7n8cirbk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.COINFRA-FTVUGRQBCN5GAKJZFDYA3SPMS63FYDABHKE1YAFGLGLB",
    "assetId": "SOLANA.COINFRA-FTVUGRQBCN5GAKJZFDYA3SPMS63FYDABHKE1YAFGLGLB",
    "assetAddress": "FtVugRqBcn5gakjzfDyA3Spms63fYDAbhke1YAFgLgLB",
    "assetName": "COINFRA",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.coinfra-ftvugrqbcn5gakjzfdya3spms63fydabhke1yafglglb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.1SP-2ZZC22UBGJGCYPDFYO7GDWZ7YHQ5SOZJC1NNBQLU8OZB",
    "assetId": "SOLANA.1SP-2ZZC22UBGJGCYPDFYO7GDWZ7YHQ5SOZJC1NNBQLU8OZB",
    "assetAddress": "2zzC22UBgJGCYPdFyo7GDwz7YHq5SozJc1nnBqLU8oZb",
    "assetName": "1SP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.1sp-2zzc22ubgjgcypdfyo7gdwz7yhq5sozjc1nnbqlu8ozb.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KRUG-ECY2MIAGMX3UYYOGGHT15AHBKOA3TWOZDQYNLWETZAUV",
    "assetId": "SOLANA.KRUG-ECY2MIAGMX3UYYOGGHT15AHBKOA3TWOZDQYNLWETZAUV",
    "assetAddress": "ECy2miAgmx3UyYoGGHt15AHBkoA3twoZDQyNLwETzAUv",
    "assetName": "KRUG",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.krug-ecy2miagmx3uyyogght15ahbkoa3twozdqynlwetzauv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FUACK-4P6GQYNZ2AVWMAR37GCEUQ1VYK1PQJMXNUA1QFRKGHKZ",
    "assetId": "SOLANA.FUACK-4P6GQYNZ2AVWMAR37GCEUQ1VYK1PQJMXNUA1QFRKGHKZ",
    "assetAddress": "4P6gQYnZ2AvwmAr37GCeUq1vyk1PQJmXnuA1QFRKGHkz",
    "assetName": "FUACK",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fuack-4p6gqynz2avwmar37gceuq1vyk1pqjmxnua1qfrkghkz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SAYLOR-8BXZUG6NVUHDMGEMEJYPYZGNY48DGPZ68TVKVVTMFDDF",
    "assetId": "SOLANA.SAYLOR-8BXZUG6NVUHDMGEMEJYPYZGNY48DGPZ68TVKVVTMFDDF",
    "assetAddress": "8bXZuG6NVuhdmGeMEjypYZGny48DgpZ68TvkvVTmFDdF",
    "assetName": "SAYLOR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.saylor-8bxzug6nvuhdmgemejypyzgny48dgpz68tvkvvtmfddf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KENIDY-BG9CZR1CMVOCN2UNWWJ9F5RLBMFYRYVCVIKCRCWAWUWR",
    "assetId": "SOLANA.KENIDY-BG9CZR1CMVOCN2UNWWJ9F5RLBMFYRYVCVIKCRCWAWUWR",
    "assetAddress": "Bg9CZr1CmVoCn2uNWwj9f5rLbmfYRYvcVikCRCwawUwR",
    "assetName": "kenidy",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.kenidy-bg9czr1cmvocn2unwwj9f5rlbmfyryvcvikcrcwawuwr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SLUNA-9-LUNGEJUXYP48NRC1GYY5O4ETAKWM4RDX8BXFUXWJBLB",
    "assetId": "SOLANA.SLUNA-9-LUNGEJUXYP48NRC1GYY5O4ETAKWM4RDX8BXFUXWJBLB",
    "assetAddress": "9",
    "assetName": "sLUNA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sluna-9-lungejuxyp48nrc1gyy5o4etakwm4rdx8bxfuxwjblb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.IN-INL8PMVD6IIW3RCBJNR5ASRRN6NQR4BTRCNUQWQSKVY",
    "assetId": "SOLANA.IN-INL8PMVD6IIW3RCBJNR5ASRRN6NQR4BTRCNUQWQSKVY",
    "assetAddress": "inL8PMVd6iiW3RCBJnr5AsrRN6nqr4BTrcNuQWQSkvY",
    "assetName": "IN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.in-inl8pmvd6iiw3rcbjnr5asrrn6nqr4btrcnuqwqskvy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WW3-7M2TUKPPZCSCBHPJNGJWJBH75KKDNNWADD7I74M8AWAD",
    "assetId": "SOLANA.WW3-7M2TUKPPZCSCBHPJNGJWJBH75KKDNNWADD7I74M8AWAD",
    "assetAddress": "7m2TUkpPZCScBhPJnGjWjbh75KkDNnwAdd7i74m8awad",
    "assetName": "Ww3",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ww3-7m2tukppzcscbhpjngjwjbh75kkdnnwadd7i74m8awad.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ANTT-ANTT2VE8C3JC6FAKSFXUGTBYZEHMJS49EUPP7HTA5W7J",
    "assetId": "SOLANA.ANTT-ANTT2VE8C3JC6FAKSFXUGTBYZEHMJS49EUPP7HTA5W7J",
    "assetAddress": "ANTT2Ve8c3JC6fAksFxUGtByzEHmJs49EupP7htA5W7j",
    "assetName": "ANTT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.antt-antt2ve8c3jc6faksfxugtbyzehmjs49eupp7hta5w7j.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CNN-DOYVGRZBKAVMBTVGQA9MFNGJTHSR337VXJFTEDWQIBN7",
    "assetId": "SOLANA.CNN-DOYVGRZBKAVMBTVGQA9MFNGJTHSR337VXJFTEDWQIBN7",
    "assetAddress": "DoyVgrZbkAVMbTVGqa9mFNgjThsr337vxjFtEDWQibN7",
    "assetName": "CNN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cnn-doyvgrzbkavmbtvgqa9mfngjthsr337vxjftedwqibn7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SSOFTT-8-FTT8CGNP3RFTC6C44UPTUEFLQMSVDHJD2BHH65V2UPPR",
    "assetId": "SOLANA.SSOFTT-8-FTT8CGNP3RFTC6C44UPTUEFLQMSVDHJD2BHH65V2UPPR",
    "assetAddress": "8",
    "assetName": "ssoFTT",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ssoftt-8-ftt8cgnp3rftc6c44uptueflqmsvdhjd2bhh65v2uppr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DEVIN-7GBEP2TAY5WM3TMMP5UTCRRVDJ3FFQYJGN5KDPXIWPMO",
    "assetId": "SOLANA.DEVIN-7GBEP2TAY5WM3TMMP5UTCRRVDJ3FFQYJGN5KDPXIWPMO",
    "assetAddress": "7gbEP2TAy5wM3TmMp5utCrRvdJ3FFqYjgN5KDpXiWPmo",
    "assetName": "DEVIN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.devin-7gbep2tay5wm3tmmp5utcrrvdj3ffqyjgn5kdpxiwpmo.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GMERS-25YPCYQSPSV8T2JWYOKKRIU622SR7LOMK9S3FMUBFT8S",
    "assetId": "SOLANA.GMERS-25YPCYQSPSV8T2JWYOKKRIU622SR7LOMK9S3FMUBFT8S",
    "assetAddress": "25yPcyqSpSv8T2JWyoKKRiU622Sr7LoMk9S3FmuBFt8S",
    "assetName": "GMERS",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gmers-25ypcyqspsv8t2jwyokkriu622sr7lomk9s3fmubft8s.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PIXL-5L2YBOFBHAUPBDDJJVDB5M6PU9CW2FRJYDB2ASZYVJTE",
    "assetId": "SOLANA.PIXL-5L2YBOFBHAUPBDDJJVDB5M6PU9CW2FRJYDB2ASZYVJTE",
    "assetAddress": "5L2YboFbHAUpBDDJjvDB5M6pu9CW2FRjyDB2asZyvjtE",
    "assetName": "PIXL",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pixl-5l2ybofbhaupbddjjvdb5m6pu9cw2frjydb2aszyvjte.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SBOY-G3UKJEHBRDJ1ZUFR6KANDNW4BPCJMVK3QL2UATRB3F63",
    "assetId": "SOLANA.SBOY-G3UKJEHBRDJ1ZUFR6KANDNW4BPCJMVK3QL2UATRB3F63",
    "assetAddress": "G3ukjeHBrDJ1zUFr6KandnW4bPcjmvK3qL2uATRb3F63",
    "assetName": "sboy",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sboy-g3ukjehbrdj1zufr6kandnw4bpcjmvk3ql2uatrb3f63.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OOINK-KRTAPYUME5FW92KZKYOXTOFTC6CN7UG6SEAKZ646OGU",
    "assetId": "SOLANA.OOINK-KRTAPYUME5FW92KZKYOXTOFTC6CN7UG6SEAKZ646OGU",
    "assetAddress": "KRTapyUMe5fW92KZkYoXToFtc6Cn7UG6seaKz646oGu",
    "assetName": "OOINK",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ooink-krtapyume5fw92kzkyoxtoftc6cn7ug6seakz646ogu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CUY-8MD6DV7B8TGWO72WYPMFSXIXPT1G1Y2U9OHHNRT3LVWX",
    "assetId": "SOLANA.CUY-8MD6DV7B8TGWO72WYPMFSXIXPT1G1Y2U9OHHNRT3LVWX",
    "assetAddress": "8MD6dV7B8Tgwo72WyPmfsXiXpt1G1y2u9ohhNrt3LVwX",
    "assetName": "Cuy",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cuy-8md6dv7b8tgwo72wypmfsxixpt1g1y2u9ohhnrt3lvwx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.VOID-DJPT6XXMOZX1DYYWUHGS4MWQWWX48FWF6ZJGQV2F9QWC",
    "assetId": "SOLANA.VOID-DJPT6XXMOZX1DYYWUHGS4MWQWWX48FWF6ZJGQV2F9QWC",
    "assetAddress": "DjPt6xxMoZx1DyyWUHGs4mwqWWX48Fwf6ZJgqv2F9qwc",
    "assetName": "VOID",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.void-djpt6xxmozx1dyywuhgs4mwqwwx48fwf6zjgqv2f9qwc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EVERMOON-CGZDCJJ5YNH51UFFQFTFBUJKMRWGDWHEVJWQJU84MV8Y",
    "assetId": "SOLANA.EVERMOON-CGZDCJJ5YNH51UFFQFTFBUJKMRWGDWHEVJWQJU84MV8Y",
    "assetAddress": "CgzdCjj5YNH51uFfQftFbuJKMrwgdWheVjwqjU84MV8y",
    "assetName": "EVERMOON",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.evermoon-cgzdcjj5ynh51uffqftfbujkmrwgdwhevjwqju84mv8y.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MOGO-2WUECDL1GQA6PWQS7SMSCF2SHEFZXWK2RANHKTHMLTMK",
    "assetId": "SOLANA.MOGO-2WUECDL1GQA6PWQS7SMSCF2SHEFZXWK2RANHKTHMLTMK",
    "assetAddress": "2WUecdL1GQA6pwQs7sMsCf2shEfZxWK2RaNHkthmLtmK",
    "assetName": "MOGO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mogo-2wuecdl1gqa6pwqs7smscf2shefzxwk2ranhkthmltmk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOY-GMEXG8FWPULZVB15R41CNB5QFNRCXCREYZSMD3X7SV2E",
    "assetId": "SOLANA.BOY-GMEXG8FWPULZVB15R41CNB5QFNRCXCREYZSMD3X7SV2E",
    "assetAddress": "GmEXg8FwpULzVb15r41CNB5qFnRcXCrEyZSmD3X7sv2e",
    "assetName": "boy",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.boy-gmexg8fwpulzvb15r41cnb5qfnrcxcreyzsmd3x7sv2e.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GOSU-6D7NXHAHSRBWJ8KFZR2AGB6GEJMLG4BM7MAQZZRT8F1J",
    "assetId": "SOLANA.GOSU-6D7NXHAHSRBWJ8KFZR2AGB6GEJMLG4BM7MAQZZRT8F1J",
    "assetAddress": "6D7nXHAhsRbwj8KFZR2agB6GEjMLg4BM7MAqZzRT8F1j",
    "assetName": "GOSU",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gosu-6d7nxhahsrbwj8kfzr2agb6gejmlg4bm7maqzzrt8f1j.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SBC-DBAZBUXALJ1QANCSEUPZZ4SP9F8D2SC78C4VKJHBTGMA",
    "assetId": "SOLANA.SBC-DBAZBUXALJ1QANCSEUPZZ4SP9F8D2SC78C4VKJHBTGMA",
    "assetAddress": "DBAzBUXaLj1qANCseUPZz4sp9F8d2sc78C4vKjhbTGMA",
    "assetName": "SBC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sbc-dbazbuxalj1qancseupzz4sp9f8d2sc78c4vkjhbtgma.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PSOL-HMATMU1KTLBOBSVIM94MFPZMJL5IIYOM1ZIDTXJRADLZ",
    "assetId": "SOLANA.PSOL-HMATMU1KTLBOBSVIM94MFPZMJL5IIYOM1ZIDTXJRADLZ",
    "assetAddress": "Hmatmu1ktLbobSvim94mfpZmjL5iiyoM1zidtXJRAdLZ",
    "assetName": "PSOL",
    "decimals": 7,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.psol-hmatmu1ktlbobsvim94mfpzmjl5iiyom1zidtxjradlz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SWTUST-9-UST98BFV6EASDTFQRRWCBCZPEHDMFWYCUDLT5TEBHPW",
    "assetId": "SOLANA.SWTUST-9-UST98BFV6EASDTFQRRWCBCZPEHDMFWYCUDLT5TEBHPW",
    "assetAddress": "9",
    "assetName": "swtUST",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.swtust-9-ust98bfv6easdtfqrrwcbczpehdmfwycudlt5tebhpw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FISHY-FISHY64JCAA3OOQXW7BHTKVYD8BTKSYAPH6RNE3XZPCN",
    "assetId": "SOLANA.FISHY-FISHY64JCAA3OOQXW7BHTKVYD8BTKSYAPH6RNE3XZPCN",
    "assetAddress": "Fishy64jCaa3ooqXw7BHtKvYD8BTkSyAPh6RNE3xZpcN",
    "assetName": "FISHY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fishy-fishy64jcaa3ooqxw7bhtkvyd8btksyaph6rne3xzpcn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BULLZ-CDKXJSEOKNWMABBN2HCRPCXNMSDE59QKTSELH1J9TEWD",
    "assetId": "SOLANA.BULLZ-CDKXJSEOKNWMABBN2HCRPCXNMSDE59QKTSELH1J9TEWD",
    "assetAddress": "CdkXjsEokNWMabBn2HCrPcXNmSDE59QKtseLh1j9tEwd",
    "assetName": "BULLZ",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bullz-cdkxjseoknwmabbn2hcrpcxnmsde59qktselh1j9tewd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TREN-HLNTNCG5RD7JYVDUFC1PMCHIUAPOWGN9LVEQEFANQFZB",
    "assetId": "SOLANA.TREN-HLNTNCG5RD7JYVDUFC1PMCHIUAPOWGN9LVEQEFANQFZB",
    "assetAddress": "HLnTNCG5RD7jYVduFc1pMCHiuApoWGn9LveqEFanQFZb",
    "assetName": "TREN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tren-hlntncg5rd7jyvdufc1pmchiuapowgn9lveqefanqfzb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LPFI-LPFINAYBMOBY5OHFYVDY9JPOZFBGKPPIEGOOBK2XCE3",
    "assetId": "SOLANA.LPFI-LPFINAYBMOBY5OHFYVDY9JPOZFBGKPPIEGOOBK2XCE3",
    "assetAddress": "LPFiNAybMobY5oHfYVdy9jPozFBGKpPiEGoobK2xCe3",
    "assetName": "LPFi",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lpfi-lpfinaybmoby5ohfyvdy9jpozfbgkppiegoobk2xce3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BUL-8RGY4BZUEKW9DC2UEZ3QHYYDAS66X63VAZDZJEZGJW5E",
    "assetId": "SOLANA.BUL-8RGY4BZUEKW9DC2UEZ3QHYYDAS66X63VAZDZJEZGJW5E",
    "assetAddress": "8RGy4BZUEkW9dc2UEZ3QHYYdas66X63vazdZJezGJw5e",
    "assetName": "bul",
    "decimals": 7,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bul-8rgy4bzuekw9dc2uez3qhyydas66x63vazdzjezgjw5e.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GARF-PZ1B7IALFQZSGDL9CA6P3NZVTXWSF1KOAQQNNOHVFCT",
    "assetId": "SOLANA.GARF-PZ1B7IALFQZSGDL9CA6P3NZVTXWSF1KOAQQNNOHVFCT",
    "assetAddress": "Pz1b7iALFqzsgdL9ca6P3NZvTXwSF1koaQqnNohVFcT",
    "assetName": "GARF",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.garf-pz1b7ialfqzsgdl9ca6p3nzvtxwsf1koaqqnnohvfct.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MOONBURN-F14CP89OAXMRNNAC4MKMNKHPWW2P2R4DRFAZEDJHUBKD",
    "assetId": "SOLANA.MOONBURN-F14CP89OAXMRNNAC4MKMNKHPWW2P2R4DRFAZEDJHUBKD",
    "assetAddress": "F14Cp89oAXMrNnaC4mKMNKHPWw2p2R4DRFAZEdJhUBkD",
    "assetName": "MOONBURN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.moonburn-f14cp89oaxmrnnac4mkmnkhpww2p2r4drfazedjhubkd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MELENYE-CER9R2RQUEYEMRGCWU5SSJU5D52SDZFC2GCYPWBHTEBB",
    "assetId": "SOLANA.MELENYE-CER9R2RQUEYEMRGCWU5SSJU5D52SDZFC2GCYPWBHTEBB",
    "assetAddress": "Cer9R2rqUEyemrgCWu5SsJu5d52sdZfC2gCYpwBhtEbB",
    "assetName": "MELENYE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.melenye-cer9r2rqueyemrgcwu5ssju5d52sdzfc2gcypwbhtebb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ROBERT-AON2Z7W7CCQJQIWS7RJS45DCYYKVKBDDXDCRZMJ69TQF",
    "assetId": "SOLANA.ROBERT-AON2Z7W7CCQJQIWS7RJS45DCYYKVKBDDXDCRZMJ69TQF",
    "assetAddress": "AoN2z7w7ccQJQiWS7rjS45dcyYkVkBddXDcrzmj69tqf",
    "assetName": "ROBERT",
    "decimals": 3,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.robert-aon2z7w7ccqjqiws7rjs45dcyykvkbddxdcrzmj69tqf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PPP-9HORXNCCXDTWA1YHYJOPVPP9YBYYS3Y8NVV42Z1MTBOV",
    "assetId": "SOLANA.PPP-9HORXNCCXDTWA1YHYJOPVPP9YBYYS3Y8NVV42Z1MTBOV",
    "assetAddress": "9HoRXnCcXdTWA1YhYJoPVpp9YByYS3Y8nVV42z1MTBoV",
    "assetName": "PPP",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ppp-9horxnccxdtwa1yhyjopvpp9ybyys3y8nvv42z1mtbov.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ZAZU-N7EKSMKVK3WT5FJQ3HBMLM9XYU3ENOXUSJ7POWPXSOG",
    "assetId": "SOLANA.ZAZU-N7EKSMKVK3WT5FJQ3HBMLM9XYU3ENOXUSJ7POWPXSOG",
    "assetAddress": "n7EksMkvk3WT5FjQ3HBMLm9XYU3EnoXUSJ7PoWpxsoG",
    "assetName": "ZAZU",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.zazu-n7eksmkvk3wt5fjq3hbmlm9xyu3enoxusj7powpxsog.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TOOL-TOOLSNYLIVQZG8O4M3L2UETBN62MVMWRQKOG6PQEYKL",
    "assetId": "SOLANA.TOOL-TOOLSNYLIVQZG8O4M3L2UETBN62MVMWRQKOG6PQEYKL",
    "assetAddress": "tooLsNYLiVqzg8o4m3L2Uetbn62mvMWRqkog6PQeYKL",
    "assetName": "TOOL",
    "decimals": 0,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tool-toolsnylivqzg8o4m3l2uetbn62mvmwrqkog6pqeykl.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DKM-HTBHBYDCFXBBD2JIH6JTSTT2M2FXJN7H4K6IXFZ98K5W",
    "assetId": "SOLANA.DKM-HTBHBYDCFXBBD2JIH6JTSTT2M2FXJN7H4K6IXFZ98K5W",
    "assetAddress": "HtbhBYdcfXbbD2JiH6jtsTt2m2FXjn7h4k6iXfz98k5W",
    "assetName": "DKM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dkm-htbhbydcfxbbd2jih6jtstt2m2fxjn7h4k6ixfz98k5w.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.STSHIP-GHTLF7DRBYXGTHX73USXQPKKJUZDQCBNE2M9FZJJZR3J",
    "assetId": "SOLANA.STSHIP-GHTLF7DRBYXGTHX73USXQPKKJUZDQCBNE2M9FZJJZR3J",
    "assetAddress": "GHtLF7drbYXGTHX73uSxqPKkJUzDqcBNe2M9fzjJzr3j",
    "assetName": "STSHIP",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.stship-ghtlf7drbyxgthx73usxqpkkjuzdqcbne2m9fzjjzr3j.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BEAVER-M9I5XQZ8Z2UA3VHUBKJBSKP5HYWDETU7N9RP5VUSW4Z",
    "assetId": "SOLANA.BEAVER-M9I5XQZ8Z2UA3VHUBKJBSKP5HYWDETU7N9RP5VUSW4Z",
    "assetAddress": "M9i5xQz8Z2Ua3VHuBkjBSkP5HYwdetu7N9RP5VUsW4z",
    "assetName": "BEAVER",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.beaver-m9i5xqz8z2ua3vhubkjbskp5hywdetu7n9rp5vusw4z.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$WEN-3J5QAP1ZJN9YXE7JR5XJA3LQ2TYGHSHU2WSSK7N1AW4P",
    "assetId": "SOLANA.$WEN-3J5QAP1ZJN9YXE7JR5XJA3LQ2TYGHSHU2WSSK7N1AW4P",
    "assetAddress": "3J5QaP1zJN9yXE7jr5XJa3Lq2TyGHSHu2wssK7N1Aw4p",
    "assetName": "$WEN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wen-3j5qap1zjn9yxe7jr5xja3lq2tyghshu2wssk7n1aw4p.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BURGUM-A7NT9KPPGGZFRJDGZ5EDUD53UPSODWDXRJYPHESWMXSC",
    "assetId": "SOLANA.BURGUM-A7NT9KPPGGZFRJDGZ5EDUD53UPSODWDXRJYPHESWMXSC",
    "assetAddress": "A7nt9kPpgGZFRjdgZ5EDUD53UpsodwdxRJYPHESwMXSC",
    "assetName": "BURGUM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.burgum-a7nt9kppggzfrjdgz5edud53upsodwdxrjypheswmxsc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HAROLD-B8AMDZRJEHUQ8CPCIEY6JOCQ9P4IVC4ZURDXPWTQS2QG",
    "assetId": "SOLANA.HAROLD-B8AMDZRJEHUQ8CPCIEY6JOCQ9P4IVC4ZURDXPWTQS2QG",
    "assetAddress": "B8AmDZRJeHuq8CPciey6jocq9p4ivc4ZurDXPwtqs2Qg",
    "assetName": "Harold",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.harold-b8amdzrjehuq8cpciey6jocq9p4ivc4zurdxpwtqs2qg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LEIA-7USVZYNPTUJ9CZDS96EZM9C6Z3HCSJB7J6TMKIPURYYQ",
    "assetId": "SOLANA.LEIA-7USVZYNPTUJ9CZDS96EZM9C6Z3HCSJB7J6TMKIPURYYQ",
    "assetAddress": "7usVzynPTUJ9czdS96ezm9C6Z3hCsjb7j6TMKipURyyQ",
    "assetName": "LEIA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.leia-7usvzynptuj9czds96ezm9c6z3hcsjb7j6tmkipuryyq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MATICPO-GZ7VKD4MACBEB6YC5XD3HCUMEIYX2ETDYYRFIKGSVOPG",
    "assetId": "SOLANA.MATICPO-GZ7VKD4MACBEB6YC5XD3HCUMEIYX2ETDYYRFIKGSVOPG",
    "assetAddress": "Gz7VkD4MacbEB6yC5XD3HcumEiYx2EtDYYrfikGsvopG",
    "assetName": "MATICpo",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.maticpo-gz7vkd4macbeb6yc5xd3hcumeiyx2etdyyrfikgsvopg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.XGLI-FSPNCBFEDV3UV9G6YYX1NNKIDVUECAAIT2NTBAPY17XG",
    "assetId": "SOLANA.XGLI-FSPNCBFEDV3UV9G6YYX1NNKIDVUECAAIT2NTBAPY17XG",
    "assetAddress": "FsPncBfeDV3Uv9g6yyx1NnKidvUeCaAiT2NtBAPy17xg",
    "assetName": "XGLI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.xgli-fspncbfedv3uv9g6yyx1nnkidvuecaait2ntbapy17xg.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ZIPPYSOL-ZIPPYBH3S5XYYAM2NVL6HVJKZ1GOT6SHGV4DYD1XQYF",
    "assetId": "SOLANA.ZIPPYSOL-ZIPPYBH3S5XYYAM2NVL6HVJKZ1GOT6SHGV4DYD1XQYF",
    "assetAddress": "Zippybh3S5xYYam2nvL6hVJKz1got6ShgV4DyD1XQYF",
    "assetName": "zippySOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.zippysol-zippybh3s5xyyam2nvl6hvjkz1got6shgv4dyd1xqyf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WHEY-UE4YJKPJA4QGIS37EWBBSNQFZYK83BTY4AIODETP3AB",
    "assetId": "SOLANA.WHEY-UE4YJKPJA4QGIS37EWBBSNQFZYK83BTY4AIODETP3AB",
    "assetAddress": "Ue4yjkPjA4QGis37eWbBsnqfzyK83BtY4AioDETp3Ab",
    "assetName": "WHEY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.whey-ue4yjkpja4qgis37ewbbsnqfzyk83bty4aiodetp3ab.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.USDTAV-KZ1CSQA91WUGCQ2TB3O5KDGMWMMGP8EJCDEYHZNDVCX",
    "assetId": "SOLANA.USDTAV-KZ1CSQA91WUGCQ2TB3O5KDGMWMMGP8EJCDEYHZNDVCX",
    "assetAddress": "Kz1csQA91WUGcQ2TB3o5kdGmWmMGp8eJcDEyHzNDVCX",
    "assetName": "USDTav",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.usdtav-kz1csqa91wugcq2tb3o5kdgmwmmgp8ejcdeyhzndvcx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GMSOL-GMDU3SNWW28DMMXCSECHP9OWWLUHAMH9ES3HWFHG8VG",
    "assetId": "SOLANA.GMSOL-GMDU3SNWW28DMMXCSECHP9OWWLUHAMH9ES3HWFHG8VG",
    "assetAddress": "gmdu3snwW28DmmxCseChp9owWLUhamH9eS3hWfHG8Vg",
    "assetName": "GMSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gmsol-gmdu3snww28dmmxcsechp9owwluhamh9es3hwfhg8vg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SIXY-GKNR1GWF7AMVEMEYMZBOEALVBVCPKJUE9LZN9HFRYYHG",
    "assetId": "SOLANA.SIXY-GKNR1GWF7AMVEMEYMZBOEALVBVCPKJUE9LZN9HFRYYHG",
    "assetAddress": "GKNr1Gwf7AMvEMEyMzBoEALVBvCpKJue9Lzn9HfrYYhg",
    "assetName": "SIXY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sixy-gknr1gwf7amvemeymzboealvbvcpkjue9lzn9hfryyhg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOBA-25P2BONP6QRJH5AS6EK6H7EI495OSKYZD3TGB97SQFMH",
    "assetId": "SOLANA.SOBA-25P2BONP6QRJH5AS6EK6H7EI495OSKYZD3TGB97SQFMH",
    "assetAddress": "25p2BoNp6qrJH5As6ek6H7Ei495oSkyZd3tGb97sqFmH",
    "assetName": "soba",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.soba-25p2bonp6qrjh5as6ek6h7ei495oskyzd3tgb97sqfmh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FIW-FEKMTUNVRXDKEODJBUTWZI8VFFFW3MHZPPB79JD8ARYU",
    "assetId": "SOLANA.FIW-FEKMTUNVRXDKEODJBUTWZI8VFFFW3MHZPPB79JD8ARYU",
    "assetAddress": "FeKmTunVrXDKEoDJbuTwZi8vfFFw3MHzpPB79JD8ARYU",
    "assetName": "FIW",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fiw-fekmtunvrxdkeodjbutwzi8vfffw3mhzppb79jd8aryu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HAROLD-EJMDTT8G3T725EFSV7OWMGD8J848GUO3LZ1EB3RFWGSW",
    "assetId": "SOLANA.HAROLD-EJMDTT8G3T725EFSV7OWMGD8J848GUO3LZ1EB3RFWGSW",
    "assetAddress": "EjmDTt8G3T725eFSV7oWmGD8J848guo3LZ1EB3RfwGSw",
    "assetName": "harold",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.harold-ejmdtt8g3t725efsv7owmgd8j848guo3lz1eb3rfwgsw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SAC-GZL4YJPOHDSHW4ROFJ6DEWU2FV7QEA5MBT7DPJE5HQE7",
    "assetId": "SOLANA.SAC-GZL4YJPOHDSHW4ROFJ6DEWU2FV7QEA5MBT7DPJE5HQE7",
    "assetAddress": "GZL4yjPohDShW4RofJ6dEWu2Fv7qEa5mBT7Dpje5hqe7",
    "assetName": "SAC",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sac-gzl4yjpohdshw4rofj6dewu2fv7qea5mbt7dpje5hqe7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OMABA-AZ7ABJTSEZOFHHNZWHMVREMGGKZVCN8NEZWQFYZDALSZ",
    "assetId": "SOLANA.OMABA-AZ7ABJTSEZOFHHNZWHMVREMGGKZVCN8NEZWQFYZDALSZ",
    "assetAddress": "AZ7ABJtSeZoFHhNzWhMVREMgGKZVCN8nEZwQfyZdALSZ",
    "assetName": "omaba",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.omaba-az7abjtsezofhhnzwhmvremggkzvcn8nezwqfyzdalsz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CREAMY-CREAMPDDIMXXJ2ZTCWP5WMETBA4NYAKCRTBEQTSKTQHE",
    "assetId": "SOLANA.CREAMY-CREAMPDDIMXXJ2ZTCWP5WMETBA4NYAKCRTBEQTSKTQHE",
    "assetAddress": "CREAMpdDimXxj2zTCwP5wMEtba4NYaKCrTBEQTSKtqHe",
    "assetName": "CREAMY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.creamy-creampddimxxj2ztcwp5wmetba4nyakcrtbeqtsktqhe.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MOOSK-3KROURETDWLYFREAPBDH6NTWQGS7Y6RORLY8JB1PJXOM",
    "assetId": "SOLANA.MOOSK-3KROURETDWLYFREAPBDH6NTWQGS7Y6RORLY8JB1PJXOM",
    "assetAddress": "3KroURETdWLyFReapbdh6nTWQgS7Y6rorLY8jb1pJXoM",
    "assetName": "MOOSK",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.moosk-3krouretdwlyfreapbdh6ntwqgs7y6rorly8jb1pjxom.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LANTERNSOL-LNTRNTK2KTFWEY6CVB8K9649PGJBT6DJLS1NS1GZCWG",
    "assetId": "SOLANA.LANTERNSOL-LNTRNTK2KTFWEY6CVB8K9649PGJBT6DJLS1NS1GZCWG",
    "assetAddress": "LnTRntk2kTfWEY6cVB8K9649pgJbt6dJLS1Ns1GZCWg",
    "assetName": "lanternSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lanternsol-lntrntk2ktfwey6cvb8k9649pgjbt6djls1ns1gzcwg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WINE-DOVRZHXXICZUCNUSKDMG7DEPHBHFKHVVHTA5NF1DEFOQ",
    "assetId": "SOLANA.WINE-DOVRZHXXICZUCNUSKDMG7DEPHBHFKHVVHTA5NF1DEFOQ",
    "assetAddress": "DoVRzHXXicZucnuskdMg7DePhBHFkhvvhta5nf1deFoQ",
    "assetName": "WINE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wine-dovrzhxxiczucnuskdmg7dephbhfkhvvhta5nf1defoq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PSOL-9EALKQRBJMBBUZG9WDPO8QFNUEJHATJFSYCEMW6F1RGX",
    "assetId": "SOLANA.PSOL-9EALKQRBJMBBUZG9WDPO8QFNUEJHATJFSYCEMW6F1RGX",
    "assetAddress": "9EaLkQrbjmbbuZG9Wdpo8qfNUEjHATJFSycEmw6f1rGX",
    "assetName": "pSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.psol-9ealkqrbjmbbuzg9wdpo8qfnuejhatjfsycemw6f1rgx.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SHUT-9E6NNQBSTJWX3SS6A3X7Q9ZVPUPLNYLB8CTBVMM6UD2K",
    "assetId": "SOLANA.SHUT-9E6NNQBSTJWX3SS6A3X7Q9ZVPUPLNYLB8CTBVMM6UD2K",
    "assetAddress": "9e6nnqbsTjWx3ss6a3x7Q9ZvpupLNYLb8cTbVmm6UD2K",
    "assetName": "SHUT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.shut-9e6nnqbstjwx3ss6a3x7q9zvpuplnylb8ctbvmm6ud2k.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ACAT-ACATZTJUEHDT3SOUFN6NMXGUMBFTOQFHNFWUSDW8KYX",
    "assetId": "SOLANA.ACAT-ACATZTJUEHDT3SOUFN6NMXGUMBFTOQFHNFWUSDW8KYX",
    "assetAddress": "acatzTjUeHDT3SoufN6NMxGUmBFtoqFHnFwusdw8kYX",
    "assetName": "acat",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.acat-acatztjuehdt3soufn6nmxgumbftoqfhnfwusdw8kyx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CDBD-55BHM86E8BRNHWY7SNZ4CHQTFQTRVGDVAEWYVCZJS8RC",
    "assetId": "SOLANA.CDBD-55BHM86E8BRNHWY7SNZ4CHQTFQTRVGDVAEWYVCZJS8RC",
    "assetAddress": "55bhM86E8brNHWy7sNz4chqtfQtrVGDvaeWyVczJs8RC",
    "assetName": "CDBD",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cdbd-55bhm86e8brnhwy7snz4chqtfqtrvgdvaewyvczjs8rc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AMMO-AMMOK8AKX2WNEBQB35CDAZTTKVSXQBI82CGETNUVVFK",
    "assetId": "SOLANA.AMMO-AMMOK8AKX2WNEBQB35CDAZTTKVSXQBI82CGETNUVVFK",
    "assetAddress": "ammoK8AkX2wnebQb35cDAZtTkvsXQbi82cGeTnUvvfK",
    "assetName": "AMMO",
    "decimals": 0,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ammo-ammok8akx2wnebqb35cdazttkvsxqbi82cgetnuvvfk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KLGT-519W6ZZNDUUDEO6TP1YMGEWINFEOQ4KTVS4UMZGGKE5R",
    "assetId": "SOLANA.KLGT-519W6ZZNDUUDEO6TP1YMGEWINFEOQ4KTVS4UMZGGKE5R",
    "assetAddress": "519W6ZZnduudeo6tp1YMgeWiNFeoq4kTVS4uMZggKE5r",
    "assetName": "KLGT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.klgt-519w6zznduudeo6tp1ymgewinfeoq4ktvs4umzggke5r.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GMEOW-A5LCTQ1VJECCQWSXJYS3RFCOEXCTBUGTCAEKDUNYE8BZ",
    "assetId": "SOLANA.GMEOW-A5LCTQ1VJECCQWSXJYS3RFCOEXCTBUGTCAEKDUNYE8BZ",
    "assetAddress": "A5LCTQ1vJECCQWSXJYs3rfCoexctbUgTCAEKDuNye8bZ",
    "assetName": "GMEOW",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gmeow-a5lctq1vjeccqwsxjys3rfcoexctbugtcaekdunye8bz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.QDOT-HCPYICXVZIKBYTGOQMLUMOOAWBKCLVZMZB9D6TYPDACR",
    "assetId": "SOLANA.QDOT-HCPYICXVZIKBYTGOQMLUMOOAWBKCLVZMZB9D6TYPDACR",
    "assetAddress": "HCpyiCXvziKByTGoQMLUmooAWBkCLvZmzB9D6TyPdACR",
    "assetName": "QDOT",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.qdot-hcpyicxvzikbytgoqmlumooawbkclvzmzb9d6typdacr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FC-DAT9V3J3CV959NRVMOENNFRQHTB68G2B6OVU6R9TAJRG",
    "assetId": "SOLANA.FC-DAT9V3J3CV959NRVMOENNFRQHTB68G2B6OVU6R9TAJRG",
    "assetAddress": "DAt9V3J3Cv959NRVmoENNfrqhTB68g2B6ovU6r9tajrG",
    "assetName": "FC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fc-dat9v3j3cv959nrvmoennfrqhtb68g2b6ovu6r9tajrg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BZONE-6BE23PRIA6RVSHFBHKFIWF5FRPWXYG6EYVOMVVLSS91P",
    "assetId": "SOLANA.BZONE-6BE23PRIA6RVSHFBHKFIWF5FRPWXYG6EYVOMVVLSS91P",
    "assetAddress": "6bE23pRia6rvShfBhkFiWF5fRPWXyG6EYvoMvvLss91p",
    "assetName": "BZONE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bzone-6be23pria6rvshfbhkfiwf5frpwxyg6eyvomvvlss91p.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MOCKJUP-JXXWSVM9JHT4AH7DT9NULYVLYZCZLUDPD93PCPQ71KA",
    "assetId": "SOLANA.MOCKJUP-JXXWSVM9JHT4AH7DT9NULYVLYZCZLUDPD93PCPQ71KA",
    "assetAddress": "JxxWsvm9jHt4ah7DT9NuLyVLYZcZLUdPD93PcPQ71Ka",
    "assetName": "mockJUP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mockjup-jxxwsvm9jht4ah7dt9nulyvlyzczludpd93pcpq71ka.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$SWTS-5SWXHEUNUUUCWE4OJYBDDWKY6DPLXAEHNMF4AA71STNH",
    "assetId": "SOLANA.$SWTS-5SWXHEUNUUUCWE4OJYBDDWKY6DPLXAEHNMF4AA71STNH",
    "assetAddress": "5SwxhEunuUUcWe4ojybdDwky6dpLxAehNmF4AA71STNh",
    "assetName": "$SWTS",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.swts-5swxheunuuucwe4ojybddwky6dplxaehnmf4aa71stnh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AKIRA-FQSKP1BXNKEALEYZPA7RED47AQMDUZZ8FSG1F3RTKRJH",
    "assetId": "SOLANA.AKIRA-FQSKP1BXNKEALEYZPA7RED47AQMDUZZ8FSG1F3RTKRJH",
    "assetAddress": "FqSkp1BxNKEaLeyZPA7REd47aQMDUzz8fsg1f3rtKrJh",
    "assetName": "AKIRA",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.akira-fqskp1bxnkealeyzpa7red47aqmduzz8fsg1f3rtkrjh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HYPE-8TQDIAZDZZETKWUR8ZJ1TQDYGPV9TR1XJPQREW39VQ9V",
    "assetId": "SOLANA.HYPE-8TQDIAZDZZETKWUR8ZJ1TQDYGPV9TR1XJPQREW39VQ9V",
    "assetAddress": "8TQdiAzdZZEtkWUR8Zj1tqDYGPv9TR1XjPqrew39Vq9V",
    "assetName": "HYPE",
    "decimals": 7,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hype-8tqdiazdzzetkwur8zj1tqdygpv9tr1xjpqrew39vq9v.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HOWIE-9CQLBCA7N6SF1LTB6RZHJXEMTVWFBK9CRXEWTQ6YGQHG",
    "assetId": "SOLANA.HOWIE-9CQLBCA7N6SF1LTB6RZHJXEMTVWFBK9CRXEWTQ6YGQHG",
    "assetAddress": "9CQLBCa7n6sf1LTB6rZHJxEmtVWfBk9cRxEWTq6ygQHG",
    "assetName": "HOWIE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.howie-9cqlbca7n6sf1ltb6rzhjxemtvwfbk9crxewtq6ygqhg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HIJI-EJERRBOCW7EWYKMFIMHPCKAPSUBUKYHUYWV2DLJYDWB7",
    "assetId": "SOLANA.HIJI-EJERRBOCW7EWYKMFIMHPCKAPSUBUKYHUYWV2DLJYDWB7",
    "assetAddress": "EjErrBoCw7eWYkMfimhPckaPSuBukyhUYwv2dLJYDWB7",
    "assetName": "hiji",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hiji-ejerrbocw7ewykmfimhpckapsubukyhuywv2dljydwb7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CROC-5V9QPFO8NMFYHYHBRQY2C1TJRVZZSNRJADJ4RYPVB18N",
    "assetId": "SOLANA.CROC-5V9QPFO8NMFYHYHBRQY2C1TJRVZZSNRJADJ4RYPVB18N",
    "assetAddress": "5V9qpFo8NMFyhYHbrqY2c1TJrvzZSnRJAdj4ryPVB18n",
    "assetName": "croc",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.croc-5v9qpfo8nmfyhyhbrqy2c1tjrvzzsnrjadj4rypvb18n.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.NOHAT-5BKTP1CWAO5DHR8TKKCFPW9MWKKTUHEMEAU6NIH2JSX",
    "assetId": "SOLANA.NOHAT-5BKTP1CWAO5DHR8TKKCFPW9MWKKTUHEMEAU6NIH2JSX",
    "assetAddress": "5BKTP1cWao5dhr8tkKcfPW9mWkKtuheMEAU6nih2jSX",
    "assetName": "NoHat",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.nohat-5bktp1cwao5dhr8tkkcfpw9mwkktuhemeau6nih2jsx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.USDCET-A9MUU4QVISCTJVPJDBJWKB28DEG915LYJKRZQ19JI3FM",
    "assetId": "SOLANA.USDCET-A9MUU4QVISCTJVPJDBJWKB28DEG915LYJKRZQ19JI3FM",
    "assetAddress": "A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM",
    "assetName": "USDCet",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.usdcet-a9muu4qvisctjvpjdbjwkb28deg915lyjkrzq19ji3fm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DATE-CE3PSQFKXT5UA4R2JQCOWYRMWKWC5HEZWSRT9HB7MAZ9",
    "assetId": "SOLANA.DATE-CE3PSQFKXT5UA4R2JQCOWYRMWKWC5HEZWSRT9HB7MAZ9",
    "assetAddress": "Ce3PSQfkxT5ua4r2JqCoWYrMwKWC5hEzwsrT9Hb7mAz9",
    "assetName": "DATE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.date-ce3psqfkxt5ua4r2jqcowyrmwkwc5hezwsrt9hb7maz9.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CLBNB-6KNSFQZCRY1VDD9QUB1ZHRCFSNZQRQHMPT2XN4X5P2CJ",
    "assetId": "SOLANA.CLBNB-6KNSFQZCRY1VDD9QUB1ZHRCFSNZQRQHMPT2XN4X5P2CJ",
    "assetAddress": "6KnsFQzCrY1VdD9Qub1ZhRcFSnzQRqhMpT2Xn4X5P2cj",
    "assetName": "clBNB",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.clbnb-6knsfqzcry1vdd9qub1zhrcfsnzqrqhmpt2xn4x5p2cj.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DAINSOL-2LUXDPKN7ZWMQUFWGUV7ZISGGGKSE5FPEHCHBSRGLG3M",
    "assetId": "SOLANA.DAINSOL-2LUXDPKN7ZWMQUFWGUV7ZISGGGKSE5FPEHCHBSRGLG3M",
    "assetAddress": "2LuXDpkn7ZWMqufwgUv7ZisggGkSE5FpeHCHBsRgLg3m",
    "assetName": "dainSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dainsol-2luxdpkn7zwmqufwguv7zisgggkse5fpehchbsrglg3m.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WHC-9VLTWHS7ZOPE97RAJ1KBK2N8FHCNDU5QXQXWV1A3WMKO",
    "assetId": "SOLANA.WHC-9VLTWHS7ZOPE97RAJ1KBK2N8FHCNDU5QXQXWV1A3WMKO",
    "assetAddress": "9VLtWhS7Zope97RAJ1KBk2n8FHcNDu5QxqXWv1A3WMko",
    "assetName": "WHC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.whc-9vltwhs7zope97raj1kbk2n8fhcndu5qxqxwv1a3wmko.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MERTD-DDRMUJ77T7D6PKBDXE47ZALJSZM3UDVGJMGZJZBK41LH",
    "assetId": "SOLANA.MERTD-DDRMUJ77T7D6PKBDXE47ZALJSZM3UDVGJMGZJZBK41LH",
    "assetAddress": "DDRmuJ77t7d6pkBDXE47ZALjSZm3udVGJMgZjZBk41LH",
    "assetName": "MERTD",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mertd-ddrmuj77t7d6pkbdxe47zaljszm3udvgjmgzjzbk41lh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WNDO-E8G4UO2I9D12AGNXDHXXCW6HU2FH2NYTR5XR3QURTLBX",
    "assetId": "SOLANA.WNDO-E8G4UO2I9D12AGNXDHXXCW6HU2FH2NYTR5XR3QURTLBX",
    "assetAddress": "E8G4uo2i9d12aGnXDHXXcw6hU2fh2NytR5XR3qurTLBx",
    "assetName": "WNDO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wndo-e8g4uo2i9d12agnxdhxxcw6hu2fh2nytr5xr3qurtlbx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MUSCAT-6PPSKLWEJGJIRBSRDTVPDO8AYF6RERXVSEZCPDA53TAP",
    "assetId": "SOLANA.MUSCAT-6PPSKLWEJGJIRBSRDTVPDO8AYF6RERXVSEZCPDA53TAP",
    "assetAddress": "6PPskLwejGjiRbsrdTvPdo8Ayf6rERxvSezcPDA53TAP",
    "assetName": "MusCat",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.muscat-6ppsklwejgjirbsrdtvpdo8ayf6rerxvsezcpda53tap.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LAPTOP-HLWEJQVZS7SVJXUXPBTRHALP5S6UWOWTUHLJJXBFY1C7",
    "assetId": "SOLANA.LAPTOP-HLWEJQVZS7SVJXUXPBTRHALP5S6UWOWTUHLJJXBFY1C7",
    "assetAddress": "HLwEJQVzs7SvjXuXpBTRHaLp5S6uWoWTUhLjJxBfy1c7",
    "assetName": "LAPTOP",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.laptop-hlwejqvzs7svjxuxpbtrhalp5s6uwowtuhljjxbfy1c7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MP-5ZYBNE6UXTN6HMTPXCNPW61IA1VYCNTZLVQDUUNOAZB",
    "assetId": "SOLANA.MP-5ZYBNE6UXTN6HMTPXCNPW61IA1VYCNTZLVQDUUNOAZB",
    "assetAddress": "5zYbnE6UXTn6HMTPXCNPW61iA1vyCNTZLVQdUUnoazB",
    "assetName": "MP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mp-5zybne6uxtn6hmtpxcnpw61ia1vycntzlvqduunoazb.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GREG-ZZRRHGNDBUUSBN4VM47RUAGDYT57HBBSKQ2BA6K5775",
    "assetId": "SOLANA.GREG-ZZRRHGNDBUUSBN4VM47RUAGDYT57HBBSKQ2BA6K5775",
    "assetAddress": "zZRRHGndBuUsbn4VM47RuagdYt57hBbskQ2Ba6K5775",
    "assetName": "GREG",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.greg-zzrrhgndbuusbn4vm47ruagdyt57hbbskq2ba6k5775.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SFTT-9-FTT9RBBRYWCHAM4QLVKZZZHRSIHYMBZ3K6WJBDOAHXAT",
    "assetId": "SOLANA.SFTT-9-FTT9RBBRYWCHAM4QLVKZZZHRSIHYMBZ3K6WJBDOAHXAT",
    "assetAddress": "9",
    "assetName": "sFTT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sftt-9-ftt9rbbrywcham4qlvkzzzhrsihymbz3k6wjbdoahxat.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$FROG-FROG8VT6GMUUUUUERD7ISPP6YAVSSBUTMPEP5DCEUUGD",
    "assetId": "SOLANA.$FROG-FROG8VT6GMUUUUUERD7ISPP6YAVSSBUTMPEP5DCEUUGD",
    "assetAddress": "Frog8vt6gmuuUuuerd7ispP6yavssBUtMpEP5DCEuUgD",
    "assetName": "$FROG",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.frog-frog8vt6gmuuuuuerd7ispp6yavssbutmpep5dceuugd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.POOR-2MQI8ILJF9WAVRHD7NHRKQVCAFXFB3US7WSHAJR73YKS",
    "assetId": "SOLANA.POOR-2MQI8ILJF9WAVRHD7NHRKQVCAFXFB3US7WSHAJR73YKS",
    "assetAddress": "2mqi8iLJf9WaVRHd7nhRkqvCafxfb3Us7wshaJR73ykS",
    "assetName": "POOR",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.poor-2mqi8iljf9wavrhd7nhrkqvcafxfb3us7wshajr73yks.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$YETI-FDKBUXKXCDNQNDRQP7DLE8KRI3HZFRXCXYOSKOPA74RK",
    "assetId": "SOLANA.$YETI-FDKBUXKXCDNQNDRQP7DLE8KRI3HZFRXCXYOSKOPA74RK",
    "assetAddress": "FDKBUXKxCdNQnDrqP7DLe8Kri3hzFRxcXyoskoPa74rk",
    "assetName": "$YETI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.yeti-fdkbuxkxcdnqndrqp7dle8kri3hzfrxcxyoskopa74rk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OLUMPC-5WJRHXBCYSTCN2KVAJV4CUBA5WDUPRQVA9YOHYTC2WBI",
    "assetId": "SOLANA.OLUMPC-5WJRHXBCYSTCN2KVAJV4CUBA5WDUPRQVA9YOHYTC2WBI",
    "assetAddress": "5WjrhXBcYsTcn2KvaJv4cuBA5WDuprQVA9YoHYTC2wbi",
    "assetName": "OLUMPC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.olumpc-5wjrhxbcystcn2kvajv4cuba5wduprqva9yohytc2wbi.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.UPT-UPTX1D24ABWURGWXVNFMX4GNRAJ3QGFZL3QQBGXTWQG",
    "assetId": "SOLANA.UPT-UPTX1D24ABWURGWXVNFMX4GNRAJ3QGFZL3QQBGXTWQG",
    "assetAddress": "UPTx1d24aBWuRgwxVnFmX4gNraj3QGFzL3QqBgxtWQG",
    "assetName": "UPT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.upt-uptx1d24abwurgwxvnfmx4gnraj3qgfzl3qqbgxtwqg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SCOIN-5QKDWKBEJLTRH1UGFV7E58QEKDN2FRYH5EHVXQUYUJNW",
    "assetId": "SOLANA.SCOIN-5QKDWKBEJLTRH1UGFV7E58QEKDN2FRYH5EHVXQUYUJNW",
    "assetAddress": "5qKDWkBejLtRh1UGFV7e58QEkdn2fRyH5ehVXqUYujNW",
    "assetName": "SCOIN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.scoin-5qkdwkbejltrh1ugfv7e58qekdn2fryh5ehvxquyujnw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SETH-8-SL819J8K9FUFPL84UEPVCFKEZQDUUVVZWDMJJCHYSYJ",
    "assetId": "SOLANA.SETH-8-SL819J8K9FUFPL84UEPVCFKEZQDUUVVZWDMJJCHYSYJ",
    "assetAddress": "8",
    "assetName": "sETH",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.seth-8-sl819j8k9fufpl84uepvcfkezqduuvvzwdmjjchysyj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KARMA-8QYH37JFCVBGSJQPDMSF8TDWP1JHTJU1MCA8GOCCPUMP",
    "assetId": "SOLANA.KARMA-8QYH37JFCVBGSJQPDMSF8TDWP1JHTJU1MCA8GOCCPUMP",
    "assetAddress": "8qYH37jFCVbGSjQPdMsf8TDwp1JHTjU1McA8GoCCpump",
    "assetName": "KARMA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.karma-8qyh37jfcvbgsjqpdmsf8tdwp1jhtju1mca8goccpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SMORES-SMOEHMZMWEWBNPD1QOU4ZJUVNBXMFCHQY4NRMBBTW7V",
    "assetId": "SOLANA.SMORES-SMOEHMZMWEWBNPD1QOU4ZJUVNBXMFCHQY4NRMBBTW7V",
    "assetAddress": "smoEhMZMweWBnpd1QoU4ZjuVNBxMFchqy4NRMBbtW7V",
    "assetName": "SMORES",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.smores-smoehmzmwewbnpd1qou4zjuvnbxmfchqy4nrmbbtw7v.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PICOSOL-PICOBAEVS6W7QEKNPCE34WAE4GKNZA9V5TTONNMHYDX",
    "assetId": "SOLANA.PICOSOL-PICOBAEVS6W7QEKNPCE34WAE4GKNZA9V5TTONNMHYDX",
    "assetAddress": "picobAEvs6w7QEknPce34wAE4gknZA9v5tTonnmHYdX",
    "assetName": "picoSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.picosol-picobaevs6w7qeknpce34wae4gknza9v5ttonnmhydx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HORSEMEAT-2FPRJEK4MTSY9CXPKUENBGDDY69R15GHHTHPG5DURDBQ",
    "assetId": "SOLANA.HORSEMEAT-2FPRJEK4MTSY9CXPKUENBGDDY69R15GHHTHPG5DURDBQ",
    "assetAddress": "2FprjEk4MTSY9CxpKuENbGDdy69R15GHhtHpG5Durdbq",
    "assetName": "HorseMeat",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.horsemeat-2fprjek4mtsy9cxpkuenbgddy69r15ghhthpg5durdbq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OVOL-4V3UTV9JIBKHPFHI5AMEVROPW6VFKVWO7BMXWQZWEWQ6",
    "assetId": "SOLANA.OVOL-4V3UTV9JIBKHPFHI5AMEVROPW6VFKVWO7BMXWQZWEWQ6",
    "assetAddress": "4v3UTV9jibkhPfHi5amevropw6vFKVWo7BmxwQzwEwq6",
    "assetName": "OVOL",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ovol-4v3utv9jibkhpfhi5amevropw6vfkvwo7bmxwqzwewq6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RICH-A2PVD9WMEK9EK9MFBF6VVBM4UIGYK24TCME5OR2WDWGH",
    "assetId": "SOLANA.RICH-A2PVD9WMEK9EK9MFBF6VVBM4UIGYK24TCME5OR2WDWGH",
    "assetAddress": "A2PVd9wmEk9Ek9MFbF6VVBm4UiGYK24TCmE5oR2WDWGH",
    "assetName": "RICH",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rich-a2pvd9wmek9ek9mfbf6vvbm4uigyk24tcme5or2wdwgh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TAPES-DXRBKQB2CEQA4RNXJWQ4Z3GUKSGPL2U27PWBW8QBCNV2",
    "assetId": "SOLANA.TAPES-DXRBKQB2CEQA4RNXJWQ4Z3GUKSGPL2U27PWBW8QBCNV2",
    "assetAddress": "DxRbkqB2cEqa4rnXJwq4z3GuksgpL2U27PWBw8QBcNv2",
    "assetName": "TAPES",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tapes-dxrbkqb2ceqa4rnxjwq4z3guksgpl2u27pwbw8qbcnv2.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RRR-3ZE4QMH9HRC4INHYNT3ASRZHCWHNXPCHLEN9WWQVORVZ",
    "assetId": "SOLANA.RRR-3ZE4QMH9HRC4INHYNT3ASRZHCWHNXPCHLEN9WWQVORVZ",
    "assetAddress": "3zE4Qmh9hrC4inHynT3aSRzHcWhnxPCHLeN9WWqvoRVz",
    "assetName": "RRR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rrr-3ze4qmh9hrc4inhynt3asrzhcwhnxpchlen9wwqvorvz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DASCH-GTUDE5YNEFKAWSSPQQKGU413KTK8WYDNUZCZUGXUNYST",
    "assetId": "SOLANA.DASCH-GTUDE5YNEFKAWSSPQQKGU413KTK8WYDNUZCZUGXUNYST",
    "assetAddress": "GTuDe5yneFKaWSsPqqKgu413KTk8WyDnUZcZUGxuNYsT",
    "assetName": "DASCH",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dasch-gtude5ynefkawsspqqkgu413ktk8wydnuzczugxunyst.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DOLAN-4YK1NJYECKBUXG6PHNTIDJWKCBBHB659IWGKUJX98P5Z",
    "assetId": "SOLANA.DOLAN-4YK1NJYECKBUXG6PHNTIDJWKCBBHB659IWGKUJX98P5Z",
    "assetAddress": "4YK1njyeCkBuXG6phNtidJWKCbBhB659iwGkUJx98P5Z",
    "assetName": "DOLAN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dolan-4yk1njyeckbuxg6phntidjwkcbbhb659iwgkujx98p5z.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DANNY-5NHD3MSP6DXI9R1SAPKEB2DOZYXLVPIQV4N9J54CPUMP",
    "assetId": "SOLANA.DANNY-5NHD3MSP6DXI9R1SAPKEB2DOZYXLVPIQV4N9J54CPUMP",
    "assetAddress": "5NHd3MsP6dXi9r1saPkeB2DoZyXLvPiqv4n9J54Cpump",
    "assetName": "DANNY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.danny-5nhd3msp6dxi9r1sapkeb2dozyxlvpiqv4n9j54cpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHB-YTFMZ4JG2UBDZ4GASY86IUGJHDO5RCPJNFQGSF8GXAZ",
    "assetId": "SOLANA.CHB-YTFMZ4JG2UBDZ4GASY86IUGJHDO5RCPJNFQGSF8GXAZ",
    "assetAddress": "YtfMZ4jg2ubdz4GasY86iuGjHdo5rCPJnFqgSf8gxAz",
    "assetName": "CHB",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chb-ytfmz4jg2ubdz4gasy86iugjhdo5rcpjnfqgsf8gxaz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ROCK-5KXNFDMSXVBNKVHYHW4KZTV7ZCCCBRKYXBVRQLWF3G7J",
    "assetId": "SOLANA.ROCK-5KXNFDMSXVBNKVHYHW4KZTV7ZCCCBRKYXBVRQLWF3G7J",
    "assetAddress": "5KxnfDmsXVBNkVHYhW4kztV7ZCCCbrkYxBVrqLWF3G7J",
    "assetName": "Rock",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rock-5kxnfdmsxvbnkvhyhw4kztv7zcccbrkyxbvrqlwf3g7j.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLCHAN-CHANM2VKA4GJ3OB1SEJJXNXONUOAXGGJXDMJRSLD7NZW",
    "assetId": "SOLANA.SOLCHAN-CHANM2VKA4GJ3OB1SEJJXNXONUOAXGGJXDMJRSLD7NZW",
    "assetAddress": "ChanM2vka4gJ3ob1SejJXnxoNuoAXGGJxDMJRSLD7nzW",
    "assetName": "SOLCHAN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solchan-chanm2vka4gj3ob1sejjxnxonuoaxggjxdmjrsld7nzw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$DAT-DATIWD3NSBARARGVMAFCFPC9JF8QCIN7FJ4JRHAFUHFA",
    "assetId": "SOLANA.$DAT-DATIWD3NSBARARGVMAFCFPC9JF8QCIN7FJ4JRHAFUHFA",
    "assetAddress": "DATiwd3NsbaRArGvmaFcFpc9jF8qciN7Fj4jRHAFUHFA",
    "assetName": "$DAT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dat-datiwd3nsbarargvmafcfpc9jf8qcin7fj4jrhafuhfa.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHILN-2DFENQRC6EVNHA3WGXIQ8UOBTEEMSTSW6ONT6IZN6AEH",
    "assetId": "SOLANA.CHILN-2DFENQRC6EVNHA3WGXIQ8UOBTEEMSTSW6ONT6IZN6AEH",
    "assetAddress": "2DfEnQrC6EVnhA3wGXiQ8UoBtEEmStsW6oNT6izn6AeH",
    "assetName": "CHILN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chiln-2dfenqrc6evnha3wgxiq8uobteemstsw6ont6izn6aeh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CLINTON-AF7X9TJDKLDVAPFMNKSKMUUSXXPHUSGUZBDECTRBVVU3",
    "assetId": "SOLANA.CLINTON-AF7X9TJDKLDVAPFMNKSKMUUSXXPHUSGUZBDECTRBVVU3",
    "assetAddress": "AF7X9tJDkLDVApfMnKSkMUUsxxPhuSGUZbDECtrbvVu3",
    "assetName": "CLINTON",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.clinton-af7x9tjdkldvapfmnkskmuusxxphusguzbdectrbvvu3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GH0ST-HBXIDXQXBKMNJQDSTAVQE7LVWRTR36WJV2EAYEQUW6QH",
    "assetId": "SOLANA.GH0ST-HBXIDXQXBKMNJQDSTAVQE7LVWRTR36WJV2EAYEQUW6QH",
    "assetAddress": "HbxiDXQxBKMNJqDsTavQE7LVwrTR36wjV2EaYEqUw6qH",
    "assetName": "GH0ST",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gh0st-hbxidxqxbkmnjqdstavqe7lvwrtr36wjv2eayequw6qh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SRENBTC-10-BTX7AFZEJLNU8KQR1AGHRHGH5S2AHUTBFJHUQP8BHPVI",
    "assetId": "SOLANA.SRENBTC-10-BTX7AFZEJLNU8KQR1AGHRHGH5S2AHUTBFJHUQP8BHPVI",
    "assetAddress": "10",
    "assetName": "srenBTC",
    "decimals": 10,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.srenbtc-10-btx7afzejlnu8kqr1aghrhgh5s2ahutbfjhuqp8bhpvi.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BABS-CEQWD4CSGBZNW8PHEEXBQCAV2NDPKBOSQHEIKR7GZELV",
    "assetId": "SOLANA.BABS-CEQWD4CSGBZNW8PHEEXBQCAV2NDPKBOSQHEIKR7GZELV",
    "assetAddress": "Ceqwd4CSGBZnW8PHEexBQCAV2NDPkBoSqheiKR7gzELV",
    "assetName": "BABS",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.babs-ceqwd4csgbznw8pheexbqcav2ndpkbosqheikr7gzelv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.POZZ-27NQFZQB2IPBEVA7BBE4KPZRJGI3DJDKV9VZHCGUSY6Y",
    "assetId": "SOLANA.POZZ-27NQFZQB2IPBEVA7BBE4KPZRJGI3DJDKV9VZHCGUSY6Y",
    "assetAddress": "27nqFZqb2iPBeVA7bbE4KPZrJgi3dJdKV9VzhCguSy6Y",
    "assetName": "POZZ",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pozz-27nqfzqb2ipbeva7bbe4kpzrjgi3djdkv9vzhcgusy6y.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ROL-ROLLN5QBN4JUQ1D2KFPJYACC7DEO3CYOTXI4QDOOHLU",
    "assetId": "SOLANA.ROL-ROLLN5QBN4JUQ1D2KFPJYACC7DEO3CYOTXI4QDOOHLU",
    "assetAddress": "RoLLn5qBN4juQ1D2KFpJyAcC7Deo3cYotXi4qDooHLU",
    "assetName": "ROL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rol-rolln5qbn4juq1d2kfpjyacc7deo3cyotxi4qdoohlu.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SCRAP-6NAWDMGNWWQFFJNNXFLBCLAYU1Y5U9ROHE5WWJPHVF1P",
    "assetId": "SOLANA.SCRAP-6NAWDMGNWWQFFJNNXFLBCLAYU1Y5U9ROHE5WWJPHVF1P",
    "assetAddress": "6naWDMGNWwqffJnnXFLBCLaYu1y5U9Rohe5wwJPHvf1p",
    "assetName": "SCRAP",
    "decimals": 3,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.scrap-6nawdmgnwwqffjnnxflbclayu1y5u9rohe5wwjphvf1p.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$FLUFF-CORKC3R6MQYUTEMRC7D8JJF7UIUYFWURXGPYY1XQATNQ",
    "assetId": "SOLANA.$FLUFF-CORKC3R6MQYUTEMRC7D8JJF7UIUYFWURXGPYY1XQATNQ",
    "assetAddress": "CoRkC3r6MqYuTeMRc7D8JJF7UiUyFWurXGpYy1xQATNq",
    "assetName": "$FLUFF",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fluff-corkc3r6mqyutemrc7d8jjf7uiuyfwurxgpyy1xqatnq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CRRT-HKNOKFCXG33EU5VCCS49MQ3JZCKZEQSQCYTA964YXXYG",
    "assetId": "SOLANA.CRRT-HKNOKFCXG33EU5VCCS49MQ3JZCKZEQSQCYTA964YXXYG",
    "assetAddress": "HkNokfCXG33eu5vCcS49mq3jZcKZeQSQCyta964YxxYg",
    "assetName": "CRRT",
    "decimals": 0,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.crrt-hknokfcxg33eu5vccs49mq3jzckzeqsqcyta964yxxyg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JEOING737-H2ZPBXTZK1DADTZSOXQMHJN6BD3QZGEFHJUZ1E2ZUWAE",
    "assetId": "SOLANA.JEOING737-H2ZPBXTZK1DADTZSOXQMHJN6BD3QZGEFHJUZ1E2ZUWAE",
    "assetAddress": "H2ZpBXtzk1DaDTzsoXqMhjN6Bd3qzgefHjUZ1e2zuwAe",
    "assetName": "jeoing737",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jeoing737-h2zpbxtzk1dadtzsoxqmhjn6bd3qzgefhjuz1e2zuwae.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WDINGOCOIN-6VYF5JXQ6RFQ4QRGGMG6CO7B1EV1LJ7KSBHBXFQ9E1L3",
    "assetId": "SOLANA.WDINGOCOIN-6VYF5JXQ6RFQ4QRGGMG6CO7B1EV1LJ7KSBHBXFQ9E1L3",
    "assetAddress": "6VYF5jXq6rfq4QRgGMG6co7b1Ev1Lj7KSbHBxfQ9e1L3",
    "assetName": "wDingocoin",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wdingocoin-6vyf5jxq6rfq4qrggmg6co7b1ev1lj7ksbhbxfq9e1l3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LIBRA-HZ1XEPA2VUKQFBCF9P7VJ3ASMKOTXYPN3S21DNVGRHND",
    "assetId": "SOLANA.LIBRA-HZ1XEPA2VUKQFBCF9P7VJ3ASMKOTXYPN3S21DNVGRHND",
    "assetAddress": "Hz1XePA2vukqFBcf9P7VJ3AsMKoTXyPn3s21dNvGrHnd",
    "assetName": "LIBRA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.libra-hz1xepa2vukqfbcf9p7vj3asmkotxypn3s21dnvgrhnd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$MILK-8FKTFPUVI73YWF7ZIVSBUQFYQCQZUWVQSQ51HNVRHR26",
    "assetId": "SOLANA.$MILK-8FKTFPUVI73YWF7ZIVSBUQFYQCQZUWVQSQ51HNVRHR26",
    "assetAddress": "8FktFPUvi73Ywf7ZivsbuQFyQCqZUwvqSQ51hNvRhR26",
    "assetName": "$MILK",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.milk-8fktfpuvi73ywf7zivsbuqfyqcqzuwvqsq51hnvrhr26.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MIKU-7GNYPRQRPVJHNWZWSJ4KVKQW9CKYV5AR3EHM2HEE5PW",
    "assetId": "SOLANA.MIKU-7GNYPRQRPVJHNWZWSJ4KVKQW9CKYV5AR3EHM2HEE5PW",
    "assetAddress": "7GNyprqrpvJHNwzWSj4KVkqw9cKyV5aR3ehM2HeE5pw",
    "assetName": "Miku",
    "decimals": 0,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.miku-7gnyprqrpvjhnwzwsj4kvkqw9ckyv5ar3ehm2hee5pw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JUNKZ-AMZMWVDRKDT5AQ3M1M28TWJZBXMQNE1PSMHNYITVZWZP",
    "assetId": "SOLANA.JUNKZ-AMZMWVDRKDT5AQ3M1M28TWJZBXMQNE1PSMHNYITVZWZP",
    "assetAddress": "AMzmwvDRKdt5AQ3m1m28tWjzBxmQNe1PsmHnYitVZwzp",
    "assetName": "JUNKz",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.junkz-amzmwvdrkdt5aq3m1m28twjzbxmqne1psmhnyitvzwzp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FLIPGG-VVWAY5U2KFD1P8ADCHJUXQAJBZPBEP5VUQRZTAY8HYC",
    "assetId": "SOLANA.FLIPGG-VVWAY5U2KFD1P8ADCHJUXQAJBZPBEP5VUQRZTAY8HYC",
    "assetAddress": "VVWAy5U2KFd1p8AdchjUxqaJbZPBeP5vUQRZtAy8hyc",
    "assetName": "FLIPGG",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.flipgg-vvway5u2kfd1p8adchjuxqajbzpbep5vuqrztay8hyc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.OBEMA-BMQZZVJA1X5CBYC8FCRVBWUFC4CZA1S5GE3HEKWFUHJZ",
    "assetId": "SOLANA.OBEMA-BMQZZVJA1X5CBYC8FCRVBWUFC4CZA1S5GE3HEKWFUHJZ",
    "assetAddress": "BMQZzVJa1X5cBYc8fCRVbWuFC4czA1s5GE3HekWfuhjz",
    "assetName": "obema",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.obema-bmqzzvja1x5cbyc8fcrvbwufc4cza1s5ge3hekwfuhjz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.EDO-7SWMHQAJJPN1RKEIZQ41IPIW5GG7RY7PXYAVHAUWY4QU",
    "assetId": "SOLANA.EDO-7SWMHQAJJPN1RKEIZQ41IPIW5GG7RY7PXYAVHAUWY4QU",
    "assetAddress": "7sWMHQaJJPn1rkeizq41iPiW5gG7Ry7PXYAVHaUwY4qu",
    "assetName": "EDO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.edo-7swmhqajjpn1rkeizq41ipiw5gg7ry7pxyavhauwy4qu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MEESA-DAYYRQTTKQGCBIPTNXSUT5D8NDXBRTW5LOJHSG4OPUMP",
    "assetId": "SOLANA.MEESA-DAYYRQTTKQGCBIPTNXSUT5D8NDXBRTW5LOJHSG4OPUMP",
    "assetAddress": "DaYYrQTtKqGCBiPtnXSUT5d8nDXBrtW5LoJHsg4opump",
    "assetName": "meesa",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.meesa-dayyrqttkqgcbiptnxsut5d8ndxbrtw5lojhsg4opump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DWAKE-9OUXHGFMW2HWQWHDS1NOV3DKLY3AATNEVA3DP7PTYEBR",
    "assetId": "SOLANA.DWAKE-9OUXHGFMW2HWQWHDS1NOV3DKLY3AATNEVA3DP7PTYEBR",
    "assetAddress": "9oUXhgFmW2HWqWHds1NoV3DKLY3AAtNevA3dP7PtyEbr",
    "assetName": "DWAKE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dwake-9ouxhgfmw2hwqwhds1nov3dkly3aatneva3dp7ptyebr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DMME-CG9WYPMF9EUQWGFUSAPANFKUAI4ENZGGGCH7MRVAFM8P",
    "assetId": "SOLANA.DMME-CG9WYPMF9EUQWGFUSAPANFKUAI4ENZGGGCH7MRVAFM8P",
    "assetAddress": "CG9WyPmf9EUQWGFUsaPAnFKuAi4eNZGGgCh7mrvAFm8p",
    "assetName": "DMME",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dmme-cg9wypmf9euqwgfusapanfkuai4enzgggch7mrvafm8p.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AEDAI-9W6LPS7RU1DKFTIWH3NGSHTXBKMM1KE9INU4G3MBXSUS",
    "assetId": "SOLANA.AEDAI-9W6LPS7RU1DKFTIWH3NGSHTXBKMM1KE9INU4G3MBXSUS",
    "assetAddress": "9w6LpS7RU1DKftiwH3NgShtXbkMM1ke9iNU4g3MBXSUs",
    "assetName": "aeDAI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.aedai-9w6lps7ru1dkftiwh3ngshtxbkmm1ke9inu4g3mbxsus.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LSTAR-C6QEP3Y7TCZUJYDXHIWUK46GT6FSOXLI8QV1BTCRYAY1",
    "assetId": "SOLANA.LSTAR-C6QEP3Y7TCZUJYDXHIWUK46GT6FSOXLI8QV1BTCRYAY1",
    "assetAddress": "C6qep3y7tCZUJYDXHiwuK46Gt6FsoxLi8qV1bTCRYaY1",
    "assetName": "LSTAR",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lstar-c6qep3y7tczujydxhiwuk46gt6fsoxli8qv1btcryay1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WDAI_V1-FYPDBUYAHSBDAAYD1SKKXYLWBAP8UUW9H6UVDHK74IJ1",
    "assetId": "SOLANA.WDAI_V1-FYPDBUYAHSBDAAYD1SKKXYLWBAP8UUW9H6UVDHK74IJ1",
    "assetAddress": "FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1",
    "assetName": "wDAI_v1",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wdai_v1-fypdbuyahsbdaayd1skkxylwbap8uuw9h6uvdhk74ij1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$DOH-DSAD47JGGNOJ8P4LJQ8X4YCFRMHBL7KD1JS3TJJ7X52C",
    "assetId": "SOLANA.$DOH-DSAD47JGGNOJ8P4LJQ8X4YCFRMHBL7KD1JS3TJJ7X52C",
    "assetAddress": "Dsad47jgGNoJ8p4LJQ8x4yCFRMHBL7kd1Js3TJj7X52C",
    "assetName": "$DOH",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.doh-dsad47jggnoj8p4ljq8x4ycfrmhbl7kd1js3tjj7x52c.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HOTTO-BQD2UJCTEZPKZFJB1FHL7FKRDM6N1RZSNRECJK57EOKZ",
    "assetId": "SOLANA.HOTTO-BQD2UJCTEZPKZFJB1FHL7FKRDM6N1RZSNRECJK57EOKZ",
    "assetAddress": "Bqd2ujCTEzpKzfjb1FHL7FKrdM6n1rZSnRecJK57EoKz",
    "assetName": "HOTTO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hotto-bqd2ujctezpkzfjb1fhl7fkrdm6n1rzsnrecjk57eokz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLALA-HTPHKNF5RBNCUZ1QK3MUJA6YO1HEHZJ722OSESJNPBIW",
    "assetId": "SOLANA.SOLALA-HTPHKNF5RBNCUZ1QK3MUJA6YO1HEHZJ722OSESJNPBIW",
    "assetAddress": "HTPHknF5rBNCuZ1qK3muJA6Yo1HEhZj722oSESJnPBiw",
    "assetName": "Solala",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.solala-htphknf5rbncuz1qk3muja6yo1hehzj722osesjnpbiw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PHASESOL-PHASEZSFPXTDBPIVB96H4XFSD8XHEHXZRE5HEREHBJG",
    "assetId": "SOLANA.PHASESOL-PHASEZSFPXTDBPIVB96H4XFSD8XHEHXZRE5HEREHBJG",
    "assetAddress": "phaseZSfPxTDBpiVb96H4XFSD8xHeHxZre5HerehBJG",
    "assetName": "phaseSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.phasesol-phasezsfpxtdbpivb96h4xfsd8xhehxzre5herehbjg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GAY-9GNU66C8QTMMJ8RZ481N8UMS1ZMQDV5HGTQJYRVXT8MS",
    "assetId": "SOLANA.GAY-9GNU66C8QTMMJ8RZ481N8UMS1ZMQDV5HGTQJYRVXT8MS",
    "assetAddress": "9GnU66c8qtMmj8rZ481N8Ums1Zmqdv5HgtQJyRVXt8MS",
    "assetName": "GAY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gay-9gnu66c8qtmmj8rz481n8ums1zmqdv5hgtqjyrvxt8ms.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$TEST-D8TFO4FSDF9CNEJKEZEZTWDHDMWAINGLRDAQUTWWQUBU",
    "assetId": "SOLANA.$TEST-D8TFO4FSDF9CNEJKEZEZTWDHDMWAINGLRDAQUTWWQUBU",
    "assetAddress": "D8TFo4FsDF9CnejkezEZtwdhdmwaiNgLRDAQUTwWQuBU",
    "assetName": "$TEST",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.test-d8tfo4fsdf9cnejkezeztwdhdmwainglrdaqutwwqubu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.IVRY-2MTPZQWNKTNSBOFCWM4ZTWK3YSZ4LSD82UCDGETK7VNU",
    "assetId": "SOLANA.IVRY-2MTPZQWNKTNSBOFCWM4ZTWK3YSZ4LSD82UCDGETK7VNU",
    "assetAddress": "2MtPZqwNKTNsBoFCwm4ZTWk3ySz4LSd82ucDGeTk7VNu",
    "assetName": "IVRY",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ivry-2mtpzqwnktnsbofcwm4ztwk3ysz4lsd82ucdgetk7vnu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LADYBOY-AMCKJCFZFQ7LWY6MNDGHGCNKCQAI5FTHXAMZYRDWOGTF",
    "assetId": "SOLANA.LADYBOY-AMCKJCFZFQ7LWY6MNDGHGCNKCQAI5FTHXAMZYRDWOGTF",
    "assetAddress": "AmCKJCFZfq7Lwy6MnDgHGcnKcqAi5ftHxAMzYRDwogTF",
    "assetName": "ladyboy",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ladyboy-amckjcfzfq7lwy6mndghgcnkcqai5fthxamzyrdwogtf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MAMI-62MALBEZUQWS3R8EZJNX1C2RICDTY9HKV8GS7MLTPUMP",
    "assetId": "SOLANA.MAMI-62MALBEZUQWS3R8EZJNX1C2RICDTY9HKV8GS7MLTPUMP",
    "assetAddress": "62mALBEzUQWS3r8EzjnX1C2ricdTy9hkv8gs7mLtpump",
    "assetName": "Mami",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.mami-62malbezuqws3r8ezjnx1c2ricdty9hkv8gs7mltpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HODI-HODIZE88VH3SVRYYX2FE6ZYE6SSXPN9XJUMUKW1DG6A",
    "assetId": "SOLANA.HODI-HODIZE88VH3SVRYYX2FE6ZYE6SSXPN9XJUMUKW1DG6A",
    "assetAddress": "HodiZE88VH3SvRYYX2fE6zYE6SsxPn9xJUMUkW1Dg6A",
    "assetName": "HODI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hodi-hodize88vh3svryyx2fe6zye6ssxpn9xjumukw1dg6a.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HOOD-AGYUUFVYGNUUXWG6GUKGA4B3MGMBUEZ9HGQY73N76XPJ",
    "assetId": "SOLANA.HOOD-AGYUUFVYGNUUXWG6GUKGA4B3MGMBUEZ9HGQY73N76XPJ",
    "assetAddress": "AGyUuFvYGnUUXWG6GUKga4B3MGmBuEZ9hGqY73n76XpJ",
    "assetName": "HOOD",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hood-agyuufvygnuuxwg6gukga4b3mgmbuez9hgqy73n76xpj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PONK-HEQCCMJMUV5S25J49YIJYT6BD5QWLKP88YPAJBYSNIAV",
    "assetId": "SOLANA.PONK-HEQCCMJMUV5S25J49YIJYT6BD5QWLKP88YPAJBYSNIAV",
    "assetAddress": "HeqCcMjmuV5s25J49YiJyT6bD5qWLkP88YPajBySniaV",
    "assetName": "PONK",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ponk-heqccmjmuv5s25j49yijyt6bd5qwlkp88ypajbysniav.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SNAP-SNAPMCWQQJ3NY2YFKQMKELQNNGAXRU6KMNYSPIFZCLN",
    "assetId": "SOLANA.SNAP-SNAPMCWQQJ3NY2YFKQMKELQNNGAXRU6KMNYSPIFZCLN",
    "assetAddress": "SNApmcWQqj3Ny2YFkQmkELQnNgaXRu6KmnYSPiFZcLn",
    "assetName": "SNAP",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.snap-snapmcwqqj3ny2yfkqmkelqnngaxru6kmnyspifzcln.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MĀO-XGWKZ2H4TNSTP3NZGYVFGA6ADU3Q7UCBTIYT7CERPA9",
    "assetId": "SOLANA.MĀO-XGWKZ2H4TNSTP3NZGYVFGA6ADU3Q7UCBTIYT7CERPA9",
    "assetAddress": "xgWKZ2h4tnstP3NzGyVFgA6Adu3Q7uCBtiYt7cErPA9",
    "assetName": "Māo",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.m%C4%81o-xgwkz2h4tnstp3nzgyvfga6adu3q7ucbtiyt7cerpa9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.UPT-9CPWICFL11DWR92DSTRZLF5CKZ6ZKHLZ3XZRCYCQ3ND2",
    "assetId": "SOLANA.UPT-9CPWICFL11DWR92DSTRZLF5CKZ6ZKHLZ3XZRCYCQ3ND2",
    "assetAddress": "9CPWiCFL11DWr92dsTrzLf5cKz6zKhLz3xzRcYCQ3Nd2",
    "assetName": "UPT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.upt-9cpwicfl11dwr92dstrzlf5ckz6zkhlz3xzrcycq3nd2.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ACF-2CZV8HRGCWSVC6N1UEIS48CEQGB1D3FIOWP2RPA4WBL9",
    "assetId": "SOLANA.ACF-2CZV8HRGCWSVC6N1UEIS48CEQGB1D3FIOWP2RPA4WBL9",
    "assetAddress": "2cZv8HrgcWSvC6n1uEiS48cEQGb1d3fiowP2rpa4wBL9",
    "assetName": "ACF",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.acf-2czv8hrgcwsvc6n1ueis48ceqgb1d3fiowp2rpa4wbl9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WIFOUT-2IOYWEEEV4XJCKFJVH868X9IP3L6Q31MVCAWFBJLRTHQ",
    "assetId": "SOLANA.WIFOUT-2IOYWEEEV4XJCKFJVH868X9IP3L6Q31MVCAWFBJLRTHQ",
    "assetAddress": "2ioyweEeV4xJCkFJvh868X9iP3L6Q31MVCawfbJLRTHq",
    "assetName": "WIFOUT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wifout-2ioyweeev4xjckfjvh868x9ip3l6q31mvcawfbjlrthq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$DICKI-8EHC2GFTLDB2EGQFJM17MVNLWPGRC9YVD75BEPZ2NZJA",
    "assetId": "SOLANA.$DICKI-8EHC2GFTLDB2EGQFJM17MVNLWPGRC9YVD75BEPZ2NZJA",
    "assetAddress": "8EHC2gfTLDb2eGQfjm17mVNLWPGRc9YVD75bepZ2nZJa",
    "assetName": "$dicki",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dicki-8ehc2gftldb2egqfjm17mvnlwpgrc9yvd75bepz2nzja.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOLS-2WME8EVKW8QSFSK2B3QEX4S64AC6WXHPXB3GRDCKEKIO",
    "assetId": "SOLANA.SOLS-2WME8EVKW8QSFSK2B3QEX4S64AC6WXHPXB3GRDCKEKIO",
    "assetAddress": "2wme8EVkw8qsfSk2B3QeX4S64ac6wxHPXb3GrdckEkio",
    "assetName": "sols",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sols-2wme8evkw8qsfsk2b3qex4s64ac6wxhpxb3grdckekio.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JOEL-JACSU5F2FCSQSCDNZ1VX2SE4VMQYJ8K5EYIGD4RPPVGV",
    "assetId": "SOLANA.JOEL-JACSU5F2FCSQSCDNZ1VX2SE4VMQYJ8K5EYIGD4RPPVGV",
    "assetAddress": "JACSU5f2fCsQSCDNz1VX2Se4vmQyj8k5EYigD4RppvGV",
    "assetName": "JOEL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.joel-jacsu5f2fcsqscdnz1vx2se4vmqyj8k5eyigd4rppvgv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SOETH-2FPYTWCZLUG1MDRWSYOP4D6S1TM7HAKHYRJKNB5W6PXK",
    "assetId": "SOLANA.SOETH-2FPYTWCZLUG1MDRWSYOP4D6S1TM7HAKHYRJKNB5W6PXK",
    "assetAddress": "2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk",
    "assetName": "soETH",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.soeth-2fpytwczlug1mdrwsyop4d6s1tm7hakhyrjknb5w6pxk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WCAPS_V1-FELOYXK8AC2AYVMDHAWEKNWWT63Z9TCZEIDYBPDVXF3T",
    "assetId": "SOLANA.WCAPS_V1-FELOYXK8AC2AYVMDHAWEKNWWT63Z9TCZEIDYBPDVXF3T",
    "assetAddress": "FeLoyXk8ac2AYVmDhAWEKNWWT63Z9TczeidYbpDvxF3T",
    "assetName": "wCAPS_v1",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wcaps_v1-feloyxk8ac2ayvmdhaweknwwt63z9tczeidybpdvxf3t.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BHST-BHSTV1NSZ57FSXZZCSYWXHEYGYQAAQU63DUJD3RCP5JO",
    "assetId": "SOLANA.BHST-BHSTV1NSZ57FSXZZCSYWXHEYGYQAAQU63DUJD3RCP5JO",
    "assetAddress": "BHSTv1nsz57fsXZzcsYWXhEygyqAaQu63DUJd3Rcp5Jo",
    "assetName": "BHST",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bhst-bhstv1nsz57fsxzzcsywxheygyqaaqu63dujd3rcp5jo.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HIKO-DR9S6HHSRSDOM1ZHWTJ7VP3RSJ3AX2Y7JA2ZYEHQIC7F",
    "assetId": "SOLANA.HIKO-DR9S6HHSRSDOM1ZHWTJ7VP3RSJ3AX2Y7JA2ZYEHQIC7F",
    "assetAddress": "Dr9s6hHSrSDom1zhwtJ7vp3RsJ3AX2y7ja2ZYEHQiC7f",
    "assetName": "HIKO",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hiko-dr9s6hhsrsdom1zhwtj7vp3rsj3ax2y7ja2zyehqic7f.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$BEER-AUJTJJ7AMS8LDO3BFZOYXDWT3JBALUBU4VZHZZDTZLMG",
    "assetId": "SOLANA.$BEER-AUJTJJ7AMS8LDO3BFZOYXDWT3JBALUBU4VZHZZDTZLMG",
    "assetAddress": "AujTJJ7aMS8LDo3bFzoyXDwT3jBALUbu4VZhzZdTZLmG",
    "assetName": "$BEER",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.beer-aujtjj7ams8ldo3bfzoyxdwt3jbalubu4vzhzzdtzlmg.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WCC-BYKKD9369RVXUEDBR7PTRZ49B7CFLRTZHGSVOQK8GC15",
    "assetId": "SOLANA.WCC-BYKKD9369RVXUEDBR7PTRZ49B7CFLRTZHGSVOQK8GC15",
    "assetAddress": "BykkD9369RvXuEDbR7pTRz49b7cfLRTzHgSVoqK8gc15",
    "assetName": "WCC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wcc-bykkd9369rvxuedbr7ptrz49b7cflrtzhgsvoqk8gc15.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WHITE-FUCKUTFQVT9YCE3JPXDEJBPHCPJPNCEQY17UVCT9CLX8",
    "assetId": "SOLANA.WHITE-FUCKUTFQVT9YCE3JPXDEJBPHCPJPNCEQY17UVCT9CLX8",
    "assetAddress": "FUCKuTfQVT9yCe3jPXdejBPhcPJpnceQy17uvcT9cLx8",
    "assetName": "white",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.white-fuckutfqvt9yce3jpxdejbphcpjpnceqy17uvct9clx8.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TOOKER-9EYSCPIYSGNEIMNQPZAZR7JN9GVFXFYZGTEJ85HV9L6U",
    "assetId": "SOLANA.TOOKER-9EYSCPIYSGNEIMNQPZAZR7JN9GVFXFYZGTEJ85HV9L6U",
    "assetAddress": "9EYScpiysGnEimnQPzazr7Jn9GVfxFYzgTEj85hV9L6U",
    "assetName": "tooker",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tooker-9eyscpiysgneimnqpzazr7jn9gvfxfyzgtej85hv9l6u.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHEESE-ABRMJWFDVRZ2EWCQ1XSCPOVEVGZNPQ1U2AOYG98ORXFN",
    "assetId": "SOLANA.CHEESE-ABRMJWFDVRZ2EWCQ1XSCPOVEVGZNPQ1U2AOYG98ORXFN",
    "assetAddress": "AbrMJWfDVRZ2EWCQ1xSCpoVeVgZNpq1U2AoYG98oRXfn",
    "assetName": "Cheese",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cheese-abrmjwfdvrz2ewcq1xscpovevgznpq1u2aoyg98orxfn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.VBLSH-FNMLMBPKHH7NBFYGHSDRMCUVGEF6YGPAVQFEJIMHEX9V",
    "assetId": "SOLANA.VBLSH-FNMLMBPKHH7NBFYGHSDRMCUVGEF6YGPAVQFEJIMHEX9V",
    "assetAddress": "FNMLmBPkhh7nBFyGHsdrmCuvgEf6ygpaVqFejimHEx9V",
    "assetName": "vBLSH",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.vblsh-fnmlmbpkhh7nbfyghsdrmcuvgef6ygpavqfejimhex9v.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FUX-PZ8KJM9AHRU8MVJEBXHJZ6RWXQJTGXVTTRSAQTCUYQF",
    "assetId": "SOLANA.FUX-PZ8KJM9AHRU8MVJEBXHJZ6RWXQJTGXVTTRSAQTCUYQF",
    "assetAddress": "pz8KjM9AHRu8MvjEbxHjZ6RwxQjTgxvTtRsaQtCUyQf",
    "assetName": "FUX",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fux-pz8kjm9ahru8mvjebxhjz6rwxqjtgxvttrsaqtcuyqf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.IMSO-IMSORU6JUZTJ4PTJVPQATNY766UEMCVC4AQJ8V1YUVS",
    "assetId": "SOLANA.IMSO-IMSORU6JUZTJ4PTJVPQATNY766UEMCVC4AQJ8V1YUVS",
    "assetAddress": "imsoru6juztJ4pTJvPQaTnY766uEmCVc4AQJ8V1Yuvs",
    "assetName": "IMSO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.imso-imsoru6juztj4ptjvpqatny766uemcvc4aqj8v1yuvs.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ABBUSD-6NUAX3OGRR2CAOAPJTAKHAOBNWOK32BMCROZUF32S2QF",
    "assetId": "SOLANA.ABBUSD-6NUAX3OGRR2CAOAPJTAKHAOBNWOK32BMCROZUF32S2QF",
    "assetAddress": "6nuaX3ogrr2CaoAPjtaKHAoBNWok32BMcRozuf32s2QF",
    "assetName": "abBUSD",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.abbusd-6nuax3ogrr2caoapjtakhaobnwok32bmcrozuf32s2qf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SKYH-J9HBNNA1TMYSX9HA3FGIC5XYPH5PYHMCLVQ2SP36HDKZ",
    "assetId": "SOLANA.SKYH-J9HBNNA1TMYSX9HA3FGIC5XYPH5PYHMCLVQ2SP36HDKZ",
    "assetAddress": "J9hBnna1TMySX9hA3FgiC5xYph5pYHmcLVq2Sp36hDkz",
    "assetName": "SKYH",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.skyh-j9hbnna1tmysx9ha3fgic5xyph5pyhmclvq2sp36hdkz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HAPPI-ABVS3ZV9JYMVUCKQRJVGCTDQSCT6R7NAMELID3FEQSQ",
    "assetId": "SOLANA.HAPPI-ABVS3ZV9JYMVUCKQRJVGCTDQSCT6R7NAMELID3FEQSQ",
    "assetAddress": "aBvs3Zv9JYmvUCKqRJvGctDQSCt6R7NAMELid3FeqsQ",
    "assetName": "HAPPI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.happi-abvs3zv9jymvuckqrjvgctdqsct6r7namelid3feqsq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.YSOL-YSO11ZXLBHA3WBJ9HATVU6WNESQZ9A2QXNHXANASZ4N",
    "assetId": "SOLANA.YSOL-YSO11ZXLBHA3WBJ9HATVU6WNESQZ9A2QXNHXANASZ4N",
    "assetAddress": "yso11zxLbHA3wBJ9HAtVu6wnesqz9A2qxnhxanasZ4N",
    "assetName": "ySOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ysol-yso11zxlbha3wbj9hatvu6wnesqz9a2qxnhxanasz4n.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LUNY-7A4CXVVVT7KF6HS5Q5LDQTZWFHFYS4A9POK6PF87RKWF",
    "assetId": "SOLANA.LUNY-7A4CXVVVT7KF6HS5Q5LDQTZWFHFYS4A9POK6PF87RKWF",
    "assetAddress": "7a4cXVvVT7kF6hS5q5LDqtzWfHfys4a9PoK6pf87RKwf",
    "assetName": "LUNY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.luny-7a4cxvvvt7kf6hs5q5ldqtzwfhfys4a9pok6pf87rkwf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WFTT_V1-GBBWWTYTMPIS4VHB8MRBBDIBPHN28TSRLB53KVUMB7GI",
    "assetId": "SOLANA.WFTT_V1-GBBWWTYTMPIS4VHB8MRBBDIBPHN28TSRLB53KVUMB7GI",
    "assetAddress": "GbBWwtYTMPis4VHb8MrBbdibPhn28TSrLB53KvUmb7Gi",
    "assetName": "wFTT_v1",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wftt_v1-gbbwwtytmpis4vhb8mrbbdibphn28tsrlb53kvumb7gi.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LILY-LILYT885CG9XZKYQK9X6VWMZMCUI4UEV9J1UZPDDAJY",
    "assetId": "SOLANA.LILY-LILYT885CG9XZKYQK9X6VWMZMCUI4UEV9J1UZPDDAJY",
    "assetAddress": "LiLyT885cG9xZKYQk9x6VWMzmcui4ueV9J1uzPDDajY",
    "assetName": "LILY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lily-lilyt885cg9xzkyqk9x6vwmzmcui4uev9j1uzpddajy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LAINESOL-LAINETNLGPMCP9RVSF5HN8W6EHNIKLZQTI1XFWMLY6X",
    "assetId": "SOLANA.LAINESOL-LAINETNLGPMCP9RVSF5HN8W6EHNIKLZQTI1XFWMLY6X",
    "assetAddress": "LAinEtNLgpmCP9Rvsf5Hn8W6EhNiKLZQti1xfWMLy6X",
    "assetName": "laineSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.lainesol-lainetnlgpmcp9rvsf5hn8w6ehniklzqti1xfwmly6x.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.LEDGER-4GKVFD2SFKJR266HEEC4XXWD7OPVXESLTAH5URXDN2VT",
    "assetId": "SOLANA.LEDGER-4GKVFD2SFKJR266HEEC4XXWD7OPVXESLTAH5URXDN2VT",
    "assetAddress": "4gkvFD2SFKjR266heEc4XxWd7oPVxesLtAh5uRXdN2vt",
    "assetName": "LEDGER",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ledger-4gkvfd2sfkjr266heec4xxwd7opvxesltah5urxdn2vt.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MESSI-HIRX4TWNMUPK6VKXGKPSW8T7DZ9NUCRAKPNPROICEYRA",
    "assetId": "SOLANA.MESSI-HIRX4TWNMUPK6VKXGKPSW8T7DZ9NUCRAKPNPROICEYRA",
    "assetAddress": "HiRx4TWNmuPK6VKxGKPSw8T7dZ9NuCrakpNpRoiCEyrA",
    "assetName": "MESSI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.messi-hirx4twnmupk6vkxgkpsw8t7dz9nucrakpnproiceyra.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GOTTI-FOANSCG6CCQTQ2RSTI58YYYBNK1HGSBLZS6B1KTP2ACL",
    "assetId": "SOLANA.GOTTI-FOANSCG6CCQTQ2RSTI58YYYBNK1HGSBLZS6B1KTP2ACL",
    "assetAddress": "FoAnSCG6CcqTq2rsTi58yyYBNk1HgsbLzS6b1kTP2ACL",
    "assetName": "GOTTI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gotti-foanscg6ccqtq2rsti58yyybnk1hgsblzs6b1ktp2acl.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BLZE-BLZEEUZUBVQFHJ8ADCCFPJVPVCICYVMH3HKJMRU8KUJA",
    "assetId": "SOLANA.BLZE-BLZEEUZUBVQFHJ8ADCCFPJVPVCICYVMH3HKJMRU8KUJA",
    "assetAddress": "BLZEEuZUBVqFhj8adcCFPJvPVCiCyVmh3hkJMrU8KuJA",
    "assetName": "BLZE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.blze-blzeeuzubvqfhj8adccfpjvpvcicyvmh3hkjmru8kuja.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PRTSOL-BDZPG9XWRG3UFRX2KRUW1JT4TZ9VKPDWKNYIHZOPRJS3",
    "assetId": "SOLANA.PRTSOL-BDZPG9XWRG3UFRX2KRUW1JT4TZ9VKPDWKNYIHZOPRJS3",
    "assetAddress": "BdZPG9xWrG3uFrx2KrUW1jT4tZ9VKPDWknYihzoPRJS3",
    "assetName": "prtSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.prtsol-bdzpg9xwrg3ufrx2kruw1jt4tz9vkpdwknyihzoprjs3.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$TIPS-AK87OZM8OVFU14BQ8MU4UFI5ZSCPDBQQVVH5B6WHBUDL",
    "assetId": "SOLANA.$TIPS-AK87OZM8OVFU14BQ8MU4UFI5ZSCPDBQQVVH5B6WHBUDL",
    "assetAddress": "AK87oZM8ovFU14bq8mu4ufi5zsCPDbqqVvh5b6WHbUdL",
    "assetName": "$TIPS",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tips-ak87ozm8ovfu14bq8mu4ufi5zscpdbqqvvh5b6whbudl.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ROGEN-DTGDZB83TQYWCUBUWE89JX4K5Y7B6NQ4GYJQ3WD61JQQ",
    "assetId": "SOLANA.ROGEN-DTGDZB83TQYWCUBUWE89JX4K5Y7B6NQ4GYJQ3WD61JQQ",
    "assetAddress": "DtgDZb83TqywcuBuWE89jx4k5Y7b6nQ4GYJq3Wd61JQQ",
    "assetName": "rogen",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.rogen-dtgdzb83tqywcubuwe89jx4k5y7b6nq4gyjq3wd61jqq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ELMNT-9XYEZDHEWYUQJMQRTOURBFRAXN2QHKYESNZ3IQ3FPZ4R",
    "assetId": "SOLANA.ELMNT-9XYEZDHEWYUQJMQRTOURBFRAXN2QHKYESNZ3IQ3FPZ4R",
    "assetAddress": "9xYeZDHEwyuqJmqrTourbFRaxN2qhkYesnz3iQ3FPz4r",
    "assetName": "ELMNT",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.elmnt-9xyezdhewyuqjmqrtourbfraxn2qhkyesnz3iq3fpz4r.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GODEX-GODCAGCTSLDNKV8T6JBAFTEMJ8DESXT71CKZEKV5ZXCF",
    "assetId": "SOLANA.GODEX-GODCAGCTSLDNKV8T6JBAFTEMJ8DESXT71CKZEKV5ZXCF",
    "assetAddress": "GoDCaGctsLDnkV8T6jBAfTeMj8DesxT71CkZekV5ZXcf",
    "assetName": "GODEX",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.godex-godcagctsldnkv8t6jbaftemj8desxt71ckzekv5zxcf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PUTEN-6Y56NYAUTKTJT5LKRVVZ3RSLNKYQ96PHU3CKSARUKNAX",
    "assetId": "SOLANA.PUTEN-6Y56NYAUTKTJT5LKRVVZ3RSLNKYQ96PHU3CKSARUKNAX",
    "assetAddress": "6y56NYaUtKtjT5LKrVvZ3rsLnKyQ96phU3ckSARUkNax",
    "assetName": "puten",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.puten-6y56nyautktjt5lkrvvz3rslnkyq96phu3cksaruknax.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHUD-6YJNQPZTSANBWSA6DXVEGTJEPXBRZ2FOHLDQWYWESYM6",
    "assetId": "SOLANA.CHUD-6YJNQPZTSANBWSA6DXVEGTJEPXBRZ2FOHLDQWYWESYM6",
    "assetAddress": "6yjNqPzTSanBWSa6dxVEgTjePXBrZ2FoHLDQwYwEsyM6",
    "assetName": "Chud",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chud-6yjnqpztsanbwsa6dxvegtjepxbrz2fohldqwywesym6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$GRW-FPYMKKGPG1SLFBVAO4JMK4IP8XB8C8UKQFMDARMOBHAW",
    "assetId": "SOLANA.$GRW-FPYMKKGPG1SLFBVAO4JMK4IP8XB8C8UKQFMDARMOBHAW",
    "assetAddress": "FPymkKgpg1sLFbVao4JMk4ip8xb8C8uKqfMdARMobHaw",
    "assetName": "$GRW",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.grw-fpymkkgpg1slfbvao4jmk4ip8xb8c8ukqfmdarmobhaw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JU-ABBEJBDZ31YCB9GTFFWGEI7UPRCZJTEKL58XQHVBBJHU",
    "assetId": "SOLANA.JU-ABBEJBDZ31YCB9GTFFWGEI7UPRCZJTEKL58XQHVBBJHU",
    "assetAddress": "AbBEjBdz31yCb9gTFfWgEi7uprCzjTEKL58xqHVbbjhu",
    "assetName": "ju",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ju-abbejbdz31ycb9gtffwgei7uprczjtekl58xqhvbbjhu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GU-5KV2W2XPDSO97WQWCUAVI6G4PACOIEG4LHHI61PAMAMJ",
    "assetId": "SOLANA.GU-5KV2W2XPDSO97WQWCUAVI6G4PACOIEG4LHHI61PAMAMJ",
    "assetAddress": "5KV2W2XPdSo97wQWcuAVi6G4PaCoieg4Lhhi61PAMaMJ",
    "assetName": "GU",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gu-5kv2w2xpdso97wqwcuavi6g4pacoieg4lhhi61pamamj.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TUB-VM4ZLJ9WSCVPQDY6UBQ7NECRZFVDLY4YLGERDNJEUB9",
    "assetId": "SOLANA.TUB-VM4ZLJ9WSCVPQDY6UBQ7NECRZFVDLY4YLGERDNJEUB9",
    "assetAddress": "Vm4ZLJ9WsCVPqdy6ubq7NECRZfvdLY4yLGERDnjEuB9",
    "assetName": "tub",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.tub-vm4zlj9wscvpqdy6ubq7necrzfvdly4ylgerdnjeub9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HASUKI-DMVSB8CJKXDQJS8CVODTKXX7KEMVEZ31KZYCSZUINJUY",
    "assetId": "SOLANA.HASUKI-DMVSB8CJKXDQJS8CVODTKXX7KEMVEZ31KZYCSZUINJUY",
    "assetAddress": "DMvsB8cjKXDQJs8cvoDtKxX7KEMVeZ31KzycszuinJUY",
    "assetName": "HASUKI",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hasuki-dmvsb8cjkxdqjs8cvodtkxx7kemvez31kzycszuinjuy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DIRTY-AUWLSEUDRJI6HVCXBEEZ9WVBPERSITVVBZG1SVZEBAXF",
    "assetId": "SOLANA.DIRTY-AUWLSEUDRJI6HVCXBEEZ9WVBPERSITVVBZG1SVZEBAXF",
    "assetAddress": "AuWLSEuDRJi6hVcXbeez9WVbpeRsiTvvbZG1svzeBaxf",
    "assetName": "DIRTY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dirty-auwlseudrji6hvcxbeez9wvbpersitvvbzg1svzebaxf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TRUMPIE-HC2KYVKPK9MC9NEFPUI43OKHTYPA5FSTK6A3CI3CDBQS",
    "assetId": "SOLANA.TRUMPIE-HC2KYVKPK9MC9NEFPUI43OKHTYPA5FSTK6A3CI3CDBQS",
    "assetAddress": "HC2KyVkPK9Mc9NEFPUi43okhTYPa5fStk6a3Ci3cDbQS",
    "assetName": "trumpie",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.trumpie-hc2kyvkpk9mc9nefpui43okhtypa5fstk6a3ci3cdbqs.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CWR-3HKAY2QCNJAXS3KEBJNDUGYBGAKLVUZHRGZYRMGJSSBM",
    "assetId": "SOLANA.CWR-3HKAY2QCNJAXS3KEBJNDUGYBGAKLVUZHRGZYRMGJSSBM",
    "assetAddress": "3hkAy2qcNJaxS3KebjNdugYbgakLvUZHRGZyRmGjSsbm",
    "assetName": "CWR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cwr-3hkay2qcnjaxs3kebjndugybgaklvuzhrgzyrmgjssbm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.RATSOL-HNRRR5YBYGBZ3ORDGVC4PGQSC5MNLTYVTFKAMT4WNDMZ",
    "assetId": "SOLANA.RATSOL-HNRRR5YBYGBZ3ORDGVC4PGQSC5MNLTYVTFKAMT4WNDMZ",
    "assetAddress": "HNRrr5YBYgBz3orDGVc4pGQsc5mnLtyVtfKaMt4WNDMz",
    "assetName": "RatSol",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ratsol-hnrrr5ybygbz3ordgvc4pgqsc5mnltyvtfkamt4wndmz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ISKT-ISKTKK27QATPORUHWWS5N9YUOYF8YDCUOTZ5R2TFEKU",
    "assetId": "SOLANA.ISKT-ISKTKK27QATPORUHWWS5N9YUOYF8YDCUOTZ5R2TFEKU",
    "assetAddress": "isktkk27QaTpoRUhwwS5n9YUoYf8ydCuoTz5R2tFEKu",
    "assetName": "ISKT",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.iskt-isktkk27qatporuhwws5n9yuoyf8ydcuotz5r2tfeku.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MOAI-7NQSHJUEGENZDWFSVPZZ7OP2D6C5JC3LJFC6UH179UFR",
    "assetId": "SOLANA.MOAI-7NQSHJUEGENZDWFSVPZZ7OP2D6C5JC3LJFC6UH179UFR",
    "assetAddress": "7NQSHjuEGENZDWfSvPZz7oP2D6c5Jc3LjFC6uh179ufr",
    "assetName": "MOAI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.moai-7nqshjuegenzdwfsvpzz7op2d6c5jc3ljfc6uh179ufr.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOTMAN-2ADQRK24G2IU2MMEIJZKPKG69DUWGK3FUWAQX7Y1KSSK",
    "assetId": "SOLANA.BOTMAN-2ADQRK24G2IU2MMEIJZKPKG69DUWGK3FUWAQX7Y1KSSK",
    "assetAddress": "2adqrK24G2iu2mmEijzKPKg69DUwGk3fUWAQx7Y1kSSK",
    "assetName": "BOTMAN",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.botman-2adqrk24g2iu2mmeijzkpkg69duwgk3fuwaqx7y1kssk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PBP-3F7WFG9YHLTGKVY75MMQSVT1UETFOQYYSQBUSRX1YAQ4",
    "assetId": "SOLANA.PBP-3F7WFG9YHLTGKVY75MMQSVT1UETFOQYYSQBUSRX1YAQ4",
    "assetAddress": "3f7wfg9yHLtGKvy75MmqsVT1ueTFoqyySQbusrX1YAQ4",
    "assetName": "PbP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pbp-3f7wfg9yhltgkvy75mmqsvt1uetfoqyysqbusrx1yaq4.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.YAKU-AQEHVH8J2NXH9SAV2CIZYYWPPQWFRFD2FFCQ5Z8XXQM5",
    "assetId": "SOLANA.YAKU-AQEHVH8J2NXH9SAV2CIZYYWPPQWFRFD2FFCQ5Z8XXQM5",
    "assetAddress": "AqEHVh8J2nXH9saV2ciZyYwPpqWFRfD2ffcq5Z8xxqm5",
    "assetName": "YAKU",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.yaku-aqehvh8j2nxh9sav2cizyywppqwfrfd2ffcq5z8xxqm5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HPOW-H865QN9MXUJDGSDS2HTNRMTRRG2Z28OHGBQJJXFAWOQM",
    "assetId": "SOLANA.HPOW-H865QN9MXUJDGSDS2HTNRMTRRG2Z28OHGBQJJXFAWOQM",
    "assetAddress": "H865QN9mXUjDgSds2HtNrmTrRg2Z28oHgBQjJXfAWoQm",
    "assetName": "HPOW",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hpow-h865qn9mxujdgsds2htnrmtrrg2z28ohgbqjjxfawoqm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MILADY-HB2PJBPUQUH1XGEVC1GCKEPDH4DAOJNKJIHFMAORFBWU",
    "assetId": "SOLANA.MILADY-HB2PJBPUQUH1XGEVC1GCKEPDH4DAOJNKJIHFMAORFBWU",
    "assetAddress": "HB2pjBPuquh1XGEVC1GCKepdh4daoJnkJiHFmaorfBWu",
    "assetName": "Milady",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.milady-hb2pjbpuquh1xgevc1gckepdh4daojnkjihfmaorfbwu.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CLAPT-HHNCIFGW3YJYAW2FRRFBYAAWND9OGBSWM5PCCFA4GHSX",
    "assetId": "SOLANA.CLAPT-HHNCIFGW3YJYAW2FRRFBYAAWND9OGBSWM5PCCFA4GHSX",
    "assetAddress": "HHncifGW3yJyaW2fRRfBYAawnD9ogbsWM5PccFA4GHSx",
    "assetName": "clAPT",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.clapt-hhncifgw3yjyaw2frrfbyaawnd9ogbswm5pccfa4ghsx.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GOMU-BX74HPFIAIBBSONRJYQXJGFAA7GRXM2CEKIY31UN6BIR",
    "assetId": "SOLANA.GOMU-BX74HPFIAIBBSONRJYQXJGFAA7GRXM2CEKIY31UN6BIR",
    "assetAddress": "Bx74hpFiaiBbSonrjyqxjGfAA7gRxM2CeKiy31uN6biR",
    "assetName": "gomu",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gomu-bx74hpfiaibbsonrjyqxjgfaa7grxm2cekiy31un6bir.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.TOWL-9J3VEMBNG3JHCL7LPAAX2COLQE46AYMGPBBFGHKZVKWZ",
    "assetId": "SOLANA.TOWL-9J3VEMBNG3JHCL7LPAAX2COLQE46AYMGPBBFGHKZVKWZ",
    "assetAddress": "9j3vEmBng3JhcL7LPaAx2CoLqE46AYmGPbbFgHKZVKWZ",
    "assetName": "TOWL",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.towl-9j3vembng3jhcl7lpaax2colqe46aymgpbbfghkzvkwz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DJCAT-A5GVJ5J16U4VCTMXQYYRHKMWGX6GEJ4NAEZRNQMLUAFY",
    "assetId": "SOLANA.DJCAT-A5GVJ5J16U4VCTMXQYYRHKMWGX6GEJ4NAEZRNQMLUAFY",
    "assetAddress": "A5gVj5j16U4vCTMXQYYrhkmWgx6gej4nAeZRNQMLUAfy",
    "assetName": "DJCAT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.djcat-a5gvj5j16u4vctmxqyyrhkmwgx6gej4naezrnqmluafy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ANONN-GREXYJQVAAETQBSIZROJTWHTNQXRAVGZIJPCK5QTJ1ZC",
    "assetId": "SOLANA.ANONN-GREXYJQVAAETQBSIZROJTWHTNQXRAVGZIJPCK5QTJ1ZC",
    "assetAddress": "GreXYjqvAaEtqBsizrojtwHTNqXraVGZiJpCk5qtj1zC",
    "assetName": "ANONN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.anonn-grexyjqvaaetqbsizrojtwhtnqxravgzijpck5qtj1zc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DEA-HXTRWX83K61KJSNU1ICB4UHX9CBUTCSIZRJLXXSZYYEM",
    "assetId": "SOLANA.DEA-HXTRWX83K61KJSNU1ICB4UHX9CBUTCSIZRJLXXSZYYEM",
    "assetAddress": "HxtRWx83K61KjsNu1iCB4uhX9cbUtcSizRjLXXSZyyEm",
    "assetName": "DEA",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dea-hxtrwx83k61kjsnu1icb4uhx9cbutcsizrjlxxszyyem.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DOBEN-EAID1TVWAFAVVTI9EROMDCOVP1OK1DPRPBRKJMZEHFXM",
    "assetId": "SOLANA.DOBEN-EAID1TVWAFAVVTI9EROMDCOVP1OK1DPRPBRKJMZEHFXM",
    "assetAddress": "EaiD1TvwaFavVTi9eroMDcovp1ok1dpRpbrKJmzeHfXM",
    "assetName": "doben",
    "decimals": 4,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.doben-eaid1tvwafavvti9eromdcovp1ok1dprpbrkjmzehfxm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HARIBO-CYOBWQ73DYIYERWCVGLKYMSZKXN5VY18JKXIDJEQM6OY",
    "assetId": "SOLANA.HARIBO-CYOBWQ73DYIYERWCVGLKYMSZKXN5VY18JKXIDJEQM6OY",
    "assetAddress": "CYobWQ73DYiyERWcvgLkYMSzkXn5Vy18JKxidjeqM6oy",
    "assetName": "HARIBO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.haribo-cyobwq73dyiyerwcvglkymszkxn5vy18jkxidjeqm6oy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.VIVAION-2JW1UFMC1HHFJH3EQGHAE2RFZMMC2YBPXKZCDUBPPPMN",
    "assetId": "SOLANA.VIVAION-2JW1UFMC1HHFJH3EQGHAE2RFZMMC2YBPXKZCDUBPPPMN",
    "assetAddress": "2jw1uFmc1hhfJH3EqGhaE2rfZMMC2YBpxkZcdUbPppMn",
    "assetName": "VIVAION",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.vivaion-2jw1ufmc1hhfjh3eqghae2rfzmmc2ybpxkzcdubpppmn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BELUGA-E63CDWLY9DWR3EPTAZOPV9RUWOQNN5ZVYEJLWNJX8DCW",
    "assetId": "SOLANA.BELUGA-E63CDWLY9DWR3EPTAZOPV9RUWOQNN5ZVYEJLWNJX8DCW",
    "assetAddress": "E63CDwLy9Dwr3EptAzopV9RuWoQnn5ZVYEjLWnJX8dCw",
    "assetName": "BELUGA",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.beluga-e63cdwly9dwr3eptazopv9ruwoqnn5zvyejlwnjx8dcw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SUITE-4H41QKUKQPD2PCAFXNNGZUYGUXQ6E7FMEXAZZHZICVHH",
    "assetId": "SOLANA.SUITE-4H41QKUKQPD2PCAFXNNGZUYGUXQ6E7FMEXAZZHZICVHH",
    "assetAddress": "4h41QKUkQPd2pCAFXNNgZUyGUxQ6E7fMexaZZHziCvhh",
    "assetName": "SUITE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.suite-4h41qkukqpd2pcafxnngzuyguxq6e7fmexazzhzicvhh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WHO-A8EYJPNQF6QYEE7DNM4F514MERFZV1PXVT69D7MDSSMK",
    "assetId": "SOLANA.WHO-A8EYJPNQF6QYEE7DNM4F514MERFZV1PXVT69D7MDSSMK",
    "assetAddress": "A8eYjpNQF6QYEE7DnM4F514MErFzV1pxVT69d7mdSsMk",
    "assetName": "WHO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.who-a8eyjpnqf6qyee7dnm4f514merfzv1pxvt69d7mdssmk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KOW-3WOATNLFMFFAZU8ACSMRSDWFNGPJQ4GUMFJXTSZODQAU",
    "assetId": "SOLANA.KOW-3WOATNLFMFFAZU8ACSMRSDWFNGPJQ4GUMFJXTSZODQAU",
    "assetAddress": "3WoatNLfMffazu8aCSmRSdWFngpJQ4GUMFJxtSzoDqau",
    "assetName": "KOW",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.kow-3woatnlfmffazu8acsmrsdwfngpjq4gumfjxtszodqau.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MONK-FYA25XNBSXQXADTNSYKBKD5GZ1VZHCHBRF57CQFRXJZX",
    "assetId": "SOLANA.MONK-FYA25XNBSXQXADTNSYKBKD5GZ1VZHCHBRF57CQFRXJZX",
    "assetAddress": "FYa25XnBsXQXAdTnsyKBKd5gZ1VZhChBRF57CqfRxJZX",
    "assetName": "monk",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.monk-fya25xnbsxqxadtnsykbkd5gz1vzhchbrf57cqfrxjzx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GSOL-GSO1XA56HACFGTHTF4F7WN5R4JBNJSKH99VR595UYBA",
    "assetId": "SOLANA.GSOL-GSO1XA56HACFGTHTF4F7WN5R4JBNJSKH99VR595UYBA",
    "assetAddress": "gso1xA56hacfgTHTF4F7wN5r4jbnJsKh99vR595uybA",
    "assetName": "GSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gsol-gso1xa56hacfgthtf4f7wn5r4jbnjskh99vr595uyba.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$WIFI-ACFKWAF4UQLA5DLRQD2EQXW6PLV79VKZVSO38WMFTXGZ",
    "assetId": "SOLANA.$WIFI-ACFKWAF4UQLA5DLRQD2EQXW6PLV79VKZVSO38WMFTXGZ",
    "assetAddress": "AcfKwAf4UQLA5DLRQD2eQxW6pLv79VKzVso38WMfTxGZ",
    "assetName": "$WIFI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wifi-acfkwaf4uqla5dlrqd2eqxw6plv79vkzvso38wmftxgz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CHAI-3JSFX1TX2Z8EWMAMIWSU851GZYZM2DJMQ7KWW5DM8PY3",
    "assetId": "SOLANA.CHAI-3JSFX1TX2Z8EWMAMIWSU851GZYZM2DJMQ7KWW5DM8PY3",
    "assetAddress": "3jsFX1tx2Z8ewmamiwSU851GzyzM2DJMq7KWW5DM8Py3",
    "assetName": "CHAI",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.chai-3jsfx1tx2z8ewmamiwsu851gzyzm2djmq7kww5dm8py3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BROKIE-4NESYZJMCSWQF58DKHDO7FNZJDDKSGAAQQRZUSXS5U6G",
    "assetId": "SOLANA.BROKIE-4NESYZJMCSWQF58DKHDO7FNZJDDKSGAAQQRZUSXS5U6G",
    "assetAddress": "4neSyzJmcSWQF58DKHdo7FNzJDDKSgaaQqrzuSXS5U6g",
    "assetName": "BROKIE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.brokie-4nesyzjmcswqf58dkhdo7fnzjddksgaaqqrzusxs5u6g.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MONKES-FMOKY2ERGME9NZRYPHAJCQH5BPRY2HS4VOMRFU8QGT7Y",
    "assetId": "SOLANA.MONKES-FMOKY2ERGME9NZRYPHAJCQH5BPRY2HS4VOMRFU8QGT7Y",
    "assetAddress": "FmoKY2ERGmE9NzrYphAJcqH5BPRy2Hs4VomRfu8Qgt7Y",
    "assetName": "MONKES",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.monkes-fmoky2ergme9nzryphajcqh5bpry2hs4vomrfu8qgt7y.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DCAP-BQXDYWZDTXQELXFWYERHLRGH8GCTMDQZQC92ENMAXSRY",
    "assetId": "SOLANA.DCAP-BQXDYWZDTXQELXFWYERHLRGH8GCTMDQZQC92ENMAXSRY",
    "assetAddress": "BQXDYWZdtXqeLXFWYeRhLrGh8gcTmDQZQc92ENMaXSry",
    "assetName": "DCAP",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.dcap-bqxdywzdtxqelxfwyerhlrgh8gctmdqzqc92enmaxsry.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GURYVEE-AGQC1DM7DD7VA5RRWBEZOX1CTYBKEBWASW83KBHJKBPN",
    "assetId": "SOLANA.GURYVEE-AGQC1DM7DD7VA5RRWBEZOX1CTYBKEBWASW83KBHJKBPN",
    "assetAddress": "Agqc1Dm7DD7va5RRwbEZoX1CtYBKEBwASw83KbhjKBPn",
    "assetName": "GuryVee",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.guryvee-agqc1dm7dd7va5rrwbezox1ctybkebwasw83kbhjkbpn.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SAMOWIF-GS1VJXDZMDFSIQZBFYOACGRQBMXYUVDPJ88NQCXXG3QM",
    "assetId": "SOLANA.SAMOWIF-GS1VJXDZMDFSIQZBFYOACGRQBMXYUVDPJ88NQCXXG3QM",
    "assetAddress": "GS1VjXDZmDFsiqzBFYoACgRQBmXYuvdPJ88NQcXxg3qM",
    "assetName": "SAMOWIF",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.samowif-gs1vjxdzmdfsiqzbfyoacgrqbmxyuvdpj88nqcxxg3qm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BATT-BZJSQPGKJXZEDFAWUV9GZMEXHR7VBVSASQV12V2PVXST",
    "assetId": "SOLANA.BATT-BZJSQPGKJXZEDFAWUV9GZMEXHR7VBVSASQV12V2PVXST",
    "assetAddress": "BzjsQpgKjxZedFawUV9GZMExhr7VbvsasQv12v2PVxSt",
    "assetName": "BATT",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.batt-bzjsqpgkjxzedfawuv9gzmexhr7vbvsasqv12v2pvxst.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BAZINGA-C3JX9TWLQHKMCOTDTPPAJEBX2U7DCUQDEHVSMJFZ6K6S",
    "assetId": "SOLANA.BAZINGA-C3JX9TWLQHKMCOTDTPPAJEBX2U7DCUQDEHVSMJFZ6K6S",
    "assetAddress": "C3JX9TWLqHKmcoTDTppaJebX2U7DcUQDEHVSmJFz6K6S",
    "assetName": "Bazinga",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bazinga-c3jx9twlqhkmcotdtppajebx2u7dcuqdehvsmjfz6k6s.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.KKO-KINEKO77W1WBEZFFCXRTDRWGRWGP8YHVKC9RX6DQJQH",
    "assetId": "SOLANA.KKO-KINEKO77W1WBEZFFCXRTDRWGRWGP8YHVKC9RX6DQJQH",
    "assetAddress": "kiNeKo77w1WBEzFFCXrTDRWGRWGP8yHvKC9rX6dqjQh",
    "assetName": "KKO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.kko-kineko77w1wbezffcxrtdrwgrwgp8yhvkc9rx6dqjqh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.XSTEP-XSTPGUCSS9PIQEFUK2ILVCVJEGHADJXJQUWLKXP555G",
    "assetId": "SOLANA.XSTEP-XSTPGUCSS9PIQEFUK2ILVCVJEGHADJXJQUWLKXP555G",
    "assetAddress": "xStpgUCss9piqeFUk2iLVcvJEGhAdJxJQuwLkXP555G",
    "assetName": "xSTEP",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.xstep-xstpgucss9piqefuk2ilvcvjeghadjxjquwlkxp555g.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BADGER-9V4X6IKFM9XKSNH3TIYJWPWQFFKJZDJIFU7VSUQG3ES1",
    "assetId": "SOLANA.BADGER-9V4X6IKFM9XKSNH3TIYJWPWQFFKJZDJIFU7VSUQG3ES1",
    "assetAddress": "9V4x6ikFm9XKsnh3TiYJWPwQfFkJZDjifu7VSUqg3es1",
    "assetName": "Badger",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.badger-9v4x6ikfm9xksnh3tiyjwpwqffkjzdjifu7vsuqg3es1.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PUMPR-PUMPRGMZ56T3VNGXO6FCP7ZJQ14OUG3BIKXXREYQBSF",
    "assetId": "SOLANA.PUMPR-PUMPRGMZ56T3VNGXO6FCP7ZJQ14OUG3BIKXXREYQBSF",
    "assetAddress": "PumPRGmZ56t3Vngxo6fCP7ZJQ14oUg3biKxXrEyQBSf",
    "assetName": "PUMPR",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pumpr-pumprgmz56t3vngxo6fcp7zjq14oug3bikxxreyqbsf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$HONEY-HONYEYAATPGKUGQPAYL914P6VAQBQZPRBKGMETZVW4IN",
    "assetId": "SOLANA.$HONEY-HONYEYAATPGKUGQPAYL914P6VAQBQZPRBKGMETZVW4IN",
    "assetAddress": "HonyeYAaTPgKUgQpayL914P6VAqbQZPrbkGMETZvW4iN",
    "assetName": "$HONEY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.honey-honyeyaatpgkugqpayl914p6vaqbqzprbkgmetzvw4in.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BOFB-45WDSJPSQZCK9MKQMQ5NH7BECEQQUJMJCVDUWYCIP5EQ",
    "assetId": "SOLANA.BOFB-45WDSJPSQZCK9MKQMQ5NH7BECEQQUJMJCVDUWYCIP5EQ",
    "assetAddress": "45wdSjpSqZCk9mkqmq5Nh7beCEqqUJMJcVduwYCip5eq",
    "assetName": "BOFB",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bofb-45wdsjpsqzck9mkqmq5nh7beceqqujmjcvduwycip5eq.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BIRDS-9M9FMQJ2S7IUXZHFMSRDES1UCATU3JYZXK3ZXFW3KPEW",
    "assetId": "SOLANA.BIRDS-9M9FMQJ2S7IUXZHFMSRDES1UCATU3JYZXK3ZXFW3KPEW",
    "assetAddress": "9m9fmqJ2s7iUXZhFMsrdes1UcAtu3JyZXK3ZXfW3KPEw",
    "assetName": "BIRDS",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.birds-9m9fmqj2s7iuxzhfmsrdes1ucatu3jyzxk3zxfw3kpew.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CLOP-4CWVHQTWIK7R9AAUSU4CETK1JTLWPYMOPWCPTSSIHCDL",
    "assetId": "SOLANA.CLOP-4CWVHQTWIK7R9AAUSU4CETK1JTLWPYMOPWCPTSSIHCDL",
    "assetAddress": "4cwVHQtwiK7r9aAUsu4cetk1JtLWPymopWcpTSsihCdL",
    "assetName": "clOP",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.clop-4cwvhqtwik7r9aausu4cetk1jtlwpymopwcptssihcdl.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.D2X-GYUP7CHTXSRB6ERAPIFBXFVUTTZ94X3ZQO3JDWOFBTGY",
    "assetId": "SOLANA.D2X-GYUP7CHTXSRB6ERAPIFBXFVUTTZ94X3ZQO3JDWOFBTGY",
    "assetAddress": "GyuP7chtXSRB6erApifBxFvuTtz94x3zQo3JdWofBTgy",
    "assetName": "D2X",
    "decimals": 3,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.d2x-gyup7chtxsrb6erapifbxfvuttz94x3zqo3jdwofbtgy.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WHUSD_V1-BYBPSTBOZHSMKNFXYG47GDHVPKRNEKX31CSCSHBRZUHX",
    "assetId": "SOLANA.WHUSD_V1-BYBPSTBOZHSMKNFXYG47GDHVPKRNEKX31CSCSHBRZUHX",
    "assetAddress": "BybpSTBoZHsmKnfxYG47GDhVPKrnEKX31CScShbrzUhX",
    "assetName": "wHUSD_v1",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.whusd_v1-bybpstbozhsmknfxyg47gdhvpkrnekx31cscshbrzuhx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.JIN-GGZJWHQWTPV2VHQVJNX5UEWQZNKFQSNCDEWUDGQK4FVH",
    "assetId": "SOLANA.JIN-GGZJWHQWTPV2VHQVJNX5UEWQZNKFQSNCDEWUDGQK4FVH",
    "assetAddress": "GgzJWhQWTpv2VHqvJnx5uEWQzNkfQSncDEWUdgQk4fvh",
    "assetName": "JIN",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.jin-ggzjwhqwtpv2vhqvjnx5uewqznkfqsncdewudgqk4fvh.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HAMWTR-HSWR3TZUXQKQ8DDZ9YXR4BX65ZNM6MNDWGURW3X5FJQI",
    "assetId": "SOLANA.HAMWTR-HSWR3TZUXQKQ8DDZ9YXR4BX65ZNM6MNDWGURW3X5FJQI",
    "assetAddress": "HSwR3tZuxQKq8ddz9YxR4BX65Znm6MNdWGURw3x5FJQi",
    "assetName": "HAMWTR",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hamwtr-hswr3tzuxqkq8ddz9yxr4bx65znm6mndwgurw3x5fjqi.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.VIBEZ-FYNUYGBBRY5LATPEKH8Y73IZJTUNT2TD2J3SGCK7E9JU",
    "assetId": "SOLANA.VIBEZ-FYNUYGBBRY5LATPEKH8Y73IZJTUNT2TD2J3SGCK7E9JU",
    "assetAddress": "FyNuYGBBry5LAtPEkh8Y73izjTUNT2td2J3sGCK7E9Ju",
    "assetName": "VIBEZ",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.vibez-fynuygbbry5latpekh8y73izjtunt2td2j3sgck7e9ju.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.COBAN-7UDMMYXH6CUWVY6QQVCD9B429WDVN2J71R5BDXHKQADY",
    "assetId": "SOLANA.COBAN-7UDMMYXH6CUWVY6QQVCD9B429WDVN2J71R5BDXHKQADY",
    "assetAddress": "7udMmYXh6cuWVY6qQVCd9b429wDVn2J71r5BdxHkQADY",
    "assetName": "COBAN",
    "decimals": 3,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.coban-7udmmyxh6cuwvy6qqvcd9b429wdvn2j71r5bdxhkqady.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SMOLE-9TTYEZ3XIRUYJ6CQAR495HBBKJU6SUWDV6AMQ9MVBYYS",
    "assetId": "SOLANA.SMOLE-9TTYEZ3XIRUYJ6CQAR495HBBKJU6SUWDV6AMQ9MVBYYS",
    "assetAddress": "9Ttyez3xiruyj6cqaR495hbBkJU6SUWdV6AmQ9MvbyyS",
    "assetName": "smole",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.smole-9ttyez3xiruyj6cqar495hbbkju6suwdv6amq9mvbyys.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.XLFNTY-XLFNTYY76B8TIIX3HA51JYVC1KMSFV4SPDR7SZTZSRU",
    "assetId": "SOLANA.XLFNTY-XLFNTYY76B8TIIX3HA51JYVC1KMSFV4SPDR7SZTZSRU",
    "assetAddress": "xLfNTYy76B8Tiix3hA51Jyvc1kMSFV4sPdR7szTZsRu",
    "assetName": "xLFNTY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.xlfnty-xlfntyy76b8tiix3ha51jyvc1kmsfv4spdr7sztzsru.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PIIN-PIINKRJEIUVWHNXZSGNCV2AVGSBC4GWHQ9ECTKAGCTW",
    "assetId": "SOLANA.PIIN-PIINKRJEIUVWHNXZSGNCV2AVGSBC4GWHQ9ECTKAGCTW",
    "assetAddress": "piinKrjeiUVwhnXzsgNCV2aVGsBc4GwHQ9eCtkAgcTw",
    "assetName": "piin",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.piin-piinkrjeiuvwhnxzsgncv2avgsbc4gwhq9ectkagctw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$POPO-96QONKZLTKH7GECF6DKMRXBQXW1WQH378UF9PNTEJGAW",
    "assetId": "SOLANA.$POPO-96QONKZLTKH7GECF6DKMRXBQXW1WQH378UF9PNTEJGAW",
    "assetAddress": "96QoNkZLTKH7Gecf6dKMRXBQXW1wqh378uF9pnTejgAw",
    "assetName": "$POPO",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.popo-96qonkzltkh7gecf6dkmrxbqxw1wqh378uf9pntejgaw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PRM-PRMMGF5GJCSDNECWZE2HWB5DSDSFNGXTETZ95C4VKXX",
    "assetId": "SOLANA.PRM-PRMMGF5GJCSDNECWZE2HWB5DSDSFNGXTETZ95C4VKXX",
    "assetAddress": "prmmgF5GJCSDNEcwZe2HWb5DsDsFngxTetZ95C4VKxX",
    "assetName": "PRM",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.prm-prmmgf5gjcsdnecwze2hwb5dsdsfngxtetz95c4vkxx.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WHEX_V1-ELSNGFD5XNSDYFFSGYQP7N89FEBDQXN4NPURLW4PPPLV",
    "assetId": "SOLANA.WHEX_V1-ELSNGFD5XNSDYFFSGYQP7N89FEBDQXN4NPURLW4PPPLV",
    "assetAddress": "ELSnGFd5XnSdYFFSgYQp7n89FEbDqxN4npuRLW4PPPLv",
    "assetName": "wHEX_v1",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.whex_v1-elsngfd5xnsdyffsgyqp7n89febdqxn4npurlw4ppplv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GENIE-3JJT8QHBQNOYFSQYHWF8ZSTJWE2CYVMURZGZJD5JPUMP",
    "assetId": "SOLANA.GENIE-3JJT8QHBQNOYFSQYHWF8ZSTJWE2CYVMURZGZJD5JPUMP",
    "assetAddress": "3Jjt8QhbqNoYfSQYHWf8ZsTJwE2CyvmUrzgzJD5Jpump",
    "assetName": "Genie",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.genie-3jjt8qhbqnoyfsqyhwf8zstjwe2cyvmurzgzjd5jpump.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.UNVAXSPERM-9IQBKYLKTQRXVB6ARGVQYDT1LYGGCTTVE3PTDPWHDPZE",
    "assetId": "SOLANA.UNVAXSPERM-9IQBKYLKTQRXVB6ARGVQYDT1LYGGCTTVE3PTDPWHDPZE",
    "assetAddress": "9iQBkyLKtqRXvb6ARGvQydt1LYgGcTtVe3PTDpWhdPze",
    "assetName": "UNVAXSPERM",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.unvaxsperm-9iqbkylktqrxvb6argvqydt1lyggcttve3ptdpwhdpze.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FUM-EZF2SPJRE26E8IYXACRMEEFRGVBKQQNGV9UPGG9ENTQZ",
    "assetId": "SOLANA.FUM-EZF2SPJRE26E8IYXACRMEEFRGVBKQQNGV9UPGG9ENTQZ",
    "assetAddress": "EZF2sPJRe26e8iyXaCrmEefrGVBkqqNGv9UPGG9EnTQz",
    "assetName": "FUM",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fum-ezf2spjre26e8iyxacrmeefrgvbkqqngv9upgg9entqz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$FLY-4H4LVS6NSVJZ87UBWRYYTEPPTM1II5PTRN9A6LD2KZJW",
    "assetId": "SOLANA.$FLY-4H4LVS6NSVJZ87UBWRYYTEPPTM1II5PTRN9A6LD2KZJW",
    "assetAddress": "4h4LvS6NsVjZ87uBwrYyTeppTm1ii5PtRN9A6Ld2kZjw",
    "assetName": "$FLY",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fly-4h4lvs6nsvjz87ubwryytepptm1ii5ptrn9a6ld2kzjw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ELSA-G7DG4UU9ZTULPE44UM9JJLSSSDZFDIVY8ALHZQUGJPSW",
    "assetId": "SOLANA.ELSA-G7DG4UU9ZTULPE44UM9JJLSSSDZFDIVY8ALHZQUGJPSW",
    "assetAddress": "G7Dg4UU9zTULPe44Um9JjLSsSDZFDiVy8aLhzQuGjPsW",
    "assetName": "ELSA",
    "decimals": 1,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.elsa-g7dg4uu9ztulpe44um9jjlsssdzfdivy8alhzqugjpsw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BUNI-7Y1TRDZE1CEECGBVGDNB9DVIMYDQ7UU2FKHNPDLYA7AE",
    "assetId": "SOLANA.BUNI-7Y1TRDZE1CEECGBVGDNB9DVIMYDQ7UU2FKHNPDLYA7AE",
    "assetAddress": "7y1TrdzE1cEeCgBvgdNB9DViMYdQ7UU2FKhnPDLYa7ae",
    "assetName": "buni",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.buni-7y1trdze1ceecgbvgdnb9dvimydq7uu2fkhnpdlya7ae.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SUSDT-8-T8KDT8HDZNHBGX5SJPEUXEPNBDB1TZOCA7VTC5JJSMW",
    "assetId": "SOLANA.SUSDT-8-T8KDT8HDZNHBGX5SJPEUXEPNBDB1TZOCA7VTC5JJSMW",
    "assetAddress": "8",
    "assetName": "sUSDT",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.susdt-8-t8kdt8hdznhbgx5sjpeuxepnbdb1tzoca7vtc5jjsmw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FATALITY-DXCOKQ7ILPUX398FNHEWQN6DJFGOBZFUPIR5O8HRVHAB",
    "assetId": "SOLANA.FATALITY-DXCOKQ7ILPUX398FNHEWQN6DJFGOBZFUPIR5O8HRVHAB",
    "assetAddress": "DXCoKQ7iLpux398fNHewQn6djfGobzFuPiR5o8hrVHAb",
    "assetName": "FATALITY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fatality-dxcokq7ilpux398fnhewqn6djfgobzfupir5o8hrvhab.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.MADX-CJ2NAXIDDVVY79PLBDVQK3F3BJOHP7HVZV4KLL1VGTV3",
    "assetId": "SOLANA.MADX-CJ2NAXIDDVVY79PLBDVQK3F3BJOHP7HVZV4KLL1VGTV3",
    "assetAddress": "Cj2NAxiDDVvY79PLBdVQK3F3bjoHp7hvZv4kLL1vgtV3",
    "assetName": "MADx",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.madx-cj2naxiddvvy79plbdvqk3f3bjohp7hvzv4kll1vgtv3.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WHAPI-6VNKQGZ9HK7ZRSHTFDG5ANKFKWZUCOJZWAKZXSH3BNUM",
    "assetId": "SOLANA.WHAPI-6VNKQGZ9HK7ZRSHTFDG5ANKFKWZUCOJZWAKZXSH3BNUM",
    "assetAddress": "6VNKqgz9hk7zRShTFdg5AnkfKwZUcojzwAkzxSH3bnUm",
    "assetName": "wHAPI",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.whapi-6vnkqgz9hk7zrshtfdg5ankfkwzucojzwakzxsh3bnum.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HORNT-7NBJRQMK3FWJDMIA93EPESHQQJIEDQQVUWNGMIWNWMJ5",
    "assetId": "SOLANA.HORNT-7NBJRQMK3FWJDMIA93EPESHQQJIEDQQVUWNGMIWNWMJ5",
    "assetAddress": "7nbJrQMK3FwJDMia93EpESHqqJiEdQqvuwnGMiwnWMJ5",
    "assetName": "HORNT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.hornt-7nbjrqmk3fwjdmia93epeshqqjiedqqvuwngmiwnwmj5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.HUHCAT-6RA49AQZTBEURJB1UQGAJ1TJVBQAJGP5UM7GSTYM8TWM",
    "assetId": "SOLANA.HUHCAT-6RA49AQZTBEURJB1UQGAJ1TJVBQAJGP5UM7GSTYM8TWM",
    "assetAddress": "6Ra49aqZTbEurJB1UQgAj1TjvbqajGP5um7gsTym8tWm",
    "assetName": "HUHCAT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.huhcat-6ra49aqztbeurjb1uqgaj1tjvbqajgp5um7gstym8twm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BRAH-6CBM8B8UOCUZWJS9N32BNY4Z6LJGJ4ETBH2X48JWVHZF",
    "assetId": "SOLANA.BRAH-6CBM8B8UOCUZWJS9N32BNY4Z6LJGJ4ETBH2X48JWVHZF",
    "assetAddress": "6CBm8B8uoCUZWjs9n32BNy4z6LjGj4etBH2X48JWVhzf",
    "assetName": "BRAH",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.brah-6cbm8b8uocuzwjs9n32bny4z6ljgj4etbh2x48jwvhzf.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WOOSH-29377UZ8EU6CEXMNMXJJGE3GDAYCYCBRUBNLIUVA75AA",
    "assetId": "SOLANA.WOOSH-29377UZ8EU6CEXMNMXJJGE3GDAYCYCBRUBNLIUVA75AA",
    "assetAddress": "29377Uz8eu6CeXmnMXJJge3gdaycyCbrubnLiUva75AA",
    "assetName": "WOOSH",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.woosh-29377uz8eu6cexmnmxjjge3gdaycycbrubnliuva75aa.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WOJAK-4MPA8WBYYTKIUFXDSDCKEOMPDN5XK1XW9S9LSLMJK5Y4",
    "assetId": "SOLANA.WOJAK-4MPA8WBYYTKIUFXDSDCKEOMPDN5XK1XW9S9LSLMJK5Y4",
    "assetAddress": "4MPA8WbyYtKiufXDSdckEoMPdN5XK1Xw9S9LSLMjK5Y4",
    "assetName": "Wojak",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wojak-4mpa8wbyytkiufxdsdckeompdn5xk1xw9s9lslmjk5y4.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.CDC-ASNR8RXZ3WK8QINXMVEVXXTFGCXCHRD8OIAANIHHJVLQ",
    "assetId": "SOLANA.CDC-ASNR8RXZ3WK8QINXMVEVXXTFGCXCHRD8OIAANIHHJVLQ",
    "assetAddress": "ASNR8RxZ3wK8QiNxmVEvxXtFGCXCHRD8oiAaNihHJvLq",
    "assetName": "CDC",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.cdc-asnr8rxz3wk8qinxmvevxxtfgcxchrd8oiaanihhjvlq.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BITXBIT-DK6PWMYUZ4NMJSM9AWNCTMKRAJQYRTFMJMJ3QAUX2UH5",
    "assetId": "SOLANA.BITXBIT-DK6PWMYUZ4NMJSM9AWNCTMKRAJQYRTFMJMJ3QAUX2UH5",
    "assetAddress": "DK6PWMyuZ4NMjsm9AWNCTMKrajQYrtfMjMJ3QauX2UH5",
    "assetName": "BITXBIT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.bitxbit-dk6pwmyuz4nmjsm9awnctmkrajqyrtfmjmj3qaux2uh5.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.FAB-EDAHKBJ5NF9SRM7XN7EWUW8C9XEUMS8P7CNOQ57SYE96",
    "assetId": "SOLANA.FAB-EDAHKBJ5NF9SRM7XN7EWUW8C9XEUMS8P7CNOQ57SYE96",
    "assetAddress": "EdAhkbj5nF9sRM7XN7ewuW8C9XEUMs8P7cnoQ57SYE96",
    "assetName": "FAB",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.fab-edahkbj5nf9srm7xn7ewuw8c9xeums8p7cnoq57sye96.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ABUSDT-E77CPQ4VNCGMCAXX16LHFFZNBEBB2U7AR7LBMZNFCGWL",
    "assetId": "SOLANA.ABUSDT-E77CPQ4VNCGMCAXX16LHFFZNBEBB2U7AR7LBMZNFCGWL",
    "assetAddress": "E77cpQ4VncGmcAXX16LHFFzNBEBb2U7Ar7LBmZNfCgwL",
    "assetName": "abUSDT",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.abusdt-e77cpq4vncgmcaxx16lhffznbebb2u7ar7lbmznfcgwl.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.BURD-QIKHHHG9TA3JG7WODFBSYUCAE14HX9HPVDZ1ZVP3ZUW",
    "assetId": "SOLANA.BURD-QIKHHHG9TA3JG7WODFBSYUCAE14HX9HPVDZ1ZVP3ZUW",
    "assetAddress": "Qikhhhg9Ta3Jg7WoDFbSYuCAE14hx9hPvdz1zVp3zUw",
    "assetName": "BURD",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.burd-qikhhhg9ta3jg7wodfbsyucae14hx9hpvdz1zvp3zuw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.STAN-CQSZJZWW5H1OYWRP6QHFUKYYWYOVBSIVDKNAXNFB1TJC",
    "assetId": "SOLANA.STAN-CQSZJZWW5H1OYWRP6QHFUKYYWYOVBSIVDKNAXNFB1TJC",
    "assetAddress": "CQSzJzwW5H1oyWrp6QhfUKYYwyovbSiVDKnAxNfb1tJC",
    "assetName": "STAN",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.stan-cqszjzww5h1oywrp6qhfukyywyovbsivdknaxnfb1tjc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$PTRUMP-GK2KRRWNMBU4DN9JHC1DKS8G5X9NQI4ZE5JMVK6BDGED",
    "assetId": "SOLANA.$PTRUMP-GK2KRRWNMBU4DN9JHC1DKS8G5X9NQI4ZE5JMVK6BDGED",
    "assetAddress": "Gk2kRrwNMBU4Dn9JhC1Dks8G5X9nqi4ZE5jMvK6bdgEd",
    "assetName": "$PTRUMP",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ptrump-gk2krrwnmbu4dn9jhc1dks8g5x9nqi4ze5jmvk6bdged.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ZSOL-SO111DZVTTNPDQ81EBEYKZMI4SKHU9YEKQB8XMMPQZA",
    "assetId": "SOLANA.ZSOL-SO111DZVTTNPDQ81EBEYKZMI4SKHU9YEKQB8XMMPQZA",
    "assetAddress": "So111DzVTTNpDq81EbeyKZMi4SkhU9yekqB8xmMpqzA",
    "assetName": "zSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.zsol-so111dzvttnpdq81ebeykzmi4skhu9yekqb8xmmpqza.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ORYS-FA96VXTHSUWGBI8DTJXFJSUJAP6RM18AWW5EZSTN4SXW",
    "assetId": "SOLANA.ORYS-FA96VXTHSUWGBI8DTJXFJSUJAP6RM18AWW5EZSTN4SXW",
    "assetAddress": "Fa96VxtHsUwGbi8DtjXFjsUjAP6rm18AWw5ezsTN4sxw",
    "assetName": "Orys",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.orys-fa96vxthsuwgbi8dtjxfjsujap6rm18aww5ezstn4sxw.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SUNSBR-SUNSBR8HRLZY13HCHDRT3HJJZVRPKVFXF3QAAJYJXZ2",
    "assetId": "SOLANA.SUNSBR-SUNSBR8HRLZY13HCHDRT3HJJZVRPKVFXF3QAAJYJXZ2",
    "assetAddress": "sunSBR8hRLZy13HCHdrT3hjJZVRpkVfXF3QaaJYjxZ2",
    "assetName": "sunSBR",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sunsbr-sunsbr8hrlzy13hchdrt3hjjzvrpkvfxf3qaajyjxz2.svg",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WIPE-9AE76ZQD3CGZR9GVF5THC2NN3ACF7RQQNRLQXNZGCRE6",
    "assetId": "SOLANA.WIPE-9AE76ZQD3CGZR9GVF5THC2NN3ACF7RQQNRLQXNZGCRE6",
    "assetAddress": "9ae76zqD3cgzR9gvf5Thc2NN3ACF7rqqnrLqxNzgcre6",
    "assetName": "WIPE",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wipe-9ae76zqd3cgzr9gvf5thc2nn3acf7rqqnrlqxnzgcre6.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SBTC-8-SBTCB6PWQEDO6ZGI9WVRMLCSKSN6JIR1RMUQVLTGSRV",
    "assetId": "SOLANA.SBTC-8-SBTCB6PWQEDO6ZGI9WVRMLCSKSN6JIR1RMUQVLTGSRV",
    "assetAddress": "8",
    "assetName": "sBTC",
    "decimals": 8,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sbtc-8-sbtcb6pwqedo6zgi9wvrmlcsksn6jir1rmuqvltgsrv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.DRAKO-DRAKDUQWNTS89CDTUDGHMZZEKN6REBLYQRK8RZVHU53",
    "assetId": "SOLANA.DRAKO-DRAKDUQWNTS89CDTUDGHMZZEKN6REBLYQRK8RZVHU53",
    "assetAddress": "drakduQWnTS89CdTUdgHmZzEkN6reBLYqrk8rzVhU53",
    "assetName": "DRAKO",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.drako-drakduqwnts89cdtudghmzzekn6reblyqrk8rzvhu53.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$SNOOPY-4LP5JKSYKC5PSAOODWCZNDCSK2GGSMCZVHKOO7HCPDCV",
    "assetId": "SOLANA.$SNOOPY-4LP5JKSYKC5PSAOODWCZNDCSK2GGSMCZVHKOO7HCPDCV",
    "assetAddress": "4LP5JKsyKC5pSAoodwcZnDCSK2ggsMcZvHKoo7HCPDCV",
    "assetName": "$SNOOPY",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.snoopy-4lp5jksykc5psaoodwczndcsk2ggsmczvhkoo7hcpdcv.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.GOBI-MARCOPAG4DV4QIT3ZPGPFM4QT4KKNBKVASM2RPGNF72",
    "assetId": "SOLANA.GOBI-MARCOPAG4DV4QIT3ZPGPFM4QT4KKNBKVASM2RPGNF72",
    "assetAddress": "MarcoPaG4dV4qit3ZPGPFm4qt4KKNBKvAsm2rPGNF72",
    "assetName": "GOBI",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.gobi-marcopag4dv4qit3zpgpfm4qt4kknbkvasm2rpgnf72.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WYAC-BEGBSVSKJSXREICE1XMWWQ8ARNWIT7XDQQXSWYGAY9XP",
    "assetId": "SOLANA.WYAC-BEGBSVSKJSXREICE1XMWWQ8ARNWIT7XDQQXSWYGAY9XP",
    "assetAddress": "BEgBsVSKJSxreiCE1XmWWq8arnwit7xDqQXSWYgay9xP",
    "assetName": "WYAC",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wyac-begbsvskjsxreice1xmwwq8arnwit7xdqqxswygay9xp.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.APUSDT-DNHZKUAXHXYVPXZ7LNNHTSS8SQGDAFD1ZYS1FB7LKWUZ",
    "assetId": "SOLANA.APUSDT-DNHZKUAXHXYVPXZ7LNNHTSS8SQGDAFD1ZYS1FB7LKWUZ",
    "assetAddress": "DNhZkUaxHXYvpxZ7LNnHtss8sQgdAfd1ZYS1fB7LKWUZ",
    "assetName": "apUSDT",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.apusdt-dnhzkuaxhxyvpxz7lnnhtss8sqgdafd1zys1fb7lkwuz.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.AFSEX-FBUY1NUBUTCFOIF7TAR6WATJVJFAPYUVWXRGMUWVKNYS",
    "assetId": "SOLANA.AFSEX-FBUY1NUBUTCFOIF7TAR6WATJVJFAPYUVWXRGMUWVKNYS",
    "assetAddress": "FbUy1NubUtCfoif7TAr6wAtJVJFapYUVWxRGmUwvKNyS",
    "assetName": "AFSeX",
    "decimals": 2,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.afsex-fbuy1nubutcfoif7tar6watjvjfapyuvwxrgmuwvknys.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.POLYGONE-J9NSNGNI1PAVF4IJP4R9QBAD1YEZKZZUQ1VVGCDQT18J",
    "assetId": "SOLANA.POLYGONE-J9NSNGNI1PAVF4IJP4R9QBAD1YEZKZZUQ1VVGCDQT18J",
    "assetAddress": "J9nsngni1Pavf4ijP4R9QBaD1yEzKzzUQ1vVgcDQT18J",
    "assetName": "POLYGONE",
    "decimals": 5,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.polygone-j9nsngni1pavf4ijp4r9qbad1yezkzzuq1vvgcdqt18j.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PRE-G6MC7TIVSYM3ZRMMXEKF3HYSC9C2HIKNGK7IDOHQHTUK",
    "assetId": "SOLANA.PRE-G6MC7TIVSYM3ZRMMXEKF3HYSC9C2HIKNGK7IDOHQHTUK",
    "assetAddress": "G6mc7tiVSym3zrmmxekF3HYSc9c2hiKnGk7idoHqHTUK",
    "assetName": "pre",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pre-g6mc7tivsym3zrmmxekf3hysc9c2hikngk7idohqhtuk.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PUSSYINBIO-E9PBR4XJSCYLPQFZ4YM4GUKCZQZ7MHPB6DK4SFSQTNJD",
    "assetId": "SOLANA.PUSSYINBIO-E9PBR4XJSCYLPQFZ4YM4GUKCZQZ7MHPB6DK4SFSQTNJD",
    "assetAddress": "E9pBR4xjscYLPqFZ4YM4gUkczqz7MHpB6dk4sfSQTnJD",
    "assetName": "pussyinbio",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pussyinbio-e9pbr4xjscylpqfz4ym4gukczqz7mhpb6dk4sfsqtnjd.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ABETH-EYRNRBE5UJD3HQG5PZD9MBECN9YAQRQC8PRWGTALOYC",
    "assetId": "SOLANA.ABETH-EYRNRBE5UJD3HQG5PZD9MBECN9YAQRQC8PRWGTALOYC",
    "assetAddress": "EyrnrbE5ujd3HQG5PZd9MbECN9yaQrqc8pRwGtaLoyC",
    "assetName": "abETH",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.abeth-eyrnrbe5ujd3hqg5pzd9mbecn9yaqrqc8prwgtaloyc.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.ACUSD-EWXNF8G9UFMSJVCZFTPL9HX5MCKOQFOJI6XNWZKF1J8E",
    "assetId": "SOLANA.ACUSD-EWXNF8G9UFMSJVCZFTPL9HX5MCKOQFOJI6XNWZKF1J8E",
    "assetAddress": "EwxNF8g9UfmsJVcZFTpL9Hx5MCkoQFoJi6XNWzKf1j8e",
    "assetName": "acUSD",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.acusd-ewxnf8g9ufmsjvczftpl9hx5mckoqfoji6xnwzkf1j8e.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.COMPASSSOL-COMP4SSDZXCLEU2MNLUGNNFC4CMLPMNG8QWHPVZAMU1H",
    "assetId": "SOLANA.COMPASSSOL-COMP4SSDZXCLEU2MNLUGNNFC4CMLPMNG8QWHPVZAMU1H",
    "assetAddress": "Comp4ssDzXcLeu2MnLuGNNFC4cmLPMng8qWHPvzAMU1h",
    "assetName": "compassSOL",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.compasssol-comp4ssdzxcleu2mnlugnnfc4cmlpmng8qwhpvzamu1h.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.WUST_V1-CXLBJMMCWKC17GFJTBOS6RQCO1YPEH6EDBB82KBY4MRM",
    "assetId": "SOLANA.WUST_V1-CXLBJMMCWKC17GFJTBOS6RQCO1YPEH6EDBB82KBY4MRM",
    "assetAddress": "CXLBjMMcwkc17GfJtBos6rQCo1ypeH6eDbB82Kby4MRm",
    "assetName": "wUST_v1",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.wust_v1-cxlbjmmcwkc17gfjtbos6rqco1ypeh6edbb82kby4mrm.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.UPS-EWJN2GQUGXXZYMOACIWUABTORHCZTA5LQBUKKXV1VIH7",
    "assetId": "SOLANA.UPS-EWJN2GQUGXXZYMOACIWUABTORHCZTA5LQBUKKXV1VIH7",
    "assetAddress": "EwJN2GqUGXXzYmoAciwuABtorHczTA5LqbukKXV1viH7",
    "assetName": "UPS",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.ups-ewjn2gqugxxzymoaciwuabtorhczta5lqbukkxv1vih7.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.SPEERO-C7K4TOT6FNNNWHWPQW9H277QPCP56VHAEEXUBRHDYCO9",
    "assetId": "SOLANA.SPEERO-C7K4TOT6FNNNWHWPQW9H277QPCP56VHAEEXUBRHDYCO9",
    "assetAddress": "C7K4Tot6fnnNwhWpqw9H277QPcP56vHAEeXubRHDyCo9",
    "assetName": "Speero",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.speero-c7k4tot6fnnnwhwpqw9h277qpcp56vhaeexubrhdyco9.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.PNKZ-GE9R56XTEXI2DF5LPMVJ5BEDCK9WNEEHVM5T5XCUXG9C",
    "assetId": "SOLANA.PNKZ-GE9R56XTEXI2DF5LPMVJ5BEDCK9WNEEHVM5T5XCUXG9C",
    "assetAddress": "GE9R56XteXi2DF5LPmVJ5BEDCk9wnEEhvM5t5xcuXG9c",
    "assetName": "PNKZ",
    "decimals": 9,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.pnkz-ge9r56xtexi2df5lpmvj5bedck9wneehvm5t5xcuxg9c.png",
    "integrations": [
      "rango"
    ]
  },
  {
    "asset": "SOLANA.$COFFEE-85YF3Z6ZWGLGQLFXWW7KOHGW4SHPX4IKI2P1CI7K7GQZ",
    "assetId": "SOLANA.$COFFEE-85YF3Z6ZWGLGQLFXWW7KOHGW4SHPX4IKI2P1CI7K7GQZ",
    "assetAddress": "85Yf3z6zwgLGQLfxww7KoHGw4sHpx4iki2P1Ci7k7gqZ",
    "assetName": "$COFFEE",
    "decimals": 6,
    "chain": "SOLANA",
    "chainName": "SOLANA",
    "logoURI": "https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.coffee-85yf3z6zwglgqlfxww7kohgw4shpx4iki2p1ci7k7gqz.png",
    "integrations": [
      "rango"
    ]
  }
];