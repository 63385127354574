import { useRuntimeConfig } from 'nuxt/app'
import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia'
import { useRangoRouteStore } from './rangoRouteStore'
import { useSwapkitRouteStore } from './swapkitRouteStore'
import { useFiatPriceStore } from './fiatPriceStore'
import type { ChainListItem, RouteEndPoint } from '~/types'
import type { AssetSelectorData, LpPoolSelectorData } from '~/types/route'
import { groupReduce } from '~/utils/route'
import { manualRoutes } from '~/config/manualRoutes'
import { getAssetChain, getAssetName, nativeAsset, nodeRequest } from '~/utils/utils'
import { getLogoForChain } from '~/utils/swapkit'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'
import { getSynthSaveAssetId } from '~/utils/main'
import { toPrice } from '~/utils/numbers'

type RouteState = {
  routes: Array<RouteEndPoint>
  lpPools: Array<LpPool>
  chainMap: {}
}
const initialState: RouteState = {
  routes: [],
  lpPools: [],
  chainMap: {},
}
export const useRouteStore = defineStore('routeStore', {
  state: () => initialState,
  getters: {
    getRoutes: (state) => state.routes,
    getChains: (state): ChainListItem[] => {
      const frequencyMap = state.routes.reduce((acc: { [key: string]: ChainListItem }, item) => {
        const key = item.chain
        if (!acc[key]) {
          acc[key] = {
            chainName: item.chainName,
            chain: item.chain,
            count: 0,
            logo: getLogoForChain(item.chain),
          }
        }
        acc[key].count++
        return acc
      }, {})

      return Object.values(frequencyMap).sort((a, b) => b.count - a.count)
    },
    getAssetSelectorData: (state): AssetSelectorData[] => {
      const { wallets } = storeToRefs(useSwapkitWalletStore())
      const { fiatPrices } = storeToRefs(useFiatPriceStore())
      const runtimeConfig = useRuntimeConfig()

      const routesWithBalance = state.routes.flatMap((route) => {
        if (runtimeConfig.public.ENABLE_SYNTHS !== 'true' && route.assetId.includes('/')) {
          return []
        }
        const assetValue = wallets.value[route.chain]?.balance.find(
          ({ chain, symbol, ticker, address }) =>
            getSynthSaveAssetId({ chain, symbol, ticker, address }) === route.assetId,
        )
        const balance = assetValue?.getValue('number') ?? 0
        const balanceDisplay = toPrice(balance, route.assetId)

        const maybeFiatPrice = fiatPrices.value[route.assetId]

        const fiatPrice =
          !!maybeFiatPrice && maybeFiatPrice.state === 'loaded' ? maybeFiatPrice.data?.usd : 0

        const balanceUsd = fiatPrice * balance
        const balanceUsdDisplay = toPrice(balanceUsd as number, 'USD')

        return { ...route, balance, balanceDisplay, balanceUsd, balanceUsdDisplay }
      })

      return groupReduce(
        routesWithBalance,
        (route) => route.chain,
        (route, assetSelectorData) => {
          return {
            chain: route.chain,
            assets: (assetSelectorData?.assets ?? []).concat(route),
          }
        },
      )
    },
    getCuratedAssetSelectorData: (state): AssetSelectorData[] => {
      const { wallets } = storeToRefs(useSwapkitWalletStore())
      const { fiatPrices } = storeToRefs(useFiatPriceStore())
      const runtimeConfig = useRuntimeConfig()

      const filteredRoutes = state.routes.filter((route) => {
        return manualRoutes.includes(route.assetId)
      })

      const filteredRoutesWithBalance = filteredRoutes.flatMap((route) => {
        if (runtimeConfig.public.ENABLE_SYNTHS !== 'true' && route.assetId.includes('/')) {
          return []
        }
        const assetValue = wallets.value[route.chain]?.balance.find(
          ({ chain, symbol, ticker, address }) =>
            getSynthSaveAssetId({ chain, symbol, ticker, address }) === route.assetId,
        )
        const balance = assetValue?.getValue('number') ?? 0
        const balanceDisplay = toPrice(balance, route.assetId)

        const maybeFiatPrice = fiatPrices.value[route.assetId]

        const fiatPrice =
          !!maybeFiatPrice && maybeFiatPrice.state === 'loaded' ? maybeFiatPrice.data?.usd : 0

        const balanceUsd = fiatPrice * balance
        const balanceUsdDisplay = toPrice(balanceUsd as number, 'USD')

        return { ...route, balance, balanceDisplay, balanceUsd, balanceUsdDisplay }
      })

      return groupReduce(
        filteredRoutesWithBalance,
        (route) => route.chain,
        (route, assetSelectorData) => {
          return {
            chain: route.chain,
            assets: (assetSelectorData?.assets ?? []).concat(route),
          }
        },
      )
    },
    getLpPoolSelectorData: (state): LpPoolSelectorData[] => {
      return groupReduce(
        state.lpPools,
        (pool) => pool.chain,
        (pool, lpPoolSelectorData) => {
          const { asset, assetName, chain, integrations } = pool
          const lpPoolSelectorAsset = {
            asset,
            assetName,
            chain,
            integrations,
          }
          return {
            chain,
            assets: (lpPoolSelectorData?.assets ?? []).concat(
              chain === 'MAYA' ? [] : lpPoolSelectorAsset,
            ),
          }
        },
      )
    },
  },
  actions: {
    isIntegrationEnabled(integration: string): boolean {
      const runtimeConfig = useRuntimeConfig()
      const ENABLED_INTEGRATIONS = runtimeConfig.public.ENABLED_INTEGRATIONS as string[]
      return ENABLED_INTEGRATIONS.includes(integration)
    },
    async initLpPools() {
      // Fetch and add available pools
      const response: MayaPool[] = await nodeRequest('/pools')
      const pools = response.flatMap<LpPool>((pool) => {
        if (pool.status !== 'Available' && pool.status !== 'Staged') {
          return []
        }
        return {
          asset: pool.asset,
          assetName: getAssetName(pool.asset),
          chain: getAssetChain(pool.asset),
          status: pool.status,
          balance_asset: parseInt(pool.balance_asset),
          balance_cacao: parseInt(pool.balance_cacao),
          integrations: ['maya'],
        }
      })

      this.lpPools = pools
    },

    initRoutes() {
      this.routes = []
      if (this.isIntegrationEnabled('swapkit')) {
        const swapkitRouteStore = useSwapkitRouteStore()
        swapkitRouteStore.initRoutes()
        swapkitRouteStore.initSwapkitProviders()

        const { routes: swapkitRoutes } = storeToRefs(swapkitRouteStore)
        this.routes = swapkitRoutes.value.map((route) => ({
          ...route,
          assetName: getAssetName(route.asset),
        }))
      }

      if (this.isIntegrationEnabled('rango')) {
        const rangoRouteStore = useRangoRouteStore()
        const { routes: routesRango } = storeToRefs(rangoRouteStore)

        // Append or merge rango routes
        routesRango.value.forEach((routeRango) => {
          // Find the corresponding route in this.routes
          const existingRoute = this.routes.find((route) => route.asset === routeRango.asset)

          if (existingRoute) {
            // Merge integrations from routeRange into existingRoute
            existingRoute.integrations = [
              ...new Set([...existingRoute.integrations, ...routeRango.integrations]),
            ]
          } else {
            // If the routeRango does not exist in this.routes, add it
            this.routes.push({ ...routeRango, assetName: getAssetName(routeRango.asset) })
          }
        })
      }

      // Sort pools so nativeAsset is 1st and then by order, then the rest of the assets
      const order = [
        'BTC.BTC',
        'ETH.ETH',
        'ETH.USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48',
        'ETH.USDT-0XDAC17F958D2EE523A2206206994597C13D831EC7',
        'DOT.DOT',
        'KUJI.KUJI',
        'THOR.RUNE',
        'DASH.DASH',
        'ETH.FLIP-0x826180541412D574cf1336d22c0C0a287822678A',
        'KUJI.USK',
        'SOLANA.SOL',
      ]
      this.routes = this.routes.sort((a, b) => {
        // Check if either asset is the nativeAsset and prioritize it
        if (a.asset === nativeAsset) return -1
        if (b.asset === nativeAsset) return 1

        // Check if assets are in the order array and get their indices
        const indexA = order.indexOf(a.asset)
        const indexB = order.indexOf(b.asset)

        // Handle assets not in the order array by placing them at the end
        if (indexA === -1 && indexB === -1) return 0 // Both assets not in order, keep their relative order
        if (indexA === -1) return 1 // a is not in order, sort b before a
        if (indexB === -1) return -1 // b is not in order, sort a before b

        // Both assets are in the order array, sort by their order
        return indexA - indexB
      })
    },
    fetchIntegrationStatus(
      integration: 'rango',
      statusId: string,
      query?: any,
    ): Array<Promise<any>> {
      if (!this.isIntegrationEnabled(integration)) {
        throw new Error(`${integration} integration not enabled.`)
      }

      const rangoRouteStore = useRangoRouteStore()

      interface FetchStatusFunctions {
        rango: Function
      }
      const fetchStatusFunctions: FetchStatusFunctions = {
        rango: rangoRouteStore.fetchStatus,
      }

      return fetchStatusFunctions[integration](statusId, query)
    },
    // TODO if this code is not needed, remove it
    // submitIntegrationSwap({ route, fees }: { route: QuoteResponseRoute; fees: StatusFees }) {
    //   const { fiatPrices } = useFiatPriceStore()
    //   const fiatPrice: State<FiatPrice> | undefined = fiatPrices[route.buyAsset]
    //   const fiatPriceOut: number = fiatPrice?.state === stateTypes.Loaded ? fiatPrice.data.usd : 0
    //   const expectedOutput = BigNumber(route.expectedBuyAmount)
    //   const buyAsset = route.buyAsset
    //   const outputPriceUsd =
    //     route.meta.assets?.find((asset) => asset.asset === buyAsset)?.price ?? fiatPriceOut
    //   const expectedOutputUsd = expectedOutput.multipliedBy(outputPriceUsd)

    //   const swapkitRouteStore = useSwapkitRouteStore()

    //   const submitSwapFunctions = {
    //     swapkit: swapkitRouteStore.submitSwap,
    //   }

    //   const submitSwap = submitSwapFunctions.swapkit
    //   return submitSwap({
    //     route,
    //     expectedOutputUsd: expectedOutputUsd.toNumber(),
    //     fees,
    //   })
    // },
  },
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRouteStore, import.meta.hot))
}
