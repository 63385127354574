import * as bip39 from 'bip39'
import { HDKey } from 'micro-ed25519-hdkey'
import {
  AssetValue,
  BigIntArithmetics,
  Chain,
  type EVMChain,
  type ChainWallet as SwapkitWallet,
  type WalletChain as SwapkitWalletChain,
  WalletOption,
} from '@swapkit/helpers'
import {
  AlchemyProvider,
  BrowserProvider,
  type Eip1193Provider,
  HDNodeWallet,
  Wallet,
} from 'ethers'
import { XDEFI_SUPPORTED_CHAINS } from '@swapkit/wallet-xdefi'
import {
  Connection,
  Keypair,
  VersionedTransaction,
  sendAndConfirmTransaction,
} from '@solana/web3.js'
import type { Account } from 'viem'
import { mnemonicToAccount } from 'viem/accounts'
import { type SwapkitClient, swapkitClient } from '~/clients/swapkit'
import type { PhantomSolanaProvider } from '~/types/phantom'
import { RangoChain } from '~/types/rango'
import type { XdefiSolanaProvider } from '~/types/xdefi'
import { isEvmChain, swapkitChains } from '~/utils/swapkit'
import type { KeystoreSolanaProvider } from '~/types/keystore'

export type WalletType = WalletOption
export type WalletChain = SwapkitWalletChain | RangoChain
export type SelectedChains = { [key in WalletChain]?: boolean }
export type WithSelectedChains = { selectedChains: SelectedChains }

/**
 * excludes private props like #arithmetics
 */
type BigIntProps = { [K in keyof BigIntArithmetics]: BigIntArithmetics[K] }
type AssetProps = Pick<
  AssetValue,
  'address' | 'isGasAsset' | 'isSynthetic' | 'symbol' | 'tax' | 'ticker' | 'toUrl'
>
export type SolanaAssetProps = AssetProps & {
  chain: RangoChain.Solana
  type: RangoChain.Solana
  chainId: '900'
}
export type SolanaAsset = BigIntProps & SolanaAssetProps
type BaseWallet = Omit<SwapkitWallet<SwapkitWalletChain>, 'chain' | 'balance' | 'walletType'>
export type SolanaPhantomWallet = BaseWallet & {
  chain: RangoChain.Solana
  balance: SolanaAsset[]
  walletType: WalletOption.PHANTOM
  provider: PhantomSolanaProvider
}
export type SolanaXdefiWallet = BaseWallet & {
  chain: RangoChain.Solana
  balance: SolanaAsset[]
  walletType: WalletOption.XDEFI
  provider: XdefiSolanaProvider
}
export type SolanaKeystoreWallet = BaseWallet & {
  chain: RangoChain.Solana
  balance: SolanaAsset[]
  walletType: WalletOption.KEYSTORE
  provider: KeystoreSolanaProvider
}
export type SolanaWallet = SolanaPhantomWallet | SolanaXdefiWallet | SolanaKeystoreWallet
export type SwapkitWalletAsset = BigIntProps &
  AssetProps &
  Pick<AssetValue, 'chain' | 'type' | 'chainId'>
export type WalletAsset = SwapkitWalletAsset | SolanaAsset
export type SwapkitChainWallet = Omit<SwapkitWallet<SwapkitWalletChain>, 'balance'> & {
  balance: SwapkitWalletAsset[]
}
export type KeystoreEvmWallet = BaseWallet & {
  account: Account
  chain: Chain.Ethereum | Chain.Arbitrum
  balance: SwapkitWalletAsset[]
  provider: { signAndSendTransaction: HDNodeWallet['sendTransaction'] }
  walletType: WalletOption.KEYSTORE
}
export type MetamaskWallet = BaseWallet & {
  chain: EVMChain
  balance: SwapkitWalletAsset[]
  eip1193Provider: Eip1193Provider
  provider: BrowserProvider
  walletType: WalletOption.METAMASK
}
export type XdefiEvmWallet = BaseWallet & {
  chain: EVMChain
  balance: SwapkitWalletAsset[]
  eip1193Provider: Eip1193Provider
  provider: BrowserProvider
  walletType: WalletOption.XDEFI
}
export type ChainWallet =
  | SwapkitChainWallet
  | SolanaWallet
  | KeystoreEvmWallet
  | MetamaskWallet
  | XdefiEvmWallet

export function assertPhantomIsInstalled(
  phantom: unknown,
): asserts phantom is { solana: PhantomSolanaProvider } {
  if (
    !phantom ||
    typeof phantom !== 'object' ||
    !('solana' in phantom) ||
    !phantom.solana ||
    typeof phantom.solana !== 'object' ||
    !('isPhantom' in phantom.solana) ||
    !phantom.solana.isPhantom
  ) {
    throw new Error('Phantom wallet not found')
  }
}
export type PhantomConfig = {
  provider: PhantomSolanaProvider
  onlyIfTrusted?: boolean
}
export const fetchPhantomSolanaAddress = async ({
  provider,
  onlyIfTrusted = true,
}: PhantomConfig) => {
  const connection = await (onlyIfTrusted
    ? provider.connect({ onlyIfTrusted }).catch(() => {
        return provider.connect()
      })
    : provider.connect())
  const address = connection?.publicKey?.toString()
  if (!address) {
    throw new Error('Could not establish connection with Phantom wallet')
  }
  return address
}
export const connectPhantomWallet = async (config: PhantomConfig): Promise<ChainWallet[]> => {
  const address = await fetchPhantomSolanaAddress(config)
  return [
    {
      chain: RangoChain.Solana,
      walletType: WalletOption.PHANTOM,
      balance: [],
      address,
      provider: config.provider,
    },
  ]
}

export const xdefiChains = [...XDEFI_SUPPORTED_CHAINS, RangoChain.Solana] as const
export type XdefiChains = typeof xdefiChains
export type XdefiChain = XdefiChains[number]
export function assertXdefiEvmInstalled(
  xdefi: unknown,
): asserts xdefi is { ethereum: Eip1193Provider } {
  if (
    typeof xdefi !== 'object' ||
    !xdefi ||
    !('ethereum' in xdefi) ||
    typeof xdefi.ethereum !== 'object' ||
    !xdefi.ethereum
    /**
     * skipping check because isXDEFI is always false
     * !('isXDEFI' in xdefi.ethereum) ||
     * !xdefi.ethereum.isXDEFI
     */
  ) {
    throw new Error('XDEFI wallet for Ethereum not found')
  }
}
export type XdefiEvmConfig = {
  evmProvider: Eip1193Provider
  /**
   * at least one evm chain will be selected
   */
  selectedChains: WithSelectedChains['selectedChains']
}
export function assertXdefiSolanaInstalled(
  xdefi: unknown,
): asserts xdefi is { solana: XdefiSolanaProvider } {
  if (
    typeof xdefi !== 'object' ||
    !xdefi ||
    !('solana' in xdefi) ||
    typeof xdefi.solana !== 'object' ||
    !xdefi.solana ||
    !('isXDEFI' in xdefi.solana) ||
    !xdefi.solana.isXDEFI
  ) {
    throw new Error('XDEFI wallet for Solana not found')
  }
}
export type XdefiSolanaConfig = {
  solProvider: XdefiSolanaProvider
  selectedChains: { [RangoChain.Solana]: true } & { [K in SwapkitWalletChain]?: boolean }
}
export const fetchXdefiSolanaAddress = async ({
  provider,
}: {
  provider: XdefiSolanaConfig['solProvider']
}) => {
  const connection = await provider.connect()
  const address = connection.publicKey.toString()
  if (!address) {
    throw new Error('Could not establish connection to XDEFI wallet')
  }
  return address
}
export type XdefiConfig = XdefiSolanaConfig | XdefiEvmConfig | WithSelectedChains
export const connectXdefiWallet = (config: XdefiConfig): Promise<ChainWallet[]> => {
  const chains = xdefiChains.filter(
    (chain): chain is Exclude<XdefiChain, RangoChain> =>
      chain !== RangoChain.Solana && !!config.selectedChains[chain],
  )
  const connectSwapkitPromise = swapkitClient.connectXDEFI(chains).then(() => {
    return chains.map((chain) => {
      if ('evmProvider' in config && isEvmChain(chain)) {
        return {
          ...swapkitClient.getWallet(chain),
          eip1193Provider: config.evmProvider,
          provider: new BrowserProvider(config.evmProvider),
          walletType: WalletOption.XDEFI,
        } satisfies XdefiEvmWallet
      }
      return swapkitClient.getWallet(chain)
    })
  })
  const connectSolanaPromise: [Promise<ChainWallet>] | [] =
    'solProvider' in config
      ? [
          fetchXdefiSolanaAddress({ provider: config.solProvider }).then((address): ChainWallet => {
            return {
              chain: RangoChain.Solana,
              walletType: WalletOption.XDEFI,
              balance: [],
              address,
              provider: config.solProvider,
            }
          }),
        ]
      : []
  return Promise.allSettled([connectSwapkitPromise, ...connectSolanaPromise]).then(
    ([swapkitWalletsResult, solanaWalletResult]) => {
      return [
        ...(swapkitWalletsResult.status === 'fulfilled' ? swapkitWalletsResult.value : []),
        ...(solanaWalletResult?.status === 'fulfilled' ? [solanaWalletResult.value] : []),
      ]
    },
  )
}

export function assertMetamaskIsInstalled(provider: unknown): asserts provider is Eip1193Provider {
  if (
    typeof provider !== 'object' ||
    !provider ||
    !('isMetaMask' in provider) ||
    !provider.isMetaMask
  ) {
    throw new Error('MetaMask wallet not found')
  }
}
export type MetamaskConfig = {
  chain: EVMChain
  provider: Eip1193Provider
}
export const connectMetamaskWallet = async (config: MetamaskConfig): Promise<MetamaskWallet[]> => {
  const chains = [config.chain]
  await swapkitClient.connectEVMWallet(chains, WalletOption.METAMASK)
  return chains.map((chain) => {
    return {
      ...swapkitClient.getWallet(chain),
      eip1193Provider: config.provider,
      provider: new BrowserProvider(config.provider),
      walletType: WalletOption.METAMASK,
    }
  })
}

export type KeplrChains = Parameters<SwapkitClient['connectKeplr']>[0]
export type KeplrChain = KeplrChains[number]
export const keplrChains = [Chain.Cosmos, Chain.Kujira] as const satisfies KeplrChains
export const connectKeplrWallet = async ({
  selectedChains,
}: WithSelectedChains): Promise<ChainWallet[]> => {
  const chains = keplrChains.filter((chain) => selectedChains[chain])
  await swapkitClient.connectKeplr(chains)
  return chains.map((chain) => {
    return swapkitClient.getWallet(chain)
  })
}

/**
 * @reference https://solana.com/developers/cookbook/wallets/restore-from-mnemonic#restoring-bip44-formant-mnemonics
 */
export const getKeystoreSolanaKeypair = (phrase: string): Keypair => {
  const seed = bip39.mnemonicToSeedSync(phrase, '')
  const bip44Format = 44
  const solanaChainId = 501
  const index = 0
  const path = `m/${bip44Format}'/${solanaChainId}'/${index}'/0'`
  const hd = HDKey.fromMasterSeed(seed.toString('hex'))
  return Keypair.fromSeed(hd.derive(path).privateKey)
}
export const connectKeystoreWallet = async ({
  selectedChains,
  phrase,
  ethProviderApiKey,
  arbProviderApiKey,
  solanaNetworkUrl,
}: WithSelectedChains & {
  phrase: string
  ethProviderApiKey: string
  arbProviderApiKey: string
  solanaNetworkUrl: string
}): Promise<ChainWallet[]> => {
  const chains = swapkitChains.filter((chain) => selectedChains[chain])
  await swapkitClient.connectKeystore(chains, phrase)
  const swapkitWallets = chains.map((chain) => {
    const provider: AlchemyProvider | null = (() => {
      if (chain === Chain.Arbitrum) {
        return new AlchemyProvider('arbitrum', arbProviderApiKey)
      }
      if (chain === Chain.Ethereum) {
        return new AlchemyProvider('mainnet', ethProviderApiKey)
      }
      return null
    })()
    const wallet = provider && Wallet.fromPhrase(phrase, provider)
    const withProvider: Pick<KeystoreEvmWallet, 'account' | 'provider'> | null = wallet && {
      account: mnemonicToAccount(phrase),
      provider: {
        signAndSendTransaction: (...params) => wallet.sendTransaction(...params),
      },
    }
    return { ...swapkitClient.getWallet(chain), ...withProvider }
  })
  if (selectedChains[RangoChain.Solana]) {
    const signer = getKeystoreSolanaKeypair(phrase)
    return [
      ...swapkitWallets,
      {
        address: signer.publicKey.toString(),
        balance: [],
        chain: RangoChain.Solana,
        walletType: WalletOption.KEYSTORE,
        provider: {
          signAndSendTransaction: async (transaction, options) => {
            const connection = new Connection(solanaNetworkUrl)
            if (transaction instanceof VersionedTransaction) {
              transaction.sign([signer])
              const signature = await connection.sendTransaction(transaction, options)
              return { signature }
            }
            const signature = await sendAndConfirmTransaction(
              connection,
              transaction,
              [signer],
              options,
            )
            return { signature }
          },
        },
      } satisfies SolanaKeystoreWallet,
    ]
  }
  return swapkitWallets
}

const connectWallet = {
  [WalletOption.PHANTOM]: connectPhantomWallet,
  [WalletOption.XDEFI]: connectXdefiWallet,
  [WalletOption.METAMASK]: connectMetamaskWallet,
  [WalletOption.KEPLR]: connectKeplrWallet,
  [WalletOption.KEYSTORE]: connectKeystoreWallet,
} satisfies {
  [key in WalletType]?: (config: any) => Promise<ChainWallet[]>
}
type ConnectWallet = typeof connectWallet
export type SwapkitWalletOption = keyof ConnectWallet
export type ConnectSwapkitWalletConfig = {
  [key in SwapkitWalletOption]: {
    wallet: key
  } & Parameters<ConnectWallet[key]>[0]
}[SwapkitWalletOption]
export const connectSwapkitWallet = (config: ConnectSwapkitWalletConfig) => {
  switch (config.wallet) {
    case WalletOption.PHANTOM:
      return connectPhantomWallet(config)
    case WalletOption.XDEFI:
      return connectXdefiWallet(config)
    case WalletOption.METAMASK:
      return connectMetamaskWallet(config)
    case WalletOption.KEPLR:
      return connectKeplrWallet(config)
    default:
      return connectKeystoreWallet(config)
  }
}
