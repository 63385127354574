import { BigNumber } from 'bignumber.js'
import { PublicKey } from '@solana/web3.js'

export const nativeAsset = 'MAYA.CACAO'
export const STABLE_POOL_ASSET = 'ETH.USDT-0XDAC17F958D2EE523A2206206994597C13D831EC7'

// cspell:disable
export const tokenIcons = {
  Native: '/tokens/chains.png',
  All: '/tokens/chains.png',
  MAYA: '/tokens/maya.png',
  'MAYA.CACAO': '/tokens/cacao.png',
  'MAYA.MAYA': '/tokens/maya.png',
  THOR: '/tokens/rune.png',
  'THOR.RUNE': '/tokens/rune.png',
  BTC: '/tokens/btc.png',
  'BTC.BTC': '/tokens/btc.png',
  DASH: '/tokens/dash.png',
  'DASH.DASH': '/tokens/dash.png',
  ETH: '/tokens/eth.png',
  'ETH.ETH': '/tokens/eth.png',
  'ETH.USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48': '/tokens/usdc.png',
  'ETH.USDT-0XDAC17F958D2EE523A2206206994597C13D831EC7': '/tokens/usdt.png',
  'ETH.WSTETH-0X7F39C581F595B53C5CB19BD0B3F8DA6C935E2CA0': '/tokens/wsteth.png',
  'ETH.USDC': '/tokens/usdc.png',
  ARB: '/tokens/arbitrum.png',
  'ARB.ETH': '/tokens/eth.png',
  'ARB.ARB-0X912CE59144191C1204E64559FE8253A0E49E6548': '/tokens/arbitrum.png',
  'ARB.DAI-0XDA10009CBD5D07DD0CECC66161FC93D7C9000DA1': '/tokens/dai.png',
  'ARB.GLD-0XAFD091F140C21770F4E5D53D26B2859AE97555AA': '/tokens/gld.png',
  'ARB.GMX-0XFC5A1A6EB076A2C7AD06ED22C90D7E710E35AD0A': '/tokens/gmx.png',
  'ARB.GNS-0X18C11FD286C5EC11C3B683CAA813B77F5163A122': '/tokens/gns.png',
  'ARB.LEO-0X93864D81175095DD93360FFA2A529B8642F76A6E': '/tokens/leo.png',
  'ARB.LINK-0XF97F4DF75117A78C1A5A0DBB814AF92458539FB4': '/tokens/link.png',
  'ARB.PEPE-0X25D887CE7A35172C62FEBFD67A1856F20FAEBB00': '/tokens/pepe.webp',
  'ARB.SUSHI-0XD4D42F0B6DEF4CE0383636770EF773390D85C61A': '/tokens/sushi.png',
  'ARB.TGT-0X429FED88F10285E61B12BDF00848315FBDFCC341': '/tokens/tgt.png',
  'ARB.UNI-0XFA7F8980B0F1E64A2062791CC3B0871572F1F7F0': '/tokens/uni.png',
  'ARB.USDC-0XAF88D065E77C8CC2239327C5EDB3A432268E5831': '/tokens/usdc.png',
  'ARB.USDT-0XFD086BC7CD5C481DCC9C85EBE478A1C0B69FCBB9': '/tokens/usdt.png',
  'ARB.WBTC-0X2F2A2543B76A4166549F7AAB2E75BEF0AEFC5B0F': '/tokens/wbtc.png',
  'ARB.WSTETH-0X5979D7B546E38E414F7E9822514BE443A4800529': '/tokens/wsteth.png',
  LTC: '/tokens/ltc.png',
  'LTC.LTC': '/tokens/ltc.png',
  BCH: '/tokens/bch.png',
  'BCH.BCH': '/tokens/bch.png',
  BNB: '/tokens/bnb.svg',
  'BNB.BNB': '/tokens/bnb.svg',
  DOGE: '/tokens/doge.png',
  'DOGE.DOGE': '/tokens/doge.png',
  KUJI: '/tokens/kuji.png',
  'KUJI.KUJI': '/tokens/kuji.png',
  'KUJI.USK': '/tokens/usk.png',
  ATOM: '/tokens/atom.svg',
  'GAIA.ATOM': '/tokens/atom.svg',
  AVAX: '/tokens/avax.svg',
  'AVAX.AVAX': '/tokens/avax.svg',
  '.ASYMM': '/tokens/asymm.png',
  'ETH.FLIP-0x826180541412D574cf1336d22c0C0a287822678A': '/tokens/chainflip.svg',
  DOT: '/tokens/polkadot.svg',
  'DOT.DOT': '/tokens/polkadot.svg',
  'SOLANA.SOL': '/tokens/solana.png',
  'SOLANA.USDC--EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v': '/tokens/usdc.png',
  'SOLANA.USDC': '/tokens/usdc.png',
  'SOLANA.ZYN--PzuaVAUH2tfxGZcbBR6kMxeJsBngnsPLFotGJNCtcsd': '/tokens/zyn.jpg',
  'SOLANA.ZYN': '/tokens/zyn.jpg',
  'ETH.ZYN--0x58cb30368ceb2d194740b144eab4c2da8a917dcb': '/tokens/zyn.jpg',
  'ETH.ZYN': '/tokens/zyn.jpg',
  'ETH.TRUMP--0x576e2bed8f7b46d34016198911cdf9886f78bea7': '/tokens/trump.png',
  'ETH.TRUMP': '/tokens/trump.png',
  'SOLANA.KENIDY--Bg9CZr1CmVoCn2uNWwj9f5rLbmfYRYvcVikCRCwawUwR': '/tokens/kenidy.jpeg',
  'SOLANA.TREN--HLnTNCG5RD7jYVduFc1pMCHiuApoWGn9LveqEFanQFZb': '/tokens/tren.jpeg',
  'ETH.APU--0x594daad7d77592a2b97b725a7ad59d7e188b5bfa': '/tokens/apu.jpeg',
  'ETH.APU': '/tokens/apu.jpeg',
  'ETH.ENA--0x57e114B691Db790C35207b2e685D4A43181e6061': '/tokens/ena.png',
  'ETH.ENA': '/tokens/ena.png',
  'ETH.ALCX--0xdbdb4d16eda451d0503b854cf79d55697f90c8df': '/tokens/alcx.png',
  'ETH.ALCX': '/tokens/alcx.png',
  'ETH.YFI--0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e': '/tokens/yfi.png',
  'ETH.YFI': '/tokens/yfi.png',
  'ETH.AAVE--0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9': '/tokens/aave.jpeg',
  'ETH.AAVE': '/tokens/aave.jpeg',
  'ETH.MOG--0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a': '/tokens/mog.png',
  'ETH.MOG': '/tokens/mog.png',
  'ETH.JESUS--0xba386A4Ca26B85FD057ab1Ef86e3DC7BdeB5ce70': '/tokens/jesus.png',
  'ETH.JESUS': '/tokens/jesus.png',
  'SOLANA.LDR--H9XmJ3ot3MpawSMMxarGqX8rJaQGWd5WWZvnLU4PTwmC': '/tokens/ldr.png',
  'SOLANA.LDR': '/tokens/ldr.png',
  'SOLANA.SWAG--FaxYQ3LVXP51rDP2yWGLWVrFAAHeSdFF8SGZxwj2dvor': '/tokens/swag.jpeg',
  'SOLANA.SWAG': '/tokens/swag.jpeg',
  'SOLANA.RETARDIO--0x6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx': '/tokens/retardio.png',
  'SOLANA.RETARDIO': '/tokens/retardio.png',
  'SOLANA.KENIDY': '/tokens/kenidy.jpeg',
  'SOLANA.TREN': '/tokens/jpeg.png',
  SOLANA: '/tokens/solana.png',
  'SOLANA.SLERF--7BgBvyjrZX1YKz4oh9mjb8ZScatkkwb8DzFx7LoiVkM3': '/tokens/slerf.png',
  'SOLANA.SLERF': '/tokens/slerf.png',
  'SOLANA.BOME--ukHH6c7mMyiWCf1b9pnWe25TSpkDDt3H5pQZgZ74J82': '/tokens/bome.png',
  'SOLANA.BOME': '/tokens/bome.png',
  'SOLANA.WIF--EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm': '/tokens/wif.png',
  'SOLANA.WIF': '/tokens/wif.png',
  'ETH.PEPE--0X6982508145454CE325DDBE47A25D4EC3D2311933': '/tokens/pepe.webp',
  'ETH.PEPE-0X6982508145454CE325DDBE47A25D4EC3D2311933': '/tokens/pepe.webp',
  'ETH.PEPE': '/tokens/pepe.webp',
  'SOLANA.BONK--DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263': '/tokens/bonk.png',
  'SOLANA.BONK': '/tokens/bonk.png',
  'SOLANA.BODEN--3psH1Mj1f7yUfaD5gh6Zj7epE8hhrMkMETgv5TshQA4o': '/tokens/boden.png',
  'SOLANA.BODEN': '/tokens/boden.png',
  GAIA: '/tokens/atom.svg',
  BSC: '/tokens/bsc.svg',
}
// cspell:enable

export type Token = keyof typeof tokenIcons
// export function getTokenIcon(asset) {
//   const capitalizedKeysTokenIcons = {}
//   for (const key in tokenIcons) {
//     if (Object.hasOwn(tokenIcons, key)) {
//       const upperKey = key.toUpperCase()
//       capitalizedKeysTokenIcons[upperKey] = tokenIcons[key]
//     }
//   }
//   const icon = capitalizedKeysTokenIcons[asset]
//   return icon
// }

// cspell:disable
export const dummyDestinations = {
  BTC: 'bc1qdvxpt06ulfk5gm5p52wa4mrt6e887wkmvc4xxw',
  ETH: '0x4E71F9debEC9117F1FACc7eeB490758AF45806A7',
  ARB: '0x4E71F9debEC9117F1FACc7eeB490758AF45806A7',
  AVAX: '0x4E71F9debEC9117F1FACc7eeB490758AF45806A7',
  THOR: 'thor1505gp5h48zd24uexrfgka70fg8ccedafsnj0e3',
  MAYA: 'maya1fn9vhzyl3e63ewgwqk806k9hqezrcflznlq8u9',
  DASH: 'Xn7yyaBh7AuLEahoepXc2QXPgSjwdATdcz',
  KUJI: 'kujira1vxrsh0vw55w3xa6pemg8c7zkmf3p7avetdr60j',
  SOLANA: 'F5YUSLQ37FduBA2p9XMdSPnz43dWuzGG9Tdq4mVoq2HM',
  // 'DOT',
  // 'BSC',
  // 'BCH',
  // 'BNB',
  // 'DOGE',
  // 'GAIA',
  // 'LTC',
}
// cspell:enable

// cspell:disable
export const ASSET_DECIMALS: { [key: string]: number } = {
  'THOR.RUNE': 8,
  'MAYA.CACAO': 10,
  'ETH.ETH': 18,
  'KUJI.KUJI': 6,
  'KUJI.USK': 8,
  ukuji: 6,
  'ETH.USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48': 6,
  'ETH.FLIP-0x826180541412D574cf1336d22c0C0a287822678A': 18,
  'ETH.WSTETH-0X7F39C581F595B53C5CB19BD0B3F8DA6C935E2CA0': 18,
  'ETH.USDT-0XDAC17F958D2EE523A2206206994597C13D831EC7': 6,
  'ETH/USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48': 6,
  'ETH/USDT-0XDAC17F958D2EE523A2206206994597C13D831EC7': 6,
  'ETH/WSTETH-0X7F39C581F595B53C5CB19BD0B3F8DA6C935E2CA0': 18,
  'BTC.BTC': 8,
  'DASH.DASH': 8,
  'DASH/DASH': 8,
  'ETH/ETH': 8,
  'BTC/BTC': 8,
  'KUJI/KUJI': 8,
  'KUJI/USK': 8,
  'THOR/RUNE': 8,
  'DOT.DOT': 10,
  'SOL.SOL': 9,
  'SOLANA.SOL': 9,
  'SOLANA.USDC--EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v': 6,
  'SOLANA.ZYN--PzuaVAUH2tfxGZcbBR6kMxeJsBngnsPLFotGJNCtcsd': 8,
  'ETH.TRUMP--0x576e2bed8f7b46d34016198911cdf9886f78bea7': 9,
  'ETH.ZYN--0x58cb30368ceb2d194740b144eab4c2da8a917dcb': 18,
  'SOLANA.SLERF--7BgBvyjrZX1YKz4oh9mjb8ZScatkkwb8DzFx7LoiVkM3': 9,
  'SOLANA.BONK--DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263': 5,
  'SOLANA.WIF--EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm': 6,
  'SOLANA.BOME--ukHH6c7mMyiWCf1b9pnWe25TSpkDDt3H5pQZgZ74J82': 6,
  'ETH.PEPE--0X6982508145454CE325DDBE47A25D4EC3D2311933': 18,
  'ETH.PEPE-0X6982508145454CE325DDBE47A25D4EC3D2311933': 8,
  'SOLANA.BODEN--3psH1Mj1f7yUfaD5gh6Zj7epE8hhrMkMETgv5TshQA4o': 9,
  'SOLANA.KENIDY--Bg9CZr1CmVoCn2uNWwj9f5rLbmfYRYvcVikCRCwawUwR': 9,
  'SOLANA.TREN--HLnTNCG5RD7jYVduFc1pMCHiuApoWGn9LveqEFanQFZb': 9,
  'ETH.APU--0x594daad7d77592a2b97b725a7ad59d7e188b5bfa': 18,
  'ETH.ENA--0x57e114B691Db790C35207b2e685D4A43181e6061': 18,
  'ETH.ALCX--0xdbdb4d16eda451d0503b854cf79d55697f90c8df': 18,
  'ETH.YFI--0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e': 18,
  'ETH.AAVE--0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9': 18,
  'ETH.MOG--0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a': 18,
  'ETH.JESUS--0xba386A4Ca26B85FD057ab1Ef86e3DC7BdeB5ce70': 18,
  'SOLANA.LDR--H9XmJ3ot3MpawSMMxarGqX8rJaQGWd5WWZvnLU4PTwmC': 6,
  'SOLANA.SWAG--FaxYQ3LVXP51rDP2yWGLWVrFAAHeSdFF8SGZxwj2dvor': 2,
  'SOLANA.RETARDIO--0x6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx': 6,
  'ARB.ETH': 18,
  'ARB.ARB-0X912CE59144191C1204E64559FE8253A0E49E6548': 18,
  'ARB.DAI-0XDA10009CBD5D07DD0CECC66161FC93D7C9000DA1': 18,
  'ARB.GLD-0XAFD091F140C21770F4E5D53D26B2859AE97555AA': 18,
  'ARB.GMX-0XFC5A1A6EB076A2C7AD06ED22C90D7E710E35AD0A': 18,
  'ARB.GNS-0X18C11FD286C5EC11C3B683CAA813B77F5163A122': 18,
  'ARB.LEO-0X93864D81175095DD93360FFA2A529B8642F76A6E': 3,
  'ARB.LINK-0XF97F4DF75117A78C1A5A0DBB814AF92458539FB4': 18,
  'ARB.PEPE-0X25D887CE7A35172C62FEBFD67A1856F20FAEBB00': 18,
  'ARB.SUSHI-0XD4D42F0B6DEF4CE0383636770EF773390D85C61A': 18,
  'ARB.TGT-0X429FED88F10285E61B12BDF00848315FBDFCC341': 18,
  'ARB.UNI-0XFA7F8980B0F1E64A2062791CC3B0871572F1F7F0': 18,
  'ARB.USDC-0XAF88D065E77C8CC2239327C5EDB3A432268E5831': 6,
  'ARB.USDT-0XFD086BC7CD5C481DCC9C85EBE478A1C0B69FCBB9': 6,
  'ARB.WBTC-0X2F2A2543B76A4166549F7AAB2E75BEF0AEFC5B0F': 8,
  'ARB.WSTETH-0X5979D7B546E38E414F7E9822514BE443A4800529': 18,
  'ARB/ETH': 8,
  'ARB/ARB-0X912CE59144191C1204E64559FE8253A0E49E6548': 8,
  'ARB/DAI-0XDA10009CBD5D07DD0CECC66161FC93D7C9000DA1': 8,
  'ARB/GLD-0XAFD091F140C21770F4E5D53D26B2859AE97555AA': 8,
  'ARB/GMX-0XFC5A1A6EB076A2C7AD06ED22C90D7E710E35AD0A': 8,
  'ARB/GNS-0X18C11FD286C5EC11C3B683CAA813B77F5163A122': 8,
  'ARB/LEO-0X93864D81175095DD93360FFA2A529B8642F76A6E': 8,
  'ARB/LINK-0XF97F4DF75117A78C1A5A0DBB814AF92458539FB4': 8,
  'ARB/PEPE-0X25D887CE7A35172C62FEBFD67A1856F20FAEBB00': 8,
  'ARB/SUSHI-0XD4D42F0B6DEF4CE0383636770EF773390D85C61A': 8,
  'ARB/TGT-0X429FED88F10285E61B12BDF00848315FBDFCC341': 8,
  'ARB/UNI-0XFA7F8980B0F1E64A2062791CC3B0871572F1F7F0': 8,
  'ARB/USDC-0XAF88D065E77C8CC2239327C5EDB3A432268E5831': 8,
  'ARB/USDT-0XFD086BC7CD5C481DCC9C85EBE478A1C0B69FCBB9': 8,
  'ARB/WBTC-0X2F2A2543B76A4166549F7AAB2E75BEF0AEFC5B0F': 8,
  'ARB/WSTETH-0X5979D7B546E38E414F7E9822514BE443A4800529': 8,
}
// cspell:enable

export function getAssetDecimals(asset: string): number {
  if (!asset) throw new Error('Asset is required to retrieve decimals')
  const decimals = ASSET_DECIMALS[asset]
  if (!decimals) throw new Error('No decimals defined for asset: ' + asset)
  return decimals
}

export function createQueryString(baseURL: string, params: { [key: string]: string }) {
  // Map each key-value pair to a 'key=value' format, ensuring values are URI-encoded
  const queryString = Object.entries(params)
    .map(([key, value]) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    })
    .join('&')

  // Construct the full URL
  return `${baseURL}?${queryString}`
}

export function assetIntegerToMayaInteger(integer: string, asset: string): string {
  const mayaDecimals = asset === 'MAYA.CACAO' ? 10 : 8
  return (parseInt(integer) / 10 ** (getAssetDecimals(asset) - mayaDecimals)).toFixed(0)
}

export function mayaIntegerToAssetInteger(integer: string, asset: string) {
  const mayaDecimals = asset === 'MAYA.CACAO' ? 10 : 8

  // Convert integer to BigInt
  const bigIntInteger = BigInt(integer)

  // Determine the scaling factor based on the relative size of mayaDecimals and ASSET_DECIMALS[asset]
  const decimalDifference = getAssetDecimals(asset) - mayaDecimals

  let result
  if (decimalDifference >= 0) {
    // If ASSET_DECIMALS[asset] is greater than or equal to mayaDecimals, scale up
    const powerOfTen = BigInt(10) ** BigInt(decimalDifference)
    result = bigIntInteger * powerOfTen
  } else {
    // If mayaDecimals is greater, scale down. Use division and ensure the result is a BigInt.
    const powerOfTen = BigInt(10) ** BigInt(-decimalDifference)
    result = bigIntInteger / powerOfTen
  }

  // Convert the result to a string to avoid scientific notation
  return result.toString()
}

export function bigIntToDecimalString(bigIntValue: BigInt, decimals: number): string {
  const stringValue = bigIntValue.toString()
  return IntStringToDecimalString(stringValue, decimals)
}

export function mayaIntegerToNumber(mayaInteger: string, asset: string): number {
  const decimals = getAssetDecimals(asset)
  return parseInt(mayaInteger) / 10 ** decimals
}

export function IntStringToDecimalString(intValue: string, decimals: number): string {
  const stringValue = intValue.toString()
  const integerPart = stringValue.length > decimals ? stringValue.slice(0, -decimals) : '0'
  let fractionalPart =
    stringValue.length > decimals
      ? stringValue.slice(-decimals)
      : stringValue.padStart(decimals, '0')

  // Optionally: Remove trailing zeros from the fractional part
  fractionalPart = fractionalPart.replace(/0+$/, '')

  // Ensure there's a fractional part to display, otherwise return the integer part only
  return fractionalPart.length > 0 ? `${integerPart}.${fractionalPart}` : integerPart
}

// cspell:disable
export const DISPLAY_DECIMALS: { [key: string]: number } = {
  'MAYA.CACAO': 3,
  'THOR.RUNE': 3,
  'ETH.ETH': 4,
  'ARB.ETH': 4,
  'ETH.USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48': 2,
  'ETH.USDC': 2,
  'ETH.FLIP-0x826180541412D574cf1336d22c0C0a287822678A': 4,
  'ETH.WSTETH-0X7F39C581F595B53C5CB19BD0B3F8DA6C935E2CA0': 4,
  'ETH.USDT-0XDAC17F958D2EE523A2206206994597C13D831EC7': 4,
  'BTC.BTC': 8,
  'DASH.DASH': 4,
  'ETH/ETH': 4,
  'BTC/BTC': 5,
  USD: 2,
  'SOLANA.USDC--EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v': 4,
  'SOLANA.ZYN--PzuaVAUH2tfxGZcbBR6kMxeJsBngnsPLFotGJNCtcsd': 4,
  'ETH.TRUMP--0x576e2bed8f7b46d34016198911cdf9886f78bea7': 4,
  'ETH.ZYN--0x58cb30368ceb2d194740b144eab4c2da8a917dcb': 4,
  'SOLANA.BODEN--3psH1Mj1f7yUfaD5gh6Zj7epE8hhrMkMETgv5TshQA4o': 4,
  'SOLANA.KENIDY--Bg9CZr1CmVoCn2uNWwj9f5rLbmfYRYvcVikCRCwawUwR': 4,
  'SOLANA.TREN--HLnTNCG5RD7jYVduFc1pMCHiuApoWGn9LveqEFanQFZb': 4,
  'ETH.ENA--0x57e114B691Db790C35207b2e685D4A43181e6061': 4,
  'ETH.ALCX--0xdbdb4d16eda451d0503b854cf79d55697f90c8df': 4,
  'ETH.YFI--0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e': 4,
  'ETH.AAVE--0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9': 4,
  'ETH.MOG--0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a': 4,
  'ETH.JESUS--0xba386A4Ca26B85FD057ab1Ef86e3DC7BdeB5ce70': 4,
  'SOLANA.LDR--H9XmJ3ot3MpawSMMxarGqX8rJaQGWd5WWZvnLU4PTwmC': 4,
  'SOLANA.SWAG--FaxYQ3LVXP51rDP2yWGLWVrFAAHeSdFF8SGZxwj2dvor': 2,
  'SOLANA.RETARDIO--0x6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx': 4,
  'ETH.APU--0x594daad7d77592a2b97b725a7ad59d7e188b5bfa': 4,
  'ETH.PEPE-0X6982508145454CE325DDBE47A25D4EC3D2311933': 4,
  'ARB.ARB-0X912CE59144191C1204E64559FE8253A0E49E6548': 4,
  'ARB.DAI-0XDA10009CBD5D07DD0CECC66161FC93D7C9000DA1': 4,
  'ARB.GLD-0XAFD091F140C21770F4E5D53D26B2859AE97555AA': 4,
  'ARB.GMX-0XFC5A1A6EB076A2C7AD06ED22C90D7E710E35AD0A': 4,
  'ARB.GNS-0X18C11FD286C5EC11C3B683CAA813B77F5163A122': 4,
  'ARB.LEO-0X93864D81175095DD93360FFA2A529B8642F76A6E': 4,
  'ARB.LINK-0XF97F4DF75117A78C1A5A0DBB814AF92458539FB4': 4,
  'ARB.PEPE-0X25D887CE7A35172C62FEBFD67A1856F20FAEBB00': 4,
  'ARB.SUSHI-0XD4D42F0B6DEF4CE0383636770EF773390D85C61A': 4,
  'ARB.TGT-0X429FED88F10285E61B12BDF00848315FBDFCC341': 4,
  'ARB.UNI-0XFA7F8980B0F1E64A2062791CC3B0871572F1F7F0': 4,
  'ARB.USDC-0XAF88D065E77C8CC2239327C5EDB3A432268E5831': 2,
  'ARB.USDT-0XFD086BC7CD5C481DCC9C85EBE478A1C0B69FCBB9': 2,
  'ARB.WBTC-0X2F2A2543B76A4166549F7AAB2E75BEF0AEFC5B0F': 8,
  'ARB.WSTETH-0X5979D7B546E38E414F7E9822514BE443A4800529': 4,
  'ARB/ETH': 4,
  'ARB/ARB-0X912CE59144191C1204E64559FE8253A0E49E6548': 4,
  'ARB/DAI-0XDA10009CBD5D07DD0CECC66161FC93D7C9000DA1': 4,
  'ARB/GLD-0XAFD091F140C21770F4E5D53D26B2859AE97555AA': 4,
  'ARB/GMX-0XFC5A1A6EB076A2C7AD06ED22C90D7E710E35AD0A': 4,
  'ARB/GNS-0X18C11FD286C5EC11C3B683CAA813B77F5163A122': 4,
  'ARB/LEO-0X93864D81175095DD93360FFA2A529B8642F76A6E': 4,
  'ARB/LINK-0XF97F4DF75117A78C1A5A0DBB814AF92458539FB4': 4,
  'ARB/PEPE-0X25D887CE7A35172C62FEBFD67A1856F20FAEBB00': 4,
  'ARB/SUSHI-0XD4D42F0B6DEF4CE0383636770EF773390D85C61A': 4,
  'ARB/TGT-0X429FED88F10285E61B12BDF00848315FBDFCC341': 4,
  'ARB/UNI-0XFA7F8980B0F1E64A2062791CC3B0871572F1F7F0': 4,
  'ARB/USDC-0XAF88D065E77C8CC2239327C5EDB3A432268E5831': 4,
  'ARB/USDT-0XFD086BC7CD5C481DCC9C85EBE478A1C0B69FCBB9': 4,
  'ARB/WBTC-0X2F2A2543B76A4166549F7AAB2E75BEF0AEFC5B0F': 4,
  'ARB/WSTETH-0X5979D7B546E38E414F7E9822514BE443A4800529': 4,
}
// cspell:enable

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text)
}

export function getAssetChain(asset: string) {
  if (!asset) return ''
  if (asset.includes('/')) {
    return 'MAYA'
  }
  if (asset.startsWith('DOT')) {
    return 'POLKADOT'
  }
  if (asset.startsWith('SOL')) {
    return 'SOLANA'
  }
  return asset.split('.')[0]
}

export function getAssetName(asset: string) {
  if (!asset) return ''
  if (asset.includes('/')) {
    return 's' + asset.split('/')[1].split('-')[0]
  }
  return asset.split('.')[1].split('-')[0].toUpperCase()
}

export function formatDatetime(d: Date) {
  d = new Date(d)
  if (d.getTime() === 0) return '-'
  const pad = (s: number) => ('0' + s).slice(-2)
  return [
    d.getFullYear() + '-',
    pad(d.getMonth() + 1) + '-',
    pad(d.getDate()) + ' ',
    pad(d.getHours()) + ':',
    pad(d.getMinutes()),
  ].join('')
}

export function formatAddress(a: string) {
  if (!a) return '-'
  return a.slice(0, 6) + '…' + a.slice(-5)
}

export function formatWalletAddress(a: string) {
  if (!a) return '-'
  return a
}

export function decimalToIntegerString(decimal: string, decimals: number): string {
  BigNumber.config({ EXPONENTIAL_AT: 200 })
  const bigDecimals = new BigNumber(10).pow(decimals)
  const int = new BigNumber(decimal).times(bigDecimals) // TODO throw error if user input has more decimal places than asset

  const resultIntStr = int.toFixed(0)

  return resultIntStr === 'NaN' ? '0' : resultIntStr
}

export function decimalToDisplayStr(decimal: string | number, asset: string): string {
  if (typeof decimal === 'string') {
    decimal = parseInt(decimal)
  }
  return formatNumber(decimal, DISPLAY_DECIMALS[asset])
}

export function integerToDisplayStr(integer: string | number, asset: string): string {
  if (typeof integer === 'string') {
    integer = parseInt(integer)
  }
  const decimal = integer / 10 ** getAssetDecimals(asset)

  return formatNumber(decimal, DISPLAY_DECIMALS[asset])
}
export const integerToDecimalStr = (integer: string, asset: string): string => {
  const decimals = getAssetDecimals(asset)
  return integer.length === decimals
    ? '0.' + integer
    : integer.slice(0, decimals) + '.' + integer.slice(decimals)
}

export function formatNumber(n: string | number | bigint, decimals = 2, size = 8): string {
  const bigNumber: BigNumber = (() => {
    if (typeof n === 'string') {
      return BigNumber(n).div(BigNumber(10).pow(size))
    }
    if (typeof n === 'bigint') {
      return BigNumber(n.toString()).div(BigNumber(10).pow(size))
    }
    return BigNumber(n)
  })()
  const formatter = new Intl.NumberFormat('en-US', {
    useGrouping: 'always',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })
  if (bigNumber.isNaN()) {
    return formatter.format(0)
  }
  if (bigNumber.isGreaterThanOrEqualTo(1e6)) {
    return (
      formatter.format(bigNumber.integerValue(BigNumber.ROUND_HALF_EVEN).div(1e6).toNumber()) + 'M'
    )
  }
  if (bigNumber.isGreaterThanOrEqualTo(1e5)) {
    return (
      formatter.format(bigNumber.integerValue(BigNumber.ROUND_HALF_EVEN).div(1e3).toNumber()) + 'K'
    )
  }
  const factor = BigNumber(10).pow(decimals)
  return formatter.format(
    bigNumber.multipliedBy(factor).integerValue(BigNumber.ROUND_FLOOR).div(factor).toNumber(),
  )
}

export function formatAddressLink(address: string, asset = '') {
  const chain = getAssetChain(asset)
  switch (chain) {
    case 'MAYA':
      return 'https://www.mayascan.org/address/' + address
    case 'THOR':
      return 'https://thorchain.net/address/' + address
    case 'ETH':
      return 'https://etherscan.io/address/' + address
    case 'ARB':
      return 'https://arbiscan.io/address/' + address
    case 'BTC':
      return 'https://mempool.space/address/' + address
    case 'DASH':
      return 'https://insight.dash.org/insight/address/' + address
    case 'KUJI':
      return 'https://finder.kujira.network/kaiyo-1/address/' + address
    case 'POLKADOT':
      return 'https://polkadot.subscan.io/account/' + address
    case 'SOLANA':
      return 'https://solscan.io/account/' + address
    default:
      return '#' + address
  }
}

export function formatExplorerLink(hash: string, asset = '') {
  const chain = getAssetChain(asset)
  switch (chain) {
    case 'MAYA':
      return 'https://www.mayascan.org/tx/' + hash
    case 'THOR':
      return 'https://thorchain.net/tx/' + hash
    case 'ETH':
      return 'https://etherscan.io/tx/' + hash
    case 'ARB':
      return 'https://arbiscan.io/tx/' + hash
    case 'BTC':
      return 'https://mempool.space/tx/' + hash
    case 'DASH':
      return 'https://insight.dash.org/insight/tx/' + hash
    case 'KUJI':
      return 'https://finder.kujira.network/kaiyo-1/tx/' + hash
    case 'POLKADOT':
      return 'https://polkadot.subscan.io/extrinsic/' + hash
    case 'SOLANA':
      return 'https://solscan.io/tx/' + hash
    default:
      return '#' + hash
  }
}

export async function apiRequest<D>(path: string): Promise<D> {
  const fetchURL = 'https://node.eldorado.market/midgard/v2' + path

  const responseJson: D = await (await fetch(fetchURL)).json()

  return responseJson
}
export async function nodeRequest<D>(path: string): Promise<D> {
  return await (await fetch('https://node.eldorado.market/mayanode/mayachain' + path)).json()
}

export type SwapPool = {
  assetDepth: string
  runeDepth: string
}
export function getSwapOutput(a: number, pool: SwapPool, toRune: boolean) {
  const X = parseFloat(toRune ? pool.assetDepth : pool.runeDepth) / 1e8
  const Y = parseFloat(toRune ? pool.runeDepth : pool.assetDepth) / 1e8
  return (a * X * Y) / (a + X) ** 2
}

export function getDoubleSwapOutput(a: number, pool1: SwapPool, pool2: SwapPool) {
  return getSwapOutput(getSwapOutput(a, pool1, true), pool2, false)
}

export type SwapPoolWithAsset = SwapPool & { asset: string }
export function getAnySwapOutput(a: number, pool1: SwapPoolWithAsset, pool2: SwapPoolWithAsset) {
  if (pool1.asset === nativeAsset) {
    return getSwapOutput(a, pool2, false)
  } else if (pool2.asset === nativeAsset) {
    return getSwapOutput(a, pool1, true)
  } else {
    return getDoubleSwapOutput(a, pool1, pool2)
  }
}

interface Pool {
  asset: string
  balanceAsset: number
  balanceNative: number
  units: number
}
export type FetchPool = Omit<Pool, 'balanceAsset' | 'balanceNative' | 'units'> &
  Pick<Pool, 'balanceAsset' | 'balanceNative' | 'units'> & {
    price: number
    tvl: number
    volume: number
    apr: number
  }
export type NodePool = {
  asset: string
  balanceAsset: number
  balanceNative: number
  units: number
}
const mayaPoolToNodePool = (pool: MayaPool): NodePool => {
  return {
    asset: pool.asset,
    balanceAsset: parseInt(pool.balance_asset) / 1e8,
    balanceNative: parseInt(pool.balance_cacao) / 1e10,
    units: parseInt(pool.pool_units),
  }
}
export async function fetchPools(): Promise<
  Array<FetchPool | (Pick<FetchPool, 'price'> & { asset: typeof nativeAsset })>
> {
  const thorPools = (await apiRequest<ThorPool[]>('/pools')).reduce<{
    [K in ThorPool['asset']]?: ThorPool
  }>((pools, pool) => {
    return {
      ...pools,
      [pool.asset]: pool,
    }
  }, {})

  const { nodePools, usdPool } = (await nodeRequest<MayaPool[]>('/pools')).reduce<{
    nodePools: NodePool[]
    usdPool: NodePool | null
  }>(
    ({ nodePools: pools, usdPool: usd }, pool) => {
      return {
        nodePools: pools.concat(
          pool.status === 'Available' || pool.status === 'Staged' ? mayaPoolToNodePool(pool) : [],
        ),
        usdPool: (pool.asset === STABLE_POOL_ASSET && mayaPoolToNodePool(pool)) || usd,
      }
    },
    { nodePools: [], usdPool: null },
  )
  if (!usdPool) {
    throw new Error('Missing pool with stable pool asset.')
  }
  nodePools.sort((a, b) => b.balanceNative - a.balanceNative)
  const nativePrice = usdPool.balanceAsset / usdPool.balanceNative
  const fetchPools = nodePools.map((p) => {
    const thorPool = thorPools[p.asset]
    return {
      ...p,
      tvl: p.balanceNative * 2 * nativePrice,
      price: (p.balanceNative * nativePrice) / p.balanceAsset,
      volume:
        (thorPool &&
          BigNumber(thorPool.volume24h).div(1e10).multipliedBy(nativePrice).toNumber()) ??
        0,
      apr: (thorPool && parseFloat(thorPool.poolAPY)) ?? 0,
    }
  })
  return [...fetchPools, { asset: nativeAsset, price: nativePrice }]
}

export function tierPercent(tier: number) {
  return tier === 3 ? '4.5' : tier === 2 ? '1.5' : '0.5'
}

export const isValidSolanaAddress = (address: string) => {
  try {
    const key = new PublicKey(address)
    return PublicKey.isOnCurve(key)
  } catch {
    return false
  }
}
