import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useSnackbar } from 'vue3-snackbar'
import type { SnackbarMessage } from '~/types/snackbar'

export interface SnackbarBase {
  component: unknown
  estimatedTimeS: number
}
export interface SnackbarNormalData extends SnackbarBase {
  component: 'normal'
}
export interface SnackbarSwapData extends SnackbarBase {
  component: 'swap'
  timeMs: number
  fromAssetLogo: string
  toAssetLogo: string
}
export interface SnackbarLiquidityData extends SnackbarBase {
  component: 'liquidity'
  assetData?: {
    ticker: string
    chain: string
    explorerUrl: string
    chainIcon: string
  }
  baseAssetData?: {
    ticker: string
    chain: string
    explorerUrl: string
    chainIcon: string
  }
}
export type SnackbarData = SnackbarSwapData | SnackbarLiquidityData | SnackbarNormalData
type SnackbarDefaultProps = Omit<SnackbarMessage, 'type'>
const defaultProps: SnackbarDefaultProps = {
  title: '',
  text: '',
  duration: 6000,
  dismissible: true,
  role: 'alert',
  action: null,
}

export type SnackbarTypedMessageProps = Partial<SnackbarDefaultProps> &
  (Pick<SnackbarMessage, 'title'> | Pick<SnackbarMessage, 'text'>)
export type SnackbarDataMessageProps =
  | SnackbarTypedMessageProps
  | (SnackbarTypedMessageProps & { data: SnackbarData })
export type SnackbarMessageProps = SnackbarTypedMessageProps & Pick<SnackbarMessage, 'type'>
export const useSnackbarMessage = defineStore('snackbar', () => {
  const defaultData: SnackbarData = {
    component: 'normal',
    estimatedTimeS: 6,
  }
  const data = ref<SnackbarData | null>(defaultData)
  const snackbar = useSnackbar()
  const add = ({ type, ...props }: SnackbarMessageProps) => {
    if (process.env.NODE_ENV === 'development') {
      const { role, title, text } = props
      // eslint-disable-next-line no-console
      console.log('snackbar', { type, role, text, title })
    }
    snackbar.add({
      ...defaultProps,
      ...props,
      type,
    })
  }

  return {
    data,
    clearData: () => {
      data.value = defaultData
    },
    clear: snackbar.clear,
    addError: (props: SnackbarDataMessageProps) => {
      add({
        ...defaultProps,
        ...props,
        type: 'error',
      })
      if (!('data' in props)) {
        return
      }
      data.value = props.data
    },
    addWarning: (props: SnackbarDataMessageProps) => {
      add({
        ...defaultProps,
        ...props,
        type: 'warning',
      })
      if (!('data' in props)) {
        return
      }
      data.value = props.data
    },
    addInfo: (props: SnackbarDataMessageProps) => {
      add({
        ...defaultProps,
        ...props,
        type: 'info',
      })
      if (!('data' in props)) {
        return
      }
      data.value = props.data
    },
    addSuccess: (props: SnackbarDataMessageProps) => {
      add({
        ...defaultProps,
        ...props,
        type: 'success',
      })
      if (!('data' in props)) {
        return
      }
      data.value = props.data
    },
    add: ({
      type,
      ...props
    }: SnackbarMessageProps | (SnackbarMessageProps & { data: SnackbarData })) => {
      add({
        ...defaultProps,
        ...props,
        type,
      })
      if (!('data' in props)) {
        return
      }
      data.value = props.data
    },
  }
})
