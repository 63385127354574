<template>
  <NuxtLayout>
    <v-app class="h-full w-full [&>div]:h-full [&>div]:!w-full [&>div]:[all:unset]">
      <NuxtPage />
    </v-app>
  </NuxtLayout>
</template>
<script setup lang="ts">
import { onErrorCaptured, onMounted } from 'vue'
import { useSnackbarMessage } from './store/snackbar'
import { getHumanError } from './utils/humanErrors'
import { manualRoutes } from './config/manualRoutes'
import { useMarketStore } from './store/marketStore'
import { useRouteStore } from '@/store/routeStore'
import { useFiatPriceStore } from '~/store/fiatPriceStore'
const { fetchMarkets } = useMarketStore()
const fiatPriceStore = useFiatPriceStore()

const routeStore = useRouteStore()
const snackbar = useSnackbarMessage()

onMounted(() => {
  routeStore.initRoutes()
  // prefetch fiat prices for better UX
  fiatPriceStore.fetchNewFiatPricesNow(manualRoutes, false)
  fetchMarkets({ pageSize: 50 })
})

// (err, instance, info)
onErrorCaptured((err) => {
  snackbar.addError({
    text: getHumanError(err.toString()),
    duration: 10000,
  })
  console.error(err)
  window.newrelic?.noticeError(err)
  return false
})

onMounted(() => {
  // Mava Widget is only visible with the docked devtools closed
  const mava = document.createElement('script')
  mava.src = 'https://widget.mava.app'
  mava.defer = true
  mava.id = 'MavaWebChat'
  mava.setAttribute('widget-version', 'v2')
  mava.setAttribute('enable-sdk', 'false')
  mava.setAttribute(
    'data-token',
    '46e424a016c1f3b4c812017fa3e3eed1b884da922dc60091191f48f559e43e2d',
  )
  mava.async = true
  document.body.appendChild(mava)
})
</script>
