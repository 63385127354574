import {
  BigIntArithmetics,
  Chain,
  type WalletChain as SwapkitWalletChain,
  WalletOption,
} from '@swapkit/helpers'
import { acceptHMRUpdate, defineStore } from 'pinia'
import type { AssetAndAmount, WalletDetailsResponse } from 'rango-types/lib/api/shared/balance'
import { useRuntimeConfig } from 'nuxt/app'
import { useFiatPriceStore } from './fiatPriceStore'
import { swapkitClient } from '~/clients/swapkit'
import { useRangoClient } from '~/composables/rango'
import {
  getMAYALiquidityPositions,
  getSaverDetails,
  getTHORLiquidityPositions,
  isEvmChain,
  isSwapkitChain,
  isXdefiChain,
} from '~/utils/swapkit'
import {
  type ChainWallet,
  type ConnectSwapkitWalletConfig,
  type KeystoreEvmWallet,
  type MetamaskWallet,
  type SolanaAsset,
  type SolanaAssetProps,
  type SolanaWallet,
  type SwapkitChainWallet,
  type WalletChain,
  type WalletType,
  type XdefiEvmWallet,
  connectSwapkitWallet,
} from '~/wallets/swapkit'
import { RangoChain } from '~/types/rango'
import { getSynthSaveAssetId } from '~/utils/main'
import type { LiquidityPosition, SaverDetail } from '~/types/earn'

export type SwapkitWalletStore = {
  wallets: {
    [K in Exclude<SwapkitWalletChain, Chain.Ethereum | Chain.Arbitrum>]?: SwapkitChainWallet
  } & {
    [K in RangoChain.Solana]?: SolanaWallet
  } & {
    [K in Chain.Ethereum | Chain.Arbitrum]?:
      | SwapkitChainWallet
      | KeystoreEvmWallet
      | XdefiEvmWallet
      | MetamaskWallet
  }
  // pools: Pool[]
  lps: { [K in 'thorchain' | 'mayachain']: LiquidityPosition[] }
  savers: { [K in 'thorchain']: SaverDetail[] }
  isLoadingBalances: boolean
  onConnectHandlers: Array<(wallets: SwapkitWalletStore['wallets']) => void>
  stopPollingBalances: ReturnType<typeof setTimeout> | null
}
const initialState: SwapkitWalletStore = {
  wallets: {},
  // pools: [], // TODO if code is unused, remove it
  lps: { mayachain: [], thorchain: [] },
  savers: { thorchain: [] },
  isLoadingBalances: false,
  onConnectHandlers: [],
  stopPollingBalances: null,
}

const getWalletName = (walletType: WalletOption) => {
  return walletType.charAt(0).concat(walletType.slice(1).replaceAll('_', ' ').toLowerCase())
}
type WalletsByType = { type: WalletOption; name: string; wallets: ChainWallet[] }
const getConnectedWallets = (state: SwapkitWalletStore) => {
  return Object.values(state.wallets).reduce<{
    indexes: { [K in WalletOption]?: number }
    wallets: WalletsByType[]
  }>(
    ({ indexes, wallets }, wallet) => {
      const index = indexes[wallet.walletType] ?? wallets.length
      const walletsByType: WalletsByType | undefined = wallets.at(index)
      return {
        indexes: { ...indexes, [wallet.walletType]: index },
        wallets: wallets.toSpliced(
          index,
          1,
          (walletsByType && {
            ...walletsByType,
            wallets: walletsByType.wallets.concat(wallet),
          }) ?? {
            type: wallet.walletType,
            name: getWalletName(wallet.walletType),
            wallets: [wallet],
          },
        ),
      }
    },
    { indexes: {}, wallets: [] },
  ).wallets
}
/**
 * @example 0.45
 */
export const useSwapkitWalletStore = defineStore('swapkitWallets', {
  state: () => initialState,
  getters: {
    listWallets: (state) => Object.values(state.wallets),
    isCCMember: (state) => {
      const CC_ASSET_ID = useRuntimeConfig().public.CC_ASSET_ID as string
      const CC_AMOUNT = useRuntimeConfig().public.CC_AMOUNT as string

      if (!CC_ASSET_ID || !CC_AMOUNT) {
        const error = `Missing CC_ASSET_ID: ${CC_ASSET_ID} or CC_AMOUNT: ${CC_AMOUNT}`
        window.newrelic?.noticeError(error)
        console.error(error)
        return false
      }
      const ccTokenAddress = CC_ASSET_ID.split('-')[1]
      if (!ccTokenAddress) {
        const error = `Invalid CC_ASSET_ID: ${CC_ASSET_ID}`
        window.newrelic?.noticeError(error)
        console.error(error)
        return false
      }
      const ccAmount = Number(CC_AMOUNT)
      if (isNaN(ccAmount)) {
        const error = `Invalid CC_AMOUNT: ${CC_AMOUNT}`
        window.newrelic?.noticeError(error)
        console.error(error)
        return false
      }
      const ccBalance = state.wallets[RangoChain.Solana]?.balance
        .find((asset) => asset.address?.toUpperCase() === ccTokenAddress.toUpperCase())
        ?.getValue('number')

      const isMember = ccBalance && ccBalance >= ccAmount
      return isMember
    },
    connectedWallets: getConnectedWallets,
    lpsAsArray: (state) => Object.values(state.lps),
    isWalletConnected: (state) => Object.keys(state.wallets).length > 0,
    isWalletTypeConnected:
      (state) =>
      (walletType: WalletOption): boolean =>
        !!Object.values(state.wallets).find((wallet) => wallet.walletType === walletType),
    isChainConnected: (state) => (chain: WalletChain) => !!state.wallets[chain],
    doesWalletSupportChain:
      () =>
      (wallet: WalletType, chain: WalletChain): boolean => {
        switch (wallet) {
          case WalletOption.PHANTOM:
            return chain === RangoChain.Solana
          case WalletOption.XDEFI:
            return isXdefiChain(chain)
          case WalletOption.METAMASK:
            return isEvmChain(chain)
          default:
            return isSwapkitChain(chain)
        }
      },
    hasBalances: (state) => Object.values(state.wallets).some((wallet) => !!wallet.balance.length),
    hasBalancesLgZero: (state) =>
      Object.values(state.wallets).some((wallet) =>
        wallet.balance.some((asset) => asset.bigIntValue > BigInt(0)),
      ),
  },
  actions: {
    pollBalances() {
      this.stopPollingBalances = setTimeout(() => {
        this.fetchBalances(this.listWallets).then(() => {
          this.pollBalances()
        })
      }, 20000)
    },
    assetAndAmountToSolanaAsset({
      asset,
      amount: { amount, decimals },
    }: AssetAndAmount): SolanaAsset {
      const walletAsset: SolanaAsset = Object.assign<BigIntArithmetics, SolanaAssetProps>(
        new BigIntArithmetics({
          value: amount,
          decimal: decimals,
          decimalMultiplier: decimals,
        } as { decimal?: number; value: number | string }), // * patches missing decimalMultiplier type definition
        {
          address: asset.address ?? undefined,
          chain: RangoChain.Solana,
          isGasAsset: !asset.address,
          isSynthetic: false,
          symbol: asset.address ? `${asset.symbol}-${asset.address}` : asset.symbol,
          ticker: asset.symbol,
          type: RangoChain.Solana,
          chainId: '900',
          toUrl: () => `${asset.blockchain}.${asset.symbol}`.toUpperCase(),
        },
      )
      return walletAsset
    },
    walletDetailsToSolanaWallet(
      walletDetails: WalletDetailsResponse,
    ): Omit<SolanaWallet, 'walletType' | 'provider'> | undefined {
      const wallet = walletDetails.wallets.find((wallet) => {
        return wallet.blockChain === 'SOLANA'
      })
      if (!wallet) {
        return
      }
      return {
        chain: RangoChain.Solana,
        address: wallet.address,
        balance:
          wallet.balances?.map((balance) => {
            return this.assetAndAmountToSolanaAsset({
              ...balance,
              asset: {
                ...balance.asset,
                address: balance.asset.address,
              },
            })
          }) ?? [],
      }
    },
    async connectWallet(config: ConnectSwapkitWalletConfig): Promise<ChainWallet[]> {
      this.isLoadingBalances = true
      const wallets = await connectSwapkitWallet(config).catch((e) => {
        this.isLoadingBalances = false

        throw e
      })

      const chainWallets = await this.fetchBalances(wallets).then(() => {
        const { startPollingFiatPrices, fetchNewFiatPricesNow } = useFiatPriceStore()

        const ids = Object.values(this.wallets).flatMap((wallet) =>
          wallet.balance.map(({ chain, symbol, ticker, address }) =>
            getSynthSaveAssetId({ chain, symbol, ticker, address }),
          ),
        )

        this.pollBalances()

        startPollingFiatPrices()

        return fetchNewFiatPricesNow(ids).then(() => {
          return wallets
        })
      })
      this.onConnectHandlers.forEach((onConnect) => {
        onConnect(this.wallets)
      })
      return chainWallets
    },
    fetchBalances(wallets: ChainWallet[]): Promise<ChainWallet[]> {
      if (!this.isLoadingBalances) {
        this.isLoadingBalances = true
      }
      this.fetchLiquidityPositions(wallets)
      const rangoClient = useRangoClient()
      return Promise.all(
        wallets.map((wallet) => {
          if (wallet.chain === RangoChain.Solana) {
            return rangoClient
              .balance({
                address: wallet.address,
                blockchain: RangoChain.Solana,
              })
              .then((walletDetails) => {
                const solanaWallet = this.walletDetailsToSolanaWallet(walletDetails)
                if (!solanaWallet) {
                  throw new Error('Failed to fetch wallet: Missing Solana wallet details')
                }
                const walletWithBalance = {
                  ...wallet,
                  ...solanaWallet,
                }
                this.wallets[wallet.chain] = walletWithBalance
                return walletWithBalance
              })
              .catch(() => {
                window.newrelic?.noticeError(
                  `Failed to fetch balance for wallet ${wallet.walletType}`,
                )
                this.wallets[wallet.chain] = wallet // * set wallet without balances
                return wallet
              })
          }
          return swapkitClient
            .getWalletWithBalance(wallet.chain)
            .then(({ balance }) => {
              const walletsState: Record<string, ChainWallet> = this.wallets
              // ! preserve additional fields like 'wallet' or 'provider'
              const walletWithBalance = {
                ...wallet,
                balance,
              }
              walletsState[wallet.chain] = walletWithBalance
              return walletWithBalance
            })
            .catch(() => {
              window.newrelic?.noticeError(
                `Failed to fetch balance for wallet ${wallet.walletType}`,
              )
              const walletsState: Record<string, ChainWallet> = this.wallets
              walletsState[wallet.chain] = wallet // * set wallet without balances
              return wallet
            })
        }),
      ).then((results) => {
        this.isLoadingBalances = false
        return results
      })
    },
    fetchLiquidityPositions(wallets: ChainWallet[]) {
      const addresses = Array.from(new Set(wallets.map((wallet) => wallet.address)))
      getMAYALiquidityPositions(addresses)
        .then((mayachainLiquidityPositions) => {
          this.lps.mayachain = mayachainLiquidityPositions ?? []
        })
        .catch((e) => {
          this.lps.mayachain = []
          console.error('Failed to fetch MayaChain liquidity positions', e)
        })

      getTHORLiquidityPositions(addresses)
        .then((thorchainLiquidityPositions) => {
          this.lps.thorchain = thorchainLiquidityPositions ?? []
        })
        .catch((e) => {
          this.lps.thorchain = []
          console.error('Failed to fetch ThorChain liquidity positions', e)
        })

      getSaverDetails(addresses)
        .then((saverDetails) => {
          this.savers.thorchain = saverDetails ?? []
        })
        .catch((e) => {
          this.savers.thorchain = []
          console.error('Failed to fetch ThorChain savers', e)
        })
    },
    onConnect(onConnect: (wallets: SwapkitWalletStore['wallets']) => void) {
      this.onConnectHandlers.push(onConnect)
    },
    resetWalletState() {
      const { stopPollingFiatPrices } = useFiatPriceStore()
      if (stopPollingFiatPrices) clearTimeout(stopPollingFiatPrices)
      if (this.stopPollingBalances) clearTimeout(this.stopPollingBalances)
      // TODO disconnect swapkit wallet
      this.wallets = {}
      this.lps = { mayachain: [], thorchain: [] }
      this.savers = { thorchain: [] }
      localStorage.removeItem('lastWallet')
      localStorage.removeItem('connectChains')
    },
    disconnectWallet(walletType: ChainWallet['walletType']) {
      this.wallets = this.listWallets.reduce((wallets, wallet) => {
        if (wallet.walletType === walletType) {
          return wallets
        }
        return {
          ...wallets,
          [wallet.chain]: wallet,
        }
      }, {})
      localStorage.removeItem('lastWallet')
      localStorage.removeItem('connectChains')
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSwapkitWalletStore, import.meta.hot))
}
