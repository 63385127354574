import { coingeckoIdsByAddress } from '~/scripts/output/coingeckoIdsByAddress'
import type { AssetPlatform } from '~/types/coingecko'

// type CoingeckoMap = {
//   coingeckoToAssetIds: Record<string, string>
//   assetIdsToCoingecko: Record<string, string>
// }

// const chainToCoingeckoPlatform: Record<string, string> = {
//   ARB: 'arbitrum-one',
//   AVAX: 'avalanche-2',
//   BCH: 'bitcoin-cash',
//   BNB: 'binancecoin',
//   BSC: 'binance-smart-chain',
//   BTC: 'bitcoin',
//   DASH: 'dash',
//   DOGE: 'dogecoin',
//   DOT: 'polkadot',
//   ETH: 'ethereum',
//   FLIP: 'Chainflip', // TODO coingecko has no Chainflip platform
//   GAIA: 'cosmos',
//   KUJI: 'kujira',
//   LTC: 'litecoin',
//   MATIC: 'polygon-pos',
//   MAYA: 'Maya', // TODO coingecko has no Maya platform
//   OP: 'optimistic-ethereum',
//   THOR: 'thorchain',
// }

// TODO check assetIds against coingeckoIds during compile time to avoid runtime errors
const hardCodedCoingeckoIds: Record<string, string> = {
  ETH: 'ethereum',
  KUJI: 'kujira',
  BNB: 'binancecoin',
  // MATIC: 'matic-network', // TODO re-enable once matic is supported
  CACAO: 'cacao',
  USK: 'usk',
  RUNE: 'thorchain',
  AVAX: 'avalanche-2',
  LTC: 'litecoin',
  DASH: 'dash',
  DOGE: 'dogecoin',
  BTC: 'bitcoin',
  BCH: 'bitcoin-cash',
  DOT: 'polkadot',
  ATOM: 'cosmos',
  OP: 'optimistic-ethereum',
  FLIP: 'chainflip',
  SOL: 'solana',
}

export const CoingeckoIdToNative = Object.entries(hardCodedCoingeckoIds).reduce(
  (entry, [key, value]) => {
    entry[value] = key
    return entry
  },
  {} as Record<string, string>,
)

export const CoingeckoIdToAddress = Object.entries(coingeckoIdsByAddress).reduce(
  (entry, [key, value]) => {
    entry[value] = key
    return entry
  },
  {} as Record<string, string>,
)

export function getCoingeckoIds(
  assetIds: Array<string>,
): { coingeckoId: string; assetId: string }[] {
  return assetIds.map((assetId) => {
    const deSynthedAssetId = assetId.includes('/')
      ? assetId.split('.')[1].replace('/', '.')
      : assetId
    const [, symbolAddress] = deSynthedAssetId.split('.')
    const [symbol] = symbolAddress.split('-')

    // Handles native tokens without address
    const coingeckoIdByHardcodedSymbol = hardCodedCoingeckoIds[symbol.toUpperCase()] ?? null

    // More specific if symbols collide, prefer this
    const coingeckoIdByHardcodedSymbolAddress =
      hardCodedCoingeckoIds[symbolAddress.toUpperCase()] ?? null

    // Most specific, prefer this
    const coingeckoIdByApiSymbolAddress =
      (coingeckoIdsByAddress as Record<string, string>)[symbolAddress.toUpperCase()] ?? null

    const coingeckoId =
      coingeckoIdByApiSymbolAddress ??
      coingeckoIdByHardcodedSymbolAddress ??
      coingeckoIdByHardcodedSymbol

    return { coingeckoId, assetId }
  }) as {
    coingeckoId: string
    assetId: string
  }[]
}

export async function getAssetPlatforms(): Promise<AssetPlatform[]> {
  const url = `https://node.eldorado.market/prices/api/v3/asset_platforms`
  const options = {
    method: 'GET',
    headers: { accept: 'application/json' },
  }

  try {
    const res = await fetch(url, options)
    const json = await res.json()
    if (json.error) {
      throw new Error(json.error)
    }
    return json
  } catch (err: any) {
    console.error('error:' + err)
    throw new Error(`Failed to fetch coingecko platforms: ${err.message ?? err}`)
  }
}

export const CoingeckoPlatformToChainMap = {
  ethereum: 'ETH',
  solana: 'SOLANA',
  'arbitrum-one': 'ARB',
  thorchain: 'THOR',
  avalanche: 'AVAX',
  'binance-smart-chain': 'BSC',
  kujira: 'KUJI',
  mayachain: 'MAYA',
  bitcoin: 'BTC',
  polkadot: 'DOT',
  dash: 'DASH',
  'bitcoin-cash': 'BCH',
  binancecoin: 'BNB',
  dogechain: 'DOGE',
  cosmos: 'GAIA',
  ltc: 'LTC',
}

export const ChainToCoingeckoPlatformMap = Object.entries(CoingeckoPlatformToChainMap).reduce(
  (acc, [key, value]) => {
    acc[value] = key
    return acc
  },
  {} as Record<string, string>,
)
