/* eslint-disable no-console */
import { defineNuxtPlugin } from 'nuxt/app'
import { isProxy, isRef, toRaw } from 'vue'

export default defineNuxtPlugin(() => {
  function unpackArg(arg: any) {
    if (isRef(arg)) {
      return toRaw(arg.value)
    } else if (isProxy(arg)) {
      return toRaw(arg)
    }
    return arg
  }

  // Custom logging function
  function customLog(...args: any[]) {
    const processedArgs = args.map((arg: any) => {
      if (Array.isArray(arg)) {
        return arg.map(unpackArg)
      } else {
        return unpackArg(arg)
      }
    })
    console.originalLog(...processedArgs)
  }

  // Overwrite console.log
  console.originalLog = console.log
  console.log = customLog
})
