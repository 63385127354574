import { useRuntimeConfig } from 'nuxt/app'
import { RangoClient } from 'rango-sdk-basic'

export const useRangoClient = () => {
  const runtimeConfig = useRuntimeConfig()
  return new RangoClient('', false, runtimeConfig.public.RANGO_API_URL)
}

export const useRangoReferrers = () => {
  const runtimeConfig = useRuntimeConfig()
  return {
    ETH: runtimeConfig.public.RANGO_REFERRER_ETH,
    KUJIRA: runtimeConfig.public.RANGO_REFERRER_KUJIRA,
    MAYA: runtimeConfig.public.RANGO_REFERRER_MAYA,
    THOR: runtimeConfig.public.RANGO_REFERRER_THOR,
  }
}
