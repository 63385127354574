// stores/marketStore.ts

import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Coin, MarketsResponse } from '~/types/coingecko'
interface MarketCache {
  [key: string]: Array<MarketsResponse & { meta?: Coin }>
}

export const useMarketStore = defineStore('marketStore', () => {
  const marketCache = ref<MarketCache>({})

  async function fetchMarkets({
    coingeckoIds,
    category,
    pageSize,
    page,
  }: {
    coingeckoIds?: string[]
    category?: string
    pageSize?: number
    page?: number
  }): Promise<MarketsResponse[]> {
    page = page ?? 1
    pageSize = pageSize ?? 250
    coingeckoIds = coingeckoIds ?? []
    category = category === '_all' ? undefined : category

    const cacheKey = `${category || '_all'}`
    const ids = coingeckoIds.length > 0 ? `&ids=${coingeckoIds.join(',')}` : ''
    const categoryParam = category ? `&category=${category}` : ''
    const pageParam = `&page=${page}`
    const url = `https://node.eldorado.market/prices/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=${pageSize}&sparkline=true&price_change_percentage=1h%2C24h%2C7d&precision=2${ids}${categoryParam}${pageParam}`
    const options = {
      method: 'GET',
      headers: { accept: 'application/json' },
    }

    try {
      const res = await fetch(url, options)
      const json: MarketsResponse[] & { error?: any } = await res.json()
      if (json.error) {
        throw new Error(json.error)
      }
      if (marketCache.value[cacheKey] === undefined) {
        marketCache.value[cacheKey] = json
        return json
      }
      const existingIds = new Set(marketCache.value[cacheKey].map((item) => item.id))

      const newData = json.filter((item) => !existingIds.has(item.id))

      marketCache.value[cacheKey] = [...marketCache.value[cacheKey], ...newData].sort(
        (a, b) => b.market_cap - a.market_cap,
      )

      return json
    } catch (err: any) {
      console.error('error:' + err)
      window.newrelic?.noticeError(err)
      throw new Error(`Failed to fetch market data: ${err.message ?? err}`)
    }
  }

  return {
    fetchMarkets,
    marketCache,
  }
})
