import { useRoute, useRouter, useRuntimeConfig } from 'nuxt/app'
import type { QuotePath, QuoteSimulationResult } from 'rango-types/lib/api/basic/common'
import type { QuoteRequest, QuoteResponse } from 'rango-types/lib/api/basic/routing'
import type {
  StatusResponse,
  SwapRequest,
  SwapResponse,
} from 'rango-types/lib/api/basic/transactions'
import { RangoClient } from 'rango-sdk-basic'
import { Chain, WalletOption } from '@swapkit/helpers'
import { acceptHMRUpdate, defineStore } from 'pinia'
import type { RouteLocationNormalizedLoaded } from 'vue-router'
import { useSwapkitWalletStore } from './swapkitWalletStore'
import { useSwapkitRouteStore } from './swapkitRouteStore'
import { type RouteEndPoint } from '~/types'
import { swapkitClient } from '~/clients/swapkit'
import { rangoSolanaTxToSolanaTx } from '~/wallets/solana'
import { allRoutesRango } from '~/scripts/output/allRoutesRango'
import {
  type ConnectedAssetResponse,
  type ConnectedChainWithAssets,
  RangoChain,
} from '~/types/rango'
import type { RangoStatusParams, StatusFees } from '~/utils/status'
import { hasMessage } from '~/utils/main'
import { checkTransactionStatusSync, prepareEvmTransaction } from '~/utils/rango'
import { intToBigNumber, intToFloat } from '~/utils/numbers'
import { useRangoReferrers } from '~/composables/rango'
import { isEvmChain } from '~/utils/swapkit'

type SwapAndStatusParams = {
  quote: QuoteSimulationResult
  requestId: SwapResponse['requestId']
  amountIn: string
  expectedOutputUsd: number
  fees: StatusFees
  toAddress: string
}
type StatusParams = SwapAndStatusParams & {
  hash: string | undefined
}
type SwapParams = SwapAndStatusParams & {
  tx: NonNullable<SwapResponse['tx']>
}
// const DEFAULT_EXCLUDED_SWAPPERS = ['Bridgers', 'SWFT']
const DEFAULT_EXCLUDED_SWAPPERS: string[] = []
const routeToSwappers = (
  route: RouteLocationNormalizedLoaded,
): { swappers: string[]; swappersExclude: boolean } => {
  const swappers = Array.isArray(route.query.swappers)
    ? route.query.swappers.filter((swapper): swapper is string => typeof swapper === 'string')
    : route.query.swappers?.split(',')
  const swappersExclude =
    (Array.isArray(route.query.swappersExclude)
      ? route.query.swappersExclude[0]
      : route.query.swappersExclude) !== 'false'
  return {
    swappers: swappers ?? DEFAULT_EXCLUDED_SWAPPERS,
    swappersExclude,
  }
}

// Initialize SDK
const rangoClient = new RangoClient('', false, 'https://node.eldorado.market/rango')
// Ethena $ENA (ETH): 0x57e114B691Db790C35207b2e685D4A43181e6061
// Alchemix $ALCX (ETH): 0xdbdb4d16eda451d0503b854cf79d55697f90c8df
// Yearn $YFI (ETH): 0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e
// AAVE $AAVE (ETH): 0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9
// MOG $MOG (ETH): 0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a
// JESUS $JESUS (ETH): 0xba386A4Ca26B85FD057ab1Ef86e3DC7BdeB5ce70

// LANA DEL RAY $LDR (SOL): H9XmJ3ot3MpawSMMxarGqX8rJaQGWd5WWZvnLU4PTwmC
// SWAG $SWAG (SOL): FaxYQ3LVXP51rDP2yWGLWVrFAAHeSdFF8SGZxwj2dvor
// RETARDIO $RETARDIO (SOL): 6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx
const routes: RouteEndPoint[] = allRoutesRango
export const useRangoRouteStore = defineStore('rangoRoute', {
  state: () => ({
    routes,
    chainMap: {
      ARBITRUM: 'ARB',
      AVAX_CCHAIN: 'AVAX',
      KUJIRA: 'KUJI',
      COSMOS: 'GAIA',
      ARB: 'ARBITRUM',
      AVAX: 'AVAX_CCHAIN',
      KUJI: 'KUJIRA',
      GAIA: 'COSMOS',
    } as { [key: string]: string },
    assetMap: {
      KUJI: 'KUJI.KUJI',
      CACAO: 'MAYA.CACAO',
      DASH: 'DASH.DASH',
      RUNE: 'THOR.RUNE',
      ukuji: 'KUJI.KUJI',
      BTC: 'BTC.BTC',
      'ETH.USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48':
        'USDC--0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48',
      'ETH.USDT-0XDAC17F958D2EE523A2206206994597C13D831EC7':
        'USDT--0XDAC17F958D2EE523A2206206994597C13D831EC7',
      'SOLANA.USDC--EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v':
        'USDC--EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
      // cspell:disable-next-line
      'SOLANA.ZYN--PzuaVAUH2tfxGZcbBR6kMxeJsBngnsPLFotGJNCtcsd':
        // cspell:disable-next-line
        'ZYN--PzuaVAUH2tfxGZcbBR6kMxeJsBngnsPLFotGJNCtcsd',
      'ETH.TRUMP--0x576e2bed8f7b46d34016198911cdf9886f78bea7':
        'TRUMP--0x576e2bed8f7b46d34016198911cdf9886f78bea7',
      'ETH.ZYN--0x58cb30368ceb2d194740b144eab4c2da8a917dcb':
        'ZYN--0x58cb30368ceb2d194740b144eab4c2da8a917dcb',
      'SOLANA.BONK--DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263':
        'BONK--DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263',
      'SOLANA.WIF--EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm':
        'WIF--EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm',
      'SOLANA.BOME--ukHH6c7mMyiWCf1b9pnWe25TSpkDDt3H5pQZgZ74J82':
        'BOME--ukHH6c7mMyiWCf1b9pnWe25TSpkDDt3H5pQZgZ74J82',
      'SOLANA.SLERF--7BgBvyjrZX1YKz4oh9mjb8ZScatkkwb8DzFx7LoiVkM3':
        'SLERF--7BgBvyjrZX1YKz4oh9mjb8ZScatkkwb8DzFx7LoiVkM3',
      'ETH.PEPE--0X6982508145454CE325DDBE47A25D4EC3D2311933':
        'PEPE--0X6982508145454CE325DDBE47A25D4EC3D2311933',
      'SOLANA.BODEN--3psH1Mj1f7yUfaD5gh6Zj7epE8hhrMkMETgv5TshQA4o':
        'BODEN--3psH1Mj1f7yUfaD5gh6Zj7epE8hhrMkMETgv5TshQA4o',
      'ETH.PEPE-0X6982508145454CE325DDBE47A25D4EC3D2311933':
        'PEPE--0X6982508145454CE325DDBE47A25D4EC3D2311933',
      'SOLANA.KENIDY--Bg9CZr1CmVoCn2uNWwj9f5rLbmfYRYvcVikCRCwawUwR':
        'KENIDY--Bg9CZr1CmVoCn2uNWwj9f5rLbmfYRYvcVikCRCwawUwR',
      'SOLANA.TREN--HLnTNCG5RD7jYVduFc1pMCHiuApoWGn9LveqEFanQFZb':
        'TREN--HLnTNCG5RD7jYVduFc1pMCHiuApoWGn9LveqEFanQFZb',
      'ETH.APU--0x594daad7d77592a2b97b725a7ad59d7e188b5bfa':
        'APU--0x594daad7d77592a2b97b725a7ad59d7e188b5bfa',
      'ETH.ENA--0x57e114B691Db790C35207b2e685D4A43181e6061':
        'ENA--0x57e114B691Db790C35207b2e685D4A43181e6061',
      'ETH.ALCX--0xdbdb4d16eda451d0503b854cf79d55697f90c8df':
        'ALCX--0xdbdb4d16eda451d0503b854cf79d55697f90c8df',
      'ETH.YFI--0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e':
        'YFI--0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
      'ETH.AAVE--0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9':
        'AAVE--0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
      'ETH.MOG--0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a':
        'MOG--0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a',
      'ETH.JESUS--0xba386A4Ca26B85FD057ab1Ef86e3DC7BdeB5ce70':
        'JESUS--0xba386A4Ca26B85FD057ab1Ef86e3DC7BdeB5ce70',
      'SOLANA.LDR--H9XmJ3ot3MpawSMMxarGqX8rJaQGWd5WWZvnLU4PTwmC':
        'LDR--H9XmJ3ot3MpawSMMxarGqX8rJaQGWd5WWZvnLU4PTwmC',
      'SOLANA.SWAG--FaxYQ3LVXP51rDP2yWGLWVrFAAHeSdFF8SGZxwj2dvor':
        'SWAG--FaxYQ3LVXP51rDP2yWGLWVrFAAHeSdFF8SGZxwj2dvor',
      // cspell:disable-next-line
      'SOLANA.RETARDIO--0x6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx':
        // cspell:disable-next-line
        'RETARDIO--0x6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx',

      //   USDC: 'ETH.USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48',
      //   FLIP: 'ETH.FLIP-0x826180541412D574cf1336d22c0C0a287822678A',
      //   DOT: 'DOT.DOT',
    } as { [key: string]: string },
  }),
  actions: {
    async fetchOutputRoutes(assetId: string): Promise<ConnectedChainWithAssets[]> {
      try {
        const rangoAssetId = assetId.replace('-', '--')
        const res = await fetch(
          `https://node.eldorado.market/rango/basic/connected-assets?from=${rangoAssetId}`,
        )
        const json = (await res.json()) as ConnectedAssetResponse
        return json.data
      } catch (error) {
        const title = 'Failed to fetch Rango output routes'
        const errorMessage =
          (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
        throw new Error(errorMessage || title)
      }
    },
    fetchQuote(
      poolIn: RouteEndPoint,
      poolOut: RouteEndPoint,
      amount: string,
    ): Promise<QuoteResponse> {
      const route = useRoute()
      const swapkitRouteStore = useSwapkitRouteStore()
      const referrerFee = swapkitRouteStore.referrerFeePercentage
      try {
        const blockchainIn = this.chainMap[poolIn.chain] || poolIn.chain
        const blockchainOut = this.chainMap[poolOut.chain] || poolOut.chain

        const quoteRequest: QuoteRequest & {
          referrerFee?: string
          enableCentralizedSwappers: boolean
        } = {
          from: {
            blockchain: blockchainIn,
            symbol: poolIn.assetName,
            address: poolIn.assetAddress,
          },
          to: {
            blockchain: blockchainOut,
            symbol: poolOut.assetName,
            address: poolOut.assetAddress,
          },
          amount,
          ...(referrerFee ? { referrerFee: referrerFee.toString() } : null),
          enableCentralizedSwappers: true,
          ...routeToSwappers(route),
        }
        return rangoClient.quote(quoteRequest)
      } catch (error) {
        const title = 'Failed to fetch Rango quote'
        const errorMessage =
          (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
        throw new Error(errorMessage || title)
      }
    },
    fetchStatus({ hash, statusId }: { hash: string; statusId: string }): Promise<StatusResponse> {
      return checkTransactionStatusSync(statusId, hash, rangoClient)
    },
    navigateToStatus({
      amountIn,
      expectedOutputUsd,
      fees,
      hash,
      quote,
      requestId,
      toAddress,
    }: StatusParams) {
      const router = useRouter()

      const fromAssetAddress = quote.from.address
      const toAssetAddress = quote.to.address

      const fromBlockchain = quote.from.blockchain
      const toBlockchain = quote.to.blockchain

      const fromSymbol = quote.from.symbol
      const toSymbol = quote.to.symbol
      const quotePath = quote.path?.at(0) as (QuotePath & { inputAmount: string }) | undefined
      const inAmount = quotePath?.inputAmount
        ? intToBigNumber(quotePath.inputAmount, quote.from.decimals).toString()
        : amountIn

      const outAmount = intToFloat(quote.outputAmount, quote.to.decimals)
      const outputAmountMin = intToBigNumber(quote.outputAmountMin, quote.to.decimals).toString()
      const outputAmountUsd = quote.outputAmountUsd ?? expectedOutputUsd

      const fromAsset = fromAssetAddress
        ? `${fromBlockchain}.${fromSymbol}-${fromAssetAddress}`
        : `${fromBlockchain}.${fromSymbol}`
      const toAsset = toAssetAddress
        ? `${toBlockchain}.${toSymbol}-${toAssetAddress}`
        : `${toBlockchain}.${toSymbol}`

      const startMs = Date.now()
      const query: RangoStatusParams = {
        openTxTrackerModal: 'true',
        estimatedTimeS: quote?.path
          ? quote?.path.reduce((acc, path) => acc + path.estimatedTimeInSeconds, 0)
          : 0,
        feesAffiliateUsd: fees.affiliateUsd,
        feesProtocolUsd: fees.protocolUsd,
        feesTotalUsd: fees.totalUsd,
        fromAsset,
        hash: hash ?? null,
        inAmount,
        outAmount,
        outputAmountMin,
        outputAmountUsd,
        startMs,
        statusId: requestId,
        swapper: quote.swapper.title ?? '',
        swapperIcon: quote.swapper.logo ?? '',
        toAddress,
        toAsset,
      }
      router.push({ query })
    },
    async submitSwap({ tx, ...params }: SwapParams): Promise<string> {
      const { wallets } = useSwapkitWalletStore()

      // Handle sign and broadcast for the different blockchain types
      if (tx.type === 'EVM' && isEvmChain(tx.blockChain.name)) {
        const evmTransaction = tx

        // main transaction
        const mainTx = prepareEvmTransaction(evmTransaction, false)
        const chain = tx.blockChain.name
        if (
          chain === Chain.Arbitrum &&
          wallets[chain]?.walletType === WalletOption.KEYSTORE &&
          'provider' in wallets[chain]
        ) {
          const hash = (await wallets[chain].provider.signAndSendTransaction(mainTx)).hash
          this.navigateToStatus({ ...params, hash })
          return hash
        }
        if (
          chain === Chain.Ethereum &&
          wallets[chain]?.walletType === WalletOption.KEYSTORE &&
          'provider' in wallets[chain]
        ) {
          const hash = (await wallets[chain].provider.signAndSendTransaction(mainTx)).hash
          this.navigateToStatus({ ...params, hash })
          return hash
        }
        const hash = await swapkitClient.getWallet(chain).EIP1193SendTransaction(mainTx)
        this.navigateToStatus({ ...params, hash })
        return hash
      }

      if (tx.type === 'SOLANA') {
        const solanaWallet = wallets[RangoChain.Solana]
        if (!solanaWallet) {
          throw new Error('Unable to sign and send Solana transaction, missing Solana wallet')
        }
        const solanaTx = rangoSolanaTxToSolanaTx(tx)
        if (!solanaTx) {
          throw new Error('Failed to create transaction due to missing message')
        }
        const hash = (await solanaWallet.provider.signAndSendTransaction(solanaTx)).signature
        this.navigateToStatus({ ...params, hash })
        return hash
      }
      throw new Error('Unsupported transaction type')
    },
    swap(
      swapParams: Pick<
        SwapRequest,
        'from' | 'to' | 'fromAddress' | 'toAddress' | 'amount' | 'slippage'
      >,
    ) {
      const runtimeConfig = useRuntimeConfig()
      const route = useRoute()
      const swapkitWalletStore = useSwapkitRouteStore()
      const referrerFee = swapkitWalletStore.referrerFeePercentage
      const rangoReferrers: { [K in string]?: string | undefined } = useRangoReferrers()
      const referrerAddress = rangoReferrers[swapParams.from.blockchain]
      const referrerCode = runtimeConfig.public.RANGO_CODE
      const swapParameters: SwapRequest & {
        enableCentralizedSwappers: boolean
        enableCentralized: boolean
        referrerCode?: string
      } = {
        ...swapParams,
        disableEstimate: true,
        enableCentralizedSwappers: true,
        enableCentralized: true,
        ...(referrerAddress && referrerFee
          ? {
              referrerCode,
              referrerAddress,
              referrerFee: referrerFee.toString(),
            }
          : null),
        ...routeToSwappers(route),
      }

      return rangoClient.swap(swapParameters)
    },
  },
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRangoRouteStore, import.meta.hot))
}
