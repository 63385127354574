import type { AssetSelectorData } from '~/types/route'

export type IndexesAndItems<G> = {
  indexes: { [key in string]?: number }
  groups: G[]
}
export const groupReduce = <I, G>(
  items: I[],
  getKey: (item: I) => string,
  reduce: (item: I, group?: G) => G,
): G[] => {
  const initialIndexesAndItems: IndexesAndItems<G> = { indexes: {}, groups: [] }
  return items.reduce(({ indexes, groups }, item) => {
    const key = getKey(item)
    const index = indexes[key] ?? groups.length
    const group = reduce(item, groups[index])

    return {
      indexes: { ...indexes, [key]: index },
      groups: groups.toSpliced(index, 1, group),
    }
  }, initialIndexesAndItems).groups
}

export const sortOrder = ['BTC', 'ETH', 'ARB', 'SOLANA', 'SOL', 'THOR', 'RUNE', 'MAYA', 'CACAO']

export const sortCuratedRoutes = (a: AssetSelectorData, b: AssetSelectorData) => {
  const indexA = sortOrder.indexOf(a.chain)
  const indexB = sortOrder.indexOf(b.chain)
  if (indexA === -1 && indexB === -1) return a.chain.localeCompare(b.chain)
  if (indexA === -1) return 1
  if (indexB === -1) return -1
  return indexA - indexB
}
