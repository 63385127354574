export const stateTypes = {
  Loading: 'loading',
  Loaded: 'loaded',
  Error: 'error',
  Initial: 'initial',
} as const
export type StateTypes = typeof stateTypes
export type StateType = keyof StateTypes
export type LoadingState = {
  state: StateTypes['Loading']
}

export type ErrorState = {
  state: StateTypes['Error']
  errorMsg: string
}

export type LoadedState<T> = {
  state: StateTypes['Loaded']
  data: T
}

export type State<T> = LoadingState | ErrorState | LoadedState<T>
