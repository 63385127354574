import type { LocationQueryValue } from 'vue-router'

export function debounce<P extends unknown[]>(fn: (...p: P) => unknown, delay: number) {
  let timeoutId: number | undefined
  return function (...args: P) {
    if (timeoutId) clearTimeout(timeoutId)
    timeoutId = window.setTimeout(() => fn(...args), delay)
  }
}

export const generateNonce = (): number => Date.now()

export const hasMessage = (value: unknown): value is { message: string } =>
  typeof value === 'object' && !!value && 'message' in value && typeof value.message === 'string'

export const getAssetId = (chain: string, symbol: string, address: string | undefined | null) =>
  address ? `${chain}.${symbol}-${address}`.toUpperCase() : `${chain}.${symbol}`.toUpperCase()

type GetSynthAssetIdParams = {
  chain: string
  symbol: string
  ticker: string
  address?: string
}
export const getSynthSaveAssetId = ({ chain, symbol, ticker, address }: GetSynthAssetIdParams) =>
  symbol.includes('/')
    ? `${chain}.${symbol}`.toUpperCase()
    : getAssetId(chain, ticker, address).toUpperCase()

export const parseParam = (param: LocationQueryValue | LocationQueryValue[]): string | null => {
  return Array.isArray(param) ? (param.at(0) ?? null) : param
}
