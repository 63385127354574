import { defineNuxtPlugin } from '#app'
import { PaydeceWidget } from 'paydece-widget'
import { useRouter } from 'vue-router'
import { watch } from 'vue'

export default defineNuxtPlugin((nuxtApp) => {
  let widgetInitialized = false

  const router = useRouter()

  watch(
    () => router.currentRoute.value.path,
    (newPath) => {
      if (newPath === '/p2p' && !widgetInitialized) {
        const widgetContainer = document.createElement('div')
        widgetContainer.id = 'global-paydece-widget'
        widgetContainer.style.display = 'none'

        document.body.appendChild(widgetContainer)

        PaydeceWidget.init({
          containerId: 'global-paydece-widget',
          width: 900,
          height: 600,
          apiKey: '963a5edc2e974219b0ed77a69224ca0c',
        })

        widgetInitialized = true
        nuxtApp.provide('paydeceWidgetContainer', widgetContainer)
      }
    },
    { immediate: true },
  )
})
