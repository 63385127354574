import type { QuoteResponseRoute } from '@swapkit/api'
import { createSwapKit } from '@swapkit/sdk'
import { AssetValue } from '@swapkit/helpers'

const config = {
  // cspell:disable
  config: {
    covalentApiKey: 'cqt_wFtXpfMCjX464ccMJmTBYrBhJVFY',
    ethplorerApiKey: 'EK-wXywJ-AZbsd9E-Y9ASw',
    chainflipBrokerUrl: 'https://node.eldorado.market/chainflip-broker',
    utxoApiKey: 'B___StgGFL49JFbhx9w3LYieY4uGwRkx', // * undocumented
  },
}
// cspell:enable

AssetValue.loadStaticAssets()
export const swapkitClient = createSwapKit(config)

export type SwapkitClient = typeof swapkitClient

export const swap = ({ route }: { route: QuoteResponseRoute }) => {
  return swapkitClient.swap({
    route,
  })
}
