import { PublicKey, Transaction, VersionedTransaction } from '@solana/web3.js'
import type { SolanaTransaction } from 'rango-types/lib/api/shared/txs/solana'

/**
 * @reference https://docs.rango.exchange/api-integration/basic-api-single-step/sample-transactions#solana-sample-transaction-test
 */
export const rangoSolanaTxToSolanaTx = (
  tx: SolanaTransaction,
): Transaction | VersionedTransaction | null => {
  if (!tx.serializedMessage) {
    return null
  }
  if (tx.txType === 'LEGACY') {
    const transaction = Transaction.from(Buffer.from(new Uint8Array(tx.serializedMessage)))
    transaction.feePayer = new PublicKey(tx.from)
    transaction.recentBlockhash = undefined
    return transaction
  }
  return VersionedTransaction.deserialize(new Uint8Array(tx.serializedMessage))
}
