import { RangoClient } from 'rango-sdk-basic'
import type { EvmTransaction } from 'rango-types/lib/api/basic/txs/evm'
import { z } from 'zod'
import { hasMessage } from './main'
import { allRoutesRango } from '~/scripts/output/allRoutesRango'

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const AssetIdToAddressMapRango: Record<string, string> = (
  allRoutesRango as RouteEndPoint[]
).reduce(
  (acc, route) => {
    if (route.assetId && route.assetAddress) {
      acc[route.assetId] = route.assetAddress
    }
    return acc
  },
  {} as Record<string, string>,
)

export const AddressToAssetIdMapRango: Record<string, string> = (
  allRoutesRango as RouteEndPoint[]
).reduce(
  (map, route) => {
    if (route.assetAddress) {
      map[route.assetAddress] = route.assetId
    }
    return map
  },
  {} as Record<string, string>,
)

// const allRangoChains = [...new Set(allTokensRango.map((item) => item.blockchain))]
export const allRangoChains = [
  'ETH',
  'BSC',
  'POLYGON',
  'ARBITRUM',
  'ZKSYNC',
  'OPTIMISM',
  'LINEA',
  'MODE',
  'AVAX_CCHAIN',
  'CELO',
  'TRON',
  'MOONBEAM',
  'OSMOSIS',
  'CRONOS',
  'AURORA',
  'STARKNET',
  'SCROLL',
  'MOONRIVER',
  'SOLANA',
  'POLYGONZK',
  'OKC',
  'JUNO',
  'BASE',
  'NOBLE',
  'BOBA',
  'BCH',
  'LTC',
  'CRYPTO_ORG',
  'INJECTIVE',
  'DYDX',
  'KUJIRA',
  'TERRA',
  'DASH',
  'BLAST',
  'METIS',
  'BANDCHAIN',
  'STRIDE',
  'SECRET',
  'PERSISTENCE',
  'IRIS',
  'SENTINEL',
  'EMONEY',
  'THOR',
  'NEUTRON',
  'COSMOS',
  'HECO',
  'CHIHUAHUA',
  'KONSTELLATION',
  'UMEE',
  'BITSONG',
  'REGEN',
  'STARGAZE',
  'BITCANNA',
  'COMDEX',
  'BTC',
  'LUMNETWORK',
  'KI',
  'AKASH',
  'DOGE',
  'MARS',
  'MAYA',
  'STARNAME',
  'DESMOS',
]

// const allSwapkitChains = [
//   'MAYA',
//   'BTC',
//   'ETH',
//   'DOT',
//   'KUJI',
//   'THOR',
//   'DASH',
//   'SOLANA',
//   'ARB',
//   'AVAX',
//   'BSC',
//   'BCH',
//   'BNB',
//   'DOGE',
//   'GAIA',
//   'LTC',
// ]

export const rangoSwapkitMap: { [key: string]: string } = {
  ARBITRUM: 'ARB',
  AVAX_CCHAIN: 'AVAX',
  KUJIRA: 'KUJI',
  COSMOS: 'GAIA',
  ARB: 'ARBITRUM',
  AVAX: 'AVAX_CCHAIN',
  KUJI: 'KUJIRA',
  GAIA: 'COSMOS',
}

export type EVMTxBaseParams<T = bigint> = {
  to?: string
  from?: string
  nonce?: number
  gasLimit?: T
  data?: string
  value?: T
  chainId?: T
}
export type EIP1559TxParams<T = bigint> = EVMTxBaseParams<T> & {
  type?: number
  maxFeePerGas?: T
  maxPriorityFeePerGas?: T
}
export function prepareEvmTransaction(evmTx: EvmTransaction, isApprove: boolean): EIP1559TxParams {
  const gasPrice =
    !!evmTx.gasPrice && !evmTx.gasPrice.startsWith('0x')
      ? '0x' + parseInt(evmTx.gasPrice).toString(16)
      : null
  const manipulatedTx = {
    ...evmTx,
    gasPrice,
  }

  /**
   * nonce will get populated by the wallet
   * @reference node_modules/ethers/src.ts/providers/abstract-signer.ts
   */
  const nonce = null as unknown as EIP1559TxParams['nonce']
  const tx = {
    nonce,
    ...(manipulatedTx.from ? { from: manipulatedTx.from } : null),
  }
  if (isApprove) {
    return {
      ...tx,
      ...(manipulatedTx.approveTo ? { approveTo: manipulatedTx.approveTo } : null),
      ...(manipulatedTx.approveData ? { approveTo: manipulatedTx.approveData } : null),
    }
  }
  return {
    ...tx,
    ...(manipulatedTx.txTo ? { to: manipulatedTx.txTo } : null),
    ...(manipulatedTx.txData ? { data: manipulatedTx.txData } : null),
    ...(manipulatedTx.value ? { value: BigInt(manipulatedTx.value) } : null),
    ...(manipulatedTx.gasLimit ? { gasLimit: BigInt(manipulatedTx.gasLimit) } : null),
    ...(manipulatedTx.gasPrice ? { gasPrice: BigInt(manipulatedTx.gasPrice) } : null),
  }
}

export async function checkApprovalSync(requestId: string, txId: string, rangoClient: RangoClient) {
  while (true) {
    const approvalResponse = await rangoClient.isApproved(requestId, txId)
    if (approvalResponse.isApproved) {
      return true
    }
    await sleep(3000)
  }
}

export async function checkTransactionStatusSync(
  requestId: string,
  txId: string,
  rangoClient: RangoClient,
) {
  try {
    const txStatus = await rangoClient.status({
      requestId,
      txId,
    })
    return txStatus

    // if (txStatus) {
    //   // show latest status of the transaction to the user
    //   if (
    //     !!txStatus.status &&
    //     [TransactionStatus.FAILED, TransactionStatus.SUCCESS].includes(txStatus.status)
    //   ) {
    //   }
    // }
    // deploy 2
  } catch (error) {
    const errorMessage =
      (typeof error === 'string' && error) ||
      (hasMessage(error) && error.message) ||
      'Failed to check transaction status'
    throw new Error(errorMessage)
  }
}

export function mayaDepositMemoForRango(
  memo: string | null,
  minOutAmountMayaStr: string,
  affiliateBps: number,
): string | null {
  if (memo === null) return null
  const rangoMemo = memo // The memo of transaction, can be null
  const parts = rangoMemo.split(':')
  const limitIntervalQuantity = parts[parts.length - 3].split('/')
  limitIntervalQuantity[0] = minOutAmountMayaStr
  parts[parts.length - 3] = limitIntervalQuantity.join('/')
  parts[parts.length - 2] = 'ELD'
  parts[parts.length - 1] = affiliateBps.toString()
  const eldoMemo = parts.join(':')

  return eldoMemo
}

// #region quote
export const rangoTokenSchema = z.object({
  blockchain: z.string(),
  chainId: z.string().nullable(),
  address: z.string().nullable(),
  symbol: z.string(),
  name: z.string().nullable(),
  decimals: z.number(),
  image: z.string(),
  blockchainImage: z.string(),
  usdPrice: z.number().nullable(),
  isPopular: z.boolean(),
})
export const rangoSwapperTypeSchema = z.enum(['BRIDGE', 'DEX', 'AGGREGATOR', 'OFF_CHAIN'])
export const rangoSwapperSchema = z.object({
  id: z.string(),
  title: z.string(),
  logo: z.string(),
  swapperGroup: z.string(),
  types: z.array(rangoSwapperTypeSchema),
})
export const rangoQuotePathSchema = z.object({
  from: rangoTokenSchema,
  to: rangoTokenSchema,
  swapper: rangoSwapperSchema,
  swapperType: rangoSwapperTypeSchema,
  expectedOutput: z.string(),
  estimatedTimeInSeconds: z.number(),
})
export const rangoExpenseTypeSchema = z.enum([
  'FROM_SOURCE_WALLET',
  'DECREASE_FROM_OUTPUT',
  'FROM_DESTINATION_WALLET',
])
export const rangoFeeSchema = z.object({
  name: z.string(),
  token: rangoTokenSchema,
  expenseType: rangoExpenseTypeSchema,
  amount: z.string(),
})
export const rangoAmountRestrictionTypeSchema = z.enum(['INCLUSIVE', 'EXCLUSIVE'])
export const rangoAmountRestrictionSchema = z.object({
  min: z.string().nullable(),
  max: z.string().nullable(),
  type: rangoAmountRestrictionTypeSchema,
})
export const rangoRouteSchema = z.object({
  from: rangoTokenSchema,
  to: rangoTokenSchema,
  outputAmount: z.string(),
  outputAmountMin: z.string(),
  outputAmountUsd: z.number().nullable(),
  swapper: rangoSwapperSchema,
  path: z.array(rangoQuotePathSchema),
  fee: z.array(rangoFeeSchema),
  feeUsd: z.number().nullable(),
  amountRestriction: rangoAmountRestrictionSchema.nullable(),
  estimatedTimeInSeconds: z.number(),
})
export const rangoResultTypeSchema = z.enum(['OK', 'HIGH_IMPACT', 'NO_ROUTE', 'INPUT_LIMIT_ISSUE'])
// #endregion
// #region tx
export const rangoNativeCurrencySchema = z.object({
  name: z.string(),
  symbol: z.string(),
  decimals: z.number(),
})
export const rangoAssetSchema = z.object({
  blockchain: z.string(),
  symbol: z.string(),
  address: z.string().nullable(),
})
export const rangoBlockchainMetaSchema = z.object({
  name: z.string(),
  shortName: z.string(),
  displayName: z.string(),
  defaultDecimals: z.number(),
  feeAssets: z.array(rangoAssetSchema),
  addressPatterns: z.array(z.string()),
  logo: z.string(),
  color: z.string(),
  sort: z.number(),
  enabled: z.boolean(),
  chainId: z.string().nullable(),
})
export const rangoTransactionSchema = z.object({
  blockChain: z.string(),
})

// #region EVM
export const rangoEVMChainInfoSchema = z.object({
  infoType: z.literal('EvmMetaInfo'),
  chainName: z.string(),
  nativeCurrency: rangoNativeCurrencySchema,
  rpcUrls: z.array(z.string()),
  blockExplorerUrls: z.array(z.string()),
  addressUrl: z.string(),
  transactionUrl: z.string(),
})
export const rangoEvmMetaSchema = rangoBlockchainMetaSchema.extend({
  type: z.literal('EVM'),
  chainId: z.string(),
  info: rangoEVMChainInfoSchema,
})
export const rangoEvmTransactionSchema = z.object({
  type: z.literal('EVM'),
  blockChain: rangoEvmMetaSchema,
  from: z.string().nullable(),
  approveTo: z.string().nullable(),
  approveData: z.string().nullable(),
  txTo: z.string(),
  txData: z.string().nullable(),
  value: z.string().nullable(),
  gasLimit: z.string().nullable(),
  gasPrice: z.string().nullable(),
  maxPriorityFeePerGas: z.string().nullable(),
  maxFeePerGas: z.string().nullable(),
})
// #endregion

// #region Cosmos
export const rangoCosmosProtoSchema = z.object({
  type_url: z.string(),
  value: z.array(z.number()),
})
export const rangoCosmosCoinSchema = z.object({
  amount: z.string(),
  denom: z.string(),
})
export const rangoCosmosFeeSchema = z.object({
  gas: z.string(),
  amount: z.array(rangoCosmosCoinSchema),
})
export const rangoCosmosMessageSchema = z.object({
  signType: z.enum(['AMINO', 'DIRECT']),
  sequence: z.string().nullable(),
  source: z.number().nullable(),
  account_number: z.number().nullable(),
  rpcUrl: z.string(),
  chainId: z.string().nullable(),
  msgs: z.array(z.unknown()),
  protoMsgs: rangoCosmosProtoSchema,
  memo: z.string().nullable(),
  fee: rangoCosmosFeeSchema.nullable(),
})
export const rangoAssetWithTickerSchema = rangoAssetSchema.extend({
  ticker: z.string(),
})
export const rangoCosmosDataSchema = z.object({
  amount: z.string(),
  asset: rangoAssetWithTickerSchema,
  decimals: z.number(),
  memo: z.string().nullable(),
  method: z.string(),
  recipient: z.string(),
})
export const rangoCosmosTransactionSchema = rangoTransactionSchema.extend({
  type: z.literal('COSMOS'),
  fromWalletAddress: z.string(),
  data: rangoCosmosMessageSchema,
  rawTransfer: rangoCosmosDataSchema.nullable(),
})
// #endregion

// #region Solana
export const rangoSolanaSignatureSchema = z.object({
  signature: z.array(z.number()),
  publicKey: z.array(z.string()),
})
export const rangoSolanaInstructionKeySchema = z.object({
  pubkey: z.string(),
  isSigner: z.boolean(),
  isWritable: z.boolean(),
})
export const rangoSolanaInstructionSchema = z.object({
  keys: z.array(rangoSolanaInstructionKeySchema),
  programId: z.string(),
  data: z.array(z.number()),
})
export const rangoSolanaTransaction = rangoTransactionSchema.extend({
  type: z.literal('SOLANA'),
  txType: z.enum(['LEGACY', 'VERSIONED']),
  from: z.string(),
  identifier: z.string(),
  recentBlockhash: z.string().nullable(),
  signatures: z.array(rangoSolanaSignatureSchema),
  serializedMessage: z.array(z.number()).nullable(),
  instructions: z.array(rangoSolanaInstructionSchema),
})
export type RangoSolanaTx = z.infer<typeof rangoSolanaTransaction>
// #endregion

// #region Transfer
export const rangoTransferSchema = rangoTransactionSchema.extend({
  type: z.literal('TRANSFER'),
  method: z.string(),
  asset: rangoAssetWithTickerSchema,
  amount: z.string(),
  decimals: z.number(),
  fromWalletAddress: z.string(),
  recipientAddress: z.string(),
  memo: z.string().nullable(),
})
// #endregion

export const rangoTxSchema = z.union(
  [
    rangoEvmTransactionSchema,
    rangoCosmosTransactionSchema,
    rangoSolanaTransaction,
    rangoTransferSchema,
  ],
  { message: 'Invalid Rango transaction' },
)
export type RangoTx = z.infer<typeof rangoTxSchema>
export const rangoSwapSchema = z.object({
  requestId: z.string({ message: 'Missing Rango request ID' }),
  tx: rangoTxSchema,
  route: rangoRouteSchema,
  error: z.string({ message: 'Rango error must be a string or null' }).nullable(),
})
export type RangoSwap = z.infer<typeof rangoSwapSchema>
// #endregion
